import { filter, map, propEq, pipe, reject, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { TaskPhoto, TaskPhotosState } from '../taskPhotos.types'
import { hydrateRawTaskPhoto } from '../taskPhotos.utils'

const getTaskPhotosState = (tpState: TaskPhotosState) => tpState
const getTaskPhotosIds = (_s, taskPhotosIds: string[]) => taskPhotosIds

const imgUrlIsNull = propEq('originalImage', null)

/**
 * Returns all valid TaskPhotos whose ID match the provided list of IDs.
 * A 'valid' TaskPhoto is one that matches the following conditions:
 * - The `original_image` field is non-null
 */
export const getTaskPhotosByIds = createSelector(
  getTaskPhotosState,
  getTaskPhotosIds,
  (taskPhotosState, taskPhotoIds): TaskPhoto[] => {
    const idIsInList = ({ id }) => taskPhotoIds.includes(id)

    return pipe(
      values,
      filter(idIsInList),
      map(hydrateRawTaskPhoto),
      reject(imgUrlIsNull),
    )(taskPhotosState.data)
  },
)
