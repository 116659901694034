import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { isWithinFreezeWindow } from 'packages/grimoire'
import { IconName } from 'packages/iconic'
import { Breakpoint } from 'packages/styles'

import { CleanCardContainer } from 'app/hkhub/components/schedule/components/common/CleanCard'
import { useScheduleContext } from 'app/hkhub/components/schedule/components/common/VirtualizedSchedule/VirtualizedSchedule.context'
import {
  AutoScheduleOverlay,
  ScheduleDayCleansContainer,
  UnavailableOverlay,
  OffIcon,
  OffIconContainer,
  OffIconText,
} from 'app/hkhub/components/schedule/components/schedule.styles'
import {
  CardSize,
  getStaffCellMinHeight,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Clean } from 'app/hkhub/store/cleans'
import { Coverage } from 'app/hkhub/store/coverage/coverage.types'
import { getLoadingCleanId } from 'app/hkhub/store/ui/selectors'
import { Visit } from 'app/hkhub/store/visits'

import { DateCellLoader } from '../../../common/DateCellLoader'
import { VisitCardContainer } from '../../../common/VisitCard'
import { hasFullDayCovered } from '../../staffSchedule.utils'

export const St = {
  AutoScheduleOverlay,

  OffIcon,
  OffIconContainer,
  OffIconText,

  StaffScheduleCleans: styled(ScheduleDayCleansContainer)<{
    cardSize: CardSize
  }>`
    min-height: ${({ cardSize }) => getStaffCellMinHeight(cardSize)}px;
  `,

  UnavailableOverlay,
}

export enum StaffScheduleTasksTestIds {
  autoScheduled = 'StaffScheduleTasks__autoScheduled',
  unavailable = 'StaffScheduleTasks__unavailable',
}

export type StaffScheduleTasksProps = {
  breakpoint: Breakpoint
  cardSize: CardSize
  cleans: Clean[]
  coverage: Coverage[]
  date: Date
  isAvailable: boolean
  unassigned: boolean
  visits: Visit[]
}

export const StaffScheduleTasks: React.FC<StaffScheduleTasksProps> = ({
  breakpoint,
  cardSize,
  cleans,
  coverage,
  date,
  isAvailable,
  unassigned,
  visits,
}) => {
  const loadingCleanId = useSelector(getLoadingCleanId)
  const { dateRange, isLoading } = useScheduleContext()
  const shouldShowUnavailable = !isAvailable && !isLoading
  const shouldShowNoVisits = coverage.some(hasFullDayCovered)

  return (
    <St.StaffScheduleCleans
      cardSize={cardSize}
      dayCount={dateRange.length}
      unassigned={unassigned}
    >
      {isLoading ? (
        <DateCellLoader cardType={'staff'} size={cardSize} />
      ) : (
        <>
          {shouldShowUnavailable && (
            <St.OffIconContainer breakpoint={breakpoint}>
              <St.OffIcon
                breakpoint={breakpoint}
                icon={IconName.bucketClean}
                size={12}
              />
              <St.OffIconText breakpoint={breakpoint}>OFF</St.OffIconText>
            </St.OffIconContainer>
          )}
          {shouldShowNoVisits && (
            <St.OffIconContainer breakpoint={breakpoint}>
              <St.OffIcon
                breakpoint={breakpoint}
                icon={IconName.refreshCw}
                size={12}
              />
              <St.OffIconText breakpoint={breakpoint}>OFF</St.OffIconText>
            </St.OffIconContainer>
          )}
          {visits.map(visit => (
            <VisitCardContainer
              visit={visit}
              isLoading={false}
              key={visit.id}
            />
          ))}
          {cleans.map(clean => (
            <CleanCardContainer
              clean={clean}
              isLoading={loadingCleanId === clean.id}
              key={clean.id}
            />
          ))}
        </>
      )}

      {shouldShowUnavailable && (
        <St.UnavailableOverlay
          data-testid={StaffScheduleTasksTestIds.unavailable}
        />
      )}

      {!isWithinFreezeWindow(date) && (
        <St.AutoScheduleOverlay
          data-testid={StaffScheduleTasksTestIds.autoScheduled}
        />
      )}
    </St.StaffScheduleCleans>
  )
}
