import React from 'react'

import { TranslatorFn } from 'packages/i18n'

import { Slugs } from 'app/hkhub/i18n'

import styles from '../../AddHkModal.module.scss'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const renderHkColumn = (hk, t: TranslatorFn): React.ReactNode => {
  const manager = hk.manager
  return (
    <>
      <div>
        {hk.firstName} {hk.lastName}
      </div>
      {!!manager && (
        <div className={styles.subText}>
          {`${t(Slugs.managedBy)} ${manager.firstName} ${manager.lastName}`}
        </div>
      )}
    </>
  )
}
