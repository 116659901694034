export enum CleanAssignmentStatus {
  Default = 'default',
  Future = 'future',
  Past = 'past',
  Unassigned = 'unassigned',
  UnassignedFuture = 'unassignedFuture',
  UnassignedPast = 'unassignedPast',
}

//------------------------------------------------
// Navigation & URL Types
//------------------------------------------------

type ScheduleUrlSearchParamKeys = 'date' | 'days'
export type ScheduleUrlSearchParams = {
  [key in ScheduleUrlSearchParamKeys]: string
}

export type ScheduleNavigationSearchParams = {
  date: Date
  dayCount: number
}

export type ScheduleNavigationMatchParams = {
  entity: ScheduleEntityType
}

export type ScheduleEntityType = 'staff' | 'unit'

export const VIRTUALIZED_SCHEDULE_CLASSNAME = 'VirtualizedSchedule'
