import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import React from 'react'

import { Drawer } from 'packages/common'
import { Features } from 'packages/optimizely'
import { colors } from 'packages/styles'

import { Lockbox } from 'app/hkhub/store/lockboxes'
import { Unit } from 'app/hkhub/store/units/units.types'

import { UnitDrawerHeader, UnitLinks, UnitStatus } from './components'
import { TicketsListContainer } from './components/TicketsList'
import { DeepCleansSectionContainer, UnitInfoSection } from './sections'
import { VisitSectionContainer } from './sections/VisitSection/VisitSection.container'

const St = {
  InvalidUnit: styled.div`
    color: ${colors.lake};
    font-size: 18px;
    font-weight: bold;
  `,
  UnitDrawerContent: styled.div`
    max-width: 480px;
    min-width: 375px; // minimum supported phone size
  `,
}

export enum UnitDrawerTestIds {
  content = 'UnitDrawer__content',
  invalid = 'UnitDrawer__invalid',
}

export type UnitDrawerProps = {
  forceCloseDrawer: boolean
  handleAfterExit: () => void
  handleClickAway: (event: MouseEvent) => boolean
  isOpen: boolean
  lockbox?: Lockbox
  unit?: Unit
}

export const UnitDrawer: React.FC<UnitDrawerProps> = React.memo(
  ({
    forceCloseDrawer,
    handleAfterExit,
    handleClickAway,
    isOpen,
    lockbox,
    unit,
  }) => {
    const [decision] = useDecision(Features.CUSTOM_INSPECTION_CHECKLIST)
    const customInspectionChecklistEnabled = decision.enabled
    return (
      <Drawer
        afterExit={handleAfterExit}
        forceClose={forceCloseDrawer}
        isOpen={isOpen}
        onClickAway={handleClickAway}
      >
        {unit ? (
          <St.UnitDrawerContent data-testid={UnitDrawerTestIds.content}>
            <UnitDrawerHeader unit={unit} />
            <UnitStatus unit={unit} />
            <UnitInfoSection lockbox={lockbox} unit={unit} />
            {customInspectionChecklistEnabled && (
              <VisitSectionContainer unit={unit} />
            )}
            <DeepCleansSectionContainer unit={unit} />
            <TicketsListContainer unitId={unit.id} />
            <UnitLinks unitId={unit.id} />
          </St.UnitDrawerContent>
        ) : (
          <St.UnitDrawerContent data-testid={UnitDrawerTestIds.invalid}>
            <St.InvalidUnit>Invalid Unit</St.InvalidUnit>
          </St.UnitDrawerContent>
        )}
      </Drawer>
    )
  },
)
