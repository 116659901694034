import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'

import { LIGHTBOX_CLASS_SELECTOR } from 'packages/common'

import { fetchCleanById } from 'app/hkhub/store/cleans/actions'
import { Clean } from 'app/hkhub/store/cleans/cleans.types'
import { getCleanById } from 'app/hkhub/store/cleans/selectors'
import { getLockboxByUnitId } from 'app/hkhub/store/lockboxes/selectors'
import { AppDispatch, ApplicationState } from 'app/hkhub/store/store'

import { CleanDrawer } from './CleanDrawer'
import { useHandleNextRes } from './hooks'
import { useCleanDrawerDeepCleanAlertConfig } from './hooks/useCleanDrawerDeepCleanAlertConfig'

export type CleanDrawerContainerProps = {
  cleanId: string | undefined
  isOpen: boolean
  onAfterExit: () => void
}

export const CleanDrawerContainer: React.FC<CleanDrawerContainerProps> = ({
  cleanId,
  isOpen,
  onAfterExit,
}) => {
  const dispatch: AppDispatch = useDispatch()

  const clean: Clean | undefined = useSelector((state: ApplicationState) =>
    cleanId ? getCleanById(state, cleanId) : undefined,
  )

  const lockbox = useSelector((state: ApplicationState) =>
    getLockboxByUnitId(state, clean?.unit.id || ''),
  )

  const [fetchCleanState, fetchClean] = useAsyncFn(
    async (cleanId: string) => {
      await dispatch(fetchCleanById(cleanId))
    },
    [dispatch],
  )

  // re-fetch the clean every time the drawer is opened, to ensure we have up-to-date data
  React.useEffect(() => {
    if (isOpen && cleanId) {
      fetchClean(cleanId)
    }
  }, [cleanId, fetchClean, isOpen])

  const [state, setState] = React.useState({
    forceCloseDrawer: false,
  })

  const hasFetchedNextReservation = useHandleNextRes({
    clean,
  })

  const { cleanupAlertOnDrawerClose, deepCleanAlertConfig } =
    useCleanDrawerDeepCleanAlertConfig(clean)

  const handleForceCloseDrawer = React.useCallback(() => {
    setState(prev => ({
      ...prev,
      forceCloseDrawer: true,
    }))
  }, [])

  const handleAfterExit = React.useCallback(() => {
    setState(prev => ({
      ...prev,
      forceCloseDrawer: false,
      hasFetchedNextReservation: false,
    }))

    onAfterExit()
  }, [onAfterExit])

  const handleClickAway = React.useCallback(
    event => {
      // prevent closing the drawer if the outside click was on the Lightbox
      const lightbox = document.querySelector(LIGHTBOX_CLASS_SELECTOR)
      const shouldNotCloseDrawer = lightbox?.contains(event.target) || false
      if (!shouldNotCloseDrawer) cleanupAlertOnDrawerClose()
      return shouldNotCloseDrawer
    },
    [cleanupAlertOnDrawerClose],
  )

  return (
    <CleanDrawer
      clean={clean}
      deepCleanAlertConfig={deepCleanAlertConfig}
      fetchingClean={fetchCleanState.loading}
      forceCloseDrawer={state.forceCloseDrawer}
      handleAfterExit={handleAfterExit}
      handleClickAway={handleClickAway}
      handleForceCloseDrawer={handleForceCloseDrawer}
      hasFetchedNextReservation={hasFetchedNextReservation}
      isOpen={isOpen}
      lockbox={lockbox}
    />
  )
}
