import { OptimizelyProvider } from '@optimizely/react-sdk'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Alert, Loader } from 'packages/common'
import { ReduxDispatch } from 'packages/grimoire/src/utils'
import { createOptimizelyInstance, decodeUserId } from 'packages/optimizely'

import { useI18n, Slugs } from 'app/hkhub/i18n'
import { fetchCurrentUser } from 'app/hkhub/store/users/actions'

import { getAuthError, getAuthToken } from '../store/auth/selectors'
import { ApplicationState } from '../store/store'
import HkHubRoot from './HkHubRoot'

const optimizelyKey = process.env.REACT_APP_OPTIMIZELY_SDK_KEY || ''
const optimizely = createOptimizelyInstance(optimizelyKey)

const AuthErrorAlert = () => {
  const { t } = useI18n()

  return (
    <Alert alertType={'danger'}>
      <div>{t(Slugs.authErrorBody)}</div>
    </Alert>
  )
}

export type HkHubIndexProps = {
  initialUrl: string
}

export type HkHubIndexState = {
  hasLocalAuthError: boolean
  initializing: boolean
}

const HkHubIndex: React.FC<HkHubIndexProps> = ({ initialUrl }) => {
  const dispatch = useDispatch<ReduxDispatch<ApplicationState>>()

  const authError = useSelector(getAuthError)
  const token = useSelector(getAuthToken)
  const monolithUserId = decodeUserId(token)

  const [state, setState] = React.useState<HkHubIndexState>({
    hasLocalAuthError: false,
    initializing: true,
  })
  const { hasLocalAuthError, initializing } = state

  React.useEffect(() => {
    const initialize = async () => {
      if (authError) {
        setState(prev => ({ ...prev, initializing: false }))
      } else {
        try {
          await dispatch(fetchCurrentUser)
          setState({ hasLocalAuthError: false, initializing: false })
        } catch (err) {
          setState({ hasLocalAuthError: true, initializing: false })
        }
      }
    }

    if (initializing) {
      initialize()
    }
  }, [authError, dispatch, initializing])

  if (initializing) {
    return <Loader />
  }

  if (!!authError || hasLocalAuthError) {
    return <AuthErrorAlert />
  }

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        attributes: {
          app: 'housekeeping-hub',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          employee_user_id: Number(monolithUserId), // This id is used for targeting feature flags by user id
        },
        id: monolithUserId, // This id is only used for Optimizely 'experiments', not Feature Flags
      }}
    >
      <HkHubRoot initialUrl={initialUrl} />
    </OptimizelyProvider>
  )
}

export default HkHubIndex
