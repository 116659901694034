import React from 'react'

import Select, { SelectProps } from './Select'

type SimpleSelectSpecificProps<T extends boolean | string> = {
  id?: string
  isOptionDisabled?: (opt: T) => boolean
  maxMenuHeight?: number
  onChange: (arg: T) => void
  // This type allows options that are type with "as const" to be allowed as the options arg
  options: T[] | readonly T[]
  translateValue: (val: T) => string
  value: T | null
}

type SimpleSelectProps<T extends boolean | string> = Omit<
  SelectProps,
  'options' | 'onChange' | 'selectedValue' | 'getOptionLabel' | 'getOptionValue'
> &
  SimpleSelectSpecificProps<T>

/**
 * Wraps the "Select" component allowing the values to be an array of strings.
 * This is useful when we're not using complex data and we'd need to force the options
 * into a "Record" type with an arbitrary key in order to use the base Select.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SimpleSelect: React.FC<SimpleSelectProps<any>> = ({
  id,
  isOptionDisabled,
  onChange,
  maxMenuHeight,
  options,
  translateValue,
  value,
  ...props
}) => {
  return (
    <Select
      {...props}
      id={id}
      onChange={selected => onChange(selected.value)}
      isOptionDisabled={isOptionDisabled}
      selectedValue={{ value }}
      getOptionLabel={opt => translateValue(opt.value)}
      getOptionValue={opt => opt.value}
      maxMenuHeight={maxMenuHeight}
      options={options.map(opt => ({ value: opt }))}
    />
  )
}

export const StringSelect: React.FC<SimpleSelectProps<string>> = SimpleSelect

export const BooleanSelect: React.FC<SimpleSelectProps<boolean>> = SimpleSelect
