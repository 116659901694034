import * as React from 'react'

import {
  AllCleanAttributes,
  cleanIsB2b,
  cleanIsLateCheckout,
  cleanIsPotentialB2b,
} from 'packages/grimoire'

import { CleanBadge, CleanBadgeTypes } from '../CleanBadge'
import { TooltipPosition } from '../Tooltip'

export type CleanForBadge = Pick<AllCleanAttributes, 'serviceType'>

export type UnitDetailBadgesProps = {
  clean: CleanForBadge
  omitBadgeTypes?: CleanBadgeTypes[]
  tooltipPosition?: TooltipPosition
}

export const UnitDetailBadges: React.FC<UnitDetailBadgesProps> = ({
  clean,
  omitBadgeTypes,
  tooltipPosition = TooltipPosition.Bottom,
}) => {
  const shouldIncludeBadge = type => !omitBadgeTypes?.includes(type)

  const renderB2B = cleanIsB2b(clean) && shouldIncludeBadge(CleanBadgeTypes.B2B)

  const renderLate =
    cleanIsLateCheckout(clean) && shouldIncludeBadge(CleanBadgeTypes.Late)

  const renderPotentialB2B =
    cleanIsPotentialB2b(clean) &&
    shouldIncludeBadge(CleanBadgeTypes.PotentialB2B)

  return (
    <>
      {renderB2B && (
        <CleanBadge
          tooltipPosition={tooltipPosition}
          type={CleanBadgeTypes.B2B}
        />
      )}

      {renderLate && (
        <CleanBadge
          tooltipPosition={tooltipPosition}
          type={CleanBadgeTypes.Late}
        />
      )}

      {renderPotentialB2B && (
        <CleanBadge
          tooltipPosition={tooltipPosition}
          type={CleanBadgeTypes.PotentialB2B}
        />
      )}
    </>
  )
}
