import get from 'lodash/get' // eslint-disable-line
import { createAsyncAction } from 'typesafe-actions'

import { format } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { Clean } from 'app/hkhub/store/cleans/cleans.types'
import { makeCleanUnitHash } from 'app/hkhub/utils/scheduleHelpers'

import { reservationsService } from '../reservations.service'
import {
  NormalizedReservationsApiResponse,
  Reservation,
  ReservationApiFields,
  ReservationsActionTypes,
} from '../reservations.types'

export const fetchNextReservationByCleanAction = createAsyncAction(
  ReservationsActionTypes.FETCH_NEXT_RESERVATION_BY_CLEAN,
  ReservationsActionTypes.FETCH_NEXT_RESERVATION_BY_CLEAN_SUCCESS,
  ReservationsActionTypes.FETCH_NEXT_RESERVATION_BY_CLEAN_FAILURE,
)<
  RequestConfig<NormalizedReservationsApiResponse>,
  NormalizedReservationsApiResponse,
  Error
>()

/* eslint-disable @typescript-eslint/naming-convention */
export const getParams = (cleanDate, unitId): RequestOptions => ({
  fields: {
    reservation: ReservationApiFields,
  },
  filter: {
    check_in__gt: cleanDate,
    is_cancelled: false,
    related_unit: unitId,
  },
  page: {
    size: 1,
  },
  sort: ['check_in'],
})
/* eslint-enable @typescript-eslint/naming-convention */

export const fetchNextReservationByClean = (clean: Clean) => async dispatch => {
  try {
    const cleanDate = format(clean.effectiveDate, 'yyyy-MM-dd')
    const params = getParams(cleanDate, clean.unit.id)
    const request = reservationsService.fetchReservations.bind(null, params)
    const result = await dispatch(
      fetchNextReservationByCleanAction.request({ request }),
    )

    // if we have a valid reservation in our response payload, we will use it
    const data = get(result, 'normalized.reservation')
    if (data) {
      const values = Object.values(data)
      if (values.length) {
        const reservation = values[0] as Reservation
        const hash = makeCleanUnitHash(clean)
        const payloadWithCustomHash = {
          ...result,
          normalized: {
            reservation: {
              [hash]: { ...reservation },
            },
          },
        }

        // dispatch/return with custom payload (hash + reservation)
        dispatch(
          fetchNextReservationByCleanAction.success(payloadWithCustomHash),
        )

        return payloadWithCustomHash.normalized
      }
    }

    // dispatch/return with empty response; nothing needs to happen, but we should return it anyway
    dispatch(fetchNextReservationByCleanAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchNextReservationByCleanAction.failure(error))
    throw error
  }
}
