/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import optimizelySdk from '@optimizely/optimizely-sdk'
import { Store } from 'redux'

import { Features } from 'packages/optimizely'
import { logInfo } from 'packages/wiretap/logging'

import { isAuthTokenValid, toCamelCase, waitForNetwork } from './helpers'
import {
  CloseCodes,
  EventHandler,
  EventTypes,
  ReadyState,
  SocketEvent,
} from './types'

type EventMapping<StateType> = Partial<
  Record<EventTypes, EventHandler<StateType>>
>
type GetAuthToken = (state) => string | undefined
type SetNeedsSilentRefresh = (need: boolean) => (dispatch) => unknown

const optimizelyKey = process.env.REACT_APP_OPTIMIZELY_SDK_KEY || ''
const optimizelyClient = optimizelySdk.createInstance({ sdkKey: optimizelyKey })

await optimizelyClient?.onReady()

export class WebSocketEvents<StateType> {
  url: string

  store: Store

  handlers: Map<EventTypes, EventHandler<StateType>>

  setNeedsSilentRefresh: SetNeedsSilentRefresh

  authToken: string | null

  socket?: WebSocket

  currentZoneId: string | null

  constructor(
    url: string,
    store: Store,
    getAuthToken: GetAuthToken,
    setNeedsSilentRefresh: SetNeedsSilentRefresh,
    events: EventMapping<StateType>,
  ) {
    this.url = url
    this.store = store
    this.authToken = null
    this.currentZoneId = null
    this.setNeedsSilentRefresh = setNeedsSilentRefresh
    this.handlers = new Map<EventTypes, EventHandler<StateType>>()

    this.initializeHandlers(events)
    this.subscribeToAuthTokenChange(getAuthToken)
    this.initializeFeatureFlag()
  }

  initializeHandlers(events: EventMapping<StateType>) {
    Object.entries(events).forEach(([type, handler]) => {
      this.handlers.set(type as EventTypes, handler as EventHandler<StateType>)
    })
  }

  subscribeToAuthTokenChange(getAuthToken: GetAuthToken) {
    this.store.subscribe(() => {
      const token = getAuthToken(this.store.getState())

      if (token && token !== this.authToken) {
        this.authToken = token
        this.connect()
      }
    })
  }

  initializeFeatureFlag() {
    if (this.isFeatureFlagEnabled()) {
      this.subscribeToZoneIdChange()
    }
  }

  isFeatureFlagEnabled(): boolean {
    const userContext = optimizelyClient?.createUserContext(
      this.currentZoneId || '',
      {},
    )
    const decision = userContext?.decide(Features.DYNAMIC_ZONE_ID_HANDLING)
    return decision?.enabled ?? false
  }

  subscribeToZoneIdChange() {
    this.store.subscribe(() => {
      const state = this.store.getState()
      const newZoneId = state.zones.currentZones && state.zones.currentZones[0]

      if (newZoneId && newZoneId !== this.currentZoneId) {
        this.currentZoneId = newZoneId
        this.connect()
      }
    })
  }

  refreshAuthToken() {
    this.setNeedsSilentRefresh(true)(this.store.dispatch)
  }

  connect() {
    if (!this.authToken) {
      throw new Error('missing auth token')
    }

    if (this.isConnected) {
      this.disconnect()
    }

    if (this.isFeatureFlagEnabled() && this.currentZoneId === null) {
      return
    }

    const urlWithZone = this.isFeatureFlagEnabled()
      ? `${this.url}?zoneid=${this.currentZoneId}`
      : this.url
    /*
      Setting up event handlers.
      https://www.tutorialspoint.com/websockets/websockets_handling_errors.htm
      The onerror event is fired when something wrong occurs between the communications.
      The event onerror is followed by a connection termination, which is a close event.
    */

    this.socket = new WebSocket(urlWithZone, [this.authToken])
    this.socket.onclose = this.onClose
    this.socket.onmessage = this.onMessage
  }

  get isConnected(): boolean {
    return (
      this.socket instanceof WebSocket &&
      this.socket.readyState === ReadyState.OPEN
    )
  }

  reconnect() {
    if (isAuthTokenValid(this.authToken)) {
      this.connect()
    } else {
      this.refreshAuthToken()
    }
  }

  private disconnect(): void {
    if (this.socket) {
      this.socket.close()
      this.socket = undefined
      logInfo('WebSocket disconnected')
    }
  }

  private onClose = async (event: CloseEvent) => {
    const { code, reason, wasClean } = event

    switch (code) {
      case CloseCodes.CLOSE_NORMAL:
      case CloseCodes.GOING_AWAY:
        this.reconnect()
        break

      case CloseCodes.ABNORMAL_CLOSURE:
        return waitForNetwork()
          .then(() => {
            this.reconnect()
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error(error)
          })

      default:
        logInfo('WebSocket connection closed unexpectedly', {
          boundary: 'websocket',
          clean: wasClean,
          code,
          reason,
          severity: 'warning',
        })
    }
  }

  private onMessage = (e: MessageEvent) => {
    const { event_name: type, properties: data }: SocketEvent = JSON.parse(
      e.data,
    )

    const handler = this.handlers.get(type)

    if (handler) {
      handler(toCamelCase(data))(this.store.dispatch, this.store.getState)
    }
  }
}
