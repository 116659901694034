import * as React from 'react'

import { Slugs, useI18n } from 'packages/i18n'

import { Button } from '../../../../Button'
import { ModalButtons, ModalContent, ModalTitle } from '../../Modal.styles'
import { ModalChildrenProps } from '../../ModalPortal'

export enum ConfirmModalTestIds {
  cancelBtn = 'ConfirmModal__cancelBtn',
  confirmBtn = 'ConfirmModal__confirmBtn',
}

export type ConfirmModalSlugsType = {
  cancel?: string
  confirm?: string
  message: string
  title: string
}

export type ConfirmModalProps = ModalChildrenProps & {
  extraChildren?: React.ReactNode
  onConfirm: () => void
  slugs: ConfirmModalSlugsType
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  beginClose,
  extraChildren,
  onConfirm,
  slugs,
}) => {
  const { ut } = useI18n()

  const handleClose = () => {
    beginClose()
    onConfirm()
  }

  return (
    <>
      <ModalTitle>{ut(slugs.title)}</ModalTitle>
      <ModalContent>{ut(slugs.message)}</ModalContent>

      {extraChildren}

      <ModalButtons>
        <Button
          dataTestId={ConfirmModalTestIds.cancelBtn}
          onClick={beginClose}
          buttonType={'utility'}
        >
          {ut(slugs.cancel ?? Slugs.cancel)}
        </Button>

        <Button
          dataTestId={ConfirmModalTestIds.confirmBtn}
          onClick={handleClose}
          buttonType={'primary'}
        >
          {ut(slugs.confirm ?? Slugs.confirm)}
        </Button>
      </ModalButtons>
    </>
  )
}
