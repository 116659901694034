import React from 'react'

import { Tooltip, TooltipPosition } from 'packages/common'
import { IconName, IconTextPosition } from 'packages/iconic'

import { useI18n, Slugs } from 'app/hkhub/i18n'

import * as St from './TicketListHeader.styles'

export enum TicketListHeaderTestIds {
  overdueIcon = 'TicketListHeader__overdueIcon',
}

export type TicketListHeaderProps = {
  numberOfOverdueTickets: number
}

export const TicketListHeader: React.FC<TicketListHeaderProps> = React.memo(
  ({ numberOfOverdueTickets }) => {
    const { ut, tp } = useI18n()

    return (
      <St.Header>
        <St.Title>{ut(Slugs.tickets)}</St.Title>
        {numberOfOverdueTickets > 0 && (
          <Tooltip
            message={tp(
              Slugs.overdueToolTipTextWithNumber,
              numberOfOverdueTickets,
            )}
            position={TooltipPosition.Top}
          >
            <St.OverdueIcon
              centerItems={true}
              dataTestId={TicketListHeaderTestIds.overdueIcon}
              icon={IconName.alertTriangle}
              size={12}
              text={numberOfOverdueTickets.toString()}
              textPosition={IconTextPosition.Left}
            />
          </Tooltip>
        )}
      </St.Header>
    )
  },
)
