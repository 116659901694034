import React from 'react'

import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Lockbox } from 'app/hkhub/store/lockboxes'
import { Unit } from 'app/hkhub/store/units'

import { UnitDrawerStyles } from '../../UnitDrawer.styles'

const St = {
  AccessCodes: UnitDrawerStyles.SubSection,
  Content: UnitDrawerStyles.SectionContent,
  Icon: UnitDrawerStyles.Icon,
  Title: UnitDrawerStyles.SectionSubTitle,
}

export type AccessCodesProps = {
  lockbox?: Lockbox
  unit: Unit
}

export const AccessCodes: React.FC<AccessCodesProps> = React.memo(
  ({ lockbox, unit }) => {
    const { t } = useI18n()

    const hasValidLockbox =
      lockbox && (lockbox.primaryLock || lockbox.backupLock)

    return (
      <St.AccessCodes>
        <St.Title>
          <St.Icon icon={IconName.lock} size={16} />
          <strong>{t(Slugs.access)}</strong>
        </St.Title>

        <St.Content>
          {!!unit.gateCode && (
            <div>
              <strong>{t(Slugs.gateCode)}</strong>: {unit.gateCode}
            </div>
          )}

          {!!unit.alarmCode && (
            <div>
              <strong>{t(Slugs.alarmCode)}</strong>: {unit.alarmCode}
            </div>
          )}

          {lockbox && (
            <div>
              {hasValidLockbox ? (
                <>
                  <div>
                    <strong>{t(Slugs.lockbox)}</strong>:{' '}
                    {lockbox?.primaryLock || '-'}
                  </div>
                  <div>
                    <strong>{t(Slugs.backup)}</strong>:{' '}
                    {lockbox?.backupLock || '-'}
                  </div>
                  <div>
                    <strong>{t(Slugs.notes)}</strong>: {lockbox?.notes || '-'}
                  </div>
                </>
              ) : (
                <> - </>
              )}
            </div>
          )}
        </St.Content>
      </St.AccessCodes>
    )
  },
)
