import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  apiDateStringWithSeconds,
  createDateObject,
} from 'packages/utils/dateHelpers'
import {
  makeRelationship,
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store'

import {
  NormalizedDeepCleanSkipsApiResponse,
  DeepCleanSkipPostData,
  DeepCleanSkipPostApiAttributes,
  DeepCleanSkipsActionTypes,
  DeepCleanSkipPostApiRelationships,
} from 'app/hkhub/store/deepCleanSkips'
import { fetchUnitByIdStrict } from 'app/hkhub/store/units/actions'

import { deepCleanSkipsService } from '../deepCleanSkips.service'

export const createDeepCleanSkipAction = createAsyncAction(
  DeepCleanSkipsActionTypes.CREATE_DEEP_CLEAN_SKIP,
  DeepCleanSkipsActionTypes.CREATE_DEEP_CLEAN_SKIP_SUCCESS,
  DeepCleanSkipsActionTypes.CREATE_DEEP_CLEAN_SKIP_FAILURE,
)<
  RequestConfig<NormalizedDeepCleanSkipsApiResponse>,
  NormalizedDeepCleanSkipsApiResponse,
  Error
>()

export const buildRequestData = (
  postData: DeepCleanSkipPostData,
): RequestOptions => {
  const { reason, unitId, userId } = postData

  const attributes: DeepCleanSkipPostApiAttributes = {
    reason,
    skipped_at: apiDateStringWithSeconds(createDateObject()),
  }

  const relationships: DeepCleanSkipPostApiRelationships = {
    skipped_by: makeRelationship('user', userId),
    unit: makeRelationship('unit', unitId),
  }

  return {
    data: {
      attributes,
      relationships,
      type: 'deep_clean_skip',
    },
  }
}

export const createDeepCleanSkip =
  (postData: DeepCleanSkipPostData, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onSuccess = noop } = callbacks

    try {
      const requestData = buildRequestData(postData)
      const request = deepCleanSkipsService.createDeepCleanSkip.bind(
        null,
        requestData,
      )

      const result = await dispatch(
        createDeepCleanSkipAction.request({ request }),
      )
      dispatch(createDeepCleanSkipAction.success(result))

      // re-fetch the unit, so we can get the updated "next due"
      await dispatch(fetchUnitByIdStrict(postData.unitId))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(createDeepCleanSkipAction.failure(error))
      throw error
    }
  }
