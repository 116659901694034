import get from 'lodash/get' // eslint-disable-line

import { getHousekeeperById } from 'app/hkhub/store/housekeepers/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import {
  getUnitById,
  getUnitHousekeeperPreferenceById,
} from 'app/hkhub/store/units/selectors'
import {
  UnitWithHousekeeperPreferences,
  UnitPreference,
  UnitPreferenceKind,
} from 'app/hkhub/store/units/units.types'

/**
 * Returns a Unit with the housekeeper preferences embedded
 *
 * @param state
 * @param id the unit id
 */
export const getUnitWithHousekeeperPreferencesById = (
  state: ApplicationState,
  id: string,
): UnitWithHousekeeperPreferences | undefined => {
  const unit = getUnitById(state.units, id)

  if (!unit) {
    return undefined
  }

  const raw = get(state.units.data, id)

  const housekeeperPreferences = raw.relationships.housekeeperPreferences.data
    .map(pref => getUnitHousekeeperPreferenceById(state.units, pref.id))
    .map(byId => {
      if (!byId) {
        return undefined
      }

      const housekeeper = getHousekeeperById(
        state.housekeepers,
        byId.housekeeper,
      )

      return {
        ...byId,
        housekeeper: housekeeper
          ? {
              firstName: housekeeper.user.firstName,
              id: housekeeper.id,
              lastName: housekeeper.user.lastName,
            }
          : undefined,
      }
    })
    .filter(pref => pref !== undefined) as UnitPreference[]

  const categorizedHousekeeperPreferences = housekeeperPreferences.reduce(
    (acc, next: UnitPreference) => ({
      ...acc,
      [next.alias]: [...acc[next.alias], next],
    }),
    {
      [UnitPreferenceKind.excluded]: [] as UnitPreference[],
      [UnitPreferenceKind.mandatory]: [] as UnitPreference[],
      [UnitPreferenceKind.suggested]: [] as UnitPreference[],
    },
  )

  const unitWithPreferences = {
    ...unit,
    housekeeperPreferences: categorizedHousekeeperPreferences,
  }

  return unitWithPreferences as UnitWithHousekeeperPreferences
}
