import React from 'react'

export type ScheduleContextType = {
  dateRange: Date[]
  isLoading: boolean
}

export const ScheduleContext = React.createContext<ScheduleContextType>({
  dateRange: [],
  isLoading: false,
})

export const useScheduleContext = (): ScheduleContextType =>
  React.useContext(ScheduleContext)
