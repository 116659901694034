import React from 'react'

import { logInfo } from 'packages/wiretap/logging'

import { getStateFromUrl } from '../utils'

const handleShouldUpdateServiceWorker = async () => {
  try {
    const registration = await navigator.serviceWorker?.getRegistration()
    await registration?.update()
    registration?.waiting?.postMessage('skipWaiting')
  } catch (e) {
    logInfo('Missing Service Worker', {
      error: JSON.stringify(e),
    })
  }
}

export type AppAuthState = {
  initialUrl: string
  loaded: boolean
}

type UseAppAuth = {
  authState: AppAuthState
  onAppAuthInitialized: (initialUrl?: string) => Promise<void>
}

export const useAppAuth = (): UseAppAuth => {
  const [authState, setAuthState] = React.useState<AppAuthState>({
    initialUrl: '',
    loaded: false,
  })

  /**
   * Callback for successfully authenticating with third-party provider
   * No other data bootstrapping can occur until this completes,
   * since it all requires valid auth.
   */
  const onAppAuthInitialized = React.useCallback(
    async (initialUrl?: string) => {
      if (!authState.loaded) {
        /** If we are authenticated, and the app has just loaded, skip waiting, if there is a waiting SW */
        await handleShouldUpdateServiceWorker()
        setAuthState({
          initialUrl: initialUrl || getStateFromUrl(),
          loaded: true,
        })
      }
    },
    [authState.loaded],
  )

  return {
    authState,
    onAppAuthInitialized,
  }
}
