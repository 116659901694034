import React from 'react'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { CustomInspectionItem } from 'app/hkhub/store/customInspectionItems/customInspectionItems.types'

import { CustomInspectionItemsFormState } from '../customInspectionItemsState/customInspectionItemsForm.reducer'
import { CustomInspectionItemActionHandlersType } from '../VisitSection.container'
import {
  CustomChecklistItemHeaderContainer,
  EditButton,
  ItemTitle,
  Subtitle,
  TextArea,
} from './CustomInspectionItem.styles'

const CUSTOM_ITEM_MAX_CHARS = 500

const St = {
  CustomChecklistItemHeaderContainer,
  EditButton,
  ItemTitle,
  Subtitle,
  TextArea,
}

export enum CustomInspectionItemDisplayTestIds {
  deleteButton = 'CustomInspectionItemDisplay__deleteButton',
  editButton = 'CustomInspectionItemDisplay__editButton',
}

export type CustomInspectionItemDisplayProps = {
  actionHandlers: CustomInspectionItemActionHandlersType
  index: number
  item?: CustomInspectionItem
  state: CustomInspectionItemsFormState
}

export const CustomInspectionItemDisplay: React.FC<CustomInspectionItemDisplayProps> =
  React.memo(({ actionHandlers, index, item, state }) => {
    const { ut } = useI18n()

    return (
      <>
        <St.CustomChecklistItemHeaderContainer>
          <St.ItemTitle>
            {ut(Slugs.checklistItem)} {index + 1}:
          </St.ItemTitle>
          <St.EditButton
            buttonType={'text'}
            dataTestId={CustomInspectionItemDisplayTestIds.editButton}
            disabled={!!state.values.crudCustomInspectionItem?.id}
            onClick={() => actionHandlers.edit(item?.id || '')}
          >
            {ut(Slugs.edit)}
          </St.EditButton>
        </St.CustomChecklistItemHeaderContainer>

        <St.TextArea
          disabled={true}
          maxLength={500}
          value={item?.itemTitle}
        ></St.TextArea>

        <St.Subtitle>{`${
          item?.itemTitle.length || 0
        }/${CUSTOM_ITEM_MAX_CHARS}`}</St.Subtitle>
      </>
    )
  })
