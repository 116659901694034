import { noop } from 'lodash/fp'
import React from 'react'

import { TransitionPhaseType } from 'packages/utils/hooks'

export enum DrawerPosition {
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
  Top = 'Top',
}

export type OptionalDrawerProps = {
  afterOpen: () => void
  duration: number
  forceClose: boolean
  hideBackdrop: boolean
  hideCloseButton: boolean
  pinned: boolean
  position: DrawerPosition
}

export const defaultOptionalProps: OptionalDrawerProps = {
  afterOpen: noop,
  duration: 250,
  forceClose: false,
  hideBackdrop: false,
  hideCloseButton: false,
  pinned: false,
  position: DrawerPosition.Right,
}

export type BaseDrawerProps = Partial<OptionalDrawerProps> & {
  afterExit: () => void
  children: React.ReactNode
  id?: string
  isOpen: boolean
}

export type DrawerContainerProps = BaseDrawerProps & {
  /**
   * An optional array of query strings to use for additional "ignore outside click" handling.
   * Anything specified here WILL NOT cause the drawer to close when it is clicked.
   *
   * Note that these are used as the value for document.querySelector queries, so they need to be valid there.
   * For example:
   * - Simple class/ID selector strings like "#AwesomeComponent" or ".okayClass"
   * - Complex selectors like `div[data-testid="EvenBetterComponent"]`
   *
   * Also note that some global elements are already ignored by default, so check the "IGNORED_CLICK_SELECTORS" list
   * in the Drawer component before adding any new ones to your custom component.
   */
  ignoreClickEventsOn?: string[]
  /**
   * Callback to be called any time a "click away" event is detected, which exposes
   * the ability to prevent an auto-close on outside click.
   * Returning "true" from this callback will prevent the drawer from closing in response to this event.
   * If no callback is provided, the drawer will close on all outside clicks.
   * @param event
   */
  onClickAway?: (event: MouseEvent) => boolean
  renderContainer?: Element
}

export type DrawerRendererProps = BaseDrawerProps & {
  beginClose: () => void
  renderContainer: Element | undefined
  transitionPhase: TransitionPhaseType
}
