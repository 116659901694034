/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames'
import * as React from 'react'

import { ButtonType } from '../Button'
import { Loader } from '../Loader'
import DataTableHeader from './Header/DataTableHeader'
import DataTableRow from './Row/DataTableRow'

import styles from './DataTable.module.scss'

export type DataTableAction = {
  /** (Optional) Function to determine whether the action should show always or only on hover. Default: () => false */
  alwaysShow?: (item: any) => boolean
  buttonType?: ButtonType
  /** Function to call when action is clicked. */
  callback: (item: any) => void
  /** (Optional) Function to determine if action is allowed and should be rendered. Default: () => true */
  condition?: (item: any) => boolean
  /** UI for action, can be text string or React Node */
  title: React.ReactNode
}

export type DataTableProps = {
  actions?: DataTableAction[]
  className?: string
  /**
   * A getter function for parsing the React key for each row. Generally speaking, the item's ID should be more than sufficient.
   */
  getItemKey: (item: any) => string
  /** (Optional) List of CSS class names to apply to each header column at the same index. */
  headerClasses?: string[]
  /**
   * The list of static values to use for the title of each column.
   *
   * Can be any valid React element. Note that these values directly determine
   * the number of columns that will be rendered in the header.
   */
  headerValues: React.ReactNode[]
  /** Whether the rows in this table should have a hover state */
  hoverable?: boolean
  /** (Optional) Component global loading state. Still renders header. Default: false */
  isLoading?: boolean
  /** The collection of items to display in the table. */
  items: any[]
  /** List of item IDs which should show "loading" state */
  loadingItemIds?: string[]
  /** (Optional) List of CSS class names to apply to each row column at the same index. */
  rowClasses?: string[]
  /** Callback to specify whether the row should be "disabled" (grayed out, no hover state) */
  rowDisabledCondition?: (item: any) => boolean
  /**
   * The list of functions to be used to parse the values for each row column.
   *
   * Each function must take 1 arg (`item: any`) and return a string from it, based on the data
   * that should be displayed in the column at that index.
   *
   * Note that these values directly determine the number of columns that will be rendered for each row.
   */
  rowValueGetters: ((item: any) => React.ReactNode)[]
}

const DataTable: React.FunctionComponent<DataTableProps> = ({
  actions = [],
  className,
  getItemKey,
  headerClasses = [],
  headerValues,
  hoverable,
  isLoading = false,
  items,
  loadingItemIds = [],
  rowClasses = [],
  rowDisabledCondition,
  rowValueGetters,
}) => {
  const disableRowActions = loadingItemIds.length > 0

  return (
    <div className={classNames(styles.dataTable, className)}>
      <DataTableHeader
        classes={headerClasses}
        hasActions={!!actions.length}
        values={headerValues}
      />
      {isLoading ? (
        <Loader transparent={true} />
      ) : (
        items.map(item => (
          <DataTableRow
            actions={actions}
            classes={rowClasses}
            disableActions={disableRowActions}
            hoverable={hoverable || !!actions.length}
            isDisabled={rowDisabledCondition}
            item={item}
            key={getItemKey(item)}
            loading={loadingItemIds.includes(item.id)}
            valueGetters={rowValueGetters}
          />
        ))
      )}
    </div>
  )
}

/**
 * A component for displaying tabular data. It has our app's general styles baked in, but also exposes
 * props for some degree of customizability for header and row styles. (For more info, see the docs in Header and Row components.)
 *
 * Note that, in spite of the name, this does not actually use any native html `table` elements. It is simply
 * a set of `divs` arranged in tabular fashion.
 */
export default React.memo(DataTable)
