import styled from '@emotion/styled'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Alert, Button, InputField } from 'packages/common'
import { colors } from 'packages/styles'

import { authService } from './auth.service'
import { setFeatureBranchToken } from './utils'

const St = {
  Alert: styled(Alert)`
    margin: 16px 0 0 0;
  `,
  Button: styled(Button)`
    margin-top: 24px;
  `,
  Container: styled.form`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100%;
    align-items: center;
  `,
  Header: styled.div`
    color: ${colors.midnight};
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    padding: 48px 16px 20px 16px;
    text-align: center;
  `,
  Label: styled.label`
    color: ${colors.dusk};
    display: block;
    font-size: 14px;
    font-weight: 800;
    padding-bottom: 8px;
    text-transform: uppercase;
  `,
  Section: styled.section`
    padding: 24px 16px 0px 16px;
  `,
}

type FeatureBranchLoginPageProps = {
  isHub?: boolean
}

export const FeatureBranchLoginPage: React.FC<FeatureBranchLoginPageProps> = ({
  isHub,
}) => {
  const navigate = useNavigate()
  const [error, setError] = React.useState<string | null>(null)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const formData = new FormData(form)

    const data = Object.fromEntries(formData.entries())
    const featureBranchTokenResponse =
      await authService.fetchFeatureBranchToken(
        data.email as string,
        data.password as string,
        isHub ? true : false,
      )
    if (
      featureBranchTokenResponse !== undefined &&
      featureBranchTokenResponse['detail'] !== undefined
    ) {
      setError(featureBranchTokenResponse['detail'])
    } else {
      setError(null)
      setFeatureBranchToken(featureBranchTokenResponse ?? '')
      navigate('/')
      window.location.reload()
    }
  }

  return (
    <St.Container onSubmit={handleSubmit}>
      <St.Header>Feature Branch Login</St.Header>
      <St.Section>
        <St.Label htmlFor="email">User e-mail</St.Label>
        <InputField id="email" name="email" />
      </St.Section>
      <St.Section>
        <St.Label htmlFor="password">Password</St.Label>
        <InputField id="password" type="password" name="password" />
      </St.Section>
      {error !== null && <St.Alert alertType="danger">{error}</St.Alert>}
      <St.Button
        isFormSubmit={true}
        dataTestId="feature-branch-login-submit-button"
      >
        Login
      </St.Button>
    </St.Container>
  )
}
