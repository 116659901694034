import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { CoverageApiResponse, CoverageServiceResponse } from './coverage.types'
import { emptyCoverageResponse } from './coverage.utils'

const URL = '/coverage'

export const coverageService = {
  async fetchCoverage(params: RequestOptions): CoverageServiceResponse {
    const response: CoverageApiResponse = await api.get(URL, { params })

    return response?.data || emptyCoverageResponse
  },
}
