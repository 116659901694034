import get from 'lodash/get' // eslint-disable-line
import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'

const getZonesState = (state: ApplicationState) => state.zones

export const getZoneError = createSelector(getZonesState, zonesState =>
  get(zonesState, 'error.response.data.errors[0].detail', 'unknown'),
)
