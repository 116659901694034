import { Action } from 'typesafe-actions'

export type Dispatch = (action: Action) => void
export type GetState<StateType> = () => StateType

export type SocketEvent = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  event_name: EventTypes
  properties: Record<string, unknown>
}

// Named values for the various WS events the API can send in relation to Tasks.
// Note that the string values here are mapped directly to the event_name field that the API populates,
// so it is important that we do not change these unless the API changes.
export enum EventTypes {
  TASK_COMPLETED = 'Task Completed',
  TASK_CREATED = 'Task Created',
  TASK_DEACTIVATED = 'Task Deactivated',
  TASK_READY = 'Task Ready',
  TASK_STARTED = 'Task Started',
}

export type EventHandler<StateType> = (
  data,
) => (dispatch: Dispatch, getState: GetState<StateType>) => void

// https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
export enum CloseCodes {
  CLOSE_NORMAL = 1000,
  GOING_AWAY = 1001,
  PROTOCOL_ERROR = 1002,
  ABNORMAL_CLOSURE = 1006,
}

export enum ReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}
