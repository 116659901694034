import React from 'react'
import { useSelector } from 'react-redux'

import { isWithinFreezeWindow } from 'packages/grimoire'

import { getAssignedHousekeepers } from 'app/hkhub/store/housekeepers/selectors/getAssignedHousekeepers'
import { ApplicationState } from 'app/hkhub/store/store'
import { Visit } from 'app/hkhub/store/visits'

import { AssignmentSection } from '../../../CleanDrawer/CleanAssignments/AssignmentSection'
import { VisitAssignmentEditorContainer } from './VisitAssignmentEditor.container'

export type VisitAssignmentsProps = {
  visit: Visit
}

export const VisitAssignments: React.FC<VisitAssignmentsProps> = React.memo(
  ({ visit }) => {
    const [isEditing, setIsEditing] = React.useState(false)

    const canEdit = React.useMemo(() => {
      // There should not be any visits outside of the freeze window, but just in case we can prevent them from being edited
      return !visit.completedAt && isWithinFreezeWindow(visit.effectiveDate)
    }, [visit])

    const assignedHks = useSelector((state: ApplicationState) =>
      getAssignedHousekeepers(state, visit),
    )

    return isEditing ? (
      <VisitAssignmentEditorContainer
        onCancelEditClick={() => setIsEditing(false)}
        visit={visit}
      />
    ) : (
      <AssignmentSection
        assignedHks={assignedHks}
        editable={canEdit}
        onEditClick={() => setIsEditing(true)}
      />
    )
  },
)
