import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

const getNotificationsState = (state: ApplicationState) => state.notifications

export const getAllNotificationsCount = createSelector(
  getNotificationsState,
  notificationState => {
    return Object.keys(notificationState.data).length
  },
)
