import { xor } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { apiFilterExcludeCleans } from 'packages/grimoire'
import { format } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { VisitApiFields, VisitsActionTypes } from '../visits.types'

// omit inspectionChecklist from the "all visits" request for performance reasons
const visitFields = xor(['inspection_checklist'], VisitApiFields)

export const fetchVisitsByZoneAndDateAction = createAsyncAction(
  VisitsActionTypes.FETCH_VISITS_BY_ZONE_AND_DATE,
  VisitsActionTypes.FETCH_VISITS_BY_ZONE_AND_DATE_SUCCESS,
  VisitsActionTypes.FETCH_VISITS_BY_ZONE_AND_DATE_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getParams = (
  zoneId: string,
  startDate: Date,
  endDate?: Date,
): RequestOptions => {
  const effectiveDate = endDate
    ? {
        range: [format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')],
      }
    : format(startDate, 'yyyy-MM-dd')

  return {
    fields: {
      task: visitFields,
      unit: [],
    },
    filter: {
      effective_date: effectiveDate,
      is_active: true,
      'unit.zone': zoneId,
      ...apiFilterExcludeCleans,
    },
    include: ['assigned_housekeepers', 'unit'],
    page: { size: 1500 },
  }
}

export const fetchVisitsByZoneAndDate = (
  zoneId: string,
  startDate: Date,
  endDate?: Date,
) => {
  return async dispatch => {
    try {
      const params = getParams(zoneId, startDate, endDate)
      const request = tasksService.fetchTasks.bind(null, params)
      const result = await dispatch(
        fetchVisitsByZoneAndDateAction.request({ request }),
      )
      dispatch(fetchVisitsByZoneAndDateAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchVisitsByZoneAndDateAction.failure(error))
      throw error
    }
  }
}
