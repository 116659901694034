import { logInfo } from 'packages/wiretap/logging'

import { ApplicationState } from '../../store'
import { Lockbox } from '../lockboxes.types'
import { getLockboxById } from './getLockboxById'

export const getLockboxByUnitId = (
  state: ApplicationState,
  unitId: string,
): Lockbox | undefined => {
  let lockboxIdForUnit
  try {
    lockboxIdForUnit = state.units.data[unitId]?.relationships?.lockBox?.data.id
  } catch (e) {
    logInfo('Missing Lockbox Data On Unit', {
      unit: { [unitId]: JSON.stringify(state.units.data[unitId]) },
    })

    return undefined
  }

  if (!lockboxIdForUnit) return undefined

  return getLockboxById(state, lockboxIdForUnit)
}
