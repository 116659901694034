import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { getIdAndDataTypeFromAction } from 'packages/utils/store/store.utils'

import { clearZoneAction } from 'app/hkhub/store/zones/actions'

import { RawReservation } from '../reservations'
import { fetchTaskByIdAction, wsTaskDeactivatedAction } from '../tasks/actions'
import { emptyNormalizedTasksData } from '../tasks/tasks.utils'
import { fetchCleanAction, fetchCleansByZoneAndDateAction } from './actions'
import { CleansState, RawClean } from './cleans.types'
import { isRawClean } from './cleans.utils'

const DEFAULT_ERROR = new Error('Unknown Error in cleansReducer')

export const initialState: CleansState = {
  assignmentChanges: {},
  data: {},
  error: undefined,
  isLoading: false,
  reservation: {},
}

const actions = {
  clearZoneAction,
  fetchCleanAction,
  fetchCleansByZoneAndDateAction,
  fetchTaskByIdAction,
  wsTaskDeactivatedAction,
}

type CleansActionsTypes = ActionType<typeof actions>

export const cleansReducer = (
  state = initialState,
  action: CleansActionsTypes,
): CleansState =>
  produce(state, (draft: CleansState) => {
    switch (action.type) {
      case getType(actions.fetchCleansByZoneAndDateAction.request): {
        draft.isLoading = true
        return
      }

      case getType(actions.fetchCleansByZoneAndDateAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedTasksData

        Object.values(normalized.task || {}).forEach(task => {
          if (isRawClean(task)) {
            draft.data[task.id] = task
          }
        })

        draft.reservation = {
          ...state.reservation,
          ...(normalized.reservation || {}),
        }

        draft.isLoading = false
        return
      }

      case getType(actions.fetchTaskByIdAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedTasksData

        // store the reservation nested in cleans.reservation.
        // this is also handled in the reservations reducer itself, but unfortunately
        // we need to follow this deprecated pattern for backwards compatibility
        Object.entries(normalized.reservation || {}).forEach(([id, res]) => {
          draft.reservation[id] = merge(state?.reservation[id] || {}, res)
        })

        const [cleanId, clean] = getIdAndDataTypeFromAction<RawClean>(
          action,
          'task',
        )

        if (!!clean && isRawClean(clean)) {
          draft.data[cleanId] = clean
        }

        draft.isLoading = false
        return
      }

      case getType(actions.fetchCleanAction.success): {
        const [cleanId, clean] = getIdAndDataTypeFromAction<RawClean>(
          action,
          'task',
        )
        const [resId, res] = getIdAndDataTypeFromAction<RawReservation>(
          action,
          'reservation',
        )

        if (cleanId) {
          draft.data[cleanId] = clean
          draft.reservation[resId] = res
        }

        draft.assignmentChanges = {
          ...state.assignmentChanges,
          ...(action.payload.normalized.assignmentChange || {}),
        }

        draft.isLoading = false
        return
      }

      case getType(actions.fetchCleanAction.failure):
      case getType(actions.fetchCleansByZoneAndDateAction.failure): {
        draft.isLoading = false
        draft.error = action?.payload || DEFAULT_ERROR
        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.data = {}
        draft.reservation = {}
        return
      }

      /**************************************************
       * WebSockets Events
       **************************************************/
      case getType(actions.wsTaskDeactivatedAction): {
        const taskId = action?.payload?.taskId
        if (taskId) {
          delete draft.data[taskId]
        }

        return
      }
    }
  })
