import { JSONApiObject } from 'packages/utils/store/jsonapi.types'
import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import {
  ReservationAttributes,
  Reservation,
  ReservationAttributeNames,
} from '../reservations.types'

export const transformNormalizedToReservation = (
  normalizedReservation: JSONApiObject<ReservationAttributes>,
): Reservation => {
  return transformNormalizedToTyped<Reservation>(
    normalizedReservation,
    ReservationAttributeNames,
  )
}
