import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { hydrateRawAssignmentChange } from '../cleans.utils'

export const getAssignmentChangeById = createSelector(
  (state: ApplicationState) => state.cleans.assignmentChanges,
  (_, id: string) => id,
  (assignmentChangesState, id) => {
    const rawAssignmentChange = assignmentChangesState[id]

    if (!rawAssignmentChange) return undefined

    return hydrateRawAssignmentChange(rawAssignmentChange)
  },
)
