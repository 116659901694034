import snakeCase from 'lodash/snakeCase'

import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  ToOneRelationship,
} from '../utils'

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
export enum HkEmployeeType {
  agency = 'agency',
  contractor = 'contractor',
  employee = 'employee',
}

/**********************************************************
 * HOUSEKEEPER
 *********************************************************/
export type Housekeeper = {
  id: string
} & HousekeeperAttributes

export type HousekeeperAttributes = {
  employeeType: HkEmployeeType
}

export const HousekeeperAttributeNames = ['employeeType']

export const HousekeeperApiFields: string[] =
  HousekeeperAttributeNames.map(snakeCase)

export type HousekeeperRelationships = {
  user: ToOneRelationship
}

export type RawHousekeeper = JSONApiObjectWithRelationships<
  HousekeeperAttributes,
  HousekeeperRelationships
>

export type JSONApiHousekeepersMap = JSONApiObjectWithRelationshipsMap<
  HousekeeperAttributes,
  HousekeeperRelationships
>
