import { AlertType } from 'packages/common'
import {
  createDateObject,
  DateFormat,
  differenceInDays,
  differenceInMonths,
  formatLocalized,
} from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'

export type UpNextConfig = {
  alertText: string
  alertType: AlertType
  subText: string
}

/**
 * Builds the config for displaying "up next" data for deep cleans.
 * This includes both styling an alert and multiple formatted date strings.
 * @param date
 */
export const useDeepCleanUpNextConfig = (
  date: string | null,
): UpNextConfig | null => {
  const { tp } = useI18n()

  if (!date) {
    return null
  }

  const TODAY = createDateObject()
  const dayDiff = differenceInDays(date, TODAY)
  const formattedDate = formatLocalized(date, DateFormat.SlashesWithShortYear)

  if (dayDiff < 0) {
    return {
      alertText: `Overdue since ${formattedDate}`,
      alertType: 'danger',
      subText: tp(Slugs.youAreOverdue, Math.abs(dayDiff), false),
    }
  } else if (dayDiff <= 30) {
    return {
      alertText: `Due By ${formattedDate}`,
      alertType: 'warning',
      subText: tp(Slugs.youHaveTimeLeft, dayDiff, false),
    }
  }

  const monthDiff = Math.max(differenceInMonths(date, TODAY), 1)
  return {
    alertText: `Due By ${formattedDate}`,
    alertType: 'info',
    subText: tp(Slugs.youHaveTimeUntil, monthDiff, false),
  }
}
