import React from 'react'
import { useSelector } from 'react-redux'

import { useCurrentCardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Clean, CleanServiceType } from 'app/hkhub/store/cleans'
import { getHkAvailabilityForDate } from 'app/hkhub/store/hkAvailabilities/selectors'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { ApplicationState } from 'app/hkhub/store/store'

import {
  StaffScheduleTasks,
  StaffScheduleTasksProps,
} from './StaffScheduleTasks'

export const sortByB2b = (clean1: Clean, clean2: Clean): number => {
  const b2bBadgeServiceTypes = [
    CleanServiceType.b2b,
    CleanServiceType.potentialB2B,
  ]
  const isClean1B2b = b2bBadgeServiceTypes.includes(clean1.serviceType)
  const isClean2B2b = b2bBadgeServiceTypes.includes(clean2.serviceType)

  if (isClean1B2b && isClean2B2b) return 0
  if (isClean1B2b) return -1
  if (isClean2B2b) return 1
  return 0
}

type StaffScheduleTasksContainerProps = Omit<
  StaffScheduleTasksProps,
  'cardSize' | 'isAvailable'
> & {
  hk: Housekeeper
}

export const StaffScheduleTasksContainer: React.FC<
  StaffScheduleTasksContainerProps
> = props => {
  const { cleans, coverage, date, hk, breakpoint } = props
  const cardSize = useCurrentCardSize()

  // do we have availability for this HK/Date combo?
  // (no HK in this cell means it is an unassigned clean, thus "available" will always be true)
  // note that getHkAvailabilityForDate() returns a full availability object, but for our
  // current requirements, we only need yes/no, hence casting it to Boolean
  const isAvailable = !!useSelector((state: ApplicationState) =>
    getHkAvailabilityForDate(state, hk.id, date),
  )

  const isUnassigned = hk.id === 'unassigned'

  const sortedCleans = cleans.sort(sortByB2b)

  return (
    <StaffScheduleTasks
      {...props}
      breakpoint={breakpoint}
      cardSize={cardSize}
      cleans={sortedCleans}
      coverage={coverage}
      isAvailable={isAvailable || isUnassigned}
    />
  )
}
