import * as React from 'react'

export type ServiceWorkerConfig = {
  /** (Optional) Callback to call after new assets have been downloaded */
  onUpdateAvailable?: (registration: ServiceWorkerRegistration) => void
}

export type ServiceWorkerProps = {
  /** The "register serviceWorker" to initialize the ServiceWorker instance */
  registerSW: (config?: ServiceWorkerConfig) => void
  /**
   * (Optional) Node to render to notify user that a new version of the app has been downloaded/cached.
   * e.g. "New version available! Please refresh your browser!"
   */
  updateNotifier?: React.ReactNode
} & ServiceWorkerConfig

export const ServiceWorkerWrapper: React.FC<ServiceWorkerProps> = React.memo(
  ({ onUpdateAvailable, registerSW, updateNotifier }) => {
    const [updateAvailable, setUpdateAvailable] = React.useState(false)

    React.useEffect(() => {
      registerSW({
        onUpdateAvailable: registration => {
          if (onUpdateAvailable) onUpdateAvailable(registration)
          setUpdateAvailable(true)
        },
      })
    }, [onUpdateAvailable, registerSW])

    return <>{updateAvailable && updateNotifier}</>
  },
)
