import { logInfo } from 'packages/wiretap/logging'

const SERVICE_URL = process.env.REACT_APP_SERVICE_HOST
const TOKEN_URL = `${SERVICE_URL}/impersonation_token`

export const authService = {
  fetchImpersonationToken: async (
    userId: string,
    authToken: string,
  ): Promise<string | undefined> => {
    const handleFailure = (error: string) => {
      logInfo('Error fetching impersonation token', { error })
      throw new Error('Error fetching impersonation token')
    }

    try {
      const response = await fetch(TOKEN_URL, {
        body: `user_id=${userId}&is_hub=true`,

        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },

        method: 'POST',
      })

      const responseBody = await response.json()

      if (!response.ok) {
        handleFailure(responseBody?.error || 'unknown')
      }

      return responseBody.access_token
    } catch (error) {
      handleFailure(error)
    }
  },
}
