import { useDispatch } from 'react-redux'

import { NonNullableFields } from 'packages/grimoire/src/utils'

import { AppDispatch } from 'app/hkhub/store/store'
import { setTicketDrawerState } from 'app/hkhub/store/ui/actions/setTicketDrawerState'

import { useTicket, UseTicket } from '../../../VisitTicketCard/hooks/useTicket'

export const useNonnullableTicket = (
  ticketId: string,
): NonNullableFields<UseTicket> => {
  const value = useTicket(ticketId)

  const dispatch: AppDispatch = useDispatch()

  if (!value?.ticket || !value?.unit || !value?.assignee) {
    // The drawer container should handle this, if we get here something is VERY wrong but we should try and close the drawer
    dispatch(
      setTicketDrawerState({
        isOpen: false,
        ticketId: '',
      }),
    )

    // This is obviously a very incorrect type cast, but things are so wrong at this point that if this causes a crash, that might be a good thing
    return value as unknown as NonNullableFields<UseTicket>
  }

  return value as NonNullableFields<UseTicket>
}
