import React from 'react'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Lockbox } from 'app/hkhub/store/lockboxes'
import { Unit } from 'app/hkhub/store/units/units.types'

import { AccessCodes, HkNotes, WifiInfo } from '../../components'
import { UnitDrawerStyles } from '../../UnitDrawer.styles'

const St = {
  Divider: UnitDrawerStyles.Divider,
  Section: UnitDrawerStyles.Section,
  Title: UnitDrawerStyles.SectionTitle,
}

export type UnitInfoSectionProps = {
  lockbox?: Lockbox
  unit: Unit
}

export const UnitInfoSection: React.FC<UnitInfoSectionProps> = React.memo(
  ({ lockbox, unit }) => {
    const { t } = useI18n()
    return (
      <>
        <St.Section>
          <St.Title>{t(Slugs.unitInfo)}</St.Title>
          <AccessCodes lockbox={lockbox} unit={unit} />
          <WifiInfo amenities={unit.amenities} />
          <HkNotes unit={unit} />
        </St.Section>
        <St.Divider />
      </>
    )
  },
)
