import React from 'react'
import { ToastContainer } from 'react-toastify'

import styles from './ToastCustomContainer.module.css'

export const ToastCustomContainer = React.memo(() => (
  <ToastContainer
    bodyClassName={styles.body}
    progressClassName={styles.progress}
    toastClassName={styles.toast}
  />
))
