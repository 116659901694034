import { EventTypes } from 'packages/webSocketEvents'

import { wsTaskUpdated, wsTaskDeactivated } from './tasks/actions'

const events = {
  [EventTypes.TASK_COMPLETED]: wsTaskUpdated,
  [EventTypes.TASK_CREATED]: wsTaskUpdated,
  [EventTypes.TASK_DEACTIVATED]: wsTaskDeactivated,
  [EventTypes.TASK_READY]: wsTaskUpdated,
  [EventTypes.TASK_STARTED]: wsTaskUpdated,
}

export default events
