import styled from '@emotion/styled'

import { colors, layers, shadows } from 'packages/styles/jss'

export const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: max-content;
`

const ARROW_SIZE = 5
const ARROW_MARGIN = 2
const BG_COLOR = colors.dusk70

const BaseTooltip = styled.div<{ visible: boolean }>`
  background-color: ${BG_COLOR};
  border-radius: 6px;
  box-shadow: ${shadows.shadow2};
  color: white;
  font-size: 12px;
  font-weight: 600;
  max-width: 200px;
  padding: 8px 12px;
  pointer-events: none;
  position: absolute;
  transition: opacity 200ms 125ms;
  width: max-content;
  z-index: ${layers.tooltip};

  opacity: ${({ visible }) => (visible ? 1 : 0)};

  &::after {
    border-style: solid;
    border-width: ${ARROW_SIZE}px;
    content: ' ';
    position: absolute;
  }
`

export const BottomTooltip = styled(BaseTooltip)`
  left: 50%;
  top: calc(100% + ${ARROW_SIZE}px + ${ARROW_MARGIN}px);
  transform: translateX(-50%);

  &::after {
    border-color: transparent transparent ${BG_COLOR} transparent;
    bottom: 100%;
    left: 50%;
    margin-left: -${ARROW_SIZE}px;
  }
`

export const LeftTooltip = styled(BaseTooltip)`
  right: calc(100% + ${ARROW_SIZE}px + ${ARROW_MARGIN}px);
  top: 50%;
  transform: translateY(-50%);

  &::after {
    border-color: transparent transparent transparent ${BG_COLOR};
    left: 100%;
    margin-top: -${ARROW_SIZE}px;
    top: 50%;
  }
`

export const RightTooltip = styled(BaseTooltip)`
  left: calc(100% + ${ARROW_SIZE}px + ${ARROW_MARGIN}px);
  top: 50%;
  transform: translateY(-50%);

  &::after {
    border-color: transparent ${BG_COLOR} transparent transparent;
    margin-top: -${ARROW_SIZE}px;
    right: 100%;
    top: 50%;
  }
`

export const TopTooltip = styled(BaseTooltip)`
  bottom: calc(100% + ${ARROW_SIZE}px + ${ARROW_MARGIN}px);
  left: 50%;
  transform: translateX(-50%);

  &::after {
    border-color: ${BG_COLOR} transparent transparent transparent;
    left: 50%;
    margin-left: -${ARROW_SIZE}px;
    top: 100%;
  }
`
