import { noop } from 'lodash/fp'
import React from 'react'
import { DayPicker } from 'react-day-picker'

import { colors } from 'packages/styles'

import 'react-day-picker/dist/style.css'

export type DatePickerProps = {
  disableAfterDate?: Date
  disableBeforeDate?: Date
  onDateChange: (date: Date | undefined) => void
  selectedDate: Date | undefined
}

export const DatePicker: React.FC<DatePickerProps> = React.memo(
  ({
    disableAfterDate,
    disableBeforeDate,
    onDateChange = noop,
    selectedDate,
  }) => {
    return (
      <DayPicker
        fromDate={disableBeforeDate}
        mode={'single'}
        modifiersStyles={{ selected: { backgroundColor: colors.lake } }}
        onSelect={onDateChange}
        selected={selectedDate}
        toDate={disableAfterDate}
      />
    )
  },
)
