import { snakeCase } from 'lodash/fp'

import { Housekeeper } from '../housekeeper'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  StringKeyToNumberMap,
  ToOneRelationship,
} from '../utils'

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
export type AssignmentToNumberMap = StringKeyToNumberMap

/**********************************************************
 * ASSIGNMENT
 *********************************************************/
export type Assignment = {
  housekeeper: Housekeeper
  id: string
} & AssignmentAttributes

// Rejection Patch types
export type AssignmentRejectionReason = 'other' | 'day_off' | 'too_busy'

export type AssignmentAttributes = {
  payRate: string | null
  rejectionReason?: AssignmentRejectionReason
}

export const AssignmentAttributeNames: string[] = ['payRate', 'rejectionReason']

export const AssignmentApiFields: string[] =
  AssignmentAttributeNames.map(snakeCase)

export type AssignmentRelationships = {
  clean: ToOneRelationship
  housekeeper: ToOneRelationship
}

export type RawAssignment = JSONApiObjectWithRelationships<
  AssignmentAttributes,
  AssignmentRelationships
>

export type JSONApiAssignmentsMap = JSONApiObjectWithRelationshipsMap<
  AssignmentAttributes,
  AssignmentRelationships
>
