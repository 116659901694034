import styled from '@emotion/styled'

import { colors } from 'packages/styles'

export const CheckboxContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  position: relative;

  & + & {
    margin-top: 8px;
  }
`

export const CheckboxInput = styled.input`
  // hide native <input> so we can show our styled one instead
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
`

export const CheckboxLabel = styled.label<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 16px;
  padding-left: 28px;
`

export const CheckboxError = styled.div`
  color: ${colors.alert};
`

export const CustomCheckbox = styled.span<{ error?: string }>`
  // basic styling for the custom checkbox area
  background-color: ${colors.midnight2};
  border-radius: 3px;
  border: ${({ error }) => (error ? colors.alert : colors.midnight30)} solid 2px;
  box-sizing: border-box;
  height: 18px;
  left: 0;
  position: absolute;
  top: 2px;
  transition: background-color 125ms ease, border-color 125ms ease;
  width: 18px;
  will-change: background-color, border-color;

  // change checkbox background to "checked" styling when the input is checked
  input:checked ~ & {
    background-color: ${({ error }) => (error ? colors.alert : colors.lake)};
    border-color: ${({ error }) => (error ? colors.alert : colors.lake)};
  }

  // reduce opacity for full component when input is disabled
  input:disabled ~ & {
    opacity: 0.5;
  }

  // styling for the checkmark "icon" within the box
  // (effectively a rotated rect with 2 borders missing)
  &:after {
    border: solid white;
    content: '';
    height: 8px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 1px;
    transform: rotate(45deg);
    transition: opacity 125ms ease;
    width: 4px;
    will-change: opacity;

    border-width: 0 2px 2px 0;
  }

  // show the checkmark itself when the input is checked
  input:checked ~ &:after {
    opacity: 1;
  }
`
