import * as React from 'react'

type UseOnlineStatusProps = {
  onStatusChange?: (newStatus: boolean) => void
}

type UseOnlineStatus = {
  isOnline: () => boolean
}

/**
 * A simple hook to notify the consumer anytime the browser's online status changes.
 */
export const useOnlineStatus = ({
  onStatusChange,
}: UseOnlineStatusProps = {}): UseOnlineStatus => {
  const isInitialRender = React.useRef(true)
  const [online, setOnline] = React.useState(() => navigator.onLine)

  const onOnline = () => setOnline(true)
  const onOffline = () => setOnline(false)
  const isOnline = React.useCallback(() => online, [online])

  React.useEffect(() => {
    // do not call the callback on initial render; only when the status actually changes
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    if (onStatusChange) {
      onStatusChange(online)
    }
  }, [onStatusChange, online])

  React.useEffect(() => {
    window.addEventListener('online', onOnline)
    window.addEventListener('offline', onOffline)

    return () => {
      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  }, [])

  return { isOnline }
}
