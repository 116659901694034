import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { Assignment } from '../assignments.types'
import { hydrateRawAssignment } from '../assignments.utils'

const getFullState = (state: ApplicationState): ApplicationState => state
const getAssignmentId = (_state: ApplicationState, id: string): string => id

export const getAssignmentById = createSelector(
  getFullState,
  getAssignmentId,
  (state, assignmentId): Assignment | undefined => {
    const rawAssignment = state.assignments?.data?.[assignmentId]

    return rawAssignment && hydrateRawAssignment(rawAssignment)
  },
)
