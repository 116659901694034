import React from 'react'

import { Button } from 'packages/common'
import { useManageDrawerState } from 'packages/common'
import { useBreakpoint } from 'packages/styles'

import {
  ScheduleControlsContainer,
  ScheduleControlsContent,
} from 'app/hkhub/components/schedule/components/common/ScheduleControls/ScheduleControls.styles'

import { CreateZoneDrawer } from '../CreateZoneDrawer'

const St = {
  AdminControlBar: ScheduleControlsContainer,
  AdminControlBarContent: ScheduleControlsContent,
}

export const AdminControlBar: React.FC = React.memo(() => {
  const breakpoint = useBreakpoint()

  const drawerStateProps = useManageDrawerState()
  const { completeDrawerClose, drawerState, openDrawer } = drawerStateProps

  return (
    <St.AdminControlBar>
      <St.AdminControlBarContent breakpoint={breakpoint}>
        <strong>Manage Zones</strong>
        <div>
          <Button onClick={openDrawer} buttonType={'utilityInverted'}>
            Create new zone
          </Button>
        </div>
      </St.AdminControlBarContent>

      <CreateZoneDrawer
        afterExit={completeDrawerClose}
        isOpen={drawerState.isOpen}
      />
    </St.AdminControlBar>
  )
})
