import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash/fp'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { makeRelationship, RequestOptions } from 'packages/utils/store'

import { fetchUnitByIdStrict } from '../../units/actions'
import { customInspectionItemsService } from '../customInspectionItems.service'
import {
  CustomInspectionItemPostApiAttributes,
  CustomInspectionItemPostData,
  CustomInspectionItemRelationships,
} from '../customInspectionItems.types'

export const buildRequestData = (
  postData: CustomInspectionItemPostData,
): RequestOptions => {
  const { itemTitle, unitId } = postData

  const attributes: CustomInspectionItemPostApiAttributes = {
    item_title: itemTitle,
  }

  const relationships: CustomInspectionItemRelationships = {
    unit: makeRelationship('unit', unitId),
  }

  return {
    data: {
      attributes,
      relationships,
      type: 'custom_inspection_item',
    },
  }
}

type CreateCustomInspectionItemThunkArgs = {
  callbacks: ReduxActionCallbacks
  postData: CustomInspectionItemPostData
}

export const createCustomInspectionItem = createAsyncThunk(
  'customInspectionItems/api/createCustomInspectionItem',
  async (args: CreateCustomInspectionItemThunkArgs, { dispatch }) => {
    const { postData, callbacks } = args
    const { onError = noop, onSuccess = noop } = callbacks
    const requestData = buildRequestData(postData)
    try {
      const result =
        await customInspectionItemsService.createCustomInspectionItem(
          requestData,
        )

      // re-fetch the unit, so we can get the updated custom checklist items
      await dispatch(fetchUnitByIdStrict(postData.unitId))

      onSuccess(result)
      return result
    } catch (error) {
      onError(error)
    }
  },
)
