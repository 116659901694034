import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  CustomInspectionItemsApiResponse,
  CustomInspectionItemsResponse,
  CustomInspectionItemsServiceResponse,
} from './customInspectionItems.types'

const URL = '/custom_inspection_items'

export const customInspectionItemsService = {
  async createCustomInspectionItem(
    data: RequestOptions,
  ): CustomInspectionItemsServiceResponse {
    const response: CustomInspectionItemsResponse = await api.post(URL, data)
    return response?.data
  },

  async updateCustomInspectionItem(
    itemId: string,
    patchData: RequestOptions,
  ): CustomInspectionItemsServiceResponse {
    const response: CustomInspectionItemsApiResponse = await api.patch(
      `${URL}/${itemId}`,
      patchData,
    )

    return response?.data || {}
  },

  async deleteCustomInspectionItem(
    id: string,
  ): CustomInspectionItemsServiceResponse {
    const response: CustomInspectionItemsApiResponse = await api.delete(
      `${URL}/${id}`,
    )
    return response?.data || {}
  },
}
