import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAsyncFn } from 'react-use'

import { useToast } from 'packages/common'
import { useI18n } from 'packages/i18n'

import { Slugs } from 'app/hkhub/i18n'
import { createCustomInspectionItem } from 'app/hkhub/store/customInspectionItems/actions/createCustomInspectionItem'
import { deleteCustomInspectionItem } from 'app/hkhub/store/customInspectionItems/actions/deleteCustomInspectionItem'
import { updateCustomInspectionItem } from 'app/hkhub/store/customInspectionItems/actions/updateCustomInspectionItem'
import { getUnitDrawerCustomInspectionItems } from 'app/hkhub/store/customInspectionItems/selectors/getUnitDrawerCustomInspectionItems'
import { AppDispatch } from 'app/hkhub/store/store'
import { Unit } from 'app/hkhub/store/units'

import {
  createCustomInspectionItemReducer,
  getInitialCustomInspectionItemsState,
} from './customInspectionItemsState/customInspectionItemsForm.reducer'
import { VisitSection } from './VisitSection'

type VisitSectionContainerProps = {
  unit: Unit
}

export type CustomInspectionItemActionHandlersType = {
  addItem: () => void
  cancel: () => void
  cancelDelete: () => void
  clickDelete: () => void
  edit: (payload: string) => void
  setTitle: (payload: string) => void
}

export type CustomInspectionItemApiCallHandlersType = {
  createItem: (itemTitle: string, unitId: string) => void
  deleteItem: (id: string, unitId: string) => void
  updateItem: (id: string, title: string, unitId: string) => void
}

export type CustomInspectionItemApiCallHandlersStateType = {
  createItemIsLoading: boolean
  deleteItemIsLoading: boolean
  updateItemIsLoading: boolean
}

export const VisitSectionContainer: React.FC<VisitSectionContainerProps> =
  React.memo(({ unit }) => {
    const dispatch: AppDispatch = useDispatch()
    const { showToast } = useToast()
    const { t } = useI18n()

    const customInspectionItems = useSelector(
      getUnitDrawerCustomInspectionItems,
    )

    const [state, dispatchCustomInspectionItemFn] = React.useReducer(
      createCustomInspectionItemReducer,
      getInitialCustomInspectionItemsState(customInspectionItems),
    )

    React.useEffect(() => {
      if (customInspectionItems) {
        dispatchCustomInspectionItemFn({
          payload: customInspectionItems,
          type: 'updateInspectionItems',
        })
      }
    }, [customInspectionItems])

    const setIsAddingNewItem = () =>
      dispatchCustomInspectionItemFn({
        type: 'addInspectionItem',
      })

    const setIsEditing = (payload: string) =>
      dispatchCustomInspectionItemFn({
        payload: payload,
        type: 'editInspectionItem',
      })

    const setTitle = (payload: string) => {
      dispatchCustomInspectionItemFn({
        payload: payload,
        type: 'setTitle',
      })
    }

    const clickDelete = () => {
      dispatchCustomInspectionItemFn({ type: 'clickDeleteButton' })

      dispatchCustomInspectionItemFn({
        payload: true,
        type: 'setShowDeleteWaitIndicator',
      })

      // show loading indicator for a half second
      setTimeout(() => {
        dispatchCustomInspectionItemFn({
          payload: false,
          type: 'setShowDeleteWaitIndicator',
        })
      }, 500)
    }

    const cancelDelete = () =>
      dispatchCustomInspectionItemFn({
        type: 'cancelDeleteItem',
      })

    const cancelCRUDItem = () =>
      dispatchCustomInspectionItemFn({
        type: 'cancelCRUDItem',
      })

    const actionHandlers = {
      addItem: setIsAddingNewItem,
      cancel: cancelCRUDItem,
      cancelDelete: cancelDelete,
      clickDelete: clickDelete,
      edit: setIsEditing,
      setTitle: setTitle,
    }

    const [createFnState, createCustomInspectionItemFn] = useAsyncFn(
      async (title: string, unitId: string) => {
        return dispatch(
          createCustomInspectionItem({
            callbacks: {
              onError: () => {
                showToast({
                  message: t(Slugs.addCustomChecklistItemError),
                  toastType: 'danger',
                })
              },
              onSuccess: () => {
                dispatchCustomInspectionItemFn({ type: 'clearCRUDItem' })
                showToast({
                  message: t(Slugs.addCustomChecklistItemSuccess),
                  toastType: 'success',
                })
              },
            },
            postData: { itemTitle: title, unitId },
          }),
        )
      },
    )

    const [deleteFnState, deleteCustomInspectionItemFn] = useAsyncFn(
      async (id: string, unitId: string) => {
        return dispatch(
          deleteCustomInspectionItem({
            callbacks: {
              onError: () => {
                showToast({
                  message: t(Slugs.deleteCustomChecklistItemError),
                  toastType: 'danger',
                })
              },
              onSuccess: () => {
                dispatchCustomInspectionItemFn({ type: 'clearCRUDItem' })
                showToast({
                  message: t(Slugs.deleteCustomChecklistItemSuccess),
                  toastType: 'success',
                })
              },
            },
            deleteData: {
              itemId: id,
              unitId,
            },
          }),
        )
      },
    )

    const [updateFnState, updateCustomInspectionItemFn] = useAsyncFn(
      async (id: string, title: string, unitId: string) => {
        return dispatch(
          updateCustomInspectionItem({
            callbacks: {
              onError: () => {
                showToast({
                  message: t(Slugs.updateCustomChecklistItemError),
                  toastType: 'danger',
                })
              },
              onSuccess: () => {
                dispatchCustomInspectionItemFn({ type: 'clearCRUDItem' })
                showToast({
                  message: t(Slugs.updateCustomChecklistItemSuccess),
                  toastType: 'success',
                })
              },
            },
            patchData: {
              itemId: id,
              itemTitle: title,
              unitId: unitId,
            },
          }),
        )
      },
    )

    const apiCallHandlers = {
      createItem: createCustomInspectionItemFn,
      deleteItem: deleteCustomInspectionItemFn,
      updateItem: updateCustomInspectionItemFn,
    }

    const apiCallHandlersState = {
      createItemIsLoading: createFnState.loading,
      deleteItemIsLoading: deleteFnState.loading,
      updateItemIsLoading: updateFnState.loading,
    }

    return (
      <VisitSection
        actionHandlers={actionHandlers}
        apiCallHandlers={apiCallHandlers}
        apiCallHandlersState={apiCallHandlersState}
        customInspectionItemsState={state}
        unit={unit}
      />
    )
  })
