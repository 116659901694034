import get from 'lodash/get' // eslint-disable-line
import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from 'app/hkhub/store/store'

import {
  HkAvailabilitiesMap,
  HkAvailabilitiesState,
  HkAvailability,
  HkAvailabilityAttributeNames,
} from '../hkAvailabilities.types'

const getHkAvailabilitiesState = (
  state: ApplicationState,
): HkAvailabilitiesState => state.hkAvailabilities

/**
 * Builds a bucketed map of all current HkAvailability records. You will most likely
 * not want to use this selector directly in the UI, but rather use it as a chained
 * selector, to take advantage of the memoized map of all the data.
 *
 * Note that any dates where an HK has _no_ availability will be completely missing,
 * so if you do not need full details of the availability record, you can simply
 * treat its presence (or lack thereof) as a simple boolean.
 *
 * The final map will look something like:
 * {
 *   [hkId_1]: {
 *     [dateString_1]: HkAvailability for this date
 *     [dateString_2]: HkAvailability for this date
 *   },
 *   [hkId_2]: {
 *     [dateString_1]: HkAvailability for this date
 *     [dateString_2]: HkAvailability for this date
 *   },
 * }
 */
export const getBucketedHkAvailabilities = createSelector(
  getHkAvailabilitiesState,
  (hkAvState): HkAvailabilitiesMap => {
    return Object.values(hkAvState.data).reduce((acc, rawHkAvailability) => {
      const hkId = get(rawHkAvailability, 'relationships.housekeeper.data.id')
      const dateKey = get(rawHkAvailability, 'attributes.date')

      if (hkId && dateKey) {
        acc[hkId] = acc[hkId] || {}
        acc[hkId][dateKey] = transformNormalizedToTyped<HkAvailability>(
          rawHkAvailability,
          HkAvailabilityAttributeNames,
        )
      }

      return acc
    }, {} as HkAvailabilitiesMap)
  },
)
