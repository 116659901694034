import { produce } from 'immer'
import { join, map, pipe, split, startCase } from 'lodash/fp'
import React from 'react'

import { isWithinFreezeWindow } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import {
  createDateObject,
  eachDay,
  isSameDay,
} from 'packages/utils/dateHelpers'

import { getAssignedCleanStatusByDate } from 'app/hkhub/components/schedule/helpers/cleanHelpers'
import { CleanAssignmentStatus } from 'app/hkhub/components/schedule/schedule.types'
import { Slugs } from 'app/hkhub/i18n'
import { Clean, CleanPatchData } from 'app/hkhub/store/cleans'
import { ReservationBookingType } from 'app/hkhub/store/reservations/reservations.types'
import { useCleanUpdate } from 'app/hkhub/utils/useCleanUpdate/useCleanUpdate'

import { CleanInfoEditor, CleanInfoEditorProps } from './CleanInfoEditor'

const useTranslations = () => {
  const { t } = useI18n()
  return {
    /** Because "startCase" strips punctuation, we need to manually split and reassemble
     * the string "add/remove days" */
    addDays: pipe(
      t,
      split('/'),
      map(startCase),
      join('/'),
    )(Slugs.addRemoveDays),
    assignmentChangeError: t(Slugs.assignmentChangeError),
    cancel: t(Slugs.cancel),
    cannotAddExtraDayWarning: t(Slugs.cannotAddExtraDayWarning),
    clean: t(Slugs.clean),
    cleanType: t(Slugs.cleanType),
    due: t(Slugs.due),
    editB2bWarning: t(Slugs.editB2bWarning),
    ownerCheckoutCannotEditClean: t(Slugs.ownerCheckoutCannotEditClean),
    save: t(Slugs.save),
    scheduledOn: t(Slugs.scheduledOn),
  }
}

export type CleanInfoEditorTranslations = ReturnType<typeof useTranslations>

export type CleanInfoEditorContainerProps = {
  clean: Clean
  onCancelEdit: () => void
}

export const CleanInfoEditorContainer: React.FC<CleanInfoEditorContainerProps> =
  React.memo(({ clean, onCancelEdit }) => {
    const strings = useTranslations()
    const originalDate = createDateObject(clean.effectiveDate)
    const cleanWithinFreezeWindow = isWithinFreezeWindow(originalDate)
    const dateOptions = eachDay(clean.earliestCleanStartTime, clean.dueDate)
    const selectedDate =
      dateOptions.find(date => isSameDay(date, originalDate)) || originalDate

    const originalIsDeepClean = clean.jobType.includes('deep_clean')

    const [isDeepClean, setIsDeepClean] = React.useState(originalIsDeepClean)

    const [date, setDate] = React.useState(selectedDate)

    const { resetUpdateCleanState, updateClean, updateCleanState } =
      useCleanUpdate(clean, onCancelEdit)

    const hasDateChanges = !isSameDay(originalDate, date)
    const hasCleanJobChange = originalIsDeepClean !== isDeepClean

    const hasUnsavedChanges = hasDateChanges || hasCleanJobChange

    const existingJobType = React.useMemo(
      () => clean?.jobType || ['standard'],
      [clean.jobType],
    )

    const handleSaveClick = React.useCallback(async () => {
      const updates = produce({} as Omit<CleanPatchData, 'id'>, draft => {
        if (hasDateChanges) draft.effectiveDate = createDateObject(date)
        if (hasCleanJobChange) {
          draft.jobType = isDeepClean
            ? [...existingJobType, 'deep_clean']
            : existingJobType.filter(job => job !== 'deep_clean')
        }
      })

      updateClean(updates)
    }, [
      existingJobType,
      date,
      hasCleanJobChange,
      hasDateChanges,
      isDeepClean,
      updateClean,
    ])

    const isDateDisabled = React.useCallback(
      (date: Date) =>
        getAssignedCleanStatusByDate(date) === CleanAssignmentStatus.Future,
      [],
    )

    const reservationTypeIsGuestOrVacasaHold =
      clean.reservation?.bookingType === ReservationBookingType.GUEST ||
      clean.reservation?.bookingType === ReservationBookingType.VACASA

    const canEditDeepClean = React.useMemo(() => {
      return reservationTypeIsGuestOrVacasaHold && !clean.startedAt
    }, [reservationTypeIsGuestOrVacasaHold, clean.startedAt])

    const onCleanJobTypeChange = React.useCallback((value: 'deep' | 'post') => {
      setIsDeepClean(value === 'deep')
    }, [])

    const handlers: CleanInfoEditorProps['handlers'] = React.useMemo(
      () => ({
        cancelEdit: onCancelEdit,
        clearError: resetUpdateCleanState,
        dateChange: date => setDate(date),
        onCleanJobTypeChange,
        save: handleSaveClick,
      }),
      [
        handleSaveClick,
        onCancelEdit,
        onCleanJobTypeChange,
        resetUpdateCleanState,
      ],
    )

    const state: CleanInfoEditorProps['state'] = React.useMemo(
      () => ({
        canEditDates: cleanWithinFreezeWindow,
        canEditDeepClean,
        dateOptions,
        disableSubmit: !hasUnsavedChanges || updateCleanState.loading,
        isDeepClean,
        selectedDate: createDateObject(date),
      }),
      [
        canEditDeepClean,
        cleanWithinFreezeWindow,
        isDeepClean,
        date,
        dateOptions,
        hasUnsavedChanges,
        updateCleanState.loading,
      ],
    )

    const selectors = React.useMemo(
      () => ({
        isDateDisabled,
      }),
      [isDateDisabled],
    )

    return (
      <CleanInfoEditor
        clean={clean}
        handlers={handlers}
        reservationBookingType={clean.reservation?.bookingType}
        requestState={updateCleanState}
        selectors={selectors}
        state={state}
        strings={strings}
      />
    )
  })
