import styled from '@emotion/styled'

import { layers } from 'packages/styles'

import { SuperModal } from '../SuperModal'

export const StyledModal = styled(SuperModal)<{ hasCloseBtn: boolean }>`
  border-radius: 4px;
  min-width: 320px;
  z-index: ${layers.modal};

  padding-top: ${({ hasCloseBtn }) => (hasCloseBtn ? '32px' : 0)};
`
