import styled from '@emotion/styled'
import React from 'react'

import { ImageThumbnail } from 'packages/common'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { TaskPhoto } from 'app/hkhub/store/taskPhotos'

const St = {
  InspectionPhotos: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding-bottom: 16px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    inspection: t(Slugs.inspection),
    noPhoto: t(Slugs.noPhoto),
  }
}

export enum InspectionPhotosTestIds {
  container = 'InspectionPhotos__container',
}

export type InspectionPhotosProps = {
  onThumbnailClick: (id: string) => void
  photos: TaskPhoto[]
}

export const InspectionPhotos: React.FC<InspectionPhotosProps> = React.memo(
  ({ onThumbnailClick, photos }) => {
    const strings = useTranslations()

    return (
      <St.InspectionPhotos data-testid={InspectionPhotosTestIds.container}>
        {photos.map((photo, idx) => {
          const thumbnailUri = photo?.thumbnails?.medium || photo?.originalImage
          const key = `inspection-photo-${photo?.id || `empty-${idx}`}`

          return (
            <ImageThumbnail
              altText={strings.inspection}
              fallbackText={strings.noPhoto}
              key={key}
              onClick={() => onThumbnailClick(photo?.id)}
              size={120}
              thumbnailUri={thumbnailUri}
            />
          )
        })}
      </St.InspectionPhotos>
    )
  },
)
