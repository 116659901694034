import classNames from 'classnames'
import * as React from 'react'

import { Badge } from '../Badge/Badge'

import styles from './NotificationBadge.module.scss'

export type NotificationBadgeProps = {
  className?: string
  count: number
}

const NotificationBadge: React.FunctionComponent<NotificationBadgeProps> = ({
  count,
  className,
}) => {
  const size = count.toString().length === 1 ? 'single' : 'double'

  return (
    <Badge
      className={classNames(styles.notificationBadge, styles[size], className)}
    >
      {count}
    </Badge>
  )
}

export default React.memo(NotificationBadge)
