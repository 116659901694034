import { noop } from 'lodash/fp'
import * as React from 'react'

import {
  BottomTooltip,
  LeftTooltip,
  RightTooltip,
  TooltipWrapper,
  TopTooltip,
} from './Tooltip.styles'

export enum TooltipPosition {
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
  Top = 'Top',
}

const renderMap = {
  [TooltipPosition.Bottom]: BottomTooltip,
  [TooltipPosition.Left]: LeftTooltip,
  [TooltipPosition.Right]: RightTooltip,
  [TooltipPosition.Top]: TopTooltip,
}

export enum TooltipTestIds {
  container = 'Tooltip__container',
  content = 'Tooltip__content',
}

export type TooltipProps = React.PropsWithChildren<{
  className?: string
  enabled?: boolean
  message: string
  position?: TooltipPosition
}>

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  className = '',
  enabled = true,
  message,
  position = TooltipPosition.Bottom,
}) => {
  const TooltipComponent = renderMap[position]
  const [visible, setVisible] = React.useState(false)

  return (
    <TooltipWrapper
      className={className}
      data-testid={TooltipTestIds.container}
      onMouseOver={() => (enabled ? setVisible(true) : noop)}
      onMouseOut={() => (enabled ? setVisible(false) : noop)}
    >
      {children}

      <TooltipComponent
        data-testid={TooltipTestIds.content}
        role="tooltip"
        visible={visible}
      >
        {message}
      </TooltipComponent>
    </TooltipWrapper>
  )
}
