import styled from '@emotion/styled'
import React from 'react'
import { useNavigate } from 'react-router'

import { Button } from 'packages/common'

import icon from '../../../assets/404-asset.svg'

const St = {
  BackBtn: styled(Button)`
    margin-top: 16px;
  `,

  Icon: styled.div`
    background-image: url(${icon});
    background-position: left center;
    background-repeat: no-repeat;
    height: 106px;
    transform: scale(-1, 1);
    width: 90px;
  `,

  Message: styled.div`
    font-size: 16px;
  `,

  NotFoundPage: styled.main`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    justify-content: center;
    margin-top: 72px;
    padding: 16px;
  `,

  Title: styled.div`
    font-size: 28px;
    font-weight: 600;
  `,
}

export const NotFoundPage: React.FC = React.memo(() => {
  const navigate = useNavigate()

  return (
    <St.NotFoundPage>
      <St.Icon />
      <div>
        <St.Title>Page not found</St.Title>
        <St.Message>
          {"Sorry, the page you're looking for must have taken a vacation."}
        </St.Message>

        <St.BackBtn
          buttonType={'utility'}
          onClick={() => {
            navigate('/zone')
          }}
        >
          Back to Dashboard
        </St.BackBtn>
      </div>
    </St.NotFoundPage>
  )
})
