import React from 'react'
import { useSelector } from 'react-redux'

import { isBefore, startOfToday } from 'packages/utils/dateHelpers'
import { track } from 'packages/wiretap'
import { SegmentEvents } from 'packages/wiretap/src/tracking/events'

import { ApplicationState } from 'app/hkhub/store/store'
import { getOpenTicketsByUnitId } from 'app/hkhub/store/tickets/selectors/getOpenTicketsByUnitId'

import { TicketListHeader } from '../TicketListHeader'
import { TicketsList } from './TicketsList'
import * as St from './TicketsList.container.styles'

export type TicketsListContainerProps = {
  unitId: string
}

export const TicketsListContainer: React.FC<TicketsListContainerProps> =
  React.memo(({ unitId }) => {
    const tickets = useSelector((state: ApplicationState) =>
      getOpenTicketsByUnitId(state, unitId),
    )

    const numberOfOverdueTickets = tickets.filter(
      ticket => ticket?.dueDate && isBefore(ticket.dueDate, startOfToday()),
    ).length

    React.useEffect(() => {
      if (numberOfOverdueTickets < 1) return

      track(SegmentEvents.hubTicketWithOverdueOpen)
    }, [numberOfOverdueTickets])

    return (
      <St.Container>
        <TicketListHeader numberOfOverdueTickets={numberOfOverdueTickets} />
        <TicketsList tickets={tickets} />
      </St.Container>
    )
  })
