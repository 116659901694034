import { ApplicationState } from '../store'
import { UnitWithHousekeeperPreferences } from '../units/units.types'
import { getUnitWithHousekeeperPreferencesById } from './getUnitWithHousekeeperPreferencesById'

export const getUnitsWithHousekeeperPreferences = (
  state: ApplicationState,
): UnitWithHousekeeperPreferences[] => {
  const unitIds = Object.keys(state.units.data)
  return unitIds
    .map(unitId => getUnitWithHousekeeperPreferencesById(state, unitId))
    .filter(unit => unit?.isActive) as UnitWithHousekeeperPreferences[]
}
