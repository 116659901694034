import uniqBy from 'lodash/uniqBy'
import { deprecated } from 'typesafe-actions'

import { Zone, ZonesActionTypes } from '../zones.types'

const { createStandardAction } = deprecated

export const selectZoneAction = createStandardAction(
  ZonesActionTypes.SELECT_ZONE,
)<Zone>()

/**
 * Use when selecting a zone, updates last known and previous selections
 * @param zone to select
 */
export const selectZone = (zone: Zone) => dispatch => {
  dispatch(selectZoneAction(zone))
  localStorage.setItem('lastZoneId', zone.id)

  let zones: Zone[] = [zone]
  const defaultZones = localStorage.getItem('defaultZones')
  if (defaultZones) {
    zones = zones.concat(JSON.parse(defaultZones))
  }

  const zonesData = uniqBy(zones, (otherZone: Zone) => otherZone.id).slice(
    0,
    10,
  )
  localStorage.setItem('defaultZones', JSON.stringify(zonesData))
}
