import styled from '@emotion/styled'

import { cleanIsDeepClean, cleanIsMidstay } from 'packages/grimoire'
import { SvgIcon } from 'packages/iconic'
import {
  colors,
  truncateTextWithEllipsis,
  centerWithFlex,
} from 'packages/styles/jss'

import { Clean } from 'app/hkhub/store/cleans'

export const BaseCleanDrawerHeader = styled.div<{ clean: Clean }>`
  align-items: center;
  color: ${colors.dusk};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  justify-content: space-between;
  left: 0;
  padding: 0 8px 0 8px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 100%;

  background-color: ${({ clean }) => {
    if (cleanIsMidstay(clean)) return '#CCE5E9' // color FKA "grape20"
    if (cleanIsDeepClean(clean)) return colors.sand
    return colors.gulf20
  }};
`

export const CleanTypeIcon = styled(SvgIcon)`
  &,
  span {
    ${truncateTextWithEllipsis};
  }

  svg {
    flex-shrink: 0;
  }
  padding-right: 12px;
`

export const CleanTypeOwnerContainer = styled.div`
  align-items: center;
  display: flex;
`

export const OwnerBadge = styled.span`
  ${centerWithFlex};
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  color: ${colors.dusk};
  font-size: inherit;
  font-weight: 700;
  height: 24px;
  margin-left: auto;
  margin-right: 8px;
  padding: 0 8px;
`

export const CleanDrawerHeaderCloseIcon = styled(SvgIcon)`
  color: ${colors.dusk};
`
