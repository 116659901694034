import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { Assignment } from '../assignments.types'
import { getAssignmentById } from './getAssignmentById'

const getFullState = (state: ApplicationState): ApplicationState => state
const getAssignmentId = (_state: ApplicationState, id: string): string => id

export const getRejectedAssignmentsByCleanId = createSelector(
  getFullState,
  getAssignmentId,
  (state, cleanId): Assignment[] => {
    const rawAssignments =
      state.cleans.data[cleanId]?.relationships?.rejectedAssignments?.data

    return (rawAssignments || []).reduce((result, ass) => {
      const assignment = getAssignmentById(state, ass.id)

      if (assignment) result.push(assignment)
      return result
    }, [] as Assignment[])
  },
)
