import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import {
  Reservation,
  ReservationAttributeNames,
} from 'app/hkhub/store/reservations/reservations.types'

import { CleansState } from '../cleans.types'

/**
 * This is used to grab reservations that are in the cleans state, not the reservations state
 * @param state
 * @param id
 * @returns reservation on a clean
 */
export const getReservationById = (
  state: CleansState,
  id: string,
): Reservation | undefined => {
  const rawRes = state?.reservation?.[id]
  return (
    rawRes &&
    transformNormalizedToTyped<Reservation>(rawRes, ReservationAttributeNames)
  )
}
