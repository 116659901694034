import { map, pipe, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { DateFormat, format } from 'packages/utils/dateHelpers'

import { ApplicationState } from 'app/hkhub/store/store'

import { getDateRangeMap } from '../../cleans/cleans.utils'
import { hydrateRawVisit } from '../utils'
import { UnitDateVisitBuckets, Visit } from '../visits.types'

export const getVisitBucketsByUnitAndDate = createSelector(
  (state: ApplicationState) => state.visits,
  (_, dateRange: Date[]) => dateRange,
  (visitsState, dateRange): UnitDateVisitBuckets => {
    const dateRangeMap = getDateRangeMap(dateRange)

    const rawVisits = visitsState.data

    const visits: Visit[] = pipe(values, map(hydrateRawVisit))(rawVisits)

    return visits.reduce((acc, visit) => {
      const date = format(visit.effectiveDate, DateFormat.ApiUtcShort)
      if (date in dateRangeMap) {
        const unitId = visit.unitId

        if (!acc[unitId]) acc[unitId] = {}
        if (!acc[unitId][date]) acc[unitId][date] = []

        acc[unitId][date].push(visit)
      }

      return acc
    }, {})
  },
)
