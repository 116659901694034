import classNames from 'classnames'
import { always, noop } from 'lodash/fp'
import * as React from 'react'
import { usePrevious } from 'react-use'
import { v4 as uuid } from 'uuid'

import { SvgIcon, IconName } from 'packages/iconic'

import styles from './InputField.module.scss'

const defaultStyleOverrides = {}
const defaultValidator = always(true)

export type InputFieldType = 'text' | 'email' | 'password' | 'search' | 'number'

export type InputFieldProps = {
  /** Class to apply to wrapper */
  className?: string
  /** Whether we should show a "clear" icon on the right side of the input, which will clear all input when clicked */
  clearable?: boolean
  /**
   * Optional ID string to apply to the parent component. Note that this is NOT the <input> field itself,
   * but the wrapper around all of the elements, icons, etc.
   */
  containerId?: string
  /** Is the input disabled? */
  disabled?: boolean
  /** Should the input have a red error border */
  errorMessage?: string | React.ReactNode
  /** Whether this input field should auto-focus upon mounting */
  focusOnMount?: boolean
  /**
   * Whether the input field should clear its own value on the next render.
   * Use this in rares cases when you need to clear the internal "value" from the outside world.
   * Note that this will still call any onChange/onClear callbacks when the value changes.
   */
  forceClear?: boolean
  /** Whether the element should be rendered with `width: 100%` */
  fullWidth?: boolean
  /**
   * (Optional) Provided an ID string to be used for the element's `id`, `name`, and `data-testid` fields.
   * If none is provided, a default one will be generated with a UUID.
   */
  id?: string
  /**
   * Optional initial value to use for the internal value
   * Note that InputField does maintain its own internal state for the input value, so this value is only used during initialization
   */
  initialValue?: string
  /**
   * (Optional) A string to use to populate an HTML <label> element paired with this input
   */
  label?: string
  /**
   * Optional icon name to show on the left side of the input.
   * Use the `IconName` values from Iconic for this.
   */
  leftIcon?: IconName
  /** max number of characters in input */
  maxLength?: number
  /**
   * Optional callback for when input has been cleared.
   * Note that this covers both clicking the "clear" icon and simply deleting all existing text
   */
  onClearValue?: () => void
  /** Optional callback for handling changes in the input value */
  onInputChange?: (value: string) => void
  /** Optional placeholder text for the input */
  placeholder?: string
  /** Should the count be displayed on the input */
  showCount?: boolean
  /** Optional style overrides object to be applied to the input element. */
  styleOverrides?: React.CSSProperties
  /**
   * (Optional) Override value for the native HTML 'tabindex' attribute.
   * It default to 0, which means it is just in the normal flow of tab ordering,
   * and a value of -1 will mean that it is not focusable by tabbing.
   * **Default: 0**
   */
  tabIndex?: number
  /** The native HTML type of this input field. Can accept any types that pertain to text-based inputs. (default: "text") */
  type?: InputFieldType
  /**
   * (Optional) Function that runs prior to changing the value
   * If the newValue passed into this function returns false, the value will not update
   */
  validationFunction?: (input: string) => boolean
} & { ref?: React.Ref<HTMLInputElement> }

/**
 * A flexible input component, which ultimately just renders a pre-styled native HTML input. Can be used directly
 * or wrapped for more specific functionality.
 *
 * This component handles its own internal state for the input value, but provides callbacks for handling changes in said value.
 */
// TODO: TPD-5369 figure this thing out
// @ts-ignore
export const InputField: React.RefForwardingComponent<
  HTMLInputElement,
  InputFieldProps
> = React.forwardRef(
  (
    {
      className,
      clearable,
      containerId = `InputField__container-${uuid()}`,
      disabled = false,
      errorMessage = '',
      focusOnMount = false,
      forceClear = false,
      fullWidth = true,
      id = `InputField-${uuid()}`,
      initialValue = '',
      label,
      leftIcon,
      maxLength = 255,
      onClearValue = noop,
      onInputChange = noop,
      placeholder,
      showCount = false,
      styleOverrides = defaultStyleOverrides,
      tabIndex = 0,
      type = 'text',
      validationFunction = defaultValidator,
    },
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    // using a ref here to prevent our callbacks from triggering on initial render
    const isInitialRender = React.useRef(true)
    const elementId = React.useRef(id) // unique id/name for input/label elements

    // If a forward-ref is passed in, use that for our input ref; otherwise, just make a new one
    const inputEl =
      (ref as React.MutableRefObject<HTMLInputElement>) ??
      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useRef<HTMLInputElement>(null) // reference to <input> element

    const [value, setValue] = React.useState(initialValue)
    const prevValue = usePrevious(value)

    // attempt to auto-focus the input element on mount when prop is true
    React.useEffect(() => {
      const currentInputEl = inputEl?.current
      if (focusOnMount && currentInputEl?.focus) {
        setTimeout(() => {
          currentInputEl.focus()
        }, 0)
      }
    }, [focusOnMount, inputEl])

    /*
     * Effect handler for changes in local "value" state
     * - Does nothing on initial render
     * - Does nothing if value has not changed since last render
     * - Otherwise, will always call "onInputChange" with new value
     * - Will additionally call "onClearValue" if the new value is empty
     */
    React.useEffect(() => {
      if (isInitialRender.current) {
        isInitialRender.current = false
        return
      }

      if (value === prevValue) return

      onInputChange(value.trim())

      if (!value) {
        onClearValue()
      }
    }, [onClearValue, onInputChange, prevValue, value])

    // clear the local state value any time "forceClear" changes to true
    React.useEffect(() => {
      if (forceClear) {
        setValue('')
      }
    }, [forceClear])

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const newValue = event?.target?.value

      if (validationFunction(newValue)) {
        setValue(newValue)
      }
    }

    function handleClear() {
      setValue('')
    }

    return (
      <div
        className={classNames(styles.inputContainer, className)}
        id={containerId}
      >
        {label && (
          <label className={styles.inputLabel} htmlFor={elementId.current}>
            {label}
          </label>
        )}

        <div
          className={classNames(styles.inputWrapper, {
            [styles.fullWidth]: fullWidth,
          })}
        >
          {leftIcon && (
            <SvgIcon
              className={classNames(styles.inputIcon, styles.leftIcon)}
              icon={leftIcon}
              size={22}
            />
          )}

          <input
            className={classNames(styles.input, {
              [styles.withLeftIcon]: !!leftIcon,
              [styles.withRightIcon]: clearable || showCount,
              [styles.error]: !!errorMessage,
            })}
            data-testid={elementId.current}
            disabled={disabled}
            id={elementId.current}
            maxLength={maxLength}
            name={elementId.current}
            onChange={handleChange}
            placeholder={placeholder}
            ref={inputEl}
            style={styleOverrides}
            tabIndex={tabIndex}
            type={type}
            value={value}
          />

          {showCount && (
            <span
              className={classNames(
                styles.count,
                styles.inputIcon,
                styles.rightIcon,
              )}
            >{`${value.length}/${maxLength}`}</span>
          )}

          {clearable && !!value && (
            <SvgIcon
              className={classNames(styles.inputIcon, styles.rightIcon)}
              icon={IconName.x}
              onClick={handleClear}
              size={22}
            />
          )}
        </div>
        {!!errorMessage && (
          <span className={styles.errorMessage}>{errorMessage}</span>
        )}
      </div>
    )
  },
)
