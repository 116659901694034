import { gt, gte, indexOf, lt, lte } from 'lodash/fp'

import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks/useScheduleMatchParams'

import { CardSize, orderedCardSizes } from './scheduleSizes'

const compareCardSizes = func => (size: CardSize, comparison: CardSize) =>
  func(indexOf(size)(orderedCardSizes), indexOf(comparison)(orderedCardSizes))

export const cardSizeGreaterThan = compareCardSizes(gt)
export const cardSizeEqualOrGreaterThan = compareCardSizes(gte)
export const cardSizeLessThan = compareCardSizes(lt)
export const cardSizeEqualOrLessThan = compareCardSizes(lte)

export const cardNotTiny = (size: CardSize): boolean =>
  cardSizeGreaterThan(size, 'tiny')

export const cardIsTiny = (size: CardSize): size is 'tiny' =>
  cardSizeEqualOrLessThan(size, 'tiny')

export const useCurrentCardSize = (): CardSize => {
  const { dayCount } = useScheduleMatchParams()

  const colSize = Math.floor(document.body.offsetWidth / dayCount)
  if (colSize <= 100) return 'tiny'
  if (colSize <= 190) return 'small'
  if (colSize <= 250) return 'medium'

  return 'large'
}
