import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import {
  Breakpoint,
  colors,
  layers,
  truncateTextWithEllipsis,
} from 'packages/styles'

import unavailableImg from 'app/hkhub/assets/tiled_unavailable_scaled.png'
import { ScheduleSizes } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

type WithBreakpoint = { breakpoint: Breakpoint }

const Overlay = styled.div`
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
`

export const PastCleanOverlay = styled(Overlay)`
  background: rgba(255, 255, 255, 0.6);
  z-index: ${layers.autoScheduleOverlay};
`

export const UnavailableOverlay = styled(Overlay)`
  background: url(${unavailableImg}) repeat;
  top: 2px;
  z-index: 1;
`

export const OffIconContainer = styled.div<WithBreakpoint>`
  align-items: center;
  align-self: flex-end;
  background-color: ${colors.white};
  border-radius: 18px;
  border: 1px solid ${colors.midnight50};
  display: flex;
  gap: 3px;
  margin: ${({ breakpoint }) =>
    breakpoint === 'LG' ? '8px 8px 8px 0' : '6px 4px 6px 0'};
  padding: ${({ breakpoint }) =>
    breakpoint === 'LG' ? '2px 8px' : '3px 4px 2px'};
  z-index: 10;

  & + & {
    margin-top: 0;
  }
`

const midnight50Filter = `brightness(0) saturate(100%) invert(64%) sepia(8%) saturate(819%) hue-rotate(153deg) brightness(92%) contrast(84%)`

export const OffIcon = styled(SvgIcon)<WithBreakpoint>`
  filter: ${midnight50Filter};
`

export const OffIconText = styled.span<WithBreakpoint>`
  color: ${colors.midnight50};
  font-size: 10px;
  font-weight: 700;
  margin: 0;
  line-height: 1;
`

export const AutoScheduleOverlay = styled(Overlay)`
  background: rgba(44, 52, 57, 0.06);
  z-index: ${layers.autoScheduleOverlay};
`

//------------------------------------------------
// General/shared virtualized schedule styles
//------------------------------------------------

export const ScheduleRowEventsContainer = styled.div`
  display: flex;
  flex-grow: 1;
`

const headerPadding: Record<Breakpoint, string> = {
  LG: '16px',
  SM: '4px',
}

export const ScheduleRowHeader = styled.div<WithBreakpoint>`
  align-items: center;
  background: white;
  box-shadow: 0px -1px 0px ${colors.midnight20},
    0px 2px 0px ${colors.midnight20};
  display: flex;
  flex-direction: ${({ breakpoint }) =>
    breakpoint === 'LG' ? 'row' : 'column'};
  height: ${ScheduleSizes.RowHeaderHeight}px;
  justify-content: space-between;
  padding: ${({ breakpoint }) => headerPadding[breakpoint]};
  position: relative;
  z-index: 1;
`

const titleFontSize: Record<Breakpoint, string> = {
  LG: '16px',
  SM: '14px',
}

const textFontSize: Record<Breakpoint, string> = {
  LG: '14px',
  SM: '12px',
}

export const ScheduleRowHeaderTitle = styled.strong<WithBreakpoint>`
  ${truncateTextWithEllipsis};
  font-size: ${({ breakpoint }) => titleFontSize[breakpoint]};
  font-weight: bold;
  width: ${({ breakpoint }) => (breakpoint === 'LG' ? 'auto' : '100%')};
`

export const ScheduleRowHeaderContent = styled.div<WithBreakpoint>`
  display: flex;
  flex-grow: 1;
  width: ${({ breakpoint }) => (breakpoint === 'LG' ? 'auto' : '100%')};
`

export const ScheduleRowHeaderText = styled.span<WithBreakpoint>`
  font-size: ${({ breakpoint }) => textFontSize[breakpoint]};
  padding-left: ${({ breakpoint }) => (breakpoint === 'LG' ? '16px' : 0)};
`

export const ScheduleRowHeaderEndText = styled(ScheduleRowHeaderText)`
  margin-left: auto;
  padding-left: 8px; // this ensures legibility on SM devices
`

export const ScheduleDayCleansContainer = styled.div<{
  dayCount: number
  unassigned?: boolean
}>`
  background: ${({ unassigned }) => (unassigned ? colors.alert10 : 'white')};
  display: flex;
  flex-direction: column;
  min-height: ${ScheduleSizes.MinRowHeight}px;
  position: relative;
  width: ${({ dayCount }) => `calc(100% / ${dayCount})`};

  &:not(:last-of-type) {
    border-right: 1px solid ${colors.midnight20};
  }
`
