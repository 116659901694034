import { deprecated } from 'typesafe-actions'

import { ZoneNotificationsActionTypes } from '../notifications.types'

const { createStandardAction } = deprecated

export const clearZoneNotificationsAction = createStandardAction(
  ZoneNotificationsActionTypes.CLEAR_NOTIFICATIONS,
)<null>()

/**
 * Dispatches an action to clear all local zone notifications.
 * Note that this simply clears the local Redux data--there is no API request associated with this action.
 */
export const clearZoneNotifications = () => dispatch => {
  dispatch(clearZoneNotificationsAction(null))
}
