import { transformNormalizedToTyped } from 'packages/utils/store'

import { RawUser, User, UserAttributeNames } from './users.types'

/**
 * Empty data in the shape of the Users service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedUsersData = Object.freeze({
  user: {},
})

type WithFullName = Record<'firstName' | 'lastName', string>
export const fullName = (user: WithFullName): string =>
  `${user.firstName} ${user.lastName}`

export const transformRawUser = (user: RawUser): User =>
  transformNormalizedToTyped<User>(user, UserAttributeNames)

export const hydrateRawUser = (rawUser: RawUser): User => {
  return transformRawUser(rawUser)
}
