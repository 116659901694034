import React from 'react'

import { useManageDrawerState } from 'packages/common'
import { SvgIcon, IconName } from 'packages/iconic'
import { useBreakpoint } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { useHubRouting } from 'app/hkhub/utils/hooks'

import { SelectedZoneDisplay } from '../SelectedZoneDisplay'
import { ZoneSelectorProps } from '../ZoneSelector/ZoneSelector'
import { NavDrawer } from './NavDrawer'
import { ScheduleEntityMenu } from './ScheduleEntityMenu'
import * as St from './Titlebar.styles'

const useTranslations = () => {
  const { tp, ut } = useI18n()

  return {
    menu: ut(Slugs.menu),
    pages: {
      housekeepers: tp(Slugs.housekeeper, 2, false),
      manage: ut(Slugs.zone),
    },
  }
}

export enum TitleBarTestIds {
  container = 'titleBar__container',
}

type PickedZoneSelectorProps = Pick<ZoneSelectorProps, 'selectedZone'>

export type TitlebarProps = {
  initializingZone: boolean
} & PickedZoneSelectorProps

export const Titlebar: React.FC<TitlebarProps> = ({
  initializingZone,
  selectedZone,
}) => {
  const strings = useTranslations()
  const breakpoint = useBreakpoint()
  const isLargeScreen = breakpoint === 'LG'

  const drawerStateProps = useManageDrawerState()
  const { openDrawer } = drawerStateProps

  const { mainPage } = useHubRouting()
  const isSchedulePage = mainPage === 'schedule'

  const [zoneSearchOpen, setZoneSearchOpen] = React.useState(false)

  const handleChangeZone = React.useCallback(() => {
    openDrawer()
    setZoneSearchOpen(true)
  }, [openDrawer])

  return (
    <St.TitlebarContainer data-testid={TitleBarTestIds.container}>
      <St.TitlebarContent breakpoint={breakpoint}>
        <NavDrawer
          {...drawerStateProps}
          initializingZone={initializingZone}
          setZoneSearchOpen={(val: boolean) => setZoneSearchOpen(val)}
          zone={selectedZone}
          zoneSearchOpen={zoneSearchOpen}
        />

        <St.LeftContent>
          <St.BrandIcon to="/zone">
            <SvgIcon centerItems={true} icon={IconName.vacasa2} size={26} />
          </St.BrandIcon>

          {isSchedulePage ? (
            <ScheduleEntityMenu />
          ) : (
            <St.BrandTitleText>{strings.pages[mainPage]}</St.BrandTitleText>
          )}
        </St.LeftContent>

        <St.RightContent>
          {isLargeScreen && (
            <SelectedZoneDisplay
              onChange={handleChangeZone}
              zone={selectedZone}
            />
          )}

          <St.MenuWrapper>
            <St.MenuIcon
              centerItems={true}
              textFontSize={16}
              icon={IconName.menu}
              onClick={openDrawer}
              size={20}
              text={isLargeScreen ? strings.menu : ''}
            />
          </St.MenuWrapper>
        </St.RightContent>
      </St.TitlebarContent>
    </St.TitlebarContainer>
  )
}
