import { AxiosResponse } from 'axios'

import {
  JSONApiObjectMap,
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToManyRelationship,
  ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

import { JSONApiHousekeepersMap } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { JSONApiUsersMap, User } from 'app/hkhub/store/users/users.types'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type ZoneNotificationsState = {
  data: JSONApiZoneNotificationMap
  error?: Error
  housekeeper?: JSONApiHousekeepersMap
  isLoading: boolean
  user?: JSONApiUsersMap
}

export enum ZoneNotificationsActionTypes {
  CLEAR_NOTIFICATIONS = 'NOTIFICATIONS/CLEAR_NOTIFICATIONS',

  DISMISS_NOTIFICATION = 'NOTIFICATIONS/API/DISMISS_NOTIFICATION',
  DISMISS_NOTIFICATION_FAILURE = 'NOTIFICATIONS/DISMISS_NOTIFICATION_FAILURE',
  DISMISS_NOTIFICATION_SUCCESS = 'NOTIFICATIONS/DISMISS_NOTIFICATION_SUCCESS',

  FETCH_NOTIFICATIONS = 'NOTIFICATIONS/API/FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_FAILURE = 'NOTIFICATIONS/FETCH_NOTIFICATIONS_FAILURE',
  FETCH_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS/FETCH_NOTIFICATIONS_SUCCESS',

  FETCH_NOTIFICATIONS_WITH_HKS = 'NOTIFICATIONS/API/FETCH_NOTIFICATIONS_WITH_HKS',
  FETCH_NOTIFICATIONS_WITH_HKS_FAILURE = 'NOTIFICATIONS/FETCH_NOTIFICATIONS_WITH_HKS_FAILURE',
  FETCH_NOTIFICATIONS_WITH_HKS_SUCCESS = 'NOTIFICATIONS/FETCH_NOTIFICATIONS_WITH_HKS_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
type NotificationsResponse = {
  housekeepers?: JSONApiHousekeepersMap
  users?: JSONApiUsersMap
  zoneNotifications: JSONApiObjectMap<ZoneNotificationAttributes>
}

export type NormalizedNotificationsApiResponse =
  NormalizedJSONApiResponse<NotificationsResponse>
export type NotificationsApiResponse =
  AxiosResponse<NormalizedNotificationsApiResponse>
export type NotificationsServiceResponse =
  Promise<NormalizedNotificationsApiResponse>

/**********************************************************
 * ZONE NOTIFICATIONS
 *********************************************************/
export type ZoneNotificationAttributes = {
  messageCode: string
}

export const ZoneNotificationAttributeNames = ['messageCode']

export type ZoneNotificationRelationships = {
  housekeeper?: ToOneRelationship
  user?: ToManyRelationship
}

export type ZoneNotification = {
  housekeeper?: ZoneNotificationHousekeeper
  id: string
} & ZoneNotificationAttributes

export type ZoneNotificationHousekeeper = {
  manager?: User
} & User

export type RawZoneNotification = JSONApiObjectWithRelationships<
  ZoneNotificationAttributes,
  ZoneNotificationRelationships
>

export type JSONApiZoneNotificationMap = JSONApiObjectWithRelationshipsMap<
  ZoneNotificationAttributes,
  ZoneNotificationRelationships
>
