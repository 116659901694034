import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ticketingService } from '../ticketing.service'
import {
  NormalizedTicketingApiResponse,
  TicketingActionTypes,
} from '../ticketing.types'

export const fetchTicketingDispositionsAction = createAsyncAction(
  TicketingActionTypes.FETCH_ALL_DISPOSITIONS,
  TicketingActionTypes.FETCH_ALL_DISPOSITIONS_SUCCESS,
  TicketingActionTypes.FETCH_ALL_DISPOSITIONS_FAILURE,
)<
  RequestConfig<NormalizedTicketingApiResponse>,
  NormalizedTicketingApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    disposition: ['tier_1_display', 'tier_2_display'],
  },
  /* eslint-disable @typescript-eslint/naming-convention */
  filter: {
    is_active: true,
    tier_three__isnull: true,
    tier_two__isnull: false,
  },
  /* eslint-enabled @typescript-eslint/naming-convention */
  page: { size: 75 },
})

export const fetchTicketingDispositions = () => async dispatch => {
  try {
    const params = getParams()
    const request = ticketingService.fetchTicketingDispositions.bind(
      null,
      params,
    )
    const result = await dispatch(
      fetchTicketingDispositionsAction.request({ request }),
    )
    dispatch(fetchTicketingDispositionsAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchTicketingDispositionsAction.failure(error))
    throw error
  }
}
