import React from 'react'
import { useNavigate } from 'react-router-dom'

import { track, Events } from 'packages/wiretap'

import {
  ScheduleEntityType,
  ScheduleNavigationSearchParams,
} from 'app/hkhub/components/schedule/schedule.types'
import { useOptionalZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'

import { useScheduleMatchParams } from '../useScheduleMatchParams'
import {
  buildSearchParams,
  buildScheduleUrl,
} from './useScheduleNavigation.helpers'

const TRACKABLE_DAYS = [1, 3, 7]
let prevDayCount = 0

/**
 * Sends a Segment tracking event for "days view" changes, IF:
 * - The new view is different than the old view
 * - The new view is an "official" view (i.e. one that we link to, not manually typed in)
 * @param newDayCount
 */
const trackDayCountChange = (newDayCount: number) => {
  if (newDayCount === prevDayCount) return
  if (!TRACKABLE_DAYS.includes(newDayCount)) return

  track(Events.hubChangeDayView, { days: newDayCount })
  prevDayCount = newDayCount
}

type SearchParams = Partial<ScheduleNavigationSearchParams>

type UseScheduleNavigation = {
  changeScheduleEntity: (entity: ScheduleEntityType) => void
  navigate: (params: SearchParams) => void
}

export const useScheduleNavigation = (): UseScheduleNavigation => {
  const routerUseNavigate = useNavigate()

  const { date, dayCount, entity } = useScheduleMatchParams()
  const { zone } = useOptionalZoneContext()
  const zoneId = zone?.id || ''

  /**
   * Navigates to a new URL with the provided schedule entity (e.g. staff/unit).
   * All other params will keep their current values (e.g. date, dayCount).
   */
  const changeScheduleEntity = React.useCallback(
    (nextEntity: ScheduleEntityType) => {
      const baseUrl = buildScheduleUrl(zoneId, nextEntity)

      const search = buildSearchParams({
        date,
        dayCount,
      })

      routerUseNavigate(`${baseUrl}${search}`)
    },
    [date, dayCount, routerUseNavigate, zoneId],
  )

  /**
   * Navigates to a new schedule URL adding any provided search params as
   * part of the search query. Any params that are omitted will retain their current values.
   */
  const navigate = React.useCallback(
    (searchParams: SearchParams) => {
      const { date: newDate = date, dayCount: newDayCount = dayCount } =
        searchParams

      const search = buildSearchParams({
        date: newDate,
        dayCount: newDayCount,
      })

      const baseUrl = buildScheduleUrl(zoneId, entity)

      trackDayCountChange(newDayCount)
      routerUseNavigate(`${baseUrl}${search}`)
    },
    [date, dayCount, entity, routerUseNavigate, zoneId],
  )

  return {
    changeScheduleEntity,
    navigate,
  }
}
