import { TFunction } from 'i18next'

import I18n, { I18nInitOptions as DefaultI18nInitOptions } from './src/i18n'
import Slugs from './translations/slugs'
export { Translation } from 'react-i18next'

export { I18nEventTypes } from './src/i18n'
export { useI18n } from './src/useI18n'
export { Slugs }

export type I18nInitOptions = DefaultI18nInitOptions

// This slightly hacky type allows us to use our ut() translator function
// in place of the regular t() function while still keeping the TypeScript compiler happy
type SimpleTranslatorFn = (slug: string) => string
export type TranslatorFn = TFunction | SimpleTranslatorFn

export default I18n
