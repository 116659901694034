import styled from '@emotion/styled'
import { startCase } from 'lodash/fp'
import React from 'react'

import { UnitRealtimeStatusIndicator } from 'packages/common'
import { colors } from 'packages/styles'

import { unitStatusTextMap } from 'app/hkhub/components/schedule/components/units/utils/unitSchedule.utils'
import { useI18n } from 'app/hkhub/i18n'
import { Unit } from 'app/hkhub/store/units/units.types'

const St = {
  StatusText: styled.span`
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  `,

  UnitStatus: styled.div`
    align-items: center;
    background: ${colors.midnight4};
    border-bottom: 1px solid ${colors.midnight10};
    border-top: 1px solid ${colors.midnight10};
    display: flex;
    grid-gap: 8px;
    height: 60px;
    padding: 16px;
  `,
}

const useTranslations = (unit: Unit) => {
  const { ut } = useI18n()

  return {
    status: startCase(ut(unitStatusTextMap[unit.realtimeStatus])),
  }
}

export type UnitStatusProps = {
  unit: Unit
}

export const UnitStatus: React.FC<UnitStatusProps> = React.memo(({ unit }) => {
  const strings = useTranslations(unit)

  return (
    <St.UnitStatus>
      <UnitRealtimeStatusIndicator unitRealtimeStatus={unit.realtimeStatus} />
      <St.StatusText>{strings.status}</St.StatusText>
    </St.UnitStatus>
  )
})
