import * as React from 'react'

import { Keys } from 'packages/utils/constants'
import { wrap } from 'packages/utils/mathHelpers'

import { useHotkeys } from './useHotkeys'

const FOCUSABLE_NODE_TYPES = ['a', 'button', 'input', 'textarea']

type UseFocusTrapProps = {
  /**
   * (Optional) A ref to a component that is being auto-focused on load. While this hook
   * does not _actually focus_ the ref itself, it is still necessary to know if one is
   * being focused, so that the hook can keep its internal focus state in sync.
   */
  initialFocusRef?: React.MutableRefObject<HTMLElement>
  /**
   * Whether the parent component is currently active. This is useful if you need to
   * temporarily disable any behavior, for example if the component is a loading state,
   * where no focus events should be processed.
   */
  isActive: boolean
  /**
   * A ref to the parent element within which focused should be trapped. When active,
   * only the focusable elements within this parent will be eligible to receive tab focus.
   */
  rootRef: React.MutableRefObject<HTMLElement>
}

/**
 * A hook to allow a component to create a "focus trap," where it will override the browser's
 * default tab key handling to ensure that the user can only tab through the elements contained
 * within the specified parent element. When tabbing beyond the first or last focusable element
 * within said parent, the focus will wrap to the other end.
 * @param initialFocusRef
 * @param isActive
 * @param rootRef
 */
export const useFocusTrap = ({
  initialFocusRef,
  isActive,
  rootRef,
}: UseFocusTrapProps): void => {
  const [nodes, setNodes] = React.useState<HTMLElement[]>()
  const [focusIdx, setFocusIdx] = React.useState(0)
  const [reverse, setReverse] = React.useState(false)

  /**
   * Event handler for focus events on focusable children.
   * This will be used to ensure our internal focus tracking stays in sync with reality.
   *
   * An example of this would be if our internal focus is at input #1, but the user mouse-clicks
   * on input #3, pressing tab from there would focus input #2, when it should actually focus input #4.
   * This will keep track of such changes.
   */
  const handleFocusEvent = React.useCallback(
    event => {
      if (!isActive || !nodes?.length) return

      // if the focused element is a child of this parent, ensure that our internal "focused index" is in sync
      if (rootRef.current.contains(event.target)) {
        const targetedIdx = nodes.findIndex(node => node === event.target)
        if (targetedIdx !== -1 && targetedIdx !== focusIdx) {
          setFocusIdx(targetedIdx)
        }
      }
    },
    [focusIdx, isActive, nodes, rootRef],
  )

  /**
   * Custom tab-key handler; will limit tab focus only to the focusable elements
   * within `rootRef`, and will cycle through them when it reaches the end (in both directions).
   * @param event
   */
  const handleTabKeyDown = React.useCallback(
    event => {
      if (!isActive || !nodes?.length) return

      event.preventDefault()
      const increment = reverse ? -1 : 1
      const idx = wrap(0, nodes.length - 1, focusIdx + increment)
      setFocusIdx(idx)
    },
    [focusIdx, isActive, nodes, reverse],
  )

  const handleShiftKeyDown = () => {
    setReverse(true)
  }

  const handleShiftKeyUp = () => {
    setReverse(false)
  }

  /**
   * When the desired "focusIdx" changes, attempt to call focus() on the Node at that index.
   */
  React.useEffect(() => {
    if (!isActive || !nodes?.length) return

    const focusNextNode = () => {
      const elToFocus = nodes[focusIdx]
      if (elToFocus?.focus) {
        elToFocus.focus()
      }
    }

    focusNextNode()
  }, [focusIdx, isActive, nodes])

  /**
   * When the component is active and we have a valid root element, find all of the "focusable" elements within.
   */
  React.useEffect(() => {
    if (!isActive || !rootRef?.current) return

    const setFocusableNodesList = () => {
      const nodeList: NodeListOf<Element> = rootRef.current.querySelectorAll(
        FOCUSABLE_NODE_TYPES.join(', '),
      )
      const focusableNodes = (Array.from(nodeList) as HTMLElement[]).filter(
        node => node.tabIndex >= 0,
      )
      setNodes(focusableNodes)
    }

    setFocusableNodesList()
  }, [isActive, rootRef])

  /**
   * Once our "focusable nodes" have been found, check if the initial focus element
   * is in the list, and store that index as our focused one.
   * This will ensure the index we are tracking is actually the currently-focused one.
   */
  React.useEffect(() => {
    if (!isActive || !nodes?.length || !initialFocusRef?.current) return

    const focusInitialRef = () => {
      const idx = nodes.findIndex(node => node === initialFocusRef.current)
      if (idx && idx !== -1) {
        setFocusIdx(idx)
      }
    }

    focusInitialRef()
  }, [initialFocusRef, isActive, nodes])

  /**
   * Effect handler to bind 'focus' event listeners on the main root element.
   * This will be used to track outside focus on child elements.
   * (e.g. clicking directly instead of tabbing)
   */
  React.useEffect(() => {
    if (!rootRef?.current) return

    const ref = rootRef.current
    ref.addEventListener('focus', handleFocusEvent, { capture: true })

    return () => {
      ref.removeEventListener('focus', handleFocusEvent, { capture: true })
    }
  }, [handleFocusEvent, rootRef])

  // setup handling for tab and shift key overrides
  useHotkeys({
    keyDownHandlers: {
      [Keys.Tab]: handleTabKeyDown,
      [Keys.ShiftLeft]: handleShiftKeyDown,
      [Keys.ShiftRight]: handleShiftKeyDown,
    },
    keyUpHandlers: {
      [Keys.ShiftLeft]: handleShiftKeyUp,
      [Keys.ShiftRight]: handleShiftKeyUp,
    },
  })
}
