import styled from '@emotion/styled'
import React from 'react'

import { Breakpoint, colors, useBreakpoint } from 'packages/styles'
import { DateFormat, format } from 'packages/utils/dateHelpers'

import { ScheduleSizes } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { VIRTUALIZED_SCHEDULE_CLASSNAME } from 'app/hkhub/components/schedule/schedule.types'

import { useScheduleContext } from '../VirtualizedSchedule/VirtualizedSchedule.context'
import { DateRangeHeaderCell } from './components'

const St = {
  Container: styled.div`
    background: ${colors.midnight};
  `,

  Content: styled.div<{ breakpoint: Breakpoint; offset: number | null }>`
    display: flex;
    height: ${({ breakpoint }) =>
      `${ScheduleSizes.TimelineHeight[breakpoint]}px`};
    width: ${({ offset }) => `calc(100% - ${offset || 0}px)`};
  `,
}

/**
 * Calculates the difference between the inner schedule component
 * and the page's full width. This allows us to set DateRangeHeader's
 * width to match this while accounting for scrollbars. This ensures that the
 * header's cells are aligned more or less perfectly with the cells of the schedule rows.
 *
 * The offset is calculated as "full body width - width of inner schedule component".
 * If the user does not have scrollbars showing, this will be 0.
 * If the user does have scrollbars showing, this will be the width of said scrollbars.
 */
const useScheduleWidthOffset = () => {
  const [offset, setOffset] = React.useState<number | null>(null)

  const bodyWidth = document?.body?.offsetWidth

  const matchScheduleWidth = React.useCallback(
    (attempts = 1) => {
      // measure the inner component for the virtualized list
      // using 'offsetWidth' here accounts for potential scrollbar width
      const scheduleContainer = document?.querySelector(
        `.${VIRTUALIZED_SCHEDULE_CLASSNAME} > div`,
      ) as HTMLElement
      const scheduleWidth = scheduleContainer?.offsetWidth

      if (!scheduleWidth) {
        // this will often fire before the schedule has rendered, but retrying a few times will resolve that
        if (attempts < 10) {
          setTimeout(() => {
            matchScheduleWidth(attempts + 1)
          }, 50)
        } else {
          // worst-case scenario, if we can't find it, just assume no offset
          // and let the header fill the full width
          setOffset(0)
        }
      } else {
        setOffset(bodyWidth - scheduleWidth)
      }
    },
    [bodyWidth],
  )

  if (offset === null) {
    matchScheduleWidth()
  }

  return offset
}

export enum DateRangeHeaderTestIds {
  container = 'DateRangeHeader__container',
}

export const DateRangeHeader: React.FC = () => {
  const { dateRange } = useScheduleContext()
  const offset = useScheduleWidthOffset()
  const breakpoint = useBreakpoint()

  return (
    <St.Container>
      <St.Content
        breakpoint={breakpoint}
        data-testid={DateRangeHeaderTestIds.container}
        offset={offset}
      >
        {dateRange.map(date => (
          <DateRangeHeaderCell
            date={date}
            key={format(date, DateFormat.ApiUtcShort)}
          />
        ))}
      </St.Content>
    </St.Container>
  )
}
