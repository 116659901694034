import get from 'lodash/get' // eslint-disable-line

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { User, UserAttributeNames } from 'app/hkhub/store/users/users.types'

import { HousekeepersState } from '../housekeepers.types'

/**
 * Returns a user by user id
 *
 * @param state
 * @param userId
 */
export const getHkUserById = (
  state: HousekeepersState,
  userId: string,
): User => {
  const rawUser = get(state.user, userId)
  return transformNormalizedToTyped<User>(rawUser, UserAttributeNames)
}
