import React from 'react'
import { useSelector } from 'react-redux'

import { useBreakpoint } from 'packages/styles'

import { useCurrentCardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { getCleanBucketsByHkAndDate } from 'app/hkhub/store/cleans/selectors'
import { getCoverageBucketsByHkAndDate } from 'app/hkhub/store/coverage/selectors/getCoverageBucketsByHkAndDate'
import { getBucketedHkAvailabilities } from 'app/hkhub/store/hkAvailabilities/selectors/getBucketedHkAvailabilities'
import { UnassignedHk } from 'app/hkhub/store/housekeepers/housekeepers.utils'
import { getHksSortedByTier } from 'app/hkhub/store/housekeepers/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { getVisitBucketsByHkAndDate } from 'app/hkhub/store/visits/selectors'

import { VirtualizedStaffSchedule } from './VirtualizedStaffSchedule'

const EMPTY_ARRAY = Object.freeze([])

type VirtualizedStaffScheduleContainerProps = {
  dateRange: Date[]
}

export const VirtualizedStaffScheduleContainer: React.FC<
  VirtualizedStaffScheduleContainerProps
> = ({ dateRange }) => {
  const breakpoint = useBreakpoint()
  const cardSize = useCurrentCardSize()

  const hkBuckets = useSelector(getHksSortedByTier)

  // pre-sort all HKs, with our "magic unassigned" HK being first
  // the order we define here is the exact order in which they will be rendered
  const hks = React.useMemo(() => {
    const {
      agencyHks = EMPTY_ARRAY,
      contractorHks = EMPTY_ARRAY,
      employeeHks = EMPTY_ARRAY,
    } = hkBuckets

    return [UnassignedHk, ...employeeHks, ...contractorHks, ...agencyHks]
  }, [hkBuckets])

  const hkDateCleanBuckets = useSelector((state: ApplicationState) =>
    getCleanBucketsByHkAndDate(state, dateRange),
  )

  const hkDateVisitBuckets = useSelector((state: ApplicationState) =>
    getVisitBucketsByHkAndDate(state, dateRange),
  )

  const hkAvailabilities = useSelector((state: ApplicationState) =>
    getBucketedHkAvailabilities(state),
  )

  const hkDateCoverageBuckets = useSelector((state: ApplicationState) =>
    getCoverageBucketsByHkAndDate(state, dateRange),
  )

  return (
    <VirtualizedStaffSchedule
      breakpoint={breakpoint}
      cardSize={cardSize}
      hkDateCleanBuckets={hkDateCleanBuckets}
      hkDateCoverageBuckets={hkDateCoverageBuckets}
      hkDateVisitBuckets={hkDateVisitBuckets}
      hkAvailabilityBuckets={hkAvailabilities}
      hks={hks}
    />
  )
}
