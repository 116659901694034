import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'

import { smallScreen } from 'packages/styles'
import { centerWithFlex, colors, layers } from 'packages/styles/jss'

type WithOverrides = {
  overrides?: SerializedStyles
}

export const MenuItem = styled.div<WithOverrides>`
  color: black;
  cursor: pointer;
  padding: 6px 12px;
  width: 100%;

  &:hover {
    background: ${colors.midnight10};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.midnight30};
  }

  ${({ overrides }) => overrides};
`

export const MenuToggle = styled.span<WithOverrides>`
  ${centerWithFlex};
  ${smallScreen(
    css`
      font-size: 14px;
    `,
  )}

  color: white;
  font-weight: bold;
  padding: 0 4px;

  &:hover {
    cursor: pointer;
  }

  ${({ overrides }) => overrides};
`

export const MenuWrapper = styled.div<WithOverrides>`
  background: white;
  border-radius: 4px;
  border: 1px solid ${colors.midnight30};
  z-index: ${layers.dropdownMenu};

  ${({ overrides }) => overrides};
`
