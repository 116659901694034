import * as React from 'react'

import styles from './ButtonGroup.module.scss'

export type ButtonGroupProps = {
  children: React.ReactNode
}

const ButtonGroup: React.SFC<ButtonGroupProps> = ({ children }) => (
  <div className={styles.buttonGroup} role="group">
    {children}
  </div>
)

export { ButtonGroup as UnwrappedButtonGroup }
/**
 * A simple utility to allow buttons to be grouped together
 * with their styles adjusted accordingly (e.g. removing inner border radii).
 */
export default React.memo(ButtonGroup)
