import { deprecated } from 'typesafe-actions'

import { ZonesActionTypes } from '../zones.types'

const { createStandardAction } = deprecated

export const clearZoneAction = createStandardAction(
  ZonesActionTypes.CLEAR_ZONE,
)()

export const clearZone = () => dispatch => dispatch(clearZoneAction())
