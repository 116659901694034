import get from 'lodash/get' // eslint-disable-line

import { format } from 'packages/utils/dateHelpers'

import { ApplicationState } from 'app/hkhub/store/store'

import { HkAvailability } from '../hkAvailabilities.types'
import { getBucketedHkAvailabilities } from './getBucketedHkAvailabilities'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const PLACEHOLDER_AVAILABILITY = {} as HkAvailability

/**
 * Returns either an HkAvailability for a given Hk/Date combo, or undefined if the
 * Hk is completely unavailable for the given date.
 *
 * Note that this selector is NOT memoized, because it is a lighter selector which
 * piggy-backs off the memoized selector that creates all the buckets. This one
 * is ultimately just a fancy lookup selector against the full table, so there is no
 * major benefit in attempting to memoize it.
 *
 * @param state
 * @param hkId
 * @param date
 */
export const getHkAvailabilityForDate = (
  state: ApplicationState,
  hkId: string,
  date: Date,
): HkAvailability | undefined => {
  const buckets = getBucketedHkAvailabilities(state)

  /*
   * NOTE:
   * Due to API limitations, the range of availabilities the API will give us is
   *   - 1 month BACK from today
   *   - 4 months FORWARD from today
   *
   * Anything outside of this window will simply return empty results. If this is the
   * case, we will just want to show "open availability" in the UI. Thus, we need
   * to return SOMETHING here, so the UI will view the date as available.
   */
  const hasResults = Object.keys(buckets).length > 0
  if (!hasResults) {
    return PLACEHOLDER_AVAILABILITY
  }

  const dateKey = format(date, 'yyyy-MM-dd')
  return get(buckets, [hkId, dateKey])
}
