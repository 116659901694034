import styled from '@emotion/styled'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Alert,
  ConfirmModalHookProps,
  ConfirmModalSlugsType,
  Loader,
  useCompleteModal,
  useErrorContext,
  useToast,
} from 'packages/common'
import { fullName } from 'packages/grimoire'
import { ReduxDispatch } from 'packages/grimoire/src/utils'
import { IconName } from 'packages/iconic'
import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'
import { createDateObject } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { updateClean } from 'app/hkhub/store/cleans/actions'
import { Clean } from 'app/hkhub/store/cleans/cleans.types'
import { ApplicationState } from 'app/hkhub/store/store'
import { setLoadingCleanId } from 'app/hkhub/store/ui/actions'
import { getLoadingCleanId } from 'app/hkhub/store/ui/selectors'

export enum CleanMarkCompleteSectionTestIds {
  markComplete = 'CleanMarkCompleteSection__markComplete',
}

export type CleanMarkCompleteSectionProps = {
  clean: Clean
  cleanIsFrozen: boolean
}

const completeModalSlugs: ConfirmModalSlugsType = {
  message: Slugs.completeCleanByManagerModalText,
  title: Slugs.completeCleanByManagerModalTitle,
}

const assignedToContractor = (clean: Clean): boolean =>
  !!(clean.assignedHks || []).find(
    emp => emp?.employeeType === 'contractor' || emp?.employeeType === 'agency',
  )

const St = {
  CompleteIcon: styled(SvgIcon)`
    color: ${colors.lake};
    padding: 0 16px;
  `,

  ContractorAlert: styled(Alert)`
    font-weight: normal;
    margin-top: 16px;
    max-width: 320px;
  `,

  LoaderWrapper: styled.div`
    position: relative;
  `,
}

function useTranslations(clean: Clean) {
  const { t } = useI18n()

  const hk = (clean.assignedHks || [])[0]
  const name = hk ? fullName(hk.user) : ''

  return {
    contractorAlert: t(Slugs.completeContractorCleanModalAlert),
    contractorMsg: t(Slugs.completeContractorCleanModalText, { name }),
    errorMsg: t(Slugs.markCleanCompleteError),
    successMsg: t(Slugs.markCleanCompleteSuccess),
    text: t(Slugs.markCleanComplete),
  }
}

export const CleanMarkCompleteSection: React.FC<CleanMarkCompleteSectionProps> =
  ({ clean, cleanIsFrozen }) => {
    const dispatch = useDispatch<ReduxDispatch<ApplicationState>>()
    const loadingCleanId = useSelector(getLoadingCleanId)

    const { showToast } = useToast()
    const { setBannerError } = useErrorContext()
    const strings = useTranslations(clean)

    const isContractorClean = assignedToContractor(clean)
    const cleanIsCompletable = (clean: Clean) =>
      !clean.startedAt && cleanIsFrozen

    const completeClean = React.useCallback(async () => {
      const date = createDateObject()
      dispatch(setLoadingCleanId(clean.id))

      try {
        await dispatch(
          updateClean({
            completedAt: date,
            id: clean.id,
            startedAt: date,
          }),
        )

        showToast({ message: strings.successMsg })
      } catch (e) {
        setBannerError({
          message: strings.errorMsg,
        })
      } finally {
        dispatch(setLoadingCleanId(''))
      }
    }, [
      clean.id,
      dispatch,
      setBannerError,
      showToast,
      strings.errorMsg,
      strings.successMsg,
    ])

    const employeeModalConfig: ConfirmModalHookProps = {
      onConfirm: completeClean,
      slugs: completeModalSlugs,
    }

    const contractorModalConfig: ConfirmModalHookProps = {
      extraChildren: (
        <St.ContractorAlert alertType={'warning'}>
          <em>{strings.contractorAlert}</em>
        </St.ContractorAlert>
      ),
      onConfirm: completeClean,
      slugs: {
        message: strings.contractorMsg,
        title: completeModalSlugs.title,
      },
    }

    const { showModal } = useCompleteModal(
      isContractorClean ? contractorModalConfig : employeeModalConfig,
    )

    return cleanIsCompletable(clean) ? (
      <St.LoaderWrapper>
        <St.CompleteIcon
          centerItems={true}
          dataTestId={CleanMarkCompleteSectionTestIds.markComplete}
          icon={IconName.checkSquare}
          onClick={showModal}
          size={20}
          text={strings.text}
          textFontSize={16}
        />
        {!!loadingCleanId && <Loader />}
      </St.LoaderWrapper>
    ) : null
  }
