import styled from '@emotion/styled'
import React from 'react'

import { CleanBadge, CleanBadgeTypes, TooltipPosition } from 'packages/common'
import {
  cleanIsB2b,
  cleanIsLateCheckout,
  cleanIsPotentialB2b,
} from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Clean } from 'app/hkhub/store/cleans'

const badgeBackground: Partial<Record<CleanBadgeTypes, string>> = {
  [CleanBadgeTypes.B2B]: colors.midnight,
  [CleanBadgeTypes.Late]: colors.midnight20,
  [CleanBadgeTypes.PotentialB2B]: 'white',
}

const badgeTextColor: Partial<Record<CleanBadgeTypes, string>> = {
  [CleanBadgeTypes.B2B]: 'white',
  [CleanBadgeTypes.Late]: colors.midnight80,
  [CleanBadgeTypes.PotentialB2B]: colors.midnight70,
}

const badgeBorderColor: Partial<Record<CleanBadgeTypes, string>> = {
  ...badgeTextColor,
  [CleanBadgeTypes.Late]: colors.midnight30,
}

const St = {
  Badge: styled(CleanBadge)`
    align-items: center;
    background: ${({ type }) => badgeBackground[type]};
    border-color: ${({ type }) => badgeBorderColor[type]};
    color: ${({ type }) => badgeTextColor[type]};
    display: inline-flex;
    height: 16px;
    padding: 0 4px;
  `,

  CameraIcon: styled(SvgIcon)`
    color: ${colors.success};
    margin-right: 3px;
  `,

  CleanCardBadges: styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
  `,

  TeamCleanIcon: styled(SvgIcon)`
    color: ${colors.awareness};
    // we need to override the higher-specificity margin-left set on base SvgIcon
    margin-left: 0 !important;
    margin-right: 3px;
  `,
}

export enum CleanCardBadgesTestIds {
  taskPhotos = 'CleanCardBadges__taskPhotos',
  teamClean = 'CleanCardBadges__teamClean',
}

export type CleanCardBadgesProps = {
  clean: Clean
}

export const CleanCardBadges: React.FC<CleanCardBadgesProps> = ({ clean }) => {
  const hasTaskPhotos = clean.hasCleanPhotos
  const isTeamClean = clean.assignedHks.length > 1

  return (
    <St.CleanCardBadges>
      {hasTaskPhotos && (
        <St.CameraIcon
          dataTestId={CleanCardBadgesTestIds.taskPhotos}
          icon={IconName.camera}
          size={18}
        />
      )}

      {isTeamClean && (
        <St.TeamCleanIcon
          dataTestId={CleanCardBadgesTestIds.teamClean}
          icon={IconName.zap}
          size={20}
        />
      )}

      {cleanIsB2b(clean) && (
        <St.Badge
          tooltipPosition={TooltipPosition.Bottom}
          type={CleanBadgeTypes.B2B}
        />
      )}

      {cleanIsLateCheckout(clean) && (
        <St.Badge
          tooltipPosition={TooltipPosition.Bottom}
          type={CleanBadgeTypes.Late}
        />
      )}

      {cleanIsPotentialB2b(clean) && (
        <St.Badge
          tooltipPosition={TooltipPosition.Bottom}
          type={CleanBadgeTypes.PotentialB2B}
        />
      )}
    </St.CleanCardBadges>
  )
}
