import React from 'react'

import { AsyncState } from 'packages/utils/hooks'

export type TicketDrawerContextType = {
  fetchTicketState: AsyncState<unknown>
}

export const TicketDrawerContext = React.createContext<TicketDrawerContextType>(
  {
    fetchTicketState: {
      loading: false,
    },
  },
)

export const useTicketDrawerContext = (): TicketDrawerContextType =>
  React.useContext(TicketDrawerContext)
