import * as React from 'react'

export type DrawerState = {
  forceClose: boolean
  initialUnitId?: string
  isOpen: boolean
}

export type UseManageDrawerState = {
  closeDrawer: () => void
  completeDrawerClose: () => void
  drawerState: DrawerState
  openDrawer: () => void
}

export const useManageDrawerState = (): UseManageDrawerState => {
  const [drawerState, setDrawerState] = React.useState<DrawerState>({
    forceClose: false,
    isOpen: false,
  })

  const openDrawer = React.useCallback(() => {
    setDrawerState(prev => ({
      ...prev,
      forceClose: false,
      isOpen: true,
    }))
  }, [])

  const closeDrawer = React.useCallback(() => {
    setDrawerState(prev => ({ ...prev, forceClose: true }))
  }, [])

  const completeDrawerClose = React.useCallback(() => {
    setDrawerState(prev => ({
      ...prev,
      forceClose: false,
      isOpen: false,
    }))
  }, [])

  return { closeDrawer, completeDrawerClose, drawerState, openDrawer }
}
