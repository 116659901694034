/**********************************************************
 * UTILITY TYPES
 *********************************************************/

import { JSONApiObject, JSONApiObjectMap } from '../utils'

// enum values for the API `unit.realtimeStatus` computed property
export enum UnitRealtimeStatus {
  CLEAN_COMPLETED = 'clean_completed',
  CLEAN_IN_PROGRESS = 'clean_in_progress',
  CLEAN_IN_PROGRESS_OVERDUE = 'clean_in_progress_overdue',
  CLEAN_READY = 'clean_ready',
  CLEAN_READY_OVERDUE = 'clean_ready_overdue',
  OCCUPIED_GUEST = 'occupied_guest',
  OWNER_HOLD = 'owner_hold',
  UNKNOWN = 'unknown',
  VACANT_NO_VACASA_CLEAN = 'vacant_no_vacasa_clean',
  VACASA_HOLD = 'vacasa_hold',
}

export type UnitDeepCleanStats = {
  b2B: number
  daysOccupied: number
  staysOver30Days: number
}

export type UnitVisitStats = {
  customInspectionItemsCount: number
  scheduledVisitCompletionPercentage: string
  totalChecklistItems: number
}

export type UnitStatistics = {
  deepCleanStats?: UnitDeepCleanStats
  lastInspectionCompletedAt: string | null
  visitStats?: UnitVisitStats
}

export type UnitOncallUserAttributes = {
  firstName: string
  lastName: string
  mobilePhone: string
  reasonForAssignment: UnitOnCallAssignmentReason
}

export type JSONApiUnitOncallUser = JSONApiObject<UnitOncallUserAttributes>

export type JSONApiUnitOncallUsersMap =
  JSONApiObjectMap<UnitOncallUserAttributes>

/**********************************************************
 * AMENITIES TYPES
 *********************************************************/
export enum BedAmenities {
  doubleBeds = 'doubleBeds',
  futon = 'futon',
  kingBeds = 'kingBeds',
  queenBeds = 'queenBeds',
  sofabed = 'sofabed',
  twinBeds = 'twinBeds',
}

export const OrderedBedAmenities = [
  BedAmenities.kingBeds,
  BedAmenities.queenBeds,
  BedAmenities.doubleBeds,
  BedAmenities.twinBeds,
  BedAmenities.futon,
  BedAmenities.sofabed,
]

export enum BathAmenities {
  fullBaths = 'fullBaths',
  halfBaths = 'halfBaths',
}

export const OrderedBathAmenities = [
  BathAmenities.fullBaths,
  BathAmenities.halfBaths,
]

export enum HotTubAmenities {
  hotTub = 'hotTub',
}

export enum NetworkAmenities {
  wifi = 'wifi',
  wifiNetwork = 'wifiNetwork',
  wifiPassword = 'wifiPassword',
}

export type Amenities = {
  [BathAmenities.fullBaths]?: number
  [BathAmenities.halfBaths]?: number
  [BedAmenities.doubleBeds]?: number
  [BedAmenities.futon]?: number
  [BedAmenities.kingBeds]?: number
  [BedAmenities.queenBeds]?: number
  [BedAmenities.sofabed]?: number
  [BedAmenities.twinBeds]?: number
  [HotTubAmenities.hotTub]?: number
  [NetworkAmenities.wifi]?: boolean
  [NetworkAmenities.wifiNetwork]?: string
  [NetworkAmenities.wifiPassword]?: string
}

/**********************************************************
 * UNIT
 *********************************************************/
export type AllUnitAttributes = {
  address: string
  alarmCode: string
  amenities: Amenities
  bathrooms: number
  bedrooms: number
  beds: number
  city: string
  gateCode: string
  hasSmartLock: boolean
  hottub: boolean
  housekeeperNotes: string | null
  isActive: boolean
  lastDeepCleanedAt: string | null
  maxOccupancy: number
  name: string
  nextDeepCleanDueAt: string | null
  oncallUserId?: string
  rateContractorDeepClean?: string
  rateContractorPostStay?: string
  realtimeStatus: UnitRealtimeStatus
  state: string
  statistics: UnitStatistics | null
  tz: string
  unitCode: string
}

export type UnitOnCallAssignmentReason =
  | 'standard_availability'
  | 'cross_coverage'
  | 'coverage_partner'
  | 'manager'
  | 'manual'
  | 'zone_disposition_default'
  | 'non_urgent_default'

export const AllUnitAttributeNames = [
  'address',
  'alarmCode',
  'amenities',
  'bathrooms',
  'bedrooms',
  'beds',
  'city',
  'gateCode',
  'hasSmartLock',
  'hottub',
  'housekeeperNotes',
  'isActive',
  'lastDeepCleanedAt',
  'maxOccupancy',
  'name',
  'nextDeepCleanDueAt',
  'rateContractorDeepClean',
  'rateContractorPostStay',
  'realtimeStatus',
  'state',
  'statistics',
  'tz',
  'unitCode',
]
