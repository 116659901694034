import { ValueOf } from 'packages/grimoire/src/utils'
// TODO: move to grimoire
import { FREEZE_WINDOW_DAYS } from 'packages/utils/constants'
import {
  addDays,
  createDateObject,
  differenceInDays,
  format,
  startOfDay,
} from 'packages/utils/dateHelpers'

import { DateCleanBuckets } from 'app/hkhub/store/cleans'
import { DateVisitBuckets } from 'app/hkhub/store/visits'

import { CleanAssignmentStatus } from '../schedule.types'

export const DEFAULT_FUTURE_CLEAN_RANGE = FREEZE_WINDOW_DAYS - 1

/**
 * Finds and returns the child "tasks bucket" from the provided set, based on the specified date key.
 * If no tasks bucket is found with the associated date, an empty Array is returned.
 * @param dateTaskBuckets
 * @param date
 */
export function getTasksForDate<
  T extends DateCleanBuckets | DateVisitBuckets = {},
>(dateTaskBuckets: T, date: Date): ValueOf<T> {
  const dateKey = format(date, 'yyyy-MM-dd')
  const tasksForDate: ValueOf<T> = dateTaskBuckets[dateKey] as ValueOf<T>
  return tasksForDate ?? []
}

/**
 * Parses the appropriate status for an assigned clean based on its date in relation to today.
 * @param cleanDate
 * @param defaultStatus
 * @param futureRange
 */
export const getAssignedCleanStatusByDate = (
  cleanDate: Date,
  defaultStatus: CleanAssignmentStatus = CleanAssignmentStatus.Default,
  futureRange: number = DEFAULT_FUTURE_CLEAN_RANGE,
): CleanAssignmentStatus => {
  const today = createDateObject()
  const todayStart = startOfDay(today)
  const cleanDateStart = startOfDay(cleanDate)

  if (differenceInDays(todayStart, cleanDateStart) > 0) {
    return CleanAssignmentStatus.Past
  }

  const startOfFutureDate = startOfDay(addDays(today, futureRange))
  if (differenceInDays(startOfFutureDate, cleanDateStart) < 0) {
    return CleanAssignmentStatus.Future
  }

  return defaultStatus
}

const unassignedStatusMap = Object.freeze({
  [CleanAssignmentStatus.Future]: CleanAssignmentStatus.UnassignedFuture,
  [CleanAssignmentStatus.Past]: CleanAssignmentStatus.UnassignedPast,
})

/**
 * Parses the appropriate status for an unassigned clean based on its date in relation to today.
 * @param cleanDate
 * @param defaultStatus
 * @param futureRange
 */
export const getUnassignedCleanStatusByDate = (
  cleanDate: Date,
  defaultStatus: CleanAssignmentStatus = CleanAssignmentStatus.Unassigned,
  futureRange: number = DEFAULT_FUTURE_CLEAN_RANGE,
): CleanAssignmentStatus => {
  const status = getAssignedCleanStatusByDate(
    cleanDate,
    defaultStatus,
    futureRange,
  )
  if (status in unassignedStatusMap) {
    return unassignedStatusMap[status]
  }

  return defaultStatus
}
