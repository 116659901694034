import classNames from 'classnames'
import * as React from 'react'
import { createPortal } from 'react-dom'

import { SvgIcon, IconName } from 'packages/iconic'

import {
  useLegacyTransition,
  DEFAULT_TRANSITION_TIME,
  TransitionPhaseType,
} from '../hooks/useLegacyTransition'
import { disableBodyScrolling, resetBodyScrolling } from './LegacyModal.helpers'
import {
  LegacyModalSharedProps,
  LegacyModalRenderProps,
} from './LegacyModal.types'

import styles from './LegacyModal.module.scss'

const KEY_ESC = 27
const BASE_Z_INDEX = 450

type LegacyModalBaseProps = {
  /** Optional style object for overriding the styling on the Modal body */
  bodyStyleOverrides?: React.CSSProperties
  /** Render props callback for handling rendering the actual content of the Modal */
  renderModalContent: (props: LegacyModalRenderProps) => React.ReactNode
}

export type LegacyModalProps = LegacyModalBaseProps & LegacyModalSharedProps

const LegacyModal: React.FunctionComponent<LegacyModalProps> = ({
  afterClose,
  canModalClose = true,
  bodyStyleOverrides = {},
  hideOnOutsideClick = false,
  renderContainer,
  renderModalContent,
  showing,
  transitionTime = DEFAULT_TRANSITION_TIME,
}) => {
  /* eslint-disable @typescript-eslint/no-use-before-define */
  const { beginTransitionOut, transitionPhase } = useLegacyTransition({
    afterExit: handleAfterExitTransition,
    beforeEnter: handleBeforeEnterTransition,
    showing,
    transitionTime,
  })
  /* eslint-enable @typescript-eslint/no-use-before-define */

  // total number of modal instances showing
  // we will need this for setting z-index appropriately, to make sure multiple simultaneous modal are displayed correctly
  const modalCount = React.useRef(0)

  React.useEffect(() => {
    // when it shows, count all current modal instances
    if (showing) {
      const q = 'div[class*="modalWrapper"]'
      modalCount.current = document.querySelectorAll(q).length
    }
  }, [showing])

  const handleKeyUp = React.useCallback(
    event => {
      if (event.keyCode === KEY_ESC && canModalClose) {
        event.preventDefault()
        event.stopPropagation()
        beginTransitionOut()
      }
    },
    [beginTransitionOut, canModalClose],
  )

  React.useEffect(() => {
    // add keyup listener when in "idle" state, but remove it for all others
    if (transitionPhase === TransitionPhaseType.idle) {
      document.body.addEventListener('keyup', handleKeyUp)
    }

    return () => {
      document.body.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyUp, transitionPhase])

  function handleBeforeEnterTransition() {
    disableBodyScrolling()
  }

  function handleAfterExitTransition() {
    resetBodyScrolling()
    afterClose()
  }

  function handleBackdropClick() {
    if (hideOnOutsideClick && canModalClose) {
      beginTransitionOut()
    }
  }

  const modalContent = () => {
    const offsetZIndex = BASE_Z_INDEX + modalCount.current
    return (
      <div id={`modalPortal-${modalCount.current}`} tabIndex={-1}>
        <div aria-label="Modal Dialog" role="dialog" tabIndex={-1}>
          <div
            className={styles.modalWrapper}
            role="document"
            style={{ zIndex: offsetZIndex }}
          >
            <div
              className={classNames(styles.modalBody, styles[transitionPhase])}
              style={{
                transition: `transform ${transitionTime}ms ease-out`,
                ...bodyStyleOverrides,
              }}
            >
              <div className={styles.closeIconWrapper}>
                {canModalClose && (
                  <SvgIcon
                    className={styles.closeIcon}
                    dataTestId={'closeModalX'}
                    icon={IconName.x}
                    onClick={beginTransitionOut}
                    size={24}
                  />
                )}
              </div>
              {renderModalContent({ beginTransitionOut })}
            </div>
          </div>
        </div>

        <div
          className={classNames(styles.modalBackdrop, styles[transitionPhase])}
          onClick={handleBackdropClick}
          style={{
            transition: `opacity ${transitionTime}ms ease-out`,
            zIndex: offsetZIndex - 1,
          }}
        />
      </div>
    )
  }

  return showing
    ? createPortal(modalContent(), renderContainer || document.body)
    : null
}

/**
 * Basic top-level Modal component.
 *
 * Note that this component itself does not handle any actual content rendering. It provides the base
 * transition styles, wrapper, and top-level Modal functionality, but a sub-component must implement
 * the `renderModalContent` render prop for the actual content to be rendered.
 *
 * **Additional Notes**
 * - Pressing ESC key at any time will close the Modal
 */
export default React.memo(LegacyModal)
