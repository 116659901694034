import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

export type LockboxesState = {
  data: JSONApiLockboxesMap
}

/**********************************************************
 * LOCKBOX TYPES
 *********************************************************/
export type LockboxAttributes = {
  backupLock: string
  notes: string
  primaryLock: string
}

export const LockboxAttributeNames = ['backupLock', 'notes', 'primaryLock']

export type LockboxRelationships = {
  unit: ToOneRelationship
}

export type RawLockbox = JSONApiObjectWithRelationships<
  LockboxAttributes,
  LockboxRelationships
>

export type JSONApiLockboxesMap = JSONApiObjectWithRelationshipsMap<
  LockboxAttributes,
  LockboxRelationships
>

export type Lockbox = {
  id: string
} & LockboxAttributes
