import {
  curry,
  filter,
  groupBy,
  map,
  merge,
  pipe,
  propEq,
  reduce,
  toPairs,
  values,
} from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { Clean, RawClean, UnitCleanSchedule } from '../cleans.types'
import { getDateRangeMap, hydrateRawClean } from '../cleans.utils'

const subGroupByDate = (
  acc: UnitCleanSchedule,
  item: [string, Clean[]],
): UnitCleanSchedule => {
  const [unitId, cleansBucket] = item
  const cleansByDate = groupBy('effectiveDate', cleansBucket)
  acc[unitId] = cleansByDate
  return acc
}

const isActiveUnit = propEq('attributes.isActive', true)

const reduceToEmptyIdMap = (acc, { id }) => {
  acc[id] = {}
  return acc
}

export const getCleansByDateAndUnit = createSelector(
  (state: ApplicationState) => state.cleans,
  (state: ApplicationState) => state.units,
  (state: ApplicationState) => state.housekeepers,
  (state: ApplicationState) => state.taskPhotos,
  (_, dateRange: Date[]) => dateRange,
  (
    cleansState,
    unitsState,
    hksState,
    taskPhotosState,
    dateRange,
  ): UnitCleanSchedule => {
    const dateRangeMap = getDateRangeMap(dateRange)
    const isWithinDateRange = (clean: RawClean) =>
      clean.attributes.effectiveDate in dateRangeMap

    const hydrator = curry(hydrateRawClean)(
      cleansState,
      unitsState,
      hksState,
      taskPhotosState,
    )

    const activeUnitIds = pipe(
      values,
      filter(isActiveUnit),
      reduce(reduceToEmptyIdMap, {}),
    )(unitsState.data)

    const cleansByUnit = pipe(
      values,
      filter(isWithinDateRange),
      map(hydrator),
      groupBy('unit.id'),
    )(cleansState.data)

    const cleansByUnitAndDate = pipe(
      toPairs,
      reduce(subGroupByDate, {}),
    )(cleansByUnit)

    return merge(activeUnitIds, cleansByUnitAndDate)
  },
)
