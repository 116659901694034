import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import {
  HousekeepersState,
  HousekeeperTier,
  HousekeeperTierAttributeNames,
} from '../housekeepers.types'
import { UnknownTier } from '../housekeepers.utils'

/**
 * Returns the tier information via the tier id
 *
 * @param state
 * @param id the tier id (not a housekeeper id)
 */
export const getHkTierById = (
  state: HousekeepersState,
  id: string | undefined,
): HousekeeperTier => {
  if (!id) return UnknownTier
  const rawTier = state.housekeeperTier[id]
  const tier = transformNormalizedToTyped<HousekeeperTier>(
    rawTier,
    HousekeeperTierAttributeNames,
  )
  return tier
}
