import React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'

import {
  UpNextConfig,
  useDeepCleanUpNextConfig,
} from 'app/hkhub/components/schedule/helpers/deepCleans.helpers'
import { Slugs } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans'

type CleanDrawerDeepCleanExtraInfoConfig = {
  icon: IconName
  showExtraInfoPanel: boolean
  toggleExtraInfoPanel: () => void
}

export type CleanDrawerDeepCleanAlertConfig =
  | (Omit<UpNextConfig, 'subText'> & CleanDrawerDeepCleanExtraInfoConfig)
  | null

type UseCleanDrawerDeepCleanAlertConfig = {
  cleanupAlertOnDrawerClose: () => void
  deepCleanAlertConfig: CleanDrawerDeepCleanAlertConfig
}

/** Hook to wrap all the logic pertaining to the "Deep Clean Due/Overdue" alert */
export const useCleanDrawerDeepCleanAlertConfig = (
  clean?: Clean,
): UseCleanDrawerDeepCleanAlertConfig => {
  const { ut } = useI18n()

  const nextDeepCleanConfig = useDeepCleanUpNextConfig(
    clean?.unit.nextDeepCleanDueAt || null,
  )

  const [showDeepCleanExtraInfo, setShowDeepCleanExtraInfo] =
    React.useState(false)

  /** This icon changes depending on if the extra info panel is open */
  const alertActionIcon = React.useMemo(
    () => (showDeepCleanExtraInfo ? IconName.minusCircle : IconName.plusCircle),
    [showDeepCleanExtraInfo],
  )

  const deepCleanAlertConfig: CleanDrawerDeepCleanAlertConfig =
    React.useMemo(() => {
      // this implicitly encompasses the case where the clean is (for some unknown reason) undefined
      if (!nextDeepCleanConfig || nextDeepCleanConfig.alertType === 'info') {
        return null
      }

      const config: CleanDrawerDeepCleanAlertConfig = {
        alertText: `${ut(Slugs.deep)} ${ut(Slugs.clean)} ${
          nextDeepCleanConfig.alertText
        }`,
        alertType: nextDeepCleanConfig.alertType,
        icon: alertActionIcon,
        showExtraInfoPanel: showDeepCleanExtraInfo,
        toggleExtraInfoPanel: () => setShowDeepCleanExtraInfo(prev => !prev),
      }

      return config
    }, [alertActionIcon, nextDeepCleanConfig, showDeepCleanExtraInfo, ut])

  const cleanupAlertOnDrawerClose = React.useCallback(() => {
    setShowDeepCleanExtraInfo(false)
  }, [])

  return { cleanupAlertOnDrawerClose, deepCleanAlertConfig }
}
