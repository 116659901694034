import React from 'react'

import { CardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks'
import { Clean } from 'app/hkhub/store/cleans'

import { CleanCardStaffViewLabel } from './CleanCardStaffViewLabel'
import { CleanCardUnitViewLabel } from './CleanCardUnitViewLabel'

export type CleanCardLabelProps = {
  clean: Clean
  size: CardSize
}

export const CleanCardLabel: React.FC<CleanCardLabelProps> = ({
  clean,
  size,
}) => {
  const { entity } = useScheduleMatchParams()
  return entity === 'unit' ? (
    <CleanCardUnitViewLabel clean={clean} size={size} />
  ) : (
    <CleanCardStaffViewLabel clean={clean} size={size} />
  )
}
