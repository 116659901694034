import classNames from 'classnames'
import * as React from 'react'
import { createPortal } from 'react-dom'

import { IconName, SvgIcon } from 'packages/iconic'

import { Button } from '../Button'

import styles from './Banner.module.scss'

const DEFAULT_TRANSITION_SPEED = 300

export type BannerLinkConfig = {
  text: string
  url: string
}

export type BannerProps = {
  /**
   * (Optional) Link config for displaying a link AFTER the message in the Banner.
   */
  link?: BannerLinkConfig
  /**
   * Message that will be displayed in the body of the banner.
   */
  message: string
  /**
   * Callback for after the banner is closed.
   */
  onClose: () => void
  /**
   * Specify an optional element into which the Banner's portal should be rendered.
   * By default, this will render directly into the bottom of `document.body`, and in almost all cases,
   * this is the behavior we will want.
   */
  renderContainer?: Element
}

export enum BannerTestIds {
  container = 'Banner__container',
}

/**
 * The Banner component is used for displaying an error that may occur
 * to the user.
 */
export const Banner: React.FC<BannerProps> = React.memo(
  ({ link, message, onClose, renderContainer }) => {
    const [showing, setShowing] = React.useState(false)
    const [msg, setMsg] = React.useState(message)
    const showingRef = React.useRef(false)

    const beginClose = React.useCallback(() => {
      setShowing(false)
      showingRef.current = false
      setTimeout(() => {
        if (!showingRef.current) {
          onClose()
          setMsg('')
        }
      }, DEFAULT_TRANSITION_SPEED)
    }, [onClose])

    React.useEffect(() => {
      if (message) {
        setMsg(message)
        setShowing(true)
        showingRef.current = true
      } else {
        if (showingRef.current) {
          beginClose()
        }
      }
    }, [beginClose, message])

    return createPortal(
      <div
        className={classNames(styles.banner, {
          [styles.showing]: showing,
        })}
      >
        {msg.length ? (
          <div
            data-testid={BannerTestIds.container}
            id={BannerTestIds.container}
            className={styles.bannerContainer}
          >
            <span className={styles.closeIconWrapper}>
              <Button
                ariaLabel={`Close Dialog`}
                dataTestId="banner-closeButton"
                onClick={beginClose}
              >
                <SvgIcon
                  className={styles.closeIcon}
                  icon={IconName.x}
                  size={20}
                />
              </Button>
            </span>

            <span className={styles.bannerContent}>
              <p className={styles.bannerText} data-testid="bannerText">
                {msg}{' '}
                {link && (
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.bannerLink}
                  >
                    {link.text}
                  </a>
                )}
              </p>
            </span>
          </div>
        ) : null}
      </div>,
      renderContainer || document.body,
    )
  },
)
