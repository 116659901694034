import React from 'react'
import { useDispatch } from 'react-redux'

import { fetchHousekeepersByZone } from 'app/hkhub/store/housekeepers/actions'
import { fetchAllZoneNotifications } from 'app/hkhub/store/notifications/actions'
import { AppDispatch } from 'app/hkhub/store/store'
import { fetchUnitsByZoneWithPreferences } from 'app/hkhub/store/units/actions'
import { fetchZoneById } from 'app/hkhub/store/zones/actions'

type UseInitZone = {
  initZone: () => Promise<void>
}

export const useInitZone = (zoneId: string): UseInitZone => {
  const dispatch: AppDispatch = useDispatch()

  const initZone = React.useCallback(async () => {
    await dispatch(fetchZoneById(zoneId))
    await Promise.all([
      dispatch(fetchUnitsByZoneWithPreferences(zoneId)),
      dispatch(fetchHousekeepersByZone(zoneId)),
    ])

    dispatch(fetchAllZoneNotifications(zoneId))
  }, [dispatch, zoneId])

  return { initZone }
}
