import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store'

import { ApplicationState } from '../../store'
import { User, UserAttributeNames } from '../users.types'

const getUsersState = (state: ApplicationState) => state.users
const getUserId = (_, id) => id

export const getUserById = createSelector(
  getUsersState,
  getUserId,
  (usersState, userId) => {
    if (!userId) return undefined
    const rawUser = usersState.data[userId]
    if (!rawUser) return undefined
    return transformNormalizedToTyped<User>(rawUser, UserAttributeNames)
  },
)
