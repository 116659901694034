const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
)

export function register(config = {}) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      checkValidServiceWorker(swUrl, config)

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      navigator.serviceWorker.ready.then(() => {
        console.log(
          'This web app is being served cache-first by a service ' +
            'worker. To learn more, visit https://goo.gl/SC7cgQ',
        )
      })
    } else {
      // Is not local host. Just register service worker
      registerValidSW(swUrl, config)
    }
  }
}

function registerValidSW(swUrl, { onUpdateAvailable }) {
  const swContainer = navigator.serviceWorker

  swContainer
    .register(swUrl)
    .then(registration => {
      /**
       * Call onUpdateAvailable callback when a there is a waiting service worker
       */
      if (onUpdateAvailable) {
        const listenForInstalledServiceWorker = () => {
          registration.installing?.addEventListener('statechange', function () {
            if (this.state === 'installed') onUpdateAvailable(registration)
          })
        }

        if (registration.waiting) {
          onUpdateAvailable(registration)
        } else if (registration.installing) {
          listenForInstalledServiceWorker()
        } else {
          registration.addEventListener(
            'updatefound',
            listenForInstalledServiceWorker,
          )
        }
      }

      /**
       * When a waiting controller is mounted reload all open tabs
       * https://stackoverflow.com/questions/55653236/access-service-worker-skipwaiting-from-within-app-build-with-webpackworkbox
       * https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
       */
      swContainer.addEventListener('controllerchange', () => {
        // The boolean here prevents infinite refresh loop when using the Chrome Dev Tools “Update on Reload” feature
        if (window.swRefreshing) return
        window.location.reload()
        window.swRefreshing = true
      })
    })
    .catch(error => {
      console.error('Error during service worker registration:', error)
    })
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't, reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.',
      )
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
    })
  }
}
