import { filter, map, pipe, propEq, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { arrayToIdMap } from 'packages/utils/collectionHelpers'

import { RawUnit, UnitByIdMap, UnitsState } from '../units.types'
import { hydrateRawUnit } from '../units.utils'

const isActive = propEq('attributes.isActive', true)

/**
 * Returns all active units currently in the store, mapped by ID.
 */
export const getActiveUnitsMap = createSelector(
  (unitsState: UnitsState): Record<string, RawUnit> => unitsState.data,
  (rawUnits): UnitByIdMap => {
    return pipe(
      values,
      filter(isActive),
      map(hydrateRawUnit),
      arrayToIdMap,
    )(rawUnits)
  },
)
