import React from 'react'
import { Provider } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import { useAppAuth } from 'packages/auth'
import {
  ErrorContextWrapper,
  Loader,
  ModalProvider,
  SuperModalProvider,
  SentryErrorBoundary,
  ServiceWorkerWrapper,
} from 'packages/common'
import { ToastCustomContainer } from 'packages/common'
import { FeatureBranchLoginPage } from 'packages/featureBranchLogin/FeatureBranchLoginPage'
import { useIsFeatureBranchDeployment } from 'packages/featureBranchLogin/useIsFeatureBranchDeployment'
import { useIsFeatureBranchLogin } from 'packages/featureBranchLogin/useIsFeatureBranchLogin'
import { WebSocketEvents } from 'packages/webSocketEvents'

import { register as registerSW } from '../../serviceWorker'
import HkHubIndex from './components/HkHubIndex'
import { HkHubAuthContainer } from './HkHubAuth.container'
import { initI18n } from './i18n'
import { setNeedsSilentRefresh } from './store/auth/actions'
import { getAuthToken } from './store/auth/selectors'
import events from './store/events'
import hkHubStore, { ApplicationState } from './store/store'

const {
  REACT_APP_AUTH_BASE_URL: AUTH_URL = '',
  REACT_APP_AUTH_TOKEN_URL: TOKEN_URL = '',
  REACT_APP_AUTH_CLIENT_ID_HKHUB: AUTH_CLIENT_ID = '',
  REACT_APP_SERVICE_WEBSOCKET_URL = '',
} = process.env

if (!REACT_APP_SERVICE_WEBSOCKET_URL) {
  // eslint-disable-next-line no-console
  console.error(
    'WebSocketEvents disabled. make sure REACT_APP_SERVICE_WEBSOCKET_URL is set',
  )
} else {
  const _hkSocketEvents = new WebSocketEvents<ApplicationState>(
    REACT_APP_SERVICE_WEBSOCKET_URL,
    hkHubStore,
    getAuthToken,
    setNeedsSilentRefresh,
    events,
  )
}

const HkHub: React.FC = () => {
  const { authState, onAppAuthInitialized } = useAppAuth()
  const { isFeatureBranchLogin } = useIsFeatureBranchLogin()
  const { isFeatureBranchDeployment } = useIsFeatureBranchDeployment()

  const handleAuthInitialized = React.useCallback(async () => {
    await initI18n()
    await onAppAuthInitialized()
  }, [onAppAuthInitialized])

  return (
    <SentryErrorBoundary boundary={'hk-hub'}>
      <>
        <ServiceWorkerWrapper registerSW={registerSW} />
        <ToastCustomContainer />
        <Provider store={hkHubStore}>
          <ErrorContextWrapper contextName={'hkhub-error-context'}>
            <SuperModalProvider>
              <ModalProvider>
                <HkHubAuthContainer
                  authClientId={AUTH_CLIENT_ID}
                  authUrl={AUTH_URL}
                  onAuthInitialized={handleAuthInitialized}
                  tokenUrl={TOKEN_URL}
                />
                {isFeatureBranchDeployment && (
                  <Routes>
                    <Route
                      path="/feature-branch-login"
                      element={<FeatureBranchLoginPage isHub={true} />}
                    />
                  </Routes>
                )}
                {!isFeatureBranchLogin && (
                  <>
                    {!authState.loaded ? (
                      <Loader />
                    ) : (
                      <HkHubIndex
                        initialUrl={authState.initialUrl}
                        isLogin={isFeatureBranchLogin}
                      />
                    )}
                  </>
                )}
              </ModalProvider>
            </SuperModalProvider>
          </ErrorContextWrapper>
        </Provider>
      </>
    </SentryErrorBoundary>
  )
}

export default HkHub
