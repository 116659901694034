import { noop } from 'lodash'
import React from 'react'
import { useKey, usePrevious } from 'react-use'

import { Keys } from 'packages/utils/constants'
import { useTransition } from 'packages/utils/hooks/useTransition/useTransition'

import { ModalChildrenProps, ModalPortal } from './ModalPortal'

export enum ModalTestIds {
  container = 'Modal__container',
}
export type ModalCloseMethods = 'esc' | 'backdrop'

type OptionalModalProps = {
  disableCloseMethods: ModalCloseMethods[]
  duration: number
  forceClose: boolean
  hideBackdrop: boolean
  hideCloseButton: boolean
}

const defaultOptionalProps: OptionalModalProps = {
  disableCloseMethods: [],
  duration: 250,
  forceClose: false,
  hideBackdrop: false,
  hideCloseButton: false,
}

export type BaseModalProps = Partial<OptionalModalProps> & {
  afterExit: () => void
  childRenderer: (props: ModalChildrenProps) => React.ReactNode
  isOpen: boolean
}

export type ModalProps = BaseModalProps & {
  renderContainer?: Element
}

export const Modal: React.FC<ModalProps> = React.memo(props => {
  const { afterExit, disableCloseMethods, duration, forceClose, isOpen } = props

  const prevForceClose = usePrevious(forceClose)
  const prevIsOpen = usePrevious(isOpen)
  const modalRef = React.useRef<HTMLDivElement>(null)

  const { beginTransitionIn, beginTransitionOut, transitionPhase } =
    useTransition({
      afterExit,
      transitionTime: duration,
    })

  // when the "forceClose" prop changes to true, begin closing the Modal
  React.useEffect(() => {
    if (forceClose && forceClose !== prevForceClose) {
      beginTransitionOut()
    }
  }, [beginTransitionOut, forceClose, prevForceClose])

  React.useEffect(() => {
    if (prevIsOpen === isOpen) return

    if (isOpen) {
      beginTransitionIn()
    }
  }, [beginTransitionIn, isOpen, prevIsOpen])

  useKey(
    Keys.Esc,
    () => {
      if (!disableCloseMethods?.includes('esc')) beginTransitionOut()
    },
    {},
    [disableCloseMethods, beginTransitionOut],
  ) // close Modal on ESC key unless explicitly asked to be disabled

  return (
    <ModalPortal
      {...defaultOptionalProps}
      {...props}
      beginClose={beginTransitionOut}
      onBackdropClick={
        disableCloseMethods?.includes('backdrop') ? noop : beginTransitionOut
      }
      ref={modalRef}
      renderContainer={props.renderContainer}
      transitionPhase={transitionPhase}
    />
  )
})
