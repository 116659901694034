import * as React from 'react'

import {
  CardSize,
  cardSizeEqualOrGreaterThan,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Unit } from 'app/hkhub/store/units'
import { Visit } from 'app/hkhub/store/visits'

import { St } from '../../../../CleanCard/components/CleanCardLabel/CleanCardLabel.styles'

export enum VisitCardStaffViewLabelTestIds {
  container = 'VisitCardStaffViewLabel__container',
}

export type VisitCardStaffViewLabelProps = {
  size: CardSize
  unit?: Unit
  visit: Visit
}

export const VisitCardStaffViewLabel: React.FC<
  VisitCardStaffViewLabelProps
> = ({ visit, unit, size }) => {
  const unitText = unit ? `${unit.unitCode} ${unit.name}` : 'Unknown'

  return (
    <St.Container data-testid={VisitCardStaffViewLabelTestIds.container}>
      <St.PrimaryLabel
        beginWithEllipsisOnTiny={true}
        size={size}
        status={visit.realtimeStatus}
      >
        {unitText}
      </St.PrimaryLabel>

      {cardSizeEqualOrGreaterThan(size, 'medium') && (
        <St.SecondaryLabel size={size} status={visit.realtimeStatus}>
          {unit?.address || ''}
        </St.SecondaryLabel>
      )}
    </St.Container>
  )
}
