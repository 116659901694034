import React from 'react'

import { Drawer } from 'packages/common'
import { isWithinFreezeWindow } from 'packages/grimoire'
import { createDateObject } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean, CleanRealtimeStatus } from 'app/hkhub/store/cleans/cleans.types'
import { Lockbox } from 'app/hkhub/store/lockboxes'

import { CleanDrawerHeader } from '../CleanDrawerHeader'
import { HkNotes } from '../UnitDrawer/components'
import { AccessCodes } from './AccessCodes'
import { CleanAssignments } from './CleanAssignments'
import * as St from './CleanDrawer.styles'
import { CleanInfo } from './CleanInfo'
import { CleanNotesContainer } from './CleanNotes'
import { CleanStatusSection } from './CleanStatusSection'
import { CleanDrawerDeepCleanAlertConfig } from './hooks/useCleanDrawerDeepCleanAlertConfig'
import { InspectionSection } from './InspectionSection'
import { WifiInfo } from './WifiInfo'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    tickets: t(Slugs.tickets),
    unitInfo: t(Slugs.unitInfo),
  }
}

export enum CleanDrawerTestIds {
  content = 'CleanDrawer__content',
  deepCleanDueAlert = 'CleanDrawer__deepCleanDueAlert',
  deepCleanDueAlertExtraInfo = 'CleanDrawer__deepCleanDueAlertExtraInfo',
}

export type CleanDrawerProps = {
  clean: Clean | undefined
  deepCleanAlertConfig: CleanDrawerDeepCleanAlertConfig
  fetchingClean: boolean
  forceCloseDrawer: boolean
  handleAfterExit: () => void
  handleClickAway: (event: MouseEvent) => boolean
  handleForceCloseDrawer: () => void
  hasFetchedNextReservation: boolean
  isOpen: boolean
  lockbox?: Lockbox
}

export const CleanDrawer: React.FunctionComponent<CleanDrawerProps> = ({
  clean,
  deepCleanAlertConfig,
  fetchingClean,
  forceCloseDrawer,
  handleAfterExit,
  handleClickAway,
  handleForceCloseDrawer,
  hasFetchedNextReservation,
  isOpen,
  lockbox,
}) => {
  const cleanDate = createDateObject(clean?.effectiveDate)
  const cleanWithinFreezeWindow = isWithinFreezeWindow(cleanDate)
  const cleanIsComplete =
    clean?.realtimeStatus === CleanRealtimeStatus.COMPLETED
  const cleanIsEditable = cleanWithinFreezeWindow && !cleanIsComplete

  const strings = useTranslations()
  return (
    <Drawer
      afterExit={handleAfterExit}
      forceClose={forceCloseDrawer}
      hideCloseButton={true}
      isOpen={isOpen}
      onClickAway={handleClickAway}
    >
      {!!clean && (
        <St.ContentWrapper data-testid={CleanDrawerTestIds.content}>
          <CleanDrawerHeader
            clean={clean}
            forceCloseDrawer={handleForceCloseDrawer}
          />

          <St.CleanUnitInfo clean={clean} />

          {deepCleanAlertConfig && (
            <>
              <St.DeepCleanAlert
                action={{
                  icon: deepCleanAlertConfig.icon,
                  onClick: deepCleanAlertConfig.toggleExtraInfoPanel,
                }}
                alertType={deepCleanAlertConfig.alertType}
                dataTestId={CleanDrawerTestIds.deepCleanDueAlert}
                isPanelOpen={deepCleanAlertConfig.showExtraInfoPanel}
              >
                <span>{deepCleanAlertConfig.alertText}</span>
              </St.DeepCleanAlert>
              {deepCleanAlertConfig.showExtraInfoPanel && (
                <St.DeepCleanExtraInfoPanel
                  dataTestId={CleanDrawerTestIds.deepCleanDueAlertExtraInfo}
                  reservationBookingType={clean.reservation?.bookingType}
                  unit={clean.unit}
                />
              )}
            </>
          )}

          <CleanStatusSection
            cleanIsFrozen={cleanWithinFreezeWindow}
            clean={clean}
          />

          <CleanInfo
            clean={clean}
            cleanIsEditable={cleanIsEditable}
            hasFetchedNextReservation={hasFetchedNextReservation}
          />
          <St.Divider />
          <CleanAssignments
            clean={clean}
            cleanIsEditable={cleanIsEditable}
            fetchingClean={fetchingClean}
          />

          <St.Divider />

          <CleanNotesContainer canEdit={cleanIsEditable} clean={clean} />

          <St.Divider />

          <St.DrawerSection>
            <St.UnitInfoTitle>{strings.unitInfo}</St.UnitInfoTitle>
          </St.DrawerSection>
          {cleanWithinFreezeWindow && (
            <AccessCodes lockbox={lockbox} unit={clean.unit} />
          )}

          <WifiInfo amenities={clean.unit.amenities} />
          <St.HousekeeperNotes>
            <HkNotes unit={clean.unit} />
          </St.HousekeeperNotes>

          <St.Divider />

          <St.DrawerSection>
            <St.UnitInfoTitle>{strings.tickets}</St.UnitInfoTitle>
          </St.DrawerSection>

          <St.UnitLinks
            reservationId={clean.reservation.id}
            unitId={clean.unit.id}
          />

          <InspectionSection loading={fetchingClean} task={clean} />
        </St.ContentWrapper>
      )}
    </Drawer>
  )
}
