import { UnitRealtimeStatus } from './unit.types'

type UnitWithBeds = {
  bedrooms: number | null
  beds: number | null
}

type UnitWithRealtimeStatus = { realtimeStatus: UnitRealtimeStatus }

const hasStatus =
  (statuses: UnitRealtimeStatus[]) => (unit: UnitWithRealtimeStatus) =>
    statuses.includes(unit.realtimeStatus)

/**
 * Helper function to parse the display for the number of beds in a Unit.
 * Under normal conditions, this is simply the greater of the values of 'beds' or 'bedrooms'.
 *
 * The caveat that this helper handles is the rare case of Units being imported with NULL for
 * BOTH values, in which case we will simply display "?", to indicate that the data needs to be cleaned up.
 * @param unit
 */
export const parseBeds = (unit: UnitWithBeds): string => {
  const beds: number = unit?.beds || -1
  const bedrooms: number = unit?.bedrooms || -1
  const max = Math.max(beds, bedrooms)
  return max >= 0 ? `${max}` : '?'
}

export const isUnitOccupied = hasStatus([
  UnitRealtimeStatus.OCCUPIED_GUEST,
  UnitRealtimeStatus.OWNER_HOLD,
])

export const isUnitCleanInProgress = hasStatus([
  UnitRealtimeStatus.CLEAN_IN_PROGRESS,
  UnitRealtimeStatus.CLEAN_IN_PROGRESS_OVERDUE,
])
