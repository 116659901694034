import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'
import { getVacasaViewUnitURL } from 'packages/utils/links'

import { Unit } from 'app/hkhub/store/units'

import { UnitDrawerStyles } from '../../UnitDrawer.styles'
import { UnitIcons } from '../UnitIcons'

const resGridUrl = (unitId: string): string =>
  `https://www.vacasa.com/admin/resgrid.php?UnitID=${unitId}`

const St = {
  Address: styled.div`
    font-size: 14px;
    margin-top: 8px;
  `,

  Icons: styled.div`
    align-items: center;
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    width: 100%;
  `,

  UnitCode: styled.a`
    color: ${colors.lake};
    font-size: 18px;
    font-weight: bold;
  `,

  UnitDrawerHeader: styled(UnitDrawerStyles.Section)`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  `,

  UnitName: styled.a`
    color: ${colors.lake};
    font-size: 16px;
  `,
}

export type UnitDrawerHeaderProps = {
  unit: Unit
}

export const UnitDrawerHeader: React.FC<UnitDrawerHeaderProps> = React.memo(
  ({ unit }) => {
    return (
      <St.UnitDrawerHeader>
        <St.UnitCode
          href={resGridUrl(unit.id)}
          rel="noreferrer"
          target="_blank"
        >
          {unit.unitCode}
        </St.UnitCode>

        <St.UnitName
          href={getVacasaViewUnitURL(unit.id)}
          rel="noreferrer"
          target="_blank"
        >
          {unit.name}
        </St.UnitName>

        <St.Address>{unit.address}</St.Address>

        <St.Icons>
          <UnitIcons unit={unit} />
        </St.Icons>
      </St.UnitDrawerHeader>
    )
  },
)
