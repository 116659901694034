import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from 'packages/common'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean, CleanPatchData } from 'app/hkhub/store/cleans'
import { updateClean } from 'app/hkhub/store/cleans/actions'
import { setLoadingCleanId } from 'app/hkhub/store/ui/actions'
import { getLoadingCleanId } from 'app/hkhub/store/ui/selectors'

import { CleanNotes } from './CleanNotes'
import { cleanNotesReducer, makeInitialState } from './cleanNotes.reducer'

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    success: ut(Slugs.cleanNotesSuccess),
  }
}

export type CleanNotesContainerProps = {
  canEdit: boolean
  clean: Clean
}

export const CleanNotesContainer: React.FC<CleanNotesContainerProps> =
  React.memo(({ canEdit, clean }) => {
    const reduxDispatch = useDispatch()

    const strings = useTranslations()
    const { showToast } = useToast()
    const loadingCleanId = useSelector(getLoadingCleanId)

    const [formState, dispatch] = React.useReducer(
      cleanNotesReducer,
      makeInitialState({
        initialValues: {
          // empty notes will be 'null' from API, but the UI needs an empty string instead
          notes: clean.notes || '',
        },
      }),
    )

    // if/when notes on the clean itself change, reset our initial values
    // this allows us to display the new "real" notes after an update
    React.useEffect(() => {
      if (clean.notes !== formState.initialValues.notes) {
        dispatch({
          payload: {
            values: {
              notes: clean.notes || '',
            },
          },
          type: 'setInitial',
        })
      }
    }, [clean.notes, formState.initialValues.notes])

    const [updateCleanState, updateCleanFn, resetUpdateCleanState] =
      useAsyncFnWithReset(
        async (patchData: CleanPatchData) => {
          // for empty notes, the API expects null instead of an empty string,
          // so we make that replacement here if all notes have been deleted
          const notes = patchData.notes || null
          return reduxDispatch(
            updateClean({
              ...patchData,
              notes,
            }),
          )
        },
        [reduxDispatch],
      )

    // watch for changes indicating successful API request, and respond accordingly
    React.useEffect(() => {
      const { error, loading, value } = updateCleanState
      const success = !error && !loading && !!value

      if (success) {
        showToast({ message: strings.success })
        dispatch({ type: 'editEnd' })
      }
    }, [reduxDispatch, showToast, strings.success, updateCleanState])

    const handleEditClick = React.useCallback(() => {
      dispatch({ type: 'editStart' })
    }, [])

    const handleCancel = React.useCallback(() => {
      dispatch({ type: 'editEnd' })
      resetUpdateCleanState()
    }, [resetUpdateCleanState])

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch({
          payload: { values: { notes: event.target.value } },
          type: 'setValues',
        })
      },
      [],
    )

    const handleSubmit = React.useCallback(
      async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        reduxDispatch(setLoadingCleanId(clean.id))
        updateCleanFn({
          id: clean.id,
          notes: formState.values.notes.trim(),
        })
      },
      [clean.id, formState.values.notes, reduxDispatch, updateCleanFn],
    )

    const handlers = React.useMemo(
      () => ({
        cancel: handleCancel,
        change: handleChange,
        clearError: resetUpdateCleanState,
        edit: handleEditClick,
        submit: handleSubmit,
      }),
      [
        handleCancel,
        handleChange,
        handleEditClick,
        handleSubmit,
        resetUpdateCleanState,
      ],
    )

    return (
      <CleanNotes
        canEdit={canEdit}
        formState={formState}
        handlers={handlers}
        loading={!!loadingCleanId || updateCleanState.loading}
        requestState={updateCleanState}
      />
    )
  })
