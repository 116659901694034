import { produce } from 'immer'
import * as React from 'react'

import { Button } from '../../../Button'
import { LegacyModal2 } from '../index'
import {
  SuperModalContext,
  SuperModalContextConfig,
  SuperModalContextType,
} from './SuperModalContext'
import {
  SuperModalButtonConfig,
  UpdatableButtonProperties,
} from './SuperModalContext.types'
import {
  initialState,
  SuperModalContextActionType,
  reducer,
} from './SuperModalContextWrapper.helpers'

import styles from './SuperModalContextWrapper.module.scss'

const noop = () => void 0

export const generateButtonDefaults = (
  defaultOnClickHandler: () => void,
): SuperModalButtonConfig => {
  return {
    disabled: false,
    loading: false,
    onClick: defaultOnClickHandler,
    text: 'OK',
    type: 'utility',
  }
}

export const SuperModalProvider: React.FC<React.PropsWithChildren> = React.memo(
  ({ children }) => {
    const [modalState, dispatch] = React.useReducer(reducer, initialState)

    const handleModalClose = React.useCallback(() => {
      const afterModalClosed = modalState.afterModalClosed || noop

      dispatch({ type: SuperModalContextActionType.HANDLE_MODAL_CLOSE })

      afterModalClosed()
    }, [modalState.afterModalClosed])

    const triggerModalClose = React.useCallback(() => {
      dispatch({ type: SuperModalContextActionType.INITIATE_CLOSE_MODAL })
    }, [])

    /**
     * Takes a button config, and replaces every undefined value with the default
     * Defaults are determined by `generateButtonDefaults` at the top of this file
     */
    const hydratePartialButtonWithDefaults = React.useCallback(
      (button: SuperModalButtonConfig): SuperModalButtonConfig => {
        const defaultButton = generateButtonDefaults(triggerModalClose)

        return produce(button, draftBtn => {
          Object.keys(defaultButton).forEach(btnProperty => {
            if (draftBtn[btnProperty] === undefined) {
              draftBtn[btnProperty] = defaultButton[btnProperty]
            }
          })

          return draftBtn
        })
      },
      [triggerModalClose],
    )

    /**
     * Method to trigger a Modal to show. Takes a config that requires a title and a message
     * Optionally takes an array of button configs and a `afterModalClosed` function
     */
    const showModal = React.useCallback(
      (config: SuperModalContextConfig) => {
        /*
         * Here we insert a single default button if none were passed in, this is a simple "OK" button,
         * that uses the `Utility` type and closes the modal on click
         */
        const buttons = (config.buttons || [{}]).map(
          hydratePartialButtonWithDefaults,
        )

        const { afterModalClosed, hideCloseButton, ...displayConfig } = config

        dispatch({
          data: {
            afterModalClosed,
            display: { ...displayConfig, buttons, hideCloseButton },
          },
          type: SuperModalContextActionType.SHOW_MODAL,
        })
      },
      [hydratePartialButtonWithDefaults],
    )

    /**
     * Helper that takes a subset of a button config, and updates all button's
     * states to match the incoming config
     */
    const updateStateForAllButtons = (
      changedState: Partial<UpdatableButtonProperties>,
    ) => {
      dispatch({
        data: changedState,
        type: SuperModalContextActionType.UPDATE_ALL_BUTTONS,
      })
    }

    const modalContextValue: SuperModalContextType = React.useMemo(() => {
      return {
        showModal,
      }
    }, [showModal])

    /** Functions used to update the state of the modal via button clicks */
    const exposedModalStateChangeFunctions = React.useMemo(
      () => ({
        setButtonsDisabled: (disabled: boolean) => {
          updateStateForAllButtons({ disabled })
        },
        setButtonsLoading: (loading: boolean) => {
          updateStateForAllButtons({ loading })
        },
        /** Master function to update any button state, only use if this is the
         * _only_ time you see the state updating in this particular way, otherwise
         * add a new named function to this object for readability and reusabilty
         */
        updateStateForAllButtons,
      }),
      [],
    )

    return (
      <SuperModalContext.Provider value={modalContextValue}>
        {children}

        <LegacyModal2
          hideCloseButton={modalState.display.hideCloseButton}
          isOpen={modalState.renderState.open}
          onClose={handleModalClose}
          readyToClose={modalState.renderState.readyToClose}
          title={modalState.display.title}
        >
          <div className={styles.superModalContainer}>
            <div className={styles.superModalText}>
              {modalState.display.message}
            </div>
            <div className={styles.buttonWrapper}>
              {modalState.display.buttons.map((btn, idx: number) => {
                const { text, type, onClick = noop, disabled, loading } = btn

                const handleOnClick = () =>
                  onClick(triggerModalClose, exposedModalStateChangeFunctions)

                return (
                  <Button
                    key={idx}
                    onClick={handleOnClick}
                    disabled={disabled}
                    isLoading={loading}
                    buttonType={type}
                  >
                    {text}
                  </Button>
                )
              })}
            </div>
          </div>
        </LegacyModal2>
      </SuperModalContext.Provider>
    )
  },
)
