import { deprecated } from 'typesafe-actions'

import { UiActionTypes } from '../ui.types'

const { createStandardAction } = deprecated

export const setLoadingCleanIdAction = createStandardAction(
  UiActionTypes.SET_LOADING_CLEAN_ID,
)<string>()

export const setLoadingCleanId = (id: string) => dispatch => {
  dispatch(setLoadingCleanIdAction(id))
}
