import { createDateObject, format } from 'packages/utils/dateHelpers'
import { logInfo } from 'packages/wiretap/logging'

import { Clean } from 'app/hkhub/store/cleans/cleans.types'
import { Unit } from 'app/hkhub/store/units/units.types'

export const makeDateUnitHash = (date: Date, unit: Unit): string => {
  const cleanDate = format(date, 'yyyy-MM-dd')
  return `${cleanDate}-${unit.id}`
}

/**
 * Returns a hash which is unique to a clean/unit combination
 * @param clean
 */
export const makeCleanUnitHash = (clean: Clean): string => {
  if (!clean.unit) {
    logInfo('Clean Found With no Unit', {
      missingEntity: {
        clean,
      },
    })
  }

  return makeDateUnitHash(createDateObject(clean.effectiveDate), clean.unit)
}
