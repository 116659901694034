// Google Tag Manager event definitions
export const GtmEvents = {
  submitTimerStartedAtChanged: 'submitTimerStartedAtChanged',
  submitTimerStoppedAtChanged: 'submitTimerStoppedAtChanged',
}

export const SegmentEvents = {
  deleteReassignedCleanTime: 'deleteReassignedCleanTime',
  deleteReassignedTicketTime: 'deleteReassignedTicketTime',

  fieldAppCompleteClean: 'fieldAppCompleteClean',
  fieldAppCreateTicketFromTask: 'fieldAppCreateTicketFromTask',
  fieldAppDeepCleanConvertToPost: 'fieldAppDeepCleanConvertToPost',
  fieldAppExternalLink: 'fieldAppExternalLink',
  fieldAppInspectionDeletePhoto: 'fieldAppInspectionDeletePhoto',
  fieldAppInspectionDeletePhotoError: 'fieldAppInspectionDeletePhotoError',
  fieldAppInspectionMarkComplete: 'fieldAppInspectionMarkComplete',
  fieldAppInspectionMarkCompleteError: 'fieldAppInspectionMarkCompleteError',
  fieldAppInspectionPhotoMultiClick: 'fieldAppInspectionPhotoMultiClick',
  fieldAppInspectionPreventDialog: 'fieldAppInspectionPreventDialog',
  fieldAppInspectionToggleItem: 'fieldAppInspectionToggleItem',
  fieldAppInspectionToggleItemError: 'fieldAppInspectionToggleItemError',
  fieldAppInspectionUploadPhoto: 'fieldAppInspectionUploadPhoto',
  fieldAppInspectionUploadPhotoError: 'fieldAppInspectionUploadPhotoError',
  fieldAppShareClean: 'fieldAppShareClean',
  fieldAppShareTicket: 'fieldAppShareTicket',
  fieldAppStartClean: 'fieldAppStartClean',
  fieldAppTimerNew: 'fieldAppTimerNew',
  fieldAppTimerUKGCatchAll: 'fieldAppTimersUKGCatchAll',
  fieldAppTodayButtonClick: 'fieldAppTodayButtonClick',

  goOffline: 'goOffline',

  hubAdminDeleteZone: 'hubAdminDeleteZone',
  hubChangeDayView: 'hubChangeDayView',
  hubExternalLink: 'hubExternalLink',
  hubFilterByOccupancy: 'hubFilterByOccupancy',
  hubFilterByUnitStatus: 'hubFilterByUnitStatus',
  hubReassignCompletedClean: 'hubReassignCompletedClean',
  hubScheduleBrowserSearch: 'hubScheduleBrowserSearch',
  hubTicketAssignmentUpdate: 'hubTicketAssignmentUpdate',
  hubTicketDrawerOpen: 'hubTicketDrawerOpen',
  hubTicketDueDateUpdate: 'hubTicketDueDateUpdate',
  hubTicketWithOverdueOpen: 'hubTicketWithOverdueOpen',
  hubUnitScheduleOpen: 'hubUnitScheduleOpen',
  hubUnitSearch: 'hubUnitSearch',
}

// all events bundled together in a single platform-agnostic collection
export const Events = {
  ...GtmEvents,
  ...SegmentEvents,
}
