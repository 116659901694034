import { createSelector } from '@reduxjs/toolkit'

import { ApplicationState } from '../../store'
import { getUnitDrawerState } from '../../ui/selectors'
import { getUnitById } from '../../units/selectors'
import { CustomInspectionItem } from '../customInspectionItems.types'
import { hydrateRawCustomInspectionItem } from '../customInspectionItems.utils'

export const getUnitDrawerCustomInspectionItems = createSelector(
  [
    getUnitDrawerState,
    (state: ApplicationState) => state.units,
    (state: ApplicationState) => state.customInspectionItems,
  ],
  ({ unitId }, units, customInspectionItems): CustomInspectionItem[] =>
    getUnitById(units, unitId)?.customInspectionItemIds.map(id =>
      hydrateRawCustomInspectionItem(customInspectionItems.data[id]),
    ) || [],
)
