import { createAsyncAction } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store/jsonapi.types'

import { notificationsService } from '../notifications.service'
import {
  NormalizedNotificationsApiResponse,
  ZoneNotificationsActionTypes,
} from '../notifications.types'

export const fetchZoneNotificationsWithAddHksAction = createAsyncAction(
  ZoneNotificationsActionTypes.FETCH_NOTIFICATIONS_WITH_HKS,
  ZoneNotificationsActionTypes.FETCH_NOTIFICATIONS_WITH_HKS_SUCCESS,
  ZoneNotificationsActionTypes.FETCH_NOTIFICATIONS_WITH_HKS_FAILURE,
)<
  RequestConfig<NormalizedNotificationsApiResponse>,
  NormalizedNotificationsApiResponse,
  Error
>()

export const getParams = (zoneId: string) => {
  return {
    fields: {
      housekeeper: [],
      user: ['first_name', 'last_name'],
      zone_notification: ['message_code'],
    },
    filter: { status: 'active', zone: zoneId },
    include: ['housekeeper', 'housekeeper.user', 'housekeeper.manager'],
    page: { size: 1500 },
  }
}

export const fetchZoneNotificationsWithAddHks =
  (zoneId: string) => async dispatch => {
    try {
      const params = getParams(zoneId)
      const request = notificationsService.fetchAllNotifications.bind(
        null,
        params,
      )
      const data = await dispatch(
        fetchZoneNotificationsWithAddHksAction.request({ request }),
      )
      dispatch(fetchZoneNotificationsWithAddHksAction.success(data))
      return data.normalized
    } catch (error) {
      dispatch(fetchZoneNotificationsWithAddHksAction.failure(error))
      throw error
    }
  }
