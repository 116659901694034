import React from 'react'

import { fullName } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'

import { useI18n, Slugs } from 'app/hkhub/i18n'
import { Ticket } from 'app/hkhub/store/tickets'
import { User } from 'app/hkhub/store/users'

import { CardSize, cardIsTiny } from '../../../helpers/scheduleMeasure'
import * as St from './TicketCard.styles'
import { TicketCardHeader } from './TicketCardHeader'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    unassigned: t(Slugs.unassigned),
  }
}

export enum TicketCardTestIds {
  container = 'TicketCard__container',
}

export type TicketCardProps = {
  assignee: User | undefined
  isOverdue?: boolean
  onClick: () => void
  size: CardSize
  ticket: Ticket
}

export const TicketCard: React.FC<TicketCardProps> = React.memo(
  ({ assignee, isOverdue, onClick, size, ticket }) => {
    const { severity, status, title } = ticket
    const strings = useTranslations()

    const assigneeName: string = assignee?.id
      ? fullName(assignee)
      : strings.unassigned

    if (cardIsTiny(size)) {
      return (
        <St.TinyCard
          dataTestId={TicketCardTestIds.container}
          onClick={onClick}
          status={status}
          size="tiny"
          severity={severity}
        >
          <St.TagIcon size={10} centerItems={true} icon={IconName.tag} />
          {assigneeName}
        </St.TinyCard>
      )
    }

    return (
      <St.TicketCard
        dataTestId={TicketCardTestIds.container}
        onClick={onClick}
        status={status}
        size={size}
      >
        <TicketCardHeader
          size={size}
          isOverdue={isOverdue}
          severity={severity}
          status={status}
        />

        <St.CardContent>
          <St.TicketTitle>{title}</St.TicketTitle>

          <St.Assignee>
            <SvgIcon icon={IconName.user2} size={14} />
            <St.AssigneeName>{assigneeName}</St.AssigneeName>
          </St.Assignee>
        </St.CardContent>
      </St.TicketCard>
    )
  },
)
