/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react'

export const DEFAULT_TRANSITION_TIME = 200

export enum TransitionPhaseType {
  enterActive = 'enterActive',
  enterStart = 'enterStart',
  exitActive = 'exitActive',
  exitStart = 'exitStart',
  hidden = 'hidden',
  idle = 'idle',
}

export const closedStates = [
  TransitionPhaseType.exitStart,
  TransitionPhaseType.exitActive,
  TransitionPhaseType.hidden,
]

export const isInClosedPhase = (phase: TransitionPhaseType): boolean =>
  closedStates.includes(phase)

export const openStates = [
  TransitionPhaseType.enterStart,
  TransitionPhaseType.enterActive,
  TransitionPhaseType.idle,
]

export const isInOpenPhase = (phase: TransitionPhaseType): boolean =>
  openStates.includes(phase)

export type UseTransitionProps = {
  /** Callback for after the modal has completely finished the exit transition */
  afterExit?: () => void
  /** Callback for before the modal has begun the enter transition */
  beforeEnter?: () => void
  /** Whether the modal is currently active */
  showing: boolean
  /** The time (in ms) that transitions should last */
  transitionTime?: number
}

type UseLegacyTransition = {
  beginTransitionOut: () => void
  transitionPhase: TransitionPhaseType
}

/**
 * @deprecated Do not use this hook! Use `useTransition` in packages instead.
 */
export const useLegacyTransition = ({
  afterExit,
  beforeEnter,
  showing,
  transitionTime = DEFAULT_TRANSITION_TIME,
}: UseTransitionProps): UseLegacyTransition => {
  const [transitionPhase, setTransitionPhase] = useState<TransitionPhaseType>(
    TransitionPhaseType.hidden,
  )

  useEffect(
    () => {
      if (!showing) {
        if (transitionPhase === TransitionPhaseType.exitActive) {
          setTransitionPhase(TransitionPhaseType.hidden)
        }
      } else if (transitionPhase === TransitionPhaseType.hidden) {
        beginEnterTransition()
      }
    },
    [showing], // eslint-disable-line
  )

  const beginEnterTransition = () => {
    if (beforeEnter) {
      beforeEnter()
    }

    setEnterStart()
  }

  const finishExitTransition = () => {
    if (afterExit) {
      afterExit()
    }
  }

  const setEnterActive = () => {
    setTransitionPhase(TransitionPhaseType.enterActive)
    setTimeout(() => {
      setTransitionPhase(TransitionPhaseType.idle)
    }, transitionTime)
  }

  const setEnterStart = () => {
    setTransitionPhase(TransitionPhaseType.enterStart)
    setTimeout(setEnterActive, 0)
  }

  const setExitActive = () => {
    setTransitionPhase(TransitionPhaseType.exitActive)
    setTimeout(finishExitTransition, transitionTime)
  }

  const setExitStart = () => {
    setTransitionPhase(TransitionPhaseType.exitStart)
    setTimeout(setExitActive, 0)
  }

  const beginTransitionOut = () => {
    if (transitionPhase === TransitionPhaseType.idle) {
      setExitStart()
    }
  }

  return { beginTransitionOut, transitionPhase }
}
