import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { housekeepersService } from '../housekeepers.service'
import {
  HousekeepersActionTypes,
  NormalizedHousekeepersApiResponse,
} from '../housekeepers.types'

export const updateHkInSearchResultsAction = createAsyncAction(
  HousekeepersActionTypes.UPDATE_HK_IN_SEARCH_RESULTS,
  HousekeepersActionTypes.UPDATE_HK_IN_SEARCH_RESULTS_SUCCESS,
  HousekeepersActionTypes.UPDATE_HK_IN_SEARCH_RESULTS_FAILURE,
)<
  RequestConfig<NormalizedHousekeepersApiResponse>,
  NormalizedHousekeepersApiResponse,
  Error
>()

export const getParams = (): RequestOptions => {
  return {
    filter: {
      is_active: true,
    },
    include: ['borrowed_zones', 'manager', 'user', 'zone'],
    page: { size: 1500 },
  }
}

export const updateHkInSearchResults = (hkId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = housekeepersService.fetchHousekeeperById.bind(
      null,
      hkId,
      params,
    )
    const response = await dispatch(
      updateHkInSearchResultsAction.request({ request }),
    )

    dispatch(updateHkInSearchResultsAction.success(response))
    return response.normalized
  } catch (error) {
    dispatch(updateHkInSearchResultsAction.failure(error))
    throw error
  }
}
