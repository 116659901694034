/* eslint-disable no-console*/
import get from 'lodash/get' // eslint-disable-line

import { handleMissingGTMAnalytics, handleGTMError } from '../errorReporting'

export function trackOnGTM(event: string, debug = false): void {
  if (debug) {
    console.info('Sending GTM event:')
    console.info({ event })
  }

  // GTM can occasionally not get defined correctly the first time around
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const gtmTrack: any = get(window, 'dataLayer.push')

  if (gtmTrack) {
    try {
      gtmTrack({ event })
    } catch (error) {
      if (debug) {
        console.info({ error })
      }

      handleGTMError(event, error)
    }
  } else if (process.env.NODE_ENV === 'development') {
    console.info(
      'Attempted to send a GTM event, but the gtmTrack function was not defined.',
    )
  } else {
    handleMissingGTMAnalytics(event)
  }
}
