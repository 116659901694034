import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Alert, UnitLinks as BaseUnitLinks } from 'packages/common'
import { colors } from 'packages/styles'

import { CleanUnitInfo as BaseCleanUnitInfo } from './CleanUnitInfo'
import { DeepCleanExtraInfoPanel as DeepCleanExtraInfoPanelComponent } from './DeepCleanExtraInfoPanel'

const drawerSection = css`
  font-size: 16px;
  padding: 16px 8px;
  width: 100%;
`

export const ContentWrapper = styled.div`
  font-size: 14px;
  padding: 48px 24px 24px 24px;

  // add a min-width to the content on large screens to minimize "content jumping" as reservation loads
  // this number is fairly arbitrary and aimed mostly at "what looks okay?"
  @media only screen and (min-width: 769px) {
    max-width: 480px;
    min-width: 432px;
  }
`

export const Divider = styled.hr`
  background-color: ${colors.midnight10};
  border: 0;
  height: 8px;
  margin-left: -24px;
  margin-right: -24px;
`

export const CleanUnitInfo = styled(BaseCleanUnitInfo)`
  ${drawerSection};
  border-top: 0;
`

export const UnitLinks = styled(BaseUnitLinks)`
  padding: 8px;
  padding-bottom: 0;
`

export const DrawerSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 8px;
  width: 100%;
`

export const DrawerSectionTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
`

export const HousekeeperNotes = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  padding-left: 8px;
`
export const UnitInfoTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
`

export const SharedEditSectionStyles = {
  Alert: styled(Alert)`
    margin-top: 16px;
  `,
  EditableSection: styled.div`
    padding-bottom: 24px;
  `,
  EditableSectionTitle: styled.div`
    color: ${colors.dusk};
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 8px;
    text-transform: uppercase;
  `,
  EditButtonContainer: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
}

export const DeepCleanAlert = styled(Alert)<{ isPanelOpen: boolean }>`
  margin-left: 8px;
  margin-bottom: ${({ isPanelOpen }) => (isPanelOpen ? '0' : '1rem')};
`
export const DeepCleanExtraInfoPanel = styled(DeepCleanExtraInfoPanelComponent)`
  margin-left: 8px;
  padding: 12px 0px;
`
