import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { zonesService } from '../zones.service'
import {
  NormalizedZonesApiResponse,
  ZonesActionTypes,
  ZoneUpdate,
} from '../zones.types'
import { fetchZoneById } from './fetchZoneById'

export const updateZoneAction = createAsyncAction(
  ZonesActionTypes.UPDATE_ZONE,
  ZonesActionTypes.UPDATE_ZONE_SUCCESS,
  ZonesActionTypes.UPDATE_ZONE_FAILURE,
)<RequestConfig<NormalizedZonesApiResponse>, ZoneUpdate, Error>()

export const buildManagers = flatManagers =>
  flatManagers.map(id => ({
    id,
    type: 'user',
  }))

export const getParams = (zoneUpdate: ZoneUpdate): RequestOptions => {
  const { isActive, name } = zoneUpdate.attributes

  const attributes = {
    is_active: isActive,
    name,
  }

  const managersPayload = zoneUpdate.relationships?.managers
    ? {
        managers: {
          data: buildManagers(zoneUpdate.relationships.managers),
        },
      }
    : {}

  const invoiceApproverUserPayload = zoneUpdate.relationships
    ?.invoiceApproverUser
    ? {
        invoice_approver_user: {
          data: {
            id: zoneUpdate.relationships?.invoiceApproverUser,
            type: 'user',
          },
        },
      }
    : {}

  const relationships = {
    ...managersPayload,
    ...invoiceApproverUserPayload,
  }

  return {
    data: {
      attributes,
      id: zoneUpdate.id,
      relationships,
      type: 'zone',
    },
  }
}

export const updateZone = (zoneUpdate: ZoneUpdate) => async dispatch => {
  try {
    const params = getParams(zoneUpdate)
    const request = zonesService.updateZone.bind(null, zoneUpdate.id, params)
    const result = await dispatch(updateZoneAction.request({ request }))
    await dispatch(updateZoneAction.success(zoneUpdate))
    await dispatch(fetchZoneById(zoneUpdate.id))
    return result.normalized
  } catch (error) {
    dispatch(updateZoneAction.failure(error))
    throw error
  }
}
