import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { clearZoneAction } from 'app/hkhub/store/zones/actions'

import {
  fetchCleanAction,
  fetchCleansByZoneAndDateAction,
} from '../cleans/actions'
import {
  fetchUnitByIdAction,
  fetchUnitByIdStrict,
  fetchUnitsByZone,
  fetchUnitsByZoneWithPreferences,
} from '../units/actions'
import { LockboxesState } from './lockboxes.types'
import { emptyNormalizedLockboxesData } from './lockboxes.utils'

const initialState: LockboxesState = {
  data: {},
}

const actions = {
  clearZoneAction,
  fetchCleanAction,
  fetchCleansByZoneAndDateAction,
  fetchUnitByIdAction,
}

type LockboxActionsTypes = ActionType<typeof actions>

export const lockboxesReducer = (
  state = initialState,
  action: LockboxActionsTypes,
): LockboxesState =>
  produce(state, (draft: LockboxesState) => {
    switch (action.type) {
      case getType(fetchCleansByZoneAndDateAction.success):
      case getType(fetchCleanAction.success):
      case getType(fetchUnitByIdAction.success):
      case fetchUnitByIdStrict.fulfilled.toString():
      case fetchUnitsByZone.fulfilled.toString():
      case fetchUnitsByZoneWithPreferences.fulfilled.toString(): {
        const normalized =
          action?.payload.normalized || emptyNormalizedLockboxesData

        draft.data = {
          ...state.data,
          ...normalized.lockBox,
        }

        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.data = {}
        return
      }
    }
  })
