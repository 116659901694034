import * as React from 'react'

import {
  CardSize,
  cardSizeEqualOrGreaterThan,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Clean } from 'app/hkhub/store/cleans'

import { St } from '../CleanCardLabel.styles'

export enum CleanCardStaffViewLabelTestIds {
  container = 'CleanCardStaffViewLabel__container',
}

export type CleanCardStaffViewLabelProps = {
  clean: Clean
  size: CardSize
}

export const CleanCardStaffViewLabel: React.FC<CleanCardStaffViewLabelProps> =
  ({ clean, size }) => {
    const unitText = clean.unit
      ? `${clean.unit.unitCode} ${clean.unit.name}`
      : 'Unknown'

    return (
      <St.Container data-testid={CleanCardStaffViewLabelTestIds.container}>
        <St.PrimaryLabel
          beginWithEllipsisOnTiny={true}
          status={clean.realtimeStatus}
          size={size}
        >
          {unitText}
        </St.PrimaryLabel>

        {cardSizeEqualOrGreaterThan(size, 'medium') && (
          <St.SecondaryLabel status={clean.realtimeStatus} size={size}>
            {clean.unit?.address || ''}
          </St.SecondaryLabel>
        )}
      </St.Container>
    )
  }
