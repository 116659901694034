export const roundToTenths = (value: number): number =>
  Math.round(value * 10) / 10

/**
 * Wraps a given value between `min` and `max`.
 *
 * As an example, if `min` is 2 and `max` is 4:
 *    - `val` of 3 will simply return 3
 *    - `val` of 2 will simply return 2
 *    - `val` of 4 will simply return 4
 *    - `val` of 5 will return 2, since 5 is greater than `max`
 *    - `val` of 42 will also return 2, since 42 is greater than `max`
 *    - `val` of 1 will return 4, since 1 is less than `min`
 *    - `val` of -42 will also return 4, since -42 is less than `min`
 *
 * @param min
 * @param max
 * @param val
 */
export const wrap = (min: number, max: number, val: number): number => {
  if (val < min) return max
  if (val > max) return min
  return val
}

const timeStr = (val: number) => `${val}`.padStart(2, '0')
/**
 * Formats a given number of seconds in digital clock display style.
 * Seconds and minutes are padding with a leading zero when necessary,
 * but hours are not.
 *
 * For example:
 *   - 4977 seconds will return `1:22:57`
 *   - 3271 seconds will return `1:02:01`
 *   - 21 seconds will return `0:00:20`
 * @param seconds
 */
export const formatSecondsAsDigitalClock = (seconds: number): string => {
  const hour = Math.floor(seconds / (60 * 60)) % 60
  const min = timeStr(Math.floor(seconds / 60) % 60)
  const sec = timeStr(seconds % 60)

  return `${hour}:${min}:${sec}`
}

export function convertHoursToTimeString(rawHours: number): string {
  const [hours, rawMinutes] = rawHours.toFixed(2).split('.')

  const minutes = Math.round(parseInt(rawMinutes) * 0.6)
  const fmtHours = `${hours}h`
  const fmtMinutes = `${minutes}m`

  return minutes > 0 ? `${fmtHours} ${fmtMinutes}` : fmtHours
}
