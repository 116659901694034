import * as React from 'react'

const Wrapper = ({ children, fill = 'none', id, viewBox }) => (
  <symbol
    fill={fill}
    id={id}
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox={viewBox}
  >
    {children}
  </symbol>
)

export default React.memo(Wrapper)
