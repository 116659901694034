import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { WithBreakpoint, useBreakpoint } from 'packages/styles'

import { getAuthUser } from 'app/hkhub/store/users/selectors'

import { NotFoundPage } from '../../core/NotFoundPage'
import { AdminControlBar, AdminTitlebar } from '../components'
import { AdminAlertsListContainer } from '../components/AdminAlertsList'
import { AdminZonePage } from '../components/AdminZonePage'
import { AdminContextWrapper } from '../contexts/AdminContext/AdminContextWrapper'

const St = {
  ContentArea: styled.div<WithBreakpoint>`
    padding: ${({ breakpoint }) => (breakpoint === 'SM' ? '8px' : '24px')};
  `,
}

export enum AdminPageTestIds {
  content = 'AdminPage__content',
}

export const AdminPage: React.FC = React.memo(() => {
  const breakpoint = useBreakpoint()

  const authUser = useSelector(getAuthUser)
  const [authState, setAuthState] = React.useState<
    'loading' | 'redirect' | 'authorized'
  >('loading')

  React.useEffect(() => {
    if (!authUser) return
    setAuthState(authUser.isSuperuser ? 'authorized' : 'redirect')
  }, [authUser])

  const isAuthorized = authState === 'authorized'

  return (
    <div>
      <AdminContextWrapper>
        <header>
          <AdminTitlebar />
          {isAuthorized && <AdminControlBar />}
        </header>

        {isAuthorized ? (
          <main data-testid={AdminPageTestIds.content}>
            <AdminAlertsListContainer />
            <St.ContentArea breakpoint={breakpoint}>
              <AdminZonePage />
            </St.ContentArea>
          </main>
        ) : (
          <NotFoundPage />
        )}
      </AdminContextWrapper>
    </div>
  )
})
