import styled from '@emotion/styled'
import { filter, flatten, map, pipe, prop, values } from 'lodash/fp'
import React from 'react'

import { Lightbox, useLightbox } from 'packages/common'
import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { TaskPhoto } from 'app/hkhub/store/taskPhotos'
import {
  categoryIconMap,
  InspectionChecklist,
  InspectionFlag,
} from 'app/hkhub/store/tasks'

import { InspectionFlags } from '../InspectionFlags'
import { InspectionPhotos } from '../InspectionPhotos'

const PHOTO_COUNT = 2

const makePhotoBuckets = (checklists, photos) => {
  const categoryIds = checklists.map(cl => cl.category.id)
  return categoryIds.reduce((acc, id) => {
    const photosForCategory = photos.filter(
      ({ categoryId }) => categoryId === id,
    )

    acc[id] = Array(PHOTO_COUNT)
      .fill(null)
      .map((_, idx) => photosForCategory[idx])

    return acc
  }, {})
}

const St = {
  CategoryContainer: styled.div`
    margin: 0 -16px;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.midnight20};
    }
  `,

  CategoryHeader: styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 12px 32px;
  `,

  CategoryTitle: styled.div`
    color: ${colors.midnight};
    flex-grow: 1;
    font-size: 16px;
    font-weight: bold;
    padding-left: 24px;
  `,

  InspectionCategories: styled.div`
    margin-top: 32px;
  `,

  ToggleText: styled.div`
    font-size: 28px;
  `,
}

export enum InspectionCategoriesTestIds {
  category = 'InspectionCategories__category',
  header = 'InspectionCategories__header',
}

export type InspectionCategoriesProps = {
  inspectionChecklist: InspectionChecklist
  inspectionFlags: InspectionFlag[] | null | undefined
  photos: TaskPhoto[]
}

export const InspectionCategories: React.FC<InspectionCategoriesProps> =
  React.memo(({ inspectionFlags, inspectionChecklist, photos }) => {
    const { openLightbox, ...lightboxProps } = useLightbox()

    const [expandedSections, setExpandedSections] = React.useState(
      [] as string[],
    )

    const bucketedTaskPhotos = React.useMemo(() => {
      return makePhotoBuckets(inspectionChecklist.checklists, photos)
    }, [inspectionChecklist.checklists, photos])

    const expandSection = (categoryId: string) => {
      setExpandedSections(prev => [...prev, categoryId])
    }

    const collapseSection = (categoryId: string) => {
      setExpandedSections(prev => prev.filter(id => id !== categoryId))
    }

    const showLightbox = (categoryId: string, photoId: string) => {
      // all current TaskPhotos, with undefined/"empty" values removed
      const taskPhotos = pipe(
        values,
        flatten,
        filter(Boolean),
      )(bucketedTaskPhotos) as TaskPhoto[]

      const newSources = pipe(
        map(prop('originalImage')),
        filter(Boolean), // remove undefined/"empty" URLs (photos that have not finished uploading)
      )(taskPhotos) as string[]

      const sourceIndex = taskPhotos.findIndex(({ id }) => id === photoId)

      // set titles (category names) for every photo
      const findCategoryName = (taskPhoto: TaskPhoto) => {
        const { categoryId } = taskPhoto
        const checklist = inspectionChecklist.checklists.find(
          cl => cl.category.id === categoryId,
        )
        return checklist ? checklist.category.title : ''
      }

      const titles = taskPhotos.map(findCategoryName)

      openLightbox({
        newSources,
        sourceIndex,
        titles,
      })
    }

    return (
      <St.InspectionCategories>
        {inspectionChecklist.checklists.map(checklist => {
          const { categoryType, id: categoryId, title } = checklist.category

          const expanded = expandedSections.includes(categoryId)
          const icon = categoryIconMap[categoryType]

          const handleHeaderClick = expanded
            ? () => collapseSection(categoryId)
            : () => expandSection(categoryId)

          const handleThumbnailClick = (photoId: string) =>
            showLightbox(categoryId, photoId)

          return (
            <St.CategoryContainer
              data-testid={InspectionCategoriesTestIds.category}
              key={categoryId}
            >
              <St.CategoryHeader
                data-testid={InspectionCategoriesTestIds.header}
                onClick={handleHeaderClick}
              >
                <SvgIcon icon={icon} size={16} />
                <St.CategoryTitle>{title}</St.CategoryTitle>
                <St.ToggleText>{expanded ? '-' : '+'}</St.ToggleText>
              </St.CategoryHeader>

              {expanded && (
                <>
                  <InspectionPhotos
                    onThumbnailClick={handleThumbnailClick}
                    photos={bucketedTaskPhotos[categoryId]}
                  />

                  <InspectionFlags
                    inspectionFlags={inspectionFlags?.filter(
                      flag => flag.categoryId === categoryId,
                    )}
                  />
                </>
              )}
            </St.CategoryContainer>
          )
        })}

        <Lightbox {...lightboxProps} />
      </St.InspectionCategories>
    )
  })
