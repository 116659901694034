/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react'

// eslint-disable-next-line no-restricted-imports
import { logError } from 'packages/wiretap/logging'

// eslint-disable-next-line no-restricted-imports
import { Slugs, useI18n } from 'app/hkhub/i18n'

import { Alert } from '../Alert'
import { SentryContextConsumer, SentryContextType } from './SentryContext'
import { sendErrorToSentry } from './utils'

import styles from './SentryErrorBoundary.module.scss'

const NoSentryErrorPage = () => {
  const { t } = useI18n()

  return (
    <Alert alertType={'danger'}>
      <span className={styles.errorMessage}>{t(Slugs.anErrorOccurred)}</span>
    </Alert>
  )
}

const SentryErrorPage = () => {
  const { t } = useI18n()

  return (
    <Alert alertType={'danger'}>
      <span className={styles.errorMessage}>
        {t(Slugs.anErrorOccurredLong)}
      </span>
    </Alert>
  )
}

export type SentryErrorBoundaryProps = {
  boundary?: string
  children?: React.ReactNode
  noSentryComponent?: () => void
  sentryComponent?: () => void
}

type SentryErrorBoundaryState = {
  error?: Error
}

export class SentryErrorBoundary extends React.Component<
  SentryErrorBoundaryProps,
  SentryErrorBoundaryState
> {
  static defaultProps = {
    noSentryComponent: null,
    sentryComponent: null,
  }

  state = {
    error: undefined,
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { boundary } = this.props
    this.setState({ error })
    logError(error, {
      boundary: boundary,
      errorInfo: errorInfo.componentStack.toString(),
    })

    // TODO: When we set up DD monitoring, we can get rid of the line below
    sendErrorToSentry(error, { boundary, errorInfo })
  }

  sentryIsActiveRenderer = () => {
    if (this.props.sentryComponent) {
      return this.props.sentryComponent()
    }

    return <SentryErrorPage />
  }

  sentryIsNotActiveRenderer = () => {
    if (this.props.noSentryComponent) {
      return this.props.noSentryComponent()
    }

    return <NoSentryErrorPage />
  }

  onErrorRenderFromContext = (context: SentryContextType | null) => {
    // context can't be null, but typescript is unaware of this
    if (context) {
      return context.sentryIsActive
        ? this.sentryIsActiveRenderer()
        : this.sentryIsNotActiveRenderer()
    }
  }

  render() {
    return (
      <SentryContextConsumer>
        {sentryContext => {
          if (this.state.error) {
            return this.onErrorRenderFromContext(sentryContext)
          }

          // Typescript doesn't like that this function returns React Elements
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return this.props.children as any
        }}
      </SentryContextConsumer>
    )
  }
}
