import styled from '@emotion/styled'

import { RadioButtons, UnitDetailBadges } from 'packages/common'
import { ComputedCleanJobType } from 'packages/grimoire'
import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { SharedEditSectionStyles } from '../../CleanDrawer.styles'
import { jobTypeConfigMap } from './CleanInfoEditor'

export const St = {
  AddDaysSection: styled.div`
    display: flex;
    color: ${colors.lake};
    justify-content: flex-start;
    padding-top: 16px;
    width: 100%;
  `,

  Alert: SharedEditSectionStyles.Alert,

  ArrowIcon: styled(SvgIcon)`
    color: ${colors.dusk};
  `,

  ArrowWrapper: styled.div`
    align-items: center;
    color: ${colors.dusk};
    display: flex;
    grid-area: arrow;
    height: 100%;
    justify-content: center;
    width: 100%;
  `,

  BadgesWrapper: styled.div`
    align-items: center;
    display: flex;
    grid-area: badges;
    justify-content: center;
  `,

  Buttons: styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
  `,

  CannotAddWarning: styled.span`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #4c5357;
  `,

  CleanInfoEditor: styled.div`
    padding-left: 8px;
    position: relative;
  `,

  CleanTypeTitle: styled(SharedEditSectionStyles.EditableSectionTitle)`
    padding-bottom: 0;
  `,

  DateEditSection: styled.div`
    display: grid;
    grid-template-areas:
      'dropdownTitle . dueTitle .'
      'dropdown arrow due badges';
    width: 100%;
  `,

  DatesContainer: styled(SharedEditSectionStyles.EditableSection)`
    // This must be display: block in order for the child grid to work with Safari
    // This overflow style is also required to make Safari work with the grid child
    overflow: hidden;
  `,

  DateSelectContainer: styled.div<{ fullHeight: boolean }>`
    grid-area: dropdown;
    // this is the height of one select option
    height: ${({ fullHeight }) => (fullHeight ? '40px' : 'auto')};
    min-width: 150px;
  `,

  DateSelectOverflowContainer: styled.div`
    overflow: visible;
    position: absolute;
    width: 150px;
  `,

  DueDate: styled.div`
    align-items: center;
    display: flex;
    grid-area: due;
    justify-content: flex-start;
  `,

  DueTitle: styled(SharedEditSectionStyles.EditableSectionTitle)`
    grid-area: dueTitle;
    padding-bottom: 8px;
  `,

  EditableSection: SharedEditSectionStyles.EditableSection,

  JobTypeRadioButtons: styled(RadioButtons)`
    & > div {
      padding-top: 8px;

      &:first-of-type {
        // this margin is applied by the component on all except the first option
        margin-top: 4px;
      }
    }
  `,

  Label: styled.span<{ job: ComputedCleanJobType }>`
    background-color: ${({ job }) => jobTypeConfigMap[job].color};

    align-items: center;
    border-radius: 12px;
    display: inline-flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 700;
    grid-gap: 8px;
    justify-content: center;
    padding: 5px 10px;
    text-transform: uppercase;
  `,

  PredictedTime: styled.span`
    color: ${colors.dusk};
    font-size: 14px;
    margin-left: 12px;
  `,

  ScheduledOn: styled(SharedEditSectionStyles.EditableSectionTitle)`
    grid-area: dropdownTitle;
    padding-bottom: 8px;
  `,

  UnitBadge: styled(UnitDetailBadges)`
    grid-area: badges;
  `,
}
