import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { SvgIcon } from 'packages/iconic'
import { Breakpoint, colors, largeScreen } from 'packages/styles'

type WithBreakpoint = {
  breakpoint: Breakpoint
}

export const ContainerHeight: Record<Breakpoint, number> = {
  LG: 64,
  SM: 44,
}

export const TitlebarContainer = styled.div`
  background-color: ${colors.midnight};
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
`

export const TitlebarContent = styled.div<WithBreakpoint>`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: ${({ breakpoint }) => `${ContainerHeight[breakpoint]}px`};
  justify-content: flex-start;
  width: 100%;
`

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`

export const LeftContent = styled(ContentWrapper)`
  padding-left: 16px;
`

export const RightContent = styled(ContentWrapper)`
  margin-left: auto;
`

export const BrandIcon = styled(Link)`
  color: ${colors.white};
  cursor: pointer;
  padding-bottom: 4px;
  margin-right: 4px;

  ${largeScreen(css`
    margin-right: 16px;
  `)};
`

export const WhiteIcon = styled(SvgIcon)`
  color: white;
`

export const MenuWrapper = styled.div`
  align-items: center;
  border-left: 1px solid ${colors.midnight60};
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 20px;
`

export const MenuIcon = styled(WhiteIcon)`
  cursor: pointer;
`

export const BrandTitleWrapper = styled.span`
  align-items: center;
  color: ${colors.midnight30};
  display: flex;
  font-size: 18px;
  justify-content: flex-start;
  padding-left: 4px;
`

export const BrandTitleText = styled(BrandTitleWrapper)`
  color: white;
  font-weight: bold;
  margin: 0 8px;
`
