import get from 'lodash/get' // eslint-disable-line
import { createAsyncAction } from 'typesafe-actions'

import { createDateObject, format } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { unitsService } from '../units.service'
import {
  NormalizedUnitsApiResponse,
  UnitsActionTypes,
  UnitPreferencesPostData,
} from '../units.types'
import { fetchUnitById } from './fetchUnitById'

export const createUnitPreferenceAction = createAsyncAction(
  UnitsActionTypes.CREATE_UNIT_PREFERENCE,
  UnitsActionTypes.CREATE_UNIT_PREFERENCE_SUCCESS,
  UnitsActionTypes.CREATE_UNIT_PREFERENCE_FAILURE,
)<
  RequestConfig<NormalizedUnitsApiResponse>,
  NormalizedUnitsApiResponse,
  Error
>()

export const getRequestOptions = (
  data: UnitPreferencesPostData,
): RequestOptions => ({
  data: {
    attributes: {
      alias: data.alias,
      effective_date: format(createDateObject(), 'yyyy-MM-dd'),
    },
    relationships: {
      housekeeper: {
        data: { id: data.housekeeperId, type: 'housekeeper' },
      },
      unit: { data: { id: data.unitId, type: 'unit' } },
    },
    type: 'housekeeper_unit_preference',
  },
})

export const getParams = () => ({ include: ['housekeeper'] })

export const getParamsForFetchUnit = () => ({
  include: ['housekeeper_preferences', 'lock_box'],
})

export const createUnitPreference =
  (data: UnitPreferencesPostData) => async dispatch => {
    try {
      const requestData = getRequestOptions(data)
      const reason = get(data, 'reason')
      if (reason) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        requestData.data!.attributes.reason = reason
      }

      const params = getParams()
      const request = unitsService.createUnitPreference.bind(
        null,
        requestData,
        params,
      )
      const result = await dispatch(
        createUnitPreferenceAction.request({ request }),
      )

      // we need to re-fetch the associated unit to ensure we have the updated data locally
      const fetchUnitsParams = getParamsForFetchUnit()
      await dispatch(fetchUnitById(data.unitId, fetchUnitsParams))

      dispatch(createUnitPreferenceAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(createUnitPreferenceAction.failure(error))
      throw error
    }
  }
