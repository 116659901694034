import { InvalidTokenError, jwtDecode } from 'jwt-decode'
import camelCase from 'lodash/camelCase'

import { asyncTimeout } from 'packages/utils/asyncHelpers'

export function toCamelCase(
  obj: Record<string, unknown>,
): Record<string, unknown> {
  const cameled = {}

  Object.entries(obj).forEach(([key, value]) => {
    cameled[camelCase(key)] = value
  })

  return cameled
}

export function isAuthTokenValid(token: string | null): boolean {
  if (!token) {
    return false
  }

  // eslint-disable-next-line no-restricted-syntax
  const now = Date.now()

  try {
    const { exp } = jwtDecode(token)
    if (exp) {
      return now < exp * 1000 // jwt timestamp is 10 digits vs 13
    }

    return false
  } catch (error) {
    if (error instanceof InvalidTokenError) return false
    throw error
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function waitForNetwork(tries = 1) {
  if (navigator.onLine) {
    return
  }

  if (tries >= 3) {
    throw new Error('max wait for network, browser still offline')
  }

  await asyncTimeout(tries * 1000)
  return waitForNetwork(tries + 1)
}
