import { capitalize } from 'lodash/fp'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'typesafe-actions'

import { getErrorDetail } from 'packages/grimoire/src/utils/jsonapi.utils'

import { ApplicationState } from 'app/hkhub/store/store'
import { createZone as actionCreateZone } from 'app/hkhub/store/zones/actions/createZone'

import { useAdminContext } from '../../contexts/AdminContext'

const standardError = new Error('An unknown error occurred.')

type ReduxDispatch = ThunkDispatch<ApplicationState, unknown, Action>

type CreateZoneDrawerState = {
  loading: boolean
  value: string
}

type UseCreateZoneDrawer = Pick<CreateZoneDrawerState, 'loading'> & {
  canSubmit: boolean
  onSubmit: (event) => Promise<void>
  onValueChange: (value: string) => void
}

export const useCreateZoneDrawer = (): UseCreateZoneDrawer => {
  const reduxDispatch = useDispatch<ReduxDispatch>()

  const { addAlert } = useAdminContext()
  const [drawerState, setDrawerState] = React.useState<CreateZoneDrawerState>({
    loading: false,
    value: '',
  })

  const onValueChange = React.useCallback(value => {
    setDrawerState(prev => ({ ...prev, value }))
  }, [])

  const createZone = React.useCallback(
    async params => {
      return await reduxDispatch(actionCreateZone(params))
    },
    [reduxDispatch],
  )

  const onSubmit = React.useCallback(
    async event => {
      event.preventDefault()
      setDrawerState(prev => ({ ...prev, loading: true }))

      try {
        const res = await createZone(drawerState.value)
        const zoneId = Object.keys(res.zone)[0]
        const fullUrl = `${window.origin}/zone/${zoneId}`

        addAlert({
          alertType: 'success',
          children: (
            <div>
              <div>New zone created!</div>
              <div>
                Access it at: <Link to={`/zone/${zoneId}`}>{fullUrl}</Link>
              </div>
            </div>
          ),
        })
      } catch (error) {
        const apiError = getErrorDetail(error)
        const err = apiError ? new Error(capitalize(apiError)) : standardError

        addAlert({
          alertType: 'danger',
          children: <div>{err.message}</div>,
        })
      } finally {
        setDrawerState(prev => ({
          ...prev,
          loading: false,
        }))
      }
    },
    [addAlert, createZone, drawerState.value],
  )

  return {
    canSubmit: !!drawerState.value && !drawerState.loading,
    loading: drawerState.loading,
    onSubmit,
    onValueChange,
  }
}
