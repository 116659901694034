/**********************************************************
 * TICKET COMMENT
 *********************************************************/
export type AllTicketCommentAttributes = {
  createdAt: string
  external: boolean
  modifiedAt: string
  monolithTimestamp: string
  note: string
  ticketId: string
  userId: string
}

export const AllTicketCommentAttributeNames = [
  'createdAt',
  'external',
  'modifiedAt',
  'monolithTimestamp',
  'note',
  'ticketId',
  'userId',
]
