import { connect } from 'react-redux'

import { getAgencyHks } from 'app/hkhub/store/housekeepers/selectors/getAgencyHks'
import { getPrimaryHks } from 'app/hkhub/store/housekeepers/selectors/getPrimaryHks'
import { getUnitsWithHousekeeperPreferences } from 'app/hkhub/store/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import {
  createUnitPreference,
  fetchUnitsByZoneWithPreferences,
  removeUnitPreference,
} from 'app/hkhub/store/units/actions'
import { UnitPreferencesPostData } from 'app/hkhub/store/units/units.types'

import HousekeepersIndex from '../HousekeepersIndex/HousekeepersIndex'

const mapStateToProps = (state: ApplicationState) => ({
  getAgencyHks: () => getAgencyHks(state),
  getPrimaryHks: () => getPrimaryHks(state),
  getUnitsWithHousekeeperPreferences: () =>
    getUnitsWithHousekeeperPreferences(state),
})

const mapDispatchToProps = dispatch => ({
  createUnitPreference: (options: UnitPreferencesPostData) =>
    dispatch(createUnitPreference(options)),
  fetchUnitsByZoneWithPreferences: (zoneId: string) =>
    dispatch(fetchUnitsByZoneWithPreferences(zoneId)),
  removeUnitPreference: (preferenceId: string, unitId: string) =>
    dispatch(removeUnitPreference(preferenceId, unitId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HousekeepersIndex)
