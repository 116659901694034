import { lowerFirst } from 'lodash'
import upperFirst from 'lodash/upperFirst'
import { useTranslation } from 'react-i18next'

/**
 * A light "wrapper hook" for react-i18next.
 * This directly exposes the `t` and `i18n` properties from the react-i18next package,
 * and adds some customization to help future-proof the way we are handling some things
 * (e.g. pluralizing strings)
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useI18n = () => {
  const { i18n, t } = useTranslation()

  /**
   * A helper function for translating strings that may or may not need to be pluralized.
   * The intention here is to provide a slightly cleaner interface towards pluralization than
   * t() does by default, as well as give us a safety net for future additions to our pluralization needs.
   * @param slug
   * @param count
   * @param withLeadingCount Whether to put the count in front of the translated string
   *    (e.g. "2 dogs" instead of just "dogs"); default = true
   */
  const tp = (slug: string, count = 0, withLeadingCount = true) => {
    const trans = t(slug, { count })
    return withLeadingCount ? `${count} ${trans}` : trans
  }

  /**
   * t function wrapped in an upperFirst function to keep the HTML clean
   * @param slug slug to translate
   */
  const ut = (slug: string) => upperFirst(t(slug))

  /**
   * t function wrapped in an lowerFirst function to keep the HTML clean
   * @param slug slug to translate
   */
  const lt = (slug: string) => lowerFirst(t(slug))

  return {
    i18n,
    lt,
    t,
    tp,
    ut,
  }
}
