import { noop } from 'lodash/fp'
import React from 'react'

import { Loader } from 'packages/common'
import { cleanIsDeepClean } from 'packages/grimoire'
import { isBefore, startOfToday } from 'packages/utils/dateHelpers'

import { PastCleanOverlay } from 'app/hkhub/components/schedule/components/schedule.styles'
import {
  cardNotTiny,
  CardSize,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks'
import { Clean } from 'app/hkhub/store/cleans'

import { Card, CardContent, Row } from './CleanCard.styles'
import { CleanCardAdditionalInfo } from './components/CleanCardAdditionalInfo'
import { CleanCardBadges } from './components/CleanCardBadges'
import { CleanCardLabel } from './components/CleanCardLabel'
import { CleanProgressText } from './components/CleanProgressText'

const St = {
  Card,
  CardContent,
  PastCleanOverlay,
  Row,
}

export enum CleanCardTestIds {
  pastCleanOverlay = 'CleanCard__pastCleanOverlay',
}

export type CleanCardPassedProps = {
  clean: Clean
  isLoading: boolean
}

export type CleanCardProps = CleanCardPassedProps & {
  onClick: (cleanId: string) => void
  size: CardSize
}

export const CleanCard: React.FC<CleanCardProps> = ({
  clean,
  isLoading,
  onClick,
  size,
}) => {
  const { entity } = useScheduleMatchParams()
  const isPastClean = isBefore(clean.effectiveDate, startOfToday())

  return (
    <St.Card
      cardType={entity}
      isLoading={isLoading}
      isDeepClean={cleanIsDeepClean(clean)}
      onClick={isLoading ? noop : () => onClick(clean.id)}
      size={size}
      status={clean.realtimeStatus}
    >
      {isLoading && <Loader />}
      {isPastClean && (
        <PastCleanOverlay data-testid={CleanCardTestIds.pastCleanOverlay} />
      )}

      <St.CardContent
        isDeepClean={cleanIsDeepClean(clean)}
        size={size}
        data-testcleanid={clean.id}
        status={clean.realtimeStatus}
      >
        {cardNotTiny(size) && (
          <St.Row>
            <CleanProgressText task={clean} />
            <CleanCardBadges clean={clean} />
          </St.Row>
        )}

        <CleanCardLabel clean={clean} size={size} />

        {cardNotTiny(size) && (
          <CleanCardAdditionalInfo clean={clean} entity={entity} size={size} />
        )}
      </St.CardContent>
    </St.Card>
  )
}
