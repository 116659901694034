import { upperFirst } from 'lodash/fp'
import * as React from 'react'

import { cleanIsDeepClean, cleanIsMidstay } from 'packages/grimoire'
import { TranslatorFn, useI18n, Slugs } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'

import { Clean } from 'app/hkhub/store/cleans'

import * as St from './CleanDrawerHeader.styles'

type CleanDrawerHeaderConfig = {
  icon: IconName
  text: string
}

const getConfig = (clean: Clean, t: TranslatorFn): CleanDrawerHeaderConfig => {
  if (cleanIsMidstay(clean)) {
    return {
      icon: IconName.midstayClean,
      text: t(Slugs.midStay),
    }
  }

  if (cleanIsDeepClean(clean)) {
    return {
      icon: IconName.bucketClean,
      text: t(Slugs.deep),
    }
  }

  return {
    icon: IconName.doorClose,
    text: t(Slugs.postStay),
  }
}

export enum CleanDrawerHeaderTestIds {
  closeIcon = 'CleanHeader__closeIcon',
  ownerBadge = 'CleanHeader__ownerBadge',
}

export type CleanDrawerHeaderProps = {
  clean: Clean
  forceCloseDrawer: () => void
}

export const CleanDrawerHeader: React.FC<CleanDrawerHeaderProps> = React.memo(
  props => {
    const { t } = useI18n()

    const { clean, forceCloseDrawer } = props
    const { icon, text } = getConfig(clean, t)

    return (
      <St.BaseCleanDrawerHeader {...props}>
        <St.CleanTypeOwnerContainer>
          <St.CleanTypeIcon
            centerItems={true}
            icon={icon}
            text={text}
            textFontSize={'inherit'}
            size={16}
          />
          {clean.isNextStayOwner && (
            <St.OwnerBadge data-testid={CleanDrawerHeaderTestIds.ownerBadge}>
              <SvgIcon
                centerItems={true}
                icon={IconName.crown}
                text={upperFirst(t(Slugs.owner))}
              />
            </St.OwnerBadge>
          )}
        </St.CleanTypeOwnerContainer>
        <St.CleanDrawerHeaderCloseIcon
          dataTestId={CleanDrawerHeaderTestIds.closeIcon}
          icon={IconName.x}
          onClick={forceCloseDrawer}
          size={22}
        />
      </St.BaseCleanDrawerHeader>
    )
  },
)
