import * as React from 'react'

import { SuperModalDisplayConfig } from './SuperModalContext.types'

export type SuperModalContextConfig = {
  afterModalClosed?: () => void
} & SuperModalDisplayConfig

export type SuperModalContextType = {
  showModal: (config: SuperModalContextConfig) => void
}

export const SuperModalContext = React.createContext<SuperModalContextType>(
  {} as SuperModalContextType,
)

export const useSuperModal = (): SuperModalContextType =>
  React.useContext(SuperModalContext)
