import * as React from 'react'
import { useLocation } from 'react-router'

import { useOnlineStatus } from 'packages/utils/hooks'

import { Events } from './events'
import { track } from './track'

/**
 * Component used to track pages and offline status
 */
export const SegmentTracker: React.FC = () => {
  const [currentLocation, setCurrentLocation] = React.useState<unknown>()
  const [currentOnlineStatus, setCurrentOnlineStatus] =
    React.useState<boolean>()

  const onlineStatus = useOnlineStatus().isOnline()

  const location = useLocation()

  React.useEffect(() => {
    if (currentLocation !== location) {
      setCurrentLocation(location)
      try {
        analytics.page({
          properties: {
            online: onlineStatus,
          },
        })
      } catch (e) {
        // If we end up here, segment isn't initialized, and there isn't anything we can do about it
        return
      }
    }
  }, [location, currentLocation, onlineStatus])

  React.useEffect(() => {
    if (currentOnlineStatus !== onlineStatus) {
      // For now, we only want to track offline in FA, if that changes, we need to update this line
      if (!onlineStatus) track(Events.goOffline)
      setCurrentOnlineStatus(onlineStatus)
    }
  }, [onlineStatus, currentOnlineStatus])

  return null
}
