import * as React from 'react'

import { Banner } from '../../Banner'
import { BannerConfig, ErrorContext, ErrorContextType } from './ErrorContext'

export type ErrorContextWrapperProps = {
  children: React.ReactNode
  contextName: string
}

export const ErrorContextWrapper: React.FC<ErrorContextWrapperProps> =
  React.memo(({ children }) => {
    const [bannerError, setBannerError] = React.useState<BannerConfig>({
      link: undefined,
      message: '',
    })

    const clearBannerError = React.useCallback(() => {
      setBannerError({
        link: undefined,
        message: '',
      })
    }, [])

    const handleSetErrorBoundary = React.useCallback(
      (args: BannerConfig) => {
        setBannerError(args)
        if (args.timeout) {
          setTimeout(() => {
            clearBannerError()
          }, args.timeout)
        }
      },
      [clearBannerError, setBannerError],
    )

    const contextValue: ErrorContextType = React.useMemo(
      () => ({
        clearBannerError,
        setBannerError: handleSetErrorBoundary,
      }),
      [clearBannerError, handleSetErrorBoundary],
    )

    return (
      <>
        <ErrorContext.Provider value={contextValue}>
          {children}
        </ErrorContext.Provider>

        <Banner
          link={bannerError.link}
          message={bannerError.message}
          onClose={clearBannerError}
        />
      </>
    )
  })
