import { SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { Arrow, useLayer } from 'react-laag'

import * as St from './DropdownMenu.styles'

/**
 * A down-pointing triangle for use as a caret in a dropdown menu.
 * This will be shown by default, but can also be used directly in custom children when necessary.
 */
export const DropdownCaret = styled.span`
  border-bottom: 5px solid transparent;
  border-left: 6px solid white;
  border-radius: 1px;
  border-top: 5px solid transparent;
  height: 0;
  margin-left: 8px;
  transform: rotate(90deg) translateX(1px);
  width: 0;
`

type OverrideKeys = 'menuItem' | 'menuWrapper' | 'menuToggle'
/** All possible keys for overriding styling of the internal components */
export type DropdownMenuStyleOverrides = {
  [key in OverrideKeys]?: SerializedStyles
}

export type DropdownMenuItem = {
  children: React.ReactNode
  key: string
  onClick?: (item: DropdownMenuItem) => void
}

export enum DropdownMenuTestIds {
  caret = 'DropdownMenu__caret',
}

export type DropdownMenuProps = {
  children: React.ReactNode
  emotionOverrides?: DropdownMenuStyleOverrides
  hideCaret?: boolean
  items: DropdownMenuItem[]
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  children,
  emotionOverrides,
  hideCaret = false,
  items,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const toggleMenu = () => setMenuOpen(prev => !prev)

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: menuOpen,
    onOutsideClick: () => setMenuOpen(false),
    placement: 'bottom-center',
  })

  const handleItemClick = React.useCallback((item: DropdownMenuItem) => {
    if (!item.onClick) return

    setMenuOpen(false)
    item.onClick(item)
  }, [])

  return (
    <>
      <St.MenuToggle
        {...triggerProps}
        onClick={toggleMenu}
        overrides={emotionOverrides?.menuToggle}
      >
        {children}
        {!hideCaret && (
          <DropdownCaret data-testid={DropdownMenuTestIds.caret} />
        )}
      </St.MenuToggle>

      {menuOpen &&
        renderLayer(
          <St.MenuWrapper
            {...layerProps}
            overrides={emotionOverrides?.menuWrapper}
          >
            {items.map(item => (
              <St.MenuItem
                key={item.key}
                onClick={() => handleItemClick(item)}
                overrides={emotionOverrides?.menuItem}
              >
                {item.children}
              </St.MenuItem>
            ))}

            <Arrow {...arrowProps} />
          </St.MenuWrapper>,
        )}
    </>
  )
}
