import styled from '@emotion/styled'

import { colors } from '../../../styles'

export const Title = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`

export const OptionWrapper = styled.div<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? colors.dusk40 : 'inherit')};
  cursor: pointer;
  margin-left: 8px;

  &:not(:first-of-type) {
    margin-top: 4px;
  }
`

export const InputWrapper = styled.span`
  display: inline-flex;
`

export const Radio = styled.input`
  cursor: pointer;
  margin-right: 8px;
`

export const Label = styled.label`
  font-weight: 400;
`
