import get from 'lodash/get' // eslint-disable-line
import { createAsyncAction } from 'typesafe-actions'

import { format } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { reservationsService } from '../reservations.service'
import {
  NormalizedReservationsApiResponse,
  ReservationApiFields,
  ReservationsActionTypes,
} from '../reservations.types'

export const fetchNextReservationsByDateAction = createAsyncAction(
  ReservationsActionTypes.FETCH_NEXT_RESERVATIONS_BY_DATE,
  ReservationsActionTypes.FETCH_NEXT_RESERVATIONS_BY_DATE_SUCCESS,
  ReservationsActionTypes.FETCH_NEXT_RESERVATIONS_BY_DATE_FAILURE,
)<
  RequestConfig<NormalizedReservationsApiResponse>,
  NormalizedReservationsApiResponse,
  Error
>()

/* eslint-disable @typescript-eslint/naming-convention */
export const getParams = (date: Date, zoneId: string): RequestOptions => ({
  fields: {
    reservation: ReservationApiFields,
    unit: [], // all we need here is Unit.id, so we can effectively exclude all fields
  },
  filter: {
    check_in__gt: format(date, 'yyyy-MM-dd'),
    only_upcoming: true,
    'unit.zone': zoneId,
  },
  include: ['unit'],
  page: { size: 1500 },
})
/* eslint-enable @typescript-eslint/naming-convention */

/**
 * Helper function to build the custom `reservations` data object.
 * This includes keying the reservation by a `date-unit hash`, rather than the usual entity ID.
 * @param rawReservations
 * @param date
 */
const buildCustomReservations = (rawReservations, date: Date) =>
  Object.values(rawReservations).reduce(
    (accum: Record<string, unknown>, res) => {
      const unitId = get(res, 'relationships.unit.data.id')
      const hash = `${format(date, 'yyyy-MM-dd')}-${unitId}`
      return {
        ...accum,
        [hash]: res,
      }
    },
    {},
  )

/**
 * Fetches all of the "next reservations" for a given date and zone.
 *
 * This effectively says to the API: "give me the ONE next reservation AFTER this date for every unit in the associated zone".
 * Most notably, this is only useful when you are only concerned with a single date at a time (e.g. Schedule Day View).
 * @param date
 * @param zoneId
 */
export const fetchNextReservationsByDate =
  (date: Date, zoneId: string) => async dispatch => {
    try {
      const params = getParams(date, zoneId)
      const request = reservationsService.fetchReservations.bind(null, params)
      const result = await dispatch(
        fetchNextReservationsByDateAction.request({ request }),
      )

      // if we have a valid reservation in our response payload, we will use it to build the custom data shape
      const rawReservations = get(result, 'normalized.reservation')
      if (rawReservations) {
        const payloadWithCustomHash = {
          ...result,
          normalized: {
            reservation: buildCustomReservations(rawReservations, date),
          },
        }

        // dispatch/return with custom payload (hash + reservation)
        dispatch(
          fetchNextReservationsByDateAction.success(payloadWithCustomHash),
        )

        return payloadWithCustomHash.normalized
      }

      // otherwise, just return the response as-is
      dispatch(fetchNextReservationsByDateAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchNextReservationsByDateAction.failure(error))
      throw error
    }
  }
