import * as React from 'react'

import {
  DEFAULT_WILDCARD,
  splitAndInsertWildcards,
} from 'packages/utils/searchHelpers'

import { User } from 'app/hkhub/store/users/users.types'

import styles from './AsyncUserSelector.module.scss'

export const displayName = (user: User): string =>
  `${user.firstName} ${user.lastName}`

export const getHighlightedUserSearchResults = (
  user: User,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  { inputValue }, // react-select params
): React.ReactNode => {
  const searchString = inputValue.trim()
  // no inputValue means we are not searching, thus we can simply display the name
  if (!searchString) {
    return displayName(user)
  }

  const splitByMatch = splitAndInsertWildcards(displayName(user), searchString)

  return splitByMatch.reduce((accum, val, i) => {
    if (val === DEFAULT_WILDCARD) {
      return accum // do not make a node for strings matching the wildcard
    }

    // bold it if the previous string was '*'
    const isSearchMatch = i > 0 && splitByMatch[i - 1] === DEFAULT_WILDCARD
    const classname = isSearchMatch ? styles.searchMatch : undefined
    return accum.concat(
      <span className={classname} key={i}>
        {val}
      </span>,
    )
  }, [] as React.ReactNode[])
}
