import classNames from 'classnames'
import * as React from 'react'

import styles from './DataTableHeader.module.scss'

export type DataTableHeaderProps = {
  /**
   * (Optional) List of CSS class names to apply to each column at the same index.
   *
   * - Note that these are just the (string) _names_ of the classes to be applied, not full React-style `style` objects.
   * - Each class in this list will be applied to the column at the same index in the `values` prop.
   * - If no `flex` overrides are provided, every column will default to `flex: 1 1 0`
   */
  classes?: string[]
  /**
   * Whether the table supports removing items from the dataset.
   * For this header component, this is primarily used for ensuring the
   *    Flexbox alignment is adjusted to match the associated rows.
   */
  hasActions?: boolean
  /**
   * The list of static values to use for the title of each column.
   *
   * Can be any valid React element. Note that these values directly determine
   * the number of columns that will be rendered.
   */
  values: React.ReactNode[]
}

const DataTableHeader: React.SFC<DataTableHeaderProps> = ({
  classes = [],
  hasActions = false,
  values = [],
}) => (
  <div className={styles.dataTableHeader}>
    {values.map((title, idx) => (
      <div className={classNames(styles.column, classes[idx])} key={idx}>
        {title}
      </div>
    ))}

    {hasActions && (
      <div className={classNames(styles.column, styles.actionColumn)} />
    )}
  </div>
)

/**
 * A header component with default styling for use within the `DataTable` component. You most likely will not
 * ever need to use this component directly, as `DataTable` will create it from its own props.
 *
 * Some quick usage notes:
 * - Column count is determined by the number of values included in the `values` prop.
 * - By default, all columns will be evenly spaced with `flex: 1 1 0` styling, but this can easily be overridden by providing accompanying override classes in the `classes` prop.
 */
export default React.memo(DataTableHeader)
