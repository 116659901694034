import { camelCase, filter, map, pipe } from 'lodash/fp'
import React from 'react'

import { TaskJobType } from 'packages/grimoire'
import { IconName } from 'packages/iconic'

import { parseHkName } from 'app/hkhub/components/schedule/helpers/housekeeperHelpers'
import { AssignmentRejectionReason } from 'app/hkhub/store/assignments'
import { HkEmployeeType, Housekeeper } from 'app/hkhub/store/housekeepers'
import { Unit } from 'app/hkhub/store/units'

import { AssignmentEditorTranslations } from './AssignmentEditor.container'
import {
  AssignmentEditorState,
  HkSelectConfig,
  SelectedHousekeeperIdList,
  SelectedHousekeeperList,
} from './AssignmentEditor.reducer'
import { St } from './AssignmentEditor.styles'

export const configToConfigDisplayName = (
  config: HkSelectConfig,
  housekeepers: Housekeeper[],
  rate: string,
  rejectedString: string,
): React.ReactFragment => {
  if (!rate && hkIsContractor(config.hkId, housekeepers)) {
    return (
      <>
        <St.MissingRatesOptionText>
          {config.displayName}
        </St.MissingRatesOptionText>
        <St.MissingRatesIcon icon={IconName.alertTriangle} size={14} />
        <St.MissingRatesText className="ratesNeeded">
          Rates Needed
        </St.MissingRatesText>
      </>
    )
  }

  return config.rejectionReason ? (
    <>
      {config.displayName}
      <St.RejectionReason>
        ({rejectedString} - {config.rejectionReason})
      </St.RejectionReason>
    </>
  ) : (
    <>{config.displayName} </>
  )
}

export const makeHkSelectConfig =
  (
    assignmentRejectionReasonMap: AssignmentEditorState['assignmentRejectionReasonMap'],
    strings: AssignmentEditorTranslations,
  ) =>
  (hk: Housekeeper): HkSelectConfig => {
    return {
      displayName: parseHkName(hk),
      hkId: hk.id,
      rejectionReason: getRejectReasonDisplay(
        assignmentRejectionReasonMap[hk.id],
        strings,
      ),
    }
  }

/** Given reducer state and the selected index of a Select, return an array of Housekeeper Select Configs to display as Options */
export const getHkConfigOptions = (
  state: AssignmentEditorState,
  strings: AssignmentEditorTranslations,
): HkSelectConfig[] => {
  return pipe(
    filter((hk: Housekeeper) => !state.selectedHousekeepers.includes(hk)),
    map(makeHkSelectConfig(state.assignmentRejectionReasonMap, strings)),
  )(state.housekeepers)
}

const getRejectReasonDisplay = (
  rejectReason: AssignmentRejectionReason,
  strings: AssignmentEditorTranslations,
) => {
  return rejectReason ? strings[camelCase(rejectReason)] : ''
}

export const getSelectedHkFromHousekeepers =
  (housekeepers: Housekeeper[]) =>
  (config: HkSelectConfig | null): Housekeeper | null => {
    const result = housekeepers.find(hk => hk.id === config?.hkId)
    if (!config || !result) return null

    return result
  }

export const getFilteredHksFromHousekeepers =
  (housekeepers: Housekeeper[], housekeeperIds: SelectedHousekeeperIdList) =>
  (hk: Housekeeper | null): Housekeeper[] => {
    return housekeepers.filter(
      // filter out other housekeepers that are already selected in other select dropdowns,
      // but do include the currently selected housekeeper so we can show them in the dropdown
      otherHk => otherHk.id === hk?.id || !housekeeperIds.includes(otherHk.id),
    )
  }

/**
 * Prepares the list of selected housekeepers for submitting to the service/API.
 * This currently includes:
 *   - Removing null values (i.e. empty Select)
 *   - Removing duplicate entries
 * @param housekeepers
 */
export const sanitizedHousekeepers = (
  housekeepers: SelectedHousekeeperList,
): Housekeeper[] =>
  housekeepers.reduce((acc, hk): Housekeeper[] => {
    if (!hk || acc.includes(hk)) {
      return acc
    }

    return acc.concat(hk)
  }, [] as Housekeeper[])

const hkIsContractor = (hkId: string, hks: Housekeeper[]): boolean => {
  const hk = hks.find(hk => hk.id === hkId)

  return (
    hk?.employeeType === HkEmployeeType.contractor ||
    hk?.employeeType === HkEmployeeType.agency
  )
}

/** if a clean doesn't have a rate, then disable contractor options in the select */
export const hkOptionIsDisabled = (
  option: HkSelectConfig,
  hks: Housekeeper[],
  payRate: string,
): boolean => {
  return !payRate && hkIsContractor(option.hkId, hks)
}

export const getCleanRateForCleanType = (
  jobType: TaskJobType[],
  unit: Unit,
): string => {
  return jobType.includes('deep_clean')
    ? unit.rateContractorDeepClean || ''
    : unit.rateContractorPostStay || ''
}
