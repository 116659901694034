import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import {
  getImpersonationToken as getImpersonationTokenFromLS,
  setImpersonationToken as setImpersonationTokenToLS,
  removeImpersonationToken as removeImpersonationTokenFromLS,
} from 'packages/utils/misc'

import {
  setAuthErrorAction,
  setNeedsFullAuthRedirectAction,
  setNeedsSilentRefreshAction,
  setTokensAction,
} from './actions'
import { clearImpersonationTokenAction } from './actions/clearImpersonationToken'
import {
  fetchImpersonationTokenRequest,
  fetchImpersonationTokenFailure,
  fetchImpersonationTokenSuccess,
} from './actions/fetchImpersonationToken'
import { AuthState } from './auth.types'

// Initial state
export const initialState: AuthState = {
  error: undefined,
  needsFullAuthRedirect: true,
  needsSilentRefresh: false,
  refreshToken: undefined,
  token: undefined,
}

// Only include synchronous actions in the `actions` object
const actions = {
  clearImpersonationTokenAction,
  fetchImpersonationTokenFailure,
  fetchImpersonationTokenRequest,
  fetchImpersonationTokenSuccess,
  setAuthErrorAction,
  setNeedsFullAuthRedirectAction,
  setNeedsSilentRefreshAction,
  setTokensAction,
}

type AuthActionsTypes = ActionType<typeof actions>

export const authReducer = (
  state = initialState,
  action: AuthActionsTypes,
): AuthState =>
  produce(state, (draft: AuthState) => {
    switch (action.type) {
      case getType(setTokensAction): {
        draft.error = undefined
        draft.needsFullAuthRedirect = false
        draft.needsSilentRefresh = false
        draft.refreshToken = action.payload.refreshToken
        draft.token = action.payload.idToken

        const impersonationTokenFromLS = getImpersonationTokenFromLS()
        const impersonationTokenFromPayload = action.payload.impersonationToken

        if (
          impersonationTokenFromPayload &&
          impersonationTokenFromPayload !== impersonationTokenFromLS
        ) {
          setImpersonationTokenToLS(impersonationTokenFromPayload)
        }

        draft.impersonationToken =
          impersonationTokenFromPayload || impersonationTokenFromLS

        return
      }

      case getType(setNeedsFullAuthRedirectAction): {
        draft.error = undefined
        draft.needsFullAuthRedirect = action.payload
        draft.needsSilentRefresh = false
        return
      }

      case getType(setNeedsSilentRefreshAction): {
        draft.error = undefined
        draft.needsFullAuthRedirect = false
        draft.needsSilentRefresh = action.payload
        return
      }

      case getType(setAuthErrorAction): {
        draft.error = action.payload
        draft.needsFullAuthRedirect = false
        draft.needsSilentRefresh = false
        draft.token = undefined
        return
      }

      case getType(fetchImpersonationTokenRequest): {
        draft.error = undefined
        return
      }

      case getType(fetchImpersonationTokenSuccess): {
        const impersonationToken = action.payload
        if (typeof impersonationToken === 'string') {
          setImpersonationTokenToLS(impersonationToken)
          draft.needsFullAuthRedirect = true
          draft.error = undefined
        }

        return
      }

      case getType(fetchImpersonationTokenFailure): {
        draft.needsFullAuthRedirect = false
        draft.needsSilentRefresh = false
        return
      }

      case getType(clearImpersonationTokenAction): {
        draft.impersonationToken = undefined
        removeImpersonationTokenFromLS()
        draft.needsFullAuthRedirect = true
        return
      }
    }
  })
