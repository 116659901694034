import { deprecated } from 'typesafe-actions'

import { EventHandler } from 'packages/webSocketEvents'

import { ApplicationState } from 'app/hkhub/store/store'

import { TasksActionTypes, TaskWsEvent } from '../tasks.types'

const { createStandardAction } = deprecated

export const wsTaskDeactivatedAction = createStandardAction(
  TasksActionTypes.WS_EVENT_TASK_DEACTIVATED,
)<TaskWsEvent>()

export const wsTaskDeactivated: EventHandler<ApplicationState> =
  (data: TaskWsEvent) => dispatch => {
    dispatch(wsTaskDeactivatedAction(data))
  }
