/* eslint-disable no-console */
import { Context } from '@datadog/browser-core'
import { datadogLogs as dd, StatusType } from '@datadog/browser-logs'

function logToTheDog(
  msg: string,
  status: StatusType,
  ctx: Context = {},
  debug = false,
  error?: Error,
) {
  if (debug) {
    console.log(`Sending "${status}" log to DataDog with msg & context:`)
    console.log({ ctx, msg })
  }

  if (process.env.NODE_ENV !== 'test') {
    dd.logger.log(msg, { ctx }, status, error)
  }
}

export function logInfo(msg: string, ctx: Context = {}, debug = false): void {
  logToTheDog(msg, StatusType.info, ctx, debug)
}

export function logWarning(
  msg: string,
  ctx: Context = {},
  debug = false,
): void {
  logToTheDog(msg, StatusType.warn, ctx, debug)
}

export function logError(err: Error, ctx: Context = {}, debug = false): void {
  const msg = err instanceof Error ? err.message : err
  logToTheDog(msg, StatusType.error, ctx, debug, err)
}

export function setLoggingUser(
  authUserId: string,
  activeUserId?: string,
): void {
  dd.setGlobalContextProperty('user', {
    activeUserId: activeUserId ?? authUserId,
    id: authUserId,
  })
}
