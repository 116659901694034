import { createSelector } from 'reselect'

import { isBefore, isSameDay, isAfter } from 'packages/utils/dateHelpers'
import { transformNormalizedToTyped } from 'packages/utils/store'

import { ApplicationState } from 'app/hkhub/store/store'

import {
  Reservation,
  ReservationAttributeNames,
  ReservationsState,
  UnitReservationsBuckets,
} from '../reservations.types'

export const occursOnDate = (
  reservation: Reservation,
  date: string,
): boolean => {
  const { checkIn, checkOut } = reservation
  return (
    isSameDay(date, checkIn) ||
    isSameDay(date, checkOut) ||
    (isAfter(date, checkIn) && isBefore(date, checkOut))
  )
}

export const isForUnit = (fromResId: string, targetId: string): boolean =>
  fromResId === targetId

const matchesUnitAndDate = (res, unitId, date) =>
  occursOnDate(res.attributes, date) &&
  isForUnit(res.relationships.unit.data.id, unitId)

const getReservationState = (state: ApplicationState) => state.reservations
const getUnitId = (_state, _date, unitId) => unitId
const getDate = (_state, date) => date

const selector = (
  reservations: ReservationsState,
  date: string,
  unitId: string,
) => {
  return Object.values(reservations.data)
    .filter(res => matchesUnitAndDate(res, unitId, date))
    .map(res =>
      transformNormalizedToTyped<Reservation>(res, ReservationAttributeNames),
    )
}

export const getReservationForUnitAndDate = createSelector(
  getReservationState,
  getDate,
  getUnitId,
  selector,
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeGetReservationForUnitAndDate = () =>
  createSelector(getReservationState, getDate, getUnitId, selector)

export const getReservationsBucketedByUnit = createSelector(
  getReservationState,
  (reservationState): UnitReservationsBuckets => {
    const buckets: UnitReservationsBuckets = {}

    Object.values(reservationState.data).forEach(res => {
      const unitId = res.relationships.unit.data.id
      const transformed = transformNormalizedToTyped<Reservation>(
        res,
        ReservationAttributeNames,
      )

      if (!buckets[unitId]) buckets[unitId] = []

      buckets[unitId].push(transformed)
    })

    return buckets
  },
)
