import { isPWA } from 'packages/utils/pwa'

import { getAnalytics } from '../providers/segment'
import {
  hasValidTokenAndNoAnalyticsUser,
  getUserData,
} from './trackers.helpers'
import { Tracker } from './trackers.types'

/**
 * Calls segment to identify the user
 * @param getAuthToken Selector to get the auth token from the store
 */
export function createIdentifyUser<ApplicationState>(
  getAuthToken: (state: ApplicationState) => string | undefined,
): Tracker<ApplicationState> {
  return _ => _ => postState => {
    if (hasValidTokenAndNoAnalyticsUser(getAuthToken)(postState)) {
      const { id, email } = getUserData(getAuthToken)(postState)

      getAnalytics().identify(id, { email, pwa: isPWA() })
    }
  }
}
