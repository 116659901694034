import { createAsyncAction } from 'typesafe-actions'

import { format } from 'packages/utils/dateHelpers'
import { RequestConfig } from 'packages/utils/store/jsonapi.types'

import { hkAvailabilitiesService } from '../hkAvailabilities.service'
import {
  HkAvailabilitiesActionTypes,
  HkAvailabilityApiFields,
  NormalizedHkAvailabilitiesApiResponse,
} from '../hkAvailabilities.types'

export const fetchHkAvailabilitiesAction = createAsyncAction(
  HkAvailabilitiesActionTypes.FETCH_HK_AVAILABILITIES,
  HkAvailabilitiesActionTypes.FETCH_HK_AVAILABILITIES_SUCCESS,
  HkAvailabilitiesActionTypes.FETCH_HK_AVAILABILITIES_FAILURE,
)<
  RequestConfig<NormalizedHkAvailabilitiesApiResponse>,
  NormalizedHkAvailabilitiesApiResponse,
  Error
>()

export const getParams = (zoneId: string, startDate: Date, endDate: Date) => ({
  fields: {
    effective_availability: HkAvailabilityApiFields,
    housekeeper: [],
  },
  filter: {
    date: {
      range: [format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')],
    },
    housekeeper: { related_to_zone: zoneId },
  },
  include: ['housekeeper'],
  page: { size: 1500 },
})

export const fetchHkAvailabilities =
  (zoneId: string, startDate: Date, endDate: Date) => async dispatch => {
    try {
      const params = getParams(zoneId, startDate, endDate)
      const request = hkAvailabilitiesService.fetchHkAvailabilities.bind(
        null,
        params,
      )
      const result = await dispatch(
        fetchHkAvailabilitiesAction.request({ request }),
      )
      dispatch(fetchHkAvailabilitiesAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchHkAvailabilitiesAction.failure(error))
      throw error
    }
  }
