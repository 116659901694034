import { createAsyncAction } from 'typesafe-actions'

import { format, apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { CleansActionTypes, CleanPatchData } from '../cleans.types'
import { fetchCleanById } from './fetchCleanById'

export const updateCleanAction = createAsyncAction(
  CleansActionTypes.UPDATE_CLEAN,
  CleansActionTypes.UPDATE_CLEAN_SUCCESS,
  CleansActionTypes.UPDATE_CLEAN_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const buildRequestData = (patchData: CleanPatchData): RequestOptions => {
  const { completedAt, effectiveDate, notes, jobType, startedAt } = patchData
  const attributes = {
    completed_at: completedAt && apiDateStringWithSeconds(completedAt),
    effective_date: effectiveDate && format(effectiveDate, 'yyyy-MM-dd'),
    job_type: jobType,
    notes,
    started_at: startedAt && apiDateStringWithSeconds(startedAt),
  }

  const assignedHousekeepers = Array.isArray(patchData.hks)
    ? patchData.hks.map(housekeeper => ({
        id: housekeeper.id,
        type: 'housekeeper',
      }))
    : undefined

  const relationships = {
    ...(assignedHousekeepers && {
      assigned_housekeepers: { data: [...assignedHousekeepers] },
    }),
  }

  return {
    data: {
      attributes,
      id: patchData.id,
      relationships,
      type: 'task',
    },
  }
}

export const updateClean = (patchData: CleanPatchData) => async dispatch => {
  try {
    const cleanId = patchData.id
    const requestData = buildRequestData(patchData)
    const request = tasksService.updateTask.bind(null, cleanId, requestData)
    const result = await dispatch(updateCleanAction.request({ request }))

    // re-fetch the associated clean to ensure we have the latest version locally
    await dispatch(fetchCleanById(cleanId))
    dispatch(updateCleanAction.success(result))

    return result.normalized
  } catch (error) {
    dispatch(updateCleanAction.failure(error))
    throw error
  }
}
