import styled from '@emotion/styled'
import React from 'react'

import { cleanIsDeepClean } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans'

const St = {
  DeepCleanBadge: styled.span`
    background: ${colors.sand};
    border: 1px #c9c2a6 solid;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    padding: 0px 6px;
    margin-right: 8px;
  `,
}

export enum DeepCleanBadgeTestIds {
  container = 'DeepCleanBadge__container',
}

export type DeepCleanBadgeProps = {
  clean: Clean
}

export const DeepCleanBadge: React.FC<DeepCleanBadgeProps> = React.memo(
  ({ clean }) => {
    const isDeepClean = cleanIsDeepClean(clean)

    const { t } = useI18n()

    return isDeepClean ? (
      <St.DeepCleanBadge data-testid={DeepCleanBadgeTestIds.container}>
        {t(Slugs.deep)}
      </St.DeepCleanBadge>
    ) : null
  },
)
