import React from 'react'
import { useSelector } from 'react-redux'

import { fullName } from 'packages/grimoire'

import { CardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { UnknownHk } from 'app/hkhub/store/housekeepers/housekeepers.utils'
import { getSortedHks } from 'app/hkhub/store/housekeepers/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { Visit } from 'app/hkhub/store/visits'

import { St } from '../VisitCardLabel.styles'

export enum VisitCardUnitViewLabelTestIds {
  container = 'VisitCardUnitViewLabel__container',
}

export type VisitCardUnitViewLabelProps = {
  size: CardSize
  visit: Visit
}

export const VisitCardUnitViewLabel: React.FC<VisitCardUnitViewLabelProps> = ({
  visit,
  size,
}) => {
  const housekeepers = useSelector((state: ApplicationState) =>
    getSortedHks(state.housekeepers),
  )

  let hkList = [UnknownHk]
  if (visit.assignedHkIds.length) {
    hkList = visit.assignedHkIds.map(
      assignedHkId =>
        housekeepers.find(otherHk => otherHk.id === assignedHkId) || UnknownHk,
    )
  }

  const hkNames = hkList.map(hk => fullName(hk.user))

  return (
    <St.Container data-testid={VisitCardUnitViewLabelTestIds.container}>
      <St.PrimaryLabel
        beginWithEllipsisOnTiny={false}
        status={visit.realtimeStatus}
        size={size}
      >
        {hkNames.join(', ')}
      </St.PrimaryLabel>
    </St.Container>
  )
}
