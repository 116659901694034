import styled from '@emotion/styled'
import React from 'react'

import { Breakpoint, colors } from 'packages/styles'
import { convertHoursToTimeString } from 'packages/utils/mathHelpers'

import { ScheduleRowHeaderEndText } from 'app/hkhub/components/schedule/components/schedule.styles'
import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'

type WithHours = { hours: number }

const BORDER_RADIUS = 8

const St = {
  HoursProgress: styled(ScheduleRowHeaderEndText)`
    align-items: center;
    display: flex;
    flex-shrink: 0;
  `,

  Label: styled.label<WithHours>`
    color: ${({ hours }) => (hours > 40 ? colors.alert : colors.success)};
    font-weight: ${({ hours }) => (hours > 40 ? 'bold' : '600')};
  `,

  Progress: styled.progress<WithHours>`
    /* All of this junk is needed to reset the browsers' default <progress> styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;

    height: 8px;
    margin: 0 8px;
    border-radius: ${BORDER_RADIUS}px;
    max-width: 64px;

    &::-moz-progress-bar {
      background-color: ${({ hours }) =>
        hours > 40 ? colors.alert : colors.success};
      border-radius: ${BORDER_RADIUS}px;
    }

    &::-webkit-progress-bar {
      background-color: ${colors.midnight10};
      border-radius: ${BORDER_RADIUS}px;
    }

    &::-webkit-progress-value {
      background-color: ${({ hours }) =>
        hours > 40 ? colors.alert : colors.success};
      border-radius: ${BORDER_RADIUS}px;
    }
  `,
}

export enum HoursProgressTestIds {
  container = 'HoursProgress__container',
}

export type HoursProgressProps = {
  breakpoint: Breakpoint
  className?: string
  hk: Housekeeper
  hours: number
}

export const HoursProgress: React.FC<HoursProgressProps> = React.memo(
  ({ breakpoint, className, hk, hours }) => {
    const { dayCount } = useScheduleMatchParams()

    const htmlId = `hours__${hk?.id}`
    const hoursText = convertHoursToTimeString(hours)
    const hoursTextPrefix = dayCount === 7 ? 'Week: ' : ''

    const showProgressBar =
      dayCount === 7 &&
      hk?.employeeType === 'employee' &&
      hk?.id !== 'unassigned'

    return (
      <St.HoursProgress
        breakpoint={breakpoint}
        className={className}
        data-testid={HoursProgressTestIds.container}
      >
        {showProgressBar ? (
          <>
            <St.Label hours={hours} htmlFor={htmlId}>
              {hoursText}
            </St.Label>
            <St.Progress hours={hours} id={htmlId} value={hours} max={40} />
            <span>{`40h Week`}</span>
          </>
        ) : (
          <span>{`${hoursTextPrefix}${hoursText}`}</span>
        )}
      </St.HoursProgress>
    )
  },
)
