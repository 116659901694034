import { deprecated } from 'typesafe-actions'

import { AuthActionTypes } from '../auth.types'

const { createStandardAction } = deprecated

export const setNeedsSilentRefreshAction = createStandardAction(
  AuthActionTypes.SET_NEEDS_SILENT_REFRESH,
)<boolean>()

export const setNeedsSilentRefresh =
  (needsSilentRefresh: boolean) => dispatch =>
    dispatch(setNeedsSilentRefreshAction(needsSilentRefresh))
