import { produce } from 'immer'
import get from 'lodash/get' // eslint-disable-line
import { ActionType, getType } from 'typesafe-actions'

import { clearZoneAction } from 'app/hkhub/store/zones/actions'

import {
  clearZoneNotificationsAction,
  dismissZoneNotificationAction,
  fetchAllZoneNotificationsAction,
  fetchZoneNotificationsWithAddHksAction,
} from './actions'
import { ZoneNotificationsState } from './notifications.types'

const initialState: ZoneNotificationsState = {
  data: {},
  error: undefined,
  housekeeper: {},
  isLoading: false,
  user: {},
}

const actions = {
  clearZoneAction,
  clearZoneNotificationsAction,
  dismissZoneNotificationAction,
  fetchAllZoneNotificationsAction,
  fetchZoneNotificationsWithAddHksAction,
}

type ZoneNotificationsActionsTypes = ActionType<typeof actions>

export const zoneNotificationsReducer = (
  state = initialState,
  action: ZoneNotificationsActionsTypes,
): ZoneNotificationsState =>
  produce(state, (draft: ZoneNotificationsState) => {
    switch (action.type) {
      case getType(actions.clearZoneNotificationsAction):
        draft.data = {}
        draft.error = undefined
        draft.housekeeper = {}
        draft.isLoading = false
        draft.user = {}
        return

      case getType(actions.fetchAllZoneNotificationsAction.success):
      case getType(actions.fetchZoneNotificationsWithAddHksAction.success): {
        draft.data = get(action, 'payload.normalized.zoneNotification') || {}
        draft.error = undefined
        draft.housekeeper = get(action, 'payload.normalized.housekeeper') || {}
        draft.isLoading = false
        draft.user = get(action, 'payload.normalized.user') || {}
        return
      }

      case getType(actions.dismissZoneNotificationAction.success): {
        draft.error = undefined
        draft.isLoading = false
        delete draft.data[action.payload]
        return
      }

      case getType(actions.dismissZoneNotificationAction.failure):
      case getType(actions.fetchAllZoneNotificationsAction.failure): {
        draft.isLoading = false
        draft.error = get(
          action,
          'payload',
          new Error('Unknown Error in notificationsReducer'),
        )

        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.data = {}
        draft.housekeeper = {}
        draft.user = {}
        return
      }
    }
  })
