import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { CleanApiFields } from '../../cleans'
import { HousekeeperApiFields } from '../../housekeepers'
import { TicketApiFields } from '../../tickets'
import { unitsService } from '../units.service'
import { UnitApiFields, UnitPreferencesApiFields } from '../units.types'

const getParams = (): RequestOptions => ({
  fields: {
    clean: CleanApiFields,
    'housekeeper_preferences.housekeeper': HousekeeperApiFields,
    housekeeper_unit_preference: UnitPreferencesApiFields,
    ticket: TicketApiFields,
    unit: UnitApiFields,
  },
  include: [
    'custom_inspection_items',
    'housekeeper_preferences',
    'housekeeper_preferences.housekeeper',
    'lock_box',
    'open_tickets',
    'open_tickets.assignee',
  ],
})

/**
 * Sends a request to fetch a given Unit based on its ID.
 * In any new code, this should always be preferred over `fetchUnitById`, as this
 * uses a static & predictable set of params for the request, which makes for much easier debugging.
 *
 * "Strict" is used here in the sense that the request params are static, rather than allowing one to
 * pass in arbitrary params like the other action.
 * @param unitId
 */
export const fetchUnitByIdStrict = createAsyncThunk(
  'units/api/fetchUnitByIdStrict',
  async (unitId: string) => {
    const params = getParams()
    return await unitsService.fetchUnitById(unitId, params)
  },
)
