import { createAsyncAction } from 'typesafe-actions'

import {
  RequestOptions,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { housekeepersService } from '../housekeepers.service'
import {
  HousekeepersActionTypes,
  NormalizedHousekeepersApiResponse,
} from '../housekeepers.types'

export const searchHousekeepersAction = createAsyncAction(
  HousekeepersActionTypes.SEARCH_HKS,
  HousekeepersActionTypes.SEARCH_HKS_SUCCESS,
  HousekeepersActionTypes.SEARCH_HKS_FAILURE,
)<
  RequestConfig<NormalizedHousekeepersApiResponse>,
  NormalizedHousekeepersApiResponse,
  Error
>()

export const getParams = (search: string): RequestOptions => {
  return {
    filter: {
      is_active: true,
      search,
    },
    include: ['borrowed_zones', 'manager', 'user', 'zone'],
    page: { size: 1500 },
  }
}

/**
 * Performs a search request to find Hks matching the specified search string.
 * Note that only Hks who are not currently associated with a zone will be included
 * in the search results.
 *
 * (For further details about the search implementation, please refer to the API docs.)
 *
 * @param search The search string to use for searching Hks
 */
export const searchHousekeepers = (search: string) => async dispatch => {
  try {
    const params = getParams(search)
    const request = housekeepersService.fetchHousekeepers.bind(null, params)
    const response = await dispatch(
      searchHousekeepersAction.request({ request }),
    )
    dispatch(searchHousekeepersAction.success(response))
    return response.normalized
  } catch (error) {
    dispatch(searchHousekeepersAction.failure(error))
    throw error
  }
}
