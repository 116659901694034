import styled from '@emotion/styled'
import React, { useMemo } from 'react'

import { Tooltip, TooltipPosition } from 'packages/common'
import { SanitizedHTML } from 'packages/common/src/SanitizedHTML'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Reservation } from 'app/hkhub/store/reservations'
import { Ticket, TicketStatus } from 'app/hkhub/store/tickets'
import { Unit } from 'app/hkhub/store/units'
import {
  ticketIsOverdue,
  ticketStatusToComputedMap,
} from 'app/hkhub/utils/ticketHelpers'

import { useTranslateTickets } from '../../../hooks/useTranslateTickets'
import { UnitLinks } from '../UnitDrawer/components'
import { AssignmentSection } from './AssignmentSection'
import { DateSection } from './DateSection'
import { TicketComments } from './TicketComments'
import {
  TicketSection,
  TicketSectionTitle,
  TicketStatusContainer,
} from './TicketDrawer.styles'
import { TicketDrawerReservationInfo } from './TicketDrawerReservationInfo/TicketDrawerReservationInfo'

const St = {
  Address: styled.div`
    ${text.bodyRegularSmall};
    margin-top: 4px;
  `,
  Description: styled(SanitizedHTML)`
    ${text.bodyRegularDefault};
    margin-bottom: 16px;
  `,
  Divider: styled.hr`
    background-color: ${colors.midnight10};
    border: 0;
    height: 8px;
  `,
  InProgressHeader: styled.div`
    ${text.headingTinyCaps};
    margin-bottom: 2px;
    margin-left: 6px;
    width: 100%;
  `,
  InProgressText: styled.span`
    display: flex;
    flex-direction: column;
  `,
  InProgressTime: styled.div`
    ${text.bodyRegularSmall}
    margin-left: 6px;
    width: 100%;
  `,
  OverdueIcon: styled(SvgIcon)`
    background: white;
    border-radius: 36px;
    border: 1px solid ${colors.dusk};
    color: ${colors.alert};
    margin-left: 8px;
    padding: 2px 16px;
  `,
  RequestedBy: styled(TicketSectionTitle)`
    margin-top: 24px;
  `,
  Section: styled(TicketSection)`
    position: relative;
  `,
  SubTitle: TicketSectionTitle,
  Text: styled.div`
    ${text.bodyRegularDefault};
  `,
  TicketDetails: styled.div`
    padding-bottom: 64px;
    padding-top: 16px;
  `,
  TicketDetailsHeaderSection: styled.div`
    margin: 0 24px;
  `,
  TicketSection,
  TicketStatusContainer,
  Title: styled.div`
    ${text.headingDefault};
    margin-bottom: 16px;
  `,
  TitleSection: styled.div`
    display: flex;
  `,
  Tooltip: styled(Tooltip)`
    margin-top: 2px;
  `,
  UnitCode: styled.div`
    ${text.headingDefault};
  `,
  UnitLinks: styled(UnitLinks)`
    padding: 8px;
    padding-bottom: 0;
    margin: 0 16px;
  `,
  UnitLinksSection: styled.div`
    margin-left: 8px;
  `,
  UnitName: styled.div`
    ${text.bodyRegularDefault}
  `,
}

const useTranslations = (ticket: Ticket) => {
  const { t, ut } = useI18n()
  const { translateSeverity } = useTranslateTickets()

  return {
    assignment: ut(Slugs.assignment),
    description: ut(Slugs.description),
    dueDate: ut(Slugs.dueDate),
    inProgress: ut(Slugs.inProgress),
    requestedBy: ut(Slugs.requestedBy),
    severity: translateSeverity(ticket.severity),
    toolTipText: t(Slugs.overdueTooltipText),
    unassigned: ut(Slugs.unassigned),
    unknown: ut(Slugs.unknown),
    viewTicket: ut(Slugs.viewTicket),
  }
}

export type TicketDetailsProps = {
  currentReservation?: Reservation
  isFetchingReservation: boolean
  isFetchingTicket: boolean
  nextReservation?: Reservation
  sortedTicketCommentIds: string[]
  ticket: Ticket
  unit: Unit
}

export const TicketDetails: React.FC<TicketDetailsProps> = React.memo(props => {
  const {
    currentReservation,
    isFetchingReservation,
    isFetchingTicket,
    nextReservation,
    ticket,
    sortedTicketCommentIds,
    unit,
  } = props
  const strings = useTranslations(ticket)

  const cityStateText = `${unit.city}, ${unit.state}`

  const computedTicketStatus = useMemo(
    () => ticketStatusToComputedMap[ticket.status],
    [ticket.status],
  )

  const isOverdue = useMemo(() => ticketIsOverdue(ticket), [ticket])

  return (
    <St.TicketDetails>
      {/* TITLE & ADDRESS INFO */}
      <St.TicketDetailsHeaderSection>
        <St.UnitCode>{unit.unitCode}</St.UnitCode>
        <St.UnitName>{unit.name}</St.UnitName>
        <St.Address>
          <div>{unit.address}</div>
          <div>{cityStateText}</div>
        </St.Address>
      </St.TicketDetailsHeaderSection>
      {/* RESERVATION CHECK IN / CHECK OUT INFO */}
      {computedTicketStatus !== 'completed' && (
        <TicketDrawerReservationInfo
          currentReservation={currentReservation}
          isFetchingReservation={isFetchingReservation}
          isFetchingTicket={isFetchingTicket}
          nextReservation={nextReservation}
        />
      )}
      <St.Divider />

      {/* DESCRIPTION & REQUESTED BY INFO */}
      <St.Section>
        <St.TitleSection>
          <St.Title>{ticket.title}</St.Title>
          {isOverdue && (
            <St.Tooltip
              message={strings.toolTipText}
              position={TooltipPosition.Top}
            >
              <St.OverdueIcon
                centerItems={true}
                icon={IconName.alertTriangle}
                size={12}
              />
            </St.Tooltip>
          )}
        </St.TitleSection>

        <St.SubTitle>{strings.description}:</St.SubTitle>
        <St.Description html={ticket.description || ''} />

        <St.RequestedBy>{strings.requestedBy}</St.RequestedBy>
        <St.Text>{ticket.requestedBy}</St.Text>

        {ticket?.status === TicketStatus.ACCEPTED && ticket?.startedAt && (
          <St.TicketStatusContainer isInProgress={!!ticket?.startedAt}>
            <SvgIcon icon={IconName.watch} size={20}></SvgIcon>
            <St.InProgressText>
              <St.InProgressHeader>{strings.inProgress}</St.InProgressHeader>
              <St.InProgressTime>
                {formatLocalized(ticket.startedAt, DateFormat.SlashesWithTime)}
              </St.InProgressTime>
            </St.InProgressText>
          </St.TicketStatusContainer>
        )}
      </St.Section>

      {/* ASSIGNMENT INFO */}
      <AssignmentSection ticketId={ticket.id} />
      <St.Divider />

      {/* DATES INFO */}
      <DateSection ticket={ticket} />
      <St.Divider />

      {/* COMMENTS SECTION */}
      <St.Section>
        <TicketComments commentIds={sortedTicketCommentIds} />
      </St.Section>

      {/* LINKS SECTION */}
      <St.UnitLinksSection>
        <St.UnitLinks unitId={unit.id} />
      </St.UnitLinksSection>
    </St.TicketDetails>
  )
})
