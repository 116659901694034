import classNames from 'classnames'
import { capitalize, startCase } from 'lodash/fp'
import get from 'lodash/get' // eslint-disable-line
import * as React from 'react'
import { v4 as uuid } from 'uuid'

import { Button, Card, Loader, Switch } from 'packages/common'

import AsyncUserSelector from 'app/hkhub/components/core/components/AsyncUserSelector/AsyncUserSelector'
import selectorStyles from 'app/hkhub/components/core/components/AsyncUserSelector/AsyncUserSelectorOverride.module.scss'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import {
  DispositionAssignment,
  TicketingDisposition,
} from 'app/hkhub/store/ticketing/ticketing.types'
import { User } from 'app/hkhub/store/users/users.types'

import {
  AssignmentSaveConfig,
  ConfirmModalConfig,
} from '../../TicketRoutingPage'

import styles from './TicketRoutingCard.module.scss'

const useTranslations = (disposition: TicketingDisposition) => {
  const { t } = useI18n()
  const { tier2Display } = disposition

  return {
    assignee: t(Slugs.assignee),
    cancel: startCase(t(Slugs.cancel)),
    edit: startCase(t(Slugs.edit)),
    off: t(Slugs.off),
    on: t(Slugs.on),
    save: startCase(t(Slugs.save)),
    title: tier2Display,
    unassigned: capitalize(t(Slugs.noAssigneeDesignated)),
    viewTickets: startCase(t(Slugs.viewTickets)),
  }
}

export type TicketRoutingCardProps = {
  assignment: DispositionAssignment | null
  className?: string
  disabled?: boolean
  disposition: TicketingDisposition
  getUsersSearchResults: () => User[]
  handleAssignmentSave: (
    dispositionId: string,
    assignment: AssignmentSaveConfig,
  ) => void
  handleSearchUsers: (input: string) => Promise<any> // eslint-disable-line
  isLoading: boolean
  setConfirmModalConfig: React.Dispatch<
    React.SetStateAction<ConfirmModalConfig>
  >
}

const TicketRoutingCard: React.FC<TicketRoutingCardProps> = ({
  assignment,
  className = '',
  disabled = false,
  disposition,
  getUsersSearchResults,
  handleAssignmentSave,
  handleSearchUsers,
  isLoading,
  setConfirmModalConfig,
}) => {
  const strings = useTranslations(disposition)
  const isActive = get(assignment, 'isActive', false)
  const assignee = get(assignment, 'user')

  const [editing, setEditing] = React.useState(false)
  const [selectedAssignee, setSelectedAssignee] = React.useState(assignee)
  const [searchValue, setSearchValue] = React.useState('')

  const onCancelCallback = React.useCallback(() => {
    setEditing(false)
    setSelectedAssignee(assignee)
  }, [assignee])

  const onConfirmCallback = React.useCallback(() => {
    handleAssignmentSave(disposition.id, {
      ...assignment,
      isActive,
      user: selectedAssignee,
    })

    setEditing(false)
  }, [
    assignment,
    disposition,
    handleAssignmentSave,
    isActive,
    selectedAssignee,
  ])

  const onSaveClick = React.useCallback(() => {
    setConfirmModalConfig({
      id: uuid(),
      newAssignmentMonolithUserId: selectedAssignee
        ? selectedAssignee.monolithUserId
        : undefined,
      onCancel: onCancelCallback,
      onConfirm: onConfirmCallback,
    })
  }, [
    onCancelCallback,
    onConfirmCallback,
    selectedAssignee,
    setConfirmModalConfig,
  ])

  const onDispositionToggle = React.useCallback(
    value => {
      handleAssignmentSave(disposition.id, {
        ...assignment,
        isActive: value,
      })
    },
    [assignment, disposition, handleAssignmentSave],
  )

  const saveDisabled = React.useMemo(
    () => get(assignee, 'id') === get(selectedAssignee, 'id'),
    [assignee, selectedAssignee],
  )

  return (
    <Card className={classNames(styles.ticketRoutingCard, className)}>
      {isLoading && <Loader />}

      <>
        <div className={styles.row}>
          <span className={styles.title}>{strings.title}</span>

          <span className={styles.status}>
            {isActive ? strings.on : strings.off}
          </span>

          <Switch
            checked={isActive}
            onToggle={onDispositionToggle}
            disabled={editing || !assignee || disabled}
          />
        </div>

        {editing ? (
          <div className={classNames(styles.row, styles.editRow)}>
            <div className={styles.editRowAssignee}>
              <span className={styles.editRowAssigneeTitle}>
                {strings.assignee}
              </span>

              <AsyncUserSelector
                className={classNames(selectorStyles.selector, {
                  [selectorStyles.noSearch]: searchValue.length < 3,
                })}
                getUsersResults={getUsersSearchResults}
                onUserSelectionChange={user =>
                  setSelectedAssignee(user as User)
                }
                searchUsers={search => {
                  setSearchValue(search)
                  return handleSearchUsers(search)
                }}
                selectedUser={selectedAssignee ? selectedAssignee : null}
              />
            </div>

            <div className={styles.editRowButtons}>
              <Button onClick={onCancelCallback} buttonType={'utility'}>
                {strings.cancel}
              </Button>
              <Button
                disabled={saveDisabled}
                onClick={onSaveClick}
                buttonType={'primary'}
              >
                {strings.save}
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.row}>
            <span className={styles.assignee}>
              {assignee ? (
                <span>
                  {`${assignee.firstName} ${assignee.lastName} - `}
                  <a
                    className={styles.ticketsLink}
                    href={`https://www.vacasa.com/admin/dashboard/tickets/index?UserID=${assignee.monolithUserId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {strings.viewTickets}
                  </a>
                </span>
              ) : (
                <span className={styles.noAssignee}>{strings.unassigned}</span>
              )}
            </span>

            <Button
              disabled={disabled}
              onClick={() => setEditing(true)}
              buttonType={'utility'}
            >
              {strings.edit}
            </Button>
          </div>
        )}
      </>
    </Card>
  )
}

export default React.memo(TicketRoutingCard)
