import styled from '@emotion/styled'
import { upperFirst } from 'lodash/fp'
import React from 'react'

import { ClipboardText } from 'packages/common'
import { Amenities } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'

const St = {
  Details: styled.div`
    padding-left: 8px;
    width: 100%;
  `,

  Icon: styled(SvgIcon)`
    padding-top: 4px;
    color: ${colors.midnight80};
  `,

  Section: styled.span`
    font-weight: 600;
  `,

  WifiInfo: styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    padding-left: 8px;
  `,

  WifiTitle: styled.strong`
    color: ${colors.midnight80};
    font-size: 12px;
    text-transform: uppercase;
  `,

  WifiTitleSection: styled.div`
    margin-bottom: 16px;
  `,
}

export type WifiInfoProps = {
  amenities: Amenities
  className?: string
}

export const WifiInfo: React.FC<WifiInfoProps> = React.memo(
  ({ amenities, className = '' }) => {
    const { t } = useI18n()

    return amenities.wifi ? (
      <St.WifiInfo className={className}>
        <St.Icon icon={IconName.wifi} size={14} />
        <St.Details>
          <St.WifiTitleSection>
            <St.WifiTitle>{upperFirst(t(Slugs.wifi))}</St.WifiTitle>
          </St.WifiTitleSection>

          <div>
            <St.Section>{`${upperFirst(t(Slugs.network))}: `}</St.Section>
            <span>{amenities.wifiNetwork}</span>
          </div>

          <div>
            <St.Section>{`${upperFirst(t(Slugs.password))}:`}</St.Section>

            {amenities.wifiPassword && (
              <ClipboardText>{amenities.wifiPassword}</ClipboardText>
            )}
          </div>
        </St.Details>
      </St.WifiInfo>
    ) : null
  },
)
