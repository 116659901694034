import { createAsyncAction } from 'typesafe-actions'

import { AssignmentApiFields, UserApiFieldsWithoutPII } from 'packages/grimoire'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ReservationApiFields } from 'app/hkhub/store/reservations/reservations.types'
import { TaskPhotoApiFields } from 'app/hkhub/store/taskPhotos'

import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { UnitApiFields } from '../../units/units.types'
import { CleanApiFields, CleansActionTypes } from '../cleans.types'

export const fetchCleanAction = createAsyncAction(
  CleansActionTypes.FETCH_CLEAN,
  CleansActionTypes.FETCH_CLEAN_SUCCESS,
  CleansActionTypes.FETCH_CLEAN_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    assignment: AssignmentApiFields,
    reservation: ReservationApiFields,
    task: CleanApiFields,
    task_photo: TaskPhotoApiFields,
    unit: UnitApiFields,
    user: UserApiFieldsWithoutPII,
  },
  include: [
    'assignment_changes',
    'assignment_changes.changed_by',
    'assignment_changes.housekeeper',
    'assignment_changes.housekeeper.user',
    'assigned_housekeepers',
    'task_photos',
    'rejected_assignments',
    'rejected_assignments.housekeeper',
    'reservation',
    'unit',
    'unit.lock_box',
  ],
  page: { size: 100 },
})

export const fetchCleanById = (cleanId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = tasksService.fetchTaskById.bind(null, cleanId, params)
    const result = await dispatch(fetchCleanAction.request({ request }))
    dispatch(fetchCleanAction.success(result))

    return result.normalized
  } catch (error) {
    dispatch(fetchCleanAction.failure(error))
    throw error
  }
}
