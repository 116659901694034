import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { colors, smallScreen } from 'packages/styles'
import {
  DateFormat,
  formatLocalized,
  isToday,
} from 'packages/utils/dateHelpers'

import { useScheduleNavigation } from 'app/hkhub/components/schedule/hooks'

const St = {
  DateNumber: styled.div`
    ${smallScreen(
      css`
        font-size: 13px;
      `,
    )};

    font-size: 21px;
    font-weight: bold;
  `,

  DateText: styled.div`
    font-size: 14px;
  `,

  DateTimelineCell: styled.div<{ today: boolean }>`
    color: ${({ today }) => (today ? colors.lake : 'white')};
    cursor: pointer;
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 4px;
    padding-left: 8px;

    & + div {
      border-left: 1px solid ${colors.midnight60};
    }
  `,
}

export type DateRangeHeaderCellProps = {
  date: Date
}

export const DateRangeHeaderCell: React.FC<DateRangeHeaderCellProps> = ({
  date,
}) => {
  const { navigate } = useScheduleNavigation()

  return (
    <St.DateTimelineCell
      onClick={() => navigate({ date, dayCount: 1 })}
      today={isToday(date)}
    >
      <St.DateText>
        {formatLocalized(date, DateFormat.DayNameShort)}
      </St.DateText>

      <St.DateNumber>
        {formatLocalized(date, DateFormat.DayOfMonthShort)}
      </St.DateNumber>
    </St.DateTimelineCell>
  )
}
