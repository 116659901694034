import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { zonesService } from '../zones.service'
import {
  NormalizedZonesApiResponse,
  ZoneApiFields,
  ZonesActionTypes,
} from '../zones.types'

export const searchZonesAction = createAsyncAction(
  ZonesActionTypes.SEARCH_ZONES,
  ZonesActionTypes.SEARCH_ZONES_SUCCESS,
  ZonesActionTypes.SEARCH_ZONES_FAILURE,
)<
  RequestConfig<NormalizedZonesApiResponse>,
  NormalizedZonesApiResponse,
  Error
>()

export const getParams = (search: string): RequestOptions => ({
  fields: {
    user: ['first_name', 'last_name'],
    zone: ZoneApiFields,
  },
  filter: {
    is_active: true,
    search,
  },
  include: ['managers'],
})

export const searchZones = (search: string) => async dispatch => {
  try {
    const params = getParams(search)
    const request = zonesService.fetchZones.bind(null, params)
    const result = await dispatch(searchZonesAction.request({ request }))
    dispatch(searchZonesAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(searchZonesAction.failure(error))
    throw error
  }
}
