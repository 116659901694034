import styled from '@emotion/styled'
import { concat, eq, reject } from 'lodash/fp'
import * as React from 'react'

import { Button, Drawer, DrawerContainerProps } from 'packages/common'
import { UnitRealtimeStatus } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'

import { UnitStatusList } from './UnitStatusList'

const DrawerContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  min-width: 320px;
  padding: 32px;
  padding-top: 48px;
`

const DrawerTitle = styled.div`
  color: ${colors.dusk};
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 24px;
`

const UnitStatusHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  // "Unit Status" sub-header
  div:first-of-type {
    font-weight: 600;
  }

  // "As of right now" sub-header
  div:nth-of-type(2) {
    color: #969a9c;
    font-size: 14px;
  }
`

const useTranslations = () => {
  const { t, ut } = useI18n()

  return {
    addFilters: t(Slugs.addFilters),
    apply: ut(Slugs.apply),
    asOfRightNow: t(Slugs.asOfRightNow),
    unitStatus: ut(Slugs.unitStatus),
  }
}

export enum UnitStatusDrawerTestIds {
  applyBtn = 'UnitStatusDrawer__applyBtn',
  container = 'UnitStatusDrawer__container',
}

type PickedDrawerProps = Pick<
  DrawerContainerProps,
  'afterExit' | 'forceClose' | 'isOpen'
>

export type UnitStatusDrawerProps = {
  onFiltersApplied: (unitStatuses: UnitRealtimeStatus[]) => void
} & PickedDrawerProps

export const UnitStatusDrawer: React.FC<UnitStatusDrawerProps> = ({
  afterExit,
  forceClose,
  isOpen,
  onFiltersApplied,
}) => {
  const strings = useTranslations()
  const [selectedStatuses, setSelectedStatuses] = React.useState(
    [] as UnitRealtimeStatus[],
  )

  const handleDrawerExit = () => {
    setSelectedStatuses([])
    afterExit()
  }

  const handleFilterToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target
    const status = value as UnitRealtimeStatus

    setSelectedStatuses(prev =>
      checked ? concat(status, prev) : reject(eq(status), prev),
    )
  }

  const applySelectedFilters = () => {
    onFiltersApplied(selectedStatuses)
    setSelectedStatuses([])
  }

  return (
    <Drawer
      afterExit={handleDrawerExit}
      forceClose={forceClose}
      isOpen={isOpen}
    >
      <DrawerContainer data-testid={UnitStatusDrawerTestIds.container}>
        <div>
          <DrawerTitle>{strings.addFilters}</DrawerTitle>
        </div>

        <UnitStatusHeader>
          <div>{strings.unitStatus}</div>
          <div>{strings.asOfRightNow}</div>
        </UnitStatusHeader>

        <UnitStatusList onFilterToggle={handleFilterToggle} />

        <Button
          block={true}
          dataTestId={UnitStatusDrawerTestIds.applyBtn}
          disabled={!selectedStatuses.length}
          onClick={applySelectedFilters}
          buttonType={'primary'}
        >
          {strings.apply}
        </Button>
      </DrawerContainer>
    </Drawer>
  )
}
