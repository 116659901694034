import { isToday, format, DateFormat } from 'packages/utils/dateHelpers'

export function getFormattedTime(time: string | null): string {
  if (!time) return ''

  const timeIsToday = isToday(time)
  const fullFormat = `${DateFormat.SlashesWithFullYear}, ${DateFormat.TimeWithAmPm}`

  return timeIsToday
    ? `Today, ${format(time, DateFormat.TimeWithAmPm)}`
    : `${format(time, fullFormat)}`
}
