import { produce } from 'immer'
import noop from 'lodash/fp/noop'

import { SuperModalDisplayConfig } from './SuperModalContext.types'

export const initialState: SuperModalContextState = {
  afterModalClosed: noop,
  display: {
    buttons: [],
    message: '',
    title: '',
  },
  renderState: {
    open: false,
    readyToClose: false,
  },
}

export type SuperModalContextState = {
  afterModalClosed: () => void
  display: SuperModalDisplayConfig
  renderState: {
    open: boolean
    readyToClose: boolean
  }
}

export enum SuperModalContextActionType {
  HANDLE_MODAL_CLOSE,
  SHOW_MODAL,
  UPDATE_ALL_BUTTONS,
  INITIATE_CLOSE_MODAL,
}

export type ModalContextReducerAction = {
  data?: {
    afterModalClosed?: () => void
    disabled?: boolean
    display?: SuperModalDisplayConfig
    loading?: boolean
  }
  type: SuperModalContextActionType
}

export const reducer = (
  state: SuperModalContextState,
  action: ModalContextReducerAction,
): SuperModalContextState => {
  return produce(state, (draft: SuperModalContextState) => {
    switch (action.type) {
      case SuperModalContextActionType.HANDLE_MODAL_CLOSE: {
        draft.renderState.open = false
        draft.renderState.readyToClose = false
        return draft
      }

      case SuperModalContextActionType.SHOW_MODAL: {
        draft.renderState.open = true
        draft.renderState.readyToClose = false

        const { afterModalClosed, display } = action.data || {}

        if (afterModalClosed) {
          draft.afterModalClosed = afterModalClosed
        }

        if (display) {
          draft.display = display
        }

        return draft
      }

      case SuperModalContextActionType.UPDATE_ALL_BUTTONS: {
        const { loading, disabled } = action.data || {}

        draft.display.buttons.forEach(btn => {
          if (loading !== undefined) btn.loading = loading
          if (disabled !== undefined) btn.disabled = disabled
        })

        return draft
      }

      case SuperModalContextActionType.INITIATE_CLOSE_MODAL: {
        draft.renderState.open = true
        draft.renderState.readyToClose = true

        return draft
      }

      default:
        return draft
    }
  })
}
