import classNames from 'classnames'
import * as React from 'react'

import {
  SuperModal,
  SuperModalProps,
  SuperModalPositionTypes,
} from '../SuperModal'

import styles from './LegacyDrawer.module.scss'

/**
 * All props that Modal can accept, regardless of optionality
 * Here we're omitting all props that exist on SuperModal, but
 * will be set by Drawer - the fillAxis props are removed from the Drawer
 * API and determined by `position`, as are `hiddenPosition` and `displayPosition`
 */
type AllPossibleDrawerProps = Omit<
  SuperModalProps,
  'centerHorizontally' | 'displayPosition' | 'hiddenPosition'
>

/**
 * Here we take mandatory props on SuperModal that have sane Drawer props
 *  and set them to optional.
 */
export type LegacyDrawerProps = AllPossibleDrawerProps & {
  position?: LegacyDrawerPosition
}

export enum LegacyDrawerPosition {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

const fullWidthDrawers = [LegacyDrawerPosition.TOP, LegacyDrawerPosition.BOTTOM]
const fullHeightDrawers = [
  LegacyDrawerPosition.RIGHT,
  LegacyDrawerPosition.LEFT,
]

const getDrawerPosition = (
  position: LegacyDrawerPosition,
): SuperModalPositionTypes[] => {
  const positions = [{ [position]: '-100%' }, { [position]: '0' }]
  // We need to add top 0 to position the drawer on the page, if it's a vertical drawer
  if (fullHeightDrawers.includes(position)) {
    return positions.map(p => ({ ...p, top: '0' }))
  }

  return positions
}

export const LegacyDrawer: React.FC<LegacyDrawerProps> = ({
  children,
  className,
  position = LegacyDrawerPosition.RIGHT,
  ...props
}) => {
  const fillXAxis = fullWidthDrawers.includes(position)
  const fillYAxis = fullHeightDrawers.includes(position)

  const [hiddenPosition, displayPosition] = getDrawerPosition(position)

  return (
    <SuperModal
      backdropClassName={styles.drawerBackdrop}
      className={classNames(styles.drawer, styles[position], className)}
      displayPosition={displayPosition}
      fillXAxis={fillXAxis}
      fillYAxis={fillYAxis}
      hiddenPosition={hiddenPosition}
      {...props}
    >
      {children}
    </SuperModal>
  )
}
