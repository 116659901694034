/* eslint-disable @typescript-eslint/naming-convention */
import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'
import { track, Events } from 'packages/wiretap'

const trackerTypes = {
  fieldapp: Events.fieldAppExternalLink,
  hub: Events.hubExternalLink,
}

const trackerType = trackerTypes[process.env.REACT_APP_BUILD_TARGET || 'hub']

const Link = styled.a`
  align-items: center;
  color: ${colors.lake};
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
`

const StyledSvgIcon = styled(SvgIcon)`
  margin-left: 5px;
`

export type ExternalLinkProps = {
  className?: string
  text: string
  url: string
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({
  className,
  text,
  url,
}) => {
  const handleTrack = () =>
    track(trackerType, {
      external_url: url,
      link_text: text,
      page: window.location.href,
    })

  return (
    <Link
      aria-label={text}
      className={className}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      onClick={handleTrack}
    >
      {text}
      <StyledSvgIcon
        centerItems={true}
        icon={IconName.externalLink}
        size={15}
      />
    </Link>
  )
}
