import * as React from 'react'

export type UseHotkeysProps = {
  /**
   * (Optional) Map of key codes to event handlers for `keydown` event
   * which should be wired up by this hook. Note that key code in this case refers to `event.code`.
   */
  keyDownHandlers?: {
    [keyCode: string]: (event: React.KeyboardEvent) => void
  }
  /**
   * (Optional) Map of key codes to event handlers for `keyup` event
   * which should be wired up by this hook. Note that key code in this case refers to `event.code`.
   */
  keyUpHandlers?: {
    [keyCode: string]: (event: React.KeyboardEvent) => void
  }
}

/**
 * A hook to simplify the management of adding and removing hotkeys to components.
 * Currently supports both `keydown` and `keyup` events.
 *
 * Note that this does currently add the event listeners directly to `document`, so there is
 * potential for collisions if we bind the same key in multiple components without managing
 * checking for "active" states in their handlers.
 * @param keyDownHandlers
 * @param keyUpHandlers
 */
export const useHotkeys = ({
  keyDownHandlers = {},
  keyUpHandlers = {},
}: UseHotkeysProps): void => {
  // wire up key-down handlers
  React.useEffect(() => {
    const handleKeyDown = event => {
      if (event.code in keyDownHandlers) {
        keyDownHandlers[event.code](event)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [keyDownHandlers])

  // wire up key-up handlers
  React.useEffect(() => {
    const handleKeyUp = event => {
      if (event.code in keyUpHandlers) {
        keyUpHandlers[event.code](event)
      }
    }

    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [keyUpHandlers])
}
