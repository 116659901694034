import styled from '@emotion/styled'
import * as React from 'react'

import { Button } from 'packages/common'

import { parseHkName } from 'app/hkhub/components/schedule/helpers/housekeeperHelpers'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers'

import { SharedEditSectionStyles } from '../../CleanDrawer.styles'

export type AssignmentSectionProps = {
  assignedHks: Housekeeper[]
  editable?: boolean
  onEditClick: () => void
}

const St = {
  AssignmentsContainer: styled.section`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 8px;
  `,

  ChangesContainer: styled.div`
    position: relative;
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding-top: 8px;
    width: 100%;
  `,

  EditButtonContainer: SharedEditSectionStyles.EditButtonContainer,

  LoaderWrapper: styled.div`
    height: 20px;
    position: relative;
  `,

  Title: styled.div`
    font-weight: bold;
    padding-bottom: 8px;
  `,
}

export const AssignmentSection: React.FC<AssignmentSectionProps> = React.memo(
  ({ assignedHks, editable = true, onEditClick }) => {
    const { t } = useI18n()

    return (
      <St.Container>
        <St.AssignmentsContainer>
          <St.Title>{t(Slugs.assignment)}</St.Title>
          <div>
            {assignedHks.length === 0
              ? t(Slugs.unassigned)
              : assignedHks
                  .map(hk =>
                    hk ? <div key={hk.id}>{parseHkName(hk)}</div> : null,
                  )
                  .filter(elem => !!elem)}
          </div>
        </St.AssignmentsContainer>

        {editable && (
          <St.EditButtonContainer>
            <Button onClick={onEditClick} buttonType={'text'}>
              {t(Slugs.editAssignment)}
            </Button>
          </St.EditButtonContainer>
        )}
      </St.Container>
    )
  },
)
