import get from 'lodash/get' // eslint-disable-line
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from '../../store'
import { User } from '../../users/users.types'
import { TicketingState, DispositionAssignment } from '../ticketing.types'

const getTicketingState = (state: ApplicationState): TicketingState =>
  state.ticketing

export const getAssignmentsKeyedByDispositionIds = createSelector(
  getTicketingState,
  ticketingState => {
    const { assignments, dispositions, users } = ticketingState

    const dispositionIds = Object.keys(dispositions)

    const assignmentsBucketedByDisposition = keyBy(
      assignments,
      'relationships.disposition.data.id',
    )

    const getAssignmentAndUser = (id): DispositionAssignment | null => {
      const assignment = assignmentsBucketedByDisposition[id]
      if (!assignment) {
        return null
      } else {
        const userId = get(assignment, 'relationships.user.data.id')
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const transformedAssignment = transformNormalizedToTyped<any>(
          assignment,
          ['isActive'],
        )

        const user = transformNormalizedToTyped<User>(users[userId], [
          'firstName',
          'lastName',
          'monolithUserId',
        ])

        return {
          ...transformedAssignment,
          user,
        }
      }
    }

    const assignmentsBucketedByDispositionId = {}
    dispositionIds.forEach(id => {
      assignmentsBucketedByDispositionId[id] = {
        assignment: getAssignmentAndUser(id),
      }
    })

    return assignmentsBucketedByDispositionId
  },
)
