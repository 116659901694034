import * as React from 'react'

import {
  UnitFiltersContext,
  UnitFiltersContextType,
} from './UnitFilters.context'
import {
  initialstate,
  unitFiltersReducer,
  UnitFiltersActionType,
} from './UnitFilters.reducer'
import { UnitFilterType, UnitViewFilter } from './UnitFilters.types'

export const UnitFiltersContextWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(unitFiltersReducer, initialstate)
  const { filters, needToClearSearchField, searchFilters } = state

  const addFilters = React.useCallback(
    (filterType: UnitFilterType, newFilters: UnitViewFilter[]) => {
      dispatch({
        filters: newFilters,
        filterType,
        type: UnitFiltersActionType.AddFilters,
      })
    },
    [],
  )

  const removeFilters = React.useCallback(
    (filterType: UnitFilterType, removeFilters: UnitViewFilter[]) => {
      dispatch({
        filters: removeFilters,
        filterType,
        type: UnitFiltersActionType.RemoveFilters,
      })
    },
    [],
  )

  const setSearchFilters = React.useCallback((newFilters: UnitViewFilter[]) => {
    dispatch({
      filters: newFilters,
      type: UnitFiltersActionType.SetSearchFilters,
    })
  }, [])

  const disableNeedToClearSearchField = React.useCallback(() => {
    dispatch({
      type: UnitFiltersActionType.DisableNeedToClearSearchField,
    })
  }, [])

  const clearAllFilters = React.useCallback(() => {
    dispatch({
      type: UnitFiltersActionType.ClearAllFilters,
    })
  }, [])

  const clearSearchFilters = React.useCallback(() => {
    dispatch({
      type: UnitFiltersActionType.ClearSearchFilters,
    })
  }, [])

  const resetToDefaultState = React.useCallback(() => {
    dispatch({
      type: UnitFiltersActionType.ResetToDefaultState,
    })
  }, [])

  const contextValue = React.useMemo(
    (): UnitFiltersContextType => ({
      addFilters,
      clearAllFilters,
      clearSearchFilters,
      disableNeedToClearSearchField,
      filters,
      needToClearSearchField,
      removeFilters,
      resetToDefaultState,
      searchFilters,
      setSearchFilters,
    }),
    [
      addFilters,
      clearAllFilters,
      clearSearchFilters,
      disableNeedToClearSearchField,
      filters,
      needToClearSearchField,
      removeFilters,
      resetToDefaultState,
      searchFilters,
      setSearchFilters,
    ],
  )

  return (
    <UnitFiltersContext.Provider value={contextValue}>
      {children}
    </UnitFiltersContext.Provider>
  )
}
