import styled from '@emotion/styled'

import { colors } from 'packages/styles'

export const CleanStatusContainer = styled.div<{ hasContent: boolean }>`
  align-items: center;
  background-color: ${colors.midnight4};
  border-top: 1px solid ${colors.midnight20};
  display: flex;
  justify-content: space-between;

  /* negate the padding of the drawer itself */
  margin-left: -24px;
  margin-right: -24px;

  border-bottom: ${({ hasContent }) =>
    hasContent ? `1px solid ${colors.midnight20}` : 'none'};

  height: ${({ hasContent }) => (hasContent ? '100%' : '0')};
  padding: ${({ hasContent }) => (hasContent ? '16px 32px 16px 32px' : '0')};
`

export const StatusSection = styled.div`
  align-items: center;
  color: ${colors.dusk};
  display: flex;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.5px;
  line-height: 10px;
`

export const CleanStatusAndTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
`
