import styled from '@emotion/styled'
import * as React from 'react'

import { NotificationBadge } from 'packages/common'
import { SvgIcon, IconName } from 'packages/iconic'
import { colors } from 'packages/styles'

import styles from './Pill.module.scss'

type PillSize = 'default' | 'small'
type PillVariant = 'default' | 'secondary'

const getSizeStyles: Record<PillSize, React.CSSProperties> = {
  default: {
    fontSize: '15px',
    padding: '8px 24px 8px 24px',
  },
  small: {
    fontSize: '13px',
    padding: '6px 16px 6px 12px',
  },
}

const getVariant: Record<PillVariant, React.CSSProperties> = {
  default: {
    background: colors.midnight40,
    color: colors.white,
  },
  secondary: {
    background: colors.midnight10,
    color: colors.midnight80,
  },
}

const PillContainer = styled.div<PillProps>`
  ${({ size = 'default' }) => ({ ...getSizeStyles[size] })}
  ${({ variant = 'default' }) => ({ ...getVariant[variant] })}
  align-items: center;
  border-radius: 24px;
  display: inline-flex;
  justify-content: center;
  cursor: ${props => (props.onClick ? 'pointer' : 'unset')};
  /* offset padding when required to make room for "close" button */
  padding-right: ${props => (props.onClose ? '12px' : '24px')};
`

const PillCloseBtn = styled.button<PillProps>`
  background: transparent;
  border: none;
  color: ${props =>
    props.variant === 'secondary' ? colors.midnight80 : 'white'};
  cursor: pointer;
  margin-left: 6px;
  padding: 2px;

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`

export enum PillTestIds {
  closeBtn = 'Pill__closeBtn',
  container = 'Pill__container',
}

export type PillProps = React.PropsWithChildren<{
  badgeCount?: number
  className?: string
  onClick?: () => void
  onClose?: () => void
  size?: PillSize
  variant?: PillVariant
}>

export const Pill: React.FC<PillProps> = props => {
  const { children, onClose, badgeCount } = props

  // wrap "onClose" to ensure the click does not bubble to "onClick" when both are present
  const handleClose = React.useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      if (!onClose || !event) return

      event.stopPropagation()
      onClose()
    },
    [onClose],
  )

  return (
    <PillContainer data-testid={PillTestIds.container} {...props}>
      {children}

      {badgeCount !== undefined && (
        <NotificationBadge count={badgeCount} className={styles.pillBadge} />
      )}

      {onClose && (
        <PillCloseBtn
          aria-label="Close"
          data-testid={PillTestIds.closeBtn}
          onClick={handleClose}
          variant={props.variant}
        >
          <SvgIcon icon={IconName.x} size={15} />
        </PillCloseBtn>
      )}
    </PillContainer>
  )
}
