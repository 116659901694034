import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { clearZoneAction } from 'app/hkhub/store/zones/actions'

import {
  fetchCleanAction,
  fetchCleansByZoneAndDateAction,
} from '../cleans/actions'
import { fetchTaskByIdAction } from '../tasks/actions'
import { fetchVisitByIdAction } from '../visits/actions'
import { TaskPhotosState } from './taskPhotos.types'
import { emptyNormalizedTaskPhotosData } from './taskPhotos.utils'

export const initialState: TaskPhotosState = Object.freeze({
  data: {},
})

const actions = {
  clearZoneAction,
  fetchCleanAction,
  fetchCleansByZoneAndDateAction,
  fetchTaskByIdAction,
  fetchVisitByIdAction,
}

export const taskPhotosReducer = (
  state = initialState,
  action: ActionType<typeof actions>,
): TaskPhotosState =>
  produce(state, (draft: TaskPhotosState) => {
    switch (action.type) {
      case getType(fetchCleanAction.success):
      case getType(fetchCleansByZoneAndDateAction.success):
      case getType(fetchTaskByIdAction.success):
      case getType(fetchVisitByIdAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedTaskPhotosData

        // The use of "merge" here lets attributes of each photo merge
        draft.data = merge(state.data, normalized.taskPhoto)

        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.data = {}
        return
      }

      default:
        return
    }
  })
