import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  CustomInspectionItem,
  CustomInspectionItemsAttributeNames,
  RawCustomInspectionItem,
} from './customInspectionItems.types'

export const hydrateRawCustomInspectionItem = (
  rawCustomInspectionItem: RawCustomInspectionItem,
): CustomInspectionItem => {
  const customInspectionItem = transformNormalizedToTyped<CustomInspectionItem>(
    rawCustomInspectionItem,
    CustomInspectionItemsAttributeNames,
  )

  return {
    ...customInspectionItem,
  }
}

export const emptyCustomInspectionItemsData = Object.freeze({
  customInspectionItem: {},
})
