import { every, map, pipe, values } from 'lodash/fp'

import { DeepCleanSkipFormProps } from './DeepCleanSkipForm'

type FormValues = DeepCleanSkipFormProps['formState']['values']

export const validateDeepCleanSkipForm = (formValues: FormValues): boolean =>
  pipe(
    values,
    map(str => str.trim()),
    every(Boolean),
  )(formValues)
