import { splitSearchQuery } from '../../../utils/misc'
import {
  CACHE_KEY_CODE_VERIFIER,
  generateCodeChallenge,
} from './generateCodeChallenge'

export const AUTH_REDIRECT_PATH = '/oauth/callback'

/**
 * Attempts to read and return a `state` param from the current window URL.
 * If no state can be found in the current URL, an empty string will be returned.
 *
 * Note that the state param is just the auth server sending back whatever we send it,
 * so we should always know what value we are expecting here (if any).
 */
export const getStateFromUrl = (): string => {
  const { state = '' } = splitSearchQuery(window.location.search)
  return decodeURIComponent(state)
}

export type AuthOverrideParams = {
  audience?: string
  scope?: string
}

const DEFAULT_SCOPE = 'openid profile params'

/**
 * Builds the full authentication URL with all necessary params, including
 * generating and storing a code verifier for later verification.
 *
 * @param authUrlBase
 * @param authClientId
 * @param overrides
 */
const getFullAuthUrl = async (
  authUrlBase: string,
  authClientId: string,
  /**
   * Optional custom OAuth `audience` and `scope` values. These may be set as
   * environment variables, e.g. `REACT_APP_IDP_SCOPE_GUESTWORKS`.
   */
  overrides: AuthOverrideParams = {},
): Promise<string> => {
  const clientId = encodeURIComponent(authClientId)
  const redirectUri = encodeURIComponent(
    `${window.location.origin}${AUTH_REDIRECT_PATH}`,
  )

  const { audience = '', scope = DEFAULT_SCOPE } = overrides
  const encodedScope = encodeURIComponent(scope)
  const encodedAudience = audience
    ? `&audience=${encodeURIComponent(audience)}`
    : ''

  const { pathname, search } = window.location
  const state = encodeURIComponent(`${pathname}${search}`)

  const { codeChallenge, codeVerifier } = await generateCodeChallenge()
  // we store this temporarily, as we will need it when we
  // exchange the returned "code" for an actual auth token
  sessionStorage.setItem(CACHE_KEY_CODE_VERIFIER, codeVerifier)

  return (
    authUrlBase +
    `?client_id=${clientId}` +
    `&redirect_uri=${redirectUri}` +
    `&response_type=code` +
    `&code_challenge_method=S256` +
    `&code_challenge=${codeChallenge}` +
    `&scope=${encodedScope}` +
    `${encodedAudience}` +
    `&state=${state}`
  )
}

/**
 * Triggers a full redirect to the auth/login server for full re-authentication.
 * This is "step 1" in the auth process, where we actually visit OneLogin to establish a session.
 *
 * @param authUrlBase
 * @param authClientId
 * @param overrides
 */
export const redirectForAuthentication = async (
  authUrlBase: string,
  authClientId: string,
  /**
   * Optional custom OAuth `audience` and `scope` values. These may be set as
   * environment variables, e.g. `REACT_APP_IDP_SCOPE_GUESTWORKS`.
   */
  overrides: AuthOverrideParams = {},
): Promise<void> => {
  const authUrl = await getFullAuthUrl(authUrlBase, authClientId, overrides)
  window.location.replace(authUrl)
}

/**
 * Builds the request configuration for "step 2" of the auth flow,
 * where we exchange the code received the previous step for a pair of auth & refresh tokens.
 *
 * This requires that the "code verifier" sent in step 1 of the process already
 * be available in session storage, without which an error will be thrown.
 *
 * Note that this DOES NOT make the request itself—only parses the necessary data for the HTTP request.
 */
export const getTokenRequest = (
  code: string,
  clientId: string,
): RequestInit => {
  const codeVerifier = sessionStorage.getItem(CACHE_KEY_CODE_VERIFIER)
  if (!codeVerifier) {
    throw Error('No codeVerifier found; full authentication redirect required.')
  }

  const redirectUri = encodeURIComponent(
    `${window.location.origin}${AUTH_REDIRECT_PATH}`,
  )

  const request = {
    body:
      'grant_type=authorization_code' +
      `&code=${code}` +
      `&redirect_uri=${redirectUri}` +
      `&client_id=${encodeURIComponent(clientId)}` +
      `&code_verifier=${codeVerifier}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  }

  return request
}

/**
 * Builds the request configuration for "step 3" of the auth flow,
 * where we exchange our current refresh token for a new pair of auth & refresh tokens.
 *
 * Note that this DOES NOT make the request itself—only parses the necessary data for the HTTP request.
 */
export const getRefreshTokenRequest = (
  refreshToken: string,
  clientId: string,
): RequestInit => {
  return {
    body:
      'grant_type=refresh_token' +
      `&client_id=${encodeURIComponent(clientId)}` +
      `&refresh_token=${refreshToken}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  }
}

const LogoutURL = 'https://vacasa.onelogin.com/oidc/2/logout'

export const logoutWithOneLogin = (): void => {
  window.location.replace(LogoutURL)
}
