import { noop } from 'lodash/fp'
import * as React from 'react'

import { ModalProps } from '../../Modal'
import { ModalChildrenProps } from '../../ModalPortal'

export type ModalConfig = {
  childRenderer: (props: ModalChildrenProps) => React.ReactNode
  modalProps?: Partial<ModalProps>
}

export type ModalContextType = {
  showModal: (config: ModalConfig) => void
}

export const ModalContext = React.createContext<ModalContextType>({
  showModal: noop,
})

export const useModalContext = (): ModalContextType =>
  React.useContext(ModalContext)
