import styled from '@emotion/styled'
import React from 'react'

import { Button, ButtonGroup } from 'packages/common'
import { colors, layers, useBreakpoint } from 'packages/styles'

import { useScheduleNavigation } from 'app/hkhub/components/schedule/hooks'
import { Slugs, useI18n } from 'app/hkhub/i18n'

const BORDER_RADIUS = 20

const St = {
  Button: styled(Button)<{ isActive: boolean }>`
    font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  `,

  FloatingRangeMenu: styled.div`
    background: ${colors.midnight};
    border-radius: ${BORDER_RADIUS}px;
    bottom: 24px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
    z-index: ${layers.tooltip};

    button,
    button:active,
    button:hover {
      border: 0;
      outline: 0;
    }

    button:first-of-type,
    button:first-of-type:active {
      border-bottom-left-radius: ${BORDER_RADIUS}px;
      border-right: 1px solid ${colors.midnight60};
      border-top-left-radius: ${BORDER_RADIUS}px;
    }

    button:last-of-type {
      border-bottom-right-radius: ${BORDER_RADIUS}px;
      border-left: 1px solid ${colors.midnight60};
      border-top-right-radius: ${BORDER_RADIUS}px;
    }
  `,
}

const BUTTON_DAYS = [1, 3, 7]

export type FloatingRangeMenuProps = {
  dayCount: number
}

export const FloatingRangeMenu: React.FC<FloatingRangeMenuProps> = ({
  dayCount,
}) => {
  const { t } = useI18n()

  const breakpoint = useBreakpoint()
  const isSmallScreen = breakpoint === 'SM'

  const { navigate } = useScheduleNavigation()

  return isSmallScreen ? (
    <St.FloatingRangeMenu>
      <ButtonGroup>
        {BUTTON_DAYS.map(days => (
          <St.Button
            isActive={dayCount === days}
            key={days}
            onClick={() => navigate({ dayCount: days })}
            buttonType={'utilityInverted'}
          >
            {`${days} ${t(Slugs.day)}`}
          </St.Button>
        ))}
      </ButtonGroup>
    </St.FloatingRangeMenu>
  ) : null
}
