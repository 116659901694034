import React from 'react'

import { fullName } from 'packages/grimoire'

import {
  CardSize,
  cardSizeGreaterThan,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans'
import { Housekeeper } from 'app/hkhub/store/housekeepers'
import {
  UnassignedHk,
  UnknownHk,
} from 'app/hkhub/store/housekeepers/housekeepers.utils'

import { St } from '../CleanCardLabel.styles'

const unassigned = [UnassignedHk]

const useTranslations = (hks: Housekeeper[]) => {
  const { t } = useI18n()

  const tierTexts = hks.reduce((acc, hk) => {
    if (!hk?.id) return acc

    const rank = hk?.tier.rank ?? 0
    acc[hk.id] = rank > 0 ? <span>{`${t(Slugs.tier)} ${rank}`}</span> : null
    return acc
  }, {})

  return {
    tierTexts,
  }
}

export type CleanCardUnitViewLabelProps = {
  clean: Clean
  size: CardSize
}

export const CleanCardUnitViewLabel: React.FC<CleanCardUnitViewLabelProps> = ({
  clean,
  size,
}) => {
  const hks = clean.assignedHks?.length ? clean.assignedHks : unassigned
  const strings = useTranslations(hks)

  const isTeamClean = clean.assignedHks?.length > 1
  const isLargeTeamClean = clean.assignedHks?.length > 2
  const displayTier = !isTeamClean && cardSizeGreaterThan(size, 'small')

  return (
    <St.Container>
      {hks.map((_hk, idx) => {
        const hk = _hk?.id ? _hk : UnknownHk
        return idx < 2 ? (
          <React.Fragment key={hk.id}>
            <St.PrimaryLabel
              beginWithEllipsisOnTiny={false}
              status={clean.realtimeStatus}
              size={size}
            >
              {fullName(hk.user)}
              {isLargeTeamClean && idx === 1 && <span> +</span>}
            </St.PrimaryLabel>

            {displayTier && (
              <St.SecondaryLabel status={clean.realtimeStatus} size={size}>
                {strings.tierTexts[hk.id] || ''}
              </St.SecondaryLabel>
            )}
          </React.Fragment>
        ) : null
      })}
    </St.Container>
  )
}
