import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { useOnlineStatus } from 'packages/utils/hooks'

import { AppDispatch, ApplicationState } from 'app/hkhub/store/store'
import { selectZone } from 'app/hkhub/store/zones/actions'
import { getZone as getZoneSelector } from 'app/hkhub/store/zones/selectors'

import ZoneNotActive from './ZoneNotActive' // Import the new component
import { ZonePage } from './ZonePage'
import { ZoneContext } from './ZonePage.context'
import { reducer, initialState, ZonePageActions } from './ZonePage.helpers'
import { useInitZone } from './ZonePage.hooks'

export const ZonePageContainer: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const getZone = useSelector(
    (state: ApplicationState) => (id: string) => getZoneSelector(state, id),
  )

  const { zoneId } = useParams<{ zoneId: string }>()
  if (!zoneId) {
    navigate('/')
  }

  const isOnline = useOnlineStatus().isOnline()
  const [state, zoneDispatch] = React.useReducer(reducer, initialState)
  const { initializingZone, refetchingZone, zone, zoneInitialized } = state

  const zoneIsLoading = useSelector(
    (state: ApplicationState) => state.zones.isLoading,
  )
  const zoneWasLoading = usePrevious(zoneIsLoading)

  // anytime zoneId changes, it means the user has changed zones in the UI, and thus we must re-init the zone
  React.useEffect(() => {
    zoneDispatch({ type: ZonePageActions.InitializeZone })
  }, [zoneId])

  // handler for initializing a zone
  // this is ideally only called when the app first starts up, or when the user changes a zone,
  // as it makes multiple API requests to fetch all of the basic data associated with a zone

  const { initZone } = useInitZone(zoneId)
  const initializeZone = React.useCallback(async () => {
    try {
      await initZone()
      zoneDispatch({ type: ZonePageActions.ZoneInitialized })
    } catch (error) {
      zoneDispatch({ type: ZonePageActions.ZoneLoadError })
    }
  }, [initZone])

  React.useEffect(() => {
    if (initializingZone) {
      initializeZone()
    }
  }, [initializingZone, initializeZone])

  React.useEffect(() => {
    const hasInitialized = initializingZone && zoneInitialized
    const hasLoaded = zoneWasLoading && !zoneIsLoading
    if (hasInitialized || hasLoaded) {
      const nextZone = getZone(zoneId)
      const isNewZone = nextZone !== zone
      if (nextZone && isNewZone) {
        dispatch(selectZone(nextZone))
      }

      zoneDispatch({ type: ZonePageActions.SetZone, zone: nextZone })
    }
  }, [
    getZone,
    initializingZone,
    dispatch,
    refetchingZone,
    zone,
    zoneId,
    zoneInitialized,
    zoneIsLoading,
    zoneWasLoading,
  ])

  const contextValue = React.useMemo(() => ({ zone }), [zone])

  const zoneIsInvalid = zoneInitialized && !initializingZone && !zone

  return (
    <ZoneContext.Provider value={contextValue}>
      {zone && !zone.isActive ? (
        <ZoneNotActive /> // Use the new component here
      ) : (
        <ZonePage
          initializingZone={initializingZone}
          isOnline={isOnline}
          zone={zone}
          zoneInitialized={zoneInitialized}
          zoneIsInvalid={zoneIsInvalid}
        />
      )}
    </ZoneContext.Provider>
  )
}
