import { Dispatch } from 'redux'
import { deprecated } from 'typesafe-actions'

import { ApplicationState } from '../../store'
import { User } from '../../users'
import { authService } from '../auth.service'
import { AuthActionTypes } from '../auth.types' // Assuming you have an AuthActionTypes enum

const { createStandardAction } = deprecated

// Action creators
export const fetchImpersonationTokenRequest = createStandardAction(
  AuthActionTypes.FETCH_IMPERSONATION_TOKEN_REQUEST,
)<void>()

export const fetchImpersonationTokenSuccess = createStandardAction(
  AuthActionTypes.FETCH_IMPERSONATION_TOKEN_SUCCESS,
)<string>()

export const fetchImpersonationTokenFailure = createStandardAction(
  AuthActionTypes.FETCH_IMPERSONATION_TOKEN_FAILURE,
)<string>()

// Thunk action to fetch the impersonation token
export const fetchImpersonationToken = (user: User) => {
  return async (dispatch: Dispatch, getState: () => ApplicationState) => {
    dispatch(fetchImpersonationTokenRequest())

    try {
      const authToken = getState().auth.token
      const response = await authService.fetchImpersonationToken(
        user.id,
        authToken ?? '',
      )

      dispatch(fetchImpersonationTokenSuccess(response ?? ''))
    } catch (error: unknown) {
      dispatch(fetchImpersonationTokenFailure((error as Error).message))
    }
  }
}
