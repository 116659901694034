import * as React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { UnitPreferencesReduxProps } from '../preferences/preferences.types'
import UnitPreferencesPage from '../preferences/UnitPreferencesPage/UnitPreferencesPage'

export type HousekeepersIndexProps = UnitPreferencesReduxProps

const HousekeepersIndex: React.FC<HousekeepersIndexProps> = props => {
  return (
    <Routes>
      {/* ROUTES WITH "/zone/:zoneId/housekeepers" PREFIX */}
      <Route path="/preferences" element={<UnitPreferencesPage {...props} />} />
      <Route path="" element={<Navigate to="preferences" replace={true} />} />
    </Routes>
  )
}

export default HousekeepersIndex
