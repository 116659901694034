import capitalize from 'lodash/capitalize'
import get from 'lodash/get' // eslint-disable-line
import * as React from 'react'

import { DataTableAction } from 'packages/common/src/DataTable/DataTable'
import { TranslatorFn } from 'packages/i18n'

import { Slugs } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import styles from '../../AddHkModal.module.scss'
import {
  hkIsBorrowedInZone,
  hkHasPrimaryZone,
  zoneIsHkPrimaryZone,
} from '../AddHkModal.helpers'

// builder helper for setting up actions for the table; should preferably be used with 'useMemo'
export const buildTableRowActions = (
  onBorrowHksToZone: (hk: Housekeeper) => void,
  onAddHksToZone: (hk: Housekeeper) => void,
  t: TranslatorFn,
  zone: Zone,
): DataTableAction[] => [
  {
    callback: onBorrowHksToZone,
    condition: hk =>
      !zoneIsHkPrimaryZone(hk, zone) && !hkIsBorrowedInZone(hk, zone),
    title: capitalize(t(Slugs.borrow)),
  },
  {
    callback: onAddHksToZone,
    condition: hk => !hkHasPrimaryZone(hk) && !hkIsBorrowedInZone(hk, zone),
    title: capitalize(t(Slugs.add)),
  },
]

const managerString = (hk, t): string | null => {
  const first = get(hk, 'manager.firstName')
  const last = get(hk, 'manager.lastName')

  return first || last ? `${t(Slugs.managedBy)} ${first} ${last}` : null
}

// helper function to determine if a HK row in the search results should be disabled
// if hk is currently associated with this zone in any way, they should be disabled
export const shouldDisableHkRow = (hk: Housekeeper, zone: Zone): boolean =>
  get(hk, 'zone.id') === zone.id || (hk.borrowedZones || []).includes(zone.id)

/** Helper function to render the first column in the HK search results table */
export const renderHkColumn = (
  hk: Housekeeper,
  t: TranslatorFn,
): React.ReactNode => {
  const managedBy = managerString(hk, t)
  return (
    <>
      <div>{`${hk.user.firstName} ${hk.user.lastName}`}</div>
      {!!managedBy && <div className={styles.subText}>{managedBy}</div>}
    </>
  )
}

/** Helper function to render the second column in the HK search results table */
export const renderZoneColumn = (
  hk: Housekeeper,
  zone: Zone,
  t: TranslatorFn,
): React.ReactNode => {
  const zoneName = get(hk, 'zone.name', '')
  return (
    <>
      {zoneName && <div>{zoneName}</div>}
      {hkIsBorrowedInZone(hk, zone) && (
        <div className={styles.subText}>{t(Slugs.alreadyBorrowed)}</div>
      )}
      {zoneIsHkPrimaryZone(hk, zone) && (
        <div className={styles.subText}>{t(Slugs.alreadyInZone)}</div>
      )}
    </>
  )
}
