import styled from '@emotion/styled'
import React from 'react'

import { Loader, LoaderAlignment } from 'packages/common'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import { TicketCommentDisplayContainer } from '../TicketCommentDisplay'
import { useTicketDrawerContext } from '../TicketDrawer.context'
import { TicketSection, TicketSectionTitle } from '../TicketDrawer.styles'

const St = {
  CommentHeader: styled.div`
    width: 50%;
  `,
  LoaderWrapper: styled.div`
    display: inline-flex;
    justify-content: flex-start;
    position: relative;
    width: 100%;
  `,
  NoComments: styled.div`
    ${text.bodyRegularSmall}
    font-style: italic;
  `,
  SectionTitle: styled(TicketSectionTitle)`
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
  `,
  TicketSection,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    comments: ut(Slugs.comments),
    noComments: ut(Slugs.noComments),
  }
}

export type TicketCommentsProps = {
  commentIds: string[]
}

export const TicketComments: React.FC<TicketCommentsProps> = React.memo(
  ({ commentIds }) => {
    const strings = useTranslations()
    const { fetchTicketState } = useTicketDrawerContext()

    return (
      <>
        <St.SectionTitle>
          <St.CommentHeader>
            {strings.comments} ({commentIds.length})
          </St.CommentHeader>

          {fetchTicketState.loading && (
            <St.LoaderWrapper>
              <Loader
                alignment={LoaderAlignment.left}
                color={colors.dusk}
                size={6}
              />
            </St.LoaderWrapper>
          )}
        </St.SectionTitle>

        {commentIds.length ? (
          commentIds.map(id => (
            <TicketCommentDisplayContainer commentId={id} key={id} />
          ))
        ) : (
          <St.NoComments>{strings.noComments}</St.NoComments>
        )}
      </>
    )
  },
)
