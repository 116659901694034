import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { hydrateRawTicketComment, TicketComment } from '../../ticketComments'

export const getTicketCommentById = createSelector(
  (state: ApplicationState) => state.ticketComments,
  (_, ticketCommentId: string) => ticketCommentId,
  (ticketCommentsState, ticketCommentId): TicketComment | undefined => {
    const rawTicketComment = ticketCommentsState.data[ticketCommentId]
    return rawTicketComment
      ? hydrateRawTicketComment(rawTicketComment)
      : undefined
  },
)
