import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useManageDrawerState } from 'packages/common'
import { ReduxDispatch } from 'packages/grimoire/src/utils'
import { getErrorDetail } from 'packages/grimoire/src/utils/jsonapi.utils'
import { track, Events } from 'packages/wiretap'

import { ApplicationState } from 'app/hkhub/store/store'
import { searchZones, updateZone } from 'app/hkhub/store/zones/actions'
import { getZoneSearchResults } from 'app/hkhub/store/zones/selectors'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import { useAdminContext } from '../../contexts/AdminContext'
import { AdminZoneDisplayDrawer } from './AdminZoneDisplayDrawer'
import { AdminZoneSearch } from './AdminZoneSearch'

const DEFAULT_SEARCH_ERROR = 'An unknown error occurred when searching.'
const DEFAULT_DELETE_ERROR = 'An unknown error occurred when deleting a zone.'

export const AdminZonePage: React.FC = React.memo(() => {
  const dispatch: ReduxDispatch<ApplicationState> = useDispatch()

  const { addAlert } = useAdminContext()

  const drawerStateProps = useManageDrawerState()

  const { completeDrawerClose, drawerState, openDrawer, closeDrawer } =
    drawerStateProps

  const [selectedZone, setSelectedZone] = React.useState<null | Zone>(null)

  const zones = useSelector((state: ApplicationState) =>
    getZoneSearchResults(state.zones),
  )

  const handleSearchZones = React.useCallback(
    async (searchString: string) => {
      try {
        await dispatch(searchZones(searchString))
      } catch (err) {
        const message = getErrorDetail(err) || DEFAULT_SEARCH_ERROR
        addAlert({
          alertType: 'danger',
          children: <div>{message}</div>,
        })

        throw err
      }
    },
    [addAlert, dispatch],
  )

  const handleSelectZone = React.useCallback(
    (zone: Zone) => {
      setSelectedZone(zone)
      openDrawer()
    },
    [openDrawer],
  )

  const handleCloseDrawer = React.useCallback(() => {
    setSelectedZone(null)
    completeDrawerClose()
  }, [completeDrawerClose])

  const handleDeleteZone = React.useCallback(async () => {
    if (selectedZone) {
      try {
        track(Events.hubAdminDeleteZone, { zoneId: selectedZone?.id })
        await dispatch(
          updateZone({
            attributes: { isActive: false },
            id: selectedZone.id,
          }),
        )

        addAlert({
          alertType: 'success',
          children: <div>Deleted {selectedZone.name}</div>,
        })
      } catch (err) {
        const message = getErrorDetail(err) || DEFAULT_DELETE_ERROR
        addAlert({
          alertType: 'danger',
          children: <div>{message}</div>,
        })

        throw err
      } finally {
        closeDrawer()
      }
    }
  }, [addAlert, closeDrawer, dispatch, selectedZone])

  return (
    <>
      <AdminZoneSearch
        onSelectZone={handleSelectZone}
        searchZones={handleSearchZones}
        zones={zones}
      />
      <AdminZoneDisplayDrawer
        afterDrawerExit={handleCloseDrawer}
        forceClose={drawerState.forceClose}
        isOpen={drawerState.isOpen}
        onDeleteZone={handleDeleteZone}
        zone={selectedZone}
      />
    </>
  )
})
