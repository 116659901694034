import { createSelector } from 'reselect'

import { UnitsState, Unit } from '../units.types'
import { getUnitById } from './getUnitById'

const sortByUnitCode = (a?: Unit, b?: Unit) => {
  if (!a || !b) {
    return 0
  }

  return a.unitCode.toUpperCase() > b.unitCode.toUpperCase() ? 1 : -1
}

const getUnitsState = (state: UnitsState): UnitsState => state

/**
 * Returns all units currently available in the local store,
 * ordered by their `unitCode` field
 */
export const getUnits = createSelector(
  getUnitsState,
  unitsState =>
    Object.keys(unitsState.data)
      .map(id => getUnitById(unitsState, id))
      .filter(unit => !!unit)
      .filter(unit => !!unit?.isActive)
      .sort(sortByUnitCode) as Unit[],
)
