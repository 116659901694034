import { deprecated } from 'typesafe-actions'

import { AuthActionTypes } from '../auth.types'

const { createStandardAction } = deprecated

export const setAuthErrorAction = createStandardAction(
  AuthActionTypes.SET_AUTH_ERROR,
)<Error | undefined>()

export const setAuthError = (error: Error | undefined) => dispatch =>
  dispatch(setAuthErrorAction(error))
