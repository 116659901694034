import React from 'react'
import { useSelector } from 'react-redux'

import { AuthCodeWithPkce, AuthCodeWithPkceProps } from 'packages/auth'

import {
  setNeedsFullAuthRedirect,
  setTokens,
} from 'app/hkhub/store/auth/actions'

import {
  getAuthToken,
  getNeedsFullAuthRedirect,
  getNeedsSilentRefresh,
  getRefreshToken,
} from './store/auth/selectors'
import { getImpersonationToken } from './store/auth/selectors/getImpersonationToken'

type PickedProps = Pick<
  AuthCodeWithPkceProps,
  'authClientId' | 'authUrl' | 'onAuthInitialized' | 'tokenUrl'
>
export type HkHubAuthContainerProps = PickedProps

export const HkHubAuthContainer: React.FC<
  HkHubAuthContainerProps
> = passThruProps => {
  const authToken = useSelector(getAuthToken)
  const refreshToken = useSelector(getRefreshToken)
  const impersonationToken = useSelector(getImpersonationToken)
  const needsFullAuth = useSelector(getNeedsFullAuthRedirect)
  const needsSilentRefresh = useSelector(getNeedsSilentRefresh)

  return (
    <AuthCodeWithPkce
      {...passThruProps}
      authToken={authToken}
      needsFullAuth={needsFullAuth}
      needsSilentRefresh={needsSilentRefresh}
      refreshToken={refreshToken}
      isImpersonated={impersonationToken !== undefined}
      setNeedsFullAuthRedirect={setNeedsFullAuthRedirect}
      setTokens={setTokens}
    />
  )
}
