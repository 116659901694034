import * as React from 'react'

import { ModalConfig, useModalContext } from '../../contexts'
import { ModalChildrenProps } from '../../ModalPortal'
import { ConfirmModal, ConfirmModalProps } from './ConfirmModal'

export type ConfirmModalHookProps = Omit<ConfirmModalProps, 'beginClose'> &
  Pick<ModalConfig, 'modalProps'>

export type UseConfirmModalValue = {
  showModal: () => void
}

export const useConfirmModal = ({
  extraChildren,
  modalProps,
  onConfirm,
  slugs,
}: ConfirmModalHookProps): UseConfirmModalValue => {
  const { showModal } = useModalContext()

  const showCompleteModal = React.useCallback(
    () =>
      showModal({
        childRenderer: (props: ModalChildrenProps) => (
          <ConfirmModal
            {...props}
            extraChildren={extraChildren}
            onConfirm={onConfirm}
            slugs={slugs}
          />
        ),
        modalProps,
      }),
    [extraChildren, modalProps, onConfirm, showModal, slugs],
  )

  return {
    showModal: showCompleteModal,
  }
}
