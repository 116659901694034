import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  TaskPhoto,
  TaskPhotoAttributeNames,
  NormalizedTaskPhotosApiResponse,
  RawTaskPhoto,
} from './taskPhotos.types'

/**
 * Empty data in the shape of the TaskPhotos service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedTaskPhotosData = Object.freeze({
  taskPhoto: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyTaskPhotosResponse: NormalizedTaskPhotosApiResponse =
  Object.freeze({
    normalized: emptyNormalizedTaskPhotosData,
    raw: { data: [] },
  })

export const hydrateRawTaskPhoto = (taskPhoto: RawTaskPhoto): TaskPhoto => {
  return transformNormalizedToTyped<TaskPhoto>(
    taskPhoto,
    TaskPhotoAttributeNames,
  )
}
