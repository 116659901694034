import styled from '@emotion/styled'
import React from 'react'

import { Alert, Button, Loader } from 'packages/common'
import { colors } from 'packages/styles'
import { AsyncState } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/hkhub/i18n'

const St = {
  Buttons: styled.div`
    display: flex;
  `,
  Container: styled.div`
    margin-top: 16px;
    position: relative;
  `,
  Footnote: styled.div`
    font-size: 14px;
    margin: 4px 0 8px 0;
  `,
  FormTitle: styled.div`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  `,
  TextArea: styled.textarea`
    border-radius: 4px;
    border: 1px solid ${colors.midnight30};
    padding: 8px 16px;
    width: 100%;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    cancel: ut(Slugs.cancel),
    error: ut(Slugs.oopsChangesNotSaved),
    footnote: ut(Slugs.deepCleanSkipFootnote),
    reasonForSkip: ut(Slugs.reasonForSkip),
    save: ut(Slugs.save),
    skip: ut(Slugs.skip),
  }
}

export enum DeepCleanSkipFormTestIds {
  cancelBtn = 'DeepCleanSkipForm__cancelBtn',
  form = 'DeepCleanSkipForm__form',
  saveBtn = 'DeepCleanSkipForm__saveBtn',
  skipBtn = 'DeepCleanSkipForm__skipBtn',
  textArea = 'DeepCleanSkipForm__textArea',
}

export type DeepCleanSkipFormProps = {
  formState: {
    canSubmit: boolean
    editing: boolean
    values: {
      reason: string
    }
  }
  handlers: {
    cancel: () => void
    change: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    edit: () => void
    submit: (event: React.FormEvent<HTMLFormElement>) => Promise<unknown>
  }
  requestState: AsyncState<unknown>
}

export const DeepCleanSkipForm: React.FC<DeepCleanSkipFormProps> = React.memo(
  ({ formState, handlers, requestState }) => {
    const strings = useTranslations()

    if (!formState.editing) {
      return (
        <St.Container>
          <Button
            block={true}
            dataTestId={DeepCleanSkipFormTestIds.skipBtn}
            onClick={handlers.edit}
            buttonType={'utility'}
          >
            {strings.skip}
          </Button>
        </St.Container>
      )
    }

    return (
      <St.Container>
        <form
          data-testid={DeepCleanSkipFormTestIds.form}
          onSubmit={handlers.submit}
        >
          <St.FormTitle>{strings.reasonForSkip}</St.FormTitle>
          <St.TextArea
            data-testid={DeepCleanSkipFormTestIds.textArea}
            onChange={handlers.change}
            maxLength={250}
            rows={4}
            value={formState.values.reason}
          />
          <St.Footnote>{strings.footnote}</St.Footnote>

          {requestState.error && (
            <Alert alertType={'danger'}>{strings.error}</Alert>
          )}

          <St.Buttons>
            <Button
              block={true}
              dataTestId={DeepCleanSkipFormTestIds.cancelBtn}
              onClick={handlers.cancel}
              buttonType={'utility'}
            >
              {strings.cancel}
            </Button>
            <Button
              block={true}
              dataTestId={DeepCleanSkipFormTestIds.saveBtn}
              disabled={!formState.canSubmit}
              isFormSubmit={true}
              buttonType={'primary'}
            >
              {strings.save}
            </Button>
          </St.Buttons>
        </form>

        {requestState.loading && <Loader />}
      </St.Container>
    )
  },
)
