import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { unitsService } from '../units.service'
import { UnitApiFields } from '../units.types'

export const getParams = (): RequestOptions => ({
  fields: {
    unit: UnitApiFields,
    zone: ['name'],
  },
  include: ['zone'],
  page: { size: 1500 },
})

export const updateUnitInSearchResults = createAsyncThunk(
  'units/api/updateUnitInSearchResults',
  async (unitId: string) => {
    const params = getParams()
    return await unitsService.fetchUnitById(unitId, params)
  },
)
