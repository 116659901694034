import React from 'react'
import { useSelector } from 'react-redux'

import { useI18n } from 'packages/i18n'

import { Slugs } from 'app/hkhub/i18n'
import { getRejectedAssignmentsByCleanId } from 'app/hkhub/store/assignments/selectors'
import { Clean } from 'app/hkhub/store/cleans'
import { getSortedHks } from 'app/hkhub/store/housekeepers/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { useCleanUpdate } from 'app/hkhub/utils/useCleanUpdate/useCleanUpdate'

import { AssignmentEditor, AssignmentEditorHandlers } from './AssignmentEditor'
import {
  assignmentEditorReducer,
  HkSelectConfig,
  initialStateReducer,
} from './AssignmentEditor.reducer'
import { sanitizedHousekeepers } from './AssignmentEditor.utils'

const useTranslations = () => {
  const { t, ut } = useI18n()

  return {
    addAssignee: t(Slugs.addAssignee),
    assignment: t(Slugs.assignment),
    assignmentChangeError: t(Slugs.assignmentChangeError),
    cancel: t(Slugs.cancel),
    clean: t(Slugs.clean),
    cleanStartedAlert: t(Slugs.cannotRemoveHkFromActiveClean),
    dayOff: t(Slugs.dayOff),
    invalidTeamClean: t(Slugs.invalidTeamClean),
    other: t(Slugs.other),
    rejected: ut(Slugs.rejected),
    save: t(Slugs.save),
    selectHousekeeper: t(Slugs.selectHousekeeper),
    tooBusy: t(Slugs.tooBusy),
  }
}

export type AssignmentEditorTranslations = ReturnType<typeof useTranslations>

type AssignmentEditorContainerProps = {
  clean: Clean
  onCancelEditClick: () => void
}

export const AssignmentEditorContainer: React.FC<AssignmentEditorContainerProps> =
  ({ clean, onCancelEditClick }) => {
    const strings = useTranslations()

    const housekeepers = useSelector((state: ApplicationState) =>
      getSortedHks(state.housekeepers),
    )

    const rejectedAssignments = useSelector((state: ApplicationState) =>
      getRejectedAssignmentsByCleanId(state, clean.id),
    )

    const [state, dispatch] = React.useReducer(
      assignmentEditorReducer,
      initialStateReducer({ clean, housekeepers, rejectedAssignments }),
    )

    const { resetUpdateCleanState, updateClean, updateCleanState } =
      useCleanUpdate(clean, onCancelEditClick)

    const handleSaveClick = React.useCallback(
      () =>
        updateClean({ hks: sanitizedHousekeepers(state.selectedHousekeepers) }),
      [updateClean, state.selectedHousekeepers],
    )

    const handleChangeHk = React.useCallback(
      (index: number) => (selectedHKConfig: HkSelectConfig) => {
        // Convert the selectedHKConfig to a housekeeper object
        const selectedHK =
          housekeepers.find(({ id }) => id === selectedHKConfig.hkId) || null
        if (!selectedHK) return
        dispatch({
          payload: { index, selectedHK },
          type: 'changeHK',
        })
      },
      [housekeepers],
    )

    const handleRemoveHk = React.useCallback(
      (index: number) => dispatch({ payload: { index }, type: 'removeHK' }),
      [],
    )

    const handleAddHkSelect = React.useCallback(
      () => dispatch({ type: 'addHkSelect' }),
      [],
    )

    const handlers: AssignmentEditorHandlers = React.useMemo(
      () => ({
        addHkSelect: handleAddHkSelect,
        cancelEdit: onCancelEditClick,
        changeHk: handleChangeHk,
        clearError: resetUpdateCleanState,
        removeHk: handleRemoveHk,
        save: handleSaveClick,
      }),
      [
        handleAddHkSelect,
        handleChangeHk,
        handleRemoveHk,
        handleSaveClick,
        onCancelEditClick,
        resetUpdateCleanState,
      ],
    )

    return (
      <AssignmentEditor
        clean={clean}
        handlers={handlers}
        requestState={updateCleanState}
        state={state}
        strings={strings}
      />
    )
  }
