import styled from '@emotion/styled'
import React from 'react'

import { layers } from 'packages/styles'
import { isInOpenPhase } from 'packages/utils/hooks'

import { ModalPortalProps } from '../Modal/ModalPortal'

type StyledProps = Pick<ModalPortalProps, 'duration' | 'transitionPhase'> &
  Pick<ModalBackdropProps, 'zIndex'>

const ModalBackdropContainer = styled.div<StyledProps>`
  background-color: rgba(7, 7, 7, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  will-change: opacity;

  opacity: ${({ transitionPhase }) =>
    isInOpenPhase(transitionPhase) ? '1' : '0'};

  transition: ${({ duration }) => `opacity ${duration}ms ease`};
  z-index: ${({ zIndex }) => `${zIndex}`};
`

export enum ModalBackdropTestIds {
  container = 'ModalBackdrop__container',
}

export type ModalBackdropProps = Pick<
  ModalPortalProps,
  'duration' | 'transitionPhase' | 'onBackdropClick'
> & {
  dataTestId?: string
  id?: string
  zIndex?: number
}

export const ModalBackdrop: React.FC<ModalBackdropProps> = ({
  dataTestId,
  duration,
  id,
  onBackdropClick,
  transitionPhase,
  zIndex = layers.modalBackdrop,
}) => {
  return (
    <ModalBackdropContainer
      data-testid={dataTestId || ModalBackdropTestIds.container}
      duration={duration}
      id={id || dataTestId || ModalBackdropTestIds.container}
      onClick={onBackdropClick}
      transitionPhase={transitionPhase}
      zIndex={zIndex}
    />
  )
}
