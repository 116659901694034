import styled from '@emotion/styled'
import React from 'react'

import { Button, Card, Loader } from 'packages/common'
import { fullName } from 'packages/grimoire'
import { truncateTextWithEllipsis } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'

const St = {
  Address: styled.div`
    ${truncateTextWithEllipsis};
  `,

  Card: styled(Card)`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 12px;
    position: relative;
    row-gap: 12px;

    &:not(:first-of-type) {
      margin-top: 12px;
    }
  `,

  HkData: styled.div`
    width: max(80%, 320px);
    overflow: hidden;
  `,

  Name: styled.div`
    ${truncateTextWithEllipsis};
    font-weight: bold;
  `,
}

export type HkCardProps = {
  hk: Housekeeper
  isLoading: boolean
  removeHk: (hk: Housekeeper) => void
}

export const HkCard: React.FC<HkCardProps> = React.memo(
  ({ hk, isLoading, removeHk }) => {
    const { ut } = useI18n()

    return (
      <St.Card>
        <St.HkData>
          <St.Name>{fullName(hk.user)}</St.Name>
        </St.HkData>

        <Button onClick={() => removeHk(hk)} buttonType={'utility'}>
          {ut(Slugs.remove)}
        </Button>

        {isLoading && <Loader />}
      </St.Card>
    )
  },
)
