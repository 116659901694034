import { configureStore } from '@reduxjs/toolkit'
import { StateType } from 'typesafe-actions'

import { getAuthMiddleware } from 'packages/auth'
import { sentryReduxEnhancer } from 'packages/common'
import { getWireTapMiddle } from 'packages/wiretap'

import { hubTrackers } from '../tracking/trackers'
import { setNeedsFullAuthRedirect, setNeedsSilentRefresh } from './auth/actions'
import { getAuthToken, getNeedsSilentRefresh } from './auth/selectors'
import { setRequestHeader } from './axiosInstance'
import { rootReducer } from './reducers'

const authMiddleware = getAuthMiddleware({
  getAuthToken,
  getNeedsSilentRefresh,
  setNeedsFullAuthRedirect,
  setNeedsSilentRefresh,
  setRequestHeader,
})

const wiretapMiddleware = getWireTapMiddle({
  trackers: hubTrackers,
})

export type ApplicationState = StateType<typeof rootReducer>

/* eslint-disable sort-keys */
export const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.request', 'meta.arg.callbacks'],
      },
    })
      .concat(authMiddleware)
      .concat(wiretapMiddleware),
  enhancers: getDefaultEnhancers =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
  reducer: rootReducer,
})

export default store
/* eslint-enable sort-keys */

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
