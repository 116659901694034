import * as React from 'react'

import { isNotReadyStatus } from 'packages/grimoire'

import { Clean } from 'app/hkhub/store/cleans/cleans.types'

import { CleanStatusDisplay } from '../CleanStatusDisplay'
import { CleanMarkCompleteSection } from './CleanMarkCompleteSection'
import {
  CleanStatusAndTimeContainer,
  CleanStatusContainer,
  StatusSection,
} from './CleanStatusSection.styles'
import { CleanTimeSection } from './CleanTimeSection'

export enum CleanStatusSectionTestIds {
  statusSection = 'CleanStatusSection__statusSection',
}

export type CleanStatusSectionProps = {
  clean: Clean
  cleanIsFrozen: boolean
}

export const CleanStatusSection: React.FC<CleanStatusSectionProps> = ({
  clean,
  cleanIsFrozen,
}) => {
  const hasContent = !isNotReadyStatus(clean.realtimeStatus) || !clean.startedAt

  return (
    <CleanStatusContainer hasContent={hasContent}>
      <CleanStatusAndTimeContainer>
        <StatusSection data-testid={CleanStatusSectionTestIds.statusSection}>
          <CleanStatusDisplay clean={clean} />
        </StatusSection>

        <CleanTimeSection clean={clean} />
      </CleanStatusAndTimeContainer>

      <CleanMarkCompleteSection clean={clean} cleanIsFrozen={cleanIsFrozen} />
    </CleanStatusContainer>
  )
}
