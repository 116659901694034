import * as React from 'react'

import { ExternalLink } from 'packages/common'
import { TooltipPosition } from 'packages/common/src/Tooltip'
import { UnitDetailBadges } from 'packages/common/src/UnitDetailBadges/UnitDetailBadges'
import { getVacasaViewUnitURL } from 'packages/utils/links'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans/cleans.types'

import { resGridUrl } from './CleanUnitInfo.helpers'
import { St } from './CleanUnitInfo.styles'
import { UnitDetailIcons } from './UnitDetailIcons'

export type CleanUnitInfoProps = {
  className?: string
  clean: Clean
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    resgrid: ut(Slugs.resgrid),
    viewListing: ut(Slugs.viewListing),
  }
}

export const CleanUnitInfo: React.FC<CleanUnitInfoProps> = ({
  className,
  clean,
}) => {
  const { unit } = clean
  const strings = useTranslations()
  return (
    <St.CleanUnitInfoContainer className={className}>
      <St.UnitCode>{unit.unitCode}</St.UnitCode>
      <St.UnitName>{unit.name}</St.UnitName>

      <St.UnitLinks>
        <ExternalLink text={strings.resgrid} url={resGridUrl(unit.id)} />

        <ExternalLink
          text={strings.viewListing}
          url={getVacasaViewUnitURL(unit.id)}
        />
      </St.UnitLinks>

      <St.UnitAddress>{unit.address}</St.UnitAddress>

      <St.IndicatorsRow>
        <UnitDetailIcons clean={clean} />
        <UnitDetailBadges
          // TODO: remove this as any when typing are imported from grimoire
          clean={clean as any} //eslint-disable-line
          tooltipPosition={TooltipPosition.Left}
        />
      </St.IndicatorsRow>
    </St.CleanUnitInfoContainer>
  )
}
