import styled from '@emotion/styled'
import React from 'react'

import { ExternalLink } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'
import { getAdminEditUnitHkNotesUrl } from 'packages/utils/links'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Unit } from 'app/hkhub/store/units'

import { UnitDrawerStyles } from '../../UnitDrawer.styles'

const St = {
  Content: UnitDrawerStyles.SectionContent,
  EditLink: styled(ExternalLink)`
    margin-top: 8px;
    position: relative;
    float: right;
  `,
  HkNotes: UnitDrawerStyles.SubSection,
  Icon: styled(SvgIcon)`
    color: ${colors.midnight80};
  `,
  Title: styled.strong`
    color: ${colors.midnight80};
  `,
  TitleSection: styled(UnitDrawerStyles.SectionSubTitle)`
    margin-bottom: 16px;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    edit: ut(Slugs.editInstructions),
    title: ut(Slugs.hkInstructions),
  }
}

export type HkNotesProps = {
  unit: Unit
}

export const HkNotes: React.FC<HkNotesProps> = React.memo(({ unit }) => {
  const { housekeeperNotes = '' } = unit
  const strings = useTranslations()

  return housekeeperNotes ? (
    <St.HkNotes>
      <St.TitleSection>
        <St.Icon icon={IconName.info} size={16} />
        <St.Title>{strings.title}</St.Title>
      </St.TitleSection>

      <St.Content>
        <div>{housekeeperNotes}</div>
        <St.EditLink
          text={strings.edit}
          url={getAdminEditUnitHkNotesUrl(unit.id)}
        />
      </St.Content>
    </St.HkNotes>
  ) : null
})
