import { combineReducers } from 'redux'

import { assignmentsReducer } from './assignments/assignments.reducer'
import { authReducer } from './auth/auth.reducer'
import { cleansReducer } from './cleans/cleans.reducer'
import coverageReducer from './coverage/coverage.slice'
import customInspectionItemsReducer from './customInspectionItems/customInspectionItems.slice'
import { hkAvailabilitiesReducer } from './hkAvailabilities/hkAvailabilities.reducer'
import { housekeepersReducer } from './housekeepers/housekeepers.reducer'
import { lockboxesReducer } from './lockboxes/lockboxes.reducer'
import { zoneNotificationsReducer } from './notifications/notifications.reducer'
import { reservationsReducer } from './reservations/reservations.reducer'
import { taskPhotosReducer } from './taskPhotos/taskPhotos.reducer'
import { ticketCommentsReducer } from './ticketComments/ticketComments.reducer'
import { ticketingReducer } from './ticketing/ticketing.reducer'
import { ticketsReducer } from './tickets/tickets.reducer'
import { uiReducer } from './ui/ui.reducer'
import unitsReducer from './units/units.slice'
import { usersReducer } from './users/users.reducer'
import { visitsReducer } from './visits/visits.reducer'
import { zonesReducer } from './zones/zones.reducer'

export const reducers = {
  assignments: assignmentsReducer,
  auth: authReducer,
  cleans: cleansReducer,
  coverage: coverageReducer,
  customInspectionItems: customInspectionItemsReducer,
  hkAvailabilities: hkAvailabilitiesReducer,
  housekeepers: housekeepersReducer,
  lockboxes: lockboxesReducer,
  notifications: zoneNotificationsReducer,
  reservations: reservationsReducer,
  taskPhotos: taskPhotosReducer,
  ticketComments: ticketCommentsReducer,
  ticketing: ticketingReducer,
  tickets: ticketsReducer,
  ui: uiReducer,
  units: unitsReducer,
  users: usersReducer,
  visits: visitsReducer,
  zones: zonesReducer,
}

export const rootReducer = combineReducers(reducers)
