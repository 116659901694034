import { isEmpty, join, pipe, reject, values } from 'lodash/fp'

import { DateFormat, format } from 'packages/utils/dateHelpers'

import {
  ScheduleEntityType,
  ScheduleNavigationSearchParams,
} from 'app/hkhub/components/schedule/schedule.types'

export const buildScheduleUrl = (
  zoneId: string,
  entity: ScheduleEntityType = 'staff',
): string => `/zone/${zoneId}/schedule/${entity}`

/**
 * Builds the "search" query portion of a URL for schedule view based on the provided
 * params (all optional). Any omitted params will not appear in the final search string.
 *
 * If no params are provided, an empty string will be returned.
 *
 * @param params
 */
export const buildSearchParams = (
  params: Partial<ScheduleNavigationSearchParams> = {},
): string => {
  const date = params.date
    ? `date=${format(params.date, DateFormat.ApiUtcShort)}`
    : ''

  const days = params.dayCount ? `days=${params.dayCount}` : ''

  const searchString = pipe(
    values,
    reject(isEmpty),
    join('&'),
  )({
    date,
    days,
  })

  return searchString ? `?${searchString}` : ''
}
