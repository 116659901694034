import { createSelector } from 'reselect'

import { getReservationFromClean } from '../../reservations/selectors'
import { ApplicationState } from '../../store'
import { Clean } from '../cleans.types'
import { hydrateRawClean } from '../cleans.utils'

export const getCleanById = createSelector(
  (state: ApplicationState) => state.cleans,
  (state: ApplicationState) => state.units,
  (state: ApplicationState) => state.housekeepers,
  (state: ApplicationState) => state.taskPhotos,
  (state: ApplicationState) => state.reservations,
  (_, id: string) => id,
  (
    cleansState,
    unitsState,
    hksState,
    taskPhotosState,
    resState,
    cleanId,
  ): Clean | undefined => {
    if (!cleanId) return undefined

    const rawClean = cleansState?.data?.[cleanId]
    const clean = rawClean
      ? hydrateRawClean(
          cleansState,
          unitsState,
          hksState,
          taskPhotosState,
          rawClean,
        )
      : undefined

    if (!clean?.unit?.id) return undefined

    const nextReservation = getReservationFromClean(resState, clean)
    if (nextReservation) clean.nextReservation = nextReservation

    return clean
  },
)
