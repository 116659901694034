import styled from '@emotion/styled'
import React from 'react'

import { Button, Card, Loader } from 'packages/common'
import { truncateTextWithEllipsis } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Unit } from 'app/hkhub/store/units/units.types'

const St = {
  Address: styled.div`
    ${truncateTextWithEllipsis};
  `,

  Card: styled(Card)`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 12px;
    position: relative;
    row-gap: 12px;

    &:not(:first-of-type) {
      margin-top: 12px;
    }
  `,

  UnitData: styled.div`
    width: max(80%, 320px);
    overflow: hidden;
  `,
}

export type UnitCardProps = {
  isLoading: boolean
  removeUnit: (unit: Unit) => void
  unit: Unit
}

export const UnitCard: React.FC<UnitCardProps> = React.memo(
  ({ isLoading, removeUnit, unit }) => {
    const { ut } = useI18n()

    const { address, unitCode } = unit
    const cityStateText = `${unit.city}, ${unit.state}`

    return (
      <St.Card>
        <St.UnitData>
          <strong>{unitCode}</strong>
          <St.Address>{address}</St.Address>
          <div>{cityStateText}</div>
        </St.UnitData>

        <Button onClick={() => removeUnit(unit)} buttonType={'utility'}>
          {ut(Slugs.remove)}
        </Button>

        {isLoading && <Loader />}
      </St.Card>
    )
  },
)
