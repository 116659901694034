import styled from '@emotion/styled'
import React from 'react'

import { DropdownButton } from 'packages/common'

import { useScheduleNavigation } from 'app/hkhub/components/schedule/hooks'
import { Slugs, useI18n } from 'app/hkhub/i18n'

const useTranslations = ({ dayCount }) => {
  const { t } = useI18n()

  return React.useMemo(
    () => ({
      dayView: {
        1: t(Slugs.dayView, { dayCount: 1 }),
        3: t(Slugs.dayView, { dayCount: 3 }),
        7: t(Slugs.dayView, { dayCount: 7 }),
      },
      dayViewCurrent: t(Slugs.dayView, { dayCount }),
    }),
    [dayCount, t],
  )
}

//----------------------------------------------------
// Mini component for rendering individual menu items
//----------------------------------------------------

type MenuItemProps = {
  active: boolean
  title: string
}

const StyledMenuItem = styled.span<{ active: boolean }>`
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`

const MenuItem: React.FC<MenuItemProps> = React.memo(({ active, title }) => (
  <StyledMenuItem active={active}>{title}</StyledMenuItem>
))

//----------------------------------------------------
// Primary menu rendering component
//----------------------------------------------------

export type ScheduleViewRangeMenuProps = {
  dayCount: number
}

export const ScheduleViewRangeMenu: React.FC<ScheduleViewRangeMenuProps> = ({
  dayCount,
}) => {
  const strings = useTranslations({ dayCount })
  const { navigate } = useScheduleNavigation()

  const buttonText = strings.dayViewCurrent

  const menuItems = React.useMemo(() => {
    return [
      {
        children: (
          <MenuItem active={dayCount === 7} title={strings.dayView[7]} />
        ),
        key: strings.dayView[7],
        onClick: () => navigate({ dayCount: 7 }),
      },
      {
        children: (
          <MenuItem active={dayCount === 3} title={strings.dayView[3]} />
        ),
        key: strings.dayView[3],
        onClick: () => navigate({ dayCount: 3 }),
      },
      {
        children: (
          <MenuItem active={dayCount === 1} title={strings.dayView[1]} />
        ),
        key: strings.dayView[1],
        onClick: () => navigate({ dayCount: 1 }),
      },
    ]
  }, [dayCount, navigate, strings.dayView])

  const dropdownMenuProps = React.useMemo(() => {
    return {
      items: menuItems,
    }
  }, [menuItems])

  return (
    <DropdownButton
      dropdownMenuProps={dropdownMenuProps}
      buttonType={'utilityInverted'}
    >
      {buttonText}
    </DropdownButton>
  )
}
