import flatMap from 'lodash/flatMap'

import { getId } from 'packages/utils/collectionHelpers'

import { Slugs } from 'app/hkhub/i18n'
import {
  UnitPreferenceKind,
  UnitPreference,
} from 'app/hkhub/store/units/units.types'

export type PreferencesType = {
  [UnitPreferenceKind.mandatory]: UnitPreference[]
  [UnitPreferenceKind.excluded]: UnitPreference[]
  [UnitPreferenceKind.suggested]: UnitPreference[]
}

export function getHkIdsInPreference(pref: UnitPreference): string {
  return getId(pref.housekeeper)
}

/**
 * Takes an object with preferenceKinds as keys to arrays of preferences
 *  and returns an array of hk Ids from on those preferences
 *  This is used to grab the ids of all the hks that already have a preference on a give unit
 *  if hk with id 123 is mandatory on a unit, then this will return an array including 123
 * @param preferences an object with arrays for each of the preference types
 */
export function getHkIdsFromPreferences(
  preferences: PreferencesType,
): string[] {
  return flatMap(UnitPreferenceKind, kind =>
    preferences[kind].map(getHkIdsInPreference),
  )
}

/**
 * Maps a UnitPreferenceKind to its related slug
 * @param alias A UnitPreferenceKind to convert to a slug
 */
export const getSlugForUnitPreferenceKind = (
  alias: UnitPreferenceKind,
): string => {
  switch (alias) {
    case UnitPreferenceKind.excluded:
      return Slugs.excluded
    case UnitPreferenceKind.mandatory:
      return Slugs.mandatory
    case UnitPreferenceKind.suggested:
      return Slugs.suggested
    default:
      throw new Error('Invalid alias passed to getUnitPreferenceKindSlug')
  }
}
