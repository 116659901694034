import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { TasksApiResponse, TasksServiceResponse } from './tasks.types'
import { emptyTasksResponse } from './tasks.utils'

const URL = '/tasks'

export const tasksService = {
  async fetchTasks(params: RequestOptions = {}): TasksServiceResponse {
    const response: TasksApiResponse = await api.get(URL, {
      params,
    })

    return response?.data || emptyTasksResponse
  },

  async fetchTaskById(
    id: string,
    params: RequestOptions = {},
  ): TasksServiceResponse {
    const response: TasksApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })

    return response?.data || emptyTasksResponse
  },

  async updateTask(
    taskId: string,
    patchData: RequestOptions,
  ): TasksServiceResponse {
    const response: TasksApiResponse = await api.patch(
      `${URL}/${taskId}`,
      patchData,
    )

    return response?.data || emptyTasksResponse
  },
}
