import escapeRegExp from 'lodash/escapeRegExp'
export const DEFAULT_WILDCARD = '*'

export const splitAndInsertWildcards = (
  sourceString: string,
  matchString: string,
  wildcard: string = DEFAULT_WILDCARD,
): string[] => {
  // insert a wildcard placeholder where we have matches for the search string, and then split by it
  // this will leave a simple '*' string in indices where the NEXT string needs to be bolded,
  // note that the '__' will all be removed by split(), and empty strings will be filtered out
  const re = new RegExp(escapeRegExp(matchString), 'gi')
  return sourceString
    .replace(re, `__${wildcard}__$&__`)
    .split('__')
    .filter(val => !!val)
}
