import React from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'

import { getLastKnownZone } from 'app/hkhub/store/zones/selectors'

import { FullPageZoneSelector } from './FullPageZoneSelector'
import { ZonePageContainer } from './ZonePage/ZonePage.container'

export const ZoneRouter: React.FC = () => {
  const lastZoneId = useSelector(getLastKnownZone)

  return (
    <Routes>
      {/* ROUTES WITH "/zone" PREFIX */}
      <Route path=":zoneId/*" element={<ZonePageContainer />} />
      <Route path="/" element={<FullPageZoneSelector />} />

      {lastZoneId ? (
        <Route
          path="*"
          element={<Navigate to={`/zone/${lastZoneId}`} replace={true} />}
        />
      ) : (
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      )}
    </Routes>
  )
}
