import { deprecated } from 'typesafe-actions'

import { HousekeepersActionTypes } from '../housekeepers.types'

const { createStandardAction } = deprecated

export const clearHkSearchResultsAction = createStandardAction(
  HousekeepersActionTypes.CLEAR_HK_SEARCH_RESULTS,
)<null>()

/**
 * Dispatches the action to clear any existing Hk search results.
 *
 * Note that this ONLY operates on local data, with no API calls,
 * so we are not concerned about error state here.
 */
export const clearHkSearchResults = () => async dispatch => {
  dispatch(clearHkSearchResultsAction(null))
}
