import styled from '@emotion/styled'
import React from 'react'

import {
  BannerTestIds,
  Drawer,
  DrawerContainerProps,
  LegacyModal2TestIds,
} from 'packages/common'

import { Reservation } from 'app/hkhub/store/reservations'

import { UseTicket } from '../../VisitTicketCard/hooks/useTicket'
import { TicketDetails } from './TicketDetails'
import { TicketDrawerHeader } from './TicketDrawerHeader'

export const TICKET_DRAWER_ID = 'VisitTicketDrawer'
const St = {
  Drawer: styled(Drawer)`
    width: min(420px, 100vw);
  `,

  Header: styled.div`
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 24px;
  `,
}

const ignoreClickEventsOn: DrawerContainerProps['ignoreClickEventsOn'] = [
  `#${BannerTestIds.container}`,
  `#${TICKET_DRAWER_ID}`,
  `#${LegacyModal2TestIds.container}`,
]

type PickedDrawerProps = Pick<DrawerContainerProps, 'afterExit' | 'isOpen'>
type PickedDetailsProps = {
  ticketDetails: UseTicket
}

export type TicketDrawerProps = PickedDrawerProps &
  PickedDetailsProps & {
    currentReservation?: Reservation
    forceCloseDrawer: boolean
    handleForceCloseDrawer: () => void
    isFetchingReservation: boolean
    isFetchingTicket: boolean
    nextReservation?: Reservation
  }

export const TicketDrawer: React.FC<TicketDrawerProps> = React.memo(
  ({
    afterExit,
    currentReservation,
    isFetchingReservation,
    isFetchingTicket,
    forceCloseDrawer,
    handleForceCloseDrawer,
    isOpen,
    nextReservation,
    ticketDetails,
  }) => {
    const { ticket, sortedTicketCommentIds, unit } = ticketDetails

    if (!ticket || !unit) return null

    return (
      <St.Drawer
        afterExit={afterExit}
        forceClose={forceCloseDrawer}
        hideCloseButton={true}
        id={TICKET_DRAWER_ID}
        ignoreClickEventsOn={ignoreClickEventsOn}
        isOpen={isOpen}
      >
        <TicketDrawerHeader
          forceCloseDrawer={handleForceCloseDrawer}
          ticket={ticket}
        />
        <TicketDetails
          currentReservation={currentReservation}
          isFetchingReservation={isFetchingReservation}
          isFetchingTicket={isFetchingTicket}
          nextReservation={nextReservation}
          sortedTicketCommentIds={sortedTicketCommentIds}
          ticket={ticket}
          unit={unit}
        />
      </St.Drawer>
    )
  },
)
