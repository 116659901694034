import snakeCase from 'lodash/snakeCase'

import { JSONApiObject, JSONApiObjectMap } from '../utils'

// Values for 'bookingType' field on Reservations; note that these map directly to the values that the API can return
export type ReservationBookingType =
  | 'guest_reservation'
  | 'owner_hold'
  | 'unknown'
  | 'vacasa_hold'

/**********************************************************
 * RESERVATION
 *********************************************************/
export type Reservation = {
  id: string
} & AllReservationAttributes

export type AllReservationAttributes = {
  bookingType: ReservationBookingType
  checkIn: string
  checkOut: string
  dogs: number
  guestEmail: string | null
  guestFirstName: string | null
  guestLastName: string | null
  guestPhone: string | null
  guests: number
  lateCheckout: boolean
}

export const AllReservationAttributeNames = [
  'bookingType',
  'checkIn',
  'checkOut',
  'dogs',
  'guests',
  'guestEmail',
  'guestFirstName',
  'guestLastName',
  'guestPhone',
  'lateCheckout',
]

export const ReservationApiFields: string[] =
  AllReservationAttributeNames.map(snakeCase)

export type RawReservation = JSONApiObject<AllReservationAttributes>

export type JSONApiReservationsMap = JSONApiObjectMap<AllReservationAttributes>
