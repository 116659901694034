import { produce } from 'immer'
import { merge } from 'lodash/fp'

type CleanNotesFormValues = {
  notes: string
}

export type CleanNotesFormState = {
  canSubmit: boolean
  initialValues: Partial<CleanNotesFormValues>
  uiState: 'edit' | 'read'
  values: CleanNotesFormValues
}

type CleanNotesAction =
  | {
      payload: {
        values: Pick<CleanNotesFormValues, 'notes'>
      }
      type: 'setValues' | 'setInitial'
    }
  | {
      type: 'editStart' | 'editEnd'
    }

export const cleanNotesInitialState: CleanNotesFormState = {
  canSubmit: false,
  initialValues: {
    notes: '',
  },
  uiState: 'read',
  values: {
    notes: '',
  },
}

export const makeInitialState = (
  state: Partial<CleanNotesFormState> = {},
): CleanNotesFormState => merge(cleanNotesInitialState, state)

export const cleanNotesReducer = (
  state: CleanNotesFormState,
  action: CleanNotesAction,
): CleanNotesFormState =>
  produce(state, draft => {
    switch (action.type) {
      case 'setValues': {
        const values = action.payload?.values || {}
        Object.entries(values).forEach(([key, value]) => {
          if (key in draft.values) {
            draft.values[key] = value
          }
        })

        draft.canSubmit =
          draft.values.notes.trim() !== state.initialValues.notes?.trim()

        break
      }

      case 'setInitial': {
        draft.initialValues.notes = action.payload.values.notes || ''
        break
      }

      case 'editStart': {
        draft.canSubmit = false
        draft.uiState = 'edit'
        draft.values.notes = state.initialValues.notes || ''
        break
      }

      case 'editEnd': {
        draft.uiState = 'read'
        break
      }
    }

    return
  })
