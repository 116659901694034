import styled from '@emotion/styled'
import React from 'react'

import { Loader } from 'packages/common'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import { AssignmentChangeDisplayContainer } from './AssignmentChangeDisplay'

const St = {
  ChangesContainer: styled.div`
    position: relative;
  `,

  Hr: styled.hr`
    color: ${colors.midnight30};
    height: 1px;
    width: 100%;
  `,

  LoaderWrapper: styled.div`
    height: 20px;
    position: relative;
  `,

  NoAssignmentHistory: styled.div`
    ${text.bodyRegularTiny}
    color: ${colors.dusk60};
    font-style: italic;
    margin-left: 8px;
  `,
}

export enum AssignmentChangeListTestIds {
  container = 'AssignmentChangeList__container',
}

export type AssignmentChangeListProps = {
  assignmentChangeIds: string[]
  isLoading: boolean
}

export const AssignmentChangeList: React.FC<AssignmentChangeListProps> =
  React.memo(({ assignmentChangeIds, isLoading }) => {
    const { t } = useI18n()
    return (
      <St.ChangesContainer>
        <St.Hr />
        {isLoading ? (
          <St.LoaderWrapper>
            <Loader />
          </St.LoaderWrapper>
        ) : !!assignmentChangeIds.length ? (
          assignmentChangeIds.map(id => (
            <AssignmentChangeDisplayContainer
              key={id}
              assignmentChangeId={id}
            />
          ))
        ) : (
          <St.NoAssignmentHistory>
            {t(Slugs.noAssignmentHistory)}
          </St.NoAssignmentHistory>
        )}
      </St.ChangesContainer>
    )
  })
