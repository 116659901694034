import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash/fp'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { RequestOptions } from 'packages/utils/store'

import { fetchUnitByIdStrict } from '../../units/actions'
import { customInspectionItemsService } from '../customInspectionItems.service'
import {
  CustomInspectionItemPatchApiAttributes,
  CustomInspectionItemPatchData,
} from '../customInspectionItems.types'

export const buildRequestData = (
  patchData: CustomInspectionItemPatchData,
): RequestOptions => {
  const { itemTitle } = patchData

  const attributes: CustomInspectionItemPatchApiAttributes = {
    item_title: itemTitle,
  }

  return {
    data: {
      attributes,
      type: 'custom_inspection_item',
    },
  }
}

type UpdateCustomInspectionItemThunkArgs = {
  callbacks: ReduxActionCallbacks
  patchData: CustomInspectionItemPatchData
}

export const updateCustomInspectionItem = createAsyncThunk(
  'customInspectionItems/api/updateCustomInspectionItem',
  async (args: UpdateCustomInspectionItemThunkArgs, { dispatch }) => {
    const { patchData, callbacks } = args
    const { onError = noop, onSuccess = noop } = callbacks
    const { itemId } = patchData
    const requestData = buildRequestData(patchData)

    try {
      const result =
        await customInspectionItemsService.updateCustomInspectionItem(
          itemId,
          requestData,
        )

      // re-fetch the unit, so we can get the updated custom checklist items
      await dispatch(fetchUnitByIdStrict(patchData.unitId))

      onSuccess(result)
      return result
    } catch (error) {
      onError(error)
    }
  },
)
