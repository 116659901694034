import styled from '@emotion/styled'
import React from 'react'

import { DropdownCaret, DropdownMenu, DropdownMenuProps } from '../DropdownMenu'
import { Button, ButtonProps } from './Button'

const CustomCaret = styled(DropdownCaret)`
  margin-left: 16px;
`

export type DropdownButtonProps = ButtonProps & {
  dropdownMenuProps: Omit<DropdownMenuProps, 'children'>
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  dropdownMenuProps,
  children,
  ...buttonProps
}) => {
  return (
    <DropdownMenu {...dropdownMenuProps} hideCaret={true}>
      <Button {...buttonProps}>
        {children}
        {!dropdownMenuProps.hideCaret && <CustomCaret />}
      </Button>
    </DropdownMenu>
  )
}
