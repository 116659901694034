import { createAsyncAction } from 'typesafe-actions'

import {
  RelationshipUpdate,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { housekeepersService } from '../housekeepers.service'
import {
  HousekeepersActionTypes,
  NormalizedHousekeepersApiResponse,
} from '../housekeepers.types'

export const removeBorrowedZoneFromHkAction = createAsyncAction(
  HousekeepersActionTypes.REMOVE_BORROWED_ZONE_FROM_HK,
  HousekeepersActionTypes.REMOVE_BORROWED_ZONE_FROM_HK_SUCCESS,
  HousekeepersActionTypes.REMOVE_BORROWED_ZONE_FROM_HK_FAILURE,
)<
  RequestConfig<NormalizedHousekeepersApiResponse>,
  NormalizedHousekeepersApiResponse,
  Error
>()

export const removeBorrowedZoneFromHk =
  (requestData: RelationshipUpdate) => async dispatch => {
    try {
      const request = housekeepersService.removeRelationships.bind(
        null,
        requestData,
        'zone',
        'borrowed_zone',
      )
      const result = await dispatch(
        removeBorrowedZoneFromHkAction.request({ request }),
      )
      result.normalized.relationshipUpdate = requestData
      dispatch(removeBorrowedZoneFromHkAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(removeBorrowedZoneFromHkAction.failure(error))
      throw error
    }
  }
