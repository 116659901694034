import { produce } from 'immer'
import get from 'lodash/get' // eslint-disable-line
import { ActionType, getType } from 'typesafe-actions'

import { getArrayOfIdsAndDataTypesFromAction } from 'packages/utils/store/store.utils'

import { RawUser } from '../users/users.types'
import {
  addHksToZoneAction,
  addUnitsToZoneAction,
  fetchZoneAction,
  removeHksFromZoneAction,
  removeUnitsFromZoneAction,
  searchZonesAction,
  selectZoneAction,
  updateZoneAction,
} from './actions'
import { RawZone, ZonesState } from './zones.types'

const initialState: ZonesState = {
  currentZones: [],
  data: {},
  error: undefined,
  isLoading: false,
  searchResults: {
    user: {},
    zone: {},
  },
  user: {},
}

type ZonesActionsTypes = ActionType<{
  addHksToZoneAction
  addUnitsToZoneAction
  fetchZoneAction
  removeHksFromZoneAction
  removeUnitsFromZoneAction
  searchZonesAction
  selectZoneAction
  updateZoneAction
}>

export const zonesReducer = (
  state = initialState,
  action: ZonesActionsTypes,
): ZonesState =>
  produce(state, (draft: ZonesState) => {
    switch (action.type) {
      case getType(fetchZoneAction.request):
        draft.isLoading = true
        return

      case getType(fetchZoneAction.success):
        const zones = getArrayOfIdsAndDataTypesFromAction<RawZone>(
          action,
          'zone',
        )
        const users = getArrayOfIdsAndDataTypesFromAction<RawUser>(
          action,
          'user',
        )

        zones.forEach(([id, zone]) => {
          draft.data[id] = zone
        })

        users.forEach(([id, user]) => {
          draft.user[id] = user
        })

        draft.isLoading = false
        return

      case getType(updateZoneAction.failure):
      case getType(addHksToZoneAction.failure):
      case getType(fetchZoneAction.failure):
      case getType(removeHksFromZoneAction.failure):
      case getType(removeUnitsFromZoneAction.failure):
      case getType(searchZonesAction.failure):
        draft.isLoading = false
        draft.error = action.payload
        return

      case getType(searchZonesAction.success):
        draft.searchResults.user = get(action, 'payload.normalized.user', {})
        draft.searchResults.zone = get(action, 'payload.normalized.zone', {})
        draft.isLoading = false
        return

      case getType(addHksToZoneAction.success):
      case getType(addUnitsToZoneAction.success):
      case getType(removeHksFromZoneAction.success):
      case getType(removeUnitsFromZoneAction.success):
        // no need for any store updates here; their own respective stores will handle the data updates
        draft.isLoading = false
        return

      case getType(selectZoneAction):
        draft.currentZones = [action.payload.id]
        return

      case getType(updateZoneAction.success): {
        const index = draft.currentZones.indexOf(action.payload.id)
        if (index !== -1) {
          draft.currentZones[index] = action.payload
        } else {
          draft.currentZones[draft.currentZones.length] = action.payload
        }

        draft.isLoading = false
        return
      }
    }
  })
