import { createAsyncAction } from 'typesafe-actions'

import { apiFilterExcludeCleans } from 'packages/grimoire'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { InspectionFlagApiFields } from '../../inspectionFlags'
import { TaskPhotoApiFields } from '../../taskPhotos'
import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { TicketApiFields } from '../../tickets'
import { VisitApiFields, VisitsActionTypes } from '../visits.types'

export const fetchVisitByIdAction = createAsyncAction(
  VisitsActionTypes.FETCH_VISIT,
  VisitsActionTypes.FETCH_VISIT_SUCCESS,
  VisitsActionTypes.FETCH_VISIT_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    inspection_flags: InspectionFlagApiFields,
    task: VisitApiFields,
    task_photo: TaskPhotoApiFields,
    ticket: TicketApiFields,
    unit: [],
  },
  filter: {
    ...apiFilterExcludeCleans,
  },
  include: [
    'assigned_housekeepers',
    'inspection_flags',
    'task_photos',
    'tickets',
    'unit',
  ],
})

export const fetchVisitById = (visitId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = tasksService.fetchTaskById.bind(null, visitId, params)
    const result = await dispatch(fetchVisitByIdAction.request({ request }))
    dispatch(fetchVisitByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchVisitByIdAction.failure(error))
    throw error
  }
}
