import get from 'lodash/get' // eslint-disable-line

import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { Zone } from 'app/hkhub/store/zones/zones.types'

/** Returns whether the housekeeper currently has a primary zone (either that or undefined) */
export const hkHasPrimaryZone = (hk: Housekeeper): boolean => !!hk.zone

/** Returns whether the housekeeper is currently "borrowed" in the provided zone */
export const hkIsBorrowedInZone = (hk: Housekeeper, zone: Zone): boolean =>
  (hk.borrowedZones || []).includes(zone.id)

/** Returns whether the provided zone is the housekeeper's primary zone (if they have one at all) */
export const zoneIsHkPrimaryZone = (hk: Housekeeper, zone: Zone): boolean =>
  get(hk, 'zone.id') === zone.id
