import { AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  AllTicketAttributes,
  AllTicketAttributeNames,
} from 'packages/grimoire/src/ticket/ticket.types'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToManyRelationship,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { JSONApiTicketCommentsMap } from '../ticketComments'
import { JSONApiUnitMap } from '../units'
import { JSONApiUsersMap } from '../users'

export * from 'packages/grimoire/src/ticket/ticket.types'

export enum TicketActionTypes {
  FETCH_INCOMPLETE_TICKETS_BY_ZONE = 'TICKETS/API/FETCH_INCOMPLETE_TICKETS_BY_ZONE',
  FETCH_INCOMPLETE_TICKETS_BY_ZONE_FAILURE = 'TICKETS/FETCH_INCOMPLETE_TICKETS_BY_ZONE_FAILURE',
  FETCH_INCOMPLETE_TICKETS_BY_ZONE_SUCCESS = 'TICKETS/FETCH_INCOMPLETE_TICKETS_BY_ZONE_SUCCESS',

  FETCH_TICKET = 'TICKETS/API/FETCH_TICKET',
  FETCH_TICKETS_BY_ZONE_AND_DATE = 'TICKETS/API/FETCH_TICKETS_BY_ZONE_AND_DATE',
  FETCH_TICKETS_BY_ZONE_AND_DATE_FAILURE = 'TICKETS/FETCH_TICKETS_BY_ZONE_AND_DATE_FAILURE',

  FETCH_TICKETS_BY_ZONE_AND_DATE_SUCCESS = 'TICKETS/FETCH_TICKETS_BY_ZONE_AND_DATE_SUCCESS',
  FETCH_TICKET_FAILURE = 'TICKETS/FETCH_TICKET_FAILURE',
  FETCH_TICKET_SUCCESS = 'TICKETS/FETCH_TICKET_SUCCESS',

  UPDATE_TICKET = 'TICKETS/API/UPDATE_TICKET',
  UPDATE_TICKET_FAILURE = 'TICKETS/UPDATE_TICKET_FAILURE',
  UPDATE_TICKET_SUCCESS = 'TICKETS/UPDATE_TICKET_SUCCESS',
}

/**********************************************************
 * API TYPES
 *********************************************************/
// PATCH REQUEST DATA
export type TicketPatchAttributes = {
  assigneeId: string
  dueDate: string
}

export type TicketPatchData = {
  id: string
} & Partial<TicketPatchAttributes>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type TicketsState = {
  byUnitIncompleteTicketsHash: { [key: string]: number }
  data: JSONApiTicketsMap
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type TicketsResponse = {
  ticket: JSONApiTicketsMap
  ticketComment: JSONApiTicketCommentsMap
  unit: JSONApiUnitMap
  user: JSONApiUsersMap
}

export type NormalizedTicketsApiResponse =
  NormalizedJSONApiResponse<TicketsResponse>

export type TicketsServiceResponse = Promise<NormalizedTicketsApiResponse>
export type TicketsApiResponse = AxiosResponse<NormalizedTicketsApiResponse>

/**********************************************************
 * TICKET
 *********************************************************/
export type Ticket = {
  assigneeId: string | null
  commentIds: string[]
  id: string
  unitId: string
} & TicketAttributes

export type TicketAttributes = AllTicketAttributes & {
  dueDateNoTimestamp?: string
  startedAt?: string
}

export const TicketAttributeNames = [...AllTicketAttributeNames, 'startedAt']

export const TicketApiFields: string[] = TicketAttributeNames.map(snakeCase)

export type TicketRelationships = {
  assignee: ToOneRelationship
  comments: ToManyRelationship
  disposition: ToOneRelationship
  lockBox: ToOneRelationship
  unit: ToOneRelationship
}

export type JSONApiTicketsMap = JSONApiObjectWithRelationshipsMap<
  TicketAttributes,
  TicketRelationships
>

export type RawTicket = JSONApiObjectWithRelationships<
  TicketAttributes,
  TicketRelationships
>

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
export type DateTicketBuckets = {
  [date: string]: Ticket[]
}

/** Tickets bucketed by unit, and then further by date */
export type UnitTicketSchedule = {
  [unitId: string]: DateTicketBuckets
}
