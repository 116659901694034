import React from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from 'app/hkhub/store/store'
import { getTicketCommentById } from 'app/hkhub/store/ticketComments/selectors'
import { getUserById } from 'app/hkhub/store/users/selectors'

import { TicketCommentDisplay } from './TicketCommentDisplay'

export type TicketCommentDisplayContainerProps = {
  commentId: string
}

export const TicketCommentDisplayContainer: React.FC<TicketCommentDisplayContainerProps> =
  React.memo(({ commentId }) => {
    const comment = useSelector((state: ApplicationState) =>
      getTicketCommentById(state, commentId),
    )

    const user = useSelector((state: ApplicationState) =>
      getUserById(state, comment?.userId || ''),
    )

    return comment ? (
      <TicketCommentDisplay comment={comment} user={user} />
    ) : null
  })
