import styled from '@emotion/styled'
import * as React from 'react'

import { Checkbox } from 'packages/common'
import { UnitRealtimeStatus } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'

import { unitStatusTranslations } from 'app/hkhub/components/schedule/components/units/utils/filters'

export const orderedStatuses: UnitRealtimeStatus[] = [
  UnitRealtimeStatus.CLEAN_COMPLETED,
  UnitRealtimeStatus.CLEAN_IN_PROGRESS,
  UnitRealtimeStatus.CLEAN_READY,
  UnitRealtimeStatus.CLEAN_READY_OVERDUE,
  UnitRealtimeStatus.CLEAN_IN_PROGRESS_OVERDUE,
  UnitRealtimeStatus.OCCUPIED_GUEST,
  UnitRealtimeStatus.OWNER_HOLD,
  UnitRealtimeStatus.VACASA_HOLD,
  UnitRealtimeStatus.VACANT_NO_VACASA_CLEAN,
]

const UnitStatusListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const useTranslations = () => {
  const { t } = useI18n()

  return {
    statusLabel: unitStatusTranslations(t),
  }
}

export enum UnitStatusListTestIds {
  container = 'UnitStatusList__container',
}

export type UnitStatusListProps = {
  onFilterToggle: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const UnitStatusList: React.FC<UnitStatusListProps> = React.memo(
  ({ onFilterToggle }) => {
    const strings = useTranslations()

    return (
      <UnitStatusListContainer data-testid={UnitStatusListTestIds.container}>
        {orderedStatuses.map(status => (
          <Checkbox
            key={status}
            label={strings.statusLabel[status]}
            onChange={onFilterToggle}
            value={status}
          />
        ))}
      </UnitStatusListContainer>
    )
  },
)
