import { EventHandler } from 'packages/webSocketEvents'

import { ApplicationState } from 'app/hkhub/store/store'
import { fetchUnitByIdStrict } from 'app/hkhub/store/units/actions'

import { fetchHousekeepersByZone } from '../../housekeepers/actions'
import { TaskWsEvent } from '../tasks.types'
import { fetchTaskById } from './fetchTaskById'

/**
 * WebSockets handler for any generic "Task Updated" event.
 *
 * The API sends a number of different events (created, started, completed, etc.), but this logic
 * will cover most of them in a single spot.
 *
 * Since there is no guarantee that any of the necessary data exists in Hub yet,
 * this will send a separate GET request for all possible data needed to display a clean.
 * @param data
 */
export const wsTaskUpdated: EventHandler<ApplicationState> =
  (data: TaskWsEvent) => async (dispatch, getState) => {
    const incomingZoneId = data?.zoneId
    const currentZoneId = (getState().zones?.currentZones || [])[0] || ''

    if (currentZoneId === incomingZoneId) {
      await Promise.all([
        fetchUnitByIdStrict(`${data.unitId}`)(dispatch),
        fetchHousekeepersByZone(currentZoneId)(dispatch),
      ])

      fetchTaskById(data.taskId)(dispatch)
    }
  }
