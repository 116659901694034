import { useSelector } from 'react-redux'

import { ApplicationState } from 'app/hkhub/store/store'
import { UnitTicketSchedule } from 'app/hkhub/store/tickets'
import { getTicketsByDateAndUnit } from 'app/hkhub/store/tickets/selectors/getTicketsByDateAndUnit'

import { useScheduleContext } from '../../../common/VirtualizedSchedule/VirtualizedSchedule.context'

export const useUnitTickets = (): { ticketBuckets: UnitTicketSchedule } => {
  const { dateRange } = useScheduleContext()

  const ticketBuckets = useSelector((state: ApplicationState) =>
    getTicketsByDateAndUnit(state, dateRange),
  )

  return { ticketBuckets }
}
