import { filter } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { Housekeeper } from '../housekeepers.types'
import { isAgency } from '../housekeepers.utils'
import { getSortedHks } from './getSortedHks'

const getHousekeepersState = (state: ApplicationState) => state.housekeepers

export const getAgencyHks = createSelector(
  getHousekeepersState,
  (hkState): Housekeeper[] => {
    const sortedHks = getSortedHks(hkState)
    return filter(isAgency)(sortedHks)
  },
)
