import styled from '@emotion/styled'
import React from 'react'

import { useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'

import { CoupaRoutingCard } from './CoupaRoutingCard'

const St = {
  Container: styled.div`
    margin: auto;
    max-width: 1200px;
    width: 100%;
  `,
  Description: styled.div`
    border-bottom: 1px solid ${colors.midnight20};
    margin-bottom: 24px;
    padding-bottom: 8px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    payContractorText: t(Slugs.payContractorText),
  }
}

export enum ContractorInvoicingTestIds {
  container = 'ContractorInvoicing__container',
}

export const ContractorInvoicing: React.FC = React.memo(() => {
  const strings = useTranslations()
  return (
    <St.Container data-testid={ContractorInvoicingTestIds.container}>
      <St.Description>{strings.payContractorText}</St.Description>
      <CoupaRoutingCard />
    </St.Container>
  )
})
