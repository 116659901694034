import styled from '@emotion/styled'
import React from 'react'

import { useToast } from 'packages/common'
import { useI18n } from 'packages/i18n'

import { Slugs } from 'app/hkhub/i18n'
import { ReservationBookingType } from 'app/hkhub/store/reservations/reservations.types'
import { Unit } from 'app/hkhub/store/units'

import { DeepCleanSkipFormContainer } from '../../DeepCleanSkipForm'
import { UnitDeepCleanStatistics } from '../../UnitDeepCleanStatistics'

const St = {
  UnitDeepCleanStatistics: styled(UnitDeepCleanStatistics)`
    margin-top: 16px;
  `,
}
export enum DeepCleanExtraInfoPanelTestIds {
  container = 'DeepCleanExtraInfoPanel__container',
}

export type DeepCleanExtraInfoPanelProps = {
  className?: string
  dataTestId?: string
  reservationBookingType?: ReservationBookingType
  unit: Unit
}

export const DeepCleanExtraInfoPanel: React.FC<DeepCleanExtraInfoPanelProps> =
  React.memo(({ className, dataTestId, reservationBookingType, unit }) => {
    const { ut } = useI18n()

    const { showToast } = useToast()

    const showSuccessToast = React.useCallback(
      () => showToast({ message: ut(Slugs.deepCleanSkipSuccess) }),
      [showToast, ut],
    )

    const deepCleanStats = React.useMemo(
      () =>
        unit.statistics?.deepCleanStats
          ? unit.statistics.deepCleanStats
          : undefined,
      [unit.statistics],
    )

    const isOwnerCheckout =
      reservationBookingType === ReservationBookingType.OWNER

    return (
      <div
        className={className}
        data-testid={dataTestId || DeepCleanExtraInfoPanelTestIds.container}
      >
        <div>
          {ut(Slugs[isOwnerCheckout ? 'ownerCheckoutExtraInfo' : 'editInfo'])}
        </div>

        {deepCleanStats && (
          <St.UnitDeepCleanStatistics deepCleanStats={deepCleanStats} />
        )}

        <DeepCleanSkipFormContainer
          onSuccess={showSuccessToast}
          unitId={unit.id}
        />
      </div>
    )
  })
