import { map } from 'lodash/fp'
import * as React from 'react'

import { UseManageDrawerState, useManageDrawerState } from 'packages/common'
import { UnitRealtimeStatus } from 'packages/grimoire'
import { Events, track } from 'packages/wiretap'

import {
  UnitFilterType,
  useUnitFiltersContext,
} from 'app/hkhub/components/schedule/components/units/contexts'
import { makeUnitStatusFilter } from 'app/hkhub/components/schedule/components/units/utils/filters'
import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'

import { UnitStatusDrawerProps } from '../UnitStatusDrawer'

type PickedDrawerStateProps = Pick<UseManageDrawerState, 'openDrawer'>

type UseUnitStatusFilters = PickedDrawerStateProps & UnitStatusDrawerProps

export const useUnitStatusFilters = (): UseUnitStatusFilters => {
  const { zone } = useZoneContext()
  const { addFilters } = useUnitFiltersContext()

  const { closeDrawer, completeDrawerClose, drawerState, openDrawer } =
    useManageDrawerState()

  const onFiltersApplied = React.useCallback(
    (unitStatuses: UnitRealtimeStatus[]) => {
      const newFilters = map(makeUnitStatusFilter, unitStatuses)
      addFilters(UnitFilterType.UnitStatus, newFilters)

      unitStatuses.forEach(status => {
        /* eslint-disable @typescript-eslint/naming-convention */
        track(Events.hubFilterByUnitStatus, {
          unit_status: status,
          zone_id: zone.id,
        })
        /* eslint-enable @typescript-eslint/naming-convention */
      })

      closeDrawer()
    },
    [addFilters, closeDrawer, zone.id],
  )

  return {
    afterExit: completeDrawerClose,
    forceClose: drawerState.forceClose,
    isOpen: drawerState.isOpen,
    onFiltersApplied,
    openDrawer,
  }
}
