import styled from '@emotion/styled'
import React from 'react'

import { cleanRealtimeStatusTextColorMap } from 'packages/common/src/RealtimeStatusIndicators/CleanRealtimeStatusIndicator/CleanRealtimeStatusIndicator.helpers'
import { CleanRealtimeStatus, taskIsVisit } from 'packages/grimoire'
import { text, truncateTextWithEllipsis } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans'
import { Visit } from 'app/hkhub/store/visits'

const St = {
  Text: styled.div<{ status: CleanRealtimeStatus }>`
    ${truncateTextWithEllipsis};
    ${text.headingLabel};
    color: ${({ status }) => cleanRealtimeStatusTextColorMap[status]};
  `,
}

const cleanStatusSlugs = {
  [CleanRealtimeStatus.COMPLETED]: Slugs.cleanStatusCompleted,
  [CleanRealtimeStatus.NOT_READY]: Slugs.cleanStatusNotReady,
  [CleanRealtimeStatus.READY]: Slugs.cleanStatusReady,
  [CleanRealtimeStatus.READY_OVERDUE]: Slugs.cleanStatusOverdue,
  [CleanRealtimeStatus.IN_PROGRESS]: Slugs.cleanStatusInProgress,
  [CleanRealtimeStatus.IN_PROGRESS_OVERDUE]: Slugs.cleanStatusInProgress,
}

const visitStatusSlugs = {
  ...cleanStatusSlugs,
  [CleanRealtimeStatus.COMPLETED]: Slugs.done,
  [CleanRealtimeStatus.READY]: Slugs.ready,
  [CleanRealtimeStatus.READY_OVERDUE]: Slugs.ready,
  [CleanRealtimeStatus.MISSED]: Slugs.missed,
}

const useTranslations = (task: Clean | Visit) => {
  const { ut } = useI18n()
  const isVisit = taskIsVisit(task)
  const status = task.realtimeStatus

  return {
    status: ut(isVisit ? visitStatusSlugs[status] : cleanStatusSlugs[status]),
  }
}

export type CleanProgressTextProps = {
  task: Clean | Visit
}

export const CleanProgressText: React.FC<CleanProgressTextProps> = ({
  task,
}) => {
  const strings = useTranslations(task)
  return <St.Text status={task.realtimeStatus}>{strings.status}</St.Text>
}
