import styled from '@emotion/styled'
import React from 'react'

import { SvgIcon, IconName } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { InspectionFlag } from 'app/hkhub/store/tasks'

const St = {
  IconWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: ${`${colors.awareness70}`};
    margin-right: 8px;
  `,

  Item: styled.li<{ hasNotes: boolean; isLast: boolean }>`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 12px;
    align-items: ${({ hasNotes }) => (hasNotes ? 'start' : 'center')};
    border-bottom: ${({ isLast }) => (isLast ? 'none' : '1px solid #E0E0E0')};
    padding: 12px 16px;
    font-size: 14px;
    color: #333;
  `,

  Notes: styled.div`
    font-size: 12px; // Smaller font size for notes
    color: #666; // Lighter text color for notes
    margin-top: 4px; // Space between title and notes
  `,

  Title: styled.span`
    text-align: left;
    overflow: hidden;
    word-wrap: break-word;
  `,
}

export enum InspectionFlagsTestIds {
  item = 'InspectionFlags__item',
}

export type InspectionFlagsProps = {
  inspectionFlags: InspectionFlag[] | null | undefined
}

export const InspectionFlags: React.FC<InspectionFlagsProps> = React.memo(
  ({ inspectionFlags }) => {
    const { t } = useI18n()

    return (
      <ul>
        {inspectionFlags?.map((item, index, array) => (
          <St.Item
            key={item.id}
            isLast={index === array.length - 1}
            hasNotes={!!item?.notes}
            data-testid={InspectionFlagsTestIds.item}
          >
            <St.IconWrapper>
              <SvgIcon icon={IconName.flag} size={20} />
            </St.IconWrapper>
            <div>
              <St.Title>{item?.title}</St.Title>
              {item?.notes && (
                <St.Notes>
                  <b>{t(Slugs.notes)}:</b> {item?.notes}
                </St.Notes>
              )}
            </div>
          </St.Item>
        ))}
      </ul>
    )
  },
)
