import get from 'lodash/get' // eslint-disable-line

import { Clean } from 'app/hkhub/store/cleans/cleans.types'
import { makeCleanUnitHash } from 'app/hkhub/utils/scheduleHelpers'

import { ReservationsState, Reservation } from '../reservations.types'
import { transformNormalizedToReservation } from '../utils/transformNormalizedToReservation'

export const getReservationFromClean = (
  state: ReservationsState,
  clean: Clean,
): Reservation | undefined => {
  if (!clean.unit?.id) return undefined

  const hash = makeCleanUnitHash(clean)
  const raw = get(state, ['byDateUnitHash', hash])
  return raw ? transformNormalizedToReservation(raw) : undefined
}
