import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ticketingService } from '../ticketing.service'
import {
  CreateAssignmentOptions,
  NormalizedTicketingApiResponse,
  TicketingActionTypes,
} from '../ticketing.types'

export const createTicketingAssignmentForDispositionAction = createAsyncAction(
  TicketingActionTypes.CREATE_ASSIGNMENT,
  TicketingActionTypes.CREATE_ASSIGNMENT_SUCCESS,
  TicketingActionTypes.CREATE_ASSIGNMENT_FAILURE,
)<
  RequestConfig<NormalizedTicketingApiResponse>,
  NormalizedTicketingApiResponse,
  Error
>()

export const buildRequestData = (
  dispositionId: string,
  userId: string,
  zoneId: string,
): RequestOptions => ({
  data: {
    attributes: {
      isActive: false,
    },
    relationships: {
      disposition: { data: { id: dispositionId, type: 'disposition' } },
      user: { data: { id: userId, type: 'user' } },
      zone: { data: { id: zoneId, type: 'zone' } },
    },
    type: 'zone_disposition_default',
  },
})

export const createTicketingAssignmentForDisposition =
  ({ zoneId, userId, dispositionId }: CreateAssignmentOptions) =>
  async dispatch => {
    try {
      const requestData = buildRequestData(dispositionId, userId, zoneId)
      const request = ticketingService.createAssignment.bind(null, requestData)
      const result = await dispatch(
        createTicketingAssignmentForDispositionAction.request({ request }),
      )
      dispatch(createTicketingAssignmentForDispositionAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(createTicketingAssignmentForDispositionAction.failure(error))
      throw error
    }
  }
