import styled from '@emotion/styled'

import { colors } from 'packages/styles'

import {
  CardSize,
  cardSizeLessThan,
  ScheduleSizes,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

import { ReservationSectionType } from './ResBar.helpers'

const HEIGHT = ScheduleSizes.ReservationsGutterHeight

const sectionTypeColumns: Record<ReservationSectionType, string> = {
  end: '1',
  middle: '1 / 4',
  start: '3',
}

const ResSlanty = styled.div`
  height: ${HEIGHT}px;
  width: ${HEIGHT}px;
`

const StartSlanty = styled(ResSlanty)`
  margin-right: -${HEIGHT / 2}px;
  transform: skew(-45deg);
`

const EndSlanty = styled(ResSlanty)`
  margin-left: -${HEIGHT / 2}px;
  transform: skew(135deg);
`

const BasicBar = styled.div<{ size: CardSize }>`
  display: flex;
  flex: 1;
  font-size: ${({ size }) =>
    cardSizeLessThan(size, 'medium') ? '8px' : '12px'};
  height: ${HEIGHT}px;
  position: relative;
`

export const St = {
  EcdBar: styled(BasicBar)`
    background: ${colors.aspen10};
    border-bottom: 1px solid ${colors.aspen40};
    border-top: 1px solid ${colors.aspen40};
  `,

  EcdEnd: styled(EndSlanty)`
    background: ${colors.aspen10};
    border: 1px solid ${colors.aspen40};
    border-left: 0;
  `,

  EcdStart: styled(StartSlanty)`
    background: ${colors.aspen10};
    border-bottom: 1px solid ${colors.aspen40};
    border-top: 1px solid ${colors.aspen40};
    border-left: 6px solid ${colors.aspen};
  `,

  ResBar: styled(BasicBar)`
    background: ${colors.midnight10};
  `,

  ResEnd: styled(EndSlanty)`
    background: ${colors.midnight10};
  `,

  ResLink: styled.a`
    align-items: center;
    color: ${colors.lake};
    cursor: pointer;
    display: flex;
    font-weight: bold;
    padding-left: 10px;
    padding-top: 1px;
    position: absolute;
    text-transform: uppercase;
    top: 3px;
    width: max-content;
    z-index: 1;
  `,

  ResStart: styled(StartSlanty)`
    background: ${colors.midnight10};
    border-left: 6px solid ${colors.midnight40};
  `,

  Wrapper: styled.div<{ sectionType: ReservationSectionType }>`
    display: flex;
    grid-column: ${({ sectionType }) => sectionTypeColumns[sectionType]};
    grid-row: 1;
  `,
}
