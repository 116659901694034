import { createSelector } from '@reduxjs/toolkit'
import { pipe, values, map } from 'lodash/fp'

import { DateFormat, format } from 'packages/utils/dateHelpers'

import { getDateRangeMap } from '../../cleans/cleans.utils'
import { ApplicationState } from '../../store'
import {
  Coverage,
  CoverageState,
  HkDateCoverageBuckets,
} from '../coverage.types'
import { hydrateRawCoverage } from '../coverage.utils'

export const getCoverageBucketsByHkAndDate = createSelector(
  (state: ApplicationState) => state.coverage,
  (_: unknown, dateRange: Date[]) => dateRange,
  (coverageState: CoverageState, dateRange: Date[]): HkDateCoverageBuckets => {
    const dateRangeMap = getDateRangeMap(dateRange)

    const rawCoverage = coverageState.data

    const coverages: Coverage[] = pipe(
      values,
      map(hydrateRawCoverage),
    )(rawCoverage)

    return coverages.reduce((acc, coverage) => {
      const date = format(coverage.start, DateFormat.ApiUtcShort)

      if (date in dateRangeMap) {
        const hkId = coverage.schedulingUserId

        if (!acc[hkId]) acc[hkId] = {}
        if (!acc[hkId][date]) acc[hkId][date] = []

        acc[hkId][date].push(coverage)
      }

      return acc
    }, {})
  },
)
