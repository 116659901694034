import { startCase } from 'lodash/fp'
import React from 'react'

import { fullName, HkEmployeeType } from 'packages/grimoire'
import { IconName } from 'packages/iconic'
import { Breakpoint } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'

import * as St from './StaffScheduleRowHeader.styles'

const useTranslations = (hk: Housekeeper) => {
  const { t, ut } = useI18n()

  const headerTextPrefix: Record<HkEmployeeType, string> = {
    [HkEmployeeType.employee]: '',
    [HkEmployeeType.contractor]: `(${ut(Slugs.contractor)}) `,
    [HkEmployeeType.agency]: `(${startCase(ut(Slugs.contractorAgency))}) `,
  }

  return {
    borrowed: t(Slugs.borrowed),
    namePrefix: t(headerTextPrefix[hk.employeeType] || ''),
    tier: t(Slugs.tier),
  }
}

export type StaffScheduleRowHeaderProps = {
  breakpoint: Breakpoint
  hk: Housekeeper
  hours: number
  isLoading: boolean
  unassigned: boolean
}

export const StaffScheduleRowHeader: React.FC<StaffScheduleRowHeaderProps> = ({
  breakpoint,
  hk,
  hours,
  isLoading,
  unassigned,
}) => {
  const strings = useTranslations(hk)

  const phoneNumberText = hk.user?.mobilePhone ? `${hk.user.mobilePhone}` : null

  const nameText = `${strings.namePrefix}${fullName(hk.user)}`

  const tierText = React.useMemo((): React.ReactNode => {
    if (hk.borrowedInCurrentZones) return strings.borrowed

    const rank = hk?.tier.rank ?? 0
    return rank > 0 ? <span>{`${strings.tier} ${rank}`}</span> : null
  }, [
    hk?.borrowedInCurrentZones,
    hk?.tier.rank,
    strings.borrowed,
    strings.tier,
  ])

  return (
    <St.StaffScheduleRowHeader breakpoint={breakpoint} unassigned={unassigned}>
      <St.ScheduleRowHeaderName breakpoint={breakpoint}>
        {nameText}
      </St.ScheduleRowHeaderName>

      <St.ScheduleRowHeaderTier breakpoint={breakpoint}>
        {tierText}
      </St.ScheduleRowHeaderTier>

      {/* We need to conditonally render this element 
      so that we dont show a phone icon when there is no phone number */}
      {phoneNumberText && (
        <St.ScheduleRowHeaderPhoneNumber
          breakpoint={breakpoint}
          href={`tel:${hk.user?.mobilePhone}`}
        >
          <St.PhoneIcon icon={IconName.phone} size={16} />
          {phoneNumberText}
        </St.ScheduleRowHeaderPhoneNumber>
      )}

      {!isLoading && (
        <St.ScheduleHoursProgress
          breakpoint={breakpoint}
          hk={hk}
          hours={hours}
        />
      )}
    </St.StaffScheduleRowHeader>
  )
}
