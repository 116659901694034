import { ContainerHeight as TitlebarSizes } from 'app/hkhub/components/core/components/Titlebar/Titlebar.styles'

export type CardSize = 'tiny' | 'small' | 'medium' | 'large'
export const orderedCardSizes: CardSize[] = ['tiny', 'small', 'medium', 'large']

/* eslint-disable sort-keys */
export const staffCardSizeHeightMap: { [k in CardSize]: number } = {
  tiny: 18,
  small: 78,
  medium: 104,
  large: 108,
}

export const unitCardSizeHeightMap: { [k in CardSize]: number } = {
  tiny: 18,
  small: 98,
  medium: 104,
  large: 108,
}
/* eslint-enable sort-keys */

export const CARD_MARGIN_Y = 8

export const ScheduleSizes = {
  CardMarginTop: CARD_MARGIN_Y,
  FilterControlsMinHeight: 52,
  MinRowHeight: staffCardSizeHeightMap.medium + CARD_MARGIN_Y * 2,
  OffIconHeight: {
    LG: 24,
    SM: 22,
  },
  ReservationsGutterHeight: 24,
  RowHeaderHeight: 46,
  ScheduleControls: {
    LG: 76,
    SM: 44,
  },
  TimelineHeight: {
    LG: 70,
    SM: 50,
  },
  TitlebarHeight: TitlebarSizes,
}
