import { AxiosResponse } from 'axios'
import snakeCase from 'lodash/snakeCase'

import {
  NormalizedJSONApiResponse,
  RelationshipUpdate,
  ToManyRelationship,
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

import { JSONApiUsersMap, User } from 'app/hkhub/store/users/users.types'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type ZoneSearchResults = {
  user: JSONApiUsersMap
  zone: JSONApiZonesMap
}

export type ZonesState = {
  currentZones: string[]
  data: JSONApiZonesMap
  error?: Error
  isLoading: boolean
  searchResults: ZoneSearchResults
  user: JSONApiUsersMap
}

/** Type used for patching a Zone */
export type ZoneUpdate = {
  attributes: Partial<ZoneAttributes>
  id: string
  relationships?: { invoiceApproverUser?: string; managers?: string[] }
}

export enum ZonesActionTypes {
  ADD_HKS_TO_ZONE = 'ZONES/API/ADD_HKS_TO_ZONE',
  ADD_HKS_TO_ZONE_FAILURE = 'ZONES/ADD_HKS_TO_ZONE_FAILURE',
  ADD_HKS_TO_ZONE_SUCCESS = 'ZONES/ADD_HKI_TO_ZONE_SUCCESS',

  ADD_UNITS_TO_ZONE = 'ZONES/API/ADD_UNITS_TO_ZONE',
  ADD_UNITS_TO_ZONE_FAILURE = 'ZONES/ADD_UNITS_TO_ZONE_FAILURE',
  ADD_UNITS_TO_ZONE_SUCCESS = 'ZONES/ADD_UNITS_TO_ZONE_SUCCESS',

  CLEAR_ZONE = 'ZONES/CLEAR_ZONE',
  CREATE_ZONE = 'ZONES/API/CREATE_ZONE',
  CREATE_ZONE_FAILURE = 'ZONES/CREATE_ZONE_FAILURE',

  CREATE_ZONE_SUCCESS = 'ZONES/CREATE_ZONE_SUCCESS',
  FETCH_ZONE = 'ZONES/API/FETCH_ZONE',
  FETCH_ZONE_FAILURE = 'ZONES/FETCH_ZONE_FAILURE',

  FETCH_ZONE_SUCCESS = 'ZONES/FETCH_ZONE_SUCCESS',
  REMOVE_HKS_FROM_ZONE = 'ZONES/API/REMOVE_HKS_FROM_ZONE',
  REMOVE_HKS_FROM_ZONE_FAILURE = 'ZONES/REMOVE_HKS_FROM_ZONE_FAILURE',

  REMOVE_HKS_FROM_ZONE_SUCCESS = 'ZONES/REMOVE_HKS_FROM_ZONE_SUCCESS',
  REMOVE_UNITS_FROM_ZONE = 'ZONES/API/REMOVE_UNITS_FROM_ZONE',
  REMOVE_UNITS_FROM_ZONE_FAILURE = 'ZONES/REMOVE_UNITS_FROM_ZONE_FAILURE',

  REMOVE_UNITS_FROM_ZONE_SUCCESS = 'ZONES/REMOVE_UNITS_FROM_ZONE_SUCCESS',
  SEARCH_ZONES = 'ZONES/API/SEARCH_ZONES',
  SEARCH_ZONES_FAILURE = 'ZONES/SEARCH_ZONES_FAILURE',

  SEARCH_ZONES_SUCCESS = 'ZONES/SEARCH_ZONES_SUCCESS',
  SELECT_ZONE = 'ZONES/SELECT_ZONE',
  UPDATE_ZONE = 'ZONES/API/UPDATE_ZONE',

  UPDATE_ZONE_FAILURE = 'ZONES/UPDATE_ZONE_FAILURE',
  UPDATE_ZONE_SUCCESS = 'ZONES/UPDATE_ZONE_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
type ZonesResponse = {
  relationshipUpdate?: RelationshipUpdate
  user: JSONApiUsersMap
  zone: JSONApiZonesMap
}

export type NormalizedZonesApiResponse =
  NormalizedJSONApiResponse<ZonesResponse>
export type ZonesApiResponse = AxiosResponse<NormalizedZonesApiResponse>
export type ZonesServiceResponse = Promise<NormalizedZonesApiResponse>

/**********************************************************
 * ZONE
 *********************************************************/
export type Zone = {
  id: string
  invoiceApproverUser: null | User
  managers?: User[]
} & ZoneAttributes

export type ZoneAttributes = {
  isActive: boolean
  isLive: boolean
  name: string
  tz: string
}

export type ZoneRelationships = {
  invoiceApproverUser: ToOneRelationship
  managers?: ToManyRelationship
}

export const ZoneAttributeNames = ['isActive', 'isLive', 'name', 'tz']

export const ZoneApiFields = ZoneAttributeNames.map<string>(snakeCase)

export type RawZone = JSONApiObjectWithRelationships<
  ZoneAttributes,
  ZoneRelationships
>

export type JSONApiZonesMap = JSONApiObjectWithRelationshipsMap<
  ZoneAttributes,
  ZoneRelationships
>
