import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ReservationApiFields } from 'app/hkhub/store/reservations'

import { reservationsService } from '../reservations.service'
import {
  NormalizedReservationsApiResponse,
  ReservationsActionTypes,
} from '../reservations.types'

export const fetchReservationByIdAction = createAsyncAction(
  ReservationsActionTypes.FETCH_RESERVATION_BY_ID,
  ReservationsActionTypes.FETCH_RESERVATION_BY_ID_SUCCESS,
  ReservationsActionTypes.FETCH_RESERVATION_BY_ID_FAILURE,
)<
  RequestConfig<NormalizedReservationsApiResponse>,
  NormalizedReservationsApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    reservation: ReservationApiFields,
    unit: [],
  },
  include: ['unit'],
})

export const fetchReservationById = (resId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = reservationsService.fetchReservationById.bind(
      null,
      resId,
      params,
    )
    const result = await dispatch(
      fetchReservationByIdAction.request({ request }),
    )
    dispatch(fetchReservationByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchReservationByIdAction.failure(error))
    throw error
  }
}
