import React from 'react'

import { useBreakpoint } from 'packages/styles'

import { useCurrentCardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

import { useScheduleContext } from '../../common/VirtualizedSchedule/VirtualizedSchedule.context'
import { useFilteredUnits, useUnitReservations, useUnitVisits } from '../hooks'
import { useUnitTickets } from '../hooks/useUnitTickets/useUnitTickets'
import { VirtualizedUnitSchedule } from './VirtualizedUnitSchedule'

export const VirtualizedUnitScheduleContainer: React.FC = () => {
  const breakpoint = useBreakpoint()

  const { ticketBuckets } = useUnitTickets()
  const { filteredUnitIds, filteredUnitRows } = useFilteredUnits()
  const { isLoadingReservations, reservationBuckets } = useUnitReservations()
  const { visitBuckets } = useUnitVisits()
  const { isLoading } = useScheduleContext()

  return (
    <VirtualizedUnitSchedule
      breakpoint={breakpoint}
      cardSize={useCurrentCardSize()}
      isLoadingReservations={isLoading || isLoadingReservations}
      reservationBuckets={reservationBuckets}
      unitDateTicketMap={ticketBuckets}
      unitDateVisitMap={visitBuckets}
      unitDateCleansMap={filteredUnitRows}
      unitIds={filteredUnitIds}
    />
  )
}
