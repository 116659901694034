import styled from '@emotion/styled'
import React from 'react'

import {
  addDays,
  isAfter,
  isBefore,
  isSameDay,
} from 'packages/utils/dateHelpers'

import {
  cardNotTiny,
  ScheduleSizes,
  useCurrentCardSize,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Reservation } from 'app/hkhub/store/reservations/reservations.types'

import { EcdBar, ResBar } from './ResBar'

const resHasGuests = ({ checkIn, checkOut }, date) =>
  (isAfter(date, checkIn) && isBefore(date, checkOut)) || // guest stay-over
  isSameDay(date, checkIn) || // guest checking in
  isSameDay(date, checkOut) // guest checking out

const dateHasEcd = (res: Reservation, date: Date) => {
  const { checkOut, extraCleaningDays } = res
  if (!extraCleaningDays) return false

  const ecdEnd = addDays(checkOut, extraCleaningDays)
  return isBefore(date, ecdEnd) || isSameDay(date, ecdEnd)
}

const getReservationsForDate = (reservations: Reservation[], date: Date) => {
  return reservations.filter(res => {
    return resHasGuests(res, date) || dateHasEcd(res, date)
  })
}

const St = {
  ReservationGutter: styled.span`
    display: grid;
    grid-template-columns: 45% auto 45%;
    height: ${ScheduleSizes.ReservationsGutterHeight}px;
  `,
}

export type ReservationsGutterProps = {
  date: Date
  reservations: Reservation[]
}

export const ReservationsGutter: React.FC<ReservationsGutterProps> = ({
  date,
  reservations,
}) => {
  const resForDate = getReservationsForDate(reservations, date)
  const size = useCurrentCardSize()

  return cardNotTiny(size) ? (
    <St.ReservationGutter>
      {resForDate.map(res => (
        <React.Fragment key={res.id}>
          <ResBar date={date} reservation={res} />
          <EcdBar date={date} reservation={res} />
        </React.Fragment>
      ))}
    </St.ReservationGutter>
  ) : null
}
