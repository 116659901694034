/* eslint-disable sort-keys */
import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  NormalizedUnitsApiResponse,
  UnitsApiResponse,
  UnitsServiceResponse,
} from './units.types'

const URL = '/units'
const URL_PREFS = '/housekeeper_unit_preferences'

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyUnitsResponse: NormalizedUnitsApiResponse = Object.freeze({
  normalized: {
    housekeeperUnitPreference: {},
    unit: {},
  },
  raw: { data: [] },
})

export const unitsService = {
  /**
   * Performs a GET request for all Units
   * @param params
   */
  async fetchUnits(params: RequestOptions = {}): UnitsServiceResponse {
    const response: UnitsApiResponse = await api.get(`${URL}`, { params })
    return get(response, 'data', emptyUnitsResponse)
  },

  /**
   * Performs a GET request for a Unit with the specified ID
   * @param id
   * @param params
   */
  async fetchUnitById(
    id: string,
    params: RequestOptions = {},
  ): UnitsServiceResponse {
    const response: UnitsApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return get(response, 'data', emptyUnitsResponse)
  },

  /**
   * Performs a POST request create a new UnitPreferences record
   * @param requestData
   * @param params
   */
  async createUnitPreference(
    requestData: RequestOptions,
    params: RequestOptions = {},
  ): UnitsServiceResponse {
    const response: UnitsApiResponse = await api.post(
      `${URL_PREFS}`,
      requestData,
      {
        params,
      },
    )
    return get(response, 'data', emptyUnitsResponse)
  },

  /**
   * Performs a GET request to fetch a UnitPreferences record by the specified ID
   * @param id
   * @param params
   */
  async fetchUnitPreferenceById(
    id: string,
    params: RequestOptions = {},
  ): UnitsServiceResponse {
    const response: UnitsApiResponse = await api.get(`${URL_PREFS}/${id}`, {
      params,
    })
    return get(response, 'data', emptyUnitsResponse)
  },

  /**
   * Performs a DELETE request to fetch a UnitPreferences record by the specified ID
   * @param id
   * @param params
   */
  async removeUnitPreferenceById(
    id: string,
    params: RequestOptions = {},
  ): UnitsServiceResponse {
    const response: UnitsApiResponse = await api.delete(`${URL_PREFS}/${id}`, {
      params,
    })
    return get(response, 'data', emptyUnitsResponse)
  },
}
