/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { jwtDecode } from 'jwt-decode'

import { AuthTokenData } from 'packages/optimizely'

import { getAnalytics } from '../providers/segment'

export const hasValidTokenAndNoAnalyticsUser = getAuthToken => postStore => {
  try {
    const token = getAuthToken(postStore)

    const currentAnalyticsUserId = getAnalytics()?.user()?.id()

    return !!token && !currentAnalyticsUserId
  } catch (e) {
    // If we get here, we won't be able to track, so we should just abort the identification process
    return false
  }
}

export const getUserData = getAuthToken => store => {
  const token = getAuthToken(store)
  const { params, email, sub } = jwtDecode<AuthTokenData>(token)
  let id = params?.monolith_user_id

  // If there is not a user id, fallback to the onelogin userid
  if (!id) id = `external_${sub}`

  return { email, id }
}
