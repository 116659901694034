import styled from '@emotion/styled'
import React from 'react'

import { TaskServiceType } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'
import { Visit } from 'app/hkhub/store/visits'

const St = {
  Title: styled.span`
    align-items: center;
    display: flex;
    grid-gap: 4px;
  `,
  VisitDrawerHeader: styled.div<{ visit: Visit }>`
    ${text.bodyBoldTiny};
    align-items: center;
    background-color: ${colors.tetons30};
    color: ${colors.dusk};
    display: flex;
    height: 40px;
    justify-content: space-between;
    left: 0;
    padding: 0 8px 0 8px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    width: 100%;
  `,
  VisitDrawerHeaderCloseIcon: styled(SvgIcon)`
    color: ${colors.dusk};
  `,
}

export enum VisitDrawerHeaderTestIds {
  closeIcon = 'VisitDrawerHeader__closeIcon',
  container = 'VisitDrawerHeader__container',
}

export type VisitDrawerHeaderProps = {
  forceCloseDrawer: () => void
  visit: Visit
}

export const VisitDrawerHeader: React.FC<VisitDrawerHeaderProps> = React.memo(
  ({ forceCloseDrawer, visit }) => {
    const { t } = useI18n()
    return (
      <St.VisitDrawerHeader
        data-testid={VisitDrawerHeaderTestIds.container}
        visit={visit}
      >
        <St.Title>
          {t(Slugs.visit)}
          {visit.serviceType === TaskServiceType.scheduled && (
            <SvgIcon icon={IconName.refreshCw} />
          )}
        </St.Title>
        <St.VisitDrawerHeaderCloseIcon
          dataTestId={VisitDrawerHeaderTestIds.closeIcon}
          icon={IconName.x}
          onClick={forceCloseDrawer}
          size={22}
        />
      </St.VisitDrawerHeader>
    )
  },
)
