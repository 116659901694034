import React from 'react'

import { createDateObject } from 'packages/utils/dateHelpers'
import { splitSearchQuery } from 'packages/utils/misc'

import {
  ScheduleEntityType,
  ScheduleNavigationMatchParams,
  ScheduleNavigationSearchParams,
  ScheduleUrlSearchParams,
} from 'app/hkhub/components/schedule/schedule.types'

export const DEFAULT_DAYS = 7
export const MAX_DAYS = 14

const parseDays = (daysParam: string): number => {
  const daysInt = parseInt(daysParam)
  if (!daysInt) return DEFAULT_DAYS
  if (daysInt > MAX_DAYS) return DEFAULT_DAYS
  if (daysInt < 1) return DEFAULT_DAYS
  return daysInt
}

export type UseScheduleMatchParams = ScheduleNavigationMatchParams &
  ScheduleNavigationSearchParams

export const useScheduleMatchParams = (): UseScheduleMatchParams => {
  const { date: dateQuery, days } = splitSearchQuery(
    window.location.search,
  ) as ScheduleUrlSearchParams

  const date = React.useMemo(() => {
    return createDateObject(dateQuery)
  }, [dateQuery])

  const dayCount = parseDays(days)

  const entity: ScheduleEntityType = window.location.pathname.match(/unit/i)
    ? 'unit'
    : 'staff'

  return {
    date,
    dayCount,
    entity,
  }
}
