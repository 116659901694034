import { createSlice } from '@reduxjs/toolkit'

import { fetchCoverageByZoneAndDateRange } from './actions'
import { CoverageState } from './coverage.types'
import { emptyNormalizedCoverageData } from './coverage.utils'

const initialState: CoverageState = {
  data: {},
  isStale: false,
}

export const coverageSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(
      fetchCoverageByZoneAndDateRange.fulfilled,
      (state, action) => {
        const normalized =
          action.payload.normalized.coverage ||
          emptyNormalizedCoverageData.coverage
        state.data = normalized
        state.isStale = false
      },
    )
  },
  initialState,
  name: 'coverage',
  reducers: {},
})

export default coverageSlice.reducer
