/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { User } from 'packages/grimoire'
import { logInfo } from 'packages/wiretap/logging'

import { addHours, createDateObject } from '../dateHelpers'

class PseudoStorage implements Storage {
  constructor() {
    this.storage = {}
    this.length = 0
  }

  public length: number

  private storage: Record<string, string>

  public setItem = (key: string, value: string): void => {
    this.storage[key] = value
  }

  public getItem = (key: string): string | null => {
    return this.storage[key] || null
  }

  public key = (index: number): string | null => {
    return Object.keys(this.storage)[index] || null
  }

  public removeItem = (key: string): void => {
    delete this.storage[key]
  }

  public clear = (): void => {
    this.storage = {}
  }
}

const pseudoStorage = new PseudoStorage()

export const storage = (() => {
  let storage: Storage

  try {
    localStorage.setItem('test', 'test')
    localStorage.removeItem('test')
    storage = localStorage
  } catch {
    try {
      sessionStorage.setItem('test', 'test')
      sessionStorage.removeItem('test')
      storage = sessionStorage
    } catch {
      logInfo('Client using pseudo storage')
      storage = pseudoStorage
    }
  }

  return storage
})()

export const CACHE_KEY_ACTIVE_USER = 'activeUser'
export const CACHE_KEY_AUTH_USER = 'authUser'

const getAuthUser = () => {
  const userInLS = storage.getItem(CACHE_KEY_AUTH_USER)
  return userInLS ? JSON.parse(userInLS) : undefined
}

export const getActiveUser = () => {
  const userInLS = storage.getItem(CACHE_KEY_ACTIVE_USER)
  return userInLS ? JSON.parse(userInLS) : undefined
}

export const getActiveUserId = (): string | undefined => {
  const user = getActiveUser()
  return user ? user?.id : undefined
}

export const getUserLogsContext = (): Partial<User> => {
  const user = getAuthUser()
  return {
    id: user?.id,
    monolithUserId: user?.attributes?.monolithUserId,
  }
}

export const getAuthUserId = (): string | undefined => {
  const user = getAuthUser()
  return user ? user?.id : undefined
}

export const setAuthUser = (user): void =>
  storage.setItem(CACHE_KEY_AUTH_USER, JSON.stringify(user))

export const setActiveUser = (user): void =>
  storage.setItem(CACHE_KEY_ACTIVE_USER, JSON.stringify(user))

export const setImpersonationToken = (token: string): void =>
  storage.setItem(
    'impersonationToken',
    JSON.stringify({
      expiration: addHours(createDateObject(), 1).getTime(),
      token,
    }),
  )

export const removeImpersonationToken = (): void =>
  storage.removeItem('impersonationToken')

export const getImpersonationToken = (): string | undefined => {
  const raw = storage.getItem('impersonationToken')
  const parsed = raw ? JSON.parse(raw) : undefined

  if (!parsed) {
    return undefined
  }

  if (
    !parsed.expiration ||
    typeof parsed.expiration !== 'number' ||
    !parsed.token ||
    typeof parsed.token !== 'string' ||
    parsed.expiration < createDateObject().getTime()
  ) {
    storage.removeItem('impersonationToken')
    return undefined
  }

  return parsed.token
}
