import styled from '@emotion/styled'

import { colors } from 'packages/styles'

export const FormStyles = {
  Form: styled.form`
    position: relative;
  `,
  TextArea: styled.textarea`
    border-radius: 4px;
    border: 1px solid ${colors.midnight30};
    padding: 8px 16px;
    width: 100%;

    label + & {
      margin-top: 4px;
    }
  `,
  TextAreaLabel: styled.label`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  `,
}
