export const shadows = {
  shadow1:
    '0 5px 15px -6px rgba(0, 51, 73, 0.15), 0 5px 15px -6px rgba(44, 52, 57, 0.15)',
  shadow2:
    '0 10px 20px -6px rgba(0, 51, 73, 0.15), 0 10px 20px -6px rgba(44, 52, 57, 0.15)',
  shadow3:
    '0 10px 30px -6px rgba(0, 51, 73, 0.2), 0 10px 30px -6px rgba(44, 52, 57, 0.2)',
  shadow4:
    '0 15px 38px -6px rgba(0, 51, 73, 0.2), 0 15px 38px -6px rgba(44, 52, 57, 0.2)',
}
