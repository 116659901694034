import styled from '@emotion/styled'
import React from 'react'

import { Alert, ExternalLink } from 'packages/common'
import { IconName } from 'packages/iconic'

import { UpNextConfig } from 'app/hkhub/components/schedule/helpers/deepCleans.helpers'
import { Unit } from 'app/hkhub/store/units'

import { DeepCleanSkipFormContainer } from '../../../DeepCleanSkipForm'
import { UnitDeepCleanStatistics } from '../../../UnitDeepCleanStatistics'
import { UnitDrawerStyles } from '../../UnitDrawer.styles'
import { DeepCleansStrings } from './DeepCleansSection.container'
import { getLastDateString } from './DeepCleansSection.helpers'

const St = {
  DeepCleanStatsSectionWrapper: styled(UnitDrawerStyles.SectionContent)`
    padding-left: 0;
    margin-top: 16px;
  `,
  Divider: UnitDrawerStyles.Divider,
  ExternalLink: styled(ExternalLink)`
    display: flex;
    margin-top: 4px;
  `,
  Icon: UnitDrawerStyles.Icon,
  Section: UnitDrawerStyles.Section,
  SectionContent: styled(UnitDrawerStyles.SectionContent)`
    margin-top: 16px;
  `,
  SubTitle: UnitDrawerStyles.SectionSubTitle,
  Title: UnitDrawerStyles.SectionTitle,
  UpNextAlert: styled(Alert)`
    margin-bottom: 8px;
  `,
}

export type DeepCleansSectionProps = {
  strings: DeepCleansStrings
  unit: Unit
  upNextConfig: UpNextConfig | null
}

export const DeepCleansSection: React.FC<DeepCleansSectionProps> = React.memo(
  ({ strings, unit, upNextConfig }) => {
    const canSkipDeepClean = upNextConfig && upNextConfig.alertType !== 'info'

    return (
      <>
        <St.Section>
          <St.Title>{strings.title}</St.Title>

          {/* Up Next */}
          <St.SubTitle>
            <St.Icon icon={IconName.calendarClean} size={16} />
            <strong>{strings.upNext}</strong>
          </St.SubTitle>

          <St.SectionContent>
            {upNextConfig ? (
              <>
                <St.UpNextAlert alertType={upNextConfig.alertType}>
                  {upNextConfig.alertText}
                </St.UpNextAlert>
                <div>{upNextConfig.subText}</div>

                {canSkipDeepClean && (
                  <DeepCleanSkipFormContainer unitId={unit.id} />
                )}
              </>
            ) : (
              <span>{strings.noActivity}</span>
            )}
          </St.SectionContent>

          {/* Last Completed */}
          <St.SubTitle>
            <St.Icon icon={IconName.calendarCheck} size={16} />
            <strong>{strings.lastCompleted}</strong>
          </St.SubTitle>

          <St.SectionContent>
            {unit.lastDeepCleanedAt
              ? getLastDateString(unit.lastDeepCleanedAt)
              : strings.noActivity}
          </St.SectionContent>

          {unit.statistics?.deepCleanStats && (
            <St.DeepCleanStatsSectionWrapper>
              <UnitDeepCleanStatistics
                deepCleanStats={unit.statistics.deepCleanStats}
                unitLastDeepCleanAt={unit.lastDeepCleanedAt}
              />
            </St.DeepCleanStatsSectionWrapper>
          )}
        </St.Section>

        <St.Divider />
      </>
    )
  },
)
