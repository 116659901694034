import { connect } from 'react-redux'

import { RelationshipUpdate } from 'packages/utils/store/jsonapi.types'

import {
  addBorrowedZoneToHk,
  clearHkSearchResults,
  fetchHousekeepersByZone,
  removeBorrowedZoneFromHk,
  searchHousekeepers,
  updateHkInSearchResults,
} from 'app/hkhub/store/housekeepers/actions'
import {
  getAgencyHks,
  getBorrowedHks,
  getHkSearchResults,
  getPrimaryHks,
} from 'app/hkhub/store/housekeepers/selectors'
import {
  dismissZoneNotification,
  fetchZoneNotificationsWithAddHks,
  fetchAllZoneNotifications,
} from 'app/hkhub/store/notifications/actions'
import notificationCodes from 'app/hkhub/store/notifications/notifications.codes'
import {
  getAddHkZoneNotifications,
  getNotificationCountByType,
} from 'app/hkhub/store/notifications/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import {
  createTicketingAssignmentForDisposition,
  fetchTicketingAssignmentById,
  fetchTicketingAssignments,
  fetchTicketingDispositions,
  updateTicketingAssignment,
} from 'app/hkhub/store/ticketing/actions'
import {
  getAssignmentsKeyedByDispositionIds,
  getDispositionsBucketedByTier,
} from 'app/hkhub/store/ticketing/selectors'
import {
  CreateAssignmentOptions,
  UpdateAssignmentsOptions,
} from 'app/hkhub/store/ticketing/ticketing.types'
import {
  clearUnitSearchResults,
  fetchUnitsByZone,
  searchUnits,
} from 'app/hkhub/store/units/actions'
import { getUnits, getUnitSearchResults } from 'app/hkhub/store/units/selectors'
import { searchUsers } from 'app/hkhub/store/users/actions'
import { getUsersSearchResults } from 'app/hkhub/store/users/selectors'
import {
  addHksToZone,
  addUnitsToZone,
  fetchZoneById,
  removeHksFromZone,
  removeUnitsFromZone,
  updateZone,
} from 'app/hkhub/store/zones/actions'
import { getZoneError } from 'app/hkhub/store/zones/selectors'
import { ZoneUpdate } from 'app/hkhub/store/zones/zones.types'

import ZoneManagePage from '../ZoneManagePage/ZoneManagePage'

const { SUGGESTED_HK } = notificationCodes

const mapStateToProps = (state: ApplicationState) => {
  return {
    getAddHKNotificationCount: () =>
      getNotificationCountByType(state, SUGGESTED_HK),
    getAgencyHks: () => getAgencyHks(state),
    getAllAddHkNotifications: () => getAddHkZoneNotifications(state),
    getAssignmentsKeyedByDispositionIds: () =>
      getAssignmentsKeyedByDispositionIds(state),
    getBorrowedHks: () => getBorrowedHks(state),
    getDispositionsBucketedByTier: () => getDispositionsBucketedByTier(state),
    getHkSearchResults: () => getHkSearchResults(state),
    getPrimaryHks: () => getPrimaryHks(state),
    getUnits: () => getUnits(state.units),
    getUnitSearchResults: () => getUnitSearchResults(state),
    getUsersSearchResults: () => getUsersSearchResults(state),
    getZoneError: () => getZoneError(state),
  }
}

const mapDispatchToProps = dispatch => ({
  addBorrowedZoneToHk: (requestData: RelationshipUpdate) =>
    dispatch(addBorrowedZoneToHk(requestData)),
  addHksToZone: (requestData: RelationshipUpdate) =>
    dispatch(addHksToZone(requestData)),
  addUnitsToZone: (requestData: RelationshipUpdate) =>
    dispatch(addUnitsToZone(requestData)),
  clearHkSearchResults: () => dispatch(clearHkSearchResults()),
  clearUnitSearchResults: () => dispatch(clearUnitSearchResults()),
  createTicketingAssignmentForDisposition: (options: CreateAssignmentOptions) =>
    dispatch(createTicketingAssignmentForDisposition(options)),
  dismissZoneNotification: id => dispatch(dismissZoneNotification(id)),
  fetchAllZoneNotifications: zoneId =>
    dispatch(fetchAllZoneNotifications(zoneId)),
  fetchHousekeepersByZone: zoneId => dispatch(fetchHousekeepersByZone(zoneId)),
  fetchTicketingAssignmentById: id =>
    dispatch(fetchTicketingAssignmentById(id)),
  fetchTicketingAssignments: zoneId =>
    dispatch(fetchTicketingAssignments(zoneId)),
  fetchTicketingDispositions: () => dispatch(fetchTicketingDispositions()),
  fetchUnitsByZone: zoneId => dispatch(fetchUnitsByZone(zoneId)),
  fetchZoneById: zoneId => dispatch(fetchZoneById(zoneId)),
  fetchZoneNotificationsWithAddHks: zoneId =>
    dispatch(fetchZoneNotificationsWithAddHks(zoneId)),
  removeBorrowedZoneFromHk: (requestData: RelationshipUpdate) =>
    dispatch(removeBorrowedZoneFromHk(requestData)),
  removeHksFromZone: (requestData: RelationshipUpdate) =>
    dispatch(removeHksFromZone(requestData)),
  removeUnitsFromZone: (requestData: RelationshipUpdate) =>
    dispatch(removeUnitsFromZone(requestData)),
  searchHousekeepers: (searchString: string) =>
    dispatch(searchHousekeepers(searchString)),
  searchUnits: (searchString: string) => dispatch(searchUnits(searchString)),
  searchUsers: (searchString: string) => dispatch(searchUsers(searchString)),
  updateHkInSearchResults: (id: string) =>
    dispatch(updateHkInSearchResults(id)),
  updateTicketingAssignment: (options: UpdateAssignmentsOptions) =>
    dispatch(updateTicketingAssignment(options)),
  updateZone: (zoneUpdate: ZoneUpdate) => dispatch(updateZone(zoneUpdate)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZoneManagePage)
