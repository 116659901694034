import { createReduxEnhancer } from '@sentry/react'
import { pipe } from 'lodash/fp'

type Payload = Record<string, unknown>

type Action = {
  payload: Payload
  type: string
}

export function cleanJsonApiAttributes(payload: unknown): Payload | unknown {
  // only clean objects that are actually objects
  if (payload && payload instanceof Object && payload.constructor === Object) {
    const clean = {}

    Object.entries(payload)
      .filter(([key, _]) => key !== 'attributes')
      .forEach(([key, value]) => {
        clean[key] = cleanJsonApiAttributes(value)
      })

    return clean
  }

  return payload
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
type StateWithAuth = { auth: { refreshToken: any; token: any } & any }

export const cleanTokens = (state: StateWithAuth): StateWithAuth => ({
  ...state,
  auth: {
    ...state.auth,
    refreshToken: null,
    token: null,
  },
})

export function actionTransformer(action: Action): Action {
  const { payload } = action

  if (payload?.normalized && payload?.raw) {
    return {
      ...action,
      payload: cleanJsonApiAttributes(payload) as Payload,
    }
  }

  return action
}

const stateTransformer = pipe(cleanJsonApiAttributes, cleanTokens)

export const sentryReduxEnhancer = createReduxEnhancer({
  actionTransformer,
  stateTransformer,
})
