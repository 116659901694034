import { deprecated } from 'typesafe-actions'

import { VisitDrawerState, UiActionTypes } from '../ui.types'

const { createStandardAction } = deprecated

export const setVisitDrawerStateAction = createStandardAction(
  UiActionTypes.SET_VISIT_DRAWER_STATE,
)<Partial<VisitDrawerState>>()

export const setVisitDrawerState =
  (newState: Partial<VisitDrawerState>) => dispatch => {
    dispatch(setVisitDrawerStateAction(newState))
  }
