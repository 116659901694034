import {
  JsonApiError,
  JsonApiErrorCodes,
  JsonApiErrorResponse,
  ToOneRelationship,
} from './jsonapi.types'

/* eslint-disable @typescript-eslint/ban-types */
const queryFormat = (
  value: object | string[] | number,
  key: string,
): string => {
  const encodedKey = encodeURIComponent(key as unknown as string)
  if (Array.isArray(value)) {
    return `${encodedKey}=${encodeURIComponent(value.join(','))}`
  }

  if (typeof value === 'object') {
    return parseQuery(value, key) /* eslint-disable-line */
  }

  return `${encodedKey}=${encodeURIComponent(value as unknown as string)}`
}

export const parseQuery = (params: object = {}, prefix?: string): string => {
  const encodedParams = Object.keys(params)
    // eslint-disable-next-line no-prototype-builtins
    .filter(p => params.hasOwnProperty(p))
    .map(param =>
      queryFormat(params[param], prefix ? `${prefix}[${param}]` : param),
    )
    .join('&')

  return encodedParams
}

/**
 * @deprecated
 * It is probably better to use the more flexible 'makeRelationship()' helper below.
 * This fn does not allow renaming the relationship, and also requires
 * spreading at its destination, so in some cases it will not be flexible enough and/or
 * TS might have trouble accepting that you have typed something correctly.
 *
 * Builds a key/value pair for the data required to specify a relationship
 * on a JSON API request.
 *
 * Note that this returns a full object, so you will most likely want to spread
 * the result into your local "relationships" object.
 *
 * @param type The name of the relationship to set
 * @param id The ID of the "other" object in the relationship
 */
export const setRequestRelationship = (
  type: string,
  id: string,
): Record<string, unknown> => ({
  [type]: { data: { id, type } },
})

/**
 * A very simple helper to build the data structure required for specifying relationships
 * in a JSON:API POST/PATCH request.
 * @param type
 * @param id
 */
export const makeRelationship = (
  type: string,
  id: string,
): ToOneRelationship => ({ data: { id, type } })

export const hasErrorCode = (
  code: JsonApiErrorCodes,
  error: JsonApiErrorResponse,
): boolean =>
  error.response?.data?.errors?.find(
    (err: JsonApiError) => err.code === code,
  ) !== undefined

export const getErrorDetail = (err: JsonApiErrorResponse): string | undefined =>
  err?.response?.data?.errors?.[0]?.detail
