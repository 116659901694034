import startCase from 'lodash/startCase'
import * as React from 'react'

import { Button, useToast } from 'packages/common'

import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { ZoneUpdate } from 'app/hkhub/store/zones/zones.types'

import { ZoneManageReduxProps } from '../../manage.types'
import EditZoneModal from './EditZoneModal'
import {
  EditZoneModalActionsTypes,
  editZoneModalReducer,
  initialState,
  shouldAllowSave,
  handlePopulateModal,
} from './EditZoneModal.helpers'

import styles from './EditZoneModal.module.scss'

export type EditZoneModalWrapperProps = ZoneManageReduxProps

const EditZoneModalWrapper: React.FC<EditZoneModalWrapperProps> = ({
  getUsersSearchResults,
  getZoneError,
  fetchAllZoneNotifications,
  fetchZoneById,
  searchUsers,
  updateZone,
}) => {
  const { t } = useI18n()
  const { zone } = useZoneContext()
  const { showToast } = useToast()

  const [state, dispatch] = React.useReducer(editZoneModalReducer, initialState)

  const { apiError, currentManagersDisplayed, isSearchableLength, zoneName } =
    state

  const getZoneErrorRef = React.useRef(getZoneError)
  getZoneErrorRef.current = getZoneError

  const [displayState, setDisplayState] = React.useState({
    loading: false,
    modalShowing: false,
  })

  const [isValid, setIsValid] = React.useState(false)

  React.useEffect(() => {
    handlePopulateModal(dispatch, zone)
  }, [zone, displayState.modalShowing])

  React.useEffect(() => {
    if (
      shouldAllowSave({
        currentManagersDisplayed,
        zone,
        zoneName,
      }) !== isValid
    ) {
      setIsValid(!isValid)
    }
  }, [currentManagersDisplayed, isValid, zoneName, zone])

  /**
   * Search for Users if searchString is larger than 2 chars, and
   *  set `isSearchableLength` to determine AsyncUserSelector CSS
   */
  const searchForUsers = React.useCallback(
    async (searchString: string) => {
      if (searchString.length >= 3) {
        await searchUsers(searchString)
        dispatch({
          isSearchableLength: true,
          type: EditZoneModalActionsTypes.SetIsSearchable,
        })
      } else {
        dispatch({
          isSearchableLength: false,
          type: EditZoneModalActionsTypes.SetIsSearchable,
        })
      }
    },
    [searchUsers],
  )

  const setUser = React.useCallback(
    (newManager, managerIndex) =>
      dispatch({
        managerIndex,
        newManager,
        type: EditZoneModalActionsTypes.ChangeManagerAtIndex,
      }),
    [],
  )

  /**
   * Append a null value to the list of current managers.
   *  This will render a new empty AsyncUserSelect
   */
  const appendNullTocurrentManagersDisplayed = React.useCallback(() => {
    dispatch({ type: EditZoneModalActionsTypes.AppendNullToDisplayedManagers })
  }, [])

  const handleZoneNameChange = React.useCallback((value: string) => {
    dispatch({ type: EditZoneModalActionsTypes.SetZoneName, zoneName: value })
  }, [])

  const handleSaveZone = React.useCallback(async (): Promise<
    Record<string, unknown>
  > => {
    setDisplayState({ ...displayState, loading: true })

    const updatedZone: ZoneUpdate = {
      attributes: {
        isActive: zone.isActive,
        isLive: zone.isLive,
        name: zoneName,
        tz: zone.tz,
      },
      id: zone.id,
      relationships: {
        managers: currentManagersDisplayed.reduce(
          (acc, manager) => (manager ? acc.concat(manager.id) : acc),
          [] as string[],
        ),
      },
    }

    try {
      await updateZone(updatedZone)
      showToast({ message: t(Slugs.zoneUpdated) })
      await fetchZoneById(zone.id)
      fetchAllZoneNotifications(zone.id)
      return Promise.resolve({})
    } catch (e) {
      const error = getZoneErrorRef.current()
      dispatch({
        error: error,
        type: EditZoneModalActionsTypes.SetApiError,
      })

      setDisplayState({ ...displayState, loading: false })
      return Promise.reject({})
    }
  }, [
    currentManagersDisplayed,
    displayState,
    fetchAllZoneNotifications,
    fetchZoneById,
    showToast,
    t,
    updateZone,
    zone.id,
    zone.isActive,
    zone.isLive,
    zone.tz,
    zoneName,
  ])

  const handleRemoveManager = React.useCallback(idx => {
    dispatch({
      managerIndex: idx,
      type: EditZoneModalActionsTypes.RemoveManagerAtIndex,
    })
  }, [])

  const handleCancelChanges = React.useCallback(() => {
    dispatch({
      managers: zone.managers,
      type: EditZoneModalActionsTypes.SetDisplayedManagers,
    })

    setDisplayState({ ...displayState, modalShowing: false })
  }, [displayState, zone.managers])

  return (
    <>
      <Button
        onClick={() => setDisplayState({ ...displayState, modalShowing: true })}
        className={styles.edit}
        buttonType={'text'}
      >
        <span>{startCase(t(Slugs.edit))}</span>
      </Button>
      <EditZoneModal
        allowSave={isValid}
        apiError={apiError}
        currentManagers={currentManagersDisplayed}
        getUsersSearchResults={getUsersSearchResults}
        isSearchableLength={isSearchableLength}
        loading={displayState.loading}
        maxWidth={390}
        onAddNewManager={appendNullTocurrentManagersDisplayed}
        onModalClose={handleCancelChanges}
        onRemoveManager={handleRemoveManager}
        onSaveZone={handleSaveZone}
        onUserSelectionChange={setUser}
        onZoneNameChange={handleZoneNameChange}
        searchUsers={searchForUsers}
        showing={displayState.modalShowing}
        zone={zone}
        zoneName={zoneName}
      />
    </>
  )
}

export default React.memo(EditZoneModalWrapper)
