import get from 'lodash/get' // eslint-disable-line
import mapValues from 'lodash/mapValues'
import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from 'app/hkhub/store/store'
import { transformNormalizedToZone } from 'app/hkhub/store/zones/utils/transformNormalizedToZone'

import { Unit, UnitAttributeNames } from '../units.types'

const sortByUnitCode = (a, b) =>
  a.attributes.unitCode > b.attributes.unitCode ? 1 : -1

const getRawUnitSearchResultUnitValues = (state: ApplicationState) =>
  Object.values(state.units.searchResults.unit)

const getRawUnitSearchResultZones = (state: ApplicationState) =>
  state.units.searchResults.zone

const getCurrentZoneIds = (state: ApplicationState) => state.zones.currentZones

const getZoneIdFromUnit = unit => get(unit, 'relationships.zone.data.id')

function attachZone(unit, searchResultsZone) {
  return {
    ...unit,
    zone: get(searchResultsZone, getZoneIdFromUnit(unit), null),
  }
}

/**
 * Strips the zone from a unit, normalizes the unit and zone, then re-appends the zone to the unit
 * @param unit
 */
function normalizeUnitWithZone(unit) {
  const { zone, ...unitWithNoZone } = unit
  const normalizedUnitWithoutZone = transformNormalizedToTyped<Unit>(
    unitWithNoZone,
    UnitAttributeNames,
  )
  const normalizedZone = zone ? transformNormalizedToZone(zone) : zone
  return {
    ...normalizedUnitWithoutZone,
    zone: normalizedZone,
  }
}

/**
 * Returns the current Unit search results (if any), as an array
 *
 */
export const getUnitSearchResults = createSelector(
  getRawUnitSearchResultUnitValues,
  getRawUnitSearchResultZones,
  getCurrentZoneIds,
  (searchUnitsArray, searchZones, currentZoneIds) => {
    const emptyBuckets = {
      currentZone: [],
      noZone: [],
      otherZone: [],
    }

    const bucketedUnits = searchUnitsArray.reduce((acc, unit) => {
      const unitZoneId = getZoneIdFromUnit(unit)
      let bucketName = 'noZone'
      if (unitZoneId) {
        bucketName = currentZoneIds.includes(unitZoneId)
          ? 'currentZone'
          : 'otherZone'
      }

      return {
        ...acc,
        [bucketName]: [...acc[bucketName], unit],
      }
    }, emptyBuckets)

    mapValues(bucketedUnits, bucket => bucket.sort(sortByUnitCode))

    const { currentZone, noZone, otherZone } = bucketedUnits

    const sortedUnits = [...noZone, ...otherZone, ...currentZone]

    return sortedUnits
      .map(unit => attachZone(unit, searchZones))
      .map(normalizeUnitWithZone)
  },
)
