import { AxiosResponse } from 'axios'
import { difference, snakeCase } from 'lodash/fp'

import {
  AllUnitAttributeNames,
  AllUnitAttributes,
  JSONApiUnitOncallUsersMap,
  UnitOnCallAssignmentReason,
} from 'packages/grimoire'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToManyRelationship,
  ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

import { JSONApiHousekeepersMap } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { JSONApiUsersMap, User } from 'app/hkhub/store/users/users.types'
import { JSONApiZonesMap } from 'app/hkhub/store/zones/zones.types'

import { JSONApiCleanMap } from '../cleans'
import { JSONApiCustomInspectionItemMap } from '../customInspectionItems/customInspectionItems.types'
import { JSONApiLockboxesMap } from '../lockboxes'
import { JSONApiTicketsMap } from '../tickets'

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
export type UnitByIdMap = {
  [unitId: string]: Unit
}

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type UnitsState = {
  data: JSONApiUnitMap
  error?: Error
  housekeeperPreferences: JSONApiUnitPreferenceMap
  isLoading: boolean
  oncallUsers: JSONApiUnitOncallUsersMap
  searchResults: UnitsSearchResults
}

export type UnitsSearchResults = {
  unit: JSONAPIUnitSearchMap
  zone: JSONApiZonesMap
}

export type UnitOncallUser = {
  firstName: string
  lastName: string
  mobilePhone: string
  reasonForAssignment: UnitOnCallAssignmentReason
}

export const UnitOncallUserAttributeNames: string[] = [
  'first_name',
  'last_name',
  'mobile_phone',
  'reason_for_assignment',
]

export const UnitOncallUserApiFields =
  UnitOncallUserAttributeNames.map<string>(snakeCase)

export enum UnitsActionTypes {
  CLEAR_UNITS_SEARCH_RESULTS = 'UNITS/CLEAR_UNITS_SEARCH_RESULTS',

  CREATE_UNIT_PREFERENCE = 'UNITS/API/CREATE_UNIT_PREFERENCE',
  CREATE_UNIT_PREFERENCE_FAILURE = 'UNITS/CREATE_UNIT_PREFERENCE_FAILURE',
  CREATE_UNIT_PREFERENCE_SUCCESS = 'UNITS/CREATE_UNIT_PREFERENCE_SUCCESS',

  FETCH_UNITS_BY_ZONE = 'units/api/fetchUnitsByZone/pending',
  FETCH_UNITS_BY_ZONE_FAILURE = 'UNITS/FETCH_UNITS_BY_ZONE_FAILURE',
  FETCH_UNITS_BY_ZONE_SUCCESS = 'UNITS/FETCH_UNITS_BY_ZONE_SUCCESS',

  FETCH_UNIT_AND_INSPECTION_FLAGS_BY_ID = 'units/api/fetchUnitAndInspectionFlagsById/pending',
  FETCH_UNIT_AND_INSPECTION_FLAGS_BY_ID_FAILURE = 'UNITS/FETCH_UNIT_AND_INSPECTION_FLAGS_BY_ID_FAILURE',
  FETCH_UNIT_AND_INSPECTION_FLAGS_BY_ID_SUCCESS = 'UNITS/FETCH_UNIT_AND_INSPECTION_FLAGS_BY_ID_SUCCESS',

  FETCH_UNIT_BY_ID = 'UNITS/API/FETCH_UNIT_BY_ID',
  FETCH_UNIT_BY_ID_FAILURE = 'UNITS/FETCH_UNIT_BY_ID_FAILURE',
  FETCH_UNIT_BY_ID_STRICT = 'units/api/fetchUnitByIdStrict/pending',

  FETCH_UNIT_BY_ID_STRICT_FAILURE = 'UNITS/FETCH_UNIT_BY_ID_STRICT_FAILURE',
  FETCH_UNIT_BY_ID_STRICT_SUCCESS = 'UNITS/FETCH_UNIT_BY_ID_STRICT_SUCCESS',
  FETCH_UNIT_BY_ID_SUCCESS = 'UNITS/FETCH_UNIT_BY_ID_SUCCESS',

  REMOVE_UNIT_PREFERENCE = 'UNITS/API/REMOVE_UNIT_PREFERENCE',
  REMOVE_UNIT_PREFERENCE_FAILURE = 'UNITS/REMOVE_UNIT_PREFERENCE_FAILURE',
  REMOVE_UNIT_PREFERENCE_SUCCESS = 'UNITS/REMOVE_UNIT_PREFERENCE_SUCCESS',

  SEARCH_UNITS = 'UNITS/API/SEARCH_UNITS',
  SEARCH_UNITS_FAILURE = 'UNITS/SEARCH_UNITS_FAILURE',
  SEARCH_UNITS_SUCCESS = 'UNITS/SEARCH_UNITS_SUCCESS',

  UPDATE_UNIT_IN_SEARCH_RESULTS = 'units/api/updateUnitInSearchResults/pending',
  UPDATE_UNIT_IN_SEARCH_RESULTS_FAILURE = 'UNITS/UPDATE_UNIT_IN_SEARCH_RESULTS_FAILURE',
  UPDATE_UNIT_IN_SEARCH_RESULTS_SUCCESS = 'UNITS/UPDATE_UNIT_IN_SEARCH_RESULTS_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type UnitResponse = {
  clean?: JSONApiCleanMap
  customInspectionItem?: JSONApiCustomInspectionItemMap
  housekeeper?: JSONApiHousekeepersMap
  housekeeperUnitPreference?: JSONApiUnitPreferenceMap
  lockBox?: JSONApiLockboxesMap
  oncallUser?: JSONApiUnitOncallUsersMap
  ticket?: JSONApiTicketsMap
  unit: JSONApiUnitMap
  user?: JSONApiUsersMap
  zone?: JSONApiZonesMap
}

export type UnitSearchResponse = {
  unit: JSONAPIUnitSearchMap
}

export type NormalizedUnitsApiResponse = NormalizedJSONApiResponse<UnitResponse>
export type NormalizedUnitSearchApiResponse =
  NormalizedJSONApiResponse<UnitSearchResponse>
export type UnitsApiResponse = AxiosResponse<NormalizedUnitsApiResponse>
export type UnitsServiceResponse = Promise<NormalizedUnitsApiResponse>

/**********************************************************
 * UNIT PREFERENCES
 *********************************************************/
export type UnitPreference = {
  housekeeper: User
  id: string
} & UnitPreferenceAttributes

export type UnitPreferenceAttributes = {
  alias: UnitPreferenceKind
  effectiveDate: string
}

export const UnitPreferenceAttributeNames: string[] = ['alias', 'effectiveDate']

export const UnitPreferencesApiFields =
  UnitPreferenceAttributeNames.map<string>(snakeCase)

export type UnitPreferencesPostData = {
  alias: UnitPreferenceKind
  housekeeperId: string
  reason?: string
  unitId: string
}

export enum UnitPreferenceKind {
  excluded = 'excluded',
  mandatory = 'mandatory',
  suggested = 'suggested',
}
export type UnitHousekeeperPreferences = {
  [UnitPreferenceKind.excluded]: UnitPreference[]
  [UnitPreferenceKind.mandatory]: UnitPreference[]
  [UnitPreferenceKind.suggested]: UnitPreference[]
}

export type UnitPreferenceRelationships = {
  housekeeper: ToOneRelationship
}

export type RawUnitPreference = JSONApiObjectWithRelationships<
  UnitPreferenceAttributes,
  UnitPreferenceRelationships
>

/**********************************************************
 * UNIT
 *********************************************************/
export type Unit = {
  currentReservationId: string
  customInspectionItemIds: string[]
  id: string // NOTE: Units from the API actually have numeric ID values, not strings
  nextReservationId: string
} & UnitAttributes

export type UnitWithHousekeeperPreferences = {
  housekeeperPreferences: UnitHousekeeperPreferences
} & Unit

export type UnitAttributes = Omit<
  AllUnitAttributes,
  'maxOccupancy' | 'hasSmartLock'
>

export type UnitSearchAttributes = Pick<
  UnitAttributes,
  'address' | 'city' | 'name' | 'state' | 'unitCode'
>

export const UnitAttributeNames = difference(AllUnitAttributeNames)([
  'hasSmartLock',
  'maxOccupancy',
])

export const UnitApiFields = UnitAttributeNames.map<string>(snakeCase)

export type UnitRelationships = {
  currentReservation: ToOneRelationship
  customInspectionItems: ToManyRelationship
  deepCleans: ToManyRelationship
  housekeeperPreferences: ToManyRelationship
  lockBox?: ToOneRelationship
  nextReservation: ToOneRelationship
  oncallUser: ToOneRelationship
  openTickets: ToManyRelationship
  zone: ToOneRelationship
}

export type UnitSearchRelationships = {
  zone: ToOneRelationship
}

export type RawUnit = JSONApiObjectWithRelationships<
  UnitAttributes,
  UnitRelationships
>

export type JSONApiUnitMap = JSONApiObjectWithRelationshipsMap<
  UnitAttributes,
  UnitRelationships
>

export type JSONApiUnitPreferenceMap = JSONApiObjectWithRelationshipsMap<
  UnitPreferenceAttributes,
  UnitPreferenceRelationships
>

export type JSONAPIUnitSearchMap = JSONApiObjectWithRelationshipsMap<
  UnitSearchAttributes,
  UnitSearchRelationships
>
