import styled from '@emotion/styled'
import React from 'react'

import { ExternalLink } from 'packages/common'
import { Slugs, useI18n } from 'packages/i18n'
import {
  getAdminCreateTicketURL,
  getAdminViewUnitTicketsURL,
  getVacasaViewUnitURL,
} from 'packages/utils/links'

import { UnitDrawerStyles } from '../../UnitDrawer.styles'

const St = {
  Row: styled.div`
    display: flex;

    :not(:last-child) {
      margin-bottom: 20px;
    }
  `,
  UnitLinks: UnitDrawerStyles.Section,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    createTicket: ut(Slugs.createTicket),
    viewHome: ut(Slugs.viewHome),
    viewTickets: ut(Slugs.viewOpenTickets),
  }
}

export type UnitLinksProps = {
  unitId: string
}

export const UnitLinks: React.FC<UnitLinksProps> = React.memo(({ unitId }) => {
  const strings = useTranslations()

  return (
    <St.UnitLinks>
      <St.Row>
        <ExternalLink
          text={strings.viewTickets}
          url={getAdminViewUnitTicketsURL(unitId)}
        />
      </St.Row>

      <St.Row>
        <ExternalLink
          text={strings.createTicket}
          url={getAdminCreateTicketURL({ unitId })}
        />
      </St.Row>

      <St.Row>
        <ExternalLink
          text={strings.viewHome}
          url={getVacasaViewUnitURL(unitId)}
        />
      </St.Row>
    </St.UnitLinks>
  )
})
