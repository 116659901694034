import { isEqual, pipe, prop } from 'lodash/fp'
import React from 'react'

import {
  Alert,
  Button,
  CleanBadgeTypes,
  ExternalLink,
  Loader,
  TooltipPosition,
} from 'packages/common'
import { StringSelect } from 'packages/common/src/Select/SimpleSelects'
import {
  cleanIsB2b,
  cleanIsMidstay,
  getDeepCleanPredictedTime,
} from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'
import {
  createDateObject,
  createDateString,
  DateFormat,
  format,
  formatLocalized,
} from 'packages/utils/dateHelpers'
import { AsyncState } from 'packages/utils/hooks'
import { getAddDaysToResUrl } from 'packages/utils/links'
import { convertHoursToTimeString } from 'packages/utils/mathHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean, ComputedCleanJobType } from 'app/hkhub/store/cleans'
import { ReservationBookingType } from 'app/hkhub/store/reservations/reservations.types'

import { CleanInfoEditorTranslations } from './CleanInfoEditor.container'
import { St } from './CleanInfoEditor.styles'

/** A parsing function for displaying formatted dates in Selects */
const getDateLabel = (date: Date) =>
  formatLocalized(date, DateFormat.FullWithoutTime)

type GroupedCleanTimes = {
  deep: number
  post: number
}

export type JobTypeUIConfig = {
  color: string
  icon: IconName
  slug: string
}

export const jobTypeConfigMap: {
  [key in ComputedCleanJobType]: JobTypeUIConfig
} = {
  deep: {
    color: colors.sand,
    icon: IconName.doorClose,
    slug: Slugs.deep,
  },
  post: {
    color: colors.gulf20,
    icon: IconName.bucketClean,
    slug: Slugs.postStay,
  },
}

const CleanJobOption: React.FC<{
  job: ComputedCleanJobType
  times: GroupedCleanTimes
}> = React.memo(({ job, times }) => {
  const { t } = useI18n()

  return (
    <>
      <St.Label job={job}>
        <SvgIcon icon={jobTypeConfigMap[job].icon} size={16} />
        {t(jobTypeConfigMap[job].slug)}
      </St.Label>
      {times[job] && (
        <St.PredictedTime>
          {convertHoursToTimeString(times[job])}
        </St.PredictedTime>
      )}
    </>
  )
})

const wrapInDateConversion = fn => str => fn(createDateObject(str))

export enum CleanInfoEditorTestIds {
  container = 'CleanInfoEditor__container',
  error = 'CleanInfoEditor__error',
  jobTypeEditor = 'CleanInfoEditor__jobTypeEditor',
}

export type CleanInfoEditorProps = {
  clean: Clean
  handlers: {
    cancelEdit: () => void
    clearError: () => void
    dateChange: (date: Date) => void
    onCleanJobTypeChange: (value: ComputedCleanJobType) => void
    save: () => void
  }
  requestState: AsyncState<unknown>
  reservationBookingType: ReservationBookingType
  selectors: {
    isDateDisabled: (date: Date) => boolean
  }
  state: {
    canEditDates: boolean
    canEditDeepClean: boolean
    dateOptions: Date[]
    disableSubmit: boolean
    isDeepClean: boolean
    selectedDate: Date
  }
  strings: CleanInfoEditorTranslations
}

export const CleanInfoEditor: React.FC<CleanInfoEditorProps> = React.memo(
  ({
    clean,
    handlers,
    reservationBookingType,
    requestState,
    selectors,
    state,
    strings,
  }) => {
    const dateStrs = state.dateOptions.map(createDateString)

    const getOptionComponent = React.useCallback(
      job => (
        <CleanJobOption
          job={job}
          times={{
            deep: getDeepCleanPredictedTime(clean),
            post: clean.predictedCleanTime,
          }}
        />
      ),
      [clean],
    )

    // checking if clean type is being changed to deep clean on a B2B clean
    const shouldShowDeepCleanB2bAlert = cleanIsB2b(clean) && state.isDeepClean

    const showAddDaysSection = !cleanIsMidstay(clean) && !cleanIsB2b(clean)

    const isOwnerCheckout =
      reservationBookingType === ReservationBookingType.OWNER

    return (
      <St.CleanInfoEditor data-testid={CleanInfoEditorTestIds.container}>
        {requestState.loading && <Loader />}

        <St.EditableSection data-testid={CleanInfoEditorTestIds.jobTypeEditor}>
          {state.canEditDeepClean && (
            <>
              <St.CleanTypeTitle>{strings.cleanType}</St.CleanTypeTitle>
              <St.JobTypeRadioButtons
                onChange={handlers.onCleanJobTypeChange}
                options={['post', 'deep']}
                getOptionLabel={getOptionComponent}
                selectedValue={state.isDeepClean ? 'deep' : 'post'}
                valueComparator={isEqual}
              />
            </>
          )}
          {isOwnerCheckout && (
            <Alert alertType={'info'}>
              <span>{strings.ownerCheckoutCannotEditClean}</span>
            </Alert>
          )}
        </St.EditableSection>

        {state.canEditDates && (
          <St.DatesContainer>
            <St.DateEditSection>
              <St.ScheduledOn>{strings.scheduledOn}</St.ScheduledOn>
              <St.DateSelectContainer fullHeight={dateStrs.length > 1}>
                {dateStrs.length > 1 ? (
                  <St.DateSelectOverflowContainer>
                    <StringSelect
                      isOptionDisabled={pipe(
                        prop('value'),
                        wrapInDateConversion(selectors.isDateDisabled),
                      )}
                      onChange={wrapInDateConversion(handlers.dateChange)}
                      options={dateStrs}
                      translateValue={wrapInDateConversion(getDateLabel)}
                      value={createDateString(state.selectedDate)}
                    />
                  </St.DateSelectOverflowContainer>
                ) : (
                  <span>
                    {getDateLabel(createDateObject(clean.effectiveDate))}
                  </span>
                )}
              </St.DateSelectContainer>
              <St.ArrowWrapper>
                <St.ArrowIcon
                  icon={IconName.rightArrow}
                  centerItems={true}
                  size={18}
                />
              </St.ArrowWrapper>
              <St.DueTitle>{strings.due}</St.DueTitle>
              <St.DueDate>
                {format(clean.dueDate, DateFormat.FullWithoutTime)}
              </St.DueDate>
              <St.BadgesWrapper>
                <St.UnitBadge
                  clean={clean}
                  omitBadgeTypes={[CleanBadgeTypes.Late]}
                  tooltipPosition={TooltipPosition.Left}
                />
              </St.BadgesWrapper>
            </St.DateEditSection>

            {showAddDaysSection && (
              <St.AddDaysSection>
                <ExternalLink
                  text={strings.addDays}
                  url={getAddDaysToResUrl(clean.reservation.id)}
                />
              </St.AddDaysSection>
            )}
          </St.DatesContainer>
        )}

        <div>
          {shouldShowDeepCleanB2bAlert && (
            <>
              <St.CannotAddWarning>
                {strings.cannotAddExtraDayWarning}
              </St.CannotAddWarning>
              <St.Alert alertType={'warning'}>
                <span>{strings.editB2bWarning}</span>
              </St.Alert>
            </>
          )}
          {requestState.error && (
            <St.Alert
              alertType={'danger'}
              dataTestId={CleanInfoEditorTestIds.error}
              onClose={handlers.clearError}
            >
              <span>{strings.assignmentChangeError}</span>
            </St.Alert>
          )}
          <St.Buttons>
            <Button onClick={handlers.cancelEdit} buttonType={'utility'}>
              {strings.cancel}
            </Button>

            <Button
              disabled={state.disableSubmit}
              onClick={handlers.save}
              buttonType={'primary'}
            >
              {strings.save} {strings.clean}
            </Button>
          </St.Buttons>
        </div>
      </St.CleanInfoEditor>
    )
  },
)
