import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { fullName } from 'packages/grimoire'
import { isDefined } from 'packages/utils/misc'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans/cleans.types'
import { ApplicationState } from 'app/hkhub/store/store'
import { getHkPreferencesForUnit } from 'app/hkhub/store/units/selectors'
import { MiniUnitPreference } from 'app/hkhub/store/units/units.utils'

const isMandatory = (pref: MiniUnitPreference) => pref.alias === 'mandatory'

const MandatoryHkNoteContainer = styled.div`
  margin-top: 16px;
`

export type MandatoryHkNoteProps = {
  clean: Clean
}

export const MandatoryHkNote: React.FC<MandatoryHkNoteProps> = ({ clean }) => {
  const { assignedHks, unit } = clean
  const { t } = useI18n()

  const unitPreferences = useSelector((state: ApplicationState) =>
    getHkPreferencesForUnit(state, unit.id),
  )

  const mandatoryHkId = unitPreferences.find(isMandatory)?.housekeeperId

  // in rare (and erroneous) edge cases, the API can send us [undefined] for the
  // assigned HKs list, so we have to protect against that possibility
  const mandatoryHk = assignedHks.find(hk => {
    const hkId = hk?.id
    return isDefined(hkId) && hkId === mandatoryHkId
  })

  const hkName = mandatoryHk ? fullName(mandatoryHk.user) : ''

  return mandatoryHk ? (
    <MandatoryHkNoteContainer>
      {t(Slugs.mandatoryHkNote, { hkName })}
    </MandatoryHkNoteContainer>
  ) : null
}
