import { AxiosResponse } from 'axios'
import snakeCase from 'lodash/snakeCase'
import xor from 'lodash/xor'

import {
  JSONApiObject,
  JSONApiObjectMap,
  NormalizedJSONApiResponse,
} from 'packages/utils/store/jsonapi.types'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type UsersSearchResultsState = {
  user: JSONApiUsersMap
}

export type UsersState = {
  authUserId: string
  data: JSONApiUsersMap
  error?: Error
  isLoading: boolean
  searchResults: UsersSearchResultsState
}

export enum UsersActionTypes {
  FETCH_CURRENT_USER = 'USERS/API/FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_FAILURE = 'USERS/FETCH_CURRENT_USER_FAILURE',
  FETCH_CURRENT_USER_SUCCESS = 'USERS/FETCH_CURRENT_USER_SUCCESS',

  SEARCH_USERS = 'USERS/API/SEARCH_USERS',
  SEARCH_USERS_FAILURE = 'USERS/SEARCH_USERS_FAILURE',
  SEARCH_USERS_SUCCESS = 'USERS/SEARCH_USERS_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
type UsersResponse = {
  user: JSONApiUsersMap
}

export type NormalizedUsersApiResponse =
  NormalizedJSONApiResponse<UsersResponse>
export type UsersApiResponse = AxiosResponse<NormalizedUsersApiResponse>
export type UsersServiceResponse = Promise<NormalizedUsersApiResponse>

/**********************************************************
 * USER
 *********************************************************/
export type User = {
  id: string
} & UserAttributes

export const UserAttributeNames = [
  'address',
  'firstName',
  'isHomeowner',
  'isSuperuser',
  'lastName',
  'mobilePhone',
  'monolithUserId',
]

export const UserApiFields = UserAttributeNames.map<string>(snakeCase)

export const UserApiFieldsWithoutPII = xor(UserApiFields, [
  'address',
  'mobile_phone',
])

export type UserAttributes = {
  address: string
  firstName: string
  isHomeowner: boolean
  isSuperuser: boolean
  lastName: string
  mobilePhone: string
  monolithUserId: string
}

export type RawUser = JSONApiObject<UserAttributes>

export type JSONApiUsersMap = JSONApiObjectMap<UserAttributes>
