import styled from '@emotion/styled'
import React from 'react'

import { colors, text } from 'packages/styles'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'
import { getSystemTZ } from 'packages/utils/timezone.helpers'

import { displayName } from 'app/hkhub/components/core/components/AsyncUserSelector/AsyncUserSelector.helpers'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers'
import { AssignmentChange, AssignmentChangeAction } from 'app/hkhub/store/tasks'
import { User } from 'app/hkhub/store/users'

export type AssignmentChangeDisplayProps = {
  assignmentChange: AssignmentChange
  housekeeper: Housekeeper
  user: User
}

const St = {
  AssignmentChangeDate: styled.span`
    ${text.bodyTiny}
    color: ${colors.dusk60};
  `,
  AssignmentChangeDescription: styled.span`
    ${text.bodyRegularTiny};
    color: ${colors.dusk60};
  `,
  AssignmentChangeRow: styled.span`
    display: grid;
    grid-template-columns: 40% 60%;
    margin-left: 8px;
  `,
}

const useTranslations = (housekeeper: Housekeeper, user: User) => {
  const { lt, t } = useI18n()

  const assignmentChangeActionTranslations: Record<
    AssignmentChangeAction,
    string
  > = {
    assign: `${displayName(user)} ${t(Slugs.assignedTo)} ${displayName(
      housekeeper.user,
    )}`,
    reject: `${displayName(user)} ${t(Slugs.rejected)}`,
    unassign: `${displayName(user)} ${lt(Slugs.unassigned)}`,
  }

  return assignmentChangeActionTranslations
}

export const AssignmentChangeDisplay: React.FC<AssignmentChangeDisplayProps> =
  React.memo(({ assignmentChange, housekeeper, user }) => {
    const changeDate = formatLocalized(
      assignmentChange.changedAt,
      DateFormat.MonthAndDayShortWithTimeAndYearAndTZ,
      getSystemTZ(),
    )

    const strings = useTranslations(housekeeper, user)
    return (
      <St.AssignmentChangeRow>
        <St.AssignmentChangeDate>({changeDate})</St.AssignmentChangeDate>
        <St.AssignmentChangeDescription>
          {strings[assignmentChange.action]}
        </St.AssignmentChangeDescription>
      </St.AssignmentChangeRow>
    )
  })
