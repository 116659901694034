import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { usersService } from '../users.service'
import { NormalizedUsersApiResponse, UsersActionTypes } from '../users.types'

export const searchUsersAction = createAsyncAction(
  UsersActionTypes.SEARCH_USERS,
  UsersActionTypes.SEARCH_USERS_SUCCESS,
  UsersActionTypes.SEARCH_USERS_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  NormalizedUsersApiResponse,
  Error
>()

export const getParams = (search: string): RequestOptions => {
  return {
    filter: {
      eligible_for_search: true,
      is_active: true,
      is_housekeeper: true,
      search,
    },
    page: { size: 1500 },
  }
}

export const searchUsers =
  (search: string, authToken: string | undefined = undefined) =>
  async dispatch => {
    try {
      const params = getParams(search)
      // Bind the fetchUsers with params and the token
      const request = usersService.fetchUsers.bind(null, params, authToken)
      const response = await dispatch(searchUsersAction.request({ request }))

      dispatch(searchUsersAction.success(response))
      return response.normalized
    } catch (error) {
      dispatch(searchUsersAction.failure(error))
      throw error
    }
  }
