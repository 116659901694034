import * as React from 'react'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AsyncSelector } from 'packages/common'

import { getAuthTokenWithoutImpersonation } from 'app/hkhub/store/auth/selectors/getAuthToken'
import { User } from 'app/hkhub/store/users'
import {
  getAuthUser,
  getUsersSearchResults,
} from 'app/hkhub/store/users/selectors'

import { loadOptions } from './loadOptions'

type AsyncUserSelectorProps = {
  className?: string
  onUserSelectionChange: (value: User) => void
}

const AsyncUserSelector: React.FC<AsyncUserSelectorProps> = ({
  className,
  onUserSelectionChange,
}) => {
  const dispatch = useDispatch()
  const selectedUser = useSelector(getAuthUser)
  const getUsersResults = useSelector(getUsersSearchResults)
  const authCodeForUserSearch = useSelector(getAuthTokenWithoutImpersonation)

  // The following line is used to refer to the current version of the getResults selector within load options
  const getResultsRef = useRef(getUsersResults)
  getResultsRef.current = getUsersResults

  const loadOptionsFunc = async (
    input: string,
    callback: (users: User[]) => void,
  ) => {
    return loadOptions(dispatch, authCodeForUserSearch, input, callback)
  }

  const noOptionsMessage = React.useCallback(
    ({ inputValue }) => (inputValue === '' ? 'Type to search' : 'No results'),
    [],
  )

  return (
    <AsyncSelector
      className={className}
      defaultOptions={[]}
      isDisabled={false}
      getOptionLabel={(user: User) => `${user.firstName} ${user.lastName}`}
      getOptionValue={(user: User) => user.id}
      loadingMessage={() => 'Loading...'}
      loadOptions={loadOptionsFunc}
      noOptionsMessage={noOptionsMessage}
      onChange={onUserSelectionChange}
      placeholder={'Select...'}
      value={selectedUser}
    />
  )
}

export default AsyncUserSelector
