import styled from '@emotion/styled'
import classNames from 'classnames'
import { startCase } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Card, Loader } from 'packages/common'
import { fullName } from 'packages/grimoire'
import { colors } from 'packages/styles'
import { useOnlineStatus } from 'packages/utils/hooks'
import { ReduxDispatch } from 'packages/utils/store'

import AsyncUserSelector from 'app/hkhub/components/core/components/AsyncUserSelector/AsyncUserSelector'
import selectorStyles from 'app/hkhub/components/core/components/AsyncUserSelector/AsyncUserSelectorOverride.module.scss'
import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { ApplicationState } from 'app/hkhub/store/store'
import { searchUsers } from 'app/hkhub/store/users/actions'
import { getUsersSearchResults } from 'app/hkhub/store/users/selectors'
import { updateZone } from 'app/hkhub/store/zones/actions'

const St = {
  Assignee: styled.span`
    flex-grow: 2;
    padding-right: 16px;
  `,
  Card: styled(Card)`
    margin: auto;
    max-width: 95vw;
    min-height: 150px;
    position: relative;

    @media only screen and (min-width: 769px) {
      width: 50%;
    }
  `,
  EditRow: styled.div`
    align-items: center;
    border-top: 1px solid ${colors.midnight20};
    display: block;
    flex-direction: column;
    margin: 0 24px;
    padding: 16px 0;
    position: relative;
  `,
  EditRowAssigneeSection: styled.div`
    align-items: center;
    display: flex;
  `,
  EditRowAssigneeTitle: styled.span`
    margin-right: 40px;
  `,
  EditRowButtons: styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  `,
  Error: styled.div`
    color: ${colors.alert};
    padding: 0px 24px 8px 24px;
  `,
  Row: styled.div<{ editRow?: boolean }>`
    align-items: center;
    display: flex;
    margin: 0 24px;
    padding: 16px 0;
    position: relative;

    & + & {
      border-top: 1px solid ${colors.midnight20};
    }
  `,
  UserSelectorWrapper: styled.span`
    align-items: center;
    display: flex;
    flex-grow: 2;
    justify-content: space-between;
    padding-bottom: 8px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    assignee: t(Slugs.assignee),
    cancel: startCase(t(Slugs.cancel)),
    edit: startCase(t(Slugs.edit)),
    housekeepingContractors: startCase(t(Slugs.housekeepingContractors)),
    save: startCase(t(Slugs.save)),
    saveError: t(Slugs.saveAssigneeError),
    unassigned: t(Slugs.noAssigneeDesignated),
  }
}

export enum CoupaRoutingCardTestIds {
  cancel = 'CoupaRoutingCard__cancel',
  edit = 'CoupaRoutingCard__edit',
}

export type CoupaRoutingCardProps = {}

export const CoupaRoutingCard: React.FC<CoupaRoutingCardProps> = React.memo(
  () => {
    const strings = useTranslations()
    const { zone } = useZoneContext()
    const { invoiceApproverUser } = zone

    const dispatch = useDispatch<ReduxDispatch<ApplicationState>>()

    const getSearchResults = useSelector(
      (state: ApplicationState) => () => getUsersSearchResults(state),
    )

    const [isLoading, setLoading] = React.useState(false)
    const [editing, setEditing] = React.useState(false)
    const [selectedAssignee, setSelectedAssignee] =
      React.useState(invoiceApproverUser)
    const [searchValue, setSearchValue] = React.useState('')
    const [error, setError] = React.useState(false)

    const isOnline = useOnlineStatus().isOnline()

    const handleSearchUsers = async search => {
      setSearchValue(search)
      return await dispatch(searchUsers(search))
    }

    const handleSubmitAssignee = async () => {
      setLoading(true)

      if (selectedAssignee?.id) {
        try {
          await dispatch(
            updateZone({
              attributes: {},
              id: zone.id,
              relationships: { invoiceApproverUser: selectedAssignee.id },
            }),
          )
        } catch (e) {
          setError(true)
        }
      }

      setEditing(false)
      setLoading(false)
    }

    const handleCancel = () => {
      setEditing(false)
      setSearchValue('')
      setSelectedAssignee(invoiceApproverUser)
    }

    return (
      <St.Card>
        {isLoading && <Loader />}
        <St.Row>
          <span>{strings.housekeepingContractors}</span>
        </St.Row>
        {editing ? (
          <>
            <St.EditRow>
              <St.EditRowAssigneeSection>
                <St.EditRowAssigneeTitle>
                  {strings.assignee}
                </St.EditRowAssigneeTitle>
                <AsyncUserSelector
                  className={classNames(selectorStyles.selector, {
                    [selectorStyles.noSearch]: searchValue.length < 3,
                  })}
                  getUsersResults={getSearchResults}
                  onUserSelectionChange={setSelectedAssignee}
                  selectedUser={selectedAssignee}
                  searchUsers={handleSearchUsers}
                />
              </St.EditRowAssigneeSection>
              <St.EditRowButtons>
                <Button
                  buttonType={'utility'}
                  dataTestId={CoupaRoutingCardTestIds.cancel}
                  onClick={handleCancel}
                >
                  {strings.cancel}
                </Button>
                <Button onClick={handleSubmitAssignee} buttonType={'primary'}>
                  {strings.save}
                </Button>
              </St.EditRowButtons>
            </St.EditRow>
          </>
        ) : (
          <St.Row>
            <St.Assignee>
              {invoiceApproverUser
                ? fullName(invoiceApproverUser)
                : strings.unassigned}
            </St.Assignee>
            <Button
              dataTestId={CoupaRoutingCardTestIds.edit}
              disabled={!isOnline}
              onClick={() => {
                setError(false)
                setEditing(true)
              }}
              buttonType={'utility'}
            >
              {strings.edit}
            </Button>
          </St.Row>
        )}
        {error && <St.Error>{strings.saveError}</St.Error>}
      </St.Card>
    )
  },
)
