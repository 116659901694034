import styled from '@emotion/styled'
import * as React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, truncateTextWithEllipsis } from 'packages/styles'

import { useTranslateTickets } from 'app/hkhub/components/schedule/hooks/useTranslateTickets'
import { Slugs } from 'app/hkhub/i18n'
import { Ticket } from 'app/hkhub/store/tickets'
import { ticketStatusToComputedMap } from 'app/hkhub/utils/ticketHelpers'

import { ComputedTicketStatus } from '../../TicketCard/TicketCard.types'
import { TicketSeverityText, TicketStatusBar } from '../TicketDrawer.styles'

const SeverityHeaderContent: React.FC = ({ children }) => {
  return (
    <St.SeverityText>
      <SvgIcon centerItems={true} icon={IconName.tag} size={16} />
      <St.SeverityText>{children}</St.SeverityText>
    </St.SeverityText>
  )
}

const St = {
  CancelledStatusBar: styled(TicketStatusBar)`
    background: ${colors.midnight20};
  `,
  CompletedStatusBar: styled(TicketStatusBar)`
    background: ${colors.success40};
  `,
  SeverityText: styled(TicketSeverityText)`
    align-items: center;
    display: flex;
    margin-left: 6px;
  `,
  TicketDrawerCloseIcon: styled(SvgIcon)`
    color: ${colors.dusk};
    right: 0px;
  `,
  TicketIcon: styled(SvgIcon)`
    &,
    span {
      ${truncateTextWithEllipsis};
    }

    svg {
      flex-shrink: 0;
    }
    padding-right: 12px;
  `,
  TicketStatusBar,
}

export enum TicketDrawerHeaderTestIds {
  closeIcon = 'TicketHeader__closeIcon',
}

const useTranslations = status => {
  const { t } = useI18n()

  const statusToTextMap: Record<ComputedTicketStatus, string> = {
    assigned: t(Slugs.assigned),
    awaiting: t(Slugs.awaiting),
    cancelled: t(Slugs.cancelled),
    completed: t(Slugs.completed),
    inProgress: t(Slugs.inProgress),
    pending: t(Slugs.pending),
    unassigned: t(Slugs.unassigned),
  }

  return {
    statusText: statusToTextMap[status],
    toolTipText: t(Slugs.overdueTooltipText),
  }
}

export type TicketDrawerHeaderProps = {
  forceCloseDrawer: () => void
  ticket: Ticket
}

export const TicketDrawerHeader: React.FC<TicketDrawerHeaderProps> = React.memo(
  props => {
    const { forceCloseDrawer, ticket } = props

    const { translateSeverity } = useTranslateTickets()
    const severity = translateSeverity(ticket?.severity)

    const computedTicketStatus = ticketStatusToComputedMap[ticket.status]

    const strings = useTranslations(computedTicketStatus)

    if (computedTicketStatus === 'completed') {
      return (
        <St.CompletedStatusBar severity={ticket.severity}>
          <SeverityHeaderContent>{strings.statusText}</SeverityHeaderContent>
          <St.TicketDrawerCloseIcon
            dataTestId={TicketDrawerHeaderTestIds.closeIcon}
            icon={IconName.x}
            onClick={forceCloseDrawer}
            size={22}
          />
        </St.CompletedStatusBar>
      )
    }

    if (computedTicketStatus === 'cancelled') {
      return (
        <St.CancelledStatusBar severity={ticket.severity}>
          <SeverityHeaderContent>{strings.statusText}</SeverityHeaderContent>
          <St.TicketDrawerCloseIcon
            dataTestId={TicketDrawerHeaderTestIds.closeIcon}
            icon={IconName.x}
            onClick={forceCloseDrawer}
            size={22}
          />
        </St.CancelledStatusBar>
      )
    }

    return (
      <St.TicketStatusBar severity={ticket.severity}>
        <SeverityHeaderContent>{severity}</SeverityHeaderContent>
        <St.TicketDrawerCloseIcon
          dataTestId={TicketDrawerHeaderTestIds.closeIcon}
          icon={IconName.x}
          onClick={forceCloseDrawer}
          size={22}
        />
      </St.TicketStatusBar>
    )
  },
)
