import { createAsyncAction } from 'typesafe-actions'

import { format } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { UserApiFields } from '../../users'
import { ticketsService } from '../tickets.service'
import {
  TicketApiFields,
  TicketActionTypes,
  NormalizedTicketsApiResponse,
} from '../tickets.types'

const withTimezone = (date: string) => {
  return date + `T00:00:00`
}

export const fetchTicketsByZoneAndDateAction = createAsyncAction(
  TicketActionTypes.FETCH_TICKETS_BY_ZONE_AND_DATE,
  TicketActionTypes.FETCH_TICKETS_BY_ZONE_AND_DATE_SUCCESS,
  TicketActionTypes.FETCH_TICKETS_BY_ZONE_AND_DATE_FAILURE,
)<
  RequestConfig<NormalizedTicketsApiResponse>,
  NormalizedTicketsApiResponse,
  Error
>()

export const getParams = (
  zoneId: string,
  startDate: Date,
  endDate?: Date,
): RequestOptions => {
  const dueDate = endDate
    ? {
        range: [
          withTimezone(format(startDate, 'yyyy-MM-dd')),
          withTimezone(format(endDate, 'yyyy-MM-dd')),
        ],
      }
    : withTimezone(format(startDate, 'yyyy-MM-dd'))

  return {
    fields: {
      assignee: UserApiFields,
      ticket: TicketApiFields,
      unit: [],
    },
    filter: {
      due_date: dueDate,
      'unit.zone': zoneId,
    },
    include: ['assignee', 'disposition', 'unit'],
    page: { size: 1500 },
  }
}

export const fetchTicketsByZoneAndDate = (
  zoneId: string,
  startDate: Date,
  endDate?: Date,
) => {
  return async dispatch => {
    try {
      const params = getParams(zoneId, startDate, endDate)
      const request = ticketsService.fetchTickets.bind(null, params)
      const result = await dispatch(
        fetchTicketsByZoneAndDateAction.request({ request }),
      )
      dispatch(fetchTicketsByZoneAndDateAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchTicketsByZoneAndDateAction.failure(error))
      throw error
    }
  }
}
