import styled from '@emotion/styled'

export const St = {
  CleanUnitInfoContainer: styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,

  IndicatorsRow: styled.div`
    align-items: center;
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    width: 100%;
  `,

  UnitAddress: styled.div`
    font-size: 14px;
  `,

  UnitCode: styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
  `,

  UnitLinks: styled.div`
    column-gap: 16px;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
  `,

  UnitName: styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
}
