import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

export const Title = styled.h1`
  ${text.bodyBold};
  margin-bottom: 0px;
`

export const OverdueIcon = styled(SvgIcon)`
  background: white;
  border-radius: 36px;
  border: 1px solid ${colors.dusk};
  color: ${colors.alert};
  margin-left: 16px;
  padding: 2px 16px;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 20px;
`
