import {
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import {
  Assignment,
  AssignmentAttributeNames,
  NormalizedAssignmentsApiResponse,
  RawAssignment,
} from './assignments.types'

export const emptyNormalizedAssignmentsData = Object.freeze({
  assignment: {},
})

export const emptyAssignmentsResponse: NormalizedAssignmentsApiResponse =
  Object.freeze({
    normalized: emptyNormalizedAssignmentsData,
    raw: { data: [] },
  })

export const hydrateRawAssignment = (
  rawAssignment: RawAssignment,
): Assignment => {
  const assignment = transformNormalizedToTyped<Assignment>(
    rawAssignment,
    AssignmentAttributeNames,
  )

  const housekeeperId = findRelationshipId(rawAssignment, 'housekeeper') || ''

  return {
    ...assignment,
    housekeeperId,
  }
}
