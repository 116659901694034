import startCase from 'lodash/startCase'
import * as React from 'react'

import { Button, useToast } from 'packages/common'

import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Unit } from 'app/hkhub/store/units/units.types'

import { ZoneManageReduxProps } from '../../manage.types'
import AddUnitModal from './AddUnitModal'

import styles from '../AddModal.module.scss'

export type AddUnitModalWrapperProps = ZoneManageReduxProps

export type AddUnitModalWrapperState = {
  error: string
  loadingIds: string[]
}

const AddUnitModalWrapper: React.FC<AddUnitModalWrapperProps> = ({
  addUnitsToZone,
  clearUnitSearchResults,
  getUnitSearchResults,
  fetchUnitsByZone,
  searchUnits,
}) => {
  const { t } = useI18n()
  const { zone } = useZoneContext()
  const { showToast } = useToast()

  const [modalShowing, setModalShowing] = React.useState(false)
  const [searchingState, setSearchingState] = React.useState({
    hasSearched: false,
    searching: false,
  })

  const [apiState, setApiState] = React.useState<AddUnitModalWrapperState>({
    error: '',
    loadingIds: [],
  })
  const { error, loadingIds } = apiState

  const handleClearUnitSearchResults = React.useCallback(() => {
    setSearchingState({ hasSearched: false, searching: false })
    clearUnitSearchResults()
  }, [clearUnitSearchResults, setSearchingState])

  const handleModalClose = React.useCallback(() => {
    handleClearUnitSearchResults()
    setModalShowing(false)
    setApiState({ error: '', loadingIds: [] })
  }, [handleClearUnitSearchResults, setApiState, setModalShowing])

  const handleSearchUnits = React.useCallback(
    async (searchString: string) => {
      setSearchingState(prev => ({ ...prev, hasSearched: false }))
      if (searchString.length >= 3) {
        setSearchingState({ hasSearched: false, searching: true })
        await searchUnits(searchString)
        setSearchingState({ hasSearched: true, searching: false })
      }
    },
    [searchUnits],
  )

  const handleAddUnitsToZone = React.useCallback(
    async (unit: Unit) => {
      try {
        const ids = [unit.id]
        setApiState({ error: '', loadingIds: ids })
        await addUnitsToZone({
          ownerId: zone.id,
          relationshipIds: ids,
        })

        showToast({
          message: t(Slugs.canNowAssignCleansForUnit, {
            unitName: unit.unitCode,
          }),
        })

        fetchUnitsByZone(zone.id)
        setApiState({ error: '', loadingIds: [] })
      } catch (err) {
        setApiState({ error: t(Slugs.errorTechSupport), loadingIds: [] })
      }
    },
    [addUnitsToZone, fetchUnitsByZone, showToast, t, zone],
  )

  return (
    <>
      <Button
        className={styles.addButton}
        onClick={() => setModalShowing(true)}
        buttonType={'text'}
      >
        {startCase(t(Slugs.addUnit))}
      </Button>

      <AddUnitModal
        addUnitsToZone={handleAddUnitsToZone}
        afterClose={handleModalClose}
        clearUnits={handleClearUnitSearchResults}
        errorMessage={error}
        hasSearched={searchingState.hasSearched}
        loadingIds={loadingIds}
        searching={searchingState.searching}
        searchUnits={handleSearchUnits}
        showing={modalShowing}
        units={getUnitSearchResults()}
        zone={zone}
      />
    </>
  )
}

/**
 * Wrapper component for internalizing all of the state functionality required by AddUnitModal.
 *
 * Note that, even though it is a Modal, this component is effectively a full page/view component, which
 * is how we are using this wrapper. Most of the state and Redux stuff is managed within this component,
 * and passed directly down to the Modal itself.
 *
 * The button/trigger is even included in this component, because it wraps a lot of the show/hide logic.
 */
export default React.memo(AddUnitModalWrapper)
