import { createAsyncAction } from 'typesafe-actions'

import {
  apiDateStringWithSeconds,
  createDateObject,
} from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { zonesService } from '../zones.service'
import {
  NormalizedZonesApiResponse,
  ZonesActionTypes,
  ZoneUpdate,
} from '../zones.types'

export const createZoneAction = createAsyncAction(
  ZonesActionTypes.CREATE_ZONE,
  ZonesActionTypes.CREATE_ZONE_SUCCESS,
  ZonesActionTypes.CREATE_ZONE_FAILURE,
)<RequestConfig<NormalizedZonesApiResponse>, ZoneUpdate, Error>()

export const getParams = (name: string): RequestOptions => ({
  data: {
    attributes: {
      is_active: true,
      live_at: apiDateStringWithSeconds(createDateObject()),
      name,
    },
    type: 'zone',
  },
})

export const createZone = (name: string) => async dispatch => {
  try {
    const params = getParams(name)
    const request = zonesService.createZone.bind(null, params)
    const result = await dispatch(createZoneAction.request({ request }))
    dispatch(createZoneAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(createZoneAction.failure(error))
    throw error
  }
}
