import React from 'react'
import { useSelector } from 'react-redux'

import { track, Events } from 'packages/wiretap'

import { useOptionalZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { getAuthUserId } from 'app/hkhub/store/users/selectors'

import { useScheduleMatchParams } from '../useScheduleMatchParams'

const isSearchEvent = (event: KeyboardEvent): boolean => {
  const isFKey = event.key === 'f' || event.key === 'F'
  const isCtrlOrCmdKey = event.ctrlKey || event.metaKey

  return isFKey && isCtrlOrCmdKey
}

export const useTrackSearchFunctionality = (): void => {
  const { zone } = useOptionalZoneContext()
  const { entity } = useScheduleMatchParams()
  const zoneId = zone?.id
  const scheduleView = entity
  const userId = useSelector(getAuthUserId)

  const trackSearchFunctionality = React.useCallback(() => {
    /* eslint-disable @typescript-eslint/naming-convention */
    track(Events.hubScheduleBrowserSearch, {
      schedule_view: scheduleView,
      user_id: userId || 'unknown',
      zone_id: zoneId || 'unknown',
    })
    /* eslint-disable @typescript-eslint/naming-convention */
  }, [scheduleView, userId, zoneId])

  const handleTrackSearch = React.useCallback(
    (event: KeyboardEvent) => {
      if (isSearchEvent(event)) trackSearchFunctionality()
    },
    [trackSearchFunctionality],
  )

  React.useEffect(() => {
    window.addEventListener('keydown', handleTrackSearch)

    return () => {
      window.removeEventListener('keydown', handleTrackSearch)
    }
  }, [handleTrackSearch, trackSearchFunctionality])
}
