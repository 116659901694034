import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import * as React from 'react'

import { DataTable } from 'packages/common'
import { DataTableAction } from 'packages/common/src/DataTable/DataTable'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { ZoneNotification } from 'app/hkhub/store/notifications/notifications.types'

import styles from '../../AddHkModal.module.scss'
import { renderHkColumn } from './AddHkNotifications.helpers'

export type AddHkNotificationContentProps = {
  fetchingNotifications: boolean
  loadingIds: string[] | undefined
  notifications: ZoneNotification[]
  onAcceptAddNotification: (notification: ZoneNotification) => void
  onDismissZoneNotification: (id: string) => void
}

const AddHkNotificationContent: React.FC<AddHkNotificationContentProps> = ({
  fetchingNotifications,
  loadingIds,
  onDismissZoneNotification,
  onAcceptAddNotification,
  notifications,
}) => {
  const { t } = useI18n()

  const tableRowValues = React.useMemo(
    () => [
      ({ housekeeper }) => {
        return renderHkColumn(housekeeper, t)
      },
    ],
    [t],
  )

  const tableRowActions = React.useMemo<DataTableAction[]>(
    () => [
      {
        alwaysShow: () => true,
        buttonType: 'utility',
        callback: ({ id }) => {
          onDismissZoneNotification(id)
        },
        title: capitalize(t(Slugs.dismiss)),
      },
      {
        alwaysShow: () => true,
        buttonType: 'primary',
        callback: notification => {
          onAcceptAddNotification(notification)
        },
        title: capitalize(t(Slugs.add)),
      },
    ],
    [onAcceptAddNotification, onDismissZoneNotification, t],
  )

  return (
    <div
      className={classNames(styles.contentSection, {
        [styles.emptySearchWrapper]: fetchingNotifications,
      })}
    >
      {
        <DataTable
          actions={tableRowActions}
          getItemKey={notification => notification.id}
          headerValues={[capitalize(t(Slugs.name))]}
          hoverable={false}
          isLoading={fetchingNotifications}
          items={notifications}
          loadingItemIds={loadingIds}
          rowClasses={['', styles.zoneItem]}
          rowValueGetters={tableRowValues}
        />
      }
    </div>
  )
}

export default React.memo(AddHkNotificationContent)
