import { reduce } from 'lodash/fp'
import get from 'lodash/get' // eslint-disable-line

type ResourceWithId = { id: string }

export const getId = (entity: ResourceWithId): string => get(entity, 'id')

type IdToObjectMap<T extends ResourceWithId> = { [id: string]: T }

function reduceToIdMap<T extends ResourceWithId>(
  arr: IdToObjectMap<T>,
  value: T,
): IdToObjectMap<T> {
  if (value?.id) {
    arr[value.id] = value
  }

  return arr
}

export function arrayToIdMap<T extends ResourceWithId>(
  arr: T[],
): IdToObjectMap<T> {
  return reduce(reduceToIdMap, {}, arr)
}
