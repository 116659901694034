import classNames from 'classnames'
import startCase from 'lodash/startCase'
import * as React from 'react'

import { AsyncSearch, Loader, DataTable } from 'packages/common'
import { TranslatorFn } from 'packages/i18n'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import styles from '../../AddHkModal.module.scss'
import {
  buildTableRowActions,
  renderHkColumn,
  renderZoneColumn,
  shouldDisableHkRow,
} from './AddHkSearchContent.helpers'

const getTableSettings = (t: TranslatorFn) => ({
  columnClasses: [styles.nameColumn, styles.addressColumn],
  getItemKey: (hk: Housekeeper) => hk.id,
  headerColumnTitles: [startCase(t(Slugs.name)), startCase(t(Slugs.zone))],
})

export type AddHkSearchContentProps = {
  /** Callback for after the Modal has completely finished exiting & hiding */
  afterClose: () => void // eslint-disable-line react/no-unused-prop-types
  /** whether a search has occurred and not been cleared */
  hasSearched: boolean
  /** List of Housekeepers to display in the list */
  housekeepers: Housekeeper[]
  /** Set of IDs (if any) that should display a "loading" state */
  loadingIds?: string[]
  /** Callback for adding an HK to this zone as their primary zone */
  onAddHksToZone: (hk: Housekeeper) => void
  /** Callback for adding an HK to this zone as a borrowed zone */
  onBorrowHksToZone: (hk: Housekeeper) => void
  /** Callback for clearing current search results */
  onClearHkSearchResults: () => void
  /** Callback for performing a housekeepers search */
  onSearchHks: (searchString: string) => void
  /** Whether there is currently a pending search operation */
  searching: boolean
  /** Whether the modal is currently active */
  showing: boolean // eslint-disable-line react/no-unused-prop-types
  /** The currently-selected Zone */
  zone: Zone
}

const AddHkSearchContent: React.FC<AddHkSearchContentProps> = ({
  hasSearched,
  housekeepers = [],
  loadingIds = [],
  onAddHksToZone,
  onBorrowHksToZone,
  onClearHkSearchResults,
  onSearchHks,
  searching,
  zone,
}) => {
  const { t, tp } = useI18n()

  const hkCount = housekeepers.length

  const tableRowActions = React.useMemo(
    () => buildTableRowActions(onBorrowHksToZone, onAddHksToZone, t, zone),
    [onAddHksToZone, onBorrowHksToZone, t, zone],
  )
  const tableRowValues = React.useMemo(
    () => [hk => renderHkColumn(hk, t), hk => renderZoneColumn(hk, zone, t)],
    [t, zone],
  )

  const { columnClasses, getItemKey, headerColumnTitles } = React.useMemo(
    () => getTableSettings(t),
    [t],
  )

  return (
    <>
      <div className={styles.searchSection}>
        <AsyncSearch
          focusOnMount={true}
          fullWidth={false}
          onClearValue={onClearHkSearchResults}
          onSearchTimeout={onSearchHks}
          placeholder={t(Slugs.searchHousekeepersName)}
          styleOverrides={{ width: 400 }}
        />
        {!!hkCount && (
          <div className={styles.resultsCount}>{tp(Slugs.result, hkCount)}</div>
        )}
      </div>
      <div
        className={classNames(styles.contentSection, {
          [styles.emptySearchWrapper]: searching && !hkCount,
        })}
      >
        {!!hkCount && (
          <DataTable
            actions={tableRowActions}
            getItemKey={getItemKey}
            headerClasses={columnClasses}
            headerValues={headerColumnTitles}
            hoverable={true}
            items={housekeepers}
            loadingItemIds={loadingIds}
            rowClasses={columnClasses}
            rowDisabledCondition={hk => shouldDisableHkRow(hk, zone)}
            rowValueGetters={tableRowValues}
          />
        )}
        {searching && <Loader />}
        {!hkCount && hasSearched && (
          <div>
            <strong>{t(Slugs.noResultsFound)}</strong>
          </div>
        )}
      </div>
    </>
  )
}

export default React.memo(AddHkSearchContent)
