import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { Housekeeper, HousekeepersState } from '../housekeepers.types'
import { hydrateRawHousekeeper } from '../housekeepers.utils'

const getHousekeepersState = (state: ApplicationState): HousekeepersState =>
  state.housekeepers

const getId = (_state: ApplicationState, id: string): string => id

export const getHkById = createSelector(
  getHousekeepersState,
  getId,
  (hksState, hkId): Housekeeper | undefined => {
    const rawHousekeeper = hksState.data[hkId]
    return rawHousekeeper && hydrateRawHousekeeper(rawHousekeeper)
  },
)
