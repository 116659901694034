import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

export const UnitDrawerStyles = {
  Divider: styled.div`
    background-color: ${colors.midnight10};
    border: 0;
    height: 8px;
  `,

  Icon: styled(SvgIcon)`
    color ${colors.midnight70};
  `,

  Section: styled.section`
    padding: 20px 16px;
  `,

  SectionContent: styled.div`
    font-size: 14px;
    padding-left: 24px;
    width: 100%;
  `,

  SectionSubTitle: styled.div`
    color: ${colors.midnight};
    display: flex;
    font-size: 12px;
    grid-column-gap: 8px;
    line-height: 16px;
    margin-bottom: 8px;
    margin-top: 24px;
    text-transform: uppercase;
  `,

  SectionTitle: styled.div`
    font-size: 16px;
    font-weight: bold;
  `,

  SubSection: styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  `,
}
