import { deprecated } from 'typesafe-actions'

import { CleanDrawerState, UiActionTypes } from '../ui.types'

const { createStandardAction } = deprecated

export const setCleanDrawerStateAction = createStandardAction(
  UiActionTypes.SET_CLEAN_DRAWER_STATE,
)<Partial<CleanDrawerState>>()

export const setCleanDrawerState =
  (newState: Partial<CleanDrawerState>) => dispatch => {
    dispatch(setCleanDrawerStateAction(newState))
  }
