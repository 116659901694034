import styled from '@emotion/styled'
import React from 'react'

import { Loader, LoaderAlignment, CleanCheckOutDisplay } from 'packages/common'
import { cleanIsMidstay } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans/cleans.types'
import {
  getFormattedCheckInDate,
  getFormattedCheckOutDate,
  getFormattedDueDate,
} from 'app/hkhub/utils'

const St = {
  Container: styled.div``,

  Grid: styled.div`
    display: grid;
    /* 130px is about ideal when there is enough room
    minmax/min-content allows the text to collapse to multiple lines on smaller screens */
    grid-template-columns: minmax(min-content, 130px) 1fr;
  `,

  Key: styled.span`
    align-items: center;
    color: ${colors.midnight80};
    display: flex;
    font-size: 12px;
    font-weight: 700;
    grid-gap: 8px;
    padding-right: 12px;
    text-transform: uppercase;
  `,

  LoaderWrapper: styled.div`
    height: 100%;
    position: relative;
    width: 50px;
  `,

  Value: styled.span`
    font-size: 16;
    font-weight: 400;
  `,
}

const NextResLoader = React.memo(() => (
  <St.LoaderWrapper>
    <Loader alignment={LoaderAlignment.left} size={8} />
  </St.LoaderWrapper>
))

/**
 * Builds and returns an object with all of the data associated with
 * displaying a Clean within a date cell in the calendar view.
 */
const parseDateData = (clean: Clean, date: Date) => ({
  formattedCheckIn: getFormattedCheckInDate(clean, date),
  formattedCheckOut: getFormattedCheckOutDate(clean, date),
  formattedDueDate: getFormattedDueDate(clean, date),
})

type CleanDateInfoSection =
  | 'startValue'
  | 'endValue'
  | 'due'
  | 'extraCleaningDays'

export type CleanDateInfoProps = {
  className?: string
  clean: Clean
  date: Date
  isLoadingNextReservation?: boolean
  omitSections?: CleanDateInfoSection[]
}

export const CleanDateInfo: React.FunctionComponent<CleanDateInfoProps> = ({
  className = '',
  clean,
  date,
  isLoadingNextReservation = false,
  omitSections = [],
}) => {
  const { t } = useI18n()
  const { formattedCheckIn, formattedDueDate } = parseDateData(clean, date)
  const checkInDisplayValue = React.useMemo(() => {
    return formattedCheckIn || '-'
  }, [formattedCheckIn])

  const shouldRender = (section: CleanDateInfoSection) =>
    !omitSections.includes(section)

  const extraCleaningDays = clean.reservation.extraCleaningDays

  return (
    <>
      {cleanIsMidstay(clean) ? (
        <St.Grid className={className}>
          {shouldRender('startValue') && (
            <>
              <St.Key>{t(Slugs.start)}</St.Key>
              <span>{'9:00am'}</span>
            </>
          )}
          {shouldRender('endValue') && (
            <>
              <St.Key>{t(Slugs.due)}</St.Key>
              <span>{'11:00am'}</span>
            </>
          )}
        </St.Grid>
      ) : (
        <St.Grid className={className}>
          {shouldRender('startValue') && (
            <>
              <St.Key>
                <SvgIcon size={12} icon={IconName.leftArrow} />
                {t(Slugs.checkOut)}
              </St.Key>
              <span>
                <CleanCheckOutDisplay clean={clean} />
              </span>
            </>
          )}

          {shouldRender('extraCleaningDays') && (
            <>
              <St.Key>
                <SvgIcon size={12} icon={IconName.sprayBottle} />
                {t(Slugs.extraCleanDays)}
              </St.Key>
              <span>{extraCleaningDays}</span>
            </>
          )}
          {shouldRender('due') && (
            <>
              <St.Key>
                <SvgIcon size={12} icon={IconName.calendarClean} />{' '}
                {t(Slugs.due)}
              </St.Key>
              <span>{formattedDueDate}</span>
            </>
          )}
          {shouldRender('endValue') && (
            <>
              <St.Key>
                <SvgIcon size={12} icon={IconName.rightArrow} />
                {t(Slugs.nextCheckIn)}
              </St.Key>

              <St.Value>
                {isLoadingNextReservation ? (
                  <NextResLoader />
                ) : (
                  checkInDisplayValue
                )}
              </St.Value>
            </>
          )}
        </St.Grid>
      )}
    </>
  )
}
