import styled from '@emotion/styled'
import * as React from 'react'

import { isInProgressStatus, isCompleteStatus } from 'packages/grimoire'
import { TranslatorFn } from 'packages/i18n'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans/cleans.types'

import { getFormattedTime } from './CleanTimeSection.helpers'

export const TimeSection = styled.div`
  color: ${colors.dusk70};
  font-size: 12px;
  letter-spacing: 0.5px;
  padding-left: 20px;
`

const getTimeText = (t: TranslatorFn, clean: Clean): string | null => {
  if (isCompleteStatus(clean.realtimeStatus)) {
    return getFormattedTime(clean.completedAt)
  }

  if (isInProgressStatus(clean.realtimeStatus)) {
    return `${t(Slugs.started)} ${getFormattedTime(clean.startedAt)}`
  }

  return null
}

export enum CleanTimeSectionTestIds {
  container = 'CleanTimeSection__container',
}

type CleanTimeSectionProps = {
  clean: Clean
}

export const CleanTimeSection: React.FC<CleanTimeSectionProps> = ({
  clean,
}) => {
  const { t } = useI18n()
  const timeText = getTimeText(t, clean)

  return timeText ? (
    <TimeSection data-testid={CleanTimeSectionTestIds.container}>
      {timeText}
    </TimeSection>
  ) : null
}
