import { produce, current } from 'immer'
import { sortBy } from 'lodash'
import { isEqual, map, pipe, prop, sortedUniq } from 'lodash/fp'

import {
  Assignment,
  AssignmentRejectionReason,
} from 'app/hkhub/store/assignments'
import { Clean } from 'app/hkhub/store/cleans'
import { HkEmployeeType, Housekeeper } from 'app/hkhub/store/housekeepers'

export const MAX_HKS = 5

export type SelectedHousekeeperList = (Housekeeper | null)[]
export type SelectedHousekeeperIdList = (string | null)[]

export type HkSelectConfig = {
  displayName: string
  hkId: string
  rejectionReason?: string
}

export type AssignmentEditorState = {
  assignmentRejectionReasonMap: Record<string, AssignmentRejectionReason>
  hasUnsavedChanges: boolean
  housekeepers: Housekeeper[]
  isInvalidTeamClean: boolean
  originalHousekeeperIds: SelectedHousekeeperIdList
  selectedHousekeepers: SelectedHousekeeperList
}

/**
 * Returns a list of unique Housekeeper IDs from the provided list of Housekeepers
 * @param housekeepers
 */
export const hkIdList = pipe(map(prop('id')), sortedUniq)

const contractorEmployeeTypes = [
  HkEmployeeType.contractor,
  HkEmployeeType.agency,
]

const hkCanDoTeamClean = (hk: Housekeeper) =>
  !contractorEmployeeTypes.includes(hk.employeeType)

const getIsInvalidTeamClean = (hks: (Housekeeper | null)[]): boolean => {
  const selectedHks = hks.filter(hk => !!hk)
  return selectedHks.length > 1
    ? selectedHks.reduce(
        (acc, hk) => (acc ? acc : !hkCanDoTeamClean(hk as Housekeeper)),
        false as boolean,
      )
    : false
}

export const getHasUnsavedChanges = (state: AssignmentEditorState): boolean => {
  return !isEqual(
    sortBy(state.originalHousekeeperIds),
    sortBy(hkIdList(state.selectedHousekeepers).filter(hk => !!hk)),
  )
}

type InitialStateReducerConfig = {
  clean: Clean
  housekeepers: Housekeeper[]
  rejectedAssignments: Assignment[]
}

export const initialStateReducer = ({
  clean,
  housekeepers,
  rejectedAssignments,
}: InitialStateReducerConfig): AssignmentEditorState => {
  const { assignedHks = [] } = clean

  const selectedHousekeepers = assignedHks.length ? assignedHks : [null]
  const originalHousekeeperIds = hkIdList(assignedHks)

  const assignmentRejectionReasonMap = rejectedAssignments.reduce(
    (acc, rejectedAssignment) => {
      if (rejectedAssignment.rejectionReason) {
        acc[rejectedAssignment.housekeeperId] =
          rejectedAssignment.rejectionReason
      }

      return acc
    },
    {} as Record<string, AssignmentRejectionReason>,
  )

  return {
    assignmentRejectionReasonMap,
    hasUnsavedChanges: false,
    housekeepers,
    isInvalidTeamClean: getIsInvalidTeamClean(selectedHousekeepers),
    originalHousekeeperIds,
    selectedHousekeepers,
  }
}

const getValidationState = (state: AssignmentEditorState) => {
  return {
    hasUnsavedChanges: getHasUnsavedChanges(current(state)),
    isInvalidTeamClean: getIsInvalidTeamClean(state.selectedHousekeepers),
  }
}

export type AssignmentEditorAction =
  | { type: 'addHkSelect' }
  | { payload: { index: number; selectedHK: Housekeeper }; type: 'changeHK' }
  | { payload: { index: number }; type: 'removeHK' }

export const assignmentEditorReducer = (
  state: AssignmentEditorState,
  action: AssignmentEditorAction,
): AssignmentEditorState =>
  produce(state, draft => {
    switch (action.type) {
      case 'addHkSelect': {
        if (state.selectedHousekeepers.length < MAX_HKS) {
          draft.selectedHousekeepers.push(null)
        }

        return
      }

      case 'changeHK': {
        if (action.payload.index >= state.selectedHousekeepers.length) {
          return
        }

        draft.selectedHousekeepers[action.payload.index] =
          action.payload.selectedHK

        const validation = getValidationState(draft)
        draft.hasUnsavedChanges = validation.hasUnsavedChanges
        draft.isInvalidTeamClean = validation.isInvalidTeamClean
        return
      }

      case 'removeHK': {
        if (draft.selectedHousekeepers.length === 1) {
          draft.selectedHousekeepers = [null]
        } else {
          draft.selectedHousekeepers = draft.selectedHousekeepers.filter(
            (_, index) => index !== action.payload.index,
          )
        }

        const validation = getValidationState(draft)
        draft.hasUnsavedChanges = validation.hasUnsavedChanges
        draft.isInvalidTeamClean = validation.isInvalidTeamClean

        return
      }
    }
  })
