import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  UsersApiResponse,
  NormalizedUsersApiResponse,
  UsersServiceResponse,
} from './users.types'

const URL = '/users'

export const emptyUsersResponse: NormalizedUsersApiResponse = {
  normalized: { user: {} },
  raw: { data: [] },
}

export const usersService = {
  /**
   * Sends a GET request to the server to fetch the currently logged-in User.
   * @param params
   */
  async fetchCurrentUser(params: RequestOptions = {}): UsersServiceResponse {
    const response: UsersApiResponse = await api.get('/users/me', {
      params,
    })
    return get(response, 'data', emptyUsersResponse)
  },

  /**
   * Preforms a GET request for all users
   * @param params
   */
  async fetchUsers(
    params: RequestOptions = {},
    forceToken = '',
  ): UsersServiceResponse {
    const headers = forceToken ? { Authorization: `Bearer ${forceToken}` } : {}

    const response: UsersApiResponse = await api.get(URL, {
      params,
      headers,
    })
    return get(response, 'data', emptyUsersResponse)
  },
}
