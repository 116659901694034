import get from 'lodash/get' // eslint-disable-line

import {
  InspectionChecklist,
  InspectionFlag,
  taskIsClean,
  TaskJobType,
} from 'packages/grimoire'
import {
  createDateObject,
  DateFormat,
  format,
  isBefore,
} from 'packages/utils/dateHelpers'
import {
  findAllRelationshipIds,
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store/store.utils'

import { getHousekeeperById } from 'app/hkhub/store/housekeepers/selectors'
import { getUnitById } from 'app/hkhub/store/units/selectors'

import { Housekeeper, HousekeepersState } from '../housekeepers'
import { TaskPhotosState } from '../taskPhotos'
import { getTaskPhotosByIds } from '../taskPhotos/selectors/getTaskPhotosByIds'
import { RawAssignmnentChange, AssignmentChange } from '../tasks'
import { UnitsState } from '../units'
import { Visit } from '../visits'
import {
  Clean,
  CleanAttributeNames,
  CleansState,
  RawClean,
} from './cleans.types'
import { getReservationById } from './selectors'

/**
 * Helper function to convert a raw JsonAPI Clean into proper clean, with `attributes` expanded
 * to top-level properties, and all relationships fully populated.
 *
 * Note that all relationship-populating attempts are guarded, so in the off-chance that they cannot
 * be populated, the relationships will simply be an empty object.
 */
export const hydrateRawClean = (
  cleansState: CleansState,
  unitsState: UnitsState,
  hksState: HousekeepersState,
  taskPhotosState: TaskPhotosState,
  clean: RawClean,
): Clean => {
  // populate Reservation
  const reservationId = findRelationshipId(clean, 'reservation')
  const reservation = reservationId
    ? getReservationById(cleansState, reservationId)
    : {}

  // populate Unit
  const unitId = findRelationshipId(clean, 'unit')
  const unit = unitId ? getUnitById(unitsState, unitId) : {}

  // populate AssignedHks
  const rawAssignedHks = clean?.relationships?.assignedHousekeepers?.data || []

  const assignedHks = rawAssignedHks?.reduce((accum, { id }) => {
    return accum.concat(getHousekeeperById(hksState, id))
  }, [] as (Housekeeper | undefined)[])

  const assignmentChangeIdsUnsorted = findAllRelationshipIds(
    clean,
    'assignmentChanges',
  )

  const assignmentChanges = assignmentChangeIdsUnsorted
    .map(id => cleansState.assignmentChanges[id])
    .filter(Boolean)

  const assignmentChangeIds = assignmentChanges
    .sort((a, b) => {
      const aDate = createDateObject(a.attributes.changedAt)
      const bDate = createDateObject(b.attributes.changedAt)
      return isBefore(aDate, bDate) ? -1 : 1
    })
    .map(({ id }: RawAssignmnentChange) => id)

  // populate taskPhotos (optional)
  const photoIds = findAllRelationshipIds(clean, 'taskPhotos')
  const taskPhotos = getTaskPhotosByIds(taskPhotosState, photoIds)

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...transformNormalizedToTyped<Clean>(clean as any, CleanAttributeNames),
    assignedHks,
    assignmentChangeIds,
    reservation,
    taskPhotos,
    unit,
  } as unknown as Clean
}

/**
 * Converts and array of Dates into an object with 'yyyy-MM-dd' versions of those
 * dates as keys (value is simply 'true' as a placeholder).
 * This is primarily useful in selectors that need to compare dates in large numbers
 * of cleans for filtering date ranges.
 * @param dateRange
 */
export const getDateRangeMap = (dateRange: Date[]): Record<string, boolean> =>
  dateRange.reduce((acc, date) => {
    const dateKey = format(date, DateFormat.ApiUtcShort)
    acc[dateKey] = true
    return acc
  }, {})

export const isRawClean = (task: {
  attributes: { jobType: TaskJobType[] }
}): task is RawClean => {
  return taskIsClean(task.attributes)
}

/** Hub Specific Type Guard */
export const taskIsHubClean = (task: Clean | Visit): task is Clean =>
  taskIsClean(task)

export function enhanceInspectionFlags(
  inspectionChecklist: InspectionChecklist,
  inspectionFlags: InspectionFlag[] | null | undefined,
): InspectionFlag[] | undefined {
  if (!inspectionFlags) return undefined

  return inspectionFlags.map(flag => {
    const enhancedFlag = { ...flag }

    inspectionChecklist.checklists.forEach(checklist => {
      const item = checklist.items.find(item => item.id === flag.itemId)
      if (item) {
        enhancedFlag.title = item.title
      }
    })

    return enhancedFlag
  })
}

export const hydrateRawAssignmentChange = (
  rawAssignmentChange: RawAssignmnentChange,
): AssignmentChange => {
  return {
    ...rawAssignmentChange.attributes,
    id: rawAssignmentChange.id,
  }
}
