import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { unitsService } from '../units.service'
import { NormalizedUnitsApiResponse, UnitsActionTypes } from '../units.types'

export const fetchUnitByIdAction = createAsyncAction(
  UnitsActionTypes.FETCH_UNIT_BY_ID,
  UnitsActionTypes.FETCH_UNIT_BY_ID_SUCCESS,
  UnitsActionTypes.FETCH_UNIT_BY_ID_FAILURE,
)<
  RequestConfig<NormalizedUnitsApiResponse>,
  NormalizedUnitsApiResponse,
  Error
>()

/**
 * @deprecated This is a very old selector that does not adhere to our current patterns.
 * It is used in too many places to try to refactor it, but for any new usages,
 * please use `fetchUnitByIdStrict` instead.
 * @param unitId
 * @param params
 */
export const fetchUnitById =
  (unitId: string, params: RequestOptions = {}) =>
  async dispatch => {
    try {
      const request = unitsService.fetchUnitById.bind(null, unitId, params)
      const result = await dispatch(fetchUnitByIdAction.request({ request }))
      dispatch(fetchUnitByIdAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchUnitByIdAction.failure(error))
      throw error
    }
  }
