import { get } from 'lodash/fp'

import {
  DateFormat,
  createDateString,
  formatLocalized,
  isSameDay,
} from 'packages/utils/dateHelpers'

import type { Clean } from 'app/hkhub/store/cleans'

export function formatCleanDateAtPath(
  clean: Clean,
  pathToDateAttr: string[],
  dateFormat: string,
  date: Date | string,
): string {
  const targetDate = get(pathToDateAttr)(clean)
  const dateString = typeof date === 'string' ? date : createDateString(date)
  const format = isSameDay(targetDate, dateString)
    ? DateFormat.TimeWithAmPm
    : dateFormat

  return formatLocalized(targetDate, format, clean.unit.tz)
}

/**
 * Builds a nice clean stringified display for the checkout date.
 * For same-day checkouts, this will simply be the time. Otherwise, it will be a full date string.
 */
export const getFormattedCheckOutDate = (clean: Clean, date: Date): string =>
  formatCleanDateAtPath(
    clean,
    ['reservation', 'checkOut'],
    DateFormat.Full,
    date,
  )

/**
 * Builds a nice clean stringified display for the checkin date.
 * For same-day checkouts, this will simply be the time. Otherwise, it will be a full date string.
 * Note that there is a possibility that there is no next checkin booked yet, in which case it will return undefined.
 */
export const getFormattedCheckInDate = (
  clean: Clean,
  date: Date,
): string | undefined => {
  if (!clean?.nextReservation?.checkIn) return undefined

  return formatCleanDateAtPath(
    clean,
    ['nextReservation', 'checkIn'],
    DateFormat.Full,
    date,
  )
}

/**
 * Builds a nice clean stringified display for the clean's due date.
 * For same-day cleans, this will simply be the time. Otherwise, it will be a full date string.
 */
export const getFormattedDueDate = (clean: Clean, date: Date): string =>
  formatCleanDateAtPath(clean, ['dueDate'], DateFormat.Full, date)
