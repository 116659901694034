import React from 'react'

import { useOptionalZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'

type MainPage = 'schedule' | 'housekeepers' | 'manage' | '404'

const pages: MainPage[] = ['housekeepers', 'manage', 'schedule']
const getCurrentPage = (): MainPage => {
  const path = window.location.pathname
  return pages.find(page => path.match(new RegExp(page, 'i'))) || '404'
}

type UseHubRouting = {
  getPageUrl: (nextPage: MainPage) => string
  mainPage: MainPage
}

export const useHubRouting = (): UseHubRouting => {
  const { zone } = useOptionalZoneContext()
  const mainPage = getCurrentPage()

  const getPageUrl = React.useCallback(
    (nextPage: MainPage) => {
      return `/zone/${zone?.id}/${nextPage}`
    },
    [zone],
  )

  return {
    getPageUrl,
    mainPage,
  }
}
