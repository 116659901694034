import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { getArrayOfIdsAndDataTypesFromAction } from 'packages/utils/store/store.utils'

import { emptyNormalizedReservationsData } from 'app/hkhub/store/reservations'
import { clearZoneAction } from 'app/hkhub/store/zones/actions'

import { fetchTaskByIdAction } from '../tasks/actions'
import { fetchTicketByIdAction } from '../tickets/actions'
import {
  fetchReservationByIdAction,
  fetchNextReservationByCleanAction,
  fetchNextReservationsByDateAction,
  fetchReservationsByZoneAndDateRangeAction,
} from './actions'
import { ReservationsState, RawReservation } from './reservations.types'

const initialState: ReservationsState = {
  byDateUnitHash: {},
  data: {},
  error: undefined,
  isLoading: false,
}

const actions = {
  clearZoneAction,
  fetchNextReservationByCleanAction,
  fetchNextReservationsByDateAction,
  fetchReservationByIdAction,
  fetchReservationsByZoneAndDateRangeAction,
  fetchTaskByIdAction,
  fetchTicketByIdAction,
}

type ReservationsActionsTypes = ActionType<typeof actions>

export const reservationsReducer = (
  state = initialState,
  action: ReservationsActionsTypes,
): ReservationsState =>
  produce(state, (draft: ReservationsState) => {
    switch (action.type) {
      case getType(actions.fetchReservationByIdAction.request): {
        draft.isLoading = true

        return
      }

      case getType(actions.fetchNextReservationByCleanAction.success):
      case getType(actions.fetchNextReservationsByDateAction.success): {
        const reservationArray =
          getArrayOfIdsAndDataTypesFromAction<RawReservation>(
            action,
            'reservation',
          )

        reservationArray.forEach(([id, res]) => {
          draft.byDateUnitHash[id] = res
        })

        draft.isLoading = false
        return
      }

      case getType(actions.fetchReservationByIdAction.success):
      case getType(actions.fetchTaskByIdAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedReservationsData

        Object.entries(normalized.reservation || {}).forEach(([id, res]) => {
          draft.data[id] = merge(state?.data[id] || {}, res)
        })

        draft.isLoading = false

        return
      }

      case getType(actions.fetchReservationsByZoneAndDateRangeAction.success): {
        const newData = action?.payload?.normalized?.reservation
        draft.data = {
          ...state.data,
          ...newData,
        }

        draft.isLoading = false
        return
      }

      case getType(actions.fetchNextReservationByCleanAction.failure):
      case getType(actions.fetchReservationsByZoneAndDateRangeAction.failure):
      case getType(actions.fetchNextReservationsByDateAction.failure): {
        draft.isLoading = false
        draft.error = action.payload
        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.data = {}
        draft.byDateUnitHash = {}
        return
      }
    }
  })
