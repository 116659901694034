import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  NormalizedTicketCommentsApiResponse,
  RawTicketComment,
  TicketComment,
  TicketCommentAttributeNames,
  TicketCommentsResponse,
} from './ticketComments.types'

export const emptyNormalizedTicketCommentsData: TicketCommentsResponse =
  Object.freeze({
    ticketComment: {},
  })

export const emptyTicketCommentsResponse: NormalizedTicketCommentsApiResponse =
  Object.freeze({
    normalized: emptyNormalizedTicketCommentsData,
    raw: { data: [] },
  })

export const hydrateRawTicketComment = (
  rawTicketComment: RawTicketComment,
): TicketComment =>
  transformNormalizedToTyped<TicketComment>(
    rawTicketComment,
    TicketCommentAttributeNames,
  )
