import styled from '@emotion/styled'
import React from 'react'

import { cleanIsDeepClean } from 'packages/grimoire'
import { parseBeds } from 'packages/grimoire/src/unit/unit.helpers'
import { IconName, SvgIcon } from 'packages/iconic'
import { convertHoursToTimeString } from 'packages/utils/mathHelpers'

import {
  CardSize,
  cardSizeGreaterThan,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { ScheduleEntityType } from 'app/hkhub/components/schedule/schedule.types'
import { Clean } from 'app/hkhub/store/cleans'

import { DeepCleanBadge } from '../DeepCleanBadge'

const St = {
  Container: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,
  IconSection: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding-left: 8px;
  `,
  LeftSideContainer: styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  `,
  OwnerInfo: styled.div`
    align-items: center;
    display: flex;
  `,
  OwnerText: styled.span`
    font-size: 14px;
    padding-left: 2px;
    line-height: 17px;
  `,
  PredictedCleanTime: styled.span`
    font-size: 12px;
  `,
}

export enum CleanCardAdditionalInfoTestIds {
  container = 'CleanCardAdditionalInfo__container',
  owner = 'CleanCardAdditionalInfo__owner',
  stayIcons = 'CleanCardAdditionalInfo__stayIcons',
}

export type CleanCardAdditionalInfoProps = {
  clean: Clean
  entity: ScheduleEntityType
  size: CardSize
}

export const CleanCardAdditionalInfo: React.FC<
  CleanCardAdditionalInfoProps
> = ({ clean, entity, size }) => {
  const { predictedCleanTime, unit } = clean

  const notTiny = cardSizeGreaterThan(size, 'tiny')

  const bathrooms = `${unit?.bathrooms || '?'}`
  const beds = parseBeds(unit)

  const effectivePredictedCleanTime = cleanIsDeepClean(clean)
    ? Math.round(predictedCleanTime * 2.5)
    : predictedCleanTime

  const displayOwnerClean = notTiny && clean.isNextStayOwner
  return (
    <St.Container data-testid={CleanCardAdditionalInfoTestIds.container}>
      <St.LeftSideContainer>
        <DeepCleanBadge clean={clean} />
        <St.PredictedCleanTime>
          {convertHoursToTimeString(effectivePredictedCleanTime)}
        </St.PredictedCleanTime>

        {cardSizeGreaterThan(size, 'small') && entity === 'staff' && (
          <St.IconSection
            data-testid={CleanCardAdditionalInfoTestIds.stayIcons}
          >
            <SvgIcon
              centerItems={true}
              icon={IconName.bed}
              size={14}
              text={beds}
            />

            <SvgIcon
              centerItems={true}
              icon={IconName.bath}
              size={14}
              text={bathrooms}
            />

            {unit?.hottub && (
              <SvgIcon centerItems={true} icon={IconName.hotTub} size={14} />
            )}
          </St.IconSection>
        )}
      </St.LeftSideContainer>

      {displayOwnerClean && (
        <St.OwnerInfo data-testid={CleanCardAdditionalInfoTestIds.owner}>
          <SvgIcon icon={IconName.crown} size={16} />
          {cardSizeGreaterThan(size, 'medium') && (
            <St.OwnerText>Owner</St.OwnerText>
          )}
        </St.OwnerInfo>
      )}
    </St.Container>
  )
}
