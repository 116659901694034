import get from 'lodash/get' // eslint-disable-line
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'
import { transformNormalizedToZone } from 'app/hkhub/store/zones/utils/transformNormalizedToZone'

import {
  HousekeepersState,
  HkSearchResult,
  HkSearchResultsState,
  RawHousekeeper,
} from '../housekeepers.types'
import { getHkUserById } from './getHkUserById'

const parseHkData = (rawHk: RawHousekeeper, state: ApplicationState) => {
  const searchResults: HkSearchResultsState = state.housekeepers.searchResults
  const searchResultsState = searchResults as unknown as HousekeepersState

  const userId = get(rawHk, 'relationships.user.data.id')
  // this fixes an edge case where we could see a crash if a user clears search results
  //  while an "add/borrow HK" request is in flight (TPD-5666)
  // the crash happens in getHkUserById below, but that selector is used in too many places; too risky to fix there
  if (!searchResultsState.user[userId]) {
    return undefined
  }

  const user = getHkUserById(searchResultsState, userId)
  const borrowedZones = get(rawHk, 'relationships.borrowedZones.data').map(
    bz => bz.id,
  )

  const managerId = get(rawHk, 'relationships.manager.data.id')
  const manager = managerId
    ? getHkUserById(searchResultsState, managerId)
    : null

  // parse the HK's primary zone (if they have one)
  // note that, in the case that our search results do not have any zones currently stored, we need to fall back to
  // the "current zones" area of Redux to find our current zone, and use that instead
  // this can happen if your search returns ONLY HKs who have no current zones, in which case adding one to your current zone would not update correctly
  const zoneId = get(rawHk, 'relationships.zone.data.id')
  const rawZone =
    get(searchResults, ['zone', zoneId]) ||
    get(state, ['zones', 'data', zoneId])
  const zone = rawZone ? transformNormalizedToZone(rawZone) : undefined

  return {
    borrowedZones,
    employeeType: rawHk.attributes.employeeType,
    id: rawHk.id,
    manager,
    user,
    zone,
  }
}

type BucketedHksType = {
  available: HkSearchResult[]
  borrowed: HkSearchResult[]
  owned: HkSearchResult[]
}

const sortByFirstName = (a: HkSearchResult, b: HkSearchResult) =>
  a.user.firstName > b.user.firstName ? 1 : -1

const getBucketedHkSearchResults = (state: ApplicationState) => {
  const searchResults = get(state, 'housekeepers.searchResults')
  const currentZoneId = get(state, 'zones.currentZones[0]')
  const hks = Object.values(searchResults.housekeeper) as RawHousekeeper[]

  const hkBuckets = hks.reduce(
    (accum, hk) => {
      // skip this HK/user altogether if any issues occur in finding it
      const parsedHk = parseHkData(hk, state)
      if (!parsedHk) {
        return accum
      }

      // check if HK is currently borrowed in this zone
      if (parsedHk.borrowedZones.includes(currentZoneId)) {
        return {
          ...accum,
          borrowed: [...accum.borrowed, parsedHk],
        }
      }

      if (get(parsedHk, 'zone.id') === currentZoneId) {
        return {
          ...accum,
          owned: [...accum.owned, parsedHk],
        }
      }

      // otherwise, this HK is available for borrowing/adding
      return {
        ...accum,
        available: [...accum.available, parsedHk],
      }
    },
    {
      available: [],
      borrowed: [],
      owned: [],
    } as BucketedHksType,
  )

  hkBuckets.available.sort(sortByFirstName)
  hkBuckets.borrowed.sort(sortByFirstName)
  hkBuckets.owned.sort(sortByFirstName)

  return [...hkBuckets.available, ...hkBuckets.borrowed, ...hkBuckets.owned]
}

/**
 * Returns the current Hk search results (if any).
 * Returns an empty array if search results are present.
 *
 * Note that this currently does not return a FULL Hk object, but only the subset
 * of data needed for displaying search results. (HkUser)
 */
export const getHkSearchResults = createSelector(
  getBucketedHkSearchResults,
  (hkBuckets): HkSearchResult[] => hkBuckets,
)
