import React from 'react'

import { Clean } from 'app/hkhub/store/cleans'

import { AssignmentChangeList } from './AssignmentChangeList'
import { AssignmentEditorContainer } from './AssignmentEditor/AssignmentEditor.container'
import { AssignmentSection } from './AssignmentSection'

export enum CleanAssignmentsTestIds {
  container = 'CleanAssignments__container',
}

export type CleanAssignmentsProps = {
  clean: Clean
  cleanIsEditable: boolean
  fetchingClean: boolean
}

export const CleanAssignments: React.FC<CleanAssignmentsProps> = React.memo(
  ({ clean, cleanIsEditable, fetchingClean }) => {
    const [isEditing, setIsEditing] = React.useState(false)
    return isEditing ? (
      <AssignmentEditorContainer
        clean={clean}
        onCancelEditClick={() => setIsEditing(false)}
      />
    ) : (
      <>
        <AssignmentSection
          assignedHks={clean.assignedHks}
          editable={cleanIsEditable}
          onEditClick={() => setIsEditing(true)}
        />
        <AssignmentChangeList
          assignmentChangeIds={clean.assignmentChangeIds}
          isLoading={fetchingClean}
        />
      </>
    )
  },
)
