import styled from '@emotion/styled'
import React from 'react'

import { centerWithFlex, colors } from 'packages/styles'

import { ImageThumbnail, ImageThumbnailProps } from '../../ImageThumbnail'
import { Loader } from '../../Loader'

type WrapperProps = Pick<
  ImageUploaderProps,
  'error' | 'size' | 'thumbnailUri'
> & {
  disabled: boolean
}

const St = {
  DeleteButton: styled.div`
    background: ${colors.alert};
    border: none;
    border-radius: 50%;
    box-shadow: 0 5px 11px rgba(0, 0, 0, 0.2);
    color: white;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    height: 28px;
    line-height: 24px;
    position: absolute;
    right: -10px;
    text-align: center;
    top: -10px;
    width: 28px;
    z-index: 1;
  `,

  ImgInput: styled.input`
    display: none;
  `,

  Overlay: styled.div`
    background: white;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity 100ms ease;
    width: 100%;

    &:hover {
      opacity: 0.3;
    }
  `,

  Wrapper: styled.label<WrapperProps>`
    ${centerWithFlex};
    border: none; // will use ImageThumbnail's border instead
    border-radius: 12px;
    font-size: 13px;
    position: relative;
    user-select: none;

    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};

    color: ${({ error }) => (error ? colors.alert : colors.midnight60)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  `,
}

export enum ImageUploaderTestIds {
  overlay = 'ImageUploader__overlay',
}

export type ImageUploaderProps = Pick<
  ImageThumbnailProps,
  'altText' | 'fallbackText' | 'size'
> & {
  disabled?: boolean
  error?: string
  /** Value to use for the native HTML 'title' attribute */
  htmlTitle: string
  /** ID field to apply as HTML native ID. Note that this MUST be unique for each individual instance. */
  id: string
  isLoading?: boolean
  onClick: React.MouseEventHandler<HTMLInputElement>
  onDelete?: React.MouseEventHandler<HTMLDivElement>
  /** Callback for client selecting an image. Access it with `event.target.files[0]`. */
  onImageSelected: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Source for an existing image to display; if this is provided, no placeholder text/icons will render. */
  thumbnailUri: string
}

export const ImageUploader: React.FC<ImageUploaderProps> = React.memo(
  ({
    altText,
    disabled = false,
    error,
    fallbackText,
    htmlTitle,
    id,
    isLoading = false,
    onClick,
    onDelete,
    onImageSelected,
    size,
    thumbnailUri,
  }) => {
    const isInteractive = !disabled && !isLoading
    const shouldShowDeleteBtn = isInteractive && !!thumbnailUri && !!onDelete

    return (
      <St.Wrapper
        error={error}
        disabled={!isInteractive}
        htmlFor={id}
        size={size}
        thumbnailUri={thumbnailUri}
        title={htmlTitle}
      >
        {shouldShowDeleteBtn && (
          <St.DeleteButton
            aria-label="Delete Image"
            onClick={onDelete}
            role="button"
          >
            x
          </St.DeleteButton>
        )}

        <St.ImgInput
          accept="image/*"
          disabled={disabled}
          id={id}
          name={id}
          onChange={onImageSelected}
          onClick={onClick}
          readOnly={disabled}
          type="file"
        />

        <ImageThumbnail
          altText={altText}
          disableStatefulUi={true}
          fallbackText={fallbackText}
          size={size}
          thumbnailUri={thumbnailUri}
        />

        {isLoading && <Loader />}
        {isInteractive && (
          <St.Overlay data-testid={ImageUploaderTestIds.overlay} />
        )}
      </St.Wrapper>
    )
  },
)
