import { xor } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { apiFilterExcludeVisits } from 'packages/grimoire'
import { format } from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ReservationApiFields } from 'app/hkhub/store/reservations/reservations.types'

import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { CleanApiFields, CleansActionTypes } from '../cleans.types'

// omit inspectionChecklist from the "all cleans" request for performance reasons
const cleanFields = xor(['inspection_checklist'], CleanApiFields)

export const fetchCleansByZoneAndDateAction = createAsyncAction(
  CleansActionTypes.FETCH_CLEANS_BY_ZONE_AND_DATE,
  CleansActionTypes.FETCH_CLEANS_BY_ZONE_AND_DATE_SUCCESS,
  CleansActionTypes.FETCH_CLEANS_BY_ZONE_AND_DATE_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getParams = (
  zoneId: string,
  startDate: Date,
  endDate?: Date,
): RequestOptions => {
  const effectiveDate = endDate
    ? {
        range: [format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd')],
      }
    : format(startDate, 'yyyy-MM-dd')

  return {
    fields: {
      reservation: ReservationApiFields,
      task: cleanFields,
      unit: [],
    },
    filter: {
      effective_date: effectiveDate,
      is_active: true,
      'unit.zone': zoneId,
      ...apiFilterExcludeVisits,
    },
    include: ['assigned_housekeepers', 'reservation', 'unit', 'unit.lock_box'],
    page: { size: 1500 },
  }
}

export const fetchCleansByZoneAndDate = (
  zoneId: string,
  startDate: Date,
  endDate?: Date,
) => {
  return async dispatch => {
    try {
      const params = getParams(zoneId, startDate, endDate)
      const request = tasksService.fetchTasks.bind(null, params)
      const result = await dispatch(
        fetchCleansByZoneAndDateAction.request({ request }),
      )
      dispatch(fetchCleansByZoneAndDateAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchCleansByZoneAndDateAction.failure(error))
      throw error
    }
  }
}
