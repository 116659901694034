import styled from '@emotion/styled'
import React from 'react'

import {
  Button,
  Drawer,
  DrawerContainerProps,
  InputField,
} from 'packages/common'
import { colors } from 'packages/styles'

import { useCreateZoneDrawer } from './useCreateZoneDrawer'

const St = {
  CreateZoneDrawer: styled(Drawer)`
    min-width: 320px;
    padding: 16px;
    padding-top: 32px;
  `,

  Form: styled.form`
    margin-top: 24px;
  `,

  FormButtons: styled.div`
    padding-top: 16px;
  `,

  Title: styled.div`
    color: ${colors.dusk};
    font-size: 18px;
    font-weight: bold;
  `,
}

type CreateZoneDrawerProps = Pick<DrawerContainerProps, 'afterExit' | 'isOpen'>

export const CreateZoneDrawer: React.FC<CreateZoneDrawerProps> = React.memo(
  ({ afterExit, isOpen }) => {
    const { canSubmit, loading, onSubmit, onValueChange } =
      useCreateZoneDrawer()

    return (
      <>
        <St.CreateZoneDrawer afterExit={afterExit} isOpen={isOpen}>
          <St.Title>Create Zone</St.Title>

          <St.Form onSubmit={onSubmit}>
            <InputField
              clearable={true}
              label="Zone Name"
              onInputChange={onValueChange}
              placeholder={'Cannon Beach, OR'}
            />

            <St.FormButtons>
              <Button
                disabled={!canSubmit}
                isFormSubmit={true}
                isLoading={loading}
                buttonType={'primary'}
              >
                Create
              </Button>
            </St.FormButtons>
          </St.Form>
        </St.CreateZoneDrawer>
      </>
    )
  },
)
