import { AxiosResponse } from 'axios'

import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/utils/store'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type CustomInspectionItemsState = {
  data: JSONApiCustomInspectionItemMap
  error?: Error
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type CustomInspectionItemsResponse = {
  data: JSONApiCustomInspectionItemMap
}

export type NormalizedCustomInspectionItemsApiResponse =
  NormalizedJSONApiResponse<CustomInspectionItemsResponse>

export type CustomInspectionItemsServiceResponse =
  Promise<NormalizedCustomInspectionItemsApiResponse>

export type CustomInspectionItemsApiResponse =
  AxiosResponse<NormalizedCustomInspectionItemsApiResponse>

/**********************************************************
 * CUSTOM INSPECTION ITEMS TYPES
 *********************************************************/
export type CustomInspectionItemDeleteData = {
  itemId: string
  unitId: string
}

export type CustomInspectionItemPatchData = {
  itemId: string
  itemTitle: string
  unitId: string
}

export type CustomInspectionItemPatchApiAttributes = {
  item_title: string
}

export type CustomInspectionItemPostData = {
  itemTitle: string
  unitId: string
}

export type CustomInspectionItemPostApiAttributes = {
  item_title: string
}

type CustomInspectionItemRelKeys = 'unit'
export type CustomInspectionItemRelationships = {
  [key in CustomInspectionItemRelKeys]: ToOneRelationship
}

export type CustomInspectionItemsAttributes = {
  itemTitle: string
}

export const CustomInspectionItemsAttributeNames = ['itemTitle']

export type CustomInspectionItemsRelationships = {
  unit: ToOneRelationship
}

export type RawCustomInspectionItem = JSONApiObjectWithRelationships<
  CustomInspectionItemsAttributes,
  CustomInspectionItemsRelationships
>

export type JSONApiCustomInspectionItemMap = JSONApiObjectWithRelationshipsMap<
  CustomInspectionItemsAttributes,
  CustomInspectionItemsRelationships
>

export type CustomInspectionItem = {
  id: string
} & CustomInspectionItemsAttributes
