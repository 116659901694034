import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { layers } from 'packages/styles'

import { ScheduleSizes } from '../../helpers/scheduleMeasure'

const SHIMMER_WIDTH = '1200px'

const shimmer = keyframes`
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: ${SHIMMER_WIDTH} 0;
  }
`

export const ResShimmer = styled.div`
  animation: ${shimmer} 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: ${SHIMMER_WIDTH} 100%;
  height: ${ScheduleSizes.ReservationsGutterHeight}px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${layers.shimmer};
`
