import { useSelector } from 'react-redux'

import { ApplicationState } from 'app/hkhub/store/store'
import { UnitDateVisitBuckets } from 'app/hkhub/store/visits'
import { getVisitBucketsByUnitAndDate } from 'app/hkhub/store/visits/selectors/getVisitBucketsByUnitAndDate'

import { useScheduleContext } from '../../../common/VirtualizedSchedule/VirtualizedSchedule.context'

export const useUnitVisits = (): { visitBuckets: UnitDateVisitBuckets } => {
  const { dateRange } = useScheduleContext()

  const visitBuckets = useSelector((state: ApplicationState) =>
    getVisitBucketsByUnitAndDate(state, dateRange),
  )

  return { visitBuckets }
}
