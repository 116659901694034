import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get' // eslint-disable-line
import startCase from 'lodash/startCase'
import * as React from 'react'

import {
  AsyncSearch,
  ContentModal,
  Alert,
  DataTable,
  Loader,
} from 'packages/common'
import { TranslatorFn } from 'packages/i18n'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Unit } from 'app/hkhub/store/units/units.types'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import styles from './AddUnitModal.module.scss'

const unitInCurrentZone = (unit, zone) => get(unit, 'zone.id') === zone.id

const createZoneColumn = (unit: Unit, zone: Zone, t: TranslatorFn) => {
  return (
    <>
      <div>{get(unit, 'zone.name', '')}</div>
      {unitInCurrentZone(unit, zone) && (
        <div className={styles.subText}>{t(Slugs.alreadyInZone)}</div>
      )}
    </>
  )
}

const getTableSettings = (t: TranslatorFn) => ({
  columnClasses: [styles.nameColumn, styles.addressColumn],
  getItemKey: (unit: Unit) => unit.id,
  headerColumnTitles: [startCase(t(Slugs.unitId)), startCase(t(Slugs.zone))],
})

export type AddUnitModalProps = {
  /** Callback for adding a Unit to a zone */
  addUnitsToZone: (unit: Unit) => void
  /** Callback for after the Modal has completely finished exiting & hiding */
  afterClose: () => void // eslint-disable-line react/no-unused-prop-types
  /** Callback for clearing the current set of Unit search results */
  clearUnits: () => void
  /**
   * (Optional) Error message to display
   * When present, this will be displayed in an Alert
   */
  errorMessage?: string
  /** Whether a search has occured that has not been cleared */
  hasSearched: boolean
  /** Set of IDs (if any) that should display a "loading" state */
  loadingIds?: string[]
  /** Callback for processing a unit search */
  searchUnits: (value: string) => void
  /** Whether there is currently a pending search operation */
  searching: boolean
  /** Whether the modal is currently active */
  showing: boolean // eslint-disable-line react/no-unused-prop-types
  /** List of Units to display in the list */
  units: Unit[]
  zone: Zone
}

export const ModalContent: React.FC<AddUnitModalProps> = props => {
  const {
    addUnitsToZone,
    clearUnits,
    errorMessage,
    hasSearched,
    loadingIds = [],
    searching,
    searchUnits,
    units = [],
    zone,
  } = props
  const { t, tp } = useI18n()

  const unitCount = units.length
  const actions = [
    {
      callback: addUnitsToZone,
      condition: unit => {
        return !unit.zone
      },
      title: startCase(t(Slugs.add)),
    },
  ]

  const { columnClasses, getItemKey, headerColumnTitles } = React.useMemo(
    () => getTableSettings(t),
    [t],
  )

  return (
    <div className={styles.addUnitModalContent}>
      {errorMessage && <Alert alertType={'danger'}>{errorMessage}</Alert>}

      <div className={styles.searchSection}>
        <AsyncSearch
          focusOnMount={true}
          fullWidth={false}
          onClearValue={clearUnits}
          onSearchTimeout={searchUnits}
          placeholder={t(Slugs.searchByUnitId)}
          styleOverrides={{ width: 400 }}
        />
        {!!unitCount && (
          <div className={styles.resultsCount}>
            {tp(Slugs.result, unitCount)}
          </div>
        )}
      </div>

      <div
        className={classNames(styles.contentSection, {
          [styles.emptySearchWrapper]: searching && !unitCount,
        })}
      >
        {!!unitCount && (
          <div data-testid="add-unit-modal-unit-list">
            <DataTable
              actions={actions}
              getItemKey={getItemKey}
              headerClasses={columnClasses}
              headerValues={headerColumnTitles}
              hoverable={true}
              items={units}
              rowDisabledCondition={unit => unitInCurrentZone(unit, zone)}
              loadingItemIds={loadingIds}
              rowClasses={columnClasses}
              rowValueGetters={[
                (unit: Unit) => unit.unitCode,
                (unit: Unit) => createZoneColumn(unit, zone, t),
              ]}
            />
          </div>
        )}
        {searching && <Loader />}
        {!unitCount && hasSearched && (
          <div>
            <strong>{t(Slugs.noResultsFound)}</strong>
            <div>{t(Slugs.onlyAbleToAddActiveUnitsWithReservations)}</div>
          </div>
        )}
      </div>
    </div>
  )
}

const AddUnitModal: React.FC<AddUnitModalProps> = props => {
  const { t } = useI18n()
  return (
    <ContentModal
      {...props}
      maxWidth={800}
      title={capitalize(t(Slugs.addUnit))}
    >
      <ModalContent {...props} />
    </ContentModal>
  )
}

/**
 * The full-size modal/page for searching/adding units to a zone.
 *
 * The guts of the modal content is pulled into a separate component in this file
 * so that we can easily test it without worrying about the Modal transitions getting in the way.
 *
 * The state and Redux handling is handled by the adjacent "wrapper" component. In general,
 * _you should probably not need to use this component directly_.
 */
export default React.memo(AddUnitModal)
