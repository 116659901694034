import { createAsyncAction } from 'typesafe-actions'

import {
  RequestOptions,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { housekeepersService } from '../housekeepers.service'
import {
  HousekeepersActionTypes,
  NormalizedHousekeepersApiResponse,
} from '../housekeepers.types'

export const fetchHousekeepersByZoneAction = createAsyncAction(
  HousekeepersActionTypes.FETCH_HOUSEKEEPERS_BY_ZONE,
  HousekeepersActionTypes.FETCH_HOUSEKEEPERS_BY_ZONE_SUCCESS,
  HousekeepersActionTypes.FETCH_HOUSEKEEPERS_BY_ZONE_FAILURE,
)<
  RequestConfig<NormalizedHousekeepersApiResponse>,
  NormalizedHousekeepersApiResponse,
  Error
>()

// TODO: add fields here
export const getParams = (zoneId: string): RequestOptions => {
  return {
    filter: {
      related_to_zone: zoneId,
    },
    include: ['borrowed_zones', 'tier', 'user', 'zone'],
    page: { size: 1500 },
  }
}

export const fetchHousekeepersByZone = (zoneId: string) => async dispatch => {
  try {
    const params = getParams(zoneId)
    const request = housekeepersService.fetchHousekeepers.bind(null, params)
    const response = await dispatch(
      fetchHousekeepersByZoneAction.request({ request }),
    )
    dispatch(fetchHousekeepersByZoneAction.success(response))
    return response.normalized
  } catch (error) {
    dispatch(fetchHousekeepersByZoneAction.failure(error))
    throw error
  }
}
