import classNames from 'classnames'
import React from 'react'

import styles from './Switch.module.scss'

export type SwitchProps = {
  checked: boolean
  className?: string
  dataTestId?: string
  disabled?: boolean
  id?: string
  onToggle: (value: boolean) => void
}

export const Switch: React.FC<SwitchProps> = React.memo(
  ({ checked, className, dataTestId, disabled, id, onToggle }) => {
    const [focused, setFocused] = React.useState(false)

    const toggleChecked = React.useCallback(() => {
      if (disabled) return
      onToggle(!checked)
    }, [checked, disabled, onToggle])

    return (
      <div
        className={classNames(styles.switch, className, {
          [styles.checked]: checked,
          [styles.disabled]: disabled,
          [styles.focused]: focused,
        })}
      >
        <input
          checked={checked}
          className={styles.inputElement}
          data-testid={dataTestId}
          disabled={disabled}
          id={id}
          onBlur={() => setFocused(false)}
          onChange={toggleChecked}
          onFocus={() => setFocused(true)}
          type="checkbox"
        />

        <div className={styles.well} onClick={toggleChecked}>
          <div className={styles.tab} />
        </div>
      </div>
    )
  },
)
