import get from 'lodash/get' // eslint-disable-line
import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from 'app/hkhub/store/store'
import {
  RawUser,
  User,
  UserAttributeNames,
} from 'app/hkhub/store/users/users.types'

import { getUserById } from '../../users/selectors/getUserById'
import { Zone, ZoneAttributeNames, ZonesState } from '../zones.types'

function normalizeManager(manager: RawUser, state: ZonesState): User {
  const user = get(state, `user[${manager.id}]`)
  return transformNormalizedToTyped<User>(user, UserAttributeNames)
}

const getApplicationState = (state: ApplicationState, _id: string) => state
const getZoneId = (_state: ApplicationState, id: string) => id

export const getZone = createSelector(
  getApplicationState,
  getZoneId,
  (state, id): Zone | undefined => {
    const rawZone = state.zones.data?.[id]
    if (!rawZone) {
      return undefined
    }

    const normalizedZone = transformNormalizedToTyped<Zone>(
      rawZone,
      ZoneAttributeNames,
    )

    const rawManagers: RawUser[] = rawZone.relationships?.managers
      ?.data as RawUser[]

    const managers = rawManagers
      ? rawManagers.map(m => normalizeManager(m, state.zones))
      : undefined

    const rawInvoiceApproverUserId =
      rawZone.relationships?.invoiceApproverUser?.data?.id

    const invoiceApproverUser =
      getUserById(state, rawInvoiceApproverUserId) || null

    return { ...normalizedZone, invoiceApproverUser, managers }
  },
)
