/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames'
import * as React from 'react'

import { Button } from '../../Button'
import { Loader } from '../../Loader'
import { DataTableAction } from '../DataTable'

import styles from './DataTableRow.module.scss'

const defaultValueGetter = item => item

export type DataTableRowProps = {
  actions?: DataTableAction[]
  /**
   * (Optional) List of CSS class names to apply to each column at the same index.
   *
   * - Note that these are just the (string) _names_ of the classes to be applied, not full React-style `style` objects.
   * - Each class in this list will be applied to the column at the same index in the `values` prop.
   * - If no `flex` overrides are provided, every column will default to `flex: 1 1 0`
   */
  classes?: string[]
  /**
   * Whether any options for this row should be disabled. This can be useful to toggle while API requests are pending,
   * in order to prevent multiple overlapping requests.
   * When true, the action text itself will not render, but the column around it will, thus preventing any layout changes in the table.
   */
  disableActions?: boolean
  /** Whether the row should have a hover state */
  hoverable?: boolean
  /** Callback to specify whether the row should be "disabled" (grayed out, no hover state) */
  isDisabled?: (item: any) => boolean
  /** The generic-typed item associated with this row */
  item: any
  /** Whether this row should show a loader */
  loading?: boolean
  /**
   * The list of functions to be used to parse the values for each column.
   *
   * Each function must take 1 arg (`item: T`) and return a string from it, based on the data
   * that should be displayed in the column at that index.
   *
   * Note that these values directly determine the number of columns that will be rendered.
   */
  valueGetters: ((item: any) => React.ReactNode)[]
}

const DataTableRow: React.FunctionComponent<DataTableRowProps> = ({
  actions = [],
  classes = [],
  disableActions,
  hoverable,
  isDisabled,
  item,
  loading,
  valueGetters: values,
}) => {
  const disabled = isDisabled ? isDisabled(item) : false
  return (
    <div
      className={classNames(styles.dataTableRow, {
        [styles.hoverable]: hoverable && !loading && !disabled,
        [styles.disabled]: disabled,
      })}
    >
      {values.map((getValue = defaultValueGetter, i) => (
        <div className={classNames(styles.column, classes[i])} key={i}>
          {getValue(item)}
        </div>
      ))}

      {!!actions.length && (
        <div className={classNames(styles.column, styles.actionColumn)}>
          {actions.map(action => {
            const { alwaysShow, buttonType, callback, condition, title } =
              action
            const shouldAlwaysShow = alwaysShow ? alwaysShow(item) : false
            const shouldRender = condition ? condition(item) : true
            const clickCallback = () => callback(item)

            return shouldRender ? (
              <Button
                className={classNames(styles.actionLink, {
                  [styles.showOnHover]: !shouldAlwaysShow,
                  [styles.removePadding]: !buttonType,
                })}
                disabled={disableActions}
                key={`action-${title}`}
                onClick={clickCallback}
                buttonType={buttonType || 'text'}
              >
                {title}
              </Button>
            ) : null
          })}
        </div>
      )}

      {loading && <Loader />}
    </div>
  )
}

/**
 * A row component with default styling for use within the `DataTable` component. You most likely will not
 * ever need to use this component directly, as `DataTable` will create it from its own props.
 *
 * Some quick usage notes:
 * - Column count is determined by the number of values included in the `values` prop.
 * - By default, all columns will be evenly spaced with `flex: 1 1 0` styling, but this can easily be overridden by providing accompanying override classes in the `classes` prop.
 */
export default React.memo(DataTableRow)
