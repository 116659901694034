import { deprecated } from 'typesafe-actions'

import { UiActionTypes, UnitDrawerState } from '../ui.types'

const { createStandardAction } = deprecated

export const setUnitDrawerStateAction = createStandardAction(
  UiActionTypes.SET_UNIT_DRAWER_STATE,
)<Partial<UnitDrawerState>>()

export const setUnitDrawerState =
  (newState: Partial<UnitDrawerState>) => dispatch => {
    dispatch(setUnitDrawerStateAction(newState))
  }
