import get from 'lodash/get' // eslint-disable-line

import { TranslatorFn } from 'packages/i18n'

import { Slugs } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { Unit } from 'app/hkhub/store/units/units.types'
import { User } from 'app/hkhub/store/users/users.types'

import { RemoveableEntity, RequestConfig } from './ZoneManagePage'

type ConfirmModalConfig = {
  modalText: string
  modalTitle: string
}

// provides a sane set of default values as a safe fallback, so we don't end up with an empty modal
export const defaultModalConfig = (t: TranslatorFn): ConfirmModalConfig => ({
  modalText: t(Slugs.areYouSure),
  modalTitle: t(Slugs.confirm),
})

/**
 * Builds an object containing the text and title that should be shown in the "delete entities" confirm dialog,
 * based on the provided RequestConfig data.
 *
 * The messaging will differ slightly based on:
 *    - The number of entities being removed
 *    - The type of entities being removed
 *
 * This helper function will build the correct messaging for any valid combination, as well as providing
 * fallback/default messaging for any invalid combinations.
 */
export function getConfirmModalConfig(
  requestConfig: RequestConfig,
  t: TranslatorFn,
): ConfirmModalConfig {
  // handle "remove Housekeeper" messaging
  if (requestConfig.entityType === RemoveableEntity.Housekeeper) {
    const hk = requestConfig.relation as Housekeeper
    const { firstName, lastName } = hk.user
    return {
      modalText: t(Slugs.removeHousekeeperNote, {
        hkName: `${firstName} ${lastName}`,
      }),
      modalTitle: t(Slugs.removeHousekeeperTitle),
    }
  }

  // handle "remove borrowed Housekeeper" messaging
  // mostly the same as "remove Housekeeper", but the relationship goes in the opposite direction
  if (requestConfig.entityType === RemoveableEntity.Zone) {
    const hk = requestConfig.owner as Housekeeper
    const { firstName, lastName } = hk.user
    return {
      modalText: t(Slugs.removeHousekeeperNote, {
        hkName: `${firstName} ${lastName}`,
      }),
      modalTitle: t(Slugs.removeHousekeeperTitle),
    }
  }

  // handle "remove Unit" messaging
  if (requestConfig.entityType === RemoveableEntity.Unit) {
    const unit = requestConfig.relation as Unit
    return {
      modalText: t(Slugs.removeUnitNote, {
        unitName: unit.unitCode,
      }),
      modalTitle: t(Slugs.removeUnitTitle),
    }
  }

  return { ...defaultModalConfig(t) }
}

function getSeperator(idx, length, t) {
  if (idx === length - 1) {
    // if this is the last manager, end the string
    return ''
  }

  if (idx === length - 2) {
    // if this is the second to last manager, add an "and"
    return ` ${t(Slugs.and)} `
  }

  return ', '
}

export function getManagerString(
  managersFromZone: User[] | undefined,
  t: TranslatorFn,
): string | undefined {
  if (!managersFromZone || !managersFromZone.length) {
    return undefined
  }

  const managers = managersFromZone.reduce((acc, m, idx, arr) => {
    return acc.concat(
      `${get(m, 'firstName', '')} ${get(m, 'lastName', '')}${getSeperator(
        idx,
        arr.length,
        t,
      )}`,
    )
  }, '')
  return `${t(Slugs.managedBy)} ${managers}`
}
