import styled from '@emotion/styled'
import { upperFirst } from 'lodash/fp'
import React from 'react'

import { ClipboardText } from 'packages/common'
import { Amenities } from 'packages/grimoire'
import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/hkhub/i18n'

import { UnitDrawerStyles } from '../../UnitDrawer.styles'

const St = {
  Content: UnitDrawerStyles.SectionContent,
  Details: UnitDrawerStyles.SectionContent,
  Icon: UnitDrawerStyles.Icon,
  Password: styled.div`
    margin-top: 2px;
  `,
  Title: UnitDrawerStyles.SectionSubTitle,
  WifiInfo: UnitDrawerStyles.SubSection,
}

export type WifiInfoProps = {
  amenities: Amenities
}

export const WifiInfo: React.FC<WifiInfoProps> = React.memo(({ amenities }) => {
  const { t } = useI18n()

  return amenities.wifi ? (
    <St.WifiInfo>
      <St.Title>
        <St.Icon icon={IconName.wifi} size={16} />
        <strong>{t(Slugs.wifi)}</strong>
      </St.Title>

      <St.Details>
        <div>
          <strong>{`${upperFirst(t(Slugs.network))}: `}</strong>
          <span>{amenities.wifiNetwork}</span>
        </div>

        <div>
          <strong>{`${upperFirst(t(Slugs.password))}`}</strong>
          {amenities.wifiPassword && (
            <St.Password>
              <ClipboardText>{amenities.wifiPassword}</ClipboardText>
            </St.Password>
          )}
        </div>
      </St.Details>
    </St.WifiInfo>
  ) : null
})
