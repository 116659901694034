import { createSelector } from 'reselect'

import {
  isEmployee,
  HousekeepersState,
  Housekeeper,
} from '../housekeepers.types'
import { getHousekeepers } from './getHousekeepers'

const sortByFirstName = (a: Housekeeper, b: Housekeeper) =>
  a.user.firstName.toUpperCase() > b.user.firstName.toUpperCase() ? 1 : -1

const getAllHousekeepers = (state: HousekeepersState) => getHousekeepers(state)

/**
 * Returns all hks currently available in the local store,
 * ordered by their `firstName` field
 */
export const getSortedHks = createSelector(
  getAllHousekeepers,
  (housekeepers): Housekeeper[] => {
    const employeeHks = housekeepers
      .filter(hk => isEmployee(hk))
      .sort(sortByFirstName)

    const otherHks = housekeepers
      .filter(hk => !isEmployee(hk))
      .sort(sortByFirstName)

    return [...employeeHks, ...otherHks]
  },
)
