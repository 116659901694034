import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { cleanRealtimeStatusIndicatorColorMap } from 'packages/common'
import { CleanRealtimeStatus } from 'packages/grimoire'
import { colors } from 'packages/styles'

import {
  CARD_MARGIN_Y,
  cardIsTiny,
  cardNotTiny,
  CardSize,
  staffCardSizeHeightMap,
  unitCardSizeHeightMap,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

import { ScheduleEntityType } from '../../../schedule.types'

type WithSizeAndStatus = {
  size: CardSize
  status: CleanRealtimeStatus
}

const shimmerAnimation = property => (color1, color2) =>
  keyframes`
  0% {
    ${property}: ${color1};
  }

  50% {
    ${property}: ${color2};
  }

  100% {
    ${property}: ${color1};
  }
`

const borderShimmer = shimmerAnimation('border-color')
const backgroundShimmer = shimmerAnimation('background-color')

const getBorder = ({ size, status }: WithSizeAndStatus): string => {
  if (cardIsTiny(size)) return 'none'

  const color = cleanRealtimeStatusIndicatorColorMap[status]
  return `6px solid ${color}`
}

const inProgressAnimation = animationFunc => status => {
  if (status === CleanRealtimeStatus.IN_PROGRESS_OVERDUE) {
    return animationFunc(colors.alert, '#84162C')
  }

  if (status === CleanRealtimeStatus.IN_PROGRESS) {
    return animationFunc(colors.lake, '#014972')
  }

  return 'none'
}

const getBorderAnimation = inProgressAnimation(borderShimmer)
const getBackgroundAnimation = inProgressAnimation(backgroundShimmer)

const withBorder = ({ size, status }: WithSizeAndStatus) => {
  return css`
    animation-name: ${getBorderAnimation(status)};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    border-left: ${getBorder({ size, status })};
  `
}

const withBackground = ({ size, status }: WithSizeAndStatus) => {
  const getTinyBackground = () => {
    return css`
      animation-name: ${getBackgroundAnimation(status)};
      animation-duration: 1s;
      animation-iteration-count: infinite;
      background: ${cleanRealtimeStatusIndicatorColorMap[status]};
    `
  }

  return cardNotTiny(size)
    ? css`
        background: white;
      `
    : getTinyBackground()
}

export const Card = styled.div<
  WithSizeAndStatus & {
    cardType: ScheduleEntityType
    isDeepClean: boolean
    isLoading: boolean
  }
>`
  ${props => withBackground(props)};

  border-radius: 2px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  display: flex;
  margin: ${CARD_MARGIN_Y}px;
  position: relative;

  border: ${props =>
    props.isDeepClean && cardIsTiny(props.size)
      ? '2px solid rgba(44, 52, 57, 0.8)'
      : 'none'};

  height: ${({ cardType, size }) =>
    `${
      cardType === 'staff'
        ? staffCardSizeHeightMap[size]
        : unitCardSizeHeightMap[size]
    }px`};

  &:not(:first-of-type) {
    margin-top: 0;
  }
`

const getTinyCardPadding = isDeepClean => (isDeepClean ? '0' : '2px')

export const CardContent = styled.div<
  WithSizeAndStatus & { isDeepClean: boolean }
>`
  ${props => withBorder(props)};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ isDeepClean, size }) =>
    cardNotTiny(size) ? '10px' : getTinyCardPadding(isDeepClean)};
  width: 100%;
`

export const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
