import { cleanIsDeepClean, getDeepCleanPredictedTime } from 'packages/grimoire'
import { roundToTenths } from 'packages/utils/mathHelpers'

import { getTasksForDate } from 'app/hkhub/components/schedule/helpers/cleanHelpers'
import { DateCleanBuckets, Clean } from 'app/hkhub/store/cleans/cleans.types'
import {
  Coverage,
  isNotWorkingCoverageTypes,
} from 'app/hkhub/store/coverage/coverage.types'

/**
 * Returns true if the coverage entry type is "covered" &
 * if it starts before 1am and ends after 11pm
 *
 * @param coverage
 * @returns boolean
 */
export function hasFullDayCovered(coverage: Coverage): boolean {
  const hourIntFrom = (isoDateTime: string): number => {
    return parseInt(isoDateTime.split('T')[1].split(':')[0])
  }

  const startHour = hourIntFrom(coverage.start)
  const endHour = hourIntFrom(coverage.end)

  return (
    isNotWorkingCoverageTypes.includes(coverage.coverageType) &&
    startHour === 0 &&
    endHour === 23
  )
}

/**
 * Returns the total hours worked for the cleans within the provided date, rounded
 * to the nearest tenths. This is based on the value of "predictedCleanTime"
 *
 * The value of team cleans is divided by the number HKs assigned to the clean.
 *
 * @param dateRange
 * @param cleans
 */
export function getHoursWorked(
  dateRange: Date[],
  cleans: DateCleanBuckets = {},
): number {
  return roundToTenths(
    dateRange.reduce((acc, date) => {
      const cleansForDate = getTasksForDate(cleans, date)

      return (
        acc +
        cleansForDate.reduce((total, clean: Clean) => {
          const effectivePredictedCleanTime = cleanIsDeepClean(clean)
            ? getDeepCleanPredictedTime(clean)
            : clean.predictedCleanTime
          return (
            total +
            effectivePredictedCleanTime / (clean.assignedHks?.length || 1)
          )
        }, 0)
      )
    }, 0),
  )
}
