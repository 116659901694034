import React from 'react'

import { UnitRealtimeStatus } from 'packages/grimoire'

import { StatusOrb } from '../../StatusOrb'
import {
  iconRenderMap,
  rendersIcon,
  hasIconForStatus,
  shouldAnimate,
  unitRealtimeStatusIndicatorMap,
} from './UnitRealtimeStatusIndicator.helpers'

export enum UnitRealtimeStatusIndicatorTestIds {
  alert = 'unitRealtimeStatusIndicator__alert',
  check = 'unitRealtimeStatusIndicator__check',
  guestOccupied = 'unitRealtimeStatusIndicator__guestOccupied',
  ownerHold = 'unitRealtimeStatusIndicator__ownerHold',
  status = 'unitRealtimeStatusIndicator__status',
  vacasaHold = 'unitRealtimeStatusIndicator__vacasaHold',
}

export type UnitRealtimeStatusIndicatorProps = {
  dataTestId?: string
  unitRealtimeStatus: UnitRealtimeStatus
}

export const UnitRealtimeStatusIndicator: React.FC<UnitRealtimeStatusIndicatorProps> =
  ({ dataTestId, unitRealtimeStatus }) => {
    if (!hasIconForStatus(unitRealtimeStatus)) {
      return null
    }

    if (rendersIcon(unitRealtimeStatus)) {
      const Icon = iconRenderMap[unitRealtimeStatus]
      return <Icon dataTestId={dataTestId} />
    }

    return (
      <StatusOrb
        dataTestId={dataTestId ?? UnitRealtimeStatusIndicatorTestIds.status}
        shouldAnimate={shouldAnimate(unitRealtimeStatus)}
        statusType={unitRealtimeStatusIndicatorMap[unitRealtimeStatus]}
      />
    )
  }
