import { createAsyncAction } from 'typesafe-actions'

import {
  RelationshipUpdate,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { zonesService } from '../zones.service'
import { NormalizedZonesApiResponse, ZonesActionTypes } from '../zones.types'

export const addHksToZoneAction = createAsyncAction(
  ZonesActionTypes.ADD_HKS_TO_ZONE,
  ZonesActionTypes.ADD_HKS_TO_ZONE_SUCCESS,
  ZonesActionTypes.ADD_HKS_TO_ZONE_FAILURE,
)<
  RequestConfig<NormalizedZonesApiResponse>,
  NormalizedZonesApiResponse,
  Error
>()

export const addHksToZone =
  (requestData: RelationshipUpdate) => async dispatch => {
    try {
      const request = zonesService.addRelationships.bind(
        null,
        requestData,
        'housekeeper',
      )
      const result = await dispatch(addHksToZoneAction.request({ request }))
      result.normalized.relationshipUpdate = requestData

      dispatch(addHksToZoneAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(addHksToZoneAction.failure(error))
      throw error
    }
  }
