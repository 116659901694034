import styled from '@emotion/styled'
import React from 'react'

import { cleanIsDeepClean, getDeepCleanPredictedTime } from 'packages/grimoire'
import { parseBeds } from 'packages/grimoire/src/unit/unit.helpers'
import { SvgIcon, IconName } from 'packages/iconic'
import { colors } from 'packages/styles/jss/colors.styles'
import { convertHoursToTimeString } from 'packages/utils/mathHelpers'

import { Clean } from 'app/hkhub/store/cleans/cleans.types'

import styles from './UnitDetailIcons.module.scss'

const St = {
  TeamCleanIcon: styled(SvgIcon)`
    color: ${colors.awareness};
    margin-left: 8px;
  `,
}

export enum UnitDetailIconsTestIds {
  bathrooms = 'UnitDetailIcons__bathrooms',
  beds = 'UnitDetailIcons__beds',
  teamClean = 'UnitDetailIcons__teamClean',
}

export type UnitDetailIconsProps = {
  clean: Clean
}

/**
 * The row of icons we use to display details about a given unit for a clean.
 *
 * This icon row is used in multiple places, but the layout changes depending on where it is,
 * so it is built to adjust its own layout based on the props passed in.
 */
export const UnitDetailIcons: React.FC<UnitDetailIconsProps> = React.memo(
  ({ clean }) => {
    const { assignedHks, predictedCleanTime, unit } = clean
    const effectivePredictedCleanTime = cleanIsDeepClean(clean)
      ? getDeepCleanPredictedTime(clean)
      : predictedCleanTime

    const bathrooms = `${unit?.bathrooms || '?'}`
    const beds = parseBeds(unit)

    return (
      <div className={styles.unitDetailIcons}>
        <SvgIcon
          centerItems={true}
          dataTestId={UnitDetailIconsTestIds.beds}
          icon={IconName.bed}
          size={14}
          text={beds}
        />

        <SvgIcon
          centerItems={true}
          dataTestId={UnitDetailIconsTestIds.bathrooms}
          icon={IconName.bath}
          text={bathrooms}
        />

        {unit.hottub && <SvgIcon centerItems={true} icon={IconName.hotTub} />}

        <span className={styles.predictedCleanTime}>
          {convertHoursToTimeString(effectivePredictedCleanTime)}
        </span>

        {assignedHks.length > 1 && (
          <St.TeamCleanIcon
            icon={IconName.zap}
            dataTestId={UnitDetailIconsTestIds.teamClean}
          />
        )}
      </div>
    )
  },
)
