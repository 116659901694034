// i18n.ts
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import i18next, { InitOptions, i18n } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export type I18nInitOptions = InitOptions

export enum I18nEventTypes {
  failedLoading = 'failedLoading',
  initialized = 'initialized',
  languageChanged = 'languageChanged',
  loaded = 'loaded',
  missingKey = 'missingKey',
}

const defaultOptions: I18nInitOptions = {
  detection: {
    lookupQuerystring: 'lng', // Corrected property name
    order: ['querystring', 'navigator'],
  },
}

export default class I18n {
  i18next: i18n

  constructor() {
    this.i18next = i18next
      .createInstance()
      .use(LanguageDetector)
      .use(initReactI18next)
  }

  subscribe(event: I18nEventTypes, callback: () => void) {
    this.i18next.on(event, callback)
  }

  unsubscribe(event: I18nEventTypes, callback: () => void) {
    this.i18next.off(event, callback)
  }

  async init(options?: I18nInitOptions) {
    await this.i18next.init({ ...defaultOptions, ...options })
    return { locale: this.i18next.language }
  }
}
