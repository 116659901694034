import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'

export const getIncompleteTicketCountByUnitId = createSelector(
  (state: ApplicationState) => state,
  (_, unitId: string) => unitId,
  (state, unitId): number => {
    //  If the unit ID does not exist in the store this selector returns zero
    return state.tickets.byUnitIncompleteTicketsHash?.[unitId] ?? 0
  },
)
