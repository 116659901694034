import styled from '@emotion/styled'

import { TicketSeverity } from 'packages/grimoire/src/ticket'
import { colors, text } from 'packages/styles'

export const severityColorMap = {
  [TicketSeverity.LOW]: colors.midnight10,
  [TicketSeverity.MEDIUM]: colors.midnight10,
  [TicketSeverity.PLANNED]: colors.midnight10,
  [TicketSeverity.PRIOR_TO_NEXT_GUEST]: colors.awareness40,
  [TicketSeverity.TODAY]: colors.alert40,
  [TicketSeverity.URGENT]: colors.alert40,
}

export const TicketStatusBar = styled.div<{ severity: TicketSeverity }>`
  background: ${props => severityColorMap[props.severity]};
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding: 6px 8px;
`

export const TicketSeverityText = styled.span`
  ${text.bodyBoldTiny};
  text-transform: uppercase;
`

export const TicketSection = styled.div`
  margin: 24px 24px;
`

export const TicketSectionTitle = styled.span`
  ${text.bodyBold};
  margin-bottom: 4px;
`

export const TicketStatusContainer = styled.div<{ isInProgress: boolean }>`
  align-items: center;
  background-color: ${colors.midnight4};
  border-top: 1px solid ${colors.midnight20};
  display: flex;

  /* negate the padding of the drawer itself */
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 16px;

  border-bottom: ${({ isInProgress }) =>
    isInProgress ? `1px solid ${colors.midnight20}` : 'none'};

  height: ${({ isInProgress }) => (isInProgress ? '100%' : '0')};
  padding: ${({ isInProgress }) =>
    isInProgress ? '16px 24px 16px 24px' : '0'};
`
