import debounce from 'lodash/debounce'
import * as React from 'react'

import { IconName } from 'packages/iconic'

import { InputField, InputFieldProps } from '../InputField'

const DEFAULT_SEARCH_TIMEOUT = 500

export type AsyncSearchProps = {
  /**
   * Callback for use to fetch the value after the search timeout duration has been reached.
   * This is what you will ultimately want to use for implementing the search functionality.
   */
  onSearchTimeout: (value: string) => void
  /**
   * Length in ms of the timeout for the search callback to be triggered.
   * If you do not provide a value, a sensible default will be used for you.
   */
  timeout?: number
} & InputFieldProps

const AsyncSearch: React.FunctionComponent<AsyncSearchProps> = props => {
  const { onSearchTimeout, timeout = DEFAULT_SEARCH_TIMEOUT } = props

  const debouncedOnSearchTimeout = React.useMemo(() => {
    return debounce(onSearchTimeout, timeout)
  }, [onSearchTimeout, timeout])

  const handleInputChange = React.useCallback(
    (value: string) => {
      if (value) {
        debouncedOnSearchTimeout(value)
      }
    },
    [debouncedOnSearchTimeout],
  )

  return (
    <InputField
      {...props}
      clearable={true}
      leftIcon={IconName.search}
      onInputChange={handleInputChange}
      placeholder={props.placeholder || 'Search...'}
    />
  )
}

/**
 * An InputField wrapper to use for debounced searching. For the most part, this is just a direct
 * wrapper of the InputField component, but with the extra debounced search handling (plus a nifty icon on the left).
 *
 * Note, for using this component, you will want to utilize the `onSearchTimeout` callback for your
 * actual searching functionality, and not the `onInputChange` callback exposed by InputField.
 */
export default React.memo(AsyncSearch)
