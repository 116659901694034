import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { capitalize, lowerCase, lowerFirst, upperFirst } from 'lodash/fp'
import React from 'react'
import { createBreakpoint } from 'react-use'

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuStyleOverrides,
} from 'packages/common'
import { equalOrSmaller } from 'packages/styles/useBreakpoint'
import { track } from 'packages/wiretap'
import { SegmentEvents } from 'packages/wiretap/src/tracking/events'

import {
  useScheduleMatchParams,
  useScheduleNavigation,
} from 'app/hkhub/components/schedule/hooks'
import { ScheduleEntityType } from 'app/hkhub/components/schedule/schedule.types'
import { Slugs, useI18n } from 'app/hkhub/i18n'

import { BrandTitleWrapper } from '../Titlebar.styles'

const useTranslations = (entity: ScheduleEntityType) => {
  const { t } = useI18n()

  return React.useMemo(() => {
    const titleTextSm =
      entity === 'unit'
        ? t(Slugs.scheduleMenuTitleSmUnit)
        : t(Slugs.scheduleMenuTitleSmStaff)

    const titleTextLg = entity === 'unit' ? t(Slugs.unit) : t(Slugs.staff)

    return {
      brandGreetingStart: t(Slugs.brandGreetingStart),
      menuOptionStaff: capitalize(t(Slugs.staff)),
      menuOptionUnit: capitalize(t(Slugs.unit)),
      schedule: lowerCase(t(Slugs.schedule)),
      titleLg: lowerFirst(titleTextLg), // lowerCase kills the apostrophe for some reason
      titleSm: upperFirst(titleTextSm),
    }
  }, [entity, t])
}

const CUSTOM_BREAKPOINT = 916
const useCustomBreakpoint = createBreakpoint({
  LG: CUSTOM_BREAKPOINT + 1,
  SM: CUSTOM_BREAKPOINT,
})

//----------------------------------------------------
// Mini component for rendering individual menu items
//----------------------------------------------------

type MenuItemProps = {
  active: boolean
  text: string
}

const StyledMenuItem = styled.span<{ active: boolean }>`
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`

const MenuItem: React.FC<MenuItemProps> = React.memo(({ active, text }) => (
  <StyledMenuItem active={active}>{text}</StyledMenuItem>
))

//----------------------------------------------------
// Primary menu rendering component
//----------------------------------------------------

const St = {
  TextContent: styled.span`
    ${equalOrSmaller(
      CUSTOM_BREAKPOINT,
      css`
        display: none;
        visibility: hidden;
      `,
    )};
  `,

  Wrapper: styled(BrandTitleWrapper)``,
}

const emotionOverrides: DropdownMenuStyleOverrides = {
  menuToggle: css`
    margin: 0 8px;
    padding: 0;
  `,
}

export enum ScheduleEntityMenuTestIds {
  container = 'ScheduleEntityMenu__container',
}

export const ScheduleEntityMenu: React.FC = () => {
  const { changeScheduleEntity } = useScheduleNavigation()
  const { entity } = useScheduleMatchParams()

  const strings = useTranslations(entity)
  const breakpoint = useCustomBreakpoint()
  const isLargeScreen = breakpoint === 'LG'

  const menuItems: DropdownMenuItem[] = React.useMemo(() => {
    return [
      {
        children: (
          <MenuItem
            active={entity === 'staff'}
            text={strings.menuOptionStaff}
          />
        ),
        key: 'staff',
        onClick: () => changeScheduleEntity('staff'),
      },
      {
        children: (
          <MenuItem active={entity === 'unit'} text={strings.menuOptionUnit} />
        ),
        key: 'unit',
        onClick: () => changeScheduleEntity('unit'),
      },
    ]
  }, [changeScheduleEntity, entity, strings])

  React.useEffect(() => {
    if (entity === 'staff') return
    track(SegmentEvents.hubUnitScheduleOpen)
  }, [entity])

  return (
    <St.Wrapper data-testid={ScheduleEntityMenuTestIds.container}>
      <St.TextContent>{strings.brandGreetingStart}</St.TextContent>
      <DropdownMenu emotionOverrides={emotionOverrides} items={menuItems}>
        {isLargeScreen ? strings.titleLg : strings.titleSm}
      </DropdownMenu>
      <St.TextContent>{strings.schedule}</St.TextContent>
    </St.Wrapper>
  )
}
