import styled from '@emotion/styled'
import React from 'react'

import {
  DEFAULT_WILDCARD,
  splitAndInsertWildcards,
} from 'packages/utils/searchHelpers'

import { Zone } from 'app/hkhub/store/zones/zones.types'

const ZoneOption = styled.span<{ isSearchMatch: boolean }>`
  font-weight: ${({ isSearchMatch }) => (isSearchMatch ? 'bold' : 'normal')};
`

export enum ZoneSelectorHelperTestIds {
  match = 'ZoneSelectorHelperTestIds__match',
  noMatch = 'ZoneSelectorHelperTestIds__no_match',
}

export const buildZoneNameWithManagers = (zone: Zone): string => {
  // get a string value of all managers' first/last names, separated by commas
  const managers: string = (zone?.managers || [])
    .map(mgr => `${mgr.firstName} ${mgr.lastName}`)
    .join(', ')

  return managers.length ? `${zone.name} (${managers})` : zone.name
}

export const getHighlightedZoneSearchResults = (
  zone: Zone,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  { inputValue }, // react-select params
): React.ReactNode => {
  const searchString = inputValue.trim()
  // no inputValue means we are not searching, thus we can simply display the name
  if (!searchString) {
    return zone.name
  }

  const zoneNameWithManagers = buildZoneNameWithManagers(zone)
  const splitByMatch = splitAndInsertWildcards(
    zoneNameWithManagers,
    searchString,
  )

  return splitByMatch.reduce((accum, val, i) => {
    if (val === DEFAULT_WILDCARD) {
      return accum // do not make a node for strings matching the wildcard
    }

    // bold it if the previous string was '*'
    const isSearchMatch = i > 0 && splitByMatch[i - 1] === DEFAULT_WILDCARD

    return accum.concat(
      <ZoneOption
        data-testid={
          isSearchMatch
            ? ZoneSelectorHelperTestIds.match
            : ZoneSelectorHelperTestIds.noMatch
        }
        isSearchMatch={isSearchMatch}
        key={i}
      >
        {val}
      </ZoneOption>,
    )
  }, [] as React.ReactNode[])
}
