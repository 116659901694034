import { produce } from 'immer'

import { CustomInspectionItem } from 'app/hkhub/store/customInspectionItems/customInspectionItems.types'

import { CustomInspectionItemAction } from './customInspectionItemsForm.actions'

export type CRUDInspectionItem = {
  id?: string
  itemTitle?: string
  type: 'edit' | 'add'
}

export type CustomInspectionItemsValuesState = {
  crudCustomInspectionItem: CRUDInspectionItem | null
  customInspectionItems: CustomInspectionItem[]
}

export type CustomInspectionItemsUiState = {
  confirmDeleting: boolean
  showDeleteWaitIndicator: boolean
}

export type CustomInspectionItemsFormState = {
  uiState: CustomInspectionItemsUiState
  values: CustomInspectionItemsValuesState
}

export const getMockInitialCustomInspectionItemsState = (
  overrides?: Partial<CustomInspectionItemsFormState>,
): CustomInspectionItemsFormState => {
  return {
    uiState: {
      confirmDeleting: false,
      showDeleteWaitIndicator: false,
    },
    values: {
      crudCustomInspectionItem: null,
      customInspectionItems: [],
    },
    ...overrides,
  }
}

export const getInitialCustomInspectionItemsState = (
  customInspectionItems?: CustomInspectionItem[],
): CustomInspectionItemsFormState => {
  return {
    uiState: {
      confirmDeleting: false,
      showDeleteWaitIndicator: false,
    },
    values: {
      crudCustomInspectionItem: null,
      customInspectionItems: customInspectionItems || [],
    },
  }
}

export const createCustomInspectionItemReducer = (
  state: CustomInspectionItemsFormState,
  action: CustomInspectionItemAction,
): CustomInspectionItemsFormState =>
  produce(state, draft => {
    switch (action.type) {
      case 'setTitle':
        if (draft.values.crudCustomInspectionItem) {
          draft.values.crudCustomInspectionItem.itemTitle = action.payload
        }

        break

      case 'editInspectionItem':
        const editItem =
          state.values.customInspectionItems.find(
            item => item.id === action.payload,
          ) || null

        draft.values.crudCustomInspectionItem = { ...editItem, type: 'edit' }

        break

      case 'addInspectionItem':
        draft.values.crudCustomInspectionItem = {
          id: 'add',
          itemTitle: '',
          type: 'add',
        }

        break

      case 'cancelCRUDItem':
      case 'clearCRUDItem':
        draft.values.crudCustomInspectionItem = null
        draft.uiState.confirmDeleting = false

        break

      case 'cancelDeleteItem':
        draft.uiState.confirmDeleting = false

        break

      case 'clickDeleteButton':
        draft.uiState.confirmDeleting = true

        break

      case 'setShowDeleteWaitIndicator':
        draft.uiState.showDeleteWaitIndicator = action.payload

        break

      case 'updateInspectionItems':
        draft.values.customInspectionItems = action.payload

        break
    }

    return
  })
