import styled from '@emotion/styled'
import * as React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'
import { Lockbox } from 'app/hkhub/store/lockboxes'
import { Unit } from 'app/hkhub/store/units/units.types'

const St = {
  AccessCodeLabel: styled.span`
    font-weight: 600;
  `,
  AccessCodes: styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    padding: 8px;
  `,

  AccessTitle: styled.strong`
    color: ${colors.midnight80};
    font-size: 12px;
    text-transform: uppercase;
  `,

  AccessTitleSection: styled.div`
    margin-bottom: 16px;
  `,

  Details: styled.div`
    padding-left: 8px;
    width: 100%;
  `,
  Icon: styled(SvgIcon)`
    padding-top: 4px;
    color: ${colors.midnight80};
  `,
}

export enum AccessCodesTestIds {
  container = 'AccessCodes__container',
}

export type AccessCodesProps = {
  className?: string
  lockbox?: Lockbox
  unit: Unit
}

export const AccessCodes: React.FC<AccessCodesProps> = React.memo(
  ({ lockbox, unit }) => {
    const { t } = useI18n()

    const hasValidLockbox =
      lockbox && (lockbox.primaryLock || lockbox.backupLock)

    return (
      <St.AccessCodes data-testid={AccessCodesTestIds.container}>
        <St.Icon icon={IconName.lock} size={14} />

        <St.Details>
          <St.AccessTitleSection>
            <St.AccessTitle>{t(Slugs.access)}</St.AccessTitle>
          </St.AccessTitleSection>

          {!!unit.gateCode && (
            <div>
              <St.AccessCodeLabel>{t(Slugs.gateCode)}</St.AccessCodeLabel>:{' '}
              {unit.gateCode}
            </div>
          )}

          {!!unit.alarmCode && (
            <div>
              <St.AccessCodeLabel>{t(Slugs.alarmCode)}</St.AccessCodeLabel>:{' '}
              {unit.alarmCode}
            </div>
          )}

          {lockbox && (
            <div data-testid="lockbox-section">
              {hasValidLockbox ? (
                <>
                  <div>
                    <St.AccessCodeLabel>{t(Slugs.lockbox)}</St.AccessCodeLabel>:{' '}
                    {lockbox?.primaryLock || '-'}
                  </div>
                  <div>
                    <St.AccessCodeLabel>{t(Slugs.backup)}</St.AccessCodeLabel>:{' '}
                    {lockbox?.backupLock || '-'}
                  </div>
                  <div>
                    <St.AccessCodeLabel>{t(Slugs.notes)}</St.AccessCodeLabel>:{' '}
                    {lockbox?.notes || '-'}
                  </div>
                </>
              ) : (
                <> - </>
              )}
            </div>
          )}
        </St.Details>
      </St.AccessCodes>
    )
  },
)
