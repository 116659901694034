import React from 'react'
import { useSelector } from 'react-redux'

import { Button, Loader, LoaderType } from 'packages/common'
import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { CustomInspectionItem } from 'app/hkhub/store/customInspectionItems/customInspectionItems.types'
import { getUnitDrawerState } from 'app/hkhub/store/ui/selectors'

import { CustomInspectionItemsFormState } from '../customInspectionItemsState/customInspectionItemsForm.reducer'
import {
  CustomInspectionItemActionHandlersType,
  CustomInspectionItemApiCallHandlersStateType,
  CustomInspectionItemApiCallHandlersType,
} from '../VisitSection.container'
import {
  ButtonContainer,
  ConfirmDeleteButton,
  CustomChecklistItemHeaderContainer,
  DeleteConfirmationButtons,
  EditButton,
  ItemTitle,
  KeepEditingButton,
  LoaderContainer,
  Subtitle,
  TextArea,
  TrashIcon,
} from './CustomInspectionItem.styles'

const CUSTOM_ITEM_MAX_CHARS = 500

const St = {
  ButtonContainer,
  ConfirmDeleteButton,
  CustomChecklistItemHeaderContainer,
  DeleteConfirmationButtons,
  EditButton,
  ItemTitle,
  KeepEditingButton,
  LoaderContainer,
  Subtitle,
  TextArea,
  TrashIcon,
}

export enum CRUDCustomInspectionItemDisplayTestIds {
  confirmDeleteButton = 'CRUDCustomInspectionItemDisplay__confirmDeleteButton',
  confirmDeleteLoader = 'CRUDCustomInspectionItemDisplay__confirmDeleteLoader',
  deleteButton = 'CRUDCustomInspectionItemDisplay__deleteButton',
  editButton = 'CRUDCustomInspectionItemDisplay__editButton',
  keepEditingButton = 'CRUDCustomInspectionItemDisplay__keepEditingButton',
}

export type CRUDCustomInspectionItemDisplayProps = {
  actionHandlers: CustomInspectionItemActionHandlersType
  apiCallHandlers: CustomInspectionItemApiCallHandlersType
  apiCallHandlersState: CustomInspectionItemApiCallHandlersStateType
  index?: number
  item?: CustomInspectionItem
  state: CustomInspectionItemsFormState
}

export const CRUDCustomInspectionItemDisplay: React.FC<CRUDCustomInspectionItemDisplayProps> =
  React.memo(
    ({
      actionHandlers,
      apiCallHandlers,
      apiCallHandlersState,
      index,
      item,
      state,
    }) => {
      const { ut } = useI18n()

      const { unitId } = useSelector(getUnitDrawerState)

      const isEditingThisItem =
        state.values.crudCustomInspectionItem &&
        state.values.crudCustomInspectionItem.id === item?.id

      const isNewItem = state.values.crudCustomInspectionItem?.type === 'add'

      const titleLength =
        state.values.crudCustomInspectionItem?.itemTitle?.length

      const disableUpdate =
        apiCallHandlersState.updateItemIsLoading ||
        apiCallHandlersState.deleteItemIsLoading ||
        state.values.crudCustomInspectionItem?.itemTitle ===
          state.values.customInspectionItems.find(
            item => item.id === state.values.crudCustomInspectionItem?.id,
          )?.itemTitle

      const isConfirmingDelete = state.uiState.confirmDeleting
      const showDeleteWaitIndicator = state.uiState.showDeleteWaitIndicator
      const showEditButton = !isNewItem && !isEditingThisItem
      const showTrashCanButton =
        !isNewItem &&
        isEditingThisItem &&
        !isConfirmingDelete &&
        !showDeleteWaitIndicator
      const showDeleteConfirmButtons =
        !isNewItem && isEditingThisItem && isConfirmingDelete

      return (
        <>
          <St.CustomChecklistItemHeaderContainer>
            <St.ItemTitle>
              {!isNewItem && index !== undefined
                ? `${ut(Slugs.checklistItem)} ${index + 1}:`
                : `${ut(Slugs.newChecklistItem)}:`}
            </St.ItemTitle>
            {showDeleteConfirmButtons && (
              <St.DeleteConfirmationButtons>
                {showDeleteWaitIndicator ? (
                  <St.LoaderContainer
                    data-testid={
                      CRUDCustomInspectionItemDisplayTestIds.confirmDeleteLoader
                    }
                  >
                    <Loader type={LoaderType.Clip} />
                  </St.LoaderContainer>
                ) : (
                  <>
                    <St.KeepEditingButton
                      buttonType={'text'}
                      dataTestId={
                        CRUDCustomInspectionItemDisplayTestIds.keepEditingButton
                      }
                      disabled={apiCallHandlersState.deleteItemIsLoading}
                      onClick={actionHandlers.cancelDelete}
                    >
                      {ut(Slugs.keepEditing)}
                    </St.KeepEditingButton>
                    <St.ConfirmDeleteButton
                      buttonType={'text'}
                      dataTestId={
                        CRUDCustomInspectionItemDisplayTestIds.confirmDeleteButton
                      }
                      disabled={apiCallHandlersState.deleteItemIsLoading}
                      onClick={() =>
                        apiCallHandlers.deleteItem(item?.id || '', unitId)
                      }
                    >
                      {ut(Slugs.yesDelete)}
                    </St.ConfirmDeleteButton>
                  </>
                )}
              </St.DeleteConfirmationButtons>
            )}

            {showTrashCanButton && (
              <St.TrashIcon
                dataTestId={CRUDCustomInspectionItemDisplayTestIds.deleteButton}
                height={22}
                icon={IconName.trash}
                onClick={actionHandlers.clickDelete}
                width={20}
              ></St.TrashIcon>
            )}
            {showEditButton && (
              <St.EditButton
                buttonType={'text'}
                dataTestId={CRUDCustomInspectionItemDisplayTestIds.editButton}
                disabled={
                  !isEditingThisItem || apiCallHandlersState.updateItemIsLoading
                }
                onClick={() => actionHandlers.edit(item?.id || '')}
              >
                {ut(Slugs.edit)}
              </St.EditButton>
            )}
          </St.CustomChecklistItemHeaderContainer>

          <St.TextArea
            maxLength={CUSTOM_ITEM_MAX_CHARS}
            onChange={e => actionHandlers.setTitle(e.target.value)}
            value={state.values.crudCustomInspectionItem?.itemTitle}
          ></St.TextArea>

          <St.Subtitle>{`${
            titleLength || 0
          }/${CUSTOM_ITEM_MAX_CHARS}`}</St.Subtitle>
          {isNewItem && (
            <St.ButtonContainer>
              <Button
                buttonType="utility"
                disabled={apiCallHandlersState.createItemIsLoading}
                onClick={actionHandlers.cancel}
              >
                {ut(Slugs.cancel)}
              </Button>
              <Button
                disabled={
                  titleLength === 0 || apiCallHandlersState.createItemIsLoading
                }
                onClick={() =>
                  apiCallHandlers.createItem(
                    state.values.crudCustomInspectionItem?.itemTitle || '',
                    unitId,
                  )
                }
              >
                {ut(Slugs.save)}
              </Button>
            </St.ButtonContainer>
          )}
          {isEditingThisItem && (
            <St.ButtonContainer>
              <Button
                buttonType="utility"
                disabled={
                  apiCallHandlersState.updateItemIsLoading ||
                  apiCallHandlersState.deleteItemIsLoading
                }
                onClick={actionHandlers.cancel}
              >
                {ut(Slugs.cancel)}
              </Button>
              <Button
                disabled={disableUpdate}
                onClick={() =>
                  apiCallHandlers.updateItem(
                    state.values.crudCustomInspectionItem?.id || '',
                    state.values.crudCustomInspectionItem?.itemTitle || '',
                    unitId,
                  )
                }
              >
                {ut(Slugs.update)}
              </Button>
            </St.ButtonContainer>
          )}
        </>
      )
    },
  )
