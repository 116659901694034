import noop from 'lodash/fp/noop'
import * as React from 'react'

import { Keys } from 'packages/utils/constants'

import { useFocusTrap } from './useFocusTrap'
import { useHotkeys } from './useHotkeys'

type UseModalProps = {
  /**
   * (Optional) disables ESC key closing the Modal
   */
  blockEsc?: boolean
  /**
   * Callback to call _after_ the modal has finished closing (i.e. after any CSS transitions/animations are complete)
   * This should signal to the parent component that the modal is now safe to be completely removed from the DOM.
   */
  closeModal: () => void
  /**
   * A ref to a child component which should be auto-focused when the modal is opened.
   */
  initialFocusRef?: React.MutableRefObject<HTMLElement>
  /**
   * Whether the modal is currently in an active state. This can be useful to toggle, for example,
   * when the modal is transitioning in or out so that it will not process any actions at an unexpected time.
   */
  isActive: boolean
  /**
   * A ref to the main top-level component which will be regarded as the root of the modal. Ideally, this should
   * be the component that has a `role="dialog"` attribute attached to it.
   */
  rootRef: React.MutableRefObject<HTMLElement>
}

/**
 * A simple hook to encompass all of the standard behavior expected of a component
 * that considers itself a modal/dialog.
 *
 * This currently includes the following behaviors:
 *   - Default ESC hotkey for closing the modal at any time
 *   - A tab key "focus trap" set up for the modal root component (ensures user cannot tab out of the component)
 *   - If specified, `initialFocusRef` will be auto-focused when the modal is opened
 *
 * @param blockEsc
 * @param closeModal
 * @param initialFocusRef
 * @param isActive
 * @param rootRef
 */
export const useModal = ({
  blockEsc = false,
  closeModal,
  initialFocusRef,
  isActive,
  rootRef,
}: UseModalProps): void => {
  /**
   * Once the component is active and has a valid ref, focus the specified element.
   */
  React.useEffect(() => {
    if (!isActive) return

    if (initialFocusRef?.current?.focus) {
      initialFocusRef.current.focus()
    }
  }, [initialFocusRef, isActive])

  const keyDownHandlers = {
    [Keys.Esc]: blockEsc ? noop : closeModal,
  }

  /**
   * Setup hotkeys shared by all modals
   */
  useHotkeys({
    keyDownHandlers,
  })

  useFocusTrap({ initialFocusRef, isActive, rootRef })
}
