import get from 'lodash/get' // eslint-disable-line
import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { User } from 'app/hkhub/store/users/users.types'

import { Zone, ZonesState, ZoneAttributeNames } from '../zones.types'

const getRawZoneSearchResults = (state: ZonesState) =>
  Object.values(state.searchResults.zone)

const getRawZoneManagers = (state: ZonesState) => state.searchResults.user

export const getZoneSearchResults = createSelector(
  getRawZoneSearchResults,
  getRawZoneManagers,
  (rawZones, rawManagers): Zone[] =>
    rawZones.map(zone => {
      const mgrIds: string[] = get(zone, 'relationships.managers.data', []).map(
        mgr => mgr.id,
      )

      const managers: User[] = mgrIds.map(id =>
        transformNormalizedToTyped<User>(rawManagers[id], [
          'firstName',
          'lastName',
        ]),
      )

      return {
        ...transformNormalizedToTyped<Zone>(zone, ZoneAttributeNames),
        managers,
      }
    }),
)
