import { createAsyncAction } from 'typesafe-actions'

import {
  RelationshipUpdate,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { zonesService } from '../zones.service'
import { NormalizedZonesApiResponse, ZonesActionTypes } from '../zones.types'

export const removeHksFromZoneAction = createAsyncAction(
  ZonesActionTypes.REMOVE_HKS_FROM_ZONE,
  ZonesActionTypes.REMOVE_HKS_FROM_ZONE_SUCCESS,
  ZonesActionTypes.REMOVE_HKS_FROM_ZONE_FAILURE,
)<
  RequestConfig<NormalizedZonesApiResponse>,
  NormalizedZonesApiResponse,
  Error
>()

export const removeHksFromZone =
  (requestData: RelationshipUpdate) => async dispatch => {
    try {
      const request = zonesService.removeRelationships.bind(
        null,
        requestData,
        'housekeeper',
      )
      const result = await dispatch(
        removeHksFromZoneAction.request({ request }),
      )
      result.normalized.relationshipUpdate = requestData
      dispatch(removeHksFromZoneAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(removeHksFromZoneAction.failure(error))
      throw error
    }
  }
