/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum AuthActionTypes {
  CLEAR_IMPERSONATION_TOKEN = 'AUTH/CLEAR_IMPERSONATION_TOKEN',
  FETCH_IMPERSONATION_TOKEN_FAILURE = 'AUTH/FETCH_IMPERSONATION_TOKEN_FAILURE',
  // Actions for fetching impersonation token
  FETCH_IMPERSONATION_TOKEN_REQUEST = 'AUTH/FETCH_IMPERSONATION_TOKEN_REQUEST',
  FETCH_IMPERSONATION_TOKEN_SUCCESS = 'AUTH/FETCH_IMPERSONATION_TOKEN_SUCCESS',

  SET_AUTH_ERROR = 'AUTH/SET_AUTH_ERROR',
  SET_NEEDS_FULL_AUTH_REDIRECT = 'AUTH/SET_NEEDS_FULL_AUTH_REDIRECT',
  SET_NEEDS_SILENT_REFRESH = 'AUTH/SET_NEEDS_SILENT_REFRESH',
  SET_TOKENS = 'AUTH/SET_TOKENS',
}

export type AuthState = {
  error?: Error
  impersonationToken?: string
  needsFullAuthRedirect: boolean
  needsSilentRefresh: boolean
  refreshToken: string | undefined
  token: string | undefined
}
