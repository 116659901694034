import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Button } from '../../Button'

export enum CloseButtonTestIds {
  container = 'CloseButton__container',
}

export const CloseButtonContainer = styled(Button)`
  height: unset; /* we don't want default/static button height here */
  padding: 0; /* also remove default button padding */
  position: absolute;
  right: 12px;
  top: 12px;
`

const StyledSvgIcon = styled(SvgIcon)`
  color: ${colors.midnight40};
`

export type CloseButtonProps = {
  className?: string
  dataTestId?: string
  onClick: () => void
}

export const CloseButton: React.FC<CloseButtonProps> = ({
  className,
  dataTestId,
  onClick,
}) => {
  return (
    <CloseButtonContainer
      buttonType={'text'}
      className={className}
      dataTestId={dataTestId || CloseButtonTestIds.container}
      onClick={onClick}
    >
      <StyledSvgIcon icon={IconName.x} size={28} />
    </CloseButtonContainer>
  )
}
