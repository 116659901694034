import {
  createDateObject,
  DateFormat,
  differenceInDays,
  differenceInMonths,
  formatLocalized,
} from 'packages/utils/dateHelpers'

/**
 * Returns a string formatted for displaying the "last completed" Deep Clean or Inspection Date.
 * Format is like: Sept 22, 2021 (4mo ago)
 * - If diff is < 30 days, the 2nd part will display in days
 * - Otherwise diff will be displayed in months
 * @param timestamp
 */
export const getLastDateString = (timestamp: string): string => {
  const MAX_DAY_DISPLAY = 31
  const TODAY = createDateObject()

  const monthDiff = differenceInMonths(TODAY, timestamp)
  const dayDiff = differenceInDays(TODAY, timestamp)

  const dateString = formatLocalized(timestamp, DateFormat.MonthDateAndYear)
  const timeAgoString =
    dayDiff <= MAX_DAY_DISPLAY ? `(${dayDiff}d ago)` : `(${monthDiff}mo ago)`

  return `${dateString} ${timeAgoString}`
}
