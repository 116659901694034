import { createSelector } from 'reselect'

import { UnitsState, Unit } from '../units.types'
import { hydrateRawUnit } from '../units.utils'

const getUnitsState = (state: UnitsState): UnitsState => state
const getUnitId = (_state: UnitsState, unitId: string): string => unitId

export const getUnitById = createSelector(
  getUnitsState,
  getUnitId,
  (unitsState: UnitsState, unitId: string): Unit | undefined => {
    const rawUnit = unitsState?.data?.[unitId]
    if (!rawUnit) return undefined

    return hydrateRawUnit(rawUnit)
  },
)
