import classNames from 'classnames'
import React from 'react'

import styles from './Badge.module.scss'

export type BadgeProps = {
  children: React.ReactNode
  className?: string
}

export const Badge: React.FC<BadgeProps> = ({ children, className = '' }) => (
  <div className={classNames(styles.badge, className)}>{children}</div>
)
