import { isBefore, startOfToday } from 'packages/utils/dateHelpers'

import { Ticket, TicketStatus } from '../store/tickets'

/** Human Readable Ticket Status - generally closely tied to what we display in the UI */
export type ComputedTicketStatus =
  | 'assigned'
  | 'awaiting'
  | 'cancelled'
  | 'completed'
  | 'inProgress'
  | 'pending'
  | 'unassigned'

export const ticketStatusToComputedMap: Record<
  TicketStatus,
  ComputedTicketStatus
> = {
  [TicketStatus.ACCEPTED]: 'inProgress',
  [TicketStatus.ASSIGNED]: 'assigned',
  [TicketStatus.AWAITING]: 'awaiting',
  [TicketStatus.RESOLUTION_PENDING]: 'pending',
  [TicketStatus.RESOLVED_COMPLETED]: 'completed',
  [TicketStatus.RESOLVED_DISMISSED]: 'cancelled',
  [TicketStatus.RESOLVED_DUPLICATE]: 'completed',
  [TicketStatus.UNASSIGNED]: 'unassigned',
}

export const ticketIsOverdue = (ticket: Ticket): boolean => {
  const computedTicketStatus = ticketStatusToComputedMap[ticket.status]
  const dueDateIsBeforeToday =
    ticket?.dueDate && isBefore(ticket.dueDate, startOfToday())
  return Boolean(dueDateIsBeforeToday) && computedTicketStatus !== 'completed'
}
