import { debounce } from 'lodash/fp'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAsyncFn, useRendersCount } from 'react-use'

import { ReduxDispatch } from 'packages/grimoire/src/utils'
import { DateFormat, format } from 'packages/utils/dateHelpers'

import { useScheduleContext } from 'app/hkhub/components/schedule/components/common/VirtualizedSchedule/VirtualizedSchedule.context'
import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { fetchReservationsByZoneAndDateRange } from 'app/hkhub/store/reservations/actions'
import { UnitReservationsBuckets } from 'app/hkhub/store/reservations/reservations.types'
import { getReservationsBucketedByUnit } from 'app/hkhub/store/reservations/selectors/getReservationForUnitAndDate'
import { ApplicationState } from 'app/hkhub/store/store'

export const RES_REFETCH_DEBOUNCE_TIME = 500

const makeFetchReservations =
  (dispatch: ReduxDispatch<ApplicationState>) =>
  async (dateRange: Date[], zoneId: string): Promise<void> => {
    const dateStrings = dateRange.map(date =>
      format(date, DateFormat.ApiUtcShort),
    )

    return await dispatch(
      fetchReservationsByZoneAndDateRange(
        {
          end: format(dateStrings[dateStrings.length - 1], 'yyyy-MM-dd'),
          start: format(dateStrings[0], 'yyyy-MM-dd'),
        },
        zoneId,
      ),
    )
  }

type UseUnitReservations = {
  isLoadingReservations: boolean
  reservationBuckets: UnitReservationsBuckets
}

export function useUnitReservations(): UseUnitReservations {
  const reduxDispatch = useDispatch<ReduxDispatch<ApplicationState>>()

  const reservationBuckets = useSelector((state: ApplicationState) =>
    getReservationsBucketedByUnit(state),
  )

  const { zone } = useZoneContext()
  const { dateRange } = useScheduleContext()
  const rendersCount = useRendersCount()

  const [fetchReservationsState, fetchReservations] = useAsyncFn(
    makeFetchReservations(reduxDispatch),
    [reduxDispatch],
  )

  const debouncedFetchReservations = React.useMemo(
    () => debounce(RES_REFETCH_DEBOUNCE_TIME, fetchReservations),
    [fetchReservations],
  )

  React.useEffect(() => {
    debouncedFetchReservations(dateRange, zone.id)
  }, [dateRange, debouncedFetchReservations, zone.id])

  return {
    isLoadingReservations: fetchReservationsState.loading || rendersCount === 1,
    reservationBuckets,
  }
}
