import styled from '@emotion/styled'
import React from 'react'

import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'
import { Visit } from 'app/hkhub/store/visits'

const St = {
  ScheduledVisitIcon: styled(SvgIcon)`
    margin-right: 4px;
  `,

  VisitBadge: styled.span`
    background: ${colors.tetons30};
    border: 1px #ced9d9 solid;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    padding: 0px 6px;
    margin-right: 8px;
  `,
}

export enum VisitBadgeTestIds {
  container = 'VisitBadge__container',
  scheduledIcon = 'VisitBadge__scheduledIcon',
}

export type VisitBadgeProps = {
  visit: Visit
}

export const VisitBadge: React.FC<VisitBadgeProps> = React.memo(({ visit }) => {
  const { t } = useI18n()

  return (
    <St.VisitBadge data-testid={VisitBadgeTestIds.container}>
      {visit.serviceType === 'scheduled' && (
        <St.ScheduledVisitIcon
          centerItems={true}
          dataTestId={VisitBadgeTestIds.scheduledIcon}
          icon={IconName.refreshCw}
          size={13}
        />
      )}
      {t(Slugs.visit)}
    </St.VisitBadge>
  )
})
