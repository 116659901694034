import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  DeepCleanSkipsApiResponse,
  DeepCleanSkipsServiceResponse,
} from './deepCleanSkips.types'
import { emptyDeepCleanSkipResponse } from './deepCleanSkips.utils'

const URL = '/deep_clean_skips'

export const deepCleanSkipsService = {
  async createDeepCleanSkip(
    data: RequestOptions,
  ): DeepCleanSkipsServiceResponse {
    const response: DeepCleanSkipsApiResponse = await api.post(URL, data)
    return response?.data || emptyDeepCleanSkipResponse
  },
}
