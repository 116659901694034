import { noop } from 'lodash/fp'
import React from 'react'

import { UseAdminAlerts } from '../../hooks/useAdminAlerts'

export type AdminContextType = UseAdminAlerts & {}

export const AdminContext = React.createContext<AdminContextType>({
  addAlert: noop,
  alerts: [],
  removeAlert: noop,
})

export const useAdminContext = (): AdminContextType =>
  React.useContext(AdminContext)
