import { createAsyncAction } from 'typesafe-actions'

import { TicketStatus } from 'packages/grimoire/src/ticket'
import { DateFormat, format, startOfToday } from 'packages/utils/dateHelpers'
import {
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  RequestConfig,
  RequestOptions,
  ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

import { ticketsService } from '../tickets.service'
import { TicketActionTypes } from '../tickets.types'

/*
  The idea here is that we need the count of overdue tickets so we can display that number in our 
  overdue icons. To achieve this we fetch from /tickets, while filtering on due_date, zone and status, and
  intentionally exclude all fields except ID for Tickets and Units to decrease payload size. We need custom types for this
  action and since these types will never be re-used, we can consolidate the types with the action in this file. 
*/

type JSONApiIncompleteTicketsMap = JSONApiObjectWithRelationshipsMap<
  {},
  { unit: ToOneRelationship }
>

type IncompleteTicketsResponse = {
  ticket: JSONApiIncompleteTicketsMap
}

type NormalizedIncompleteTicketsApiResponse =
  NormalizedJSONApiResponse<IncompleteTicketsResponse>

export const fetchIncompleteTicketsByZoneAction = createAsyncAction(
  TicketActionTypes.FETCH_INCOMPLETE_TICKETS_BY_ZONE,
  TicketActionTypes.FETCH_INCOMPLETE_TICKETS_BY_ZONE_SUCCESS,
  TicketActionTypes.FETCH_INCOMPLETE_TICKETS_BY_ZONE_FAILURE,
)<
  RequestConfig<NormalizedIncompleteTicketsApiResponse>,
  NormalizedIncompleteTicketsApiResponse,
  Error
>()

export const getParams = (zoneId: string): RequestOptions => {
  const today = startOfToday()

  return {
    fields: {
      ticket: [],
      unit: [],
    },
    filter: {
      due_date: { lt: format(today, DateFormat.ApiUtcWithSeconds) },
      status: {
        in: [TicketStatus.ASSIGNED, TicketStatus.ACCEPTED],
      },
      'unit.zone': zoneId,
    },
    include: ['unit'],
    page: { size: 1500 },
  }
}

export const fetchIncompleteTicketsByZone = (zoneId: string) => {
  return async dispatch => {
    try {
      const params = getParams(zoneId)
      const request = ticketsService.fetchTickets.bind(null, params)
      const result = await dispatch(
        fetchIncompleteTicketsByZoneAction.request({ request }),
      )
      dispatch(fetchIncompleteTicketsByZoneAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchIncompleteTicketsByZoneAction.failure(error))
      throw error
    }
  }
}
