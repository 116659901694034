/**
 * Custom error for providing clean messaging for missing Redux data.
 * Use this in selectors when you get "undefined" when trying to pull
 * raw data from the store using an object's ID.
 */
export class MissingDataError extends Error {
  constructor(objType: string, id: string) {
    super(`No "${objType}" found with ID: "${id}"`)
    this.name = 'Selector Error: Missing Data'
  }
}
