import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { Ticket } from '../tickets.types'
import { getTicketsByIds } from './getTicketsByIds'

export const getOpenTicketsByUnitId = createSelector(
  (state: ApplicationState) => state,
  (_, unitId: string) => unitId,
  (state, unitId): Ticket[] => {
    const ticketIds = (
      state.units.data[unitId]?.relationships?.openTickets?.data || []
    ).map(ticket => ticket.id)
    return getTicketsByIds(state, ticketIds)
  },
)
