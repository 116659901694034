import { createAsyncThunk } from '@reduxjs/toolkit'
import { noop } from 'lodash/fp'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'

import { fetchUnitByIdStrict } from '../../units/actions'
import { customInspectionItemsService } from '../customInspectionItems.service'
import { CustomInspectionItemDeleteData } from '../customInspectionItems.types'

type DeleteCustomInspectionItemThunkArgs = {
  callbacks: ReduxActionCallbacks
  deleteData: CustomInspectionItemDeleteData
}

export const deleteCustomInspectionItem = createAsyncThunk(
  'customInspectionItems/deleteCustomInspectionItem',
  async (args: DeleteCustomInspectionItemThunkArgs, { dispatch }) => {
    const { callbacks, deleteData } = args
    const { itemId, unitId } = deleteData
    const { onError = noop, onSuccess = noop } = callbacks
    try {
      const result =
        await customInspectionItemsService.deleteCustomInspectionItem(itemId)

      // re-fetch the unit, so we can get the updated custom checklist items
      await dispatch(fetchUnitByIdStrict(unitId))

      onSuccess(result)
      return result
    } catch (error) {
      onError(error)
    }
  },
)
