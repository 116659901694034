import styled from '@emotion/styled'
import * as React from 'react'

import { Slugs, useI18n } from 'packages/i18n'
import { colors } from 'packages/styles/jss'

import { Badge } from '../Badge/Badge'
import { Tooltip, TooltipPosition } from '../Tooltip'

export enum CleanBadgeTypes {
  B2B = 'B2B',
  Early = 'Early',
  Late = 'Late',
  PotentialB2B = 'PotentialB2B',
}

/**************************************************
 * Styles
 **************************************************/
const BaseCleanBadge = styled(Badge)`
  height: 20px;
`

const StandardBadge = styled(BaseCleanBadge)`
  background-color: ${colors.lake};
  color: white;
`

const LightBadge = styled(BaseCleanBadge)`
  background-color: #c1e0f1;
`

const GhostBadge = styled(BaseCleanBadge)`
  background-color: white;
  border: 1px ${colors.lake} solid;
  color: ${colors.lake};
`

const badgeTypeRenderMap = {
  [CleanBadgeTypes.B2B]: StandardBadge,
  [CleanBadgeTypes.Early]: LightBadge,
  [CleanBadgeTypes.Late]: LightBadge,
  [CleanBadgeTypes.PotentialB2B]: GhostBadge,
}

/**************************************************
 * Translations
 **************************************************/
const badgeTypeTooltipSlugMap = {
  [CleanBadgeTypes.B2B]: Slugs.toolTipB2b,
  [CleanBadgeTypes.Early]: Slugs.toolTipEarly,
  [CleanBadgeTypes.Late]: Slugs.toolTipLate,
  [CleanBadgeTypes.PotentialB2B]: Slugs.toolTipPotentialB2b,
}

const badgeTypeSlugMap = {
  [CleanBadgeTypes.B2B]: Slugs.badgeB2b,
  [CleanBadgeTypes.Early]: Slugs.badgeEarly,
  [CleanBadgeTypes.Late]: Slugs.badgeLate,
  [CleanBadgeTypes.PotentialB2B]: Slugs.badgeB2b,
}

type TranslationsProps = Pick<CleanBadgeProps, 'type'>

const useTranslations = ({ type }: TranslationsProps) => {
  const { t } = useI18n()

  return {
    content: t(badgeTypeSlugMap[type]),
    tooltip: t(badgeTypeTooltipSlugMap[type]),
  }
}

/**************************************************
 * Render
 **************************************************/
export type CleanBadgeProps = {
  className?: string
  tooltipPosition?: TooltipPosition
  type: CleanBadgeTypes
}

export const CleanBadge: React.FC<CleanBadgeProps> = ({
  className,
  tooltipPosition = TooltipPosition.Left,
  type,
}) => {
  const BadgeComponent = badgeTypeRenderMap[type]
  const strings = useTranslations({ type })

  return (
    <Tooltip message={strings.tooltip} position={tooltipPosition}>
      <BadgeComponent className={className}>{strings.content}</BadgeComponent>
    </Tooltip>
  )
}
