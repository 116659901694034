import styled from '@emotion/styled'
import React from 'react'

import { parseBeds } from 'packages/grimoire/src/unit/unit.helpers'
import { IconName, SvgIcon } from 'packages/iconic'

import {
  CardSize,
  cardSizeGreaterThan,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Unit } from 'app/hkhub/store/units'
import { Visit } from 'app/hkhub/store/visits'

import { VisitBadge } from '../VisitBadge'

const St = {
  Container: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,
  IconSection: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding-left: 8px;
  `,
  LeftSideContainer: styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  `,
  OwnerInfo: styled.div`
    align-items: center;
    display: flex;
  `,
  OwnerText: styled.span`
    font-size: 14px;
    padding-left: 2px;
    line-height: 17px;
  `,
}

export enum VisitCardAdditionalInfoTestIds {
  container = 'VisitCardAdditionalInfo__container',
  owner = 'VisitCardAdditionalInfo__owner',
  stayIcons = 'VisitCardAdditionalInfo__stayIcons',
}

export type VisitCardAdditionalInfoProps = {
  size: CardSize
  unit: Unit
  visit: Visit
}

export const VisitCardAdditionalInfo: React.FC<
  VisitCardAdditionalInfoProps
> = ({ visit, size, unit }) => {
  const notTiny = cardSizeGreaterThan(size, 'tiny')

  const bathrooms = `${unit?.bathrooms || '?'}`
  const beds = parseBeds(unit)

  const displayOwnerVisit = notTiny && visit.isNextStayOwner
  return (
    <St.Container data-testid={VisitCardAdditionalInfoTestIds.container}>
      <St.LeftSideContainer>
        <VisitBadge visit={visit} />
        {cardSizeGreaterThan(size, 'small') && (
          <St.IconSection
            data-testid={VisitCardAdditionalInfoTestIds.stayIcons}
          >
            {visit.ticketAttachedCount > 0 && (
              <SvgIcon centerItems={true} icon={IconName.tag} size={14} />
            )}

            <SvgIcon
              centerItems={true}
              icon={IconName.bed}
              size={14}
              text={beds}
            />

            <SvgIcon
              centerItems={true}
              icon={IconName.bath}
              size={14}
              text={bathrooms}
            />

            {unit?.hottub && (
              <SvgIcon centerItems={true} icon={IconName.hotTub} size={14} />
            )}
          </St.IconSection>
        )}
      </St.LeftSideContainer>

      {displayOwnerVisit && (
        <St.OwnerInfo data-testid={VisitCardAdditionalInfoTestIds.owner}>
          <SvgIcon icon={IconName.crown} size={16} />
          {cardSizeGreaterThan(size, 'medium') && (
            <St.OwnerText>Owner</St.OwnerText>
          )}
        </St.OwnerInfo>
      )}
    </St.Container>
  )
}
