import { createAsyncAction } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store/jsonapi.types'

import { unitsService } from '../units.service'
import { NormalizedUnitsApiResponse, UnitsActionTypes } from '../units.types'
import { fetchUnitById } from './fetchUnitById'

export const removeUnitPreferenceAction = createAsyncAction(
  UnitsActionTypes.REMOVE_UNIT_PREFERENCE,
  UnitsActionTypes.REMOVE_UNIT_PREFERENCE_SUCCESS,
  UnitsActionTypes.REMOVE_UNIT_PREFERENCE_FAILURE,
)<RequestConfig<NormalizedUnitsApiResponse>, string, Error>()

export const getParamsForFetchUnit = () => ({
  include: ['housekeeper_preferences', 'lock_box'],
})

export const removeUnitPreference =
  (preferenceId: string, unitId: string) => async dispatch => {
    try {
      const request = unitsService.removeUnitPreferenceById.bind(
        null,
        preferenceId,
      )
      const result = await dispatch(
        removeUnitPreferenceAction.request({ request }),
      )

      // we need to re-fetch the associated unit to ensure we have the updated data locally
      const fetchUnitParams = getParamsForFetchUnit()
      await dispatch(fetchUnitById(unitId, fetchUnitParams))

      dispatch(removeUnitPreferenceAction.success(preferenceId))
      return result.normalized
    } catch (error) {
      dispatch(removeUnitPreferenceAction.failure(error))
      throw error
    }
  }
