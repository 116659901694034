import { GetState } from 'packages/webSocketEvents'

import { ApplicationState } from 'app/hkhub/store/store'
import { fetchUnitById } from 'app/hkhub/store/units/actions'
import { getUnitById } from 'app/hkhub/store/units/selectors'

export const getParams = () => ({
  // Include preferences in the case the unit is fetched while the user is in the "Housekeepers" tab
  include: ['housekeeper_preferences', 'lock_box'],
})

/**
 * Triggers a call to refetch a given Unit IF that Unit is currently
 * in our local Redux store.
 */
export const refetchUnitIfExists =
  (unitId: string) =>
  (dispatch, getState: GetState<ApplicationState>): void => {
    const state = getState()
    const unit = getUnitById(state.units, unitId)
    if (unit) dispatch(fetchUnitById(unitId, getParams()))
  }
