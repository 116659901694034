import { css } from '@emotion/react'

export const truncateTextWithEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const beginWithEllipsis = css`
  ${truncateTextWithEllipsis};
  direction: rtl;
  text-align: left;
`

export const centerWithFlex = css`
  align-items: center;
  display: flex;
  justify-content: center;
`
