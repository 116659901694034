import styled from '@emotion/styled'
import React from 'react'

import { Button } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Zone } from 'app/hkhub/store/zones/zones.types'

const St = {
  ChangeZoneButton: styled(Button)`
    margin-left: 8px;
  `,

  Icon: styled(SvgIcon)`
    color: white;
  `,

  SelectedZoneDisplay: styled.div`
    align-items: center;
    color: ${colors.white};
    display: flex;
    font-size: 16px;
    height: 100%;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    change: ut(Slugs.change),
    noZone: ut(Slugs.unknown),
  }
}

export enum SelectedZoneDisplayTestIds {
  container = 'SelectedZoneDisplay__container',
}

export type SelectedZoneDisplayProps = {
  className?: string
  onChange: () => void
  zone: Zone | undefined
}

export const SelectedZoneDisplay: React.FC<SelectedZoneDisplayProps> = ({
  className,
  onChange,
  zone,
}) => {
  const strings = useTranslations()

  return (
    <St.SelectedZoneDisplay
      className={className}
      data-testid={SelectedZoneDisplayTestIds.container}
    >
      <St.Icon
        centerItems={true}
        textFontSize={16}
        icon={IconName.mapPin}
        size={22}
        text={zone?.name || strings.noZone}
      />

      <St.ChangeZoneButton
        buttonSize={'small'}
        buttonType={'textInverted'}
        onClick={onChange}
      >
        {strings.change}
      </St.ChangeZoneButton>
    </St.SelectedZoneDisplay>
  )
}
