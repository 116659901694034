import { JSONApiObject } from 'packages/utils/store/jsonapi.types'
import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ZoneAttributes, Zone, ZoneAttributeNames } from '../zones.types'

export const transformNormalizedToZone = (
  normalizedZone: JSONApiObject<ZoneAttributes>,
): Zone => {
  return transformNormalizedToTyped<Zone>(normalizedZone, ZoneAttributeNames)
}
