import styled from '@emotion/styled'
import React from 'react'

import { ExternalLink } from 'packages/common'
import { parseBeds } from 'packages/grimoire/src/unit/unit.helpers'
import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { getVacasaViewUnitURL } from 'packages/utils/links'

import { Slugs } from 'app/hkhub/i18n'
import { Unit } from 'app/hkhub/store/units'

import { resGridUrl } from '../../../CleanDrawer/CleanUnitInfo/CleanUnitInfo.helpers'
// Styles imported from the cleans side of things
import { St as UnitInfoStyles } from '../../../CleanDrawer/CleanUnitInfo/CleanUnitInfo.styles'

const St = {
  InfoContainer: styled(UnitInfoStyles.CleanUnitInfoContainer)`
    font-size: 16px;
    padding: 16px 8px;
    width: 100%;
  `,

  UnitDetailIcons: styled.div`
    align-items: center;
    display: flex;
    width: 100%;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    resgrid: ut(Slugs.resgrid),
    viewListing: ut(Slugs.viewListing),
  }
}

export enum VisitUnitDetailsTestIds {
  container = 'VisitUnitDetails__container',
  hottub = 'VisitUnitDetails__hottub',
}

export type VisitUnitDetailsProps = {
  className?: string
  unit: Unit
}

export const VisitUnitDetails: React.FC<VisitUnitDetailsProps> = React.memo(
  ({ className, unit }) => {
    const strings = useTranslations()

    const bathrooms = `${unit?.bathrooms || '?'}`
    const beds = parseBeds(unit)

    return (
      <St.InfoContainer
        className={className}
        data-testid={VisitUnitDetailsTestIds.container}
      >
        <UnitInfoStyles.UnitCode>{unit.unitCode}</UnitInfoStyles.UnitCode>
        <UnitInfoStyles.UnitName>{unit.name}</UnitInfoStyles.UnitName>

        <UnitInfoStyles.UnitLinks>
          <ExternalLink text={strings.resgrid} url={resGridUrl(unit.id)} />

          <ExternalLink
            text={strings.viewListing}
            url={getVacasaViewUnitURL(unit.id)}
          />
        </UnitInfoStyles.UnitLinks>

        <UnitInfoStyles.UnitAddress>{unit.address}</UnitInfoStyles.UnitAddress>
        <St.UnitDetailIcons>
          <SvgIcon
            centerItems={true}
            icon={IconName.bed}
            size={14}
            text={beds}
          />

          <SvgIcon centerItems={true} icon={IconName.bath} text={bathrooms} />

          {unit.hottub && (
            <SvgIcon
              centerItems={true}
              icon={IconName.hotTub}
              dataTestId={VisitUnitDetailsTestIds.hottub}
            />
          )}
        </St.UnitDetailIcons>
      </St.InfoContainer>
    )
  },
)
