/* eslint-disable @typescript-eslint/no-explicit-any */
import { always, identity } from 'lodash/fp'
import * as React from 'react'
import { v4 as uuid } from 'uuid'

import { defaultValueComparator } from './RadioButtons.helpers'
import {
  InputWrapper,
  Label,
  OptionWrapper,
  Radio,
  Title,
} from './RadioButtons.styles'

const defaultIsOptionDisabled = always(false)

export type RadioButtonsProps = {
  className?: string
  dataTestId?: string
  /**
   * (Optional) Callback to parse the value of what should be shown for the label
   * (If not provided, this will simply attempt to render the entire option as the label.)
   */
  getOptionLabel?: (option: any) => React.ReactNode
  /**
   * (Optional) Callback to parse the value of what should passed to the callback when value changes
   * (If not provided, this will simply pass the entire option as the value.)
   */
  getOptionValue?: (option: any) => any
  isOptionDisabled?: (option: any) => boolean
  onChange: (value: any) => void
  /**
   * The set of options to display as radio buttons.
   * These can be more or less anything, but anytime you are providing anything
   * other than simple strings here, you should also consider implementing `getOptionLabel`
   *
   * If the options have an "id" field, this will be used for the iteration key.
   * Otherwise, it will simply use the index of the option's place in the list.
   */
  options: any[]
  /**
   * (Optional) Specify a name for the radio group. If nothing is provided, we will simply use
   * a UUID-based random name, which should be fine for most cases.
   */
  radioGroupName?: string
  selectedValue: any
  title?: string
  /**
   * (Optional) Comparator function used to compare two instances of the associated type.
   *
   * __Note__: If no comparator is provided, the component will simply attempt to compare ID fields.
   */
  valueComparator?: (a: any, b: any) => boolean
}

/**
 * Basic set of native HTML radio buttons. Pass it a set of any type of options, and it will let you choose between them!
 */
export const RadioButtons: React.FC<RadioButtonsProps> = React.memo(props => {
  const {
    className,
    dataTestId,
    getOptionLabel = identity,
    getOptionValue = identity,
    isOptionDisabled = defaultIsOptionDisabled,
    onChange,
    options,
    radioGroupName,
    selectedValue,
    title,
    valueComparator = defaultValueComparator,
  } = props

  const name = React.useMemo(
    () => radioGroupName || `radioGroup-${uuid()}`,
    [radioGroupName],
  )

  return (
    <div className={className} data-testid={dataTestId}>
      {title && <Title>{title}</Title>}

      {options.map((option, idx) => {
        const disabled = isOptionDisabled(option)
        const key = option?.id || `${name}__${idx}`

        return (
          <OptionWrapper disabled={disabled} key={key}>
            <InputWrapper>
              <Radio
                checked={valueComparator(option, selectedValue)}
                data-testid={name}
                disabled={disabled}
                id={key}
                name={name}
                onChange={() => onChange(getOptionValue(option))}
                type="radio"
              />
            </InputWrapper>

            <Label htmlFor={key}>{getOptionLabel(option)}</Label>
          </OptionWrapper>
        )
      })}
    </div>
  )
})
