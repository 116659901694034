import { produce } from 'immer'
import get from 'lodash/get' // eslint-disable-line
import { ActionType, getType } from 'typesafe-actions'

import { getIdAndDataTypeFromAction } from 'packages/utils/store/store.utils'

import { clearZoneAction } from 'app/hkhub/store/zones/actions'

import { RawUser } from '../users/users.types'
import {
  createTicketingAssignmentForDispositionAction,
  fetchTicketingAssignmentsAction,
  fetchTicketingAssignmentByIdAction,
  fetchTicketingDispositionsAction,
  updateTicketingAssignmentAction,
} from './actions'
import { TicketingState } from './ticketing.types'

export const initialTicketingState: TicketingState = {
  assignments: {},
  dispositions: {},
  users: {},
}

const fallbackError = new Error('Unknown Error in ticketingReducer')

const actions = {
  clearZoneAction,
  createTicketingAssignmentForDispositionAction,
  fetchTicketingAssignmentByIdAction,
  fetchTicketingAssignmentsAction,
  fetchTicketingDispositionsAction,
  updateTicketingAssignmentAction,
}

type TicketingActionsTypes = ActionType<typeof actions>

export const ticketingReducer = (
  state = initialTicketingState,
  action: TicketingActionsTypes,
): TicketingState =>
  produce(state, (draft: TicketingState) => {
    switch (action.type) {
      case getType(createTicketingAssignmentForDispositionAction.failure):
      case getType(fetchTicketingAssignmentByIdAction.failure):
      case getType(fetchTicketingAssignmentsAction.failure):
      case getType(fetchTicketingDispositionsAction.failure):
      case getType(updateTicketingAssignmentAction.failure): {
        draft.error = get(action, 'payload', fallbackError)
        return
      }

      case getType(fetchTicketingAssignmentByIdAction.success): {
        const [userId, user] = getIdAndDataTypeFromAction<RawUser>(
          action,
          'user',
        )
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const [assignmentId, assignment] = getIdAndDataTypeFromAction<any>(
          action,
          'zoneDispositionDefault',
        )
        draft.users[userId] = user
        draft.assignments[assignmentId] = assignment
        draft.error = undefined
        return
      }

      case getType(fetchTicketingAssignmentsAction.success): {
        draft.assignments = get(
          action,
          'payload.normalized.zoneDispositionDefault',
          {},
        )

        draft.users = get(action, 'payload.normalized.user', {})
        draft.error = undefined
        return
      }

      case getType(fetchTicketingDispositionsAction.success): {
        draft.dispositions = get(action, 'payload.normalized.disposition', {})
        draft.error = undefined
        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.assignments = {}
        draft.dispositions = {}
        draft.users = {}
        return
      }
    }
  })
