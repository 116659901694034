import { HkEmployeeType, Housekeeper } from './housekeeper.types'

export const isEmployee = (hk: Housekeeper): boolean =>
  hk.employeeType === HkEmployeeType.employee

export const isContractor = (hk: Housekeeper): boolean =>
  hk.employeeType === HkEmployeeType.contractor

export const isAgency = (hk: Housekeeper): boolean =>
  hk.employeeType === HkEmployeeType.agency
