import * as React from 'react'

export type BannerConfig = {
  link?: {
    text: string
    url: string
  }
  message: string
  timeout?: number
}

export type ErrorContextType = {
  clearBannerError: () => void
  setBannerError: (config: BannerConfig) => void
}

export const ErrorContext = React.createContext<ErrorContextType>(
  {} as ErrorContextType,
)

export const useErrorContext = (): ErrorContextType =>
  React.useContext(ErrorContext)
