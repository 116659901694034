import React from 'react'
import { createPortal } from 'react-dom'

import { layers } from 'packages/styles'

import { CloseButton, ModalBackdrop } from '../components'
import { DrawerTestIds } from './Drawer'
import {
  BottomDrawer,
  LeftDrawer,
  RightDrawer,
  TopDrawer,
} from './Drawer.styles'
import { DrawerPosition, DrawerRendererProps } from './Drawer.types'

const rendererMap = {
  [DrawerPosition.Top]: TopDrawer,
  [DrawerPosition.Bottom]: BottomDrawer,
  [DrawerPosition.Left]: LeftDrawer,
  [DrawerPosition.Right]: RightDrawer,
}

export const DrawerPortal = React.forwardRef<
  HTMLDivElement,
  Required<Omit<DrawerRendererProps, 'id'>> & { id?: string }
>((props, ref) => {
  const {
    beginClose,
    children,
    hideBackdrop,
    hideCloseButton,
    id,
    isOpen,
    position,
    renderContainer,
  } = props

  const mutableRef = ref as React.MutableRefObject<HTMLDivElement>
  const elementHeight = mutableRef?.current?.offsetHeight
  const elementWidth = mutableRef?.current?.offsetWidth

  return isOpen
    ? createPortal(
        <>
          {React.createElement(
            rendererMap[position],
            {
              ...props,
              elementHeight,
              elementWidth,
              ref,
            },
            <>
              {!hideCloseButton && <CloseButton onClick={beginClose} />}
              {children}
            </>,
          )}
          {!hideBackdrop && (
            <ModalBackdrop
              {...props}
              dataTestId={DrawerTestIds.backdrop}
              id={id ? `${id}__backdrop` : DrawerTestIds.backdrop}
              onBackdropClick={beginClose}
              zIndex={layers.drawerBackdrop}
            />
          )}
        </>,
        renderContainer || document.body,
      )
    : null
})
