import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { notificationsService } from '../notifications.service'
import {
  NormalizedNotificationsApiResponse,
  ZoneNotificationsActionTypes,
} from '../notifications.types'

export const dismissZoneNotificationAction = createAsyncAction(
  ZoneNotificationsActionTypes.DISMISS_NOTIFICATION,
  ZoneNotificationsActionTypes.DISMISS_NOTIFICATION_SUCCESS,
  ZoneNotificationsActionTypes.DISMISS_NOTIFICATION_FAILURE,
)<RequestConfig<NormalizedNotificationsApiResponse>, string, Error>()

export const getParams = (): RequestOptions => {
  return {
    data: {
      attributes: {
        status: 'dismissed',
      },
      type: 'zone_notification',
    },
  }
}

export const dismissZoneNotification = (id: string) => async dispatch => {
  try {
    const params = getParams()
    const request = notificationsService.updateNotification.bind(
      null,
      id,
      params,
    )
    await dispatch(dismissZoneNotificationAction.request({ request }))
    dispatch(dismissZoneNotificationAction.success(id))
    return id
  } catch (error) {
    dispatch(dismissZoneNotificationAction.failure(error))
    throw error
  }
}
