import { createAsyncAction } from 'typesafe-actions'

import { UserApiFieldsWithoutPII } from 'packages/grimoire'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { zonesService } from '../zones.service'
import {
  NormalizedZonesApiResponse,
  Zone,
  ZonesActionTypes,
} from '../zones.types'

export const fetchZoneAction = createAsyncAction(
  ZonesActionTypes.FETCH_ZONE,
  ZonesActionTypes.FETCH_ZONE_SUCCESS,
  ZonesActionTypes.FETCH_ZONE_FAILURE,
)<
  RequestConfig<NormalizedZonesApiResponse>,
  NormalizedZonesApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    user: UserApiFieldsWithoutPII,
  },
  include: ['invoice_approver_user', 'managers'],
})

export const fetchZoneById = (id: string) => async dispatch => {
  try {
    const params = getParams()
    const request = zonesService.fetchZoneById.bind(null, id, params)
    const result = await dispatch(fetchZoneAction.request({ request }))
    dispatch(fetchZoneAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchZoneAction.failure(error))

    // For an invalid zone ID, remove all references to it in localStorage
    const lastZoneId = localStorage.getItem('lastZoneId')
    if (lastZoneId === id) {
      localStorage.removeItem('lastZoneId')
    }

    let zones: Zone[] = []
    const defaultZones = localStorage.getItem('defaultZones')
    if (defaultZones) {
      zones = zones.concat(JSON.parse(defaultZones))
    }

    const zonesData = zones.filter(zone => zone.id !== id).slice(0, 10)
    localStorage.setItem('defaultZones', JSON.stringify(zonesData))

    throw error
  }
}
