import React from 'react'
import { useSelector } from 'react-redux'

import { getAssignmentChangeById } from 'app/hkhub/store/cleans/selectors/getAssignmentChangeById'
import { UnknownHk } from 'app/hkhub/store/housekeepers/housekeepers.utils'
import { getHousekeeperById } from 'app/hkhub/store/housekeepers/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { getUserById } from 'app/hkhub/store/users/selectors/getUserById'

import { AssignmentChangeDisplay } from './AssignmentChangeDisplay'

export enum AssignmentChangeDisplayTestIds {
  container = 'AssignmentChangeDisplay__container',
}

export type AssignmentChangeDisplayContainerProps = {
  assignmentChangeId: string
}

export const AssignmentChangeDisplayContainer: React.FC<AssignmentChangeDisplayContainerProps> =
  React.memo(({ assignmentChangeId }) => {
    const assignmentChange = useSelector((state: ApplicationState) =>
      getAssignmentChangeById(state, assignmentChangeId),
    )

    const hkId = assignmentChange?.housekeeperId
    const userId = assignmentChange?.changedById

    const housekeeper =
      useSelector((state: ApplicationState) =>
        getHousekeeperById(state.housekeepers, hkId),
      ) || UnknownHk

    const user =
      useSelector((state: ApplicationState) => getUserById(state, userId)) ||
      UnknownHk.user

    return assignmentChange ? (
      <AssignmentChangeDisplay
        assignmentChange={assignmentChange}
        housekeeper={housekeeper}
        user={user}
      />
    ) : null
  })
