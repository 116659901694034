import * as Sentry from '@sentry/react'

import { evolve } from 'packages/utils/misc'

// Define errors (string or RegExp) that should NEVER go to Sentry
const ignoreErrors = [
  /Full authentication redirect required/i,
  /Api request failed/i,
]

// sensitive auth data keys; these values be replaced before being sent to Sentry
const sanitizeKeys = [
  'accessToken',
  'delegateToken',
  'idToken',
  'refreshToken',
  'token',
]

const applyIfNotNull = fn => val => val !== null ? fn(val) : val
const sanitize = applyIfNotNull(() => '[sanitized]')
const authTransforms = sanitizeKeys.reduce((acc, key) => {
  acc[key] = sanitize
  return acc
}, {})

export const initializeSentry = (): boolean => {
  const sentryKey = process.env.REACT_APP_SENTRY_DSN_KEY || ''
  const sentryProjectNumber = process.env.REACT_APP_SENTRY_PROJECT_NUMBER || ''
  const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'staging'
  const release = `housekeeping-web@${process.env.REACT_APP_RELEASE}`
  const dsn = `https://${sentryKey}@o274383.ingest.sentry.io/${sentryProjectNumber}`

  if (sentryKey && sentryProjectNumber) {
    try {
      Sentry.init({
        attachStacktrace: true,

        // https://docs.sentry.io/platforms/javascript/guides/react/data-management/sensitive-data/
        // sorry for the weird conditional nesting here, but we must appease the TS compiler!
        beforeSend: event => {
          if (event.contexts) {
            // update Redux auth state to sanitize any potentially sensitive tokens (replace actual values with [sanitized])
            const reduxAuthState = event.contexts?.['redux.state']?.auth
            if (reduxAuthState) {
              event.contexts['redux.state'].auth = evolve(
                authTransforms,
                reduxAuthState,
              )
            }
          }

          return event
        },
        dsn,
        environment,
        ignoreErrors,
        release,
      })

      return true
    } catch (error) {
      return false
    }
  }

  return false
}
