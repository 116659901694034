import React from 'react'

import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks'

import { FloatingRangeMenu } from './FloatingRangeMenu'

export const FloatingRangeMenuContainer: React.FC = () => {
  const { dayCount } = useScheduleMatchParams()

  return <FloatingRangeMenu dayCount={dayCount} />
}
