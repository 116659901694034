/**********************************************************
 * TASK PHOTO
 *********************************************************/
// all of the fields in the `upload_config.fields` data,
// for authenticating the upload request to S3
type TaskPhotoUploadConfigFields =
  | 'key'
  | 'policy'
  | 'xAmzAlgorithm'
  | 'xAmzCredential'
  | 'xAmzDate'
  | 'xAmzSecurityToken'
  | 'xAmzSignature'

export type TaskPhotoUploadConfig = {
  fields: { [key in TaskPhotoUploadConfigFields]: string }
  url: string
}

export type AllTaskPhotoAttributes = {
  categoryId: string
  createdAt: string
  originalImage: string | null
  thumbnails: {
    medium: string
  }
  uploadConfig: TaskPhotoUploadConfig
}

export const AllTaskPhotoAttributeNames = [
  'categoryId',
  'createdAt',
  'originalImage',
  'thumbnails',
  'uploadConfig',
]
