import * as React from 'react'
import { usePrevious } from 'react-use'
import { v4 as uuid } from 'uuid'

import {
  SentryErrorBoundary,
  Tab,
  TabbedContent,
  useConfirmModal,
  useErrorContext,
  TabTypes,
  useToast,
} from 'packages/common'
import { hasErrorCode } from 'packages/utils/store'
import {
  JsonApiErrorCodes,
  JsonApiErrorResponse,
  RelationshipUpdate,
} from 'packages/utils/store/jsonapi.types'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { Unit } from 'app/hkhub/store/units/units.types'

import { useZoneContext } from '../../zone/ZonePage/ZonePage.context'
import AddHkModalWrapper from '../components/AddHkModal/AddHkModalWrapper'
import AddUnitModalWrapper from '../components/AddUnitModal/AddUnitModalWrapper'
import { ContractorInvoicing } from '../components/ContractorInvoicing'
import EditZoneModalWrapper from '../components/EditZoneModal/EditZoneModalWrapper'
import { HksList } from '../components/HksList'
import TicketRoutingPage from '../components/TicketRoutingPage/TicketRoutingPage'
import { UnitsList } from '../components/UnitsList'
import { ZoneManageReduxProps } from '../manage.types'
import {
  getConfirmModalConfig,
  getManagerString,
} from './ZoneManagePage.helpers'

import styles from './ZoneManagePage.module.scss'

const useErrorMessage = () => {
  const { t } = useI18n()

  const isBorrowedWithUnit = err =>
    hasErrorCode(JsonApiErrorCodes.REMOVE_BORROWED_HK_FROM_ZONE_WITH_UNITS, err)

  const isInZoneWithUnit = err =>
    hasErrorCode(JsonApiErrorCodes.REMOVE_HK_FROM_ZONE_WITH_UNITS, err)

  return (err: JsonApiErrorResponse) => {
    if (isBorrowedWithUnit(err)) return t(Slugs.errorHkBorrowedRemovalWithUnit)
    if (isInZoneWithUnit(err)) return t(Slugs.errorHKRemovalWithUnit)
    return t(Slugs.errorTechSupport)
  }
}

/**
 * Type for using in state for declaring the params for a pending request/action
 * Any operations on this page need to go through a confirmation dialog first, so this
 *    data structure is used to both hold the pending data while this is happening, as well
 *    as abstract the request to we can ultimately use the same handler for multiple operations.
 */
export type RequestConfig = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  entityNameForToast: string
  entityType: RemoveableEntity
  id: string
  owner: any
  reduxAction: (requestData: RelationshipUpdate) => Promise<any>
  relation: any
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

export enum RemoveableEntity {
  Housekeeper = 'Housekeeper',
  Unit = 'Unit',
  Zone = 'Zone',
}

export type ZoneManagePageProps = ZoneManageReduxProps

const ZoneManagePage: React.FC<ZoneManagePageProps> = props => {
  const {
    fetchAllZoneNotifications,
    getAgencyHks,
    getBorrowedHks,
    getPrimaryHks,
    getUnits,
    removeBorrowedZoneFromHk,
    removeHksFromZone,
    removeUnitsFromZone,
  } = props
  const { t, tp } = useI18n()
  const { zone } = useZoneContext()
  const { setBannerError } = useErrorContext()
  const { showToast } = useToast()

  const getErrorMessage = useErrorMessage()

  const [requestConfig, setRequestConfig] = React.useState({} as RequestConfig)
  const prevRequestConfig = usePrevious(requestConfig)
  const [loadingIds, setLoadingIds] = React.useState([] as string[])
  const { modalText, modalTitle } = getConfirmModalConfig(requestConfig, t)

  /**
   * Generic "remove entity" handler suitable for removing anything with IDs from this zone
   * Note that it relies on `requestConfig` being populated before it is called.
   */
  const handleConfirm = React.useCallback(async () => {
    const { entityNameForToast, owner, reduxAction, relation } = requestConfig

    try {
      setLoadingIds([relation.id])
      await reduxAction({
        ownerId: owner.id,
        relationshipIds: [relation.id],
      })

      showToast({ message: `${entityNameForToast} ${t(Slugs.removed)}` })
    } catch (err) {
      const message = getErrorMessage(err as JsonApiErrorResponse)

      setBannerError({
        message,
      })
    } finally {
      setLoadingIds([])
    }
  }, [requestConfig, getErrorMessage, setBannerError, showToast, t])

  const { showModal: showConfirmModal } = useConfirmModal({
    onConfirm: handleConfirm,
    slugs: {
      confirm: Slugs.remove,
      message: modalText,
      title: modalTitle,
    },
  })

  // effect handler for showing a confirmation modal anytime "requestConfig" is updated
  React.useEffect(() => {
    const isNewValidRequestConfig =
      requestConfig?.id && requestConfig.id !== prevRequestConfig?.id

    if (isNewValidRequestConfig) {
      showConfirmModal()
    }
  }, [prevRequestConfig, requestConfig, showConfirmModal])

  const handleRemoveBorrowedZoneFromHk = React.useCallback(
    (hk: Housekeeper) => {
      if (!loadingIds.length) {
        setRequestConfig({
          entityNameForToast: t(Slugs.housekeeper),
          entityType: RemoveableEntity.Zone,
          id: uuid(),
          owner: hk,
          reduxAction: removeBorrowedZoneFromHk,
          relation: zone,
        })
      }
    },
    [loadingIds, removeBorrowedZoneFromHk, t, zone],
  )

  const handleRemoveHkFromZone = React.useCallback(
    (hk: Housekeeper) => {
      if (!loadingIds.length) {
        setRequestConfig({
          entityNameForToast: t(Slugs.housekeeper),
          entityType: RemoveableEntity.Housekeeper,
          id: uuid(),
          owner: zone,
          reduxAction: removeHksFromZone,
          relation: hk,
        })
      }
    },
    [loadingIds, removeHksFromZone, t, zone],
  )

  const handleRemoveUnitFromZone = React.useCallback(
    (unit: Unit) => {
      if (!loadingIds.length) {
        setRequestConfig({
          entityNameForToast: t(Slugs.unit),
          entityType: RemoveableEntity.Unit,
          id: uuid(),
          owner: zone,
          reduxAction: removeUnitsFromZone,
          relation: unit,
        })
      }
    },
    [loadingIds, removeUnitsFromZone, t, zone],
  )

  React.useEffect(() => {
    fetchAllZoneNotifications(zone.id)
  }, [fetchAllZoneNotifications, zone.id])

  const agencyHks = getAgencyHks()
  const primaryHks = getPrimaryHks()
  const borrowedHks = getBorrowedHks()

  const units = getUnits()
  const totalHkCount = primaryHks.length + borrowedHks.length + agencyHks.length
  const managerString = getManagerString(zone.managers, t)

  return (
    <SentryErrorBoundary boundary={'zone-manage-page'}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContent}>
          <div className={styles.contentWrapper}>
            <div className={styles.modalButtonsWrapper}>
              <div className={styles.tabbedContentControls}>
                <AddHkModalWrapper {...props} />
              </div>
              <div className={styles.tabbedContentControls}>
                <AddUnitModalWrapper {...props} />
              </div>
            </div>
            <span className={styles.zoneTitleWrapper}>
              <h2 className={styles.title}>{zone.name}</h2>
              <EditZoneModalWrapper {...props} />
            </span>
            <span>{managerString}</span>
          </div>
        </div>
      </div>

      <TabbedContent
        tabContentClasses={[styles.tabbedContent]}
        tabWrapperClasses={[styles.tabbedWrapper]}
        type={TabTypes.LAKE}
      >
        <Tab title={`${tp(Slugs.unit, 99, false)} (${units.length})`}>
          <UnitsList
            loadingItemIds={loadingIds}
            removeUnit={handleRemoveUnitFromZone}
            units={units}
          />
        </Tab>

        <Tab
          dataTestId={'housekeepersTab'}
          title={`${tp(Slugs.housekeeper, 99, false)} (${totalHkCount})`}
        >
          <HksList
            agencyHousekeepers={agencyHks}
            borrowedHousekeepers={borrowedHks}
            housekeepers={primaryHks}
            loadingItemIds={loadingIds}
            onRemoveBorrowedZoneFromHk={handleRemoveBorrowedZoneFromHk}
            onRemoveHkFromZone={handleRemoveHkFromZone}
          />
        </Tab>

        <Tab title={t(Slugs.ticketRouting)}>
          <TicketRoutingPage {...props} />
        </Tab>

        <Tab title={t(Slugs.contractorInvoicing)}>
          <ContractorInvoicing />
        </Tab>
      </TabbedContent>
    </SentryErrorBoundary>
  )
}

export default React.memo(ZoneManagePage)
