import { produce } from 'immer'
import isEqual from 'lodash/isEqual'

import { User } from 'app/hkhub/store/users/users.types'
import { Zone } from 'app/hkhub/store/zones/zones.types'

/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
const exists = (x: any): boolean => !!x

type EditZoneModalState = {
  apiError: string
  currentManagersDisplayed: (User | null)[]
  isSearchableLength: boolean
  zoneName: string
}

export enum EditZoneModalActionsTypes {
  AppendNullToDisplayedManagers,
  ChangeManagerAtIndex,
  InitializeModal,
  RemoveManagerAtIndex,
  SetApiError,
  SetDisplayedManagers,
  SetIsSearchable,
  SetZoneName,
}

export const initialState: EditZoneModalState = {
  apiError: '',
  currentManagersDisplayed: [],
  isSearchableLength: false,
  zoneName: '',
}

type EditZoneReducerAction = {
  error?: string | null
  isSearchableLength?: boolean
  managerIndex?: number
  managers?: User[]
  newManager?: User
  type: EditZoneModalActionsTypes
  zoneName?: string
}

/**
 * All of these will fallback to their initial state if they require a
 *  piece of data that they do not receive
 */
export function editZoneModalReducer(
  state: EditZoneModalState,
  action: EditZoneReducerAction,
): EditZoneModalState {
  return produce(state, (draft: EditZoneModalState) => {
    switch (action.type) {
      case EditZoneModalActionsTypes.InitializeModal:
        draft.apiError = ''
        draft.currentManagersDisplayed = action.managers || []
        draft.zoneName = action.zoneName || ''
        return

      case EditZoneModalActionsTypes.SetZoneName:
        draft.apiError = ''
        draft.zoneName = action.zoneName || ''
        return

      case EditZoneModalActionsTypes.SetIsSearchable:
        draft.isSearchableLength = action.isSearchableLength || false
        return

      case EditZoneModalActionsTypes.SetApiError:
        draft.apiError = action.error || ''
        return

      case EditZoneModalActionsTypes.ChangeManagerAtIndex:
        if (action.managerIndex !== undefined && action.newManager) {
          draft.currentManagersDisplayed[action.managerIndex] =
            action.newManager
        }

        return

      case EditZoneModalActionsTypes.AppendNullToDisplayedManagers:
        draft.currentManagersDisplayed[draft.currentManagersDisplayed.length] =
          null

        return

      case EditZoneModalActionsTypes.SetDisplayedManagers:
        draft.currentManagersDisplayed = action.managers || []
        return

      case EditZoneModalActionsTypes.RemoveManagerAtIndex:
        if (action.managerIndex !== undefined) {
          draft.currentManagersDisplayed =
            state.currentManagersDisplayed.filter(
              (_, idx) => idx !== action.managerIndex,
            )
        }

        return
    }
  })
}

type shouldAllowSaveArgs = {
  currentManagersDisplayed: (User | null)[]
  zone: Zone
  zoneName: string
}

export function shouldAllowSave({
  currentManagersDisplayed,
  zoneName,
  zone,
}: shouldAllowSaveArgs): boolean {
  const zoneHasManager = !!currentManagersDisplayed.filter(exists).length
  const zoneHasName = !!zoneName.length
  const zoneNameHasChanged = zoneName !== zone.name
  const zoneManagersHaveChanged = !isEqual(
    currentManagersDisplayed.filter(exists),
    zone.managers,
  )
  const zoneHasChangesToSave = zoneNameHasChanged || zoneManagersHaveChanged

  return zoneHasManager && zoneHasName && zoneHasChangesToSave
}

export function handlePopulateModal(
  dispatch: React.Dispatch<EditZoneReducerAction>,
  zone: Zone,
): void {
  dispatch({
    managers: zone.managers,
    type: EditZoneModalActionsTypes.InitializeModal,
    zoneName: zone.name,
  })
}
