import { logInfo } from 'packages/wiretap/logging'

const SERVICE_URL = process.env.REACT_APP_SERVICE_HOST
const FEATURE_BRANCH_TOKEN_URL = `${SERVICE_URL}/feature_branch_token`

export const authService = {
  fetchFeatureBranchToken: async (
    userEmail: string,
    userSecret: string,
    isHub: boolean = false,
  ): Promise<string | undefined> => {
    const handleFailure = (error: string) => {
      logInfo('Error fetching impersonation token', { error })
      throw new Error('Error fetching impersonation token')
    }

    try {
      const response = await fetch(FEATURE_BRANCH_TOKEN_URL, {
        body: JSON.stringify({
          user_email: userEmail,
          user_secret: userSecret,
          is_hub: isHub,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      const responseBody = await response.json()

      if (!response.ok) {
        return responseBody
      }

      return responseBody.access_token
    } catch (error) {
      handleFailure(error)
    }
  },
}
