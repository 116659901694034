import { AxiosResponse } from 'axios'

import {
  ToOneRelationship,
  JSONApiObjectWithRelationshipsMap,
  JSONApiObjectMap,
  NormalizedJSONApiResponse,
} from 'packages/utils/store/jsonapi.types'

import { JSONApiUsersMap, User } from '../users/users.types'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum TicketingActionTypes {
  CREATE_ASSIGNMENT = 'TICKETING/API/CREATE_ASSIGNMENT',
  CREATE_ASSIGNMENT_FAILURE = 'TICKETING/CREATE_ASSIGNMENT_FAILURE',
  CREATE_ASSIGNMENT_SUCCESS = 'TICKETING/CREATE_ASSIGNMENT_SUCCESS',

  FETCH_ALL_DISPOSITIONS = 'TICKETING/API/FETCH_ALL_DISPOSITIONS',
  FETCH_ALL_DISPOSITIONS_FAILURE = 'TICKETING/FETCH_ALL_DISPOSITIONS_FAILURE',
  FETCH_ALL_DISPOSITIONS_SUCCESS = 'TICKETING/FETCH_ALL_DISPOSITIONS_SUCCESS',

  FETCH_ASSIGNMENTS = 'TICKETING/API/FETCH_ASSIGNMENTS',
  FETCH_ASSIGNMENTS_FAILURE = 'TICKETING/FETCH_ASSIGNMENTS_FAILURE',
  FETCH_ASSIGNMENTS_SUCCESS = 'TICKETING/FETCH_ASSIGNMENTS_SUCCESS',

  FETCH_ASSIGNMENT_BY_ID = 'TICKETING/API/FETCH_ASSIGNMENT_BY_ID',
  FETCH_ASSIGNMENT_BY_ID_FAILURE = 'TICKETING/FETCH_ASSIGNMENT_BY_ID_FAILURE',
  FETCH_ASSIGNMENT_BY_ID_SUCCESS = 'TICKETING/FETCH_ASSIGNMENT_BY_ID_SUCCESS',

  UPDATE_ASSIGNMENT = 'TICKETING/API/UPDATE_ASSIGNMENT',
  UPDATE_ASSIGNMENT_FAILURE = 'TICKETING/UPDATE_ASSIGNMENT_FAILURE',
  UPDATE_ASSIGNMENT_SUCCESS = 'TICKETING/UPDATE_ASSIGNMENT_SUCCESS',
}

export type TicketingState = {
  assignments: JSONApiTicketAssignmentMap
  dispositions: JSONApiDispositionMap
  error?: Error
  users: JSONApiUsersMap
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
type TicketingResponse = {
  assignment?: JSONApiTicketAssignmentMap
  disposition?: JSONApiDispositionMap
  user?: JSONApiUsersMap
}

export type CreateAssignmentOptions = {
  dispositionId: string
  userId: string
  zoneId: string
}

export type UpdateAssignmentsOptions = {
  assignmentId: string
  isActive?: boolean
  userId?: string
}

export type NormalizedTicketingApiResponse =
  NormalizedJSONApiResponse<TicketingResponse>
export type TicketingApiResponse = AxiosResponse<NormalizedTicketingApiResponse>
export type TicketingServiceResponse = Promise<NormalizedTicketingApiResponse>

/**********************************************************
 * TICKETING DISPOSITION
 *********************************************************/
export type TicketingDisposition = {
  id: string
} & TicketingDispositionAttributes

export type TicketingDispositionAttributes = {
  tier1Display: string
  tier2Display: string
}

export type DispositionAssignment = {
  id: string
  isActive: boolean
  user: User
}

export type RawTicketingDisposition =
  JSONApiObjectMap<TicketingDispositionAttributes>

export type JSONApiDispositionMap =
  JSONApiObjectMap<TicketingDispositionAttributes>

/**********************************************************
 * TICKET ASSIGNMENT
 *********************************************************/
export type TicketAssignmentAttributes = {
  isActive: boolean
}

export type TicketAssignmentRelationships = {
  disposition: ToOneRelationship
  user: ToOneRelationship
  zone: ToOneRelationship
}

export type JSONApiTicketAssignmentMap = JSONApiObjectWithRelationshipsMap<
  TicketAssignmentAttributes,
  TicketAssignmentRelationships
>
