import { GtmEvents, SegmentEvents } from './events'
import { trackOnGTM } from './providers/gtm'
import { trackOnSegment } from './providers/segment'

export type AnalyticsPayload = Record<string, unknown>

let debug = false
/** Enables debugging, which will confirm to console for every event sent */
export const enableDebug = (): void => {
  debug = true
}

export function track(event: string, payload?: AnalyticsPayload): void {
  // no analytics when testing!
  if (process.env.NODE_ENV === 'test') {
    return
  }

  if (event in GtmEvents) {
    trackOnGTM(event, debug)
  }

  if (event in SegmentEvents) {
    trackOnSegment(event, payload || {}, debug)
  }
}
