import { addDays, createDateString, subDays } from 'packages/utils/dateHelpers'
import { logInfo } from 'packages/wiretap/logging'

import { fetchCleansByZoneAndDate } from 'app/hkhub/store/cleans/actions'
import { fetchHkAvailabilities } from 'app/hkhub/store/hkAvailabilities/actions'
import { fetchReservationsByZoneAndDateRange } from 'app/hkhub/store/reservations/actions'

type RefetchDataForNextAndPrevArgs = {
  dayCount
  dispatch
  startDate
  zoneId
}

/**
 * Attempts to send API requests to fetch schedule data for both "next" and "previous"
 * view sets from the current view.
 *
 * To match the behavior of the UI, for 7-day view this is the full 7-day range; for all
 * other views, it is simply one day in each direction.
 *
 * API errors from these requests are considered non-critical (since these are BG requests),
 * and are simply sent to Sentry as logs/messages.
 */
export const refetchDataForNextAndPrev = ({
  dayCount,
  dispatch,
  startDate,
  zoneId,
}: RefetchDataForNextAndPrevArgs): void => {
  const increment = dayCount === 7 ? 7 : 1

  const nextStartDate = addDays(startDate, dayCount)
  const nextEndDate = addDays(nextStartDate, increment - 1)

  const prevStartDate = subDays(startDate, increment)
  const prevEndDate = addDays(prevStartDate, increment - 1)

  try {
    Promise.all([
      // fetch data for next set of days
      dispatch(fetchCleansByZoneAndDate(zoneId, nextStartDate, nextEndDate)),
      dispatch(fetchHkAvailabilities(zoneId, nextStartDate, nextEndDate)),
      // fetch data for prev set of days
      dispatch(fetchCleansByZoneAndDate(zoneId, prevStartDate, prevEndDate)),
      dispatch(fetchHkAvailabilities(zoneId, prevStartDate, prevEndDate)),
      dispatch(
        fetchReservationsByZoneAndDateRange(
          {
            end: createDateString(prevEndDate),
            start: createDateString(prevStartDate),
          },
          zoneId,
        ),
      ),
    ])
  } catch (err) {
    logInfo('Failed next/prev fetch for schedule', {
      requestData: {
        dayCount,
        nextEndDate: nextEndDate.toString(),
        nextStartDate: nextStartDate.toString(),
        prevEndDate: prevEndDate.toString(),
        prevStartDate: prevStartDate.toString(),
        zoneId,
      },
    })
  }
}
