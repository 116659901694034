import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { clearZoneAction } from 'app/hkhub/store/zones/actions'

import { fetchHkAvailabilitiesAction } from './actions'
import { HkAvailabilitiesState } from './hkAvailabilities.types'

export const DEFAULT_ERROR = new Error(
  'Unknown Error in hkAvailabilitiesReducer',
)

export const initialState: HkAvailabilitiesState = {
  data: {},
  error: undefined,
}

const actions = {
  clearZoneAction,
  fetchHkAvailabilitiesAction,
}

type HkAvailabilitiesActionsTypes = ActionType<typeof actions>

export const hkAvailabilitiesReducer = (
  state = initialState,
  action: HkAvailabilitiesActionsTypes,
): HkAvailabilitiesState =>
  produce(state, (draft: HkAvailabilitiesState) => {
    switch (action.type) {
      case getType(fetchHkAvailabilitiesAction.request): {
        draft.error = undefined
        return
      }

      case getType(fetchHkAvailabilitiesAction.success): {
        const normalized =
          action.payload?.normalized?.effectiveAvailability || {}

        draft.data = {
          ...state.data,
          ...normalized,
        }

        draft.error = undefined
        return
      }

      case getType(fetchHkAvailabilitiesAction.failure): {
        draft.error = action.payload || DEFAULT_ERROR
        return
      }

      // clear all data when switching the current zone
      case getType(actions.clearZoneAction): {
        draft.data = {}
        return
      }
    }
  })
