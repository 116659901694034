import { produce } from 'immer'

import { Zone } from 'app/hkhub/store/zones/zones.types'

export enum ZonePageActions {
  InitializeZone,
  RefetchZone,
  SetZone,
  ZoneLoadError,
  ZoneInitialized,
  ZoneRefetched,
}

export const initialState: ZonePageState = {
  initializingZone: true,
  refetchingZone: false,
  zone: undefined,
  zoneInitialized: false,
  zoneRefetched: true,
}

type ZonePageReducerAction = {
  type: ZonePageActions
  zone?: Zone
}

type ZonePageState = {
  initializingZone: boolean
  refetchingZone: boolean
  zone?: Zone
  zoneInitialized: boolean
  zoneRefetched: boolean
}

export function reducer(
  state: ZonePageState,
  action: ZonePageReducerAction,
): ZonePageState {
  return produce(state, (draft: ZonePageState) => {
    switch (action.type) {
      case ZonePageActions.InitializeZone:
        draft.initializingZone = true
        draft.zoneInitialized = false
        return

      case ZonePageActions.RefetchZone:
        draft.refetchingZone = true
        draft.zoneRefetched = false
        return

      case ZonePageActions.ZoneLoadError:
        draft.initializingZone = false
        draft.zoneInitialized = true
        return

      case ZonePageActions.ZoneInitialized:
        draft.zoneInitialized = true
        return

      case ZonePageActions.ZoneRefetched:
        draft.zoneRefetched = true
        return

      case ZonePageActions.SetZone:
        draft.refetchingZone = false
        draft.initializingZone = false
        draft.zone = action.zone
        return
    }
  })
}
