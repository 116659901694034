import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { unitsService } from '../units.service'
import { UnitApiFields } from '../units.types'

export const getParams = (zoneId: string): RequestOptions => ({
  fields: {
    unit: UnitApiFields,
  },
  filter: {
    is_active: true,
    is_guestworks: false,
    zone: zoneId,
  },
  include: ['lock_box'],
  page: { size: 1500 },
})

export const fetchUnitsByZone = createAsyncThunk(
  'units/api/fetchUnitsByZone',
  async (zoneId: string) => {
    const params = getParams(zoneId)
    return await unitsService.fetchUnits(params)
  },
)
