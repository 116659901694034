import { AxiosResponse } from 'axios'

import {
  JSONApiObjectMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'

//------------------------------------------------
// REDUX TYPES
//------------------------------------------------
export enum DeepCleanSkipsActionTypes {
  CREATE_DEEP_CLEAN_SKIP = 'DEEP_CLEAN_SKIPS/API/CREATE_DEEP_CLEAN_SKIP',
  CREATE_DEEP_CLEAN_SKIP_FAILURE = 'DEEP_CLEAN_SKIPS/CREATE_DEEP_CLEAN_SKIP_FAILURE',
  CREATE_DEEP_CLEAN_SKIP_SUCCESS = 'DEEP_CLEAN_SKIPS/CREATE_DEEP_CLEAN_SKIP_SUCCESS',
}

//------------------------------------------------
// SERVICE TYPES
//------------------------------------------------
export type DeepCleanSkipsResponse = {
  deepCleanSkip: JSONApiDeepCleanSkipsMap
}

export type NormalizedDeepCleanSkipsApiResponse =
  NormalizedJSONApiResponse<DeepCleanSkipsResponse>

export type DeepCleanSkipsServiceResponse =
  Promise<NormalizedDeepCleanSkipsApiResponse>

export type DeepCleanSkipsApiResponse =
  AxiosResponse<NormalizedDeepCleanSkipsApiResponse>

export type DeepCleanSkipPostData = {
  reason: string
  unitId: string
  userId: string
}

type DeepCleanSkipRelKeys = 'skipped_by' | 'unit'
export type DeepCleanSkipPostApiRelationships = {
  [key in DeepCleanSkipRelKeys]: ToOneRelationship
}

export type DeepCleanSkipPostApiAttributes = {
  reason: string
  skipped_at: string
}

//------------------------------------------------
// DEEP CLEAN SKIP
//------------------------------------------------
export type DeepCleanSkipAttributes = {
  reason: string
  skippedAt: string
}

export type JSONApiDeepCleanSkipsMap = JSONApiObjectMap<DeepCleanSkipAttributes>
