import get from 'lodash/get' // eslint-disable-line

import {
  RelationshipUpdate,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  NormalizedZonesApiResponse,
  ZonesApiResponse,
  ZonesServiceResponse,
} from './zones.types'

const URL = '/zones'

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyZonesResponse: NormalizedZonesApiResponse = Object.freeze({
  normalized: { user: {}, zone: {} },
  raw: { data: [] },
})

export const zonesService = {
  /**
   * Performs a GET request for all zones
   * @param params
   */
  async fetchZones(params: RequestOptions = {}): ZonesServiceResponse {
    const response: ZonesApiResponse = await api.get(URL, {
      params,
    })
    return get(response, 'data', emptyZonesResponse)
  },

  /**
   * Performs a GET request for a Zone with the specified ID
   * @param id
   * @param params
   */
  async fetchZoneById(
    id: string,
    params: RequestOptions = {},
  ): ZonesServiceResponse {
    const response: ZonesApiResponse = await api.get(`${URL}/${id}`, { params })
    return get(response, 'data', emptyZonesResponse)
  },

  /**
   * Performs a PATCH request to update the specified zone
   * @param id
   * @param params
   */
  async updateZone(
    id: string,
    params: RequestOptions = {},
  ): ZonesServiceResponse {
    const response: ZonesApiResponse = await api.patch(`${URL}/${id}`, params)
    return get(response, 'data', emptyZonesResponse)
  },

  async createZone(params: RequestOptions = {}): ZonesServiceResponse {
    const response: ZonesApiResponse = await api.post(`${URL}`, params)
    return get(response, 'data', emptyZonesResponse)
  },

  /**
   * Performs a request to POST a relationship between a Zone and another entity
   * e.g. Adding a Unit/HK to a zone
   * @param requestData
   * @param relationshipName
   */
  async addRelationships(
    requestData: RelationshipUpdate,
    relationshipName: string,
  ): ZonesServiceResponse {
    const ownerId = requestData.ownerId
    const relationshipsToAdd = requestData.relationshipIds.map(rId => ({
      id: rId,
      type: relationshipName,
    }))

    const response: ZonesApiResponse = await api.post(
      `${URL}/${ownerId}/relationships/${relationshipName}s`,
      { data: relationshipsToAdd },
    )
    return get(response, 'data', emptyZonesResponse)
  },

  /**
   * Performs a request to DELETE a relationship between a Zone and another entity
   * e.g. Removing a Unit/HK from a zone
   * @param requestData
   * @param relationshipName
   */
  async removeRelationships(
    requestData: RelationshipUpdate,
    relationshipName: string,
  ): ZonesServiceResponse {
    const ownerId = requestData.ownerId
    const relationshipsToRemove = requestData.relationshipIds.map(rId => ({
      id: rId,
      type: relationshipName,
    }))

    const response: ZonesApiResponse = await api.delete(
      `${URL}/${ownerId}/relationships/${relationshipName}s`,
      { data: { data: relationshipsToRemove } },
    )
    return get(response, 'data', emptyZonesResponse)
  },
}
