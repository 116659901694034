import styled from '@emotion/styled'
import { noop } from 'lodash/fp'
import React from 'react'

import { Loader } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'
import { isBefore, startOfToday } from 'packages/utils/dateHelpers'

import { PastCleanOverlay as PastVisitOverlay } from 'app/hkhub/components/schedule/components/schedule.styles'
import {
  cardNotTiny,
  CardSize,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Unit } from 'app/hkhub/store/units'
import { Visit } from 'app/hkhub/store/visits/visits.types'

import { useScheduleMatchParams } from '../../../hooks'
import { Card, CardContent, Row } from '../CleanCard/CleanCard.styles'
import { CleanProgressText } from '../CleanCard/components/CleanProgressText'
import { VisitCardAdditionalInfo, VisitCardLabel } from './components'

const St = {
  CameraIcon: styled(SvgIcon)`
    color: ${colors.success};
    margin-left: 6px;
  `,
  Card,
  CardContent,
  CardHeaderRow: styled(Row)`
    justify-content: left;
  `,
  InspectionIcon: styled(SvgIcon)`
    color: ${colors.success};
    margin-left: 6px;
  `,
  PastVisitOverlay,
}

export enum VisitCardTestIds {
  hasPhotosIcon = 'VisitCard__hasPhotosIcon',
  inspectionCompleteIcon = 'VisitCard__inspectionCompleteIcon',
  pastVisitOverlay = 'VisitCard__pastVisitOverlay',
}

export type VisitCardPassedProps = {
  isLoading: boolean
  visit: Visit
}

export type VisitCardProps = VisitCardPassedProps & {
  onClick: () => void
  size: CardSize
  unit: Unit
}

export const VisitCard: React.FC<VisitCardProps> = ({
  isLoading,
  onClick,
  size,
  unit,
  visit,
}) => {
  const { entity } = useScheduleMatchParams()
  const isPastVisit = isBefore(visit.effectiveDate, startOfToday())

  return (
    <St.Card
      cardType={entity}
      isDeepClean={false}
      isLoading={isLoading}
      onClick={isLoading ? noop : onClick}
      size={size}
      status={visit.realtimeStatus}
    >
      {isLoading && <Loader />}
      {isPastVisit && (
        <PastVisitOverlay data-testid={VisitCardTestIds.pastVisitOverlay} />
      )}

      <St.CardContent
        isDeepClean={false}
        size={size}
        status={visit.realtimeStatus}
      >
        {cardNotTiny(size) && (
          <St.CardHeaderRow>
            <CleanProgressText task={visit} />

            {visit.hasCleanPhotos && (
              <St.CameraIcon
                dataTestId={VisitCardTestIds.hasPhotosIcon}
                icon={IconName.camera}
                size={18}
              />
            )}
            {visit.inspectionCompletedAt && (
              <St.InspectionIcon
                dataTestId={VisitCardTestIds.inspectionCompleteIcon}
                icon={IconName.note}
                size={18}
              />
            )}
          </St.CardHeaderRow>
        )}

        <VisitCardLabel unit={unit} size={size} visit={visit} />
        {cardNotTiny(size) && (
          <VisitCardAdditionalInfo visit={visit} unit={unit} size={size} />
        )}
      </St.CardContent>
    </St.Card>
  )
}
