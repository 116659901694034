import { createAsyncAction } from 'typesafe-actions'

import {
  subDays,
  addDays,
  format,
  DateFormat,
} from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { reservationsService } from '../reservations.service'
import {
  NormalizedReservationsApiResponse,
  ReservationApiFields,
  ReservationsActionTypes,
} from '../reservations.types'

export const fetchReservationsByZoneAndDateRangeAction = createAsyncAction(
  ReservationsActionTypes.FETCH_NEXT_RESERVATIONS_FOR_ZONE_AND_DATE_RANGE,
  ReservationsActionTypes.FETCH_NEXT_RESERVATIONS_FOR_ZONE_AND_DATE_RANGE_SUCCESS,
  ReservationsActionTypes.FETCH_NEXT_RESERVATIONS_FOR_ZONE_AND_DATE_RANGE_FAILURE,
)<
  RequestConfig<NormalizedReservationsApiResponse>,
  NormalizedReservationsApiResponse,
  Error
>()

/* eslint-disable @typescript-eslint/naming-convention */
export const getParams = (
  { start, end }: DateRange,
  zoneId: string,
): RequestOptions => ({
  fields: {
    reservation: ReservationApiFields,
    unit: [],
  },
  filter: {
    // Make sure the date range is wide enough for all times through the target dates
    check_in__lt: format(addDays(end, 1), DateFormat.ApiUtcShort),
    check_out__gte: format(subDays(start, 1), DateFormat.ApiUtcShort),
    is_cancelled: false,
    'unit.zone': zoneId,
  },
  include: ['unit'],
  page: { size: 1500 },
})
/* eslint-enable @typescript-eslint/naming-convention */

export type DateRange = { end: string; start: string }

export const fetchReservationsByZoneAndDateRange =
  (dates: DateRange, zoneId: string) => async dispatch => {
    try {
      const params = getParams(dates, zoneId)
      const request = reservationsService.fetchReservations.bind(null, params)
      const result = await dispatch(
        fetchReservationsByZoneAndDateRangeAction.request({ request }),
      )
      dispatch(fetchReservationsByZoneAndDateRangeAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchReservationsByZoneAndDateRangeAction.failure(error))
      throw error
    }
  }
