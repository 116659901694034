import { difference, xor } from 'lodash/fp'

import {
  ComputedTaskJobType,
  computedTaskJobTypeValues,
  TaskServiceType,
  TaskRealtimeStatus,
  AllTaskAttributes,
  AllTaskAttributeNames,
  TaskJobType,
} from '../task/task.types'

export { TaskRealtimeStatus as CleanRealtimeStatus }
export { TaskServiceType as CleanServiceType }

export type CleanJobType = Exclude<TaskJobType, 'inspection'>

export const computedCleanJobTypeValues = xor(
  ['visit'],
  computedTaskJobTypeValues,
) as Exclude<ComputedTaskJobType, 'visit'>[]

export type ComputedCleanJobType = (typeof computedCleanJobTypeValues)[number]

/**********************************************************
 * CLEAN
 *********************************************************/
/**
 * All keys here are task attribute keys, but the values are more specific to Cleans.
 * All keys in this type should be omitted from `AllTaskAttributes` in the `AllCleanAttributes` definition
 */
type FilteredCleanAttributes = {
  jobType: CleanJobType[]
}

export type AllCleanAttributes = Omit<
  AllTaskAttributes,
  'createdAt' | 'jobType' | 'ticketAttachedCount'
> &
  FilteredCleanAttributes

export const AllCleanAttributeNames = difference(AllTaskAttributeNames)([
  'createdAt',
  'metadata',
])
