import styled from '@emotion/styled'
import * as React from 'react'

import { CleanRealtimeStatus } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { StatusOrb, StatusOrbType } from '../../StatusOrb'

const cleanRealtimeStatusIndicatorMap = {
  [CleanRealtimeStatus.NOT_READY]: StatusOrbType.TRANSPARENT,
  [CleanRealtimeStatus.READY]: StatusOrbType.YELLOW,
  [CleanRealtimeStatus.READY_OVERDUE]: StatusOrbType.RED,
  [CleanRealtimeStatus.IN_PROGRESS]: StatusOrbType.BLUE,
  [CleanRealtimeStatus.IN_PROGRESS_OVERDUE]: StatusOrbType.RED,
}

const animatedStatuses = [
  CleanRealtimeStatus.IN_PROGRESS,
  CleanRealtimeStatus.IN_PROGRESS_OVERDUE,
]

export const shouldAnimateCleanRealTimeStatus = (
  realTimeStatus: CleanRealtimeStatus,
): boolean => animatedStatuses.includes(realTimeStatus)

const CompleteStatusIcon = styled(SvgIcon)`
  color: ${colors.success70};
`

export enum CleanRealTimeStatusIndicatorTestIds {
  check = 'cleanRealTimeStatusIndicatorTestIds__check',
  status = 'cleanRealTimeStatusIndicatorTestIds__status',
}

export type CleanRealtimeStatusIndicatorProps = {
  cleanRealtimeStatus: CleanRealtimeStatus
  dataTestId?: string
}

export const CleanRealtimeStatusIndicator: React.FC<CleanRealtimeStatusIndicatorProps> =
  ({ cleanRealtimeStatus, dataTestId }) => {
    switch (cleanRealtimeStatus) {
      case CleanRealtimeStatus.COMPLETED:
        return (
          <CompleteStatusIcon
            icon={IconName.check}
            dataTestId={dataTestId ?? CleanRealTimeStatusIndicatorTestIds.check}
            size={14}
          />
        )

      default:
        return (
          <StatusOrb
            statusType={cleanRealtimeStatusIndicatorMap[cleanRealtimeStatus]}
            dataTestId={
              dataTestId ?? CleanRealTimeStatusIndicatorTestIds.status
            }
            shouldAnimate={shouldAnimateCleanRealTimeStatus(
              cleanRealtimeStatus,
            )}
          />
        )
    }
  }
