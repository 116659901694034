/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import * as React from 'react'
import AsyncSelect from 'react-select/async'
import { getOptionLabel, getOptionValue } from 'react-select/src/builtins'
import { OptionsType, ValueType } from 'react-select/src/types'

import { colors } from 'packages/styles'

import styles from './AsyncSelector.module.scss'

export enum AsyncSelectorTextStyle {
  DARK,
  WHITE,
}

const setColorFromProvided = color => provided => ({ ...provided, color })

const darkTextStyles = {
  dropdownIndicator: setColorFromProvided(colors.midnight80),
  singleValue: setColorFromProvided(colors.midnight80),
}

const whiteTextStyles = {
  dropdownIndicator: setColorFromProvided(colors.white),
  input: setColorFromProvided(colors.midnight80),
  placeholder: setColorFromProvided(colors.midnight80),
  singleValue: setColorFromProvided(colors.white),
}

const asyncSelectorColorMap = {
  [AsyncSelectorTextStyle.DARK]: darkTextStyles,
  [AsyncSelectorTextStyle.WHITE]: whiteTextStyles,
}

const DEFAULT_SEARCH_DEBOUNCE_TIME = 500

export type AsyncSelectorProps = {
  className?: string
  defaultOptions: OptionsType<any> | boolean
  formatOptionLabel?: (
    item: any,
    params: { inputValue: string },
  ) => React.ReactNode
  getOptionLabel?: getOptionLabel<any>
  getOptionValue?: getOptionValue<any>
  isDisabled?: boolean
  loadOptions: (
    inputValue: string,
    callback: (options: OptionsType<any>) => void,
  ) => Promise<any>
  loadingMessage?: () => string
  noOptionsMessage: (inputValue: ValueType<any>) => string
  onBlur?: () => void
  onChange: (value: ValueType<any>) => void
  onInputChange?: any
  placeholder?: string
  searchDebounceTime?: number
  textStyle?: AsyncSelectorTextStyle
  value?: ValueType<any>
}

const AsyncSelector = React.forwardRef<HTMLInputElement, AsyncSelectorProps>(
  (
    {
      className,
      defaultOptions,
      formatOptionLabel,
      getOptionLabel: getOptionLabelProp,
      getOptionValue: getOptionValueProp,
      isDisabled,
      loadingMessage,
      loadOptions: loadOptionsProp,
      noOptionsMessage,
      onBlur,
      onChange,
      onInputChange,
      placeholder,
      textStyle = AsyncSelectorTextStyle.DARK,
      searchDebounceTime = DEFAULT_SEARCH_DEBOUNCE_TIME,
      value,
    },
    ref,
  ) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedLoadOptions = React.useCallback(
      debounce(
        (searchTerm: string, callback: (result?: any, other?) => any) => {
          loadOptionsProp(searchTerm, callback)
            .then(result => {
              callback(result)
            })
            .catch(error => callback(error, null))
        },
        searchDebounceTime,
      ),
      [loadOptionsProp, searchDebounceTime],
    )

    const loadOptions = React.useCallback(
      (searchValue: string, callback) => {
        const trimmedSearch = searchValue.trim()
        if (trimmedSearch) debouncedLoadOptions(trimmedSearch, callback)
        else callback(null)
      },
      [debouncedLoadOptions],
    )

    const handleChange = React.useCallback(
      (value, { action }) => {
        if (action === 'select-option' && value) onChange(value)
      },
      [onChange],
    )

    const getOptionLabel = React.useCallback(
      (option): string => {
        if (getOptionLabelProp) return getOptionLabelProp(option)
        return typeof option === 'string' ? option : '--'
      },
      [getOptionLabelProp],
    )

    const getOptionValue = React.useCallback(
      (option): string => {
        if (getOptionValueProp) return getOptionValueProp(option)
        return typeof option === 'string' ? option : '--'
      },
      [getOptionValueProp],
    )

    return (
      <AsyncSelect
        backspaceRemovesValue={false}
        blurInputOnSelect={true}
        cacheOptions={false}
        className={classNames([styles.asyncSelector, className])}
        classNamePrefix="asyncSelector"
        defaultOptions={defaultOptions}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled}
        loadingMessage={loadingMessage}
        loadOptions={loadOptions}
        noOptionsMessage={noOptionsMessage}
        onBlur={onBlur}
        onInputChange={onInputChange}
        onChange={handleChange}
        placeholder={placeholder}
        ref={ref}
        styles={asyncSelectorColorMap[textStyle]}
        value={value}
      />
    )
  },
)

export default React.memo(AsyncSelector)
