import React from 'react'

import { SvgIcon, IconName } from 'packages/iconic'
import { useBreakpoint } from 'packages/styles'

import * as St from 'app/hkhub/components/core/components/Titlebar/Titlebar.styles'

export const AdminTitlebar: React.FC = React.memo(() => {
  const breakpoint = useBreakpoint()

  return (
    <St.TitlebarContainer>
      <St.TitlebarContent breakpoint={breakpoint}>
        <St.LeftContent>
          <St.BrandIcon to="/zone">
            <SvgIcon centerItems={true} icon={IconName.vacasa} size={26} />
          </St.BrandIcon>
          <St.BrandTitleText>Admin</St.BrandTitleText>
        </St.LeftContent>
      </St.TitlebarContent>
    </St.TitlebarContainer>
  )
})
