import { anyPass, pipe, prop, propEq, startCase } from 'lodash/fp'

import { UnitRealtimeStatus } from 'packages/grimoire'
import { TranslatorFn } from 'packages/i18n'

import { Slugs } from 'app/hkhub/i18n'

import { UnitViewFilter } from '../../contexts'
import { UnitFilter } from '../unitSchedule.filters'

export const unitStatusTranslations = (
  t: TranslatorFn,
): Record<string, string> => ({
  [UnitRealtimeStatus.CLEAN_COMPLETED]: t(Slugs.clean),
  [UnitRealtimeStatus.CLEAN_IN_PROGRESS]: t(Slugs.cleanStatusInProgress),
  [UnitRealtimeStatus.CLEAN_READY]: t(Slugs.cleanStatusReady),
  [UnitRealtimeStatus.CLEAN_READY_OVERDUE]: t(
    Slugs.cleanStatusOverdueNotStarted,
  ),
  [UnitRealtimeStatus.CLEAN_IN_PROGRESS_OVERDUE]: t(
    Slugs.cleanStatusOverdueInProgress,
  ),
  [UnitRealtimeStatus.OCCUPIED_GUEST]: startCase(t(Slugs.guestStay)),
  [UnitRealtimeStatus.OWNER_HOLD]: startCase(t(Slugs.ownerHold)),
  [UnitRealtimeStatus.VACASA_HOLD]: startCase(t(Slugs.vacasaHold)),
  [UnitRealtimeStatus.VACANT_NO_VACASA_CLEAN]: t(Slugs.vacant),
})

/** Returns whether the provided status matches the status for a given unit */
export const matchesUnitStatus = (unitStatus: UnitRealtimeStatus): UnitFilter =>
  propEq('realtimeStatus', unitStatus)

export const makeUnitStatusFilter = (
  unitStatus: UnitRealtimeStatus,
): UnitViewFilter => ({
  process: pipe(prop('unit'), anyPass([matchesUnitStatus(unitStatus)])),
  toString: () => unitStatus,
  toUiLabel: t => `${unitStatusTranslations(t)[unitStatus]} ${t(Slugs.units)}`,
})
