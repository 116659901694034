import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { Breakpoint, colors, largeScreen, smallScreen } from 'packages/styles'

import {
  ScheduleRowHeaderContent,
  ScheduleRowHeaderText,
  ScheduleRowHeaderTitle,
} from 'app/hkhub/components/schedule/components/schedule.styles'
import { ScheduleSizes } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

import { HoursProgress } from './HoursProgress'

type WithBreakpoint = { breakpoint: Breakpoint }

type StaffScheduleRowHeaderProps = WithBreakpoint & { unassigned: boolean }

const textFontSize: Record<Breakpoint, string> = {
  LG: '14px',
  SM: '12px',
}

export const ContentContainer = ScheduleRowHeaderContent

export const StaffScheduleRowHeader = styled.div<StaffScheduleRowHeaderProps>`
  background: white;
  box-shadow: 0px -1px 0px ${colors.midnight20},
    0px 2px 0px ${colors.midnight20};
  color: ${({ unassigned }) => (unassigned ? colors.alert : colors.midnight)};
  display: ${({ unassigned }) => (unassigned ? 'flex' : 'grid')};
  grid-template-areas:
    'name tier'
    'phone hours';
  grid-template-columns: auto;
  height: ${ScheduleSizes.RowHeaderHeight}px;
  padding: ${({ breakpoint }) => breakpoint === 'SM' && '4px'};
  position: relative;
  z-index: 1;

  ${largeScreen(
    css`
      grid-template-areas: 'name tier phone hours';
      grid-template-columns: auto auto auto 1fr;
    `,
  )};
`

export const ScheduleRowHeaderName = styled(
  ScheduleRowHeaderTitle,
)<WithBreakpoint>`
  align-items: center;
  display: flex;
  grid-area: name;

  ${largeScreen(
    css`
      padding-left: 16px;
    `,
  )};
`

export const ScheduleRowHeaderPhoneNumber = styled.a<WithBreakpoint>`
  align-items: center;
  display: flex;
  color: inherit;
  font-size: ${({ breakpoint }) => textFontSize[breakpoint]};
  grid-area: phone;
  padding-left: ${({ breakpoint }) => (breakpoint === 'LG' ? '16px' : 0)};
  text-decoration: inherit;
`

export const ScheduleRowHeaderTier = styled(
  ScheduleRowHeaderText,
)<WithBreakpoint>`
  align-items: center;
  display: flex;
  grid-area: tier;

  ${smallScreen(
    css`
      justify-content: flex-end;
    `,
  )};
`

export const ScheduleHoursProgress = styled(HoursProgress)`
  grid-area: hours;

  ${largeScreen(
    css`
      padding-right: 16px;
    `,
  )};
`

export const PhoneIcon = styled(SvgIcon)`
  margin-right: 4px;
`
