import { CleanRealtimeStatus } from 'packages/grimoire'
import { colors } from 'packages/styles'

export const cleanRealtimeStatusIndicatorColorMap = {
  [CleanRealtimeStatus.COMPLETED]: colors.success,
  [CleanRealtimeStatus.NOT_READY]: colors.white,
  [CleanRealtimeStatus.READY]: colors.yellow,
  [CleanRealtimeStatus.READY_OVERDUE]: colors.alert,
  [CleanRealtimeStatus.IN_PROGRESS]: colors.lake,
  [CleanRealtimeStatus.IN_PROGRESS_OVERDUE]: colors.alert,
  [CleanRealtimeStatus.MISSED]: colors.midnight40,
}

export const cleanRealtimeStatusTextColorMap = {
  ...cleanRealtimeStatusIndicatorColorMap,
  [CleanRealtimeStatus.NOT_READY]: colors.midnight70,
  [CleanRealtimeStatus.MISSED]: colors.midnight90,
}
