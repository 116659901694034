import { JSONApiObject } from 'packages/utils/store/jsonapi.types'

import { transformNormalizedToZone } from '../utils/transformNormalizedToZone'
import { Zone, ZoneAttributes } from '../zones.types'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const isJsonApiObject = (obj: JSONApiObject<any> | any): boolean => {
  return obj && obj.attributes !== null && obj.attributes !== undefined
}

export const getPreviouslySelectedZones = (): Zone[] => {
  const json = localStorage.getItem('defaultZones')
  if (json) {
    const zones: JSONApiObject<ZoneAttributes>[] = JSON.parse(json)
    return zones.map(zone => {
      return (
        isJsonApiObject(zone) ? transformNormalizedToZone(zone) : zone
      ) as Zone
    })
  }

  return []
}
