import { NormalizedReservationsApiResponse } from './reservations.types'

export const emptyNormalizedReservationsData = Object.freeze({
  reservation: {},
})

export const emptyReservationsResponse: NormalizedReservationsApiResponse =
  Object.freeze({
    normalized: emptyNormalizedReservationsData,
    raw: { data: [] },
  })
