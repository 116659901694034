import { snakeCase } from 'lodash/fp'

import {
  AllInspectionFlagAttributeNames,
  AllInspectionFlagAttributes,
} from 'packages/grimoire/src/inspectionFlag/inspectionFlag.types'
import {
  JsonApiErrorResponse,
  JSONApiObjectWithRelationshipsMap,
  ToOneRelationship,
} from 'packages/utils/store'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type InspectionFlagsState = {
  data: JSONApiInspectionFlagsMap
  error?: JsonApiErrorResponse
}

/**********************************************************
 * INSPECTION FLAG
 *********************************************************/

export const InspectionFlagAttributeNames = AllInspectionFlagAttributeNames

export const InspectionFlagApiFields: string[] =
  InspectionFlagAttributeNames.map(snakeCase)

export type InspectionFlagsRelationships = {
  task: ToOneRelationship
}

export type JSONApiInspectionFlagsMap = JSONApiObjectWithRelationshipsMap<
  AllInspectionFlagAttributes,
  InspectionFlagsRelationships
>
