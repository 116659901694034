import { intersection, pick } from 'lodash/fp'
import React from 'react'
import { useSelector } from 'react-redux'

import { useScheduleContext } from 'app/hkhub/components/schedule/components/common/VirtualizedSchedule/VirtualizedSchedule.context'
import { useUnitFiltersContext } from 'app/hkhub/components/schedule/components/units/contexts'
import { UnitCleanSchedule } from 'app/hkhub/store/cleans/cleans.types'
import { getCleansByDateAndUnit } from 'app/hkhub/store/cleans/selectors/getCleansByDateAndUnit'
import { getReservationsByUnits } from 'app/hkhub/store/reservations/selectors/getReservationsByUnits'
import { ApplicationState } from 'app/hkhub/store/store'
import { getActiveUnitsMap } from 'app/hkhub/store/units/selectors'

import {
  findFilteredUnitIds,
  getSortedIdsFromUnitMap,
} from './useFilteredUnits.helpers'

type UseFilteredUnits = {
  filteredUnitIds: string[]
  filteredUnitRows: UnitCleanSchedule
}

export const useFilteredUnits = (): UseFilteredUnits => {
  const { filters, searchFilters } = useUnitFiltersContext()
  const { dateRange } = useScheduleContext()

  const activeUnitsMap = useSelector((state: ApplicationState) =>
    getActiveUnitsMap(state.units),
  )

  const unfilteredUnitRows = useSelector((state: ApplicationState) =>
    getCleansByDateAndUnit(state, dateRange),
  )

  const reservationsByUnit = useSelector(getReservationsByUnits)

  /*
   * List of Unit IDs that pass all current filters, sorted by Unit Code
   */
  const filteredUnitIds: string[] = React.useMemo(() => {
    const filteredIds = findFilteredUnitIds({
      activeUnitsMap,
      filters,
      reservationsByUnit,
      searchFilters,
      unfilteredUnitRows,
    })

    const sortedUnitIds = getSortedIdsFromUnitMap(activeUnitsMap)

    return intersection(filteredIds, sortedUnitIds)
  }, [
    activeUnitsMap,
    filters,
    reservationsByUnit,
    searchFilters,
    unfilteredUnitRows,
  ])

  /**
   * Unit->Date->Clean buckets that pass the current filters.
   * This object is bucketed in the way the UI expects for row-by-row rendering, like:
   *
   * {
   *   [unit123]: {
   *     [2020-01-01]: Clean[]
   *     [2020-01-02]: Clean[]
   *   },
   *   [unit456]: {
   *     [2020-01-01]: Clean[]
   *     [2020-01-02]: Clean[]
   *   }
   * }
   */
  const filteredUnitRows = React.useMemo(
    () => pick(filteredUnitIds, unfilteredUnitRows),
    [filteredUnitIds, unfilteredUnitRows],
  )

  return {
    filteredUnitIds,
    filteredUnitRows,
  }
}
