import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  TicketingApiResponse,
  TicketingServiceResponse,
} from './ticketing.types'

const URL_DISPOSITIONS = '/dispositions'
const URL_ASSIGNMENTS = '/zone_disposition_defaults'

export const emptyTicketsResponse = Object.freeze({
  normalized: {
    disposition: {},
  },
  raw: { data: [] },
})

export const ticketingService = {
  async fetchTicketingDispositions(
    params: RequestOptions = {},
  ): TicketingServiceResponse {
    const response: TicketingApiResponse = await api.get(URL_DISPOSITIONS, {
      params,
    })
    return get(response, 'data', emptyTicketsResponse)
  },

  async fetchTicketingAssignments(
    params: RequestOptions = {},
  ): TicketingServiceResponse {
    const response: TicketingApiResponse = await api.get(URL_ASSIGNMENTS, {
      params,
    })
    return get(response, 'data', emptyTicketsResponse)
  },

  async fetchTicketingAssignmentById(
    id: string,
    params: RequestOptions = {},
  ): TicketingServiceResponse {
    const response: TicketingApiResponse = await api.get(
      `${URL_ASSIGNMENTS}/${id}`,
      { params },
    )
    return get(response, 'data', emptyTicketsResponse)
  },

  async createAssignment(data: RequestOptions): TicketingServiceResponse {
    const response: TicketingApiResponse = await api.post(URL_ASSIGNMENTS, data)
    return get(response, 'data', emptyTicketsResponse)
  },

  async updateAssignment(
    assignmentId: string,
    data: RequestOptions,
  ): TicketingServiceResponse {
    const response: TicketingApiResponse = await api.patch(
      `${URL_ASSIGNMENTS}/${assignmentId}`,
      data,
    )
    return get(response, 'data', emptyTicketsResponse)
  },
}
