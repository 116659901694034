import styled from '@emotion/styled'
import React from 'react'

import { AsyncSearch, Loader } from 'packages/common'

import { Zone } from 'app/hkhub/store/zones/zones.types'

import { ZoneCard } from '../../ZoneCard'

const buildResultString = (zones: Zone[], lastSearch: string) => {
  if (!lastSearch) return ''
  const results = zones.length === 1 ? 'result' : 'results'
  return `${zones.length} ${results} matching "${lastSearch}"`
}

const St = {
  SearchCount: styled.div`
    font-weight: bold;
    margin-bottom: 8px;
  `,

  ZonesDisplay: styled.div`
    margin-top: 24px;
    position: relative;
  `,
}

export type AdminZoneSearchProps = {
  onSelectZone: (zone: Zone) => void
  searchZones: (searchString: string) => Promise<void>
  zones: Zone[]
}

export const AdminZoneSearch: React.FC<AdminZoneSearchProps> = React.memo(
  ({ onSelectZone, searchZones, zones }) => {
    const [uiState, setUiState] = React.useState({
      lastSearch: '',
      loading: false,
    })
    const { lastSearch, loading } = uiState
    const resultString = buildResultString(zones, lastSearch)

    const handleSearch = React.useCallback(
      async (search: string) => {
        setUiState(prev => ({ ...prev, loading: true }))
        try {
          await searchZones(search)
          setUiState({ lastSearch: search, loading: false })
        } catch (err) {
          // just don't update 'lastSearch' if an error occurs
          setUiState(prev => ({ ...prev, loading: false }))
        }
      },
      [searchZones],
    )

    return (
      <div>
        <AsyncSearch
          id="SearchZones"
          label="Search Zones"
          onSearchTimeout={handleSearch}
        />

        <St.ZonesDisplay>
          {!!resultString && <St.SearchCount>{resultString}</St.SearchCount>}
          {zones.map(zone => (
            <ZoneCard
              onClick={() => onSelectZone(zone)}
              key={zone.id}
              zone={zone}
            />
          ))}
          {loading && <Loader />}
        </St.ZonesDisplay>
      </div>
    )
  },
)
