/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames'
import * as React from 'react'
import ReactSelect from 'react-select'

import { colors } from 'packages/styles'

import styles from './Select.module.scss'

const customStyles = {
  control: (provided, state) => {
    const borderRadius = '2px'
    const borderColor = colors.midnight30
    const borderStyle = 'solid'
    const borderWidth = '1px'
    const color = colors.dusk
    const fontWeight = 500
    const height = '40px'
    const lineHeight = '24px'
    const outline = state.isFocused ? `4px auto ${colors.lake}` : 'none'
    const outlineOffset = state.isFocused && '-2px'
    const transition = state.isFocused && 'none'
    const boxShadow = state.isFocused && 'none'

    return {
      ...provided,
      borderColor,
      borderRadius,
      borderStyle,
      borderWidth,
      boxShadow,
      color,
      fontWeight,
      height,
      lineHeight,
      outline,
      outlineOffset,
      transition,
    }
  },
  option: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1

    return { ...provided, opacity }
  },
}

const IDENTITY = f => f

export type SelectProps = {
  ariaLabel?: string
  clearable?: boolean
  closeMenuOnSelect?: boolean
  components?: Record<string, (props?: any) => React.ReactElement>
  disabled?: boolean
  filterOption?: (
    candidate: {
      data: any
      label: string
      value: any
    },
    input: string,
  ) => boolean
  formatGroupLabel?: (data: any) => React.ReactNode
  formatOptionLabel?: (option: any) => React.ReactNode
  fullWidth?: boolean
  getOptionLabel?: (option: any) => any
  getOptionValue?: (option: any) => any
  hideSelectedOptions?: boolean
  id?: string
  isMulti?: boolean
  isOptionDisabled?: (option: any) => boolean
  maxMenuHeight?: number
  noOptionsMessage?: () => string
  onChange: (value: any, action: any) => void
  onFocus?: () => void
  options?: Record<string, unknown>[]
  placeholder?: string
  searchable?: boolean
  selectedValue: any
  // TODO: find the correct typing for this after we upgrade react-select
  style?: any
}

const Select: React.FC<SelectProps> = ({
  ariaLabel,
  components = undefined,
  id,
  isOptionDisabled,
  clearable = false,
  closeMenuOnSelect = true,
  disabled = false,
  filterOption,
  formatGroupLabel,
  formatOptionLabel,
  fullWidth = false,
  getOptionLabel,
  getOptionValue = IDENTITY,
  hideSelectedOptions = false,
  isMulti = false,
  noOptionsMessage,
  onChange,
  onFocus,
  options,
  maxMenuHeight,
  placeholder = '',
  searchable = true,
  selectedValue,
  style = customStyles,
}: SelectProps) => {
  return (
    <ReactSelect
      aria-label={ariaLabel}
      components={components}
      className={classNames(styles.select, {
        [styles.fullWidth]: fullWidth,
      })}
      classNamePrefix="selectWrapper"
      closeMenuOnSelect={closeMenuOnSelect}
      filterOption={filterOption}
      id={id}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      hideSelectedOptions={hideSelectedOptions}
      isClearable={clearable}
      isDisabled={disabled}
      isMulti={isMulti}
      isOptionDisabled={isOptionDisabled}
      isSearchable={searchable}
      noOptionsMessage={noOptionsMessage}
      maxMenuHeight={maxMenuHeight}
      onFocus={onFocus}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      styles={style}
      value={selectedValue}
    />
  )
}

export default React.memo(Select)
