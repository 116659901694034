import styled from '@emotion/styled'
import React from 'react'

import { useI18n } from 'packages/i18n'
import { centerWithFlex } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'

import { ZoneSelector } from '../core/components/ZoneSelector/ZoneSelector'

const St = {
  Backdrop: styled.div`
    ${centerWithFlex};
    background: rgba(10, 40, 80, 0.1);
    height: 100%;
    position: fixed;
    width: 100%;
  `,
  Container: styled.div`
    background-color: white;
    max-width: 448px;
    padding: 48px;
    width: 448px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 16px;
  `,
}
export const FullPageZoneSelector: React.FC = () => {
  const { t } = useI18n()

  return (
    <St.Backdrop>
      <St.Container>
        <St.Title>{t(Slugs.selectZone)}</St.Title>
        <ZoneSelector />
      </St.Container>
    </St.Backdrop>
  )
}
