import {
  filter,
  groupBy,
  map,
  merge,
  pipe,
  propEq,
  reduce,
  toPairs,
  values,
} from 'lodash/fp'
import { createSelector } from 'reselect'

import { DateFormat, format, formatLocalized } from 'packages/utils/dateHelpers'

import { ApplicationState } from 'app/hkhub/store/store'

import {
  Ticket,
  UnitTicketSchedule,
  RawTicket,
  DateTicketBuckets,
} from '../tickets.types'
import { hydrateRawTicket, stripTimeZoneFromDateString } from '../tickets.utils'

const subGroupByDate =
  () =>
  (acc: UnitTicketSchedule, item: [string, Ticket[]]): UnitTicketSchedule => {
    const [unitId, ticketsBucket] = item

    const ticketsByDate: DateTicketBuckets = pipe(
      groupBy('dueDateNoTimestamp'),
    )(ticketsBucket as (Ticket & { dueDate: string })[])

    acc[unitId] = ticketsByDate
    return acc
  }

const isActiveUnit = propEq('attributes.isActive', true)

const reduceToEmptyIdMap = (acc, { id }) => {
  acc[id] = {}
  return acc
}

export const getTZAwareDateRangeMap =
  (dateRange: Date[]) =>
  (tz: string): Record<string, boolean> =>
    dateRange.reduce((acc, date) => {
      const dateKey = formatLocalized(date, DateFormat.ApiUtcShort, tz)

      acc[dateKey] = true
      return acc
    }, {})

export const getTicketsByDateAndUnit = createSelector(
  (state: ApplicationState) => state.tickets,
  (state: ApplicationState) => state.units,
  (state: ApplicationState) => state.zones,
  (_, dateRange: Date[]) => dateRange,
  (ticketsState, unitsState, zonesState, dateRange): UnitTicketSchedule => {
    // TODO: HUGE assumption - only one zone is selected
    const activeZone = zonesState.data[zonesState.currentZones[0]]

    const timeZone = activeZone.attributes.tz

    const dateRangeMap = getTZAwareDateRangeMap(dateRange)(timeZone)

    const isWithinDateRange = (rawTicket: RawTicket) => {
      const dueDate = stripTimeZoneFromDateString(rawTicket.attributes.dueDate)
      if (!dueDate) return false

      const localizedDueDate = format(dueDate, DateFormat.ApiUtcShort)

      return localizedDueDate in dateRangeMap
    }

    const activeUnitIds = pipe(
      values,
      filter(isActiveUnit),
      reduce(reduceToEmptyIdMap, {}),
    )(unitsState.data)

    const ticketsByUnit = pipe(
      values,
      filter(isWithinDateRange),
      map(hydrateRawTicket),
      groupBy('unitId'),
    )(ticketsState.data)

    const ticketsByUnitAndDate = pipe(
      toPairs,
      reduce(subGroupByDate(timeZone), {}),
    )(ticketsByUnit)

    return merge(activeUnitIds, ticketsByUnitAndDate)
  },
)
