import { AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  AllTicketCommentAttributeNames,
  AllTicketCommentAttributes,
} from 'packages/grimoire/src/ticketComment'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum TicketCommentsActionTypes {
  CREATE_TICKET_COMMENT = 'TICKET_COMMENTS/API/CREATE_TICKET_COMMENT',
  CREATE_TICKET_COMMENT_FAILURE = 'TICKET_COMMENTS/CREATE_TICKET_COMMENT_FAILURE',
  CREATE_TICKET_COMMENT_SUCCESS = 'TICKET_COMMENTS/CREATE_TICKET_COMMENT_SUCCESS',

  FETCH_TICKET_COMMENT = 'TICKET_COMMENTS/API/FETCH_TICKET_COMMENT',
  FETCH_TICKET_COMMENT_FAILURE = 'TICKET_COMMENTS/FETCH_TICKET_COMMENT_FAILURE',
  FETCH_TICKET_COMMENT_SUCCESS = 'TICKET_COMMENTS/FETCH_TICKET_COMMENT_SUCCESS',
}

export type TicketCommentsState = {
  data: JSONApiTicketCommentsMap
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type TicketCommentsResponse = {
  ticketComment: JSONApiTicketCommentsMap
}

export type NormalizedTicketCommentsApiResponse =
  NormalizedJSONApiResponse<TicketCommentsResponse>

export type TicketCommentsServiceResponse =
  Promise<NormalizedTicketCommentsApiResponse>

export type TicketCommentsApiResponse =
  AxiosResponse<NormalizedTicketCommentsApiResponse>

export type TicketCommentPostData = {
  external: boolean
  note: string
  ticketId: string
  userId: string
}

/**********************************************************
 * COMMENT
 *********************************************************/
export type TicketComment = {
  id: string
} & TicketCommentAttributes

export type TicketCommentAttributes = AllTicketCommentAttributes
export const TicketCommentAttributeNames = AllTicketCommentAttributeNames
export const TicketCommentApiFields: string[] =
  TicketCommentAttributeNames.map(snakeCase)

export type TicketCommentRelationships = {
  ticket: ToOneRelationship
  user: ToOneRelationship
}

export type RawTicketComment = JSONApiObjectWithRelationships<
  TicketCommentAttributes,
  TicketCommentRelationships
>

export type JSONApiTicketCommentsMap = JSONApiObjectWithRelationshipsMap<
  TicketCommentAttributes,
  TicketCommentRelationships
>
