import React from 'react'

import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks'

import { ScheduleViewRangeMenu } from './ScheduleViewRangeMenu'

export const ScheduleViewRangeMenuContainer: React.FC = () => {
  const { dayCount } = useScheduleMatchParams()

  return <ScheduleViewRangeMenu dayCount={dayCount} />
}
