import { map, pick, prop } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { hydrateMiniUnitPreference, MiniUnitPreference } from '../units.utils'

const getUnitsState = (state: ApplicationState) => state.units
const getUnitId = (_state: ApplicationState, unitId: string) => unitId

/**
 * Returns a set of Unit/HK Preferences for a given unit ID (or empty array if
 * none are found). Note that this returns only a sub-set of the full UnitPreference
 * data (e.g. only an HK ID instead of the full HK data).
 */
export const getHkPreferencesForUnit = createSelector(
  getUnitsState,
  getUnitId,
  (unitsState, unitId): MiniUnitPreference[] => {
    const rawUnit = unitsState.data[unitId]

    const preferenceIds: string[] = map(
      prop('id'),
      rawUnit.relationships.housekeeperPreferences?.data || {},
    )

    const rawPreferences = pick(
      preferenceIds,
      unitsState.housekeeperPreferences,
    )

    return map(hydrateMiniUnitPreference, rawPreferences)
  },
)
