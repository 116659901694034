import React from 'react'

import { useCurrentCardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

import {
  UnitScheduleEvents,
  UnitScheduleEventsProps,
} from './UnitScheduleEvents'

type UnitScheduleEventsContainerProps = Omit<
  UnitScheduleEventsProps,
  'cardSize'
>

export const UnitScheduleEventsContainer: React.FC<
  UnitScheduleEventsContainerProps
> = props => {
  const cardSize = useCurrentCardSize()

  return <UnitScheduleEvents {...props} cardSize={cardSize} />
}
