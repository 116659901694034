import { produce } from 'immer'
import { ActionType, getType } from 'typesafe-actions'

import { fetchTicketByIdAction } from '../tickets/actions'
import { TicketCommentsState } from './ticketComments.types'
import { emptyNormalizedTicketCommentsData } from './ticketComments.utils'

export const initialState: TicketCommentsState = {
  data: {},
}

const actions = {
  fetchTicketByIdAction,
}

type TicketCommentActionsTypes = ActionType<typeof actions>

export const ticketCommentsReducer = (
  state = initialState,
  action: TicketCommentActionsTypes,
): TicketCommentsState =>
  produce(state, (draft: TicketCommentsState) => {
    switch (action.type) {
      case getType(fetchTicketByIdAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedTicketCommentsData
        draft.data = {
          ...state.data,
          ...normalized.ticketComment,
        }

        return
      }
    }
  })
