import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'

export const St = {
  Container: styled.section`
    font-size: 14px;
  `,

  Content: styled.div`
    flex: 1;
  `,

  CreateTicket: styled.div`
    font-size: 16px;
    margin-bottom: 12px;
  `,

  Header: styled.h3`
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  `,

  Row: styled.div`
    display: flex;

    :not(:last-child) {
      margin-bottom: 20px;
    }
  `,

  StyledSvgIcon: styled(SvgIcon)`
    flex: 0;
    margin-top: 1px;
    padding-right: 16px;
  `,

  ViewTickets: styled.div`
    font-size: 16px;
    margin-bottom: 12px;
  `,
}
