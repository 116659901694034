import React from 'react'
import { useDispatch } from 'react-redux'
import { usePrevious } from 'react-use'

import { isSameDay } from 'packages/utils/dateHelpers'

import { Clean } from 'app/hkhub/store/cleans'
import { fetchNextReservationByClean } from 'app/hkhub/store/reservations/actions'
import { AppDispatch } from 'app/hkhub/store/store'

type useHandleNextResArgs = {
  clean?: Clean
}

export function useHandleNextRes({ clean }: useHandleNextResArgs): boolean {
  const dispatch: AppDispatch = useDispatch()

  const prevCleanId = usePrevious(clean?.id)
  const prevCleanEffectiveDate = usePrevious(clean?.effectiveDate)

  const [state, setState] = React.useState({
    hasFetchedForActiveClean: !!clean?.nextReservation,
    isFetching: false,
    /* ensures that if the next res doesn't exist on the clean, we don't get an infinite loop */
    lastFetchedCleanId: '',
  })

  const handlePreFetch = () =>
    setState(prev => ({
      ...prev,
      hasFetchedForActiveClean: false,
      isFetching: true,
    }))

  const handlePostFetch = cleanId =>
    setState(prev => ({
      ...prev,
      hasFetchedForActiveClean: true,
      isFetching: false,
      lastFetchedCleanId: cleanId,
    }))

  const handleFetchNextRes = React.useCallback(async () => {
    if (!clean) return

    handlePreFetch()
    await dispatch(fetchNextReservationByClean(clean))
    handlePostFetch(clean?.id)
  }, [clean, dispatch])

  /*
   * Watch for changes that require us to fetch the next reservation for the current clean.
   * These conditions include:
   * - We have a valid clean that does not have a "next res" attached to it
   * - The clean has changed since last time (i.e. user not re-opening the same clean multiple times)
   * - We have not already attempted to fetch for this clean (i.e. to handle cleans with NO next res)
   * - We are not already fetching the reservation
   */
  React.useEffect(() => {
    const needsToFetch =
      !clean?.nextReservation &&
      !!clean?.id &&
      !state.isFetching &&
      state.lastFetchedCleanId !== clean?.id

    if (needsToFetch) {
      handleFetchNextRes()
    }
  }, [
    clean,
    dispatch,
    handleFetchNextRes,
    state.isFetching,
    state.lastFetchedCleanId,
  ])

  /*
   * If the date changes but the clean does not, this clean has probably been
   * rescheduled, so we need to ensure we have the latest "next res" data.
   */
  React.useEffect(() => {
    const isDifferentDate =
      prevCleanEffectiveDate &&
      clean?.effectiveDate &&
      !isSameDay(prevCleanEffectiveDate, clean?.effectiveDate)

    const isSameClean = !!clean?.id && clean.id === prevCleanId

    if (isSameClean && isDifferentDate) {
      handleFetchNextRes()
    }
  }, [
    clean?.effectiveDate,
    clean?.id,
    handleFetchNextRes,
    prevCleanEffectiveDate,
    prevCleanId,
  ])

  return state.hasFetchedForActiveClean
}
