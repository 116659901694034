type CodeKeys = 'name' | 'unitCode'
type WithCodeAndName = {
  [key in CodeKeys]: string
}

/**
 * Parses the unit code + name string for the provided Unit.
 * The format for this string is: [unit code] - [unit name]
 * @param unit
 */
export const unitCodeAndNameString = (unit: WithCodeAndName): string =>
  `${unit.unitCode} - ${unit.name}`

type AddressKeys = 'address' | 'city' | 'state'
type WithAddress = {
  [key in AddressKeys]: string
}

/**
 * Parses the full US address string of the provided Unit.
 * The format for this address string is: [street address], [city], [state]
 * @param unit
 */
export const unitFullAddressString = (unit: WithAddress): string =>
  `${unit.address}, ${unit.city}, ${unit.state}`
