import classNames from 'classnames'
import * as React from 'react'

import styles from './TabbedContent.module.scss'

export enum TabTypes {
  DEFAULT = 'default',
  FLOATING = 'floating',
  LAKE = 'lake',
}

export type TabbedContentProps = {
  children: React.ReactNode
  /**
   * Additional CSS classes to apply to the actual tab area.
   *
   * This can be useful, for example, for adding a `max-width` constraint to the actual tabs themselves.
   */
  tabContentClasses?: string[]
  /**
   * Additional CSS classes to apply to the outer wrapper of the tabs area.
   *
   * This can be useful, for example, for adding a `background-color` that spans the full page with.
   */
  tabWrapperClasses?: string[]
  /** Enum value determining the style of the tabs*/
  type?: TabTypes
}

export type TabbedContentState = {
  activeTabIdx: number
  hoverTabIdx: number | null
}

const TabbedContent: React.FunctionComponent<TabbedContentProps> = ({
  children,
  tabContentClasses = [],
  tabWrapperClasses = [],
  type = TabTypes.DEFAULT,
}) => {
  const [activeTabIdx, setActiveTabIdx] = React.useState(0)

  let activeChildContent = null

  return (
    <>
      <div
        className={classNames(
          styles.tabbedContentWrapper,
          styles[type],
          ...tabWrapperClasses,
        )}
      >
        <div className={classNames(styles.tabbedContent, ...tabContentClasses)}>
          {React.Children.map(children, (child, idx) => {
            const tab = child as React.ReactElement
            const isActiveTab = idx === activeTabIdx

            // save a reference to the renderable content from the active tab (to render below)
            if (isActiveTab) {
              activeChildContent = tab.props.children
            }

            return (
              <div className={styles.tabWrapper}>
                {React.cloneElement(tab, {
                  active: isActiveTab,
                  onClick: () => setActiveTabIdx(idx),
                  type: type,
                })}
                {isActiveTab &&
                  (type === TabTypes.DEFAULT || type === TabTypes.LAKE) && (
                    <span
                      className={classNames(styles.activeTab, styles[type])}
                    />
                  )}
              </div>
            )
          })}
        </div>
      </div>

      {/* render the active Tab's content _outside_ of the main tabs area */}
      {activeChildContent}
    </>
  )
}

export default TabbedContent
