import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store'

import { ApplicationState } from '../../store'
import { UnitOncallUser } from '../units.types'

export const getOncallUserForUnit = createSelector(
  // Input selectors
  (state: ApplicationState, unitId: string) =>
    state.units.data?.[unitId]?.relationships?.oncallUser?.data?.id,
  (state: ApplicationState) => state.units.oncallUsers,

  // Output selector
  (oncallUserId, oncallUsers): UnitOncallUser | undefined => {
    if (!oncallUserId || !oncallUsers?.[oncallUserId]) {
      return undefined
    }

    const rawOncallUser = oncallUsers[oncallUserId]

    return transformNormalizedToTyped<UnitOncallUser>(rawOncallUser, [
      'firstName',
      'lastName',
      'reasonForAssignment',
      'mobilePhone',
    ])
  },
)
