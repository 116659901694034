import React from 'react'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'

import { parseISO } from 'packages/utils/dateHelpers'

import { useScheduleNavigation } from './hooks'
import { SchedulePage } from './SchedulePage'

/**
 * A simple redirect component to maintain backwards compatibility with our old URL schema.
 * This renders nothing, but simply does an immediate redirect re-mapping the old schema to the new.
 *
 * e.g.
 * FROM: /zone/{zoneId}/schedule/{date}/{viewType}/{entity}
 * TO:   /zone/{zoneId}/schedule/{entity}?date={date}&days={dayCount}
 *
 * Note that most of these are just moving params, with the exception of "viewType",
 * where before it was [day|week], it is now "days" as a simple numeric value.
 */
const LegacyScheduleRedirect = () => {
  const { navigate } = useScheduleNavigation()
  const { date, days } = useParams<{
    date: string
    days: string
    entity: string
  }>()
  if (date !== undefined && days !== undefined) {
    const dateQuery = parseISO(date)
    const dayCountQuery = days === 'day' ? 1 : 7

    navigate({ date: dateQuery, dayCount: dayCountQuery })
  }

  return null
}

export const ScheduleRouter: React.FC = props => {
  return (
    <Routes>
      {/* ROUTES WITH "/zone/:zoneId/schedule/" PREFIX */}
      <Route path=":scheduleEntity" element={<SchedulePage {...props} />} />

      <Route path="" element={<Navigate to={`staff`} replace={true} />} />

      <Route path=":date/:days/:entity" element={<LegacyScheduleRedirect />} />
    </Routes>
  )
}
