import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Button, InputField, InputFieldProps } from 'packages/common'
import { colors, Breakpoint, smallScreen } from 'packages/styles'

import { ScheduleSizes } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

const contentHeight = ScheduleSizes.ScheduleControls

type WithBreakpoint = {
  breakpoint: Breakpoint
}

type Hideable = {
  hidden: boolean
}

const hiddenStyles = css`
  display: none;
  visibility: hidden;
`

const nonHiddenStyles = css`
  display: inherit;
  visibility: visible;
`

export const ScheduleControlsContainer = styled.div`
  background: ${colors.midnight};
  border-top: 1px solid ${colors.midnight60};
  width: 100%;
`

export const ScheduleControlsContent = styled.div<WithBreakpoint>`
  align-items: center;
  color: white;
  display: flex;
  justify-content: space-between;

  height: ${({ breakpoint }) => contentHeight[breakpoint]}px;
  padding: ${({ breakpoint }) => (breakpoint === 'SM' ? '8px' : '16px')};
`

export const TodayButton = styled(Button)`
  margin-right: 24px;
`

export const ArrowGroup = styled.div`
  margin-left: 8px;
`

export const ArrowButton = styled(Button)`
  border-radius: 100px;
  font-size: 1.25rem;
  height: 40px;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
  width: 40px;

  &,
  &:active,
  &:hover {
    border-color: transparent;
  }

  &:active,
  &:focus {
    outline: none;
  }
`

export const DateDisplay = styled.div`
  ${smallScreen(
    css`
      font-size: 16px;
    `,
  )};
  font-size: 21px;
  font-weight: bold;
`

export const ControlGroup = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`

const mobileSearchField = css`
  left: 0;
  margin: 0;
  padding: 0 2px;
  position: absolute;
  right: 0;
`

export const UnitFilterControls = styled(ControlGroup)`
  margin-left: auto;
  margin-right: 8px;
`

export const UnitSearchField = styled(InputField)<
  InputFieldProps & WithBreakpoint & Hideable
>`
  color: ${colors.midnight60};

  ${({ breakpoint }) => (breakpoint === 'SM' ? mobileSearchField : '')};
  ${({ hidden }) => (hidden ? hiddenStyles : nonHiddenStyles)};
`

export const ViewControls = styled(ControlGroup)`
  div:not(:last-of-type) {
    margin-right: 16px;
  }
`
