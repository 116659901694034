/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO: Update the functions in this file to return data cast to the generic type passed in (and remove the ESLint-disable above)
import get from 'lodash/get' // eslint-disable-line
import pick from 'lodash/pick'
import snakeCase from 'lodash/snakeCase'
import { PayloadAction } from 'typesafe-actions/dist/type-helpers'

import { InspectionFlag } from 'packages/grimoire'

import { JSONApiObject, JSONApiObjectWithRelationships } from './jsonapi.types'

export type noResultDefaults = {
  dataType: any
  id: string | number
}

export type IdDataTypeArray<T> = [string | number, T]

/**
 * A helper to extract the normalized data for a particular entity from a payload.
 *  For instance, an action that gets units and reservations, where this function takes said action
 *  and the string 'units' will return `action.payload.normalized.units`, and safely return undefined
 *  if anything in that chain does not exist.
 * @param action an action with a payload
 * @param dataType a string representing the key by which the target data is represented
 */
export function getKeyedDataFromAction(
  action: PayloadAction<string, any>,
  dataType: string,
) {
  return get(action, `payload.normalized[${dataType}]`)
}

/**
 * Helper for extracting entity ids and data (`JSONApiObject<dataTypeAttributes>`) from actions in reducers.
 *  For use when it can be assumed that there will be one, and only one object returned,
 *  for instance, when calling fetchUnitById, only one unit should be expected on the payload
 *
 * NOTE: If JSONApiObject returned from this function includes relationships, the output will
 * likely need to be forced to a JSONApiObjectWithRelationships type
 * @param action an action with a payload
 * @param dataType a string representing the key by which the target data is represented
 * @param defaults fallback options for data and id in the case no data exists
 */
export function getIdAndDataTypeFromAction<T>(
  action: PayloadAction<string, any>,
  dataType: string,
  defaults: noResultDefaults = { dataType: {}, id: '' },
): IdDataTypeArray<T> {
  //TODO I think the default is wrong here - increase tests
  const keyedDataFromAction =
    getKeyedDataFromAction(action, dataType) || defaults.dataType

  const id: string | number = get(
    Object.keys(keyedDataFromAction),
    '[0]',
    defaults.id,
  )
  const data = get(keyedDataFromAction, id, defaults.dataType)
  return [id, data]
}

export function getInspectionFlagFromAction(
  action: PayloadAction<string, any>,
): InspectionFlag[] {
  return Object.entries(action.payload?.normalized.inspectionFlag).map(
    ([key, value]: [string, any]) => ({
      categoryId: value.attributes.categoryId,
      deletedAt: value.attributes.deletedAt,
      id: key,
      itemId: value.attributes.itemId,
      notes: value.attributes.notes,
      taskId: Object.keys(action.payload?.normalized.task)[0],
    }),
  )
}

/**
 *  Helper for extracting arrays of ids and data (`JSONApiObject<dataTypeAttributes>`) from actions in reducers.
 *  For use when more than one entity is possible, for instance, fetchAllUnits,
 *  where zero, one, or many units may be returned. If zero are returned, this will return
 *  an empty array
 *
 * NOTE: If the JSONApiObjects returned from this function includes relationships, the outputs will
 * likely need to be forced to a JSONApiObjectWithRelationships type
 * @param action an action with a payload
 * @param dataType a string representing the key by which the target data is represented
 */
export function getArrayOfIdsAndDataTypesFromAction<T>(
  action: PayloadAction<string, any>,
  dataType: string,
): IdDataTypeArray<T>[] {
  const keyedDataFromAction = getKeyedDataFromAction(action, dataType) || {}

  return Object.keys(keyedDataFromAction).map(
    (id: string | number): IdDataTypeArray<T> => {
      const data = get(keyedDataFromAction, id)
      return [id, data]
    },
  )
}

/**
 * Simple helper function to dig into raw JsonAPI data and attempt to find the `id`
 * for a given individual relationship.
 * @param obj The raw JsonAPI object to inspect
 * @param relationshipName The name of the associated relationship
 */
export const findRelationshipId = (
  obj,
  relationshipName: string,
): string | undefined =>
  get(obj, ['relationships', relationshipName, 'data', 'id'])

export const findAllRelationshipIds = (
  jsonApiObject: JSONApiObjectWithRelationships<any, any>,
  relationshipName: string,
): string[] => {
  const data = jsonApiObject.relationships?.[relationshipName]?.data

  return Array.isArray(data) ? data.map(rel => rel.id) : []
}

/**
 * Returns a new "flattened" object consisting of the properties in `attributes`
 * picked from `jsonApiObject.attributes`.
 *
 * @param jsonApiObject the JSON API Object to be transformed
 * @param attributes the attributes to extract from the JSON API Object
 */
export const transformNormalizedToTyped = <T>(
  jsonApiObject: JSONApiObject<any> | JSONApiObjectWithRelationships<any, any>,
  attributes: string[],
): T => {
  const picked =
    Array.isArray(attributes) && attributes.length > 0
      ? pick(jsonApiObject.attributes, attributes)
      : {}
  const generic: unknown = Object.assign({}, picked, { id: jsonApiObject.id })
  return generic as T
}

/**
 * Takes an object and returns a copy of the object with each key passed through Lodash's `snakeCase` function
 * Note that this does not work for all cases, B2B being one common example
 * @param object
 */
export function convertObjectKeysToSnakeCase<T extends {}>(object: T) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    return { ...acc, [snakeCase(key)]: value }
  }, {})
}
