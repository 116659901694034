/**
 * A set of helper functions to simplify the process of preventing scrolling while a Modal is open.
 *
 * The bulk of this logic is a simplified version of the way ReactStrap handles doing the same:
 * https://github.com/reactstrap/reactstrap/blob/master/src/utils.js
 */
const getScrollbarWidth = () => {
  const scrollDiv = document.createElement('div')
  scrollDiv.style.position = 'absolute'
  scrollDiv.style.top = '-9999px'
  scrollDiv.style.width = '50px'
  scrollDiv.style.height = '50px'
  scrollDiv.style.overflow = 'scroll'
  document.body.appendChild(scrollDiv)

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)

  return scrollbarWidth
}

const setScrollbarWidth = padding => {
  document.body.style.paddingRight = padding > 0 ? `${padding}px` : 'unset'
}

const isBodyOverflowing = () => document.body.clientWidth < window.innerWidth

const getCurrentBodyPadding = () => {
  const style = window.getComputedStyle(document.body, null)
  return parseInt(style && style.getPropertyValue('padding-right'), 10) || 0
}

let originalBodyPadding = 0

/**
 * Removes any "scroll-blocking" styles that have been applied to the body.
 * Essentially, this needs to be called when a Modal is hidden, in order to return the body to its original state.
 */
export const resetBodyScrolling = (): void => {
  setScrollbarWidth(originalBodyPadding)
  document.body.style.overflow = 'unset'
}

/**
 * Ensures that the document body does not have the ability to scroll, which is an important
 * consideration when a Modal is open, because we don't want the content _behind_ the Modal scrolling.
 *
 * Essentially, this sets `overflow: hidden` on the body, and does a little extra behind-the-scenes calculation
 * to ensure scroll bars are accounted for by adding an appropriate amount of padding in their place.
 */
export const disableBodyScrolling = (): void => {
  if (isBodyOverflowing()) {
    originalBodyPadding = getCurrentBodyPadding()
    setScrollbarWidth(getScrollbarWidth())
    document.body.style.overflow = 'hidden'
  }
}
