import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { layers } from 'packages/styles'

import {
  CardSize,
  CARD_MARGIN_Y,
  staffCardSizeHeightMap,
  unitCardSizeHeightMap,
} from '../../../helpers/scheduleMeasure'
import { ScheduleEntityType } from '../../../schedule.types'

const shimmer = keyframes`
  0% {
    background-position: 200% 0;
    
  }

  100% {
    background-position: 0 0;
  }
`

const St = {
  Shimmer: styled.div<{ cardType: ScheduleEntityType; size: CardSize }>`
    animation: ${shimmer} 2s infinite linear;
    background: linear-gradient(to right, #ffffff 4%, #e7e7e7 25%, #ffffff 52%);
    background-size: 200% 100%;
    border-radius: 2px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    height: ${({ cardType, size }) =>
      `${
        cardType === 'staff'
          ? staffCardSizeHeightMap[size]
          : unitCardSizeHeightMap[size]
      }px`};
    margin: ${CARD_MARGIN_Y}px;
    z-index: ${layers.shimmer};
  `,
}

export enum DateCellLoaderTestIds {
  container = 'DateCellLoader__container',
}

export type DateCellLoaderProps = {
  cardType: ScheduleEntityType
  size: CardSize
}

export const DateCellLoader: React.FC<DateCellLoaderProps> = React.memo(
  ({ cardType, size }) => {
    return (
      <St.Shimmer
        data-testid={DateCellLoaderTestIds.container}
        cardType={cardType}
        size={size}
      />
    )
  },
)
