/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Tracker } from './trackers/trackers.types'

type WiretapMiddlewareConfig<StateType> = {
  trackers: Tracker<StateType>[]
}

export function getWireTapMiddle<StateType>({
  trackers,
}: WiretapMiddlewareConfig<StateType>) {
  return ({ getState }) =>
    next =>
    async action => {
      const preState = getState()
      const partialyAppliedTrackers = trackers.map(t => t(action)(preState))
      const result = next(action)

      const postState = getState()
      partialyAppliedTrackers.forEach(pT => pT(postState))
      return result
    }
}
