import { createAsyncAction } from 'typesafe-actions'

import {
  RelationshipUpdate,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { updateUnitInSearchResults } from 'app/hkhub/store/units/actions'

import { zonesService } from '../zones.service'
import { NormalizedZonesApiResponse, ZonesActionTypes } from '../zones.types'

export const addUnitsToZoneAction = createAsyncAction(
  ZonesActionTypes.ADD_UNITS_TO_ZONE,
  ZonesActionTypes.ADD_UNITS_TO_ZONE_SUCCESS,
  ZonesActionTypes.ADD_UNITS_TO_ZONE_FAILURE,
)<
  RequestConfig<NormalizedZonesApiResponse>,
  NormalizedZonesApiResponse,
  Error
>()

export const addUnitsToZone =
  (requestData: RelationshipUpdate) => async dispatch => {
    try {
      const request = zonesService.addRelationships.bind(
        null,
        requestData,
        'unit',
      )
      const result = await dispatch(addUnitsToZoneAction.request({ request }))
      result.normalized.relationshipUpdate = requestData

      const unitId = requestData.relationshipIds[0]
      await dispatch(updateUnitInSearchResults(unitId))
      await dispatch(addUnitsToZoneAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(addUnitsToZoneAction.failure(error))
      throw error
    }
  }
