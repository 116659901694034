import styled from '@emotion/styled'
import get from 'lodash/get' // eslint-disable-line
import * as React from 'react'
import { usePrevious } from 'react-use'

import {
  Alert,
  Loader,
  useConfirmModal,
  useErrorContext,
} from 'packages/common'
import { ModalContent } from 'packages/common/src/modals/Modal/Modal.styles'
import { useOnlineStatus } from 'packages/utils/hooks'

import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { TicketingDisposition } from 'app/hkhub/store/ticketing/ticketing.types'
import { User } from 'app/hkhub/store/users/users.types'

import { ZoneManageReduxProps } from '../../manage.types'
import TicketRoutingCard from './components/TicketRoutingCard/TicketRoutingCard'

import styles from './TicketRoutingPage.module.scss'

const CoverageLink = styled(ModalContent)`
  margin-top: 16px;
`

export type TicketRoutingPageProps = ZoneManageReduxProps

/**
 * We need this type to handleAssignmentSave work in it's current form.
 * - When an assignment is first made no DispositionAssignment exists so id is not defined
 * - Typescript sees that the assignee passed is sometimes undefined, this will never actually get called this way though.
 */
export type AssignmentSaveConfig = {
  id?: string
  isActive: boolean
  user?: User
}

export type ConfirmModalConfig = {
  id: string
  newAssignmentMonolithUserId?: string
  onCancel: () => void
  onConfirm: () => void
}

const TicketRoutingPage: React.FC<TicketRoutingPageProps> = ({
  createTicketingAssignmentForDisposition,
  fetchTicketingAssignments,
  fetchTicketingDispositions,
  fetchTicketingAssignmentById,
  getAssignmentsKeyedByDispositionIds,
  getDispositionsBucketedByTier,
  getUsersSearchResults,
  searchUsers,
  updateTicketingAssignment,
}) => {
  const { t } = useI18n()
  const { zone } = useZoneContext()
  const { setBannerError } = useErrorContext()

  const [assignments, setAssignments] = React.useState({})
  const [dispositions, setDispositions] = React.useState<{
    [key: string]: TicketingDisposition[]
  }>({})

  const [confirmModalConfig, setConfirmModalConfig] =
    React.useState<ConfirmModalConfig>({} as ConfirmModalConfig)
  const prevConfirmModalConfig = usePrevious(confirmModalConfig)

  const [isLoading, setIsLoading] = React.useState(true)
  const [loadingId, setLoadingId] = React.useState<string | null>(null)

  const currentZoneId = get(zone, 'id')

  React.useEffect(() => {
    const fetchTicketingData = async () => {
      await fetchTicketingDispositions()
      await fetchTicketingAssignments(currentZoneId)
      setIsLoading(false)
    }

    if (currentZoneId) fetchTicketingData()
  }, [currentZoneId]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!isLoading) {
      setAssignments(getAssignmentsKeyedByDispositionIds())
      setDispositions(getDispositionsBucketedByTier())
    }
  }, [
    getAssignmentsKeyedByDispositionIds,
    getDispositionsBucketedByTier,
    isLoading,
  ])

  const handleAssignmentSave = React.useCallback(
    async (dispositionId: string, assignment: AssignmentSaveConfig) => {
      setLoadingId(dispositionId)
      try {
        let assignmentId
        if (assignment.id) {
          assignmentId = assignment.id
          await updateTicketingAssignment({
            assignmentId,
            isActive: assignment.isActive,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            userId: assignment.user!.id, // It is only possible to save, and thus call this button if the user exists
          })
        } else {
          const newAssignment = await createTicketingAssignmentForDisposition({
            dispositionId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            userId: assignment.user!.id, // It is only possible to save, and thus call this button if the user exists
            zoneId: currentZoneId,
          })

          assignmentId = get(
            Object.keys(get(newAssignment, 'zoneDispositionDefault')),
            '[0]',
          )
        }

        await fetchTicketingAssignmentById(assignmentId)
      } catch (e) {
        setBannerError({
          message: t(Slugs.errorTechSupport),
        })
      }

      setLoadingId(null)
    },
    [currentZoneId, setBannerError], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const { showModal: showConfirmModal } = useConfirmModal({
    extraChildren: (
      <CoverageLink>
        <a
          className={styles.ticketsLink}
          href={
            confirmModalConfig && confirmModalConfig.newAssignmentMonolithUserId
              ? `https://admin.vacasa.com/admin/dashboard/schedules/calendar?scheduleType=null&userID=${confirmModalConfig.newAssignmentMonolithUserId}`
              : `https://admin.vacasa.com/admin/dashboard/schedules/index`
          }
          target="_blank"
          rel="noreferrer"
        >
          {`${t(Slugs.ticketRoutingConfirmModalXcLinkText)} →`}
        </a>
      </CoverageLink>
    ),
    onConfirm: confirmModalConfig.onConfirm,
    slugs: {
      confirm: Slugs.ticketRoutingConfirmModalConfirmText,
      message: Slugs.ticketRoutingConfirmModalBody,
      title: Slugs.ticketRoutingConfirmModalTitle,
    },
  })

  // effect handler for showing a confirmation modal anytime the config is updated
  React.useEffect(() => {
    const isNewValidRequestConfig =
      confirmModalConfig?.id &&
      confirmModalConfig.id !== prevConfirmModalConfig?.id

    if (isNewValidRequestConfig) {
      showConfirmModal()
    }
  }, [confirmModalConfig, prevConfirmModalConfig, showConfirmModal])

  const isOnline = useOnlineStatus().isOnline()

  return (
    <>
      <div className={styles.ticketRoutingPage}>
        <div className={styles.leadText}>{t(Slugs.ticketRoutingLead)}</div>
        <Alert alertType="info">{t(Slugs.ticketRoutingCredentialsInfo)}</Alert>
        <div className={styles.tierOneContainer}>
          {isLoading ? (
            <Loader />
          ) : (
            Object.entries(dispositions).map(([key, tierTwos]) => {
              return (
                <div className={styles.tierOneRow} key={key}>
                  <h4 className={styles.tierOneTitle}>
                    {key} {t(Slugs.tickets)}
                  </h4>
                  <div className={styles.tierTwoContainer}>
                    {tierTwos.map(disposition => {
                      return (
                        <TicketRoutingCard
                          assignment={assignments[disposition.id].assignment}
                          className={styles.dispositionCard}
                          disabled={!!loadingId || !isOnline}
                          disposition={disposition}
                          getUsersSearchResults={getUsersSearchResults}
                          handleAssignmentSave={handleAssignmentSave}
                          handleSearchUsers={searchUsers}
                          isLoading={loadingId === disposition.id}
                          key={disposition.id}
                          setConfirmModalConfig={setConfirmModalConfig}
                        />
                      )
                    })}
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>
    </>
  )
}

export default React.memo(TicketRoutingPage)
