import get from 'lodash/get' // eslint-disable-line

import {
  RelationshipUpdate,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { HksApiResponse, HksServiceResponse } from './housekeepers.types'
import { emptyHousekeepersResponse } from './housekeepers.utils'

const URL = '/housekeepers'

export const housekeepersService = {
  async addRelationships(
    requestData: RelationshipUpdate,
    relationshipType: string,
    relationshipName?: string,
  ): HksServiceResponse {
    const ownerId = requestData.ownerId
    const payload = requestData.relationshipIds.map(rId => ({
      id: rId,
      type: relationshipType,
    }))
    const relationshipPath = relationshipName || relationshipType

    const response: HksApiResponse = await api.post(
      `${URL}/${ownerId}/relationships/${relationshipPath}s`,
      { data: payload },
    )
    return get(response, 'data', emptyHousekeepersResponse)
  },

  async fetchHousekeeperById(
    id: string,
    params: RequestOptions = {},
  ): HksServiceResponse {
    const response: HksApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return get(response, 'data', emptyHousekeepersResponse)
  },

  async fetchHousekeepers(params: RequestOptions = {}): HksServiceResponse {
    const response: HksApiResponse = await api.get(URL, {
      params,
    })
    return get(response, 'data', emptyHousekeepersResponse)
  },

  async removeRelationships(
    requestData: RelationshipUpdate,
    relationshipType: string,
    relationshipName?: string,
  ): HksServiceResponse {
    const ownerId = requestData.ownerId
    const payload = requestData.relationshipIds.map(rId => ({
      id: rId,
      type: relationshipType,
    }))
    const relationshipPath = relationshipName || relationshipType

    const response: HksApiResponse = await api.delete(
      `${URL}/${ownerId}/relationships/${relationshipPath}s`,
      { data: { data: payload } },
    )
    return get(response, 'data', emptyHousekeepersResponse)
  },
}
