import React from 'react'

import { Tooltip, TooltipPosition } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'

import { useI18n, Slugs } from 'app/hkhub/i18n'
import { TicketSeverity, TicketStatus } from 'app/hkhub/store/tickets'
import {
  ComputedTicketStatus,
  ticketStatusToComputedMap,
} from 'app/hkhub/utils/ticketHelpers'

import { CardSize } from '../../../../helpers/scheduleMeasure'
import { useTranslateTickets } from '../../../../hooks/useTranslateTickets'
import * as St from './TicketCardHeader.styles'

const SeverityHeaderContent: React.FC = ({ children }) => {
  return (
    <St.SeverityTextContainer>
      <SvgIcon centerItems={true} icon={IconName.tag} />
      <St.SeverityText>{children}</St.SeverityText>
    </St.SeverityTextContainer>
  )
}

const useTranslations = status => {
  const { t } = useI18n()

  const statusToTextMap: Record<ComputedTicketStatus, string> = {
    assigned: t(Slugs.assigned),
    awaiting: t(Slugs.awaiting),
    cancelled: t(Slugs.cancelled),
    completed: t(Slugs.completed),
    inProgress: t(Slugs.inProgress),
    pending: t(Slugs.pending),
    unassigned: t(Slugs.unassigned),
  }

  return {
    statusText: statusToTextMap[status],
    toolTipText: t(Slugs.overdueTooltipText),
  }
}

export type TicketCardHeaderProps = {
  isOverdue?: boolean
  severity: TicketSeverity
  size: Exclude<CardSize, 'tiny'>
  status: TicketStatus
}

export const TicketCardHeader: React.FC<TicketCardHeaderProps> = React.memo(
  ({ size, isOverdue, severity, status }) => {
    const { translateSeverity } = useTranslateTickets()

    const computedTicketStatus = ticketStatusToComputedMap[status]

    const strings = useTranslations(computedTicketStatus)

    if (computedTicketStatus === 'completed') {
      return (
        <St.TicketHeader>
          <St.CompletedStatusBar>
            <SeverityHeaderContent>{strings.statusText}</SeverityHeaderContent>
          </St.CompletedStatusBar>
        </St.TicketHeader>
      )
    }

    if (computedTicketStatus === 'cancelled') {
      return (
        <St.TicketHeader>
          <St.CancelledStatusBar>
            <SeverityHeaderContent>{strings.statusText}</SeverityHeaderContent>
          </St.CancelledStatusBar>
        </St.TicketHeader>
      )
    }

    return (
      <St.TicketHeader>
        <St.TicketSeverityBar size={size} severity={severity}>
          <SeverityHeaderContent>
            {translateSeverity(severity)}
          </SeverityHeaderContent>
          {isOverdue && (
            <Tooltip
              message={strings.toolTipText}
              position={TooltipPosition.Top}
            >
              <St.OverdueIcon
                centerItems={true}
                icon={IconName.alertTriangle}
                size={12}
              />
            </Tooltip>
          )}
        </St.TicketSeverityBar>

        {severity !== 'Planned' && (
          <St.TicketStatusBar size={size} severity={severity}>
            <St.StatusText>{strings.statusText}</St.StatusText>
          </St.TicketStatusBar>
        )}
      </St.TicketHeader>
    )
  },
)
