import styled from '@emotion/styled'
import { noop } from 'lodash/fp'
import React from 'react'

import { useI18n, Slugs } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors } from 'packages/styles'

import { Loader } from '../Loader'

type ThumbnailState = 'noPhoto' | 'loading' | 'error' | 'loaded'

type WrapperProps = Pick<ImageThumbnailProps, 'size' | 'thumbnailUri'> & {
  hasError?: boolean
  thumbnailState: ThumbnailState
}

const St = {
  /*
   * This odd-looking combination of styles ensures that the downloaded photo
   * will center and proportionally scale itself to fit within the <img> element in a lovely way.
   * - max-width/height of 100% ensures the <img> element itself fills the parent
   * - hard-coded height/width + 'cover' property ensures the actual photo is scaled and centered.
   */
  Img: styled.img<{ size: number }>`
    ${centerWithFlex};
    border-radius: 12px;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;

    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
  `,

  Overlay: styled.div`
    background: white;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity 100ms ease;
    width: 100%;

    &:hover {
      opacity: 0.3;
    }
  `,

  Placeholder: styled.div`
    ${centerWithFlex};
    flex-direction: column;
    text-align: center;
  `,

  Wrapper: styled.div<WrapperProps>`
    ${centerWithFlex};
    border-radius: 12px;
    font-size: 13px;
    position: relative;
    user-select: none;

    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};

    background-color: ${({ hasError }) =>
      hasError ? colors.alert10 : colors.midnight10};

    border: ${({ thumbnailState }) =>
      thumbnailState === 'loaded' ? '1px solid' : '1px dashed'};

    border-color: ${({ hasError }) =>
      hasError ? colors.alert : colors.midnight60};

    color: ${({ hasError }) => (hasError ? colors.alert : colors.midnight60)};

    cursor: ${({ thumbnailState }) =>
      thumbnailState === 'loaded' ? 'pointer' : 'default'};

    pointer-events: ${({ thumbnailState }) =>
      thumbnailState === 'loaded' ? 'all' : 'none'};
  `,
}

export enum ImageThumbnailTestIds {
  placeholder = 'ImageThumbnail__placeholder',
}

export type ImageThumbnailProps = {
  /** Text value to use for the native HTML 'alt' attr on <img> */
  altText: string
  disableStatefulUi?: boolean
  /** Optional text to show when no image is present */
  fallbackText?: string
  onClick?: React.MouseEventHandler<HTMLInputElement>
  size: number
  /** Source for an existing image to display; if this is provided, no placeholder text/icons will render. */
  thumbnailUri: string | null
}

export const ImageThumbnail: React.FC<ImageThumbnailProps> = React.memo(
  ({
    altText,
    disableStatefulUi = false,
    fallbackText,
    onClick = noop,
    size,
    thumbnailUri,
  }) => {
    const [thumbnailState, setThumbnailState] = React.useState<ThumbnailState>(
      thumbnailUri ? 'loading' : 'noPhoto',
    )

    const { t } = useI18n()

    const hasError = thumbnailState === 'error'

    // if the URI gets cleared, the photo has probably been deleted, so we need to clear the thumbnail
    React.useEffect(() => {
      if (!thumbnailUri) {
        setThumbnailState('noPhoto')
      }
    }, [thumbnailUri])

    return (
      <St.Wrapper
        hasError={hasError}
        onClick={onClick}
        size={size}
        thumbnailState={thumbnailState}
        thumbnailUri={thumbnailUri}
      >
        {!!thumbnailUri && !hasError ? (
          <St.Img
            alt={altText}
            onError={() => setThumbnailState('error')}
            onLoad={() => setThumbnailState('loaded')}
            size={size}
            src={thumbnailUri}
          />
        ) : (
          <St.Placeholder data-testid={ImageThumbnailTestIds.placeholder}>
            <SvgIcon icon={IconName.camera} size={18} />
            <span>{hasError ? t(Slugs.invalidImage) : fallbackText}</span>
          </St.Placeholder>
        )}

        {!disableStatefulUi && (
          <>
            {thumbnailState === 'loaded' && <St.Overlay />}
            {thumbnailState === 'loading' && <Loader />}
          </>
        )}
      </St.Wrapper>
    )
  },
)
