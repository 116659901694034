import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ReservationApiFields } from 'app/hkhub/store/reservations'
import { TaskPhotoApiFields } from 'app/hkhub/store/taskPhotos'

import { tasksService } from '../tasks.service'
import {
  NormalizedTasksApiResponse,
  TaskApiFields,
  TasksActionTypes,
} from '../tasks.types'

export const fetchTaskByIdAction = createAsyncAction(
  TasksActionTypes.FETCH_TASK,
  TasksActionTypes.FETCH_TASK_SUCCESS,
  TasksActionTypes.FETCH_TASK_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    housekeeper: [],
    reservation: ReservationApiFields,
    task: TaskApiFields,
    task_photo: TaskPhotoApiFields,
    unit: [],
  },
  include: [
    'assigned_housekeepers',
    'task_photos',
    'reservation',
    'reservation.unit',
    'unit',
  ],
})

export const fetchTaskById = (taskId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = tasksService.fetchTaskById.bind(null, taskId, params)
    const result = await dispatch(fetchTaskByIdAction.request({ request }))
    dispatch(fetchTaskByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchTaskByIdAction.failure(error))
    throw error
  }
}
