import { AxiosResponse } from 'axios'
import snakeCase from 'lodash/snakeCase'

import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum HkAvailabilitiesActionTypes {
  FETCH_HK_AVAILABILITIES = 'HK_AVAILABILITIES/API/FETCH_HK_AVAILABILITIES',
  FETCH_HK_AVAILABILITIES_FAILURE = 'HK_AVAILABILITIES/FETCH_HK_AVAILABILITIES_FAILURE',
  FETCH_HK_AVAILABILITIES_SUCCESS = 'HK_AVAILABILITIES/FETCH_HK_AVAILABILITIES_SUCCESS',
}

export type HkAvailabilitiesState = {
  data: JSONApiHkAvailabilityMap
  error?: Error
}

export type HkAvailabilitiesMap = {
  [hkId: string]: {
    [dateKey: string]: HkAvailability
  }
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
type HkAvailabilitiesResponse = {
  effectiveAvailability: JSONApiHkAvailabilityMap
}

export type NormalizedHkAvailabilitiesApiResponse =
  NormalizedJSONApiResponse<HkAvailabilitiesResponse>

export type HkAvailabilitiesServiceResponse =
  Promise<NormalizedHkAvailabilitiesApiResponse>

export type HkAvailabilitiesApiResponse =
  AxiosResponse<NormalizedHkAvailabilitiesApiResponse>

/**********************************************************
 * HK EFFECTIVE AVAILABILITY
 *********************************************************/
export type HkAvailability = {
  id: string
} & HkAvailabilityAttributes

export type HkAvailabilityAttributes = {
  b2b: boolean
  date: string
  lateCheckout: boolean
  standard: boolean
}

export const HkAvailabilityAttributeNames = [
  'b2b',
  'date',
  'lateCheckout',
  'standard',
]

export const HkAvailabilityApiFields = HkAvailabilityAttributeNames.map<string>(
  str => {
    // snakeCase() breaks on 'b2b', so we will manually convert this one
    if (str === 'b2b') {
      return str
    }

    return snakeCase(str)
  },
)

export type HkAvailabilityRelationships = {
  housekeeper?: ToOneRelationship
}

export type RawHkAvailability = JSONApiObjectWithRelationships<
  HkAvailabilityAttributes,
  HkAvailabilityRelationships
>

export type JSONApiHkAvailabilityMap = JSONApiObjectWithRelationshipsMap<
  HkAvailabilityAttributes,
  HkAvailabilityRelationships
>
