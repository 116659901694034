import { JwtPayload, jwtDecode } from 'jwt-decode'

import { createDateObject } from 'packages/utils/dateHelpers'
import { storage } from 'packages/utils/misc'

export const setFeatureBranchToken = (token: string): void =>
  storage.setItem('featureBranchToken', token)

export const getFeatureBranchToken = (): string | null => {
  return storage.getItem('featureBranchToken')
}

export const isFeatureBranchTokenValid = (): boolean => {
  const token = getFeatureBranchToken()

  // If the token is not present, it is invalid
  if (token === null) {
    return false
  }

  try {
    const decodedToken: JwtPayload = jwtDecode<JwtPayload>(token)
    const currentTime = Math.floor(
      (createDateObject() as unknown as number) / 1000,
    )

    // is expired?
    if (currentTime > (decodedToken.exp ?? 0)) {
      return false
    }

    // is issued in the future?
    if (currentTime < (decodedToken.iat ?? 0)) {
      return false
    }

    // otherwise, it is valid (if other things are corrupted, API will reject it)
    return true
  } catch (error) {
    return false
  }
}
