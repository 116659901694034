import styled from '@emotion/styled'

import { Button } from 'packages/common'
import { SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 28px;
`
export const ConfirmDeleteButton = styled(Button)`
  ${text.bodyRegularDefault}
  color: ${colors.alert};
  margin-right: 32px;
`
export const CustomChecklistItemHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`
export const EditButton = styled(Button)`
  margin-right: 32px;
`
export const ItemTitle = styled.div`
  ${text.bodyRegularDefault}
  color: ${colors.dusk};
  margin-left: 32px;
`
export const KeepEditingButton = styled(Button)`
  ${text.bodyRegularDefault}
  color: ${colors.midnight};
`
export const LoaderContainer = styled.div`
  margin-right: 28px;
  height: 20px;
  position: relative;
  width: 20px;
`
export const DeleteConfirmationButtons = styled.div``
export const Subtitle = styled.div`
  color: ${colors.dusk70};
  display: flex;
  font-weight: 400;
  justify-content: flex-end;
  margin: 4px 28px 12px 0;
`
export const TextArea = styled.textarea`
  border: 1px solid ${colors.midnight20};
  border-radius: 4px;
  flex-grow: 1;
  font-size: 16px;
  margin-left: 24px;
  margin-top: 12px;
  padding: 8px 16px;
  width: 90%;
`
export const TrashIcon = styled(SvgIcon)`
  color: red;
  margin-left: 26px;
  margin-right: 28px;
`
