import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { track } from 'packages/wiretap'
import { SegmentEvents } from 'packages/wiretap/src/tracking/events'

import { fetchReservationById } from 'app/hkhub/store/reservations/actions/fetchReservationById'
import { getReservation } from 'app/hkhub/store/reservations/selectors'
import { AppDispatch, ApplicationState } from 'app/hkhub/store/store'
import { fetchTicketById } from 'app/hkhub/store/tickets/actions/fetchTicketById'
import { setTicketDrawerState } from 'app/hkhub/store/ui/actions/setTicketDrawerState'
import { getTicketDrawerState } from 'app/hkhub/store/ui/selectors/getTicketDrawerState'

import { useTicket } from '../../VisitTicketCard/hooks/useTicket'
import { TicketDrawer } from './TicketDrawer'
import {
  TicketDrawerContext,
  TicketDrawerContextType,
} from './TicketDrawer.context'

export const TicketDrawerContainer: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const { isOpen, ticketId } = useSelector(getTicketDrawerState)

  const ticketDetails = useTicket(ticketId)

  const afterExit = React.useCallback(() => {
    dispatch(
      setTicketDrawerState({
        isOpen: false,
        ticketId: '',
      }),
    )
  }, [dispatch])

  const handleAfterExit = React.useCallback(() => {
    setState(prev => ({
      ...prev,
      forceCloseDrawer: false,
      hasFetchedNextReservation: false,
    }))

    afterExit()
  }, [afterExit])

  const [fetchTicketState, fetchTicketFn] = useAsyncFnWithReset(
    async (ticketId: string) => dispatch(fetchTicketById(ticketId)),
    [dispatch],
  )

  const [fetchReservationState, fetchReservationFn] = useAsyncFnWithReset(
    async (reservationId: string) =>
      dispatch(fetchReservationById(reservationId)),
    [dispatch],
  )

  const [state, setState] = React.useState({
    forceCloseDrawer: false,
  })

  const contextValue: TicketDrawerContextType = React.useMemo(
    () => ({
      fetchTicketState,
    }),
    [fetchTicketState],
  )

  const handleForceCloseDrawer = React.useCallback(() => {
    setState(prev => ({
      ...prev,
      forceCloseDrawer: true,
    }))
  }, [])

  React.useEffect(() => {
    if (isOpen && ticketId) {
      fetchTicketFn(ticketId)
    }
  }, [fetchTicketFn, isOpen, ticketId])

  React.useEffect(() => {
    if (!isOpen || !ticketId) return
    track(SegmentEvents.hubTicketDrawerOpen)
  }, [isOpen, ticketId])

  // if we have a ticket ID but no ticket is found, we are here by mistake; close the drawer
  React.useEffect(() => {
    if (ticketId && !ticketDetails.ticket) {
      afterExit()
    }
  }, [afterExit, ticketDetails.ticket, ticketId])

  const nextReservation = useSelector((state: ApplicationState) =>
    ticketDetails.unit?.nextReservationId
      ? getReservation(
          state.reservations,
          ticketDetails.unit?.nextReservationId,
        )
      : undefined,
  )

  const currentReservation = useSelector((state: ApplicationState) =>
    ticketDetails.unit?.currentReservationId
      ? getReservation(
          state.reservations,
          ticketDetails.unit.currentReservationId,
        )
      : undefined,
  )

  const shouldFetchReservation =
    isOpen &&
    ticketDetails &&
    !nextReservation &&
    !fetchReservationState.loading

  React.useEffect(() => {
    if (shouldFetchReservation && ticketDetails.unit?.nextReservationId) {
      fetchReservationFn(ticketDetails.unit.nextReservationId)
    }
  }, [
    fetchReservationFn,
    shouldFetchReservation,
    ticketDetails.unit?.nextReservationId,
  ])

  return (
    <TicketDrawerContext.Provider value={contextValue}>
      <TicketDrawer
        afterExit={handleAfterExit}
        currentReservation={currentReservation}
        forceCloseDrawer={state.forceCloseDrawer}
        handleForceCloseDrawer={handleForceCloseDrawer}
        isFetchingReservation={fetchReservationState.loading}
        isFetchingTicket={fetchTicketState.loading}
        isOpen={isOpen}
        nextReservation={nextReservation}
        ticketDetails={ticketDetails}
      />
    </TicketDrawerContext.Provider>
  )
}
