import React from 'react'
import { createPortal } from 'react-dom'

import { TransitionPhaseType } from 'packages/utils/hooks'

import { CloseButton, ModalBackdrop } from '../components'
import { BaseModalProps, ModalTestIds } from './Modal'
import { StyledModal } from './Modal.styles'

export type ModalPortalProps = BaseModalProps & {
  beginClose: () => void
  onBackdropClick: () => void
  renderContainer: Element | undefined
  transitionPhase: TransitionPhaseType
}

export type ModalChildrenProps = Pick<ModalPortalProps, 'beginClose'>

export const ModalPortal = React.forwardRef<
  HTMLDivElement,
  Required<ModalPortalProps>
>((props, ref) => {
  const {
    beginClose,
    childRenderer,
    duration,
    hideBackdrop,
    hideCloseButton,
    isOpen,
    renderContainer,
    transitionPhase,
  } = props

  const childrenProps: ModalChildrenProps = {
    beginClose,
  }

  const mutableRef = ref as React.MutableRefObject<HTMLDivElement>
  const elementWidth = mutableRef?.current?.offsetWidth
  const elementHeight = mutableRef?.current?.offsetHeight

  return isOpen
    ? createPortal(
        <>
          <StyledModal
            data-testid={ModalTestIds.container}
            duration={duration}
            elementHeight={elementHeight}
            elementWidth={elementWidth}
            ref={ref}
            transitionPhase={transitionPhase}
          >
            {!hideCloseButton && <CloseButton onClick={beginClose} />}
            {childRenderer(childrenProps)}
          </StyledModal>
          {!hideBackdrop && <ModalBackdrop {...props} />}
        </>,
        renderContainer || document.body,
      )
    : null
})
