import classNames from 'classnames'
import * as React from 'react'

import { SvgIcon, IconName } from 'packages/iconic'

import { useI18n } from 'app/hkhub/i18n'
import {
  UnitWithHousekeeperPreferences,
  UnitPreferenceKind,
  UnitPreference,
} from 'app/hkhub/store/units/units.types'

import { getSlugForUnitPreferenceKind } from '../../helpers/unitPreferenceHelpers'

import styles from './UnitPreferenceCell.module.scss'

export type UnitPreferenceCellProps = {
  alias: UnitPreferenceKind
  editing: boolean
  openAddPreferenceModal: (
    unit: UnitWithHousekeeperPreferences,
    alias: UnitPreferenceKind,
  ) => void
  openRemovePreferenceModal: (
    preference: UnitPreference,
    unitId: string,
  ) => void

  unit: UnitWithHousekeeperPreferences
}

const UnitPreferencesCell: React.FC<UnitPreferenceCellProps> = ({
  alias,
  editing,
  openAddPreferenceModal,
  openRemovePreferenceModal,
  unit,
}) => {
  const { t } = useI18n()

  const hkNodes = React.useMemo(
    () =>
      unit.housekeeperPreferences[alias]
        .filter(({ housekeeper }) => housekeeper)
        .map(preference => {
          const { housekeeper, id } = preference
          return (
            <div
              key={id}
              className={classNames(styles.preference, {
                [styles.editingPreference]: editing,
              })}
            >
              <div className={styles.preferenceContent}>
                {housekeeper.firstName} {housekeeper.lastName}
              </div>
              {editing ? (
                <div
                  className={styles.removePreference}
                  onClick={() => openRemovePreferenceModal(preference, unit.id)}
                >
                  <SvgIcon centerItems={true} icon={IconName.x} size={16} />
                </div>
              ) : null}
            </div>
          )
        }),
    [alias, editing, openRemovePreferenceModal, unit],
  )

  const emptyOrNotMandatory = () =>
    hkNodes.length === 0 || alias !== UnitPreferenceKind.mandatory

  return (
    <>
      {hkNodes}
      {editing && emptyOrNotMandatory() ? (
        <div
          className={styles.addPreference}
          onClick={() => openAddPreferenceModal(unit, alias)}
        >
          + {t(getSlugForUnitPreferenceKind(alias))}
        </div>
      ) : null}
    </>
  )
}

export default React.memo(UnitPreferencesCell)
