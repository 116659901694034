import get from 'lodash/get' // eslint-disable-line

import { ReservationsState, Reservation } from '../reservations.types'
import { transformNormalizedToReservation } from '../utils/transformNormalizedToReservation'

/**
 * Reads a reservation from the reservations store by id and "flattens" it down removing the attributes property
 * @param state
 * @param hash
 */
export const getReservation = (
  state: ReservationsState,
  hash: string,
): Reservation | undefined => {
  const raw = get(state.data, hash)
  return raw ? transformNormalizedToReservation(raw) : undefined
}
