/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import get from 'lodash/get' // eslint-disable-line

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import {
  UnitsState,
  UnitPreference,
  UnitPreferenceKind,
  UnitPreferenceAttributeNames,
} from '../units.types'

/**
 * Returns the Housekeeper Preferences for a Unit by preference Id
 * Should probably not be called directly
 *
 * @param state
 * @param id the housekeeper preference id
 */
export const getUnitHousekeeperPreferenceById = (
  state: UnitsState,
  id: string,
) => {
  const raw = get(state.housekeeperPreferences, id)
  if (!raw) {
    return undefined
  }

  const housekeeperId = raw.relationships.housekeeper.data.id
  const preference = transformNormalizedToTyped<UnitPreference>(
    raw,
    UnitPreferenceAttributeNames,
  )
  const aliasName = preference.alias.toLowerCase()
  const alias = UnitPreferenceKind[aliasName]

  return {
    alias,
    effectiveDate: preference.effectiveDate,
    housekeeper: housekeeperId,
    id,
  }
}
