import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import {
  colors,
  text,
  truncateTextWithEllipsis,
  WithBreakpoint,
} from 'packages/styles'

import { ScheduleSizes } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

import {
  ScheduleRowHeaderEndText,
  ScheduleRowHeaderText,
  ScheduleRowHeaderTitle,
} from '../../../../schedule.styles'

const deepCleanAlertColorMap = {
  danger: {
    background: colors.alert10,
    border: colors.alert70,
  },
  warning: {
    background: colors.awareness10,
    border: colors.awareness70,
  },
}

const alignCentered = css`
  display: flex;
  align-items: center;
`

const gridLayoutDesktop = `'code overdue name info dcAlert onCallManager status'`
const gridLayoutDesktopNoOverdue = `'code name info dcAlert onCallManager status'`

const gridLayoutMobile = `'code info dcAlert onCallManager overdue . status'
'name name name name name . status'`
const gridLayoutMobileNoOverdue = `'code info dcAlert onCallManager . status'
'name name name name . status'`

const largeAndSmall = (lg: string, sm: string) => (props: WithBreakpoint) =>
  props.breakpoint === 'LG' ? lg : sm

type ScheduleRowHeaderProps = WithBreakpoint & { overdueTicketCount: number }

export const St = {
  DeepCleanAlert: styled.div<{ type: 'warning' | 'danger' } & WithBreakpoint>`
    border-radius: 20px;
    color: ${colors.dusk};
    font-weight: 700;

    background-color: ${({ type }) => deepCleanAlertColorMap[type].background};
    border: 2px solid ${({ type }) => deepCleanAlertColorMap[type].border};
    padding: ${largeAndSmall('4px 6px', '1px')};

    /* 21px is the height of the top grid row on mobile. Auto-sizing the height and width as the
    same value within the tight confines of the grid is challenging, so we manually set height/width to 21px.
    32px on desktop allows the alert to have some nice spacing without cramping the internals of the alert.
    */
    height: ${largeAndSmall('32px', '21px')};
    width: ${largeAndSmall('fit-content', '21px')};
  `,

  DeepCleanAlertContainer: styled.div<WithBreakpoint>`
    ${alignCentered};
    grid-area: dcAlert;
  `,

  OnCallManagerContainer: styled.div<WithBreakpoint>`
    ${alignCentered};
    ${text.bodyRegularTiny};
    align-items: center;
    display: ${largeAndSmall('flex', 'none')};
    gap: 8px;
    grid-area: onCallManager;
  `,

  OnCallManagerIcon: styled(SvgIcon)``,

  OverdueIcon: styled(SvgIcon)`
    background: white;
    border-radius: 36px;
    border: 1px solid ${colors.dusk};
    color: ${colors.alert};
    grid-area: overdue;
    padding: 2px 16px;
  `,

  ScheduleRowHeader: styled.div<ScheduleRowHeaderProps>`
    align-items: center;
    background: white;
    box-shadow: 0px -1px 0px ${colors.midnight20},
      0px 2px 0px ${colors.midnight20};
    cursor: pointer;
    display: grid;
    grid-column-gap: 16px;
    height: ${ScheduleSizes.RowHeaderHeight}px;
    position: relative;
    z-index: 1;

    /* this effectively has each element take up as little room as it can without wrapping text,
    while giving the "spacer" all extra space, pushing the last element to the far right */
    grid-template-columns:
      minmax(0, max-content) minmax(0, max-content) minmax(0, max-content)
      minmax(0, max-content) minmax(0, max-content);
    grid-template-areas: ${({ overdueTicketCount }) =>
      overdueTicketCount === 0
        ? largeAndSmall(gridLayoutDesktopNoOverdue, gridLayoutMobileNoOverdue)
        : largeAndSmall(gridLayoutDesktop, gridLayoutMobile)};
    padding: ${largeAndSmall('0 16px', '0 4px')};
  `,

  ScheduleRowHeaderStatus: styled(ScheduleRowHeaderEndText)`
    ${alignCentered};
    font-weight: bold;
    grid-area: status;
    padding: 0;
  `,

  ScheduleRowHeaderUnitCode: styled(ScheduleRowHeaderTitle)`
    ${alignCentered};
    grid-area: code;
    padding: 0;
  `,

  ScheduleRowHeaderUnitInfo: styled.div`
    ${alignCentered};
    grid-area: info;
    padding: 0;
  `,

  ScheduleRowHeaderUnitName: styled(ScheduleRowHeaderText)`
    ${alignCentered};
    ${truncateTextWithEllipsis};
    grid-area: name;
    padding: 0;
  `,
}
