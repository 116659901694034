import styled from '@emotion/styled'

import { colors } from 'packages/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4px;
`

export const ReservationCheckOutTime = styled.span<{
  isLateCheckOut: boolean
  isOverridden: boolean
}>`
  font-weight: ${({ isLateCheckOut }) => (isLateCheckOut ? 'bold' : 'normal')};

  text-decoration: ${({ isOverridden }) =>
    isOverridden ? 'line-through' : 'none'};
`

export const GuestCheckOutTime = styled.span`
  border-left: 2px solid ${colors.yellow};

  padding-left: 8px;
`
export const EarlyText = styled.span`
  font-weight: bold;
  padding-right: 8px;
`
