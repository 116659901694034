import { deprecated } from 'typesafe-actions'

import { AuthActionTypes } from '../auth.types'

const { createStandardAction } = deprecated

export const clearImpersonationTokenAction = createStandardAction(
  AuthActionTypes.CLEAR_IMPERSONATION_TOKEN,
)<void>()

export const clearImpersonationToken = () => dispatch => {
  dispatch(clearImpersonationTokenAction())
}
