import { intersection, mergeWith } from 'lodash/fp'

import { Breakpoint } from 'packages/styles'

import {
  DateCleanBuckets,
  HkDateCleanBuckets,
  UnitCleanSchedule,
} from 'app/hkhub/store/cleans'
import { HkDateCoverageBuckets } from 'app/hkhub/store/coverage/coverage.types'
import { HkAvailabilitiesMap } from 'app/hkhub/store/hkAvailabilities/hkAvailabilities.types'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { DateTicketBuckets, UnitTicketSchedule } from 'app/hkhub/store/tickets'
import {
  DateVisitBuckets,
  HkDateVisitBuckets,
  UnitDateVisitBuckets,
} from 'app/hkhub/store/visits'

import { hasFullDayCovered } from '../../components/staff/staffSchedule.utils'
import { cardNotTiny } from './cardMeasure.utils'
import {
  CARD_MARGIN_Y,
  CardSize,
  ScheduleSizes,
  staffCardSizeHeightMap,
  unitCardSizeHeightMap,
} from './scheduleSizes'

const concatIfExists = (objValue, srcValue) => (objValue || []).concat(srcValue)

/**
 * Finds the max number of events (cleans or tickets) in any one of the provided buckets.
 * Returns a minimum of "1" to ensure we do not completely collapse any rows when rendering.
 * @param buckets
 */
const findMaxEvents = (
  buckets: DateCleanBuckets | DateTicketBuckets | DateVisitBuckets,
): number =>
  Object.values(buckets).reduce(
    (acc, buckets) => Math.max(acc, buckets.length),
    1,
  )

//------------------------------------------------
// Staff Schedule view measurements
//------------------------------------------------

/**
 * Calculates the total desired height of the virtualized staff schedule component.
 * This is effectively "everything below nav/title bar to the bottom of the page"
 * @param breakpoint
 */
export const getStaffScheduleHeight = (breakpoint: Breakpoint): number => {
  const titlebar = ScheduleSizes.TitlebarHeight[breakpoint]
  const scheduleControls = ScheduleSizes.ScheduleControls[breakpoint]
  const timeline = ScheduleSizes.TimelineHeight[breakpoint]
  const pageHeight = window.innerHeight

  return pageHeight - titlebar - scheduleControls - timeline - 1
}

export const getStaffCellMinHeight = (cardSize: CardSize): number => {
  return staffCardSizeHeightMap[cardSize] + CARD_MARGIN_Y * 2
}

export const measureStaffScheduleRow =
  (
    breakpoint: Breakpoint,
    hkAvailabilityBuckets: HkAvailabilitiesMap,
    allHks: Housekeeper[],
    hkDateCleanBuckets: HkDateCleanBuckets,
    hkDateCoverageBuckets: HkDateCoverageBuckets,
    cardSize: CardSize,
    isLoading: boolean,
    hkDateVisitBuckets: HkDateVisitBuckets,
  ) =>
  (idx: number): number => {
    const hk = allHks[idx]
    const hkId = hk.id
    const cleanBuckets = hkDateCleanBuckets[hkId] || {}
    const visitBuckets = hkDateVisitBuckets[hkId] || {}
    const availabilityBuckets = hkAvailabilityBuckets[hkId] || {}
    const coverageBuckets = hkDateCoverageBuckets[hk.user.id] || {}

    const combinedBuckets = mergeWith(
      concatIfExists,
      cleanBuckets,
      visitBuckets,
    )

    const hasUnavailableDayWithCleanOrVisit = (() => {
      const intersectionOfAvailabilityAndCleanVisits = intersection(
        Object.keys(availabilityBuckets),
        Object.keys(combinedBuckets),
      )

      return (
        intersectionOfAvailabilityAndCleanVisits.length !==
        Object.keys(combinedBuckets).length
      )
    })()

    const isNotWorkingCoverageBuckets = (() =>
      Object.keys(coverageBuckets).reduce((acc, date) => {
        const isNotWorkingCoverageEntry =
          coverageBuckets[date].find(hasFullDayCovered)

        if (isNotWorkingCoverageEntry) {
          acc[date] = isNotWorkingCoverageEntry
        }

        return acc
      }, {}))()

    const hasNoTicketsDayWithCleanOrVisit = (() => {
      const intersectionOfCoverageAndCleanVisits = intersection(
        Object.keys(isNotWorkingCoverageBuckets),
        Object.keys(combinedBuckets),
      )

      return (
        intersectionOfCoverageAndCleanVisits.length !==
        Object.keys(combinedBuckets).length
      )
    })()

    const hasNoTicketsDayWithUnavailable = (() => {
      const intersectionOfCoverageAndAvailability = intersection(
        Object.keys(availabilityBuckets),
        Object.keys(isNotWorkingCoverageBuckets),
      )

      return (
        intersectionOfCoverageAndAvailability.length !==
        Object.keys(isNotWorkingCoverageBuckets).length
      )
    })()

    const maxCleansPerDay = findMaxEvents(combinedBuckets)
    // If the schedule is loading, set the row height to one card to fit the loader
    const maxCleans = isLoading ? 1 : Math.max(maxCleansPerDay, 1)

    const cardsHeight = maxCleans * staffCardSizeHeightMap[cardSize]
    const marginsHeight = (maxCleans + 1) * ScheduleSizes.CardMarginTop
    const offIconHeight = hasUnavailableDayWithCleanOrVisit
      ? ScheduleSizes.OffIconHeight[breakpoint]
      : 0
    const noTicketsIconHeight = hasNoTicketsDayWithCleanOrVisit
      ? ScheduleSizes.OffIconHeight[breakpoint]
      : 0
    const noTicketsAndOffIconHeight = hasNoTicketsDayWithUnavailable
      ? ScheduleSizes.OffIconHeight[breakpoint]
      : 0

    return (
      ScheduleSizes.RowHeaderHeight +
      offIconHeight +
      noTicketsIconHeight +
      noTicketsAndOffIconHeight +
      cardsHeight +
      marginsHeight
    )
  }

//------------------------------------------------
// Unit Schedule view measurements
//------------------------------------------------

/**
 * Calculates the total desired height of the virtualized unit schedule component.
 * This is effectively "everything below nav/title bar to the bottom of the page"
 * @param breakpoint
 */
export const getUnitScheduleHeight = (breakpoint: Breakpoint): number => {
  const titlebar = ScheduleSizes.TitlebarHeight[breakpoint]
  const scheduleControls = ScheduleSizes.ScheduleControls[breakpoint]
  const timeline = ScheduleSizes.TimelineHeight[breakpoint]
  const pageHeight = window.innerHeight

  return (
    pageHeight -
    titlebar -
    scheduleControls -
    ScheduleSizes.FilterControlsMinHeight -
    timeline -
    1
  )
}

export const getUnitCellMinHeight = (cardSize: CardSize): number => {
  return (
    unitCardSizeHeightMap[cardSize] +
    CARD_MARGIN_Y * 2 +
    (cardNotTiny(cardSize) ? ScheduleSizes.ReservationsGutterHeight : 0)
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mergeBuckets = (
  ...buckets: (DateCleanBuckets | DateTicketBuckets | DateVisitBuckets)[]
): Record<string, any[]> => {
  const result: Record<string, any[]> = {}

  buckets.forEach(bucket => {
    if (bucket) {
      Object.keys(bucket).forEach(date => {
        if (result[date]) {
          result[date] = result[date].concat(bucket[date])
        } else {
          result[date] = bucket[date]
        }
      })
    }
  })

  return result
}

export const measureUnitScheduleRow =
  (
    unitIds: string[],
    buckets: {
      cleans: UnitCleanSchedule
      tickets: UnitTicketSchedule
      visits: UnitDateVisitBuckets
    },
    cardSize: CardSize,
  ) =>
  (idx: number): number => {
    const unitId = unitIds[idx]
    const cleanBuckets = buckets.cleans[unitId]
    const ticketBuckets = buckets.tickets[unitId]
    const visitBuckets = buckets.visits[unitId]

    const eventBuckets = mergeBuckets(cleanBuckets, ticketBuckets, visitBuckets)
    const maxCleansPerDay = findMaxEvents(eventBuckets)

    const cardsHeight = maxCleansPerDay * unitCardSizeHeightMap[cardSize]
    const marginsHeight = (maxCleansPerDay + 1) * ScheduleSizes.CardMarginTop

    return (
      ScheduleSizes.RowHeaderHeight +
      cardsHeight +
      marginsHeight +
      (cardNotTiny(cardSize) ? ScheduleSizes.ReservationsGutterHeight : 0)
    )
  }
