import styled from '@emotion/styled'
import React from 'react'

import { parseBeds } from 'packages/grimoire/src/unit/unit.helpers'
import { SvgIcon, IconName } from 'packages/iconic'

import { Unit } from 'app/hkhub/store/units/units.types'

const St = {
  UnitIcons: styled.div`
    align-items: center;
    display: flex;
    width: 100%;
  `,
}

export type UnitIconsProps = {
  unit: Unit
}

export const UnitIcons: React.FC<UnitIconsProps> = React.memo(({ unit }) => {
  const bathrooms = `${unit?.bathrooms || '?'}`
  const beds = parseBeds(unit)

  return (
    <St.UnitIcons>
      <SvgIcon centerItems={true} icon={IconName.bed} size={16} text={beds} />
      <SvgIcon
        centerItems={true}
        icon={IconName.bath}
        size={16}
        text={bathrooms}
      />
      {unit.hottub && <SvgIcon centerItems={true} icon={IconName.hotTub} />}
    </St.UnitIcons>
  )
})
