import * as Sentry from '@sentry/react'

import { AnalyticsPayload } from './track'

/**********************************************************
 * Segment
 *********************************************************/

/**
 * Warn us if the segment initialization fails so that we know we're missing data
 */
export const handleMissingSegmentAnalytics = (
  event: string,
  payload?: AnalyticsPayload,
): void => {
  Sentry.getCurrentScope()
    .setLevel('info')
    .setTag('Segment', 'analytics() undefined')
    .setExtra('Event Type', event)

  if (payload) {
    Sentry.getCurrentScope()
      .setExtra('Event Payload', payload)
      .setFingerprint(['Segment undefined'])
  }

  Sentry.captureMessage('Segment undefined')
}

export const handleSegmentUserIsNotAFunction = (): void => {
  Sentry.getCurrentScope()
    .setLevel('info')
    .setTag('Segment', 'analytics() undefined')
    .setFingerprint(['Segment user is not a function'])

  Sentry.captureMessage('Segment analytics.user is not a function')
}

/**********************************************************
 * GTM
 *********************************************************/

/**
 * Reports errors from calling GTM where the gtmTracker _is_ defined
 */
export const handleGTMError = (event: string, error: Error): void => {
  Sentry.getCurrentScope()
    .setLevel('warning')
    .setTag('GTM', 'Event undefined')
    .setExtra('Event Data', event)
    .setExtra('Error', error)
    .setFingerprint(['Event undefined'])

  Sentry.captureMessage("GTM 'event' undefined")
}

/**
 * Warn us if the GTM initialization fails so that we know we're missing data
 */
export const handleMissingGTMAnalytics = (event: string): void => {
  Sentry.getCurrentScope()
    .setLevel('info')
    .setTag('GTM', 'gtmTrack() undefined')
    .setExtra('Event Data', event)
    .setFingerprint(['GTM undefined'])

  Sentry.captureMessage('GTM undefined')
}
