import { mapKeys, noop, snakeCase } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { RequestConfig } from 'packages/utils/store/jsonapi.types'

import { ticketsService } from '../tickets.service'
import {
  NormalizedTicketsApiResponse,
  TicketPatchData,
  TicketActionTypes,
} from '../tickets.types'
import { fetchTicketById } from './fetchTicketById'

export const updateTicketAction = createAsyncAction(
  TicketActionTypes.UPDATE_TICKET,
  TicketActionTypes.UPDATE_TICKET_SUCCESS,
  TicketActionTypes.UPDATE_TICKET_FAILURE,
)<
  RequestConfig<NormalizedTicketsApiResponse>,
  NormalizedTicketsApiResponse,
  Error
>()

export const buildRequestData = (ticketPatchData: TicketPatchData) => {
  const { id, ...attributes } = ticketPatchData

  const ticketAssignee = attributes.assigneeId
    ? {
        id: attributes.assigneeId,
        type: 'user',
      }
    : undefined

  const relationships = ticketAssignee
    ? {
        assignee: { data: ticketAssignee },
      }
    : undefined

  const attributesForApi = mapKeys(snakeCase, attributes)

  return {
    data: {
      attributes: attributesForApi,
      id,
      relationships,
      type: 'ticket',
    },
  }
}

export const updateTicket =
  (patchData: TicketPatchData, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const requestData = buildRequestData(patchData)
    const request = ticketsService.updateTicket.bind(
      null,
      patchData.id,
      requestData,
    )

    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const result = await dispatch(updateTicketAction.request({ request }))

      dispatch(updateTicketAction.success(result))

      // re-fetch the associated ticket to ensure we have the latest version locally
      await dispatch(fetchTicketById(patchData.id))

      onSuccess()

      return result
    } catch (error) {
      dispatch(updateTicketAction.failure(error))
      onError()
      throw error
    }
  }
