import styled from '@emotion/styled'

import { Pill } from 'packages/common'
import { Button } from 'packages/common'
import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { ScheduleSizes } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

export const FilterControlsContainer = styled.div`
  align-items: center;
  background-color: ${colors.midnight};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;
`

export const FilterControllsPillsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-height: ${ScheduleSizes.FilterControlsMinHeight}px;
`

export const FilterControlPill = styled(Pill)`
  background: ${colors.midnight};
  border: white 1px solid;
  color: white;
  padding: 8px 16px;

  &:not(:first-of-type) {
    margin-right: 8px;
  }
`

export const FilterPill = styled(Pill)`
  background: ${colors.midnight40};
  color: white;
  border: white 1px solid;
  margin-right: 8px;
`

export const DrawerButtonIcon = styled(SvgIcon)`
  transform: rotate(90deg);
`

export const DrawerButtonText = styled.span`
  font-weight: 600;
  margin-left: 8px;
`

export const ClearFiltersButton = styled(Button)`
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.7);
  &:focus {
    outline: none;
  }
`
