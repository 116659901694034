import { createSlice } from '@reduxjs/toolkit'

import { fetchUnitByIdStrict } from '../units/actions'
import { CustomInspectionItemsState } from './customInspectionItems.types'

const initialState: CustomInspectionItemsState = {
  data: {},
  error: undefined,
}

export const customInspectionItemsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchUnitByIdStrict.fulfilled, (state, action) => {
      state.data = {}

      const normalized = action.payload?.normalized || {}
      state.data = Object.values(normalized.customInspectionItem || {}).reduce(
        (data, item) => {
          data[item.id] = item
          return data
        },
        {},
      )
    })
  },
  initialState,
  name: 'customInspectionItems',
  reducers: {},
})

export default customInspectionItemsSlice.reducer
