import { createSelector } from 'reselect'

import { HousekeepersState, Housekeeper } from '../housekeepers.types'
import { getHousekeeperById } from './getHousekeeperById'

const getHousekeeperIds = createSelector(
  (state: HousekeepersState) => state.data,
  data => Object.keys(data),
)

/**
 * Returns all housekeepers (cached via reselect) should recompute if state changes
 *
 * @param state
 */
export const getHousekeepers = createSelector(
  //input selectors
  getHousekeeperIds,
  (state: HousekeepersState) => state,

  //output selector
  (housekeeperIds, state): Housekeeper[] =>
    housekeeperIds
      .map(id => getHousekeeperById(state, id))
      .filter((hk): hk is Housekeeper => !!hk),
)
