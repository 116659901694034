import { difference, snakeCase } from 'lodash/fp'

import {
  AllCleanAttributes,
  AllCleanAttributeNames,
  CleanJobType,
  Assignment,
  JSONApiReservationsMap,
  JSONApiAssignmentsMap,
} from 'packages/grimoire'
import {
  JSONApiObjectWithRelationshipsMap,
  ToManyRelationship,
  ToOneRelationship,
  JSONApiObjectWithRelationships,
} from 'packages/utils/store/jsonapi.types'

import { Housekeeper } from '../housekeepers'
import { Reservation, JSONApiReservationMapFromTasks } from '../reservations'
import { JsonApiTaskPhotosMap, TaskPhoto } from '../taskPhotos'
import { JSONApiAssignmentChangeMap } from '../tasks'
import { JSONApiUnitMap, Unit } from '../units'
import { JSONApiUsersMap } from '../users'

export * from 'packages/grimoire/src/clean/clean.types'

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
export type HkDateCleanBuckets = {
  [hkId: string]: DateCleanBuckets
}

export type DateCleanBuckets = {
  [date: string]: Clean[]
}

export type UnitCleanSchedule = {
  [unitId: string]: DateCleanBuckets
}

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum CleansActionTypes {
  FETCH_CLEAN = 'CLEANS/API/FETCH_CLEAN',
  FETCH_CLEANS_BY_ZONE_AND_DATE = 'CLEANS/API/FETCH_CLEANS_BY_ZONE_AND_DATE',
  FETCH_CLEANS_BY_ZONE_AND_DATE_FAILURE = 'CLEANS/FETCH_CLEANS_BY_ZONE_AND_DATE_FAILURE',

  FETCH_CLEANS_BY_ZONE_AND_DATE_SUCCESS = 'CLEANS/FETCH_CLEANS_BY_ZONE_AND_DATE_SUCCESS',
  FETCH_CLEAN_FAILURE = 'CLEANS/FETCH_CLEAN_FAILURE',
  FETCH_CLEAN_SUCCESS = 'CLEANS/FETCH_CLEAN_SUCCESS',

  UPDATE_CLEAN = 'CLEANS/API/UPDATE_CLEAN',
  UPDATE_CLEAN_FAILURE = 'CLEANS/UPDATE_CLEAN_FAILURE',
  UPDATE_CLEAN_SUCCESS = 'CLEANS/UPDATE_CLEAN_SUCCESS',
}

export type CleansState = {
  assignmentChanges: JSONApiAssignmentChangeMap
  data: JSONApiCleanMap
  error?: Error
  isLoading: boolean
  reservation: JSONApiReservationMapFromTasks
}

/**********************************************************
 * API WRITE TYPES
 *********************************************************/
export type CleanPatchData = {
  id: string
} & Partial<CleanPatchAttributes>

export type CleanPatchAttributes = {
  completedAt?: Date
  effectiveDate?: Date
  hks: { id: string }[]
  jobType?: CleanJobType[]
  notes: string | null
  startedAt?: Date
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export type CleansResponse = {
  assignment: JSONApiAssignmentsMap
  reservation: JSONApiReservationsMap
  task: JSONApiCleanMap
  taskPhoto: JsonApiTaskPhotosMap
  unit: JSONApiUnitMap
  user: JSONApiUsersMap
}

/**********************************************************
 * CLEAN
 *********************************************************/
export type Clean = {
  id: string
} & CleanAttributes &
  CleanDynamicAttributes

type CleanDynamicAttributes = {
  assignedHks: Housekeeper[]
  assignmentChangeIds: string[]
  nextReservation?: Reservation
  rejectedAssignments?: Assignment[]
  reservation: Reservation
  taskPhotos: TaskPhoto[]
  unit: Unit
}

export type CleanAttributes = Omit<AllCleanAttributes, 'timersSubmitted'>

export const CleanAttributeNames = difference(AllCleanAttributeNames, [
  'timersSubmitted',
])

export const CleanApiFields = CleanAttributeNames.map<string>(snakeCase)

export type CleanRelationships = {
  assignedHousekeepers: ToManyRelationship
  rejectedAssignments: ToManyRelationship
  reservation: ToOneRelationship
  taskPhotos: ToManyRelationship
  unit: ToOneRelationship
}

export type RawClean = JSONApiObjectWithRelationships<
  CleanAttributes,
  CleanRelationships
>

export type JSONApiCleanMap = JSONApiObjectWithRelationshipsMap<
  CleanAttributes,
  CleanRelationships
>
