import * as React from 'react'

import { Zone } from 'app/hkhub/store/zones/zones.types'

export type ZonePageContextType = {
  zone: Zone
}

export type OptionalZonePageContextType = {
  zone?: Zone
}

export const ZoneContext = React.createContext<OptionalZonePageContextType>(
  {} as ZonePageContextType,
)

/** Gets the current zone from the zone context when the zone does not need to be defined*/
export const useOptionalZoneContext = (): OptionalZonePageContextType =>
  React.useContext(ZoneContext)

/** Gets the current zone from the zone context, throwing an error if it is undefined*/
export const useZoneContext = (): ZonePageContextType => {
  const context = React.useContext(ZoneContext)
  if (!context.zone) throw new Error('Zone must exist')
  return context as ZonePageContextType
}
