import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ticketingService } from '../ticketing.service'
import {
  NormalizedTicketingApiResponse,
  TicketingActionTypes,
} from '../ticketing.types'

export const fetchTicketingAssignmentByIdAction = createAsyncAction(
  TicketingActionTypes.FETCH_ASSIGNMENT_BY_ID,
  TicketingActionTypes.FETCH_ASSIGNMENT_BY_ID_SUCCESS,
  TicketingActionTypes.FETCH_ASSIGNMENT_BY_ID_FAILURE,
)<
  RequestConfig<NormalizedTicketingApiResponse>,
  NormalizedTicketingApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    user: ['first_name', 'last_name', 'monolith_user_id'],
    zone_disposition_default: ['is_active'],
  },
  include: ['disposition', 'user'],
})

export const fetchTicketingAssignmentById = (id: string) => async dispatch => {
  try {
    const params = getParams()
    const request = ticketingService.fetchTicketingAssignmentById.bind(
      null,
      id,
      params,
    )
    const result = await dispatch(
      fetchTicketingAssignmentByIdAction.request({ request }),
    )
    dispatch(fetchTicketingAssignmentByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchTicketingAssignmentByIdAction.failure(error))
    throw error
  }
}
