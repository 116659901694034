import React from 'react'

import { useTicket } from './hooks/useTicket'
import { VisitTicketCard } from './VisitTicketCard'

export const VisitTicketCardContainer: React.FC<{
  ticketId: string
}> = React.memo(({ ticketId }) => {
  const { ticket } = useTicket(ticketId)

  return ticket ? <VisitTicketCard ticket={ticket} /> : null
})
