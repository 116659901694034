import { produce } from 'immer'
import {
  concat,
  includes,
  isEmpty,
  keys,
  map,
  pipe,
  reject,
  toString,
  uniqBy,
} from 'lodash/fp'

import { emptyFilter, emptyFilterBuckets } from './UnitFilters.context'
import {
  UnitFilterBuckets,
  UnitFilterType,
  UnitViewFilter,
} from './UnitFilters.types'

export type UnitFiltersState = {
  filters: UnitFilterBuckets
  needToClearSearchField: boolean
  searchFilters: UnitViewFilter[]
}

export enum UnitFiltersActionType {
  AddFilters = 'AddFilters',
  ClearAllFilters = 'ClearAllFilters',
  ClearSearchFilters = 'ClearSearchFilters',
  DisableNeedToClearSearchField = 'DisabledNeedClearSearchField',
  RemoveFilters = 'RemoveFilter',
  ResetToDefaultState = 'ResetToDefaultState',
  SetSearchFilters = 'SetSearchFilters',
}

type NoPayloadTypes =
  | UnitFiltersActionType.ClearAllFilters
  | UnitFiltersActionType.ClearSearchFilters
  | UnitFiltersActionType.DisableNeedToClearSearchField
  | UnitFiltersActionType.ResetToDefaultState

export type TypedFilterPayloadTypes =
  | UnitFiltersActionType.AddFilters
  | UnitFiltersActionType.RemoveFilters

export type UntypedFilterPayloadTypes = UnitFiltersActionType.SetSearchFilters

export type UnitFiltersAction =
  | {
      filterType: UnitFilterType
      filters: UnitViewFilter[]
      type: TypedFilterPayloadTypes
    }
  | {
      filters: UnitViewFilter[]
      type: UntypedFilterPayloadTypes
    }
  | {
      type: NoPayloadTypes
    }

export const initialstate: UnitFiltersState = {
  filters: emptyFilterBuckets,
  needToClearSearchField: false,
  searchFilters: emptyFilter,
}

export const unitFiltersReducer = (
  state: UnitFiltersState,
  action: UnitFiltersAction,
): UnitFiltersState => {
  return produce(state, draft => {
    switch (action.type) {
      case UnitFiltersActionType.AddFilters: {
        const { filters: filtersToAdd, filterType } = action

        if (filtersToAdd?.length) {
          draft.filters[filterType] = pipe(
            // If we have a valid filter, we can remove the empty filter
            reject(pipe(toString, isEmpty)),
            concat(filtersToAdd),
            uniqBy(toString),
          )(state.filters[filterType])
        }

        return
      }

      case UnitFiltersActionType.RemoveFilters: {
        const { filters: filtersToRemove, filterType } = action

        if (filtersToRemove?.length) {
          const filterStringsToRemove = map(toString)(filtersToRemove)
          const shouldRemoveFilter = includes(filterStringsToRemove)

          const filtersLeft = reject(pipe(toString, shouldRemoveFilter))(
            state.filters[filterType],
          )

          draft.filters[filterType] = filtersLeft.length
            ? filtersLeft
            : emptyFilter
        }

        return
      }

      case UnitFiltersActionType.DisableNeedToClearSearchField: {
        draft.needToClearSearchField = false
        return
      }

      case UnitFiltersActionType.SetSearchFilters: {
        const { filters } = action
        draft.searchFilters = filters?.length ? filters : emptyFilter
        draft.needToClearSearchField = false
        return
      }

      case UnitFiltersActionType.ClearAllFilters: {
        draft.filters = emptyFilterBuckets
        draft.needToClearSearchField = true
        draft.searchFilters = emptyFilter
        return
      }

      case UnitFiltersActionType.ClearSearchFilters: {
        draft.needToClearSearchField = true
        draft.searchFilters = emptyFilter
        return
      }

      case UnitFiltersActionType.ResetToDefaultState: {
        keys(initialstate).forEach(key => {
          draft[key] = initialstate[key]
        })

        return
      }

      default:
        return
    }
  })
}
