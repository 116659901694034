import styled from '@emotion/styled'
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { Alert, Loader } from 'packages/common'

import { Titlebar } from 'app/hkhub/components/core/components/Titlebar'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import { NotFoundPage } from '../../core/NotFoundPage'
import HousekeepersContainer from '../../housekeepers/HousekeepersContainer/HousekeepersContainer'
import ZoneManageContainer from '../../manage/ZoneManageContainer/ZoneManageContainer'
import { ScheduleRouter } from '../../schedule/Schedule.router'

const St = {
  OfflineAlert: styled(Alert)`
    margin: 0;
  `,
}

export type ZonePageProps = {
  initializingZone: boolean
  isOnline: boolean
  zone?: Zone
  zoneInitialized: boolean
  zoneIsInvalid: boolean
}

export const ZonePage: React.FC<ZonePageProps> = ({
  initializingZone,
  isOnline,
  zone,
  zoneInitialized,
  zoneIsInvalid,
}) => {
  return (
    <>
      {!isOnline && (
        <St.OfflineAlert alertType={'warning'}>
          You are currently offline. Functionality is limited.
        </St.OfflineAlert>
      )}

      <Titlebar initializingZone={initializingZone} selectedZone={zone} />

      {(initializingZone || !zoneInitialized) && <Loader transparent={true} />}

      {zoneInitialized && zone && (
        <Routes>
          {/* ROUTES WITH "/zone/:zoneId" PREFIX */}
          {/* Redirect from `/zone/:zoneId` to `/zone/:zoneId/schedule` */}
          <Route
            path=""
            element={
              <Navigate to={`/zone/${zone.id}/schedule`} replace={true} />
            }
          />

          {/* Define the valid routes */}
          <Route path="schedule/*" element={<ScheduleRouter />} />
          <Route path="housekeepers/*" element={<HousekeepersContainer />} />
          <Route path="manage/*" element={<ZoneManageContainer />} />

          {/* Catch-all route for unmatched paths */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      )}

      {zoneIsInvalid && <NotFoundPage />}
    </>
  )
}
