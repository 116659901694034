import {
  always,
  cond,
  curry,
  isFunction,
  isObject,
  mapValues,
  prop,
  T,
} from 'lodash/fp'

/*
 * As far as I can tell, there is a known bug, with no fix: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/45235
 * As of now the `convert` method, which allows us to use both values and keys when mapping an object, isn't defined, according to TS.
 *
 * This type is for using with mapValues converted to include keys.
 */
type ConvertedLodashMapFunction = typeof mapValues & {
  convert: ({ cap }: { cap: boolean }) => typeof mapValues
}

const mapValuesWithKeys = (mapValues as ConvertedLodashMapFunction).convert({
  cap: false,
})

/**
 * Heavily inspired by: https://ramdajs.com/docs/#evolve
 * For each function at each path in the transformations object, the function is
 * applied to the value at the matching path in the object to be transformed
 */
export const evolve = curry((transformations, item) =>
  mapValuesWithKeys((value, key) => {
    const transformation = prop(key)(transformations)

    return cond([
      /* Apply the function, if the transform is a function */
      [isFunction, transform => transform(value)],
      /* Evolve the sub-object if the transform is an object */
      [isObject, transform => evolve(transform)(value)],
      /* If none of the above, just return the value from the item */
      [T, always(value)],
    ])(transformation)
  }, item),
)
