/* eslint-disable no-restricted-imports */
/* eslint-disable no-restricted-syntax */
import {
  parseISO,
  add as _add,
  differenceInDays as _differenceInDays,
  differenceInHours as _differenceInHours,
  differenceInMonths as _differenceInMonths,
  differenceInSeconds as _differenceInSeconds,
  eachDayOfInterval as _eachDayOfInterval,
  endOfDay as _endOfDay,
  format as _format,
  formatDistanceToNow as _formatDistanceToNow,
  fromUnixTime as _fromUnixTime,
  isAfter as _isAfter,
  isBefore as _isBefore,
  isFuture as _isFuture,
  isPast as _isPast,
  isSameDay as _isSameDay,
  isSameMinute as _isSameMinute,
  isSameMonth as _isSameMonth,
  isSameSecond as _isSameSecond,
  isToday as _isToday,
  isValid as _isValid,
  isWithinInterval as _isWithinInterval,
  setHours as _setHours,
  setMinutes as _setMinutes,
  startOfDay as _startOfDay,
  startOfWeek as _startOfWeek,
  sub as _sub,
} from 'date-fns'
import { format as formatToTimeZone } from 'date-fns-tz'
// NOTE: tree-shaking may not be working properly for these imports
// https://github.com/date-fns/date-fns/issues/2207
import { cs, enUS as en, es, fr, hu, it } from 'date-fns/locale'
import { identity } from 'lodash/fp'

import { IntRange } from 'packages/grimoire/src/utils'
export { default as addWeeks } from 'date-fns/addWeeks'
export { startOfToday } from 'date-fns'
export { default as getDay } from 'date-fns/getDay'
export { default as intervalToDuration } from 'date-fns/intervalToDuration'
export { formatInTimeZone, toZonedTime, fromZonedTime } from 'date-fns-tz'
export { parseISO }

/**********************************************************
 * date-fns wrappers
 * mostly to provide backwards compatibility with v1,
 * most notably to continue allowing us to use strings
 *********************************************************/

/** Wrapper fn to ensure a 'date' arg is strictly a Date type (from string) */
const _getADate = (date: Date | string): Date =>
  typeof date === 'string' ? parseISO(date) : date

/** Wrapped format function allowing both Date and string args */
export const format = (date: Date | string, format: string): string =>
  _format(_getADate(date), format)

export const addDays = (date: Date | string, days: number): Date =>
  _add(_getADate(date), { days })

export const addHours = (date: Date | string, hours: number): Date =>
  _add(_getADate(date), { hours })

export const addMinutes = (date: Date | string, minutes: number): Date =>
  _add(_getADate(date), { minutes })

export const addSeconds = (date: Date | string, seconds: number): Date =>
  _add(_getADate(date), { seconds })

export const subDays = (date: Date | string, days: number): Date =>
  _sub(_getADate(date), { days })

export const subHours = (date: Date | string, hours: number): Date =>
  _sub(_getADate(date), { hours })

export const subMinutes = (date: Date | string, minutes: number): Date =>
  _sub(_getADate(date), { minutes })

export const differenceInSeconds = (
  dateA: Date | string,
  dateB: Date | string,
): number => _differenceInSeconds(_getADate(dateA), _getADate(dateB))

export const differenceInHours = (
  dateA: Date | string,
  dateB: Date | string,
): number => _differenceInHours(_getADate(dateA), _getADate(dateB))

export const differenceInDays = (
  dateA: Date | string,
  dateB: Date | string,
): number => _differenceInDays(_getADate(dateA), _getADate(dateB))

export const differenceInMonths = (
  dateA: Date | string,
  dateB: Date | string,
): number => _differenceInMonths(_getADate(dateA), _getADate(dateB))

export const eachDay = (start: Date | string, end: Date | string): Date[] =>
  _eachDayOfInterval({ end: _getADate(end), start: _getADate(start) })

export const isAfter = (dateA: Date | string, dateB: Date | string): boolean =>
  _isAfter(_getADate(dateA), _getADate(dateB))

export const isBefore = (dateA: Date | string, dateB: Date | string): boolean =>
  _isBefore(_getADate(dateA), _getADate(dateB))

export const isSameMonth = (
  dateA: Date | string,
  dateB: Date | string,
): boolean => _isSameMonth(_getADate(dateA), _getADate(dateB))

export const isSameDay = (
  dateA: Date | string,
  dateB: Date | string,
): boolean => _isSameDay(_getADate(dateA), _getADate(dateB))

export const isSameMinute = (
  dateA: Date | string,
  dateB: Date | string,
): boolean => _isSameMinute(_getADate(dateA), _getADate(dateB))

export const isSameSecond = (
  dateA: Date | string,
  dateB: Date | string,
): boolean => _isSameSecond(_getADate(dateA), _getADate(dateB))

export const isToday = (date: Date | string): boolean =>
  _isToday(_getADate(date))

export const isFuture = (date: Date | string): boolean =>
  _isFuture(_getADate(date))

export const isWithinInterval = (
  date: Date | string,
  interval: { end: Date | string; start: Date | string },
): boolean =>
  _isWithinInterval(_getADate(date), {
    end: _getADate(interval.end),
    start: _getADate(interval.start),
  })

export const isPast = (date: Date | string): boolean => _isPast(_getADate(date))

export const startOfDay = (date: Date | string): Date =>
  _startOfDay(_getADate(date))

export const startOfWeek = (
  date: Date | string,
  extra?: { weekStartsOn: IntRange<0, 7> },
): Date => _startOfWeek(_getADate(date), extra)

export const endOfDay = (date: Date | string): Date =>
  _endOfDay(_getADate(date))

/**********************************************************
 * Date formatting constants
 * https://date-fns.org/docs/format
 *
 * For the sake of maintainability, we should favor using
 * these as often as possible when calling "format()," rather
 * than specifying the format inline.
 *
 * As you add new formats, please leave a comment
 * specifying how the output will look.
 *
 * (Use single quotes to escape characters like 'T')
 *********************************************************/
export const RE_UTC_TIMESTAMP =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}$/

export const DateFormat = {
  ApiTimestamp: `HH:mm:ss`, // 11:02:34
  ApiUtcShort: 'yyyy-MM-dd', // 2021-03-21
  ApiUtcWithSeconds: `yyyy-MM-dd'T'HH:mm:ssxxx`, // 2021-03-21T11:02:34-07:00
  DayNameAndDate: 'ccc, MMM dd', // Sun, Mar 21,
  DayNameFull: 'eeee', // Monday, Tuesday, etc...
  DayNameShort: 'eee', // Mon, Tue, Wed, etc...
  DayOfMonth: 'dd', // 01, 02, ..., 31
  DayOfMonthShort: 'd', // 1, 2, ..., 31
  Full: 'eee, MMM do h:mmaaa', // Sun, Mar 21st 4:00pm
  FullWithoutTime: 'eee, MMM do', // Sun, Mar 21st
  IsoDateAndTime: `yyyy-MM-dd'T'HH:mm`, // 2021-07-07T11:03
  IsoDateAndTimeWithOffset: `yyyy-MM-dd'T'HH:mm:ssxxx`, // 2021-07-07T11:03:00-07:00
  MonthAndDayLong: 'MMMM d', /// March 3
  MonthAndDayShort: 'MMM d', /// Mar 3
  MonthAndDayShortWithTimeAndYear: 'MMM d, yyyy h:mmaaa', // Mar 21, 2023 4:00pm
  MonthAndDayShortWithTimeAndYearAndTZ: 'MMM d, yyyy h:mmaaa zzz', // Mar 21, 2023 4:00pm
  MonthAndDayShortWithYear: 'MMM d, yyyy', // Mar 21, 2023
  MonthDateAndYear: 'MMMM do, yyyy', /// March 21st, 2021
  MonthNameAbrevWithYear: 'MMM yyyy', // Mar 2021,
  MonthNameFull: 'MMMM', // January, February, ..., December
  MonthNameFullWithYear: 'MMMM yyyy', // March 2021
  MonthNameShort: 'MMM', // Jan, Feb, ..., Dec
  MonthShortDateAndYear: 'MMM do, yyyy', /// Mar 21st, 2021
  ShareText: 'eee, MMM-dd-yyyy h:mmaaa', // Sun, Mar-21-2021 4:00pm
  ShareTextDateOnly: 'eee, MMM-dd-yyyy', // Sun, Mar-21-2021
  SlashesWithFullYear: 'MM/dd/yyyy', // 03/21/2021
  SlashesWithShortYear: 'MM/dd/yy', // 03/21/21
  SlashesWithTime: 'MM/dd/yy h:mmaaa', // 03/21/21 4:00pm
  TimeWithAmPm: 'h:mmaaa', // 4:00pm
  YearFull: 'yyyy', // 2023
}

/**********************************************************
 * i18n Configuration for Date localization
 *********************************************************/

const locales = {
  cs,
  en,
  es,
  fr,
  hu,
  it,
}

const defaultLocale = locales.en
let locale = defaultLocale

export const setLocale = (code: string): void => {
  if (code.match(/^cs/i)) {
    locale = locales.cs
  } else if (code.match(/^en/i)) {
    locale = locales.en
  } else if (code.match(/^es/i)) {
    locale = locales.es
  } else if (code.match(/^fr/i)) {
    locale = locales.fr
  } else if (code.match(/^hu/i)) {
    locale = locales.hu
  } else if (code.match(/^it/i)) {
    locale = locales.it
  } else {
    locale = defaultLocale
  }
}

/**********************************************************
 * Locale-aware date parsing functions
 *********************************************************/
// TODO: Someday when we have time, we should fix this function.
// Currently it doesn't actually care about the timeZone that's sent in
export const formatLocalized = (
  date: Date | string,
  dateFormat: string,
  timeZone?: string,
): string => {
  if (timeZone) {
    return formatToTimeZone(new Date(date), dateFormat, {
      locale,
      timeZone,
    })
  } else {
    return _format(_getADate(date), dateFormat, { locale })
  }
}

export const distanceToNowLocalized = (then: number): string =>
  _formatDistanceToNow(then, { addSuffix: true, locale })

/**********************************************************
 * Pre-formatted Date string instances
 *********************************************************/

export const MIDSTAY_CLEAN_START_TIME_STRING = formatLocalized(
  _setHours(_setMinutes(new Date(), 0), 9),
  DateFormat.TimeWithAmPm,
)

export const MIDSTAY_CLEAN_DUE_TIME_STRING = formatLocalized(
  _setHours(_setMinutes(new Date(), 0), 11),
  DateFormat.TimeWithAmPm,
)

/**********************************************************
 * Date creation helpers
 *********************************************************/

/**
 * Returns a Date object from the provided Date or string, based on the following conditions:
 * - If `date` is already a Date instance, it will return it directly
 * - If `date` is a string, it will parse the string into a new Date object
 * - If `date` is a falsey value, it will create and return a new Date instance.
 * @param date
 */
export const createDateObject = (date?: Date | string): Date => {
  if (date instanceof Date) return date
  const newDate = date ? parseISO(date) : new Date()
  return _isValid(newDate) ? newDate : new Date()
}

export const createDateObjectFromTimestamp = (date: number): Date => {
  return _fromUnixTime(date)
}

/**
 * Parses the provided Date or string into a full ISO date string.
 * If no `date` arg is provided, it will make the ISO string from a new Date instance.
 * @param date
 */
export const createDateString = (date?: Date | string): string => {
  return createDateObject(date).toISOString()
}

/**********************************************************
 * API Date/string conversion helpers
 *********************************************************/

/**
 * Attempts to convert the provided Date object into an API-friendly ISO date
 * string, including seconds.
 * @param date
 */
export const apiDateStringWithSeconds = (date: Date): string =>
  _format(_getADate(date), DateFormat.ApiUtcWithSeconds)

const TODAY = createDateObject()

type DateRangeIteratorConfig<T = Date> = {
  endDate?: Date | string
  format?: (date: Date) => T
  startDate?: Date | string
  /** End Day will override this */
  totalDays?: number
}

/** Generaterator that creates a date range - if no end date or total days is passed in, defaults to a year */
export function* dateRangeGenerator<T>(
  config: DateRangeIteratorConfig<T>,
): Generator<T> {
  const startDate = config.startDate || TODAY
  const endDate = config.endDate || null
  const totalDays = endDate
    ? differenceInDays(endDate, startDate) + 1 // include the final day
    : config.totalDays || 365
  const formatDate = config.format || identity

  for (let i = 0; i < totalDays; i += 1) {
    yield formatDate(addDays(startDate, i)) as T
  }

  return
}

export function createDateRange<T>(config: DateRangeIteratorConfig<T>): T[] {
  return Array.from(dateRangeGenerator<T>(config))
}
