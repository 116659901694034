import { createAsyncThunk } from '@reduxjs/toolkit'

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { unitsService } from '../units.service'

export const getParams = (search: string): RequestOptions => ({
  fields: {
    zone: ['name'],
  },
  filter: {
    all_units: true,
    is_guestworks: false,
    search,
  },
  include: ['zone'],
  page: { size: 1500 },
})

/**
 * Performs a search request to find Units matching the specified search string.
 * Note that only Units who are not currently associated with a zone will be included
 * in the search results.
 *
 * (For further details about the search implementation, please refer to the API docs.)
 *
 * @param search The search string to use for searching Units
 */
export const searchUnits = createAsyncThunk(
  'units/api/searchUnits',
  async (search: string) => {
    const params = getParams(search)
    return await unitsService.fetchUnits(params)
  },
)
