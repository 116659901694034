import { noop } from 'lodash/fp'
import { useCallback, useState } from 'react'

export const DEFAULT_TRANSITION_TIME = 200

export enum TransitionPhaseType {
  enterActive = 'enterActive',
  enterStart = 'enterStart',
  exitActive = 'exitActive',
  exitStart = 'exitStart',
  hidden = 'hidden',
  idle = 'idle',
}

export const closedStates = [
  TransitionPhaseType.exitStart,
  TransitionPhaseType.exitActive,
  TransitionPhaseType.hidden,
]

export const isInClosedPhase = (phase: TransitionPhaseType): boolean =>
  closedStates.includes(phase)

export const openStates = [
  TransitionPhaseType.enterStart,
  TransitionPhaseType.enterActive,
  TransitionPhaseType.idle,
]

export const isInOpenPhase = (phase: TransitionPhaseType): boolean =>
  openStates.includes(phase)

export type UseTransitionProps = {
  afterExit?: () => void
  beforeEnter?: () => void
  transitionTime?: number
}

type UseTransition = {
  beginTransitionIn: () => void
  beginTransitionOut: () => void
  transitionPhase: TransitionPhaseType
}

export const useTransition = ({
  afterExit = noop,
  beforeEnter = noop,
  transitionTime = DEFAULT_TRANSITION_TIME,
}: UseTransitionProps): UseTransition => {
  const [transitionPhase, setTransitionPhase] = useState<TransitionPhaseType>(
    TransitionPhaseType.hidden,
  )

  /**************************************************
   * "Enter" transitions
   **************************************************/

  const finishTransitionIn = useCallback(() => {
    setTransitionPhase(TransitionPhaseType.idle)
  }, [])

  const setEnterActive = useCallback(() => {
    setTransitionPhase(TransitionPhaseType.enterActive)
    setTimeout(finishTransitionIn, transitionTime)
  }, [finishTransitionIn, transitionTime])

  const setEnterStart = useCallback(() => {
    setTransitionPhase(TransitionPhaseType.enterStart)
    setTimeout(setEnterActive, 0)
  }, [setEnterActive])

  const beginTransitionIn = useCallback(() => {
    if (transitionPhase === TransitionPhaseType.hidden) {
      beforeEnter()
      setEnterStart()
    }
  }, [beforeEnter, setEnterStart, transitionPhase])

  /**************************************************
   * "Exit" transitions
   **************************************************/

  const finishTransitionOut = useCallback(() => {
    setTransitionPhase(TransitionPhaseType.hidden)
    afterExit()
  }, [afterExit])

  const setExitActive = useCallback(() => {
    setTransitionPhase(TransitionPhaseType.exitActive)
    setTimeout(finishTransitionOut, transitionTime)
  }, [finishTransitionOut, transitionTime])

  const setExitStart = useCallback(() => {
    setTransitionPhase(TransitionPhaseType.exitStart)
    setTimeout(setExitActive, 0)
  }, [setExitActive])

  const beginTransitionOut = useCallback(() => {
    if (transitionPhase === TransitionPhaseType.idle) {
      setExitStart()
    }
  }, [setExitStart, transitionPhase])

  return { beginTransitionIn, beginTransitionOut, transitionPhase }
}
