import React from 'react'
import { VariableSizeList } from 'react-window'

import { Breakpoint } from 'packages/styles'

import {
  CardSize,
  getStaffScheduleHeight,
  measureStaffScheduleRow,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { VIRTUALIZED_SCHEDULE_CLASSNAME } from 'app/hkhub/components/schedule/schedule.types'
import { HkDateCleanBuckets } from 'app/hkhub/store/cleans'
import { HkDateCoverageBuckets } from 'app/hkhub/store/coverage/coverage.types'
import { HkAvailabilitiesMap } from 'app/hkhub/store/hkAvailabilities/hkAvailabilities.types'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { HkDateVisitBuckets } from 'app/hkhub/store/visits/visits.types'

import { useScheduleContext } from '../../common/VirtualizedSchedule/VirtualizedSchedule.context'
import { StaffScheduleRow } from './components'

const getItemKey = (idx: number, hks: Housekeeper[]): string | number =>
  hks?.length ? hks[idx]?.id : idx

const listStyles: React.CSSProperties = {
  // ensure we do not get a horizontal scrollbar (schedule view must be full-width)
  overflowX: 'hidden',
}

const createRowRenderer =
  ({
    breakpoint,
    hkDateCleanBuckets,
    hkDateCoverageBuckets,
    hkDateVisitBuckets,
    hks,
  }: VirtualizedStaffScheduleProps) =>
  ({ index, style }) => {
    const hk: Housekeeper = hks[index]
    const cleans = hkDateCleanBuckets[hk.id] || {}
    const visits = hkDateVisitBuckets[hk.id] || {}
    const coverage = hkDateCoverageBuckets[hk.user.id] || {}

    return (
      <StaffScheduleRow
        breakpoint={breakpoint}
        cleans={cleans}
        coverage={coverage}
        hk={hk}
        style={style}
        visits={visits}
      />
    )
  }

export type VirtualizedStaffScheduleProps = {
  breakpoint: Breakpoint
  cardSize: CardSize
  hkAvailabilityBuckets: HkAvailabilitiesMap
  hkDateCleanBuckets: HkDateCleanBuckets
  hkDateCoverageBuckets: HkDateCoverageBuckets
  hkDateVisitBuckets: HkDateVisitBuckets
  hks: Housekeeper[]
}

export const VirtualizedStaffSchedule: React.FC<VirtualizedStaffScheduleProps> =
  React.memo(props => {
    const {
      breakpoint,
      cardSize,
      hkAvailabilityBuckets,
      hkDateCleanBuckets,
      hkDateCoverageBuckets,
      hkDateVisitBuckets,
      hks,
    } = props
    const listRef = React.useRef<VariableSizeList>(null)

    const { isLoading } = useScheduleContext()

    const measureRow = React.useMemo(() => {
      // since we know that some key data has changed, we must
      // trigger a reset so that react-window will re-measure all rows
      listRef.current?.resetAfterIndex(0, false)
      return measureStaffScheduleRow(
        breakpoint,
        hkAvailabilityBuckets,
        hks,
        hkDateCleanBuckets,
        hkDateCoverageBuckets,
        cardSize,
        isLoading,
        hkDateVisitBuckets,
      )
    }, [
      breakpoint,
      cardSize,
      hkAvailabilityBuckets,
      hkDateCleanBuckets,
      hkDateCoverageBuckets,
      hkDateVisitBuckets,
      hks,
      isLoading,
    ])

    const Row = React.useMemo(() => createRowRenderer(props), [props])

    return (
      <VariableSizeList
        className={VIRTUALIZED_SCHEDULE_CLASSNAME}
        height={getStaffScheduleHeight(breakpoint)}
        itemCount={hks.length}
        itemData={hks}
        itemKey={getItemKey}
        itemSize={measureRow}
        ref={listRef}
        style={listStyles}
        width={'100%'}
        overscanCount={500}
      >
        {Row}
      </VariableSizeList>
    )
  })
