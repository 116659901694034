import { createAsyncAction } from 'typesafe-actions'

import { RequestConfig } from 'packages/utils/store/jsonapi.types'

import { notificationsService } from '../notifications.service'
import {
  NormalizedNotificationsApiResponse,
  ZoneNotificationsActionTypes,
} from '../notifications.types'

export const fetchAllZoneNotificationsAction = createAsyncAction(
  ZoneNotificationsActionTypes.FETCH_NOTIFICATIONS,
  ZoneNotificationsActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
  ZoneNotificationsActionTypes.FETCH_NOTIFICATIONS_FAILURE,
)<
  RequestConfig<NormalizedNotificationsApiResponse>,
  NormalizedNotificationsApiResponse,
  Error
>()

export const getParams = (zoneId: string) => {
  return {
    fields: { zone_notification: ['message_code'] },
    filter: { status: 'active', zone: zoneId },
    page: { size: 1500 },
  }
}

export const fetchAllZoneNotifications = (zoneId: string) => async dispatch => {
  try {
    const params = getParams(zoneId)
    const request = notificationsService.fetchAllNotifications.bind(
      null,
      params,
    )
    const data = await dispatch(
      fetchAllZoneNotificationsAction.request({ request }),
    )
    dispatch(fetchAllZoneNotificationsAction.success(data))
    return data.normalized
  } catch (error) {
    dispatch(fetchAllZoneNotificationsAction.failure(error))
    throw error
  }
}
