import * as React from 'react'

export type SentryContextType = {
  sentryIsActive: boolean
}

const { Provider, Consumer } = React.createContext<SentryContextType | null>(
  null,
)

export { Consumer as SentryContextConsumer, Provider as SentryContextProvider }
