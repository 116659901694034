import { produce } from 'immer'

import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  NormalizedTicketsApiResponse,
  RawTicket,
  Ticket,
  TicketAttributeNames,
  TicketsResponse,
} from './tickets.types'

export const stripTimeZoneFromDateString = (date?: string): string => {
  if (!date) return ''
  return date.slice(0, 10)
}

export const emptyNormalizedTicketsData: TicketsResponse = Object.freeze({
  comment: {},
  lockBox: {},
  reservation: {},
  ticket: {},
  ticketComment: {},
  unit: {},
  user: {},
})

export const emptyTicketsResponse: NormalizedTicketsApiResponse = Object.freeze(
  {
    normalized: emptyNormalizedTicketsData,
    raw: { data: [] },
  },
)

export const hydrateRawTicket = (rawTicket: RawTicket): Ticket => {
  const baseTicket = transformNormalizedToTyped<Ticket>(
    rawTicket,
    TicketAttributeNames,
  )
  return produce(baseTicket, draft => {
    draft.assigneeId = rawTicket.relationships?.assignee?.data?.id || null
    draft.commentIds = (rawTicket.relationships?.comments?.data || []).map(
      ({ id }) => id,
    )

    draft.unitId = rawTicket.relationships?.unit?.data?.id

    // strip the timezone from the due date because we don't care about it
    draft.dueDateNoTimestamp = stripTimeZoneFromDateString(
      rawTicket.attributes.dueDate,
    )
  })
}
