import styled from '@emotion/styled'
import React from 'react'

import { Alert } from 'packages/common'

import { useI18n, Slugs } from 'app/hkhub/i18n'
import { Ticket } from 'app/hkhub/store/tickets'

import { TicketCardContainer } from '../../../TicketCard/TicketCard.container'

const St = {
  Alert: styled(Alert)`
    margin-bottom: 0px;
  `,
}

export type TicketsListProps = {
  tickets: Ticket[]
}

export const TicketsList: React.FC<TicketsListProps> = React.memo(
  ({ tickets }) => {
    const { t } = useI18n()
    return tickets.length !== 0 ? (
      <>
        {tickets.map(ticket => {
          return (
            <TicketCardContainer key={ticket.id} size="large" ticket={ticket} />
          )
        })}
      </>
    ) : (
      <St.Alert alertType="info">{t(Slugs.noTicketsAlert)}</St.Alert>
    )
  },
)
