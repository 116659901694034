import { noop } from 'lodash/fp'
import React from 'react'

import {
  CheckboxContainer,
  CheckboxError,
  CheckboxInput,
  CheckboxLabel,
  CustomCheckbox,
} from './Checkbox.styles'

export enum CheckboxTestIds {
  container = 'Checkbox__container',
  error = 'Checkbox__error',
}

export type CheckboxProps = {
  /**
   * Whether the <input> element should be checked in its current state. Note that
   * providing any value for this prop makes this a controlled input, so the parent
   * component will have to manage state.
   *
   * If you do not need a controlled checkbox, don't need to provide initial state, and
   * only need to respond to changes, you can omit this altogether
   * and rely solely on the 'onChange' callback.
   */
  checked?: boolean
  className?: string
  dataTestId?: string
  disabled?: boolean
  error?: string
  label: string | React.ReactNode
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string | number
}

export const Checkbox: React.FC<CheckboxProps> = React.memo(
  ({
    checked,
    className,
    dataTestId = CheckboxTestIds.container,
    disabled = false,
    error,
    label,
    name,
    onChange = noop,
    value,
  }) => {
    const cbName = name ?? `Checkbox__${value}`

    return (
      <CheckboxContainer className={className} data-testid={dataTestId}>
        <CheckboxLabel disabled={disabled} htmlFor={cbName}>
          {error ? (
            <CheckboxError data-testid={CheckboxTestIds.error}>
              {error}
            </CheckboxError>
          ) : null}
          {label}
          <CheckboxInput
            checked={checked}
            disabled={disabled}
            id={cbName}
            name={cbName}
            onChange={onChange}
            type="checkbox"
            value={value}
            data-testid={cbName}
          />
          <CustomCheckbox error={error} />
        </CheckboxLabel>
      </CheckboxContainer>
    )
  },
)
