import * as React from 'react'

import { IconName } from 'packages/iconic'

import {
  LinkToggleContainer,
  LinkToggleOption,
  LinkToggleOptionIcon,
} from './LinkToggle.styles'

export type LinkToggleOptionType = {
  iconName?: string
  id: string
  onClick: () => void
  title: string
}

type LinkToggleProps = {
  options: [LinkToggleOptionType, LinkToggleOptionType]
  selectedOption?: string
}

export const LinkToggle: React.FC<LinkToggleProps> = ({
  options,
  selectedOption,
}) => {
  return (
    <LinkToggleContainer>
      {(options as unknown as LinkToggleOptionType[]).map(option => {
        return (
          <LinkToggleOption
            key={option.id}
            onClick={option.onClick}
            isSelected={selectedOption === option.id}
          >
            {option.iconName && (
              <div data-testid="linktoggle-icon">
                <LinkToggleOptionIcon
                  icon={option.iconName as IconName}
                  size={18}
                />
              </div>
            )}
            {option.title}
          </LinkToggleOption>
        )
      })}
    </LinkToggleContainer>
  )
}
