import styled from '@emotion/styled'
import React from 'react'

import { Alert } from 'packages/common'

import { AdminContextType } from '../../contexts/AdminContext'

const St = {
  AdminAlert: styled(Alert)`
    margin: 0;

    &:not(:first-of-type) {
      border-top: 1px solid currentColor;
    }

    a {
      color: currentColor;
      text-decoration: underline;
    }
  `,
}

export type AdminAlertsListProps = Pick<
  AdminContextType,
  'alerts' | 'removeAlert'
>

export const AdminAlertsList: React.FC<AdminAlertsListProps> = React.memo(
  ({ alerts, removeAlert }) => {
    return (
      <div>
        {alerts.map((alert, idx) => (
          <St.AdminAlert
            alertType={alert.alertType}
            key={idx}
            onClose={() => removeAlert(alert)}
          >
            {alert.children}
          </St.AdminAlert>
        ))}
      </div>
    )
  },
)
