import { prop, reject, includes, pipe } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { ZonesState } from '../../zones/zones.types'
import { Housekeeper, HousekeepersState } from '../housekeepers.types'
import { isAgency } from '../housekeepers.utils'
import { getSortedHks } from './getSortedHks'

const getHousekeepersState = (state: ApplicationState): HousekeepersState =>
  state.housekeepers
const getZonesState = (state: ApplicationState): ZonesState => state.zones

export const getBorrowedHks = createSelector(
  getHousekeepersState,
  getZonesState,
  (hkState, zoneState): Housekeeper[] => {
    const sortedHks = getSortedHks(hkState)
    const currentZones = prop('currentZones')(zoneState)

    const isInCurrentZone = (hk: Housekeeper) =>
      includes(prop('zone.id')(hk))(currentZones)

    return pipe(reject(isAgency), reject(isInCurrentZone))(sortedHks)
  },
)
