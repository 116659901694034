import get from 'lodash/get' // eslint-disable-line

import { ApplicationState } from '../../store'

export function getNotificationCountByType(
  state: ApplicationState,
  // TODO: it might be better to type this as an enum
  notificationCode: string,
): number {
  const notifications = get(state, 'notifications.data', {})
  return Object.values(notifications).filter(
    notification =>
      get(notification, 'attributes.messageCode', '') === notificationCode,
  ).length
}
