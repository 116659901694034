import groupBy from 'lodash/groupBy'
import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from '../../store'
import { TicketingState, TicketingDisposition } from '../ticketing.types'

const getTicketingState = (state: ApplicationState): TicketingState =>
  state.ticketing

export const getDispositionsBucketedByTier = createSelector(
  getTicketingState,
  ticketingState => {
    const { dispositions } = ticketingState
    const flattenedDispositions = Object.values(dispositions).map(disposition =>
      transformNormalizedToTyped<TicketingDisposition>(disposition, [
        'tier1Display',
        'tier2Display',
      ]),
    )

    return groupBy(flattenedDispositions, 'tier1Display')
  },
)
