import React from 'react'

import { LightboxProps } from './Lightbox'

type LightboxConfig = {
  newSources: string[]
  sourceIndex: number
  // Singular "title" is only here for legacy support in Field App, where we do
  // not currently cycle through all photos. If we update Field App, we can remove
  // this in favor of only using "titles," similarly to Hub's implementation.
  title?: string
  titles: string[]
}

export type UseLightbox = Omit<LightboxProps, 'renderContainer'> & {
  openLightbox: (config: LightboxConfig) => void
}

// most of this implementation is taken directly from the docs example:
// https://github.com/Veylkh/react18-image-lightbox#example
export const useLightbox = (): UseLightbox => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [sourcesState, setSourcesState] = React.useState({
    imageTitle: '',
    sourceIndex: 0,
    sources: [] as string[],
    titles: [] as string[],
  })

  const openLightbox = React.useCallback((config: LightboxConfig) => {
    const { newSources, sourceIndex, title, titles = [] } = config
    setIsOpen(true)
    setSourcesState({
      imageTitle: titles[sourceIndex] || title || '',
      sourceIndex,
      sources: newSources,
      titles,
    })
  }, [])

  const onCloseRequest = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  const onMoveNextRequest = React.useCallback(() => {
    return setSourcesState(prev => {
      const sourceIndex = (prev.sourceIndex + 1) % prev.sources.length
      return {
        ...prev,
        imageTitle: prev.titles[sourceIndex] || prev.imageTitle,
        sourceIndex,
      }
    })
  }, [])

  const onMovePrevRequest = React.useCallback(() => {
    return setSourcesState(prev => {
      const sourceIndex =
        (prev.sourceIndex + prev.sources.length - 1) % prev.sources.length

      return {
        ...prev,
        imageTitle: prev.titles[sourceIndex] || prev.imageTitle,
        sourceIndex,
      }
    })
  }, [])

  return {
    isOpen,
    onCloseRequest,
    onMoveNextRequest,
    onMovePrevRequest,
    openLightbox,
    ...sourcesState,
  }
}
