import { pipe, reject, filter, prop, includes } from 'lodash/fp'
import { createSelector } from 'reselect'

import { isAgency } from 'packages/grimoire'

import { ApplicationState } from '../../store'
import { Housekeeper } from '../housekeepers.types'
import { getSortedHks } from './getSortedHks'

const getHousekeepersState = (state: ApplicationState) => state.housekeepers
const getZonesState = (state: ApplicationState) => state.zones

export const getPrimaryHks = createSelector(
  getHousekeepersState,
  getZonesState,
  (hkState, zonesState): Housekeeper[] => {
    const currentZones = prop('currentZones')(zonesState)
    const sortedHks = getSortedHks(hkState)

    const isInCurrentZone = (hk: Housekeeper) =>
      includes(prop('zone.id')(hk))(currentZones)

    return pipe(reject(isAgency), filter(isInCurrentZone))(sortedHks)
  },
)
