import { fromPairs } from 'lodash/fp'

/**
 * Takes the "search" portion of a URL and returns an object with the search queries
 * split into key/value pairs, as defined by the "&" delimiter.
 *
 * The URL can either be the full URL with a search query, or just the search query
 * itself (i.e. just the string starting with "?")
 *
 * - If an invalid URL with no search query is passed in, an empty object will returned
 * - Undefined or empty values will be removed from the returned object
 * @param urlWithSearch
 */
export const splitSearchQuery = (
  urlWithSearch: string,
): Record<string, string> => {
  const search = urlWithSearch.split('?')[1] || ''

  const sanitized = search
    .split('&') // split to individual queries
    .map(str => str.split('=')) // split each query into [key, value] pairs
    .filter(([_key, value]) => !!value) // remove any empty/undefined values

  return fromPairs(sanitized)
}
