import styled from '@emotion/styled'
import React from 'react'

import { Button } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Unit } from 'app/hkhub/store/units'

import { InspectionCategoriesTestIds } from '../../../CleanDrawer/InspectionSection/components/InspectionCategories'
import { UnitDrawerStyles } from '../../UnitDrawer.styles'
import { getLastDateString } from '../DeepCleansSection/DeepCleansSection.helpers'
import { CRUDCustomInspectionItemDisplay } from './CustomInspectionItemDisplay/CRUDCustomInspectionItemDisplay'
import { CustomInspectionItemDisplay } from './CustomInspectionItemDisplay/CustomInspectionItemDisplay'
import { CustomInspectionItemsFormState } from './customInspectionItemsState/customInspectionItemsForm.reducer'
import {
  CustomInspectionItemActionHandlersType,
  CustomInspectionItemApiCallHandlersStateType,
  CustomInspectionItemApiCallHandlersType,
} from './VisitSection.container'

const St = {
  CategoryContainer: styled.div`
    margin: 0 -16px;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${colors.midnight20};
    }
  `,
  CategoryHeader: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
  `,
  CategoryTitle: styled.div`
    ${text.headingXsCaps}
    color: ${colors.midnight};
    flex-grow: 1;
    padding-left: 10px;
  `,
  ChecklistIcon: styled(SvgIcon)`
    color: ${colors.midnight60};
  `,
  Divider: UnitDrawerStyles.Divider,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  `,
  InfoText: styled.span`
    ${text.bodyBoldRegular};
    margin-left: 4px;
  `,
  InfoTitle: styled.span`
    ${text.bodyRegularDefault};
  `,
  NewItemButton: styled(Button)`
    display: flex;
    justify-content: flex-start;
    margin: 24px 0 40px 24px;
  `,
  Section: UnitDrawerStyles.Section,
  SectionContent: styled(UnitDrawerStyles.SectionContent)`
    margin-left: 20px;
    margin-bottom: 10px;
  `,
  Title: UnitDrawerStyles.SectionTitle,
}

export enum VisitSectionTestIds {
  addNewChecklistItemButton = 'VisitSection__addNewChecklistItemButton',
}

export type VisitSectionProps = {
  actionHandlers: CustomInspectionItemActionHandlersType
  apiCallHandlers: CustomInspectionItemApiCallHandlersType
  apiCallHandlersState: CustomInspectionItemApiCallHandlersStateType
  customInspectionItemsState: CustomInspectionItemsFormState
  unit: Unit
}

export const VisitSection: React.FC<VisitSectionProps> = React.memo(
  ({
    actionHandlers,
    apiCallHandlers,
    apiCallHandlersState,
    customInspectionItemsState,
    unit,
  }) => {
    const { t, ut } = useI18n()
    return (
      <>
        <St.Section>
          <St.Form>
            <St.Title>{t(Slugs.homeVisits)}</St.Title>
            <St.CategoryContainer
              data-testid={InspectionCategoriesTestIds.category}
              key={'custom'}
            >
              <St.CategoryHeader
                data-testid={InspectionCategoriesTestIds.header}
              >
                <St.ChecklistIcon icon={IconName.calendarCheck} size={16} />
                <St.CategoryTitle>{t(Slugs.zoneCompletion)}</St.CategoryTitle>
              </St.CategoryHeader>
              <St.SectionContent>
                <St.InfoTitle>{t(Slugs.completionRate)}</St.InfoTitle>
                <St.InfoText>
                  {
                    unit.statistics?.visitStats
                      ?.scheduledVisitCompletionPercentage
                  }
                </St.InfoText>
              </St.SectionContent>
              <St.CategoryHeader>
                <St.ChecklistIcon icon={IconName.calendarCheck} size={16} />
                <St.CategoryTitle>{t(Slugs.unitLastVisited)}</St.CategoryTitle>
              </St.CategoryHeader>
              <St.SectionContent>
                <St.InfoTitle>
                  {unit.statistics?.lastInspectionCompletedAt
                    ? getLastDateString(
                        unit.statistics?.lastInspectionCompletedAt,
                      )
                    : t(Slugs.noActivity)}
                </St.InfoTitle>
              </St.SectionContent>
              <St.CategoryHeader>
                <St.ChecklistIcon icon={IconName.calendarCheck} size={16} />
                <St.CategoryTitle>{t(Slugs.checklistInfo)}</St.CategoryTitle>
              </St.CategoryHeader>
              <St.SectionContent>
                <div>
                  <St.InfoTitle>{ut(Slugs.items)}:</St.InfoTitle>
                  <St.InfoText>
                    {unit.statistics?.visitStats?.totalChecklistItems}
                  </St.InfoText>
                </div>
                <div>
                  <St.InfoTitle>{t(Slugs.custom)}:</St.InfoTitle>
                  <St.InfoText>
                    {unit.statistics?.visitStats?.customInspectionItemsCount}
                  </St.InfoText>
                </div>
              </St.SectionContent>
              <St.CategoryHeader>
                <St.ChecklistIcon icon={IconName.note} size={16} />
                <St.CategoryTitle>{t(Slugs.customChecklists)}</St.CategoryTitle>
              </St.CategoryHeader>
              {customInspectionItemsState.values.customInspectionItems?.map(
                (item, index) =>
                  customInspectionItemsState.values.crudCustomInspectionItem
                    ?.id === item.id ? (
                    <CRUDCustomInspectionItemDisplay
                      actionHandlers={actionHandlers}
                      apiCallHandlers={apiCallHandlers}
                      apiCallHandlersState={apiCallHandlersState}
                      index={index}
                      item={item}
                      key={`item-${item.id}`}
                      state={customInspectionItemsState}
                    />
                  ) : (
                    <CustomInspectionItemDisplay
                      actionHandlers={actionHandlers}
                      index={index}
                      item={item}
                      key={`item-${item.id}`}
                      state={customInspectionItemsState}
                    />
                  ),
              )}
              {customInspectionItemsState.values.crudCustomInspectionItem
                ?.type === 'add' && (
                <CRUDCustomInspectionItemDisplay
                  actionHandlers={actionHandlers}
                  apiCallHandlers={apiCallHandlers}
                  apiCallHandlersState={apiCallHandlersState}
                  state={customInspectionItemsState}
                />
              )}

              <St.NewItemButton
                dataTestId={VisitSectionTestIds.addNewChecklistItemButton}
                buttonType={'text'}
                disabled={
                  !!customInspectionItemsState.values.crudCustomInspectionItem
                    ?.id
                }
                onClick={actionHandlers.addItem}
              >
                {ut(Slugs.addChecklistItem)}
              </St.NewItemButton>
            </St.CategoryContainer>
          </St.Form>
        </St.Section>

        <St.Divider />
      </>
    )
  },
)
