import { filter, merge, pipe, propEq, reduce, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { findRelationshipId } from 'packages/utils/store'

import { getUnitById } from 'app/hkhub/store/units/selectors'

import { ApplicationState } from '../../store'
import { Reservation } from '../reservations.types'
import { transformNormalizedToReservation } from '../utils/transformNormalizedToReservation'

type UnitReservationSchedule = {
  [unitId: string]: Reservation[]
}

const isActiveUnit = propEq('attributes.isActive', true)

const reduceToEmptyIdMap = (acc, { id }) => {
  acc[id] = []
  return acc
}

export const getReservationsByUnits = createSelector(
  (state: ApplicationState) => state.reservations,
  (state: ApplicationState) => state.units,
  (resState, unitsState): UnitReservationSchedule => {
    const activeUnitIds = pipe(
      values,
      filter(isActiveUnit),
      reduce(reduceToEmptyIdMap, {}),
    )(unitsState.data)

    const groupReservationsByUnit = (acc, res) => {
      const unitId = findRelationshipId(res, 'unit')
      const unit = unitId ? getUnitById(unitsState, unitId) : undefined

      if (unit?.id) {
        const arr = acc[unit.id] || []
        arr.push(transformNormalizedToReservation(res))
        acc[unit.id] = arr
      }

      return acc
    }

    const reservationByUnits = pipe(
      values,
      reduce(groupReservationsByUnit, {}),
    )(resState.data)

    return merge(activeUnitIds, reservationByUnits)
  },
)
