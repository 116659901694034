import { isMobile } from 'mobile-device-detect'

// wrapping this mostly for testing purposes; since the original helper
// is not a function, we cannot easily mock it for tests that rely on it
export const isMobileOS = (): boolean => isMobile

export const isPWA = (): boolean =>
  window.navigator?.['standalone'] ||
  window.matchMedia('(display-mode: standalone)').matches

export const isOnline = (): boolean => navigator.onLine
