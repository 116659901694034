import styled from '@emotion/styled'
import React from 'react'

import { Card } from 'packages/common'
import { text, colors } from 'packages/styles'
import { DateFormat, format } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Ticket, TicketSeverity } from 'app/hkhub/store/tickets'

import { useTranslateTickets } from '../../hooks/useTranslateTickets'

export const severityColorMap = {
  [TicketSeverity.LOW]: colors.midnight10,
  [TicketSeverity.MEDIUM]: colors.midnight10,
  [TicketSeverity.PLANNED]: colors.midnight10,
  [TicketSeverity.PRIOR_TO_NEXT_GUEST]: colors.awareness40,
  [TicketSeverity.TODAY]: colors.alert40,
  [TicketSeverity.URGENT]: colors.alert40,
}

const St = {
  CardContent: styled.div`
    padding: 12px;
  `,
  SeverityText: styled.span`
    ${text.bodyBoldTiny};
    text-transform: uppercase;
  `,
  StatusBar: styled.div<{ severity: TicketSeverity }>`
    background: ${props => severityColorMap[props.severity]};
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    padding: 6px 8px;
  `,
  TicketDescription: styled.div`
    ${text.bodyRegularTiny}
  `,
  TicketTitle: styled.div`
    ${text.bodyRegularDefault}
    font-weight: bold;
    margin-bottom: 2px;
  `,
  VisitTicketCard: styled(Card)`
    margin-bottom: 8px;
  `,
}

export type VisitTicketCardProps = {
  ticket: Ticket
}

export const VisitTicketCard: React.FC<VisitTicketCardProps> = ({ ticket }) => {
  const { severity, title } = ticket
  const { translateSeverity } = useTranslateTickets()
  const { ut } = useI18n()

  return (
    <St.VisitTicketCard>
      <St.StatusBar severity={ticket.severity}>
        <St.SeverityText>{translateSeverity(severity)}</St.SeverityText>
        <span>
          <b>{ut(Slugs.created)}:</b>{' '}
          {format(ticket.createdAt, DateFormat.MonthDateAndYear)}
        </span>
      </St.StatusBar>

      <St.CardContent>
        <div>
          <St.TicketTitle>{title}</St.TicketTitle>
          <St.TicketDescription>{ticket.description}</St.TicketDescription>
        </div>
      </St.CardContent>
    </St.VisitTicketCard>
  )
}
