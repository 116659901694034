import { useLocation } from 'react-router-dom'

export const useIsFeatureBranchLogin = (): {
  isFeatureBranchLogin: boolean
} => {
  const location = useLocation()
  const isFeatureBranchLogin = location.pathname.includes(
    '/feature-branch-login',
  )
  return { isFeatureBranchLogin }
}
