import styled from '@emotion/styled'
import { startCase } from 'lodash/fp'
import React from 'react'

import { colors } from 'packages/styles'

import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'

import { HkCard } from './HkCard'

const St = {
  HksList: styled.div`
    margin: auto;
    max-width: 1000px;
    padding: 16px;
  `,

  Section: styled.div`
    &:not(:first-of-type) {
      margin-top: 40px;
    }
  `,

  SectionTitle: styled.div`
    color: ${colors.dusk};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  `,
}

export type HksListProps = {
  agencyHousekeepers?: Housekeeper[]
  borrowedHousekeepers?: Housekeeper[]
  housekeepers?: Housekeeper[]
  loadingItemIds?: string[]
  onRemoveBorrowedZoneFromHk: (hk: Housekeeper) => void
  onRemoveHkFromZone: (item: Housekeeper) => void
}

export const HksList: React.FC<HksListProps> = React.memo(
  ({
    agencyHousekeepers = [],
    borrowedHousekeepers = [],
    housekeepers = [],
    loadingItemIds = [],
    onRemoveBorrowedZoneFromHk,
    onRemoveHkFromZone,
  }) => {
    const { t } = useI18n()
    const { zone } = useZoneContext()

    const hasBorrowedHks = !!borrowedHousekeepers.length
    const hasAgencyHks = !!agencyHousekeepers.length

    const onRemoveAgency = (hk: Housekeeper) => {
      hk.zone?.id === zone.id
        ? onRemoveHkFromZone(hk)
        : onRemoveBorrowedZoneFromHk(hk)
    }

    return (
      <St.HksList>
        {hasBorrowedHks && (
          <St.Section>
            <St.SectionTitle>{startCase(t(Slugs.borrowed))}</St.SectionTitle>

            {borrowedHousekeepers.map(hk => (
              <HkCard
                isLoading={loadingItemIds.includes(hk.id)}
                key={hk.id}
                hk={hk}
                removeHk={onRemoveBorrowedZoneFromHk}
              />
            ))}
          </St.Section>
        )}

        <St.Section>
          <St.SectionTitle>
            {startCase(t(Slugs.myHousekeepers))}
          </St.SectionTitle>

          {housekeepers.map(hk => (
            <HkCard
              isLoading={loadingItemIds.includes(hk.id)}
              key={hk.id}
              hk={hk}
              removeHk={onRemoveHkFromZone}
            />
          ))}
        </St.Section>

        {hasAgencyHks && (
          <St.Section>
            <St.SectionTitle>
              {startCase(t(Slugs.contractorAgency))}
            </St.SectionTitle>

            {agencyHousekeepers.map(hk => (
              <HkCard
                isLoading={loadingItemIds.includes(hk.id)}
                key={hk.id}
                hk={hk}
                removeHk={onRemoveAgency}
              />
            ))}
          </St.Section>
        )}
      </St.HksList>
    )
  },
)
