import styled from '@emotion/styled'
import React from 'react'

import { Button } from 'packages/common'
import { cleanIsB2b } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'
import { createDateObject } from 'packages/utils/dateHelpers'

import { Slugs } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans'

import { SharedEditSectionStyles } from '../CleanDrawer.styles'
import { CleanDateInfo } from './CleanDateInfo'
import { CleanInfoEditorContainer } from './CleanInfoEditor/CleanInfoEditor.container'

const getCleanDateInfoPadding = (
  dateIsEditable: boolean,
  isEditing: boolean,
) => {
  if (isEditing) return '24px 32px 24px 32px;'
  if (dateIsEditable) return '24px 8px 0px 8px;'
  return '24px 8px 8px 8px;'
}

export const St = {
  CleanDateInfo: styled(CleanDateInfo)<{
    dateIsEditable: boolean
    isEditing: boolean
  }>`
    grid-row-gap: 4px;

    border-bottom: ${({ isEditing }) =>
      isEditing ? `1px solid ${colors.midnight10}` : 0};
    border-top: ${({ isEditing }) =>
      isEditing ? `1px solid ${colors.midnight10}` : 0};
    padding: ${({ dateIsEditable, isEditing }) =>
      getCleanDateInfoPadding(dateIsEditable, isEditing)};
    background: ${({ isEditing }) =>
      isEditing ? colors.midnight2 : 'inherit'};
    margin: ${({ isEditing }) => (isEditing ? '24px -24px' : 'inherit')};
    width: ${({ isEditing }) => (isEditing ? 'auto' : '100%')};
  `,

  EditButtonContainer: SharedEditSectionStyles.EditButtonContainer,
}

export enum CleanInfoTestIds {
  edit = 'CleanInfo__edit',
}

export type CleanInfoProps = {
  clean: Clean
  cleanIsEditable: boolean
  hasFetchedNextReservation: boolean
}

export const CleanInfo: React.FC<CleanInfoProps> = React.memo(
  ({ clean, cleanIsEditable, hasFetchedNextReservation }) => {
    const [isEditing, setIsEditing] = React.useState(false)

    const { t } = useI18n()

    const effectiveDate = React.useMemo(
      () => createDateObject(clean?.effectiveDate),
      [clean.effectiveDate],
    )

    /** If there is data that is editable in this section, this is true, otherwise it is false */
    const dateInfoIsEditable = React.useMemo(() => {
      if (!cleanIsEditable) return false
      if (cleanIsB2b(clean) && clean.startedAt) return false
      return true
    }, [clean, cleanIsEditable])

    return (
      <>
        <St.CleanDateInfo
          clean={clean}
          date={effectiveDate}
          dateIsEditable={dateInfoIsEditable}
          isEditing={isEditing}
          isLoadingNextReservation={!hasFetchedNextReservation}
          omitSections={isEditing ? ['due', 'extraCleaningDays'] : []}
        />
        {isEditing ? (
          <CleanInfoEditorContainer
            clean={clean}
            onCancelEdit={() => setIsEditing(false)}
          />
        ) : (
          <>
            {dateInfoIsEditable && (
              <St.EditButtonContainer>
                <Button
                  dataTestId={CleanInfoTestIds.edit}
                  onClick={() => setIsEditing(true)}
                  buttonType={'text'}
                >
                  {t(Slugs.editClean)}
                </Button>
              </St.EditButtonContainer>
            )}
          </>
        )}
      </>
    )
  },
)
