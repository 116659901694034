import React from 'react'

import { useCurrentCardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import {
  Reservation,
  ReservationBookingType,
} from 'app/hkhub/store/reservations/reservations.types'

import {
  shouldShowBookingTypeText,
  getReservationDetailUrl,
  getReservationBookingType,
  getReservationSectionType,
} from './ResBar.helpers'
import { St } from './ResBar.styles'

const bookingTypeToString = {
  [ReservationBookingType.GUEST]: 'guest',
  [ReservationBookingType.OWNER]: 'owner',
  [ReservationBookingType.UNKNOWN]: 'unknown',
  [ReservationBookingType.VACASA]: 'vacasa',
}

export enum ResBarTestIds {
  end = 'ResBar__end',
  start = 'ResBar__start',
}

export type ResBarProps = {
  date: Date
  reservation: Reservation
}

export const ResBar: React.FC<ResBarProps> = ({ date, reservation }) => {
  const sectionType = getReservationSectionType(reservation, date)
  const bookingType = getReservationBookingType(reservation)
  const resDetailUrl = getReservationDetailUrl(reservation.id)

  const size = useCurrentCardSize()

  if (!sectionType) return null

  return (
    <St.Wrapper sectionType={sectionType}>
      {sectionType === 'start' && (
        <St.ResStart data-testid={ResBarTestIds.start} />
      )}

      <St.ResBar size={size}>
        {shouldShowBookingTypeText(reservation, date) && (
          <St.ResLink
            href={resDetailUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {bookingTypeToString[bookingType]}
          </St.ResLink>
        )}
      </St.ResBar>

      {sectionType === 'end' && <St.ResEnd data-testid={ResBarTestIds.end} />}
    </St.Wrapper>
  )
}
