import styled from '@emotion/styled'
import React from 'react'

import { Card } from 'packages/common'

import { Zone } from 'app/hkhub/store/zones/zones.types'

const St = {
  ZoneCard: styled(Card)`
    cursor: pointer;
    padding: 8px 16px;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  `,
}

export type ZoneCardProps = {
  onClick: () => void
  zone: Zone
}

export const ZoneCard: React.FC<ZoneCardProps> = React.memo(
  ({ onClick, zone }) => {
    return (
      <St.ZoneCard onClick={onClick}>
        <div>
          <strong>Zone Name: </strong>
          {zone.name}
        </div>
      </St.ZoneCard>
    )
  },
)
