import { map, pipe, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { DateFormat, format } from 'packages/utils/dateHelpers'

import {
  UnassignedHk,
  UnknownHk,
} from 'app/hkhub/store/housekeepers/housekeepers.utils'
import { ApplicationState } from 'app/hkhub/store/store'

import { Clean, HkDateCleanBuckets } from '../cleans.types'
import { getDateRangeMap, hydrateRawClean } from '../cleans.utils'

/**
 * Buckets cleans within the specified date range from the store into hk-date-buckets.
 * Unassigned cleans will be bucketed by our "magic unassigned" HK record,
 * so they will simply be keyed by 'unassigned' instead of an actual HK ID.
 *
 * The final result set will look something like:
 * {
 *   'unassigned': {
 *     '2021-07-15': [...unassigned cleans for this date]
 *     '2021-07-16': [...unassigned cleans for this date]
 *   },
 *   [hk1.id]: {
 *     '2021-07-15': [...cleans for this HK/Date combo]
 *     '2021-07-16': [...cleans for this HK/Date combo]
 *   },
 *   [hk2.id]: {
 *     '2021-07-15': [...cleans for this HK/Date combo]
 *     '2021-07-17': [...cleans for this HK/Date combo]
 *   },
 *   ...and so on for all HKs and Cleans
 * }
 */
export const getCleanBucketsByHkAndDate = createSelector(
  (state: ApplicationState) => state.cleans,
  (state: ApplicationState) => state.units,
  (state: ApplicationState) => state.housekeepers,
  (state: ApplicationState) => state.taskPhotos,
  (_, dateRange: Date[]) => dateRange,
  (
    cleansState,
    unitsState,
    hksState,
    taskPhotosState,
    dateRange,
  ): HkDateCleanBuckets => {
    const dateRangeMap = getDateRangeMap(dateRange)
    const hydrator = rawClean =>
      hydrateRawClean(
        cleansState,
        unitsState,
        hksState,
        taskPhotosState,
        rawClean,
      )

    const rawCleans = cleansState.data
    const cleans: Clean[] = pipe(values, map(hydrator))(rawCleans)

    return cleans.reduce((acc, clean) => {
      const date = format(clean.effectiveDate, DateFormat.ApiUtcShort)
      if (date in dateRangeMap) {
        // for unassigned cleans, use the "magic unassigned" HK for bucketing
        const assignedHks = clean.assignedHks.length
          ? clean.assignedHks
          : [UnassignedHk]

        assignedHks.forEach(_hk => {
          const hk = _hk?.id ? _hk : UnknownHk
          const hkId = hk.id

          // ensure we have a bucket at both levels
          if (!acc[hkId]) acc[hkId] = {}
          if (!acc[hkId][date]) acc[hkId][date] = []

          acc[hkId][date].push(clean)
        })
      }

      return acc
    }, {})
  },
)
