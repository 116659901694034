import React from 'react'

import { useDeepCleanUpNextConfig } from 'app/hkhub/components/schedule/helpers/deepCleans.helpers'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Unit } from 'app/hkhub/store/units'

import { DeepCleansSection } from './DeepCleansSection'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    lastCompleted: t(Slugs.lastCompleted),
    noActivity: t(Slugs.noActivity),
    title: t(Slugs.deepCleans),
    upNext: t(Slugs.upNext),
  }
}

export type DeepCleansStrings = ReturnType<typeof useTranslations>

export type DeepCleansSectionContainerProps = {
  unit: Unit
}

export const DeepCleansSectionContainer: React.FC<DeepCleansSectionContainerProps> =
  React.memo(({ unit }) => {
    const strings = useTranslations()
    const upNextConfig = useDeepCleanUpNextConfig(unit.nextDeepCleanDueAt)

    return (
      <DeepCleansSection
        strings={strings}
        unit={unit}
        upNextConfig={upNextConfig}
      />
    )
  })
