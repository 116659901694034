import { UnitRealtimeStatus } from 'packages/grimoire'

import { Slugs } from 'app/hkhub/i18n'

export const unitStatusTextMap: Record<UnitRealtimeStatus, string> = {
  [UnitRealtimeStatus.CLEAN_COMPLETED]: Slugs.clean,
  [UnitRealtimeStatus.CLEAN_IN_PROGRESS_OVERDUE]: Slugs.cleanStatusOverdue,
  [UnitRealtimeStatus.CLEAN_IN_PROGRESS]: Slugs.cleanStatusInProgress,
  [UnitRealtimeStatus.CLEAN_READY_OVERDUE]: Slugs.cleanStatusOverdue,
  [UnitRealtimeStatus.CLEAN_READY]: Slugs.cleanStatusReady,
  [UnitRealtimeStatus.OCCUPIED_GUEST]: Slugs.guestStay,
  [UnitRealtimeStatus.OWNER_HOLD]: Slugs.ownerHold,
  [UnitRealtimeStatus.UNKNOWN]: Slugs.unknown,
  [UnitRealtimeStatus.VACANT_NO_VACASA_CLEAN]: Slugs.vacant,
  [UnitRealtimeStatus.VACASA_HOLD]: Slugs.vacasaHold,
}
