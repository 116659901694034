import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Drawer } from 'packages/common'
import { fullName } from 'packages/grimoire'

import { Zone } from 'app/hkhub/store/zones/zones.types'

const St = {
  AdminZoneDisplayDrawer: styled(Drawer)`
    min-width: 320px;
  `,

  Prompt: styled.div<{ isVisible: boolean }>`
    font-weight: 600;
    margin-bottom: 8px;
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  `,
  Section: styled.div`
    padding: 24px;
  `,

  ZoneName: styled.div`
    font-size: 18px;
    font-weight: bold;
  `,
}

export type AdminZoneDisplayDrawerProps = {
  afterDrawerExit: () => void
  forceClose: boolean
  isOpen: boolean
  onDeleteZone: () => Promise<void>
  zone: Zone | null
}

export const AdminZoneDisplayDrawer: React.FC<AdminZoneDisplayDrawerProps> =
  React.memo(({ afterDrawerExit, onDeleteZone, forceClose, isOpen, zone }) => {
    const [deleteZoneState, setDeleteZoneState] = React.useState<
      'idle' | 'confirming' | 'deleting'
    >('idle')

    const handleAfterDrawerExit = React.useCallback(() => {
      afterDrawerExit()
      setDeleteZoneState('idle')
    }, [afterDrawerExit])

    const initiateDeleteZone = React.useCallback(
      () => setDeleteZoneState('confirming'),
      [],
    )

    const confirmDeleteZone = React.useCallback(async () => {
      setDeleteZoneState('deleting')
      try {
        await onDeleteZone()
        setDeleteZoneState('idle')
      } catch (e) {
        setDeleteZoneState('confirming')
      }
    }, [onDeleteZone])

    return (
      <St.AdminZoneDisplayDrawer
        afterExit={handleAfterDrawerExit}
        forceClose={forceClose}
        isOpen={isOpen}
      >
        {zone && (
          <>
            <St.Section>
              <St.ZoneName>{zone.name}</St.ZoneName>
              {zone.managers?.map(user => (
                <div key={user.id}>{fullName(user)}</div>
              ))}
              <Link to={`/zone/${zone.id}`}>Go to Zone in Hub</Link>
            </St.Section>

            <St.Section>
              <St.Prompt isVisible={deleteZoneState === 'confirming'}>
                Are you sure you want to delete {zone.name}?
              </St.Prompt>
              {deleteZoneState !== 'idle' ? (
                <Button
                  block={true}
                  isLoading={deleteZoneState === 'deleting'}
                  onClick={confirmDeleteZone}
                  buttonType={'danger'}
                >
                  Delete Zone
                </Button>
              ) : (
                <Button
                  block={true}
                  onClick={initiateDeleteZone}
                  buttonType={'primary'}
                >
                  Delete this zone
                </Button>
              )}
            </St.Section>
          </>
        )}
      </St.AdminZoneDisplayDrawer>
    )
  })
