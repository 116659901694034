import {
  addDays,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
} from 'packages/utils/dateHelpers'

import {
  Reservation,
  ReservationBookingType,
} from 'app/hkhub/store/reservations/reservations.types'

export const shouldShowBookingTypeText = (
  res: Reservation,
  date: Date,
): boolean => {
  // Note: since this line only checks for 0/Sunday, this logic only technically works in views that being with Sunday (e.g. 7-day)
  if (getDay(date) === 0) return true // is Sunday (first day of view)
  if (isSameDay(date, res.checkIn)) return true // is check-in date

  return false
}

export const getReservationDetailUrl = (reservationId: string): string => {
  const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL
  const RES_DETAIL_URL = `${ADMIN_BASE_URL}/admin/dashboard/reservation`

  return `${RES_DETAIL_URL}/${reservationId}`
}

const isSingleDayReservation = (res: Reservation, date: Date): boolean =>
  isSameDay(date, res.checkIn) && isSameDay(date, res.checkOut)

export type ReservationSectionType = 'start' | 'middle' | 'end'

export const getReservationSectionType = (
  res: Reservation,
  date: Date,
): ReservationSectionType | null => {
  const { checkIn, checkOut } = res

  if (isSingleDayReservation(res, date)) {
    return 'middle'
  }

  if (isSameDay(date, checkIn)) return 'start'
  if (isSameDay(date, checkOut)) return 'end'

  // at this point, ignore this date if it completely outside the res dates range
  // this ensures that this bar type does not mistakenly render for ECD reservations
  if (isBefore(date, checkIn) || isAfter(date, checkOut)) return null

  return 'middle'
}

type WithBookingType = Pick<Reservation, 'bookingType'>

export const getReservationBookingType = (
  reservation: WithBookingType,
): ReservationBookingType => {
  const type = reservation.bookingType

  if (type === 'guest_reservation') return ReservationBookingType.GUEST
  if (type === 'owner_hold') return ReservationBookingType.OWNER
  if (type === 'vacasa_hold') return ReservationBookingType.VACASA

  // This should never happen, but if it does, we can gracefully handle it with "unknown" in the UI
  return ReservationBookingType.UNKNOWN
}

/**************************************************
 * Extra Cleaning Days Logic
 **************************************************/
export const getEcdSectionType = (
  res: Reservation,
  date: Date,
): ReservationSectionType | null => {
  const { checkOut, extraCleaningDays } = res
  if (!extraCleaningDays) return null

  const ecdEnd = addDays(checkOut, extraCleaningDays)
  if (isSameDay(date, ecdEnd)) return 'end'
  if (isSameDay(date, checkOut)) return 'start'
  if (isAfter(date, res.checkOut)) return 'middle'

  return null
}

export const shouldShowEcdText = (res: Reservation, date: Date): boolean => {
  // Note: since this line only checks for 0/Sunday, this logic only technically works in views that being with Sunday (e.g. 7-day)
  if (getDay(date) === 0) return true // is Sunday (first day of view)
  if (isSameDay(date, res.checkOut)) return true // is check-out date

  return false
}
