import classNames from 'classnames'
import * as React from 'react'
import { createPortal } from 'react-dom'

import { IconName, SvgIcon } from 'packages/iconic'

import { Button } from '../../Button'
import { Loader } from '../../Loader'
import { ModalBackdropTestIds } from '../../modals/components'
import { useModal } from './hooks'

import styles from './SuperModal.module.scss'

const noop = () => void 0

export type SuperModalPositionTypes = {
  bottom?: string
  left?: string
  right?: string
  top?: string
}

export const TEST_ID_SUPERMODAL_CLOSE_BTN = 'superModal__closeBtn'
const DEFAULT_TRANSITION_SPEED = 200
const DEFAULT_WIDTH = 356
const DEFAULT_DISPLAY_POSITION = { top: 0 }

export type SuperModalProps = {
  /** (Optional) Class name to apply to the backdrop */
  backdropClassName?: string
  /**
   * (Optional) Should the Modal be horizontally displayed on the screen (margin: auto for "real" modals)
   */
  centerHorizontally?: boolean
  /**
   * React children to render into the SuperModal
   */
  children: React.ReactChild
  /**
   * (Optional) Adds a class
   */
  className?: string
  /** (Optional) Change style of close button */
  closeButtonStyleOverride?: string
  /**
   * (Optional) Whether the SuperModal should close itself if an outside click occurs (e.g. clicking the backdrop)
   * **Default: true**
   */
  closeOnOutsideClick?: boolean
  /** (Optional) String to apply to root element as "data-testid" */
  dataTestId?: string
  /**
   * (Optional) Should there be a backdrop?
   */
  displayBackdrop?: boolean
  /**
   * Position that determines where the component will display on the screen after transitioning in
   */
  displayPosition: SuperModalPositionTypes
  /**
   * (Optional) Should the component fill the screen horizontally
   */
  fillXAxis?: boolean
  /**
   * (Optional) Should the component fill the screen vertically
   */
  fillYAxis?: boolean
  /** (Optional) Set width to 100vw*/
  fullVW?: boolean
  /**
   * Position that determines where the component starts/ends before transitioning in or out
   */
  hiddenPosition: SuperModalPositionTypes
  /** (Optional) Determines weather or not to display the close button the modal */
  hideCloseButton?: boolean
  /**
   * A ref to a child component which should be auto-focused when the modal is opened.
   */
  initialFocusRef?: React.MutableRefObject<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  /**
   * Whether the SuperModal should render itself in the DOM.
   * Note that its transitions are handled internally, and this prop will determine if it will _anything at all_.
   * Generally speaking, this should remain true until the parent receives a call to the `onClose` callback,
   * as that is the point when the SuperModal has finished transitioning out, and is safe to remove from the DOM.
   */
  isOpen: boolean
  /**
   * (Optional) Whether the SuperModal should show a Loader.
   * Note that the SuperModal will not close while a Loader is showing, so you will need to be sure
   * to flip this prop back to false when the loading operation is complete.
   */
  loading?: boolean
  /**
   * (Optional) Max height of the component (fillYAxis can override this)
   */
  maxHeight?: number
  /**
   * (Optional) Max Width of the component (fillXAxis can override this)
   */
  maxWidth?: string
  /** Gives the modal a min width */
  minWidth?: string
  /**
   * Callback for cleaning up after the SuperModal has closed.
   * You will most likely want to have the parent component toggle its internal SuperModal state to remove it from the DOM.
   */
  onClose?: () => void
  /**
   * (Optional) Should we prevent the user from closing via the X in the corner?
   * Note that in order to prevent the user from manually closing, the backdrop,
   * or `closeOnOutsideClick` needs to be disabled
   */
  pinned?: boolean
  /**
   * (Optional) Tell the SuperModal to begin closing itself. This allows the parent to trigger a "graceful" close.
   * TL;DR: don't set this to true until you ready to get rid of the SuperModal.
   */
  readyToClose?: boolean
  /**
   * Specify an optional element into which the SuperModal's portal should be rendered.
   * By default, this will render directly into the bottom of `document.body`, and in almost all cases,
   * this is the behavior we will want. An example where specifying a custom render container could be useful,
   * for example, is for testing.
   */
  renderContainer?: Element
  /**
   * Title to display at the top of the SuperModal.
   * Note that this is optional, but strongly recommended for ARIA/A11y purposes.
   */
  title?: string
  /**
   * (Optional) The speed (in ms) at which the SuperModal will transition in and out of the DOM.
   * **Default: 200**
   */
  transitionSpeed?: number
}

/**
 * A SuperModal component with modal/dialog behavior. Use this when you want to show something in a
 * temporary overlay on top of other content.
 *
 * This builds in traditional modal behavior as much as possible including accessibility features, like:
 * - ESC key to close
 * - Focus trapping for tab/focus management
 * - On open, auto-focusing the specified element
 */
export const SuperModal: React.FC<SuperModalProps> = React.memo(
  ({
    backdropClassName,
    centerHorizontally = false,
    children,
    className,
    closeButtonStyleOverride,
    closeOnOutsideClick = true,
    dataTestId,
    displayBackdrop = true,
    displayPosition = DEFAULT_DISPLAY_POSITION,
    fillXAxis = false,
    fillYAxis = true,
    fullVW = false,
    hiddenPosition,
    hideCloseButton = false,
    initialFocusRef,
    isOpen,
    loading = false,
    maxHeight = '100%',
    maxWidth = DEFAULT_WIDTH,
    minWidth,
    onClose = noop,
    pinned = false,
    readyToClose = false,
    renderContainer,
    title,
    transitionSpeed = DEFAULT_TRANSITION_SPEED,
  }) => {
    // internal state for whether the SuperModal is showing
    // this is different than the `isOpen` prop, as this is used primarily
    // for conditional styling, while `isOpen` determines what will actually be rendered
    const [showing, setShowing] = React.useState(false)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rootEl = React.useRef<any>()

    const superModalStyles = React.useMemo((): React.CSSProperties => {
      const styles = showing
        ? displayPosition
        : // We need displayPosition here to handle static placement on the modal's non-transitioning axis
          { ...displayPosition, ...hiddenPosition, opacity: 0 }

      if (fillXAxis) {
        styles['width'] = '100%'
      } else if (fullVW) {
        styles['width'] = '100vw'
      } else if (maxWidth) {
        styles['maxWidth'] = maxWidth
      }

      if (fillYAxis) {
        styles['height'] = '100vh'
      } else if (maxHeight) {
        styles['maxHeight'] = maxHeight
      }

      if (centerHorizontally) {
        styles['left'] = 0
        styles['right'] = 0
        styles['margin'] = 'auto'
      }

      if (minWidth) {
        styles['minWidth'] = minWidth
      }

      return {
        transition: `all ${transitionSpeed}ms ease`,
        ...styles,
      }
    }, [
      centerHorizontally,
      displayPosition,
      fillXAxis,
      fillYAxis,
      fullVW,
      hiddenPosition,
      maxHeight,
      maxWidth,
      minWidth,
      showing,
      transitionSpeed,
    ])

    const backdropStyles = React.useMemo((): React.CSSProperties => {
      return {
        opacity: showing ? 1 : 0,
        transition: `all ${transitionSpeed}ms ease`,
      }
    }, [showing, transitionSpeed])

    /**
     * Callback for closing the SuperModal. This will trigger the CSS animation out,
     * and call the parent's `onClose` callback after `transitionSpeed` time.
     */
    const beginClose = React.useCallback(() => {
      if (loading) return

      setShowing(false)
      setTimeout(onClose, transitionSpeed)
    }, [loading, onClose, transitionSpeed])

    /**
     * Effect handler for 'isOpen' prop, this is primarily used to initially open
     * the SuperModal, since closing (i.e. transitioning out) is handled internally.
     */
    React.useEffect(() => {
      setShowing(isOpen)
    }, [isOpen])

    React.useEffect(() => {
      if (readyToClose) {
        beginClose()
      }
    }, [beginClose, readyToClose])

    useModal({
      blockEsc: pinned,
      closeModal: beginClose,
      initialFocusRef,
      isActive: showing && !loading,
      rootRef: rootEl,
    })

    const showCloseButton = React.useMemo(
      () => !pinned && !hideCloseButton,
      [hideCloseButton, pinned],
    )

    return isOpen
      ? createPortal(
          <>
            <div
              aria-label={title && `${title} Dialog`}
              className={classNames(styles.superModal, className, {
                [styles.hidden]: !showing,
              })}
              data-testid={dataTestId}
              id={dataTestId}
              ref={rootEl}
              role="dialog"
              style={superModalStyles}
            >
              {showCloseButton && (
                <div
                  className={classNames(
                    closeButtonStyleOverride,
                    styles.closeIconWrapper,
                  )}
                  data-testid={TEST_ID_SUPERMODAL_CLOSE_BTN}
                >
                  <Button
                    ariaLabel={title && `Close ${title} Dialog`}
                    buttonType={'text'}
                    onClick={beginClose}
                  >
                    <SvgIcon
                      className={styles.closeIcon}
                      icon={IconName.x}
                      size={24}
                    />
                  </Button>
                </div>
              )}

              <div className={styles.superModalContent}>
                {title && <div className={styles.superModalTitle}>{title}</div>}
                {children}
              </div>

              {loading && <Loader />}
            </div>

            {displayBackdrop && !pinned && (
              <div
                className={classNames(
                  styles.superModalBackdrop,
                  backdropClassName,
                )}
                id={ModalBackdropTestIds.container}
                onClick={closeOnOutsideClick ? beginClose : undefined}
                style={backdropStyles}
              />
            )}
          </>,
          renderContainer || document.body,
        )
      : null
  },
)
