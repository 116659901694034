/**********************************************************
 * INSPECTION FLAG
 *********************************************************/
export type AllInspectionFlagAttributes = {
  categoryId: string
  deletedAt: string
  itemId: string
  notes: string
}

export const AllInspectionFlagAttributeNames = [
  'categoryId',
  'deletedAt',
  'itemId',
  'notes',
]
