import { produce } from 'immer'
import { difference } from 'lodash/fp'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { AlertType } from 'packages/common'

export type NewAlertConfig = {
  alertType: AlertType
  children: React.ReactNode
}

export type AlertConfig = NewAlertConfig & {
  id: string
}

export type UseAdminAlerts = {
  addAlert: (alertConfig: NewAlertConfig) => void
  alerts: AlertConfig[]
  removeAlert: (alert: AlertConfig) => void
}

export const useAdminAlerts = (): UseAdminAlerts => {
  const [alerts, setAlerts] = React.useState<AlertConfig[]>([])

  const addAlert = React.useCallback((alertConfig: NewAlertConfig) => {
    setAlerts(prev =>
      produce(prev, draft => {
        draft.push({
          ...alertConfig,
          id: uuid(),
        })
      }),
    )
  }, [])

  const removeAlert = React.useCallback((alert: AlertConfig) => {
    setAlerts(prev => difference(prev, [alert]))
  }, [])

  return {
    addAlert,
    alerts,
    removeAlert,
  }
}
