import styled from '@emotion/styled'
import React from 'react'

import { Unit } from 'app/hkhub/store/units/units.types'

import { UnitCard } from './UnitCard'

const St = {
  UnitsList: styled.div`
    margin: auto;
    max-width: 1000px;
    padding: 16px;
  `,
}

export type UnitsListProps = {
  loadingItemIds?: string[]
  removeUnit: (unit: Unit) => void
  units: Unit[]
}

export const UnitsList: React.FC<UnitsListProps> = React.memo(
  ({ loadingItemIds = [], removeUnit, units = [] }) => {
    return (
      <St.UnitsList>
        {units.map(unit => (
          <UnitCard
            isLoading={loadingItemIds.includes(unit.id)}
            key={unit.id}
            removeUnit={removeUnit}
            unit={unit}
          />
        ))}
      </St.UnitsList>
    )
  },
)
