import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { ActionType, getType } from 'typesafe-actions'

import { fetchUnitByIdStrict } from '../units/actions'
import {
  fetchVisitByIdAction,
  fetchVisitsByZoneAndDateAction,
} from '../visits/actions'
import {
  fetchIncompleteTicketsByZoneAction,
  fetchTicketByIdAction,
  fetchTicketsByZoneAndDateAction,
} from './actions'
import { TicketsState } from './tickets.types'

const actions = {
  fetchIncompleteTicketsByZoneAction,
  fetchTicketByIdAction,
  fetchTicketsByZoneAndDateAction,
  fetchVisitByIdAction,
  fetchVisitsByZoneAndDateAction,
}

type TicketsActionsTypes = ActionType<typeof actions>

export const initialState: TicketsState = {
  byUnitIncompleteTicketsHash: {},
  data: {},
}

export const ticketsReducer = (
  state = initialState,
  action: TicketsActionsTypes,
): TicketsState =>
  produce(state, (draft: TicketsState) => {
    switch (action.type) {
      case getType(fetchTicketByIdAction.success):
      case getType(fetchTicketsByZoneAndDateAction.success):
      case fetchUnitByIdStrict.fulfilled.toString():
      case getType(fetchVisitByIdAction.success): {
        const ticketsData = action.payload.normalized?.ticket || {}

        if (ticketsData) {
          Object.values(ticketsData).forEach(rawTicket => {
            draft.data[rawTicket.id] = merge(
              state.data[rawTicket.id] ?? {},
              rawTicket,
            )
          })
        }

        return
      }

      case getType(fetchIncompleteTicketsByZoneAction.success): {
        const ticketsData = action.payload.normalized?.ticket

        /*
          Since we refetch whenever the view changes from staff to unit and vice versa, we need to ensure
          that there is no duplicate data here. So each pass through in this case we create a new hash map of
          ticket counts by unitId and add it to the draft.
        */
        const newMap = produce({}, mapDraft => {
          if (ticketsData) {
            Object.values(ticketsData).forEach(rawTicket => {
              const unitId = rawTicket.relationships.unit.data.id
              let existingValue = mapDraft[unitId] ?? 0

              mapDraft[unitId] = existingValue += 1
            })
          }
        })

        draft.byUnitIncompleteTicketsHash = newMap
      }
    }
  })
