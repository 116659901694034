import * as React from 'react'

import { PickAllAndConvertToOptional } from 'packages/grimoire/src/utils'

import { SuperModalProps } from '../SuperModal'
import { StyledModal } from './LegacyModal2.styles'

import styles from './LegacyModal2.module.scss'

export const DEFAULT_MAX_WIDTH = '1200px'
export const DEFAULT_DISTANCE_FROM_TOP = '40px'

const DEFAULT_DISPLAY_POSITION = { top: DEFAULT_DISTANCE_FROM_TOP }
const DEFAULT_HIDDEN_POSITION = { top: '0' }

export enum LegacyModal2TestIds {
  container = 'LegacyModal2__container',
}

/**
 * All props that Modal can accept, regardless of optionality
 */
type AllPossibleModalProps = Omit<
  SuperModalProps,
  'centerHorizontally' | 'mainAxis'
>

export type LegacyModal2Props = PickAllAndConvertToOptional<
  AllPossibleModalProps,
  'hiddenPosition' | 'displayPosition'
>

export const LegacyModal2: React.FC<LegacyModal2Props> = ({
  children,
  displayPosition = DEFAULT_DISPLAY_POSITION,
  fillXAxis = false,
  fillYAxis = false,
  hiddenPosition = DEFAULT_HIDDEN_POSITION,
  hideCloseButton,
  maxWidth = DEFAULT_MAX_WIDTH,
  ...props
}) => {
  return (
    <StyledModal
      backdropClassName={styles.modalBackdrop}
      centerHorizontally={true}
      dataTestId={LegacyModal2TestIds.container}
      displayPosition={displayPosition}
      fillXAxis={fillXAxis}
      fillYAxis={fillYAxis}
      hasCloseBtn={!hideCloseButton}
      hiddenPosition={hiddenPosition}
      hideCloseButton={hideCloseButton}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </StyledModal>
  )
}
