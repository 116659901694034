import React from 'react'

import { getAddDaysToResUrl } from 'packages/utils/links'

import { useCurrentCardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { Reservation } from 'app/hkhub/store/reservations/reservations.types'

import { getEcdSectionType, shouldShowEcdText } from './ResBar.helpers'
import { St } from './ResBar.styles'

export enum EcdBarTestIds {
  end = 'EcdBar__end',
  start = 'EcdBar__start',
}

export type EcdBarProps = {
  date: Date
  reservation: Reservation
}

/**
 * A bar component specifically for display ECD ranges for reservations in Units view.
 * This is extremely similar to the neighboring ResBar component, but they diff in logic
 * and styling enough that it made sense to make a completely separate component.
 * ECD = Extra Cleaning Days!
 */
export const EcdBar: React.FC<EcdBarProps> = ({ date, reservation }) => {
  const { extraCleaningDays = 0 } = reservation

  const size = useCurrentCardSize()
  const sectionType = getEcdSectionType(reservation, date)
  const isTextNode = shouldShowEcdText(reservation, date)

  if (!extraCleaningDays || !sectionType) return null

  return (
    <St.Wrapper sectionType={sectionType}>
      {sectionType === 'start' && (
        <St.EcdStart data-testid={EcdBarTestIds.start} />
      )}

      <St.EcdBar size={size}>
        {isTextNode && (
          <St.ResLink
            href={getAddDaysToResUrl(reservation.id)}
            rel="noopener noreferrer"
            target="_blank"
          >
            Extra Clean Days
          </St.ResLink>
        )}
      </St.EcdBar>

      {sectionType === 'end' && <St.EcdEnd data-testid={EcdBarTestIds.end} />}
    </St.Wrapper>
  )
}
