import * as React from 'react'

import {
  InnerCircle,
  OuterCircle,
  StatusCirclesContainer,
} from './StatusOrb.styles'
import { StatusOrbType } from './StatusOrb.types'

export type StatusOrbProps = {
  dataTestId?: string
  shouldAnimate: boolean
  statusType: StatusOrbType
}

export const StatusOrb: React.FC<StatusOrbProps> = React.memo(
  ({ shouldAnimate, statusType, dataTestId }) => {
    return (
      <StatusCirclesContainer data-testid={dataTestId}>
        <InnerCircle shouldAnimate={shouldAnimate} statusType={statusType} />
        <OuterCircle shouldAnimate={shouldAnimate} statusType={statusType} />
      </StatusCirclesContainer>
    )
  },
)
