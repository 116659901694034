import styled from '@emotion/styled'
import { debounce } from 'lodash'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AsyncSelector } from 'packages/common'
import { AsyncSelectorTextStyle } from 'packages/common/src/AsyncSelector/AsyncSelector'
import { layers, colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { AppDispatch } from 'app/hkhub/store/store'
import { RawUser } from 'app/hkhub/store/users'
import {
  clearZone,
  searchZones,
  selectZone,
} from 'app/hkhub/store/zones/actions'
import { getPreviouslySelectedZones } from 'app/hkhub/store/zones/selectors'
import { RawZone, Zone } from 'app/hkhub/store/zones/zones.types'

import { getHighlightedZoneSearchResults } from './ZoneSelector.helpers'

const St = {
  ZoneSelector: styled(AsyncSelector)`
    min-width: 250px;
    z-index: ${layers.zoneSelector};

    // This targets the singleValue element in the asyncSelector, when the selector is open
    div.asyncSelector__control--is-focused .asyncSelector__single-value {
      color: ${colors.midnight};
    }
  `,
}

//fix because some newer version of react-select does not have the exported ValueType type
type ValueType<OptionType> = OptionType | OptionType[] | null

type SearchZoneResult = {
  user: RawUser[]
  zone: RawZone[]
}

const buildZoneResolveObject = (result: SearchZoneResult) => {
  const zones = Object.values(result.zone)
  const users = result.user ? Object.values(result.user) : []

  const parsedUsers = users.length
    ? users.map(user => ({
        ...user.attributes,
        id: user.id,
      }))
    : []

  return zones.map(zone => {
    const managers = (zone.relationships?.managers?.data || []).map(manager => {
      const user = parsedUsers.find(user => user.id === manager.id)
      return user ? user : { id: manager.id, name: '' }
    })

    return {
      ...zone.attributes,
      id: zone.id,
      managers,
    }
  })
}

// Builds the Promise required for loading search results in the react-select "loadOptions" prop
const useZoneSearchLoader = () => {
  const dispatch: AppDispatch = useDispatch()

  // Debounce the search function
  const debouncedSearch = useMemo(
    () =>
      debounce(async (input, setResult) => {
        try {
          const result = await dispatch(searchZones(input))
          setResult(
            buildZoneResolveObject(result as unknown as SearchZoneResult),
          )
        } catch (e) {
          setResult([])
        }
      }, 300), // Set debounce delay to 300ms
    [dispatch],
  )

  return (input: string): Promise<Zone[]> =>
    new Promise(resolve => {
      debouncedSearch(input, resolve)
    })
}

export type ZoneSelectorProps = {
  className?: string // eslint-disable-line react/no-unused-prop-types
  isDisabled?: boolean // eslint-disable-line react/no-unused-prop-types
  onBlur?: () => void // eslint-disable-line react/no-unused-prop-types
  onZoneSelectionChange?: (value: ValueType<Zone>) => void // eslint-disable-line react/no-unused-prop-types
  selectedZone?: Zone // eslint-disable-line react/no-unused-prop-types
}

export const ZoneSelector = React.memo(
  React.forwardRef<HTMLInputElement, ZoneSelectorProps>(
    (
      {
        className,
        isDisabled = false,
        onBlur,
        onZoneSelectionChange,
        selectedZone,
      },
      ref,
    ) => {
      const { t } = useI18n()
      const dispatch: AppDispatch = useDispatch()
      const navigate = useNavigate()
      const zonesLoader = useZoneSearchLoader()

      // store our current zone in local state
      // this is USUALLY the same as the prop, but since the prop does not update until after the zone has fully loaded,
      // there can be a small window where this selector still shows the old zone name
      // we will always favor the prop, but in the time between selecting a new zone and when the prop updates, we will just set the zone directly internally
      const [zone, setZone] = React.useState(() => selectedZone)

      // use the prop anytime it actually updates
      React.useEffect(() => {
        setZone(selectedZone)
      }, [selectedZone])

      const handleZoneChange = React.useCallback(
        value => {
          // use the new selection as our selected zone until the updated prop has a chance to propogate
          setZone(value)

          if (value && !Array.isArray(value)) {
            dispatch(clearZone())
            dispatch(selectZone(value))
            navigate(`/zone/${(value as Zone).id}/schedule`)
          }

          if (onZoneSelectionChange) onZoneSelectionChange(value)
        },
        [dispatch, navigate, onZoneSelectionChange],
      )

      const noOptionsMessage = React.useCallback(
        ({ inputValue }) =>
          inputValue === '' ? t(Slugs.typeToSearch) : t(Slugs.noResults),
        [t],
      )

      return (
        <div data-testid="zone-selector">
          <St.ZoneSelector
            className={className}
            defaultOptions={getPreviouslySelectedZones()}
            formatOptionLabel={getHighlightedZoneSearchResults}
            getOptionValue={zone => zone.id}
            isDisabled={isDisabled}
            loadingMessage={() => t(Slugs.loading)}
            loadOptions={zonesLoader}
            onBlur={onBlur}
            noOptionsMessage={noOptionsMessage}
            onChange={handleZoneChange}
            placeholder={`${t(Slugs.select)}...`}
            ref={ref}
            textStyle={AsyncSelectorTextStyle.WHITE}
            value={zone}
          />
        </div>
      )
    },
  ),
)
