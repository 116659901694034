import { propEq, reject } from 'lodash/fp'
import * as React from 'react'
import { v4 as uuid } from 'uuid'

import { Modal } from '../../Modal'
import { ModalContext, ModalContextType, ModalConfig } from './Modal.context'

type ModalRendererConfig = ModalConfig & {
  id: string
}

export const ModalProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [modals, setModals] = React.useState<ModalRendererConfig[]>(
    [] as ModalRendererConfig[],
  )

  const showModal = React.useCallback((config: ModalConfig) => {
    const modalConfig: ModalRendererConfig = {
      ...config,
      id: uuid(),
    }

    setModals(prev => [...prev, modalConfig])
  }, [])

  /**
   * Callback for after a given modal has finished its exit transition.
   * Simply removes the Modal with matching "ID" from the list.
   */
  const handleAfterExit = React.useCallback((id: string) => {
    setModals(reject(propEq('id', id)))
  }, [])

  const contextValue = React.useMemo(
    (): ModalContextType => ({
      showModal,
    }),
    [showModal],
  )

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      {modals.map(modalConfig => (
        <Modal
          {...modalConfig}
          {...(modalConfig.modalProps ?? {})}
          afterExit={() => handleAfterExit(modalConfig.id)}
          isOpen={true}
          key={modalConfig.id}
        />
      ))}
    </ModalContext.Provider>
  )
}
