import get from 'lodash/get' // eslint-disable-line
import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from 'app/hkhub/store/store'

import { UserAttributeNames, UsersState, User } from '../users.types'

const sortByFirstName = (a, b) => (a.firstName > b.firstName ? 1 : -1)

const getUsersSearchResultsState = (state: ApplicationState): UsersState => {
  return get(state, 'users')
}

export const getUsersSearchResults = createSelector(
  getUsersSearchResultsState,
  (usersState): User[] => {
    return Object.values(usersState.searchResults.user)
      .map(
        (user): User =>
          transformNormalizedToTyped<User>(user, UserAttributeNames),
      )
      .sort(sortByFirstName)
  },
)
