import React from 'react'

import { ExternalLink } from 'packages/common'
import { Slugs, useI18n } from 'packages/i18n'
import {
  getAdminCreateTicketURL,
  getAdminViewUnitTicketsURL,
} from 'packages/utils/links'

import { St } from './UnitLinks.styles'

export enum UnitLinksTestIds {
  container = 'UnitLinks__container',
  createATicket = 'UnitLinks__createATicket',
  seeAllTickets = 'UnitLinks__seeAllTickets',
  viewHome = 'UnitLinks__viewHome',
}

type UnitLinkSection = 'seeAllTickets' | 'createATicket' | 'viewHome'

export type UnitLinksProps = {
  className?: string
  hideAdminLinks?: boolean
  omitSections?: UnitLinkSection[]
  reservationId?: string
  unitId: string
}

export const UnitLinks: React.FC<UnitLinksProps> = ({
  className,
  hideAdminLinks = false,
  omitSections = [],
  reservationId,
  unitId,
}) => {
  const { ut } = useI18n()

  const shouldRender = (section: UnitLinkSection) =>
    !omitSections.includes(section)

  return (
    <St.Container
      className={className}
      data-testid={UnitLinksTestIds.container}
    >
      {!hideAdminLinks && (
        <>
          <St.ViewTickets>
            {shouldRender('seeAllTickets') && (
              <ExternalLink
                text={ut(Slugs.viewOpenTickets)}
                url={getAdminViewUnitTicketsURL(unitId)}
              />
            )}
          </St.ViewTickets>

          <St.CreateTicket>
            {shouldRender('createATicket') && (
              <ExternalLink
                text={ut(Slugs.createTicket)}
                url={getAdminCreateTicketURL({ reservationId, unitId })}
              />
            )}
          </St.CreateTicket>
        </>
      )}
    </St.Container>
  )
}
