/* eslint-disable no-restricted-syntax */
import {
  addDays,
  createDateObject,
  startOfDay,
  subDays,
} from 'packages/utils/dateHelpers'

import { intBetween } from './numbers.utils'

const TODAY = startOfDay(createDateObject())

/** Returns a date string between 1 and {past} days into the past from today */
export const withinDaysPast = (past: number): string =>
  subDays(TODAY, intBetween(1, Math.max(past, 1) + 1)).toISOString()

/** Returns a date string between 1 and {future} days into the future from today */
export const withinDaysFuture = (future: number): string =>
  addDays(TODAY, intBetween(1, Math.max(future, 1) + 1)).toISOString()

/**
 * Returns a date string between `minimumDaysAgo` and `past` days ago
 *
 * @throws If `past` is less than `minimumDaysAgo`
 */
export const withinDaysMinimumPast = (
  minimumDaysAgo: number,
  past: number,
): string => {
  if (past < minimumDaysAgo) {
    throw new Error(
      `Past days ago (${past}) must be more than minimum days ago (${minimumDaysAgo})`,
    )
  }

  return subDays(
    subDays(TODAY, minimumDaysAgo),
    intBetween(minimumDaysAgo, Math.max(past, minimumDaysAgo) + 1),
  ).toISOString()
}

export const daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const

export type DayOfWeek = (typeof daysOfWeek)[number]

export type DayOfWeekBoolMap = Record<(typeof daysOfWeek)[number], boolean>
