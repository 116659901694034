import { NormalizedDeepCleanSkipsApiResponse } from './deepCleanSkips.types'

/**
 * Empty data in the shape of the DeepCleanSkips service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedDeepCleanSkipData = Object.freeze({
  deepCleanSkip: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyDeepCleanSkipResponse: NormalizedDeepCleanSkipsApiResponse =
  Object.freeze({
    normalized: emptyNormalizedDeepCleanSkipData,
    raw: { data: [] },
  })
