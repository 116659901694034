import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import * as React from 'react'

import {
  ContentModal,
  Alert,
  NotificationBadge,
  Tab,
  TabbedContent,
  TabTypes,
} from 'packages/common'
import { SvgIcon, IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { ZoneNotification } from 'app/hkhub/store/notifications/notifications.types'
import { Zone } from 'app/hkhub/store/zones/zones.types'

import AddHkNotificationContent from './AddHkContent/AddHkNotificationContent/AddHkNotificationContent'
import AddHkSearchContent from './AddHkContent/AddHkSearchContent/AddHkSearchContent'

import styles from './AddHkModal.module.scss'

export type AddHkModalProps = {
  /** Callback for after the Modal has completely finished exiting & hiding */
  afterClose: () => void
  /**
   * (Optional) Error message to display
   * When present, this will be displayed in an Alert
   */
  errorMessage?: string
  /** If the notifications are currently being fetched  */
  fetchingNotifications: boolean
  /** whether a search has occured and not been cleared */
  hasSearched: boolean
  /** List of Housekeepers to display in the list */
  housekeepers: Housekeeper[]
  /** Set of IDs (if any) that should display a "loading" state */
  loadingIds?: string[]
  /** List of notifications to display */
  notifications: ZoneNotification[]
  onAcceptAddNotification: (notification: ZoneNotification) => void
  /** Callback for adding an HK to this zone as their primary zone */
  onAddHksToZone: (hk: Housekeeper) => void
  /** Callback for adding an HK to this zone as a borrowed zone */
  onBorrowHksToZone: (hk: Housekeeper) => void
  /** Callback for clearing current search results */
  onClearHkSearchResults: () => void
  /** Callback for dismissing a notification */
  onDismissZoneNotification: (id: string) => void
  /** Callback for performing a housekeepers search */
  onSearchHks: (searchString: string) => void
  /** Whether there is currently a pending search operation */
  searching: boolean
  /** Whether the modal is currently active */
  showing: boolean
  /** The currently-selected Zone */
  zone: Zone
}

export const ModalContent: React.FC<AddHkModalProps> = props => {
  const {
    onAddHksToZone,
    onDismissZoneNotification,
    errorMessage,
    fetchingNotifications,
    loadingIds,
    onAcceptAddNotification,
    notifications,
    zone,
    ...passThruSearchProps
  } = props
  const { t } = useI18n()

  return (
    <div className={styles.addHkModalContent}>
      <div className={styles.headerNotes}>
        <strong>{capitalize(t(Slugs.note))}:</strong>{' '}
        {t(Slugs.newHousekeepersWillNotHaveCleansAssignedInNextThreeDays)}
      </div>
      {errorMessage && <Alert alertType={'danger'}>{errorMessage}</Alert>}
      <TabbedContent type={TabTypes.FLOATING}>
        <Tab
          title={
            <span className={styles.tabContent}>
              <span className={styles.tabText}>{capitalize(t(Slugs.new))}</span>
              {!!notifications.length && (
                <NotificationBadge count={notifications.length} />
              )}
            </span>
          }
        >
          <AddHkNotificationContent
            fetchingNotifications={fetchingNotifications}
            loadingIds={loadingIds}
            notifications={notifications}
            onAcceptAddNotification={onAcceptAddNotification}
            onDismissZoneNotification={onDismissZoneNotification}
          />
        </Tab>

        <Tab
          title={
            <span className={styles.tabContent}>
              <SvgIcon icon={IconName.search} size={16} />
              <span className={styles.tabText}>
                {t(Slugs.searchAllHousekeepers)}
              </span>
            </span>
          }
        >
          <AddHkSearchContent
            {...passThruSearchProps}
            loadingIds={loadingIds}
            onAddHksToZone={onAddHksToZone}
            zone={zone}
          />
        </Tab>
      </TabbedContent>
    </div>
  )
}

const AddHkModal: React.FC<AddHkModalProps> = props => {
  const { t } = useI18n()
  return (
    <ContentModal
      {...props}
      maxWidth={800}
      title={startCase(t(Slugs.addHousekeeper))}
    >
      <ModalContent {...props} />
    </ContentModal>
  )
}

/**
 * The full-size modal/page for searching/adding housekeepers to a zone.
 *
 * The guts of the modal content is pulled into a separate component in this file
 * so that we can easily test it without worrying about the Modal transitions getting in the way.
 *
 * The state and Redux handling is handled by the adjacent "wrapper" component. In general,
 * _you should probably not need to use this component directly_.
 */
export default React.memo(AddHkModal)
