import React from 'react'
import { useSelector } from 'react-redux'
import { VariableSizeList } from 'react-window'

import { Breakpoint } from 'packages/styles'

import {
  CardSize,
  getUnitScheduleHeight,
  measureUnitScheduleRow,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { VIRTUALIZED_SCHEDULE_CLASSNAME } from 'app/hkhub/components/schedule/schedule.types'
import { UnitCleanSchedule } from 'app/hkhub/store/cleans/cleans.types'
import { UnitReservationsBuckets } from 'app/hkhub/store/reservations/reservations.types'
import { ApplicationState } from 'app/hkhub/store/store'
import { UnitTicketSchedule } from 'app/hkhub/store/tickets'
import { getUnitById } from 'app/hkhub/store/units/selectors'
import { UnitDateVisitBuckets } from 'app/hkhub/store/visits'

import { UnitScheduleRow } from './components'

const getItemKey = (idx: number, unitIds: string[]): string | number =>
  unitIds?.length ? unitIds[idx] : idx

const listStyles: React.CSSProperties = {
  // ensure we do not get a horizontal scrollbar (schedule view must be full-width)
  overflowX: 'hidden',
}

const createRowRenderer =
  (props: VirtualizedUnitScheduleProps) =>
  ({ index, style }) => {
    const {
      breakpoint,
      isLoadingReservations,
      reservationBuckets,
      unitDateTicketMap,
      unitDateCleansMap,
      unitDateVisitMap,
      unitIds,
    } = props

    const unitId = unitIds[index]
    const unit = useSelector((state: ApplicationState) =>
      getUnitById(state.units, unitId),
    )
    const cleans = unitDateCleansMap[unitId]
    const reservations = reservationBuckets[unitId]
    const tickets = unitDateTicketMap[unitId]
    const visits = unitDateVisitMap[unitId]

    if (!unit) {
      return null
    }

    return (
      <UnitScheduleRow
        breakpoint={breakpoint}
        cleans={cleans}
        isLoadingReservations={isLoadingReservations}
        reservations={reservations}
        tickets={tickets}
        style={style}
        unit={unit}
        visits={visits}
      />
    )
  }

export type VirtualizedUnitScheduleProps = {
  breakpoint: Breakpoint
  cardSize: CardSize
  isLoadingReservations: boolean
  reservationBuckets: UnitReservationsBuckets
  unitDateCleansMap: UnitCleanSchedule
  unitDateTicketMap: UnitTicketSchedule
  unitDateVisitMap: UnitDateVisitBuckets
  unitIds: string[]
}

export const VirtualizedUnitSchedule: React.FC<VirtualizedUnitScheduleProps> =
  React.memo(props => {
    const {
      breakpoint,
      cardSize,
      unitDateTicketMap,
      unitDateCleansMap,
      unitDateVisitMap,
      unitIds,
    } = props
    const listRef = React.useRef<VariableSizeList>(null)

    const measureRow = React.useMemo(() => {
      // since we know that some key data has changed, we must
      // trigger a reset so that react-window will re-measure all rows
      listRef.current?.resetAfterIndex(0, false)
      return measureUnitScheduleRow(
        unitIds,
        {
          cleans: unitDateCleansMap,
          tickets: unitDateTicketMap,
          visits: unitDateVisitMap,
        },
        cardSize,
      )
    }, [
      cardSize,
      unitDateTicketMap,
      unitDateCleansMap,
      unitDateVisitMap,
      unitIds,
    ])

    const Row = createRowRenderer(props)

    return (
      <VariableSizeList
        className={VIRTUALIZED_SCHEDULE_CLASSNAME}
        height={getUnitScheduleHeight(breakpoint)}
        itemCount={unitIds.length}
        itemData={unitIds}
        itemKey={getItemKey}
        itemSize={measureRow}
        ref={listRef}
        style={listStyles}
        width={'100%'}
      >
        {Row}
      </VariableSizeList>
    )
  })
