/* eslint-disable @typescript-eslint/naming-convention */
import { createInstance, enums } from '@optimizely/react-sdk'
import { jwtDecode } from 'jwt-decode'

export type AuthTokenData = {
  aud: string
  email: string
  exp: number
  family_name: string
  given_name: string
  iat: number
  iss: string
  name: string
  nonce: string
  params?: {
    monolith_user_id: string
  }
  preferred_username: string
  s_hash: string
  sid: string
  sub: string
  updated_at: number
}

export const DEFAULT_USER_ID_VALUE = 'Unknown'

export function decodeUserId(token: string | undefined): string {
  if (!token) return DEFAULT_USER_ID_VALUE

  try {
    const data: AuthTokenData = jwtDecode(token)
    return data.params?.monolith_user_id || DEFAULT_USER_ID_VALUE
  } catch (err) {
    return DEFAULT_USER_ID_VALUE
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createOptimizelyInstance = (sdkKey: string) =>
  createInstance({
    datafileOptions: {
      autoUpdate: true, // default every 5 min
    },
    logLevel: enums.LOG_LEVEL.ERROR,
    sdkKey,
  })
