import styled from '@emotion/styled'

import { Card } from 'packages/common'
import { TicketSeverity, TicketStatus } from 'packages/grimoire/src/ticket'
import { SvgIcon } from 'packages/iconic'
import { colors, text, truncateTextWithEllipsis } from 'packages/styles'

import {
  CARD_MARGIN_Y,
  unitCardSizeHeightMap,
} from '../../../helpers/scheduleMeasure'
import { severityStatusColorMap } from '../../../helpers/ticketHelpers'
import {
  WithTicketSizeAndStatus,
  WithTicketSizeAndStatusNotTiny,
} from './TicketCard.types'

const GenericTicketCard = styled(Card)<WithTicketSizeAndStatus>`
  height: ${({ size }) => `${unitCardSizeHeightMap[size]}px`};

  border-radius: 2px;
  margin: ${CARD_MARGIN_Y}px;
  padding: 0;
  &:not(:first-of-type) {
    margin-top: 0;
  }
`

export const TicketCard = styled(
  GenericTicketCard,
)<WithTicketSizeAndStatusNotTiny>`
  opacity: ${props =>
    props.status !== TicketStatus.RESOLVED_DISMISSED ? 1 : 0.5};
`

export const TinyCard = styled(GenericTicketCard)<
  Omit<WithTicketSizeAndStatus, 'size'> & { severity: TicketSeverity }
>`
  ${truncateTextWithEllipsis}
  ${text.bodyBoldSmall};
  border: ${({ status }) =>
    status === TicketStatus.ACCEPTED ? `1px solid ${colors.dusk}` : 'none'};
  background: ${({ severity }) => severityStatusColorMap[severity].severity};
`

export const CardContent = styled.div`
  padding: 10px;
`

export const TicketTitle = styled.div`
  ${text.bodyBoldSmall};
  ${truncateTextWithEllipsis};
`

export const Assignee = styled.div`
  align-items: center;
  display: flex;
  ${truncateTextWithEllipsis};
`

export const AssigneeName = styled.span`
  ${text.bodyRegularSmall};
  ${truncateTextWithEllipsis};
  margin-left: 6px;
`

export const TagIcon = styled(SvgIcon)`
  display: inline;
  margin: 0 4px;
`
