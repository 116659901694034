import { Slugs } from 'packages/i18n'

import {
  ConfirmModalHookProps,
  useConfirmModal,
  UseConfirmModalValue,
} from '../useConfirmModal'

export const useCompleteModal = ({
  slugs,
  ...rest
}: ConfirmModalHookProps): UseConfirmModalValue =>
  useConfirmModal({
    ...rest,
    slugs: {
      ...slugs,
      cancel: Slugs.notYet,
      confirm: Slugs.completed,
    },
  })
