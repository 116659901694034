import React from 'react'

import { CardSize } from 'app/hkhub/components/schedule/helpers/scheduleMeasure'
import { useScheduleMatchParams } from 'app/hkhub/components/schedule/hooks'
import { Unit } from 'app/hkhub/store/units'
import { Visit } from 'app/hkhub/store/visits'

import { VisitCardStaffViewLabel } from './VisitCardStaffViewLabel'
import { VisitCardUnitViewLabel } from './VisitCardUnitViewLabel'

export type VisitCardLabelProps = {
  size: CardSize
  unit?: Unit
  visit: Visit
}

export const VisitCardLabel: React.FC<VisitCardLabelProps> = ({
  size,
  unit,
  visit,
}) => {
  const { entity } = useScheduleMatchParams()
  return entity === 'unit' ? (
    <VisitCardUnitViewLabel visit={visit} size={size} />
  ) : (
    <VisitCardStaffViewLabel visit={visit} unit={unit} size={size} />
  )
}
