import { AxiosResponse } from 'axios'
import { difference, snakeCase } from 'lodash/fp'

import {
  AllTaskPhotoAttributeNames,
  AllTaskPhotoAttributes,
} from 'packages/grimoire/src/taskPhoto'
import {
  JSONApiObjectWithRelationships,
  JSONApiObjectWithRelationshipsMap,
  NormalizedJSONApiResponse,
  ToOneRelationship,
} from 'packages/grimoire/src/utils'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/

export type TaskPhotosResponse = {
  taskPhoto: JsonApiTaskPhotosMap
}

export type NormalizedTaskPhotosApiResponse =
  NormalizedJSONApiResponse<TaskPhotosResponse>

export type TaskPhotosApiResponse =
  AxiosResponse<NormalizedTaskPhotosApiResponse>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export type TaskPhotosState = {
  data: JsonApiTaskPhotosMap
}

/**********************************************************
 * TASK PHOTO
 *********************************************************/
export type TaskPhoto = {
  id: string
} & TaskPhotoAttributes

export type TaskPhotoAttributes = Omit<AllTaskPhotoAttributes, 'uploadConfig'>

export const TaskPhotoAttributeNames = difference(AllTaskPhotoAttributeNames)([
  'uploadConfig',
])

export const TaskPhotoApiFields = TaskPhotoAttributeNames.map(snakeCase)

export type TaskPhotoRelationships = {
  clean: ToOneRelationship
  uploadedBy: ToOneRelationship
}

export type RawTaskPhoto = JSONApiObjectWithRelationships<
  TaskPhotoAttributes,
  TaskPhotoRelationships
>

export type JsonApiTaskPhotosMap = JSONApiObjectWithRelationshipsMap<
  TaskPhotoAttributes,
  TaskPhotoRelationships
>
