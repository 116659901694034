import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  HkAvailabilitiesApiResponse,
  HkAvailabilitiesServiceResponse,
  NormalizedHkAvailabilitiesApiResponse,
} from './hkAvailabilities.types'

const URL = '/effective_availabilities'

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyHkAvailabilitiesResponse: NormalizedHkAvailabilitiesApiResponse =
  Object.freeze({
    normalized: {
      effectiveAvailability: {},
    },
    raw: { data: [] },
  })

export const hkAvailabilitiesService = {
  /**
   * Performs a GET request for all HK Availabilities
   * @param params
   */
  async fetchHkAvailabilities(
    params: RequestOptions = {},
  ): HkAvailabilitiesServiceResponse {
    const response: HkAvailabilitiesApiResponse = await api.get(URL, { params })
    return get(response, 'data', emptyHkAvailabilitiesResponse)
  },
}
