import styled from '@emotion/styled'

import { TicketSeverity } from 'packages/grimoire/src/ticket'
import { SvgIcon } from 'packages/iconic'
import {
  centerWithFlex,
  colors,
  text,
  truncateTextWithEllipsis,
} from 'packages/styles'

import { CardSize } from '../../../../helpers/scheduleMeasure'
import { severityStatusColorMap } from '../../../../helpers/ticketHelpers'

const severityBarSizeMap = {
  large: 4,
  medium: 1,
  small: 1,
}

export const TicketHeader = styled.div`
  display: flex;
`

type TicketStatusSeverityBarProps = {
  cardSize: CardSize
  severity: TicketSeverity
}

const GenericStatusBar = styled.div`
  display: flex;
  padding: 6px 8px;
  width: 100%;
`

export const CompletedStatusBar = styled(GenericStatusBar)`
  background: ${colors.success40};
`

export const CancelledStatusBar = styled(GenericStatusBar)`
  background: ${colors.midnight20};
`

export const TicketSeverityBar = styled.div<TicketStatusSeverityBarProps>`
  align-items: center;
  background: ${props => severityStatusColorMap[props.severity].severity};
  display: flex;
  flex-grow: ${props => severityBarSizeMap[props.cardSize]};
  padding: 4px 8px;

  // If there is also a status bar, we want to make sure the severity bar doesn't take up too much space
  &:not(:last-child) {
    max-width: 65%;
  }
`

export const TicketStatusBar = styled.div<TicketStatusSeverityBarProps>`
  ${centerWithFlex};
  background: ${props => severityStatusColorMap[props.severity].status};
  flex-grow: 1;
  padding: 6px 8px;
  overflow: hidden;
`

export const StatusText = styled.span`
  ${text.headingTiny};
  ${truncateTextWithEllipsis};
`

export const SeverityText = styled.div`
  ${truncateTextWithEllipsis};
  font-weight: 800;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  overflow: hidden;
`

export const SeverityTextContainer = styled.div`
  ${truncateTextWithEllipsis};
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;
  line-height: 14px;
`

export const OverdueIcon = styled(SvgIcon)`
  background: white;
  border-radius: 36px;
  border: 1px solid ${colors.dusk};
  color: ${colors.alert};
  margin-left: 16px;
  padding: 2px 16px;
`
