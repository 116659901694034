import { Global, css } from '@emotion/react'
import React from 'react'

const styles = css`
  @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600;700;800&display=swap');

  * {
    box-sizing: border-box;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: white;
    color: #212529;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    text-align: left;

    * {
      font-family: 'Public Sans', Helvetica, Arial, sans-serif;

      &:focus {
        outline: none;
      }
    }
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }

  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }

  hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    height: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
    overflow: visible;
  }
`

export const CssReset: React.FC = () => {
  return <Global styles={styles} />
}
