import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'

const St = {
  VersionDisplay: styled.div`
    color: ${colors.dusk40};
    display: flex;
    font-size: 12px;
    justify-content: flex-end;
    padding-bottom: 4px;
  `,
}

export type VersionDisplayProps = {
  className?: string
}

export const VersionDisplay: React.FC<VersionDisplayProps> = ({
  className,
}) => {
  const rawVersion = process.env.REACT_APP_RELEASE

  return rawVersion ? (
    <St.VersionDisplay
      className={className}
    >{`v. ${rawVersion}`}</St.VersionDisplay>
  ) : null
}
