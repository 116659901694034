import * as React from 'react'

import LegacyModal from './LegacyModal'
import {
  LegacyModalSharedProps,
  LegacyModalWrapperProps,
  LegacyModalRenderProps,
} from './LegacyModal.types'

import sharedModalStyles from './LegacyModal.module.scss'

const DEFAULT_MAX_WIDTH = 1200

export type ContentModalProps = {
  /** The max-width override for this instance. */
  maxWidth?: number
  overflow?: string
  renderChildren?: (renderProps: LegacyModalRenderProps) => React.ReactNode
} & LegacyModalWrapperProps &
  LegacyModalSharedProps

export const ContentModal: React.FC<ContentModalProps> = React.memo(
  ({
    children = React.Fragment,
    maxWidth = DEFAULT_MAX_WIDTH,
    overflow,
    renderChildren,
    title,
    ...passThruProps
  }) => (
    <LegacyModal
      {...passThruProps}
      bodyStyleOverrides={React.useMemo(
        () => ({
          maxWidth: Math.min(maxWidth, DEFAULT_MAX_WIDTH),
          overflow,
        }),
        [overflow, maxWidth],
      )}
      renderModalContent={renderProps => (
        <div className={sharedModalStyles.modalContent}>
          <div className={sharedModalStyles.contentWrapper}>
            {title && <div className={sharedModalStyles.title}>{title}</div>}
            <div className={sharedModalStyles.childrenWrapper}>
              {renderChildren ? renderChildren(renderProps) : children}
            </div>
          </div>
        </div>
      )}
    />
  ),
)
