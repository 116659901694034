import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  ReservationsApiResponse,
  ReservationsServiceResponse,
} from './reservations.types'
import { emptyReservationsResponse } from './reservations.utils'

const URL = '/reservations'

export const reservationsService = {
  async fetchReservationById(
    id: string,
    params: RequestOptions = {},
  ): ReservationsServiceResponse {
    const response: ReservationsApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return response?.data || emptyReservationsResponse
  },
  /**
   * Performs a GET request for all Reservations
   * @param params
   */
  async fetchReservations(
    params: RequestOptions = {},
  ): ReservationsServiceResponse {
    const response: ReservationsApiResponse = await api.get(URL, { params })
    return get(response, 'data', emptyReservationsResponse)
  },
}
