import { deprecated } from 'typesafe-actions'

import { TicketDrawerState, UiActionTypes } from '../ui.types'

const { createStandardAction } = deprecated

export const setTicketDrawerStateAction = createStandardAction(
  UiActionTypes.SET_TICKET_DRAWER_STATE,
)<Partial<TicketDrawerState>>()

export const setTicketDrawerState =
  (newState: Partial<TicketDrawerState>) => dispatch => {
    dispatch(setTicketDrawerStateAction(newState))
  }
