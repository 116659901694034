import get from 'lodash/get' // eslint-disable-line

import { RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  NotificationsApiResponse,
  NotificationsServiceResponse,
} from './notifications.types'

const URL = '/zone_notifications'

export const emptyNotificationsResponse = Object.freeze({
  normalized: {
    housekeepers: {},
    users: {},
    zoneNotifications: {},
  },
  raw: { data: [] },
})

export const notificationsService = {
  async fetchAllNotifications(
    params: RequestOptions = {},
  ): NotificationsServiceResponse {
    const response: NotificationsApiResponse = await api.get(URL, { params })
    return get(response, 'data', emptyNotificationsResponse)
  },

  async updateNotification(
    id: string,
    params: RequestOptions = {},
  ): NotificationsServiceResponse {
    const response: NotificationsApiResponse = await api.patch(
      `${URL}/${id}`,
      params,
    )
    return get(response, 'data', emptyNotificationsResponse)
  },
}
