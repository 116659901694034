import styled from '@emotion/styled'
import React from 'react'

import { Alert, Loader, LoaderAlignment } from 'packages/common'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Reservation } from 'app/hkhub/store/reservations'

const St = {
  LoaderWrapper: styled.div`
    height: 40px;
    position: relative;
  `,
  ReservationAlert: styled(Alert)`
    margin: 16px 24px 0 24px;
  `,
}

const useTranslations = (
  currentReservation?: Reservation,
  nextReservation?: Reservation,
) => {
  const { t } = useI18n()

  const checkoutDateStr = currentReservation
    ? formatLocalized(currentReservation.checkOut, DateFormat.MonthAndDayLong)
    : ''
  const checkoutTimeStr = currentReservation
    ? formatLocalized(currentReservation.checkOut, DateFormat.TimeWithAmPm)
    : ''

  const checkinDateStr = nextReservation
    ? formatLocalized(nextReservation.checkIn, DateFormat.MonthAndDayLong)
    : ''
  const checkinTimeStr = nextReservation
    ? formatLocalized(nextReservation.checkIn, DateFormat.TimeWithAmPm)
    : ''

  return {
    unitIsOccupied: t(Slugs.unitIsOccupied, {
      date: checkoutDateStr,
      time: checkoutTimeStr,
    }),
    unitIsVacant: t(Slugs.unitIsVacant, {
      date: checkinDateStr,
      time: checkinTimeStr,
    }),
  }
}

export type TicketDrawerReservationInfoProps = {
  currentReservation?: Reservation
  isFetchingReservation: boolean
  isFetchingTicket: boolean
  nextReservation?: Reservation
}

export const TicketDrawerReservationInfo: React.FC<TicketDrawerReservationInfoProps> =
  React.memo(props => {
    const {
      currentReservation,
      isFetchingReservation,
      isFetchingTicket,
      nextReservation,
    } = props
    const strings = useTranslations(currentReservation, nextReservation)

    if (isFetchingReservation || isFetchingTicket) {
      return (
        <St.LoaderWrapper>
          <Loader alignment={LoaderAlignment.center} />
        </St.LoaderWrapper>
      )
    }

    return (
      <>
        {currentReservation ? (
          <St.ReservationAlert alertType={'warning'}>
            {strings.unitIsOccupied}
          </St.ReservationAlert>
        ) : (
          <St.ReservationAlert alertType={'info'}>
            {strings.unitIsVacant}
          </St.ReservationAlert>
        )}
      </>
    )
  })
