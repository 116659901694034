import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { VisitPatchData } from 'app/hkhub/store/visits'

import { NormalizedTasksApiResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { VisitsActionTypes } from '../visits.types'
import { fetchVisitById } from './fetchVisitById'

export const updateVisitAction = createAsyncAction(
  VisitsActionTypes.UPDATE_VISIT,
  VisitsActionTypes.UPDATE_VISIT_SUCCESS,
  VisitsActionTypes.UPDATE_VISIT_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const buildRequestData = (patchData: VisitPatchData): RequestOptions => {
  const { hks } = patchData

  const assignedHousekeepers = Array.isArray(hks)
    ? hks.map(housekeeper => ({
        id: housekeeper.id,
        type: 'housekeeper',
      }))
    : undefined

  const relationships = {
    ...(assignedHousekeepers && {
      assigned_housekeepers: { data: assignedHousekeepers },
    }),
  }

  return {
    data: {
      attributes: {},
      id: patchData.id,
      relationships,
      type: 'task',
    },
  }
}

export const updateVisit =
  (patchData: VisitPatchData, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onError = noop, onSuccess = noop } = callbacks
    try {
      const visitId = patchData.id
      const requestData = buildRequestData(patchData)
      const request = tasksService.updateTask.bind(null, visitId, requestData)
      const result = await dispatch(updateVisitAction.request({ request }))
      dispatch(updateVisitAction.success(result))

      // re-fetch the associated visit to ensure we have the latest version locally
      await dispatch(fetchVisitById(visitId))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(updateVisitAction.failure(error))
      onError()
      throw error
    }
  }
