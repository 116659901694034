import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ticketingService } from '../ticketing.service'
import {
  NormalizedTicketingApiResponse,
  TicketingActionTypes,
  UpdateAssignmentsOptions,
} from '../ticketing.types'

export const updateTicketingAssignmentAction = createAsyncAction(
  TicketingActionTypes.UPDATE_ASSIGNMENT,
  TicketingActionTypes.UPDATE_ASSIGNMENT_SUCCESS,
  TicketingActionTypes.UPDATE_ASSIGNMENT_FAILURE,
)<
  RequestConfig<NormalizedTicketingApiResponse>,
  NormalizedTicketingApiResponse,
  Error
>()

export const buildRequestData = (
  patchData: UpdateAssignmentsOptions,
): RequestOptions => {
  const { isActive, userId } = patchData

  const attributes = isActive !== undefined ? { is_active: isActive } : {}

  const relationships = userId
    ? {
        user: { data: { id: userId, type: 'user' } },
      }
    : {}

  return {
    data: {
      attributes,
      relationships,
      type: 'zone_disposition_default',
    },
  }
}

export const updateTicketingAssignment =
  (patchData: UpdateAssignmentsOptions) => async dispatch => {
    try {
      const { assignmentId } = patchData
      const requestData = buildRequestData(patchData)
      const request = ticketingService.updateAssignment.bind(
        null,
        assignmentId,
        requestData,
      )
      const result = await dispatch(
        updateTicketingAssignmentAction.request({ request }),
      )
      dispatch(updateTicketingAssignmentAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(updateTicketingAssignmentAction.failure(error))
      throw error
    }
  }
