import { ApplicationState } from '../../store'

/**
 * Returns an ID token, based on multiple possible token in the following order of precedence:
 * - Delegate token for shared/delegate views
 * - Impersonation tokens for impersonating users (admin only)
 * - Actual ID token (for all regular users)
 */
export const getAuthToken = (state: ApplicationState): string | undefined =>
  state.auth.impersonationToken || state.auth.token

export const getAuthTokenWithoutImpersonation = (
  state: ApplicationState,
): string | undefined => state.auth.token
