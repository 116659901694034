import startCase from 'lodash/startCase'
import * as React from 'react'
import { usePrevious } from 'react-use'
import { v4 as uuid } from 'uuid'

import {
  Button,
  NotificationBadge,
  useConfirmModal,
  useToast,
} from 'packages/common'

import { useZoneContext } from 'app/hkhub/components/zone/ZonePage/ZonePage.context'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { isAgency } from 'app/hkhub/store/housekeepers/housekeepers.utils'
import {
  ZoneNotification,
  ZoneNotificationHousekeeper,
} from 'app/hkhub/store/notifications/notifications.types'

import { ZoneManageReduxProps } from '../../manage.types'
import AddHkModal from './AddHkModal'

import styles from '../AddModal.module.scss'

type ConfirmModalState<T> = {
  data?: T
  id: string
  operation: 'add' | 'borrow'
}

export type AddHkModalWrapperProps = ZoneManageReduxProps

export type AddHkModalWrapperState = {
  error: string
  loadingIds: string[]
}

const AddHkModalWrapper: React.FC<AddHkModalWrapperProps> = ({
  addBorrowedZoneToHk,
  addHksToZone,
  clearHkSearchResults,
  dismissZoneNotification,
  getAllAddHkNotifications,
  getAddHKNotificationCount,
  getHkSearchResults,
  fetchZoneNotificationsWithAddHks,
  fetchHousekeepersByZone,
  searchHousekeepers,
  updateHkInSearchResults,
}) => {
  const { t } = useI18n()
  const { zone } = useZoneContext()
  const { showToast } = useToast()

  const [mainModalShowing, setMainModalShowing] = React.useState(false)
  const [confirmModalState, setConfirmModalState] = React.useState<
    ConfirmModalState<Housekeeper>
  >({
    data: undefined,
    id: '',
    operation: 'add',
  })
  const prevModalState = usePrevious(confirmModalState)

  const [searchingState, setSearchingState] = React.useState({
    hasSearched: false,
    searching: false,
  })

  const [fetchingNotifications, setFetchingNotifications] = React.useState(true)
  const [apiState, setApiState] = React.useState<AddHkModalWrapperState>({
    error: '',
    loadingIds: [],
  })

  React.useEffect(() => {
    async function fetchCurrentNotificationsIfModalShows() {
      if (mainModalShowing) {
        setFetchingNotifications(true)
        await fetchZoneNotificationsWithAddHks(zone.id)
        setFetchingNotifications(false)
      }
    }

    fetchCurrentNotificationsIfModalShows()
  }, [fetchZoneNotificationsWithAddHks, mainModalShowing, zone.id])

  const handleClearHkSearchResults = React.useCallback(() => {
    setSearchingState({ hasSearched: false, searching: false })
    clearHkSearchResults()
  }, [clearHkSearchResults, setSearchingState])

  const handleModalClose = () => {
    handleClearHkSearchResults()
    setMainModalShowing(false)
    setApiState({ error: '', loadingIds: [] })
  }

  const handleSearchHousekeepers = React.useCallback(
    async (searchString: string) => {
      setSearchingState(prev => ({ ...prev, hasSearched: false }))
      if (searchString.length >= 3) {
        setSearchingState({ hasSearched: false, searching: true })
        await searchHousekeepers(searchString)
        setSearchingState({ hasSearched: true, searching: false })
      }
    },
    [searchHousekeepers],
  )

  const handleConfirmAddHk = React.useCallback(
    async (hk: Housekeeper) => {
      try {
        const ids = [hk.id]
        setApiState({ error: '', loadingIds: ids })
        await addHksToZone({
          ownerId: zone.id,
          relationshipIds: ids,
        })

        const hkName = `${hk.user.firstName} ${hk.user.lastName}`
        await updateHkInSearchResults(hk.id)

        setApiState({ error: '', loadingIds: [] })
        fetchHousekeepersByZone(zone.id)
        showToast({ message: t(Slugs.canNowAssignCleansToHk, { hkName }) })
        fetchZoneNotificationsWithAddHks(zone.id)
      } catch (err) {
        setApiState({ error: t(Slugs.errorTechSupport), loadingIds: [] })
      }
    },
    [
      addHksToZone,
      fetchHousekeepersByZone,
      fetchZoneNotificationsWithAddHks,
      showToast,
      t,
      updateHkInSearchResults,
      zone.id,
    ],
  )

  const handleConfirmAddAgencyHk = React.useCallback(() => {
    const { data: hk } = confirmModalState
    if (!hk) {
      throw new Error('hk not defined in AddHkModalWrapper')
    }

    handleConfirmAddHk(hk)
  }, [confirmModalState, handleConfirmAddHk])

  const handleAddHkToZone = React.useCallback(
    (hk: Housekeeper) => {
      // for agency HKs, we want to show a confirm modal before adding;
      // for all others, no confirmation needed—just add immediately
      if (isAgency(hk)) {
        setConfirmModalState({
          data: hk,
          id: uuid(),
          operation: 'add',
        })
      } else {
        handleConfirmAddHk(hk)
      }
    },
    [handleConfirmAddHk],
  )

  const handleAcceptAddNotification = React.useCallback(
    async (notification: ZoneNotification) => {
      try {
        const notificationIds = [notification.id]
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        const hk: ZoneNotificationHousekeeper = notification.housekeeper! // This *must* exist for the notification to exist
        setApiState({ error: '', loadingIds: notificationIds })

        await addHksToZone({
          ownerId: zone.id,
          relationshipIds: [hk.id],
        })

        await fetchZoneNotificationsWithAddHks(zone.id)

        const hkName = `${hk.firstName} ${hk.lastName}`
        fetchHousekeepersByZone(zone.id)
        showToast({ message: t(Slugs.canNowAssignCleansToHk, { hkName }) })
        setApiState({ error: '', loadingIds: [] })
      } catch (err) {
        setApiState({ error: t(Slugs.errorTechSupport), loadingIds: [] })
      }
    },
    [
      addHksToZone,
      fetchHousekeepersByZone,
      fetchZoneNotificationsWithAddHks,
      showToast,
      t,
      zone.id,
    ],
  )

  /**
   * Callback for clicking "Confirm" on the borrow HK confirmation modal
   * This callback _does_ trigger the Redux action to make the actual API request
   */
  const handleConfirmBorrowHk = React.useCallback(async () => {
    try {
      const { data: hk } = confirmModalState

      if (!hk) throw new Error('hk not defined in AddHkModalWrapper')

      const relationshipIds = [zone.id]
      const hkLoadingIds = [hk.id]
      setApiState({ error: '', loadingIds: hkLoadingIds })
      await addBorrowedZoneToHk({
        ownerId: hk.id,
        relationshipIds,
      })

      const hkName = `${hk.user.firstName} ${hk.user.lastName}`
      await updateHkInSearchResults(hk.id)
      showToast({ message: t(Slugs.canNowAssignCleansToHk, { hkName }) })
      fetchHousekeepersByZone(zone.id)
      setApiState({ error: '', loadingIds: [] })
    } catch (err) {
      setApiState({ error: t(Slugs.errorTechSupport), loadingIds: [] })
    }
  }, [
    addBorrowedZoneToHk,
    confirmModalState,
    fetchHousekeepersByZone,
    showToast,
    t,
    updateHkInSearchResults,
    zone.id,
  ])

  /**
   * Callback for clicking the "Borrow" action next to a housekeeper in search results
   * Note that this _does not_ make the call yet, but rather sets up and displays the confirmation modal.
   */
  const handleBorrowHksToZone = React.useCallback((hk: Housekeeper) => {
    setConfirmModalState({
      data: hk,
      id: uuid(),
      operation: 'borrow',
    })
  }, [])

  const handleDismissZoneNotification = React.useCallback(
    async (id: string) => {
      try {
        const hkLoadingIds = [id]
        setApiState({ error: '', loadingIds: hkLoadingIds })
        await dismissZoneNotification(id)
        fetchZoneNotificationsWithAddHks(zone.id)
        setApiState({ error: '', loadingIds: [] })
      } catch (err) {
        setApiState({ error: t(Slugs.errorTechSupport), loadingIds: [] })
      }
    },
    [dismissZoneNotification, fetchZoneNotificationsWithAddHks, t, zone],
  )

  const { error, loadingIds } = apiState
  const searchResults = getHkSearchResults()
  const addHkNotifications = getAllAddHkNotifications()
  const totalHkNotifications = getAddHKNotificationCount()

  // default confirm modal instance for adding/borrowing standard HKs
  const { showModal: showConfirmModal } = useConfirmModal({
    onConfirm: handleConfirmBorrowHk,
    slugs: {
      confirm: Slugs.borrowHousekeeperModalConfirm,
      message: Slugs.borrowedHousekeepersWillNotHaveCleansAssigned,
      title: Slugs.borrowHousekeeperModalTitle,
    },
  })

  // confirm modal instance specific to adding "contractor agency" HKs
  const { showModal: showConfirmAddAgencyModal } = useConfirmModal({
    onConfirm: handleConfirmAddAgencyHk,
    slugs: {
      confirm: Slugs.confirm,
      message: Slugs.addContractorAgencyBody,
      title: Slugs.addContractorAgencyTitle,
    },
  })

  // confirm modal instance specific to borrowing "contractor agency" HKs
  const { showModal: showConfirmBorrowAgencyModal } = useConfirmModal({
    onConfirm: handleConfirmBorrowHk,
    slugs: {
      confirm: Slugs.confirm,
      message: Slugs.addContractorAgencyBody,
      title: Slugs.addContractorAgencyTitle,
    },
  })

  // effect handler for showing a confirmation modal any time "confirmModalState" is updated
  React.useEffect(() => {
    const isNewValidRequestConfig =
      confirmModalState?.id && confirmModalState.id !== prevModalState?.id

    if (isNewValidRequestConfig) {
      const { data, operation } = confirmModalState
      const hk = data as Housekeeper

      if (hk?.employeeType && isAgency(hk)) {
        operation === 'borrow'
          ? showConfirmBorrowAgencyModal()
          : showConfirmAddAgencyModal()
      } else {
        showConfirmModal()
      }
    }
  }, [
    confirmModalState,
    prevModalState,
    showConfirmModal,
    showConfirmBorrowAgencyModal,
    showConfirmAddAgencyModal,
  ])

  return (
    <>
      <Button
        className={styles.addButton}
        dataTestId="addHkButton"
        onClick={() => setMainModalShowing(true)}
        buttonType={'text'}
      >
        <span className={styles.buttonContent}>
          {startCase(t(Slugs.addHousekeeper))}
          {!!totalHkNotifications && (
            <NotificationBadge count={totalHkNotifications} />
          )}
        </span>
      </Button>

      <AddHkModal
        afterClose={handleModalClose}
        errorMessage={error}
        fetchingNotifications={fetchingNotifications}
        hasSearched={searchingState.hasSearched}
        housekeepers={searchResults}
        loadingIds={loadingIds}
        notifications={addHkNotifications}
        onAcceptAddNotification={handleAcceptAddNotification}
        onAddHksToZone={handleAddHkToZone}
        onBorrowHksToZone={handleBorrowHksToZone}
        onClearHkSearchResults={handleClearHkSearchResults}
        onDismissZoneNotification={handleDismissZoneNotification}
        onSearchHks={handleSearchHousekeepers}
        searching={searchingState.searching}
        showing={mainModalShowing}
        zone={zone}
      />
    </>
  )
}

/**
 * Wrapper component for internalizing all of the state functionality required by AddHkModal.
 *
 * Note that, even though it is a Modal, this component is effectively a full page/view component, which
 * is how we are using this wrapper. Most of the state and Redux stuff is managed within this component,
 * and passed directly down to the Modal itself.
 *
 * The button/trigger is even included in this component, because it wraps a lot of the show/hide logic.
 */
export default React.memo(AddHkModalWrapper)
