import { TicketSeverity } from 'packages/grimoire/src/ticket'
import { colors } from 'packages/styles'

export const severityStatusColorMap: Record<
  TicketSeverity,
  { severity: string; status: string }
> = {
  [TicketSeverity.LOW]: { severity: colors.midnight10, status: '#F2F5F6' },
  [TicketSeverity.MEDIUM]: { severity: colors.midnight10, status: '#F2F5F6' },
  [TicketSeverity.PLANNED]: {
    severity: colors.midnight10,
    status: colors.midnight10,
  },
  [TicketSeverity.PRIOR_TO_NEXT_GUEST]: {
    severity: colors.awareness40,
    status: '#F8EFDB',
  },
  [TicketSeverity.TODAY]: { severity: colors.alert40, status: '#F7D5DC' },
  [TicketSeverity.URGENT]: { severity: colors.alert40, status: '#F7D5DC' },
}
