import styled from '@emotion/styled'
import React from 'react'

import { CleanRealtimeStatusIndicator } from 'packages/common'
import { CleanRealtimeStatus, isNotReadyStatus } from 'packages/grimoire'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Clean } from 'app/hkhub/store/cleans/cleans.types'

export type CleanStatusDisplayProps = {
  clean: Clean
}

const StatusText = styled.span<{ cleanRealtimeStatus: CleanRealtimeStatus }>`
  padding-left: ${({ cleanRealtimeStatus }) =>
    cleanRealtimeStatus === CleanRealtimeStatus.NOT_READY ? '0' : '8px'};
  padding-top: 1px; // for better visual centering of text
  text-transform: uppercase;
`

const statusTextMap = {
  [CleanRealtimeStatus.NOT_READY]: Slugs.cleanStatusNotReady,
  [CleanRealtimeStatus.READY]: Slugs.cleanStatusReady,
  [CleanRealtimeStatus.READY_OVERDUE]: Slugs.cleanStatusOverdue,
  [CleanRealtimeStatus.IN_PROGRESS]: Slugs.cleanStatusInProgress,
  [CleanRealtimeStatus.IN_PROGRESS_OVERDUE]: Slugs.cleanStatusOverdue,
  [CleanRealtimeStatus.COMPLETED]: Slugs.cleanStatusCompleted,
}

function useTranslations(cleanRealtimeStatus: CleanRealtimeStatus) {
  const { t } = useI18n()

  return {
    statusText: t(statusTextMap[cleanRealtimeStatus]),
  }
}

export const CleanStatusDisplay: React.FC<CleanStatusDisplayProps> = React.memo(
  ({ clean }) => {
    const strings = useTranslations(clean.realtimeStatus)

    return (
      <>
        {!isNotReadyStatus(clean.realtimeStatus) && (
          <CleanRealtimeStatusIndicator
            cleanRealtimeStatus={clean.realtimeStatus}
          />
        )}
        <StatusText cleanRealtimeStatus={clean.realtimeStatus}>
          {strings.statusText}
        </StatusText>
      </>
    )
  },
)
