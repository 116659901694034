import get from 'lodash/get' // eslint-disable-line
import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import {
  RawZoneNotification,
  ZoneNotification,
  ZoneNotificationAttributeNames,
  ZoneNotificationHousekeeper,
  ZoneNotificationsState,
} from 'app/hkhub/store/notifications/notifications.types'
import { ApplicationState } from 'app/hkhub/store/store'
import { UserAttributeNames } from 'app/hkhub/store/users/users.types'

import { User } from '../../users/users.types'
import NotificationCodes from '../notifications.codes'

const { SUGGESTED_HK } = NotificationCodes

const getNotificationsState = (
  state: ApplicationState,
): ZoneNotificationsState => state.notifications

/**
 * Function that plucks the necessary info about the HK and their manager from an add HK notification
 * @param notification
 * @param notificationState
 */
function getHkFromZoneNotification(
  notification,
  notificationState: ZoneNotificationsState,
): ZoneNotificationHousekeeper | undefined {
  const notificationsHkState = notificationState.housekeeper
  const notificationUserState = notificationState.user

  const hkId = get(notification, 'relationships.housekeeper.data.id')

  const hk = get(notificationsHkState, hkId)

  const hkUserId = get(hk, 'relationships.user.data.id')
  const rawHkUser = get(notificationUserState, hkUserId || '')

  const hkManagerUserId = get(hk, 'relationships.manager.data.id')
  const rawHkManager = get(notificationUserState, hkManagerUserId || '')

  if (!rawHkUser || !rawHkManager) {
    return undefined
  }

  const normalizedHkUser = transformNormalizedToTyped<User>(
    rawHkUser,
    UserAttributeNames,
  )

  const normalizedManagerUser = transformNormalizedToTyped<User>(
    rawHkManager,
    UserAttributeNames,
  )

  return {
    ...normalizedHkUser,
    id: hkId,
    manager: normalizedManagerUser,
  }
}

function transformToNormalizeAndAppendRelationships(
  notification: RawZoneNotification,
  notificationUserState,
): ZoneNotification | undefined {
  const normalizedHk = getHkFromZoneNotification(
    notification,
    notificationUserState,
  )

  const normalizedNotification = transformNormalizedToTyped<ZoneNotification>(
    notification,
    ZoneNotificationAttributeNames,
  )

  normalizedNotification.housekeeper = normalizedHk

  return normalizedHk && normalizedNotification
    ? normalizedNotification
    : undefined
}

/**
 * Returns all current Zone Notifications that match the code for "suggested HK".
 * These are notifications for when the API believes you have a new HK that needs
 * to be added to your zone.
 */
export const getAddHkZoneNotifications = createSelector(
  getNotificationsState,
  (notificationsState): ZoneNotification[] => {
    const isSuggestedHkNotification = (notification: RawZoneNotification) =>
      notification.attributes.messageCode === SUGGESTED_HK

    const transformHkZoneNotification = (notification: RawZoneNotification) =>
      transformToNormalizeAndAppendRelationships(
        notification,
        notificationsState,
      )

    return Object.values(notificationsState.data)
      .filter(isSuggestedHkNotification)
      .map(transformHkZoneNotification)
      .filter(n => !!n) as ZoneNotification[]
  },
)
