import { map, pipe, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { DateFormat, format } from 'packages/utils/dateHelpers'

import {
  UnassignedHk,
  UnknownHk,
} from 'app/hkhub/store/housekeepers/housekeepers.utils'
import { ApplicationState } from 'app/hkhub/store/store'

import { getDateRangeMap } from '../../cleans/cleans.utils'
import { hydrateRawVisit } from '../utils'
import { HkDateVisitBuckets, Visit } from '../visits.types'

/**
 * Buckets visits within the specified date range from the store into hk-date-buckets.
 * Unassigned visits should not exist, but will be bucketed by our "magic unassigned" HK record,
 * so they will simply be keyed by 'unassigned' instead of an actual HK ID.
 *
 * The final result set will look something like:
 * {
 *   'unassigned': {
 *     '2021-07-15': [...unassigned visits for this date]
 *     '2021-07-16': [...unassigned visits for this date]
 *   },
 *   [hk1.id]: {
 *     '2021-07-15': [...visits for this HK/Date combo]
 *     '2021-07-16': [...visits for this HK/Date combo]
 *   },
 *   [hk2.id]: {
 *     '2021-07-15': [...visits for this HK/Date combo]
 *     '2021-07-17': [...visits for this HK/Date combo]
 *   },
 *   ...and so on for all HKs and Visits
 * }
 */
export const getVisitBucketsByHkAndDate = createSelector(
  (state: ApplicationState) => state.visits,
  (_, dateRange: Date[]) => dateRange,
  (visitsState, dateRange): HkDateVisitBuckets => {
    const dateRangeMap = getDateRangeMap(dateRange)

    const rawVisits = visitsState.data

    const visits: Visit[] = pipe(values, map(hydrateRawVisit))(rawVisits)

    return visits.reduce((acc, visit) => {
      const date = format(visit.effectiveDate, DateFormat.ApiUtcShort)
      if (date in dateRangeMap) {
        /*
         * The assumption here is that visits have one and only one assignment.
         * This is true for now, but may not be true in the future.
         * We're also handling "unassigned" visits here, which should not exist,
         * but we can prevent a crash if they do somehow exist.
         */
        const hkId = visit.assignedHkIds.length
          ? visit.assignedHkIds[0] ?? UnknownHk.id
          : UnassignedHk.id

        if (!acc[hkId]) acc[hkId] = {}
        if (!acc[hkId][date]) acc[hkId][date] = []

        acc[hkId][date].push(visit)
      }

      return acc
    }, {})
  },
)
