import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { hydrateRawVisit } from '../utils'
import { Visit } from '../visits.types'

export const getVisitById = createSelector(
  (state: ApplicationState) => state.visits,
  (_, id: string) => id,
  (visitsState, visitId): Visit | undefined => {
    if (!visitId) return undefined

    const rawVisit = visitsState?.data?.[visitId]
    return rawVisit ? hydrateRawVisit(rawVisit) : undefined
  },
)
