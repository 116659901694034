import styled from '@emotion/styled'
import React from 'react'

import {
  Drawer,
  DrawerContainerProps,
  LIGHTBOX_CLASS_SELECTOR,
  Loader,
  LoaderAlignment,
} from 'packages/common'
import { isWithinFreezeWindow } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'
import { createDateObject } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Lockbox } from 'app/hkhub/store/lockboxes'
import { Unit } from 'app/hkhub/store/units'
import { Visit } from 'app/hkhub/store/visits'

import { VisitTicketCardContainer } from '../../VisitTicketCard'
import { AccessCodes } from '../CleanDrawer/AccessCodes'
import {
  ContentWrapper,
  Divider,
  DrawerSection,
  HousekeeperNotes,
  UnitInfoTitle,
  UnitLinks,
} from '../CleanDrawer/CleanDrawer.styles'
import { InspectionSection } from '../CleanDrawer/InspectionSection'
import { WifiInfo } from '../CleanDrawer/WifiInfo'
import { HkNotes } from '../UnitDrawer/components'
import { VisitAssignments } from './components/VisitAssignments'
import { VisitDrawerHeader } from './components/VisitDrawerHeader'
import { VisitUnitDetails } from './components/VisitUnitDetails'

const St = {
  ContentWrapper,
  Divider,
  DrawerSection,
  HomeIcon: styled(SvgIcon)`
    color: ${colors.midnight80};
    display: flex;
    margin-left: 6px;
  `,

  HousekeeperNotes,

  Invalid: styled.div`
    padding: 24px;
  `,
  LoaderWrapper: styled.div`
    display: inline-flex;
    justify-content: flex-start;
    margin-left: 6px;
    position: relative;
    width: 100%;
  `,
  TicketsAddedToVisitText: styled.span`
    ${text.bodyBoldTiny}
    color: ${colors.midnight80};
    display: flex;
    margin-left: 4px;
    text-transform: uppercase;
  `,
  TicketsHeader: styled.div`
    display: flex;
    flex-direction: row;
  `,
  TicketsList: styled.div`
    margin-left: 24px;
    margin-top: 16px;
  `,

  UnitInfoTitle,
  UnitLinks,

  VisitTicketsTitle: styled(UnitInfoTitle)`
    display: flex;
    flex-direction: row;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    tickets: t(Slugs.tickets),
    ticketsAddedToVisit: t(Slugs.ticketsAddedToVisit),
    unitInfo: t(Slugs.unitInfo),
  }
}

export enum VisitDrawerTestIds {
  content = 'VisitDrawer__content',
}

export type VisitDrawerProps = {
  fetchingVisit: boolean
  forceCloseDrawer: boolean
  handleAfterExit: () => void
  handleForceCloseDrawer: () => void
  isOpen: boolean
  lockbox?: Lockbox
  unit: Unit
  visit: Visit | undefined
}

const getAttachedTicketsCount = (fetchingVisit, ticketLength) => {
  if (!ticketLength && fetchingVisit) {
    return (
      <St.LoaderWrapper>
        <Loader alignment={LoaderAlignment.left} color={colors.dusk} size={8} />
      </St.LoaderWrapper>
    )
  } else {
    return <>({ticketLength ? ticketLength : 0})</>
  }
}

export const VisitDrawer: React.FunctionComponent<VisitDrawerProps> = ({
  fetchingVisit,
  forceCloseDrawer,
  handleAfterExit,
  handleForceCloseDrawer,
  isOpen,
  lockbox,
  unit,
  visit,
}) => {
  const visitDate = createDateObject(visit?.effectiveDate)
  const visitWithinFreezeWindow = isWithinFreezeWindow(visitDate)

  const ignoreClickEventsOn: DrawerContainerProps['ignoreClickEventsOn'] = [
    LIGHTBOX_CLASS_SELECTOR,
  ]

  const strings = useTranslations()

  return (
    <Drawer
      afterExit={handleAfterExit}
      forceClose={forceCloseDrawer}
      hideCloseButton={true}
      ignoreClickEventsOn={ignoreClickEventsOn}
      isOpen={isOpen}
    >
      {!!visit ? (
        <St.ContentWrapper data-testid={VisitDrawerTestIds.content}>
          <VisitDrawerHeader
            visit={visit}
            forceCloseDrawer={handleForceCloseDrawer}
          />

          <VisitUnitDetails unit={unit} />

          <St.Divider />

          <VisitAssignments visit={visit} />

          <St.Divider />

          <St.DrawerSection>
            <St.UnitInfoTitle>{strings.unitInfo}</St.UnitInfoTitle>
          </St.DrawerSection>
          {visitWithinFreezeWindow && (
            <AccessCodes lockbox={lockbox} unit={unit} />
          )}

          <WifiInfo amenities={unit.amenities} />
          <St.HousekeeperNotes>
            <HkNotes unit={unit} />
          </St.HousekeeperNotes>

          <St.Divider />

          <St.DrawerSection>
            <St.VisitTicketsTitle>
              {strings.tickets}{' '}
              {getAttachedTicketsCount(fetchingVisit, visit?.ticketIds.length)}
            </St.VisitTicketsTitle>
          </St.DrawerSection>

          <St.TicketsHeader>
            <St.HomeIcon icon={IconName.home} size={14}></St.HomeIcon>
            <St.TicketsAddedToVisitText>
              {strings.ticketsAddedToVisit}
            </St.TicketsAddedToVisitText>
          </St.TicketsHeader>

          {visit.ticketIds.length ? (
            <>
              <St.TicketsList>
                {visit.ticketIds.map(ticketId => (
                  <VisitTicketCardContainer
                    key={ticketId}
                    ticketId={ticketId}
                  />
                ))}
              </St.TicketsList>
            </>
          ) : (
            <></>
          )}

          <St.UnitLinks omitSections={['seeAllTickets']} unitId={unit.id} />

          <InspectionSection loading={fetchingVisit} task={visit} />
        </St.ContentWrapper>
      ) : (
        <St.Invalid>Invalid Visit Details</St.Invalid>
      )}
    </Drawer>
  )
}
