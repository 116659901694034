import styled from '@emotion/styled'
import React from 'react'

import { UnitDeepCleanStats } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs } from 'app/hkhub/i18n'

const St = {
  Icon: styled(SvgIcon)`
    margin-right: 8px;
  `,
  Row: styled.div`
    font-size: 14px;
  `,
  Stats: styled.div`
    padding-left: 24px;
  `,
  Title: styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    font-weight: 700;
    color: ${colors.midnight};
    text-transform: uppercase;
    font-size: 12px;
  `,
  Value: styled.span`
    font-weight: bold;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    b2Bs: ut(Slugs.b2Bs),
    daysOccupied: ut(Slugs.daysOccupied),
    sinceInitialClean: ut(Slugs.sinceInitialClean),
    sinceLastDeepClean: ut(Slugs.sinceLastDeepClean),
    staysOver30Days: ut(Slugs.staysOver30Days),
  }
}

export enum UnitDeepCleanStatisticsTestIds {
  container = 'UnitDeepCleanStatistics__container',
}

export type UnitDeepCleanStatisticsProps = {
  className?: string
  deepCleanStats: UnitDeepCleanStats
  unitLastDeepCleanAt?: string | null
}

export const UnitDeepCleanStatistics: React.FC<UnitDeepCleanStatisticsProps> =
  React.memo(({ className, deepCleanStats, unitLastDeepCleanAt }) => {
    const strings = useTranslations()
    return (
      <div
        className={className}
        data-testid={UnitDeepCleanStatisticsTestIds.container}
      >
        <St.Title>
          <St.Icon size={16} icon={IconName.homeLineGraph} />
          <span>
            {unitLastDeepCleanAt
              ? strings.sinceLastDeepClean
              : strings.sinceInitialClean}
          </span>
        </St.Title>
        <St.Stats>
          <St.Row>
            <span>{strings.b2Bs}: </span>
            <St.Value>{deepCleanStats.b2B}</St.Value>
          </St.Row>
          <St.Row>
            <span>{strings.daysOccupied}: </span>
            <St.Value>{deepCleanStats.daysOccupied}</St.Value>
          </St.Row>
          <St.Row>
            <span>{strings.staysOver30Days}: </span>
            <St.Value>{deepCleanStats.staysOver30Days}</St.Value>
          </St.Row>
        </St.Stats>
      </div>
    )
  })
