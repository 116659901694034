import { produce } from 'immer'
import { groupBy, intersection, map, pipe } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ApplicationState } from 'app/hkhub/store/store'

import { isEmployee, Housekeeper } from '../housekeepers.types'
import { isAgency } from '../housekeepers.utils'
import { getHousekeepers } from './getHousekeepers'

export type HkEmployeeStatusBuckets = {
  agencyHks: Housekeeper[]
  contractorHks: Housekeeper[]
  employeeHks: Housekeeper[]
}

export const hkIsBorrowedInZones =
  (zoneIds: string[]) =>
  (hk: Housekeeper): boolean =>
    !!intersection(zoneIds, hk.borrowedZones || []).length

/**
 * Stably sorts Hks by tier, with higher ranks coming first.
 */
export const sortByTier = (hks: Housekeeper[]): Housekeeper[] =>
  hks.sort((a: Housekeeper, b: Housekeeper) => {
    if (a.tier.rank === b.tier.rank) return 0
    return a.tier.rank > b.tier.rank ? -1 : 1
  })

/**
 * Stably sorts HKs by "borrowed" status, with non-borrowed coming first.
 */
export const sortByBorrowed = (hks: Housekeeper[]): Housekeeper[] =>
  hks.sort((a: Housekeeper, b: Housekeeper) => {
    const aBorrowed = a.borrowedInCurrentZones ? 0 : 1
    const bBorrowed = b.borrowedInCurrentZones ? 0 : 1
    if (aBorrowed === bBorrowed) return 0
    return aBorrowed < bBorrowed ? 1 : -1
  })

export const groupByEmployeeType = groupBy((hk: Housekeeper) => {
  if (isAgency(hk)) return 'agencyHks'
  if (isEmployee(hk)) return 'employeeHks'
  return 'contractorHks'
})

export const filterHksNotInSchedule =
  (zoneIds: string[]) =>
  (hks: Housekeeper[]): Housekeeper[] =>
    hks.filter(hk => {
      if (hk.borrowedInCurrentZones) return true
      if (!hk.zone?.id) return false
      if (zoneIds.includes(hk.zone?.id)) return true
      return false
    })

/**
 * Returns a bucketed set of all HKs, grouped by employee type,
 * with each bucket sorted by tier from high-to-low.
 *
 * This looks roughly like:
 * {
 *    employeeHks: [],
 *    contractorHks: [],
 *    agencyHks: [],
 * }
 */
export const getHksSortedByTier = createSelector(
  (state: ApplicationState) => state.housekeepers,
  (state: ApplicationState) => state.zones,
  (hkState, zoneState): HkEmployeeStatusBuckets => {
    const housekeepers = getHousekeepers(hkState)
    const hkIsBorrowed = hkIsBorrowedInZones(zoneState.currentZones)
    const hkIsInSchedule = filterHksNotInSchedule(zoneState.currentZones)

    const setBorrowedStatusForHks = map((hk: Housekeeper) =>
      produce(hk, draft => {
        draft.borrowedInCurrentZones = hkIsBorrowed(hk)
      }),
    )

    return pipe(
      setBorrowedStatusForHks,
      hkIsInSchedule,
      sortByTier,
      sortByBorrowed,
      groupByEmployeeType,
    )(housekeepers) as HkEmployeeStatusBuckets
  },
)
