import { createSelector } from 'reselect'

import { Housekeeper } from 'app/hkhub/store/housekeepers'
import { getHousekeeperById } from 'app/hkhub/store/housekeepers/selectors'
import { ApplicationState } from 'app/hkhub/store/store'

import { Visit } from '../../visits'

const getHousekeepersState = (state: ApplicationState) => state.housekeepers
const getAssignedHkIds = (_: ApplicationState, visit: Visit) =>
  visit.assignedHkIds

export const getAssignedHousekeepers = createSelector(
  // Input selectors
  getHousekeepersState,
  getAssignedHkIds,

  // Output selector
  (housekeepersState, assignedHkIds): Housekeeper[] => {
    return assignedHkIds
      .map(id => getHousekeeperById(housekeepersState, id))
      .filter(hk => !!hk) as Housekeeper[]
  },
)
