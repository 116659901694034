import { createAsyncAction } from 'typesafe-actions'

import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { ticketingService } from '../ticketing.service'
import {
  NormalizedTicketingApiResponse,
  TicketingActionTypes,
} from '../ticketing.types'

export const fetchTicketingAssignmentsAction = createAsyncAction(
  TicketingActionTypes.FETCH_ASSIGNMENTS,
  TicketingActionTypes.FETCH_ASSIGNMENTS_SUCCESS,
  TicketingActionTypes.FETCH_ASSIGNMENTS_FAILURE,
)<
  RequestConfig<NormalizedTicketingApiResponse>,
  NormalizedTicketingApiResponse,
  Error
>()

export const getParams = (zoneId: string): RequestOptions => ({
  fields: {
    disposition: [],
    user: ['first_name', 'last_name', 'monolith_user_id'],
    zone_disposition_default: ['is_active'],
  },
  filter: { zone: zoneId },
  include: ['disposition', 'user'],
  page: { size: 100 },
})

export const fetchTicketingAssignments = (zoneId: string) => async dispatch => {
  try {
    const params = getParams(zoneId)
    const request = ticketingService.fetchTicketingAssignments.bind(
      null,
      params,
    )
    const result = await dispatch(
      fetchTicketingAssignmentsAction.request({ request }),
    )
    dispatch(fetchTicketingAssignmentsAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchTicketingAssignmentsAction.failure(error))
    throw error
  }
}
