import styled from '@emotion/styled'

import { CleanRealtimeStatus } from 'packages/grimoire'
import { beginWithEllipsis, truncateTextWithEllipsis } from 'packages/styles'

import {
  cardIsTiny,
  cardNotTiny,
  CardSize,
  cardSizeEqualOrGreaterThan,
} from 'app/hkhub/components/schedule/helpers/scheduleMeasure'

const getTitleSize = (size: CardSize) => {
  if (cardSizeEqualOrGreaterThan(size, 'medium')) return 15
  if (cardSizeEqualOrGreaterThan(size, 'small')) return 14
  return 10
}

const getTextColor = (size: CardSize, status: CleanRealtimeStatus) => {
  const isTinyNotReadyCard =
    cardIsTiny(size) && status === CleanRealtimeStatus.NOT_READY
  if (cardNotTiny(size) || isTinyNotReadyCard) {
    return 'black'
  }

  return 'white'
}

export const St = {
  Container: styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
  `,

  PrimaryLabel: styled.div<{
    beginWithEllipsisOnTiny: boolean
    size: CardSize
    status: CleanRealtimeStatus
  }>`
    ${({ beginWithEllipsisOnTiny, size }) => {
      if (beginWithEllipsisOnTiny && cardIsTiny(size)) {
        return beginWithEllipsis
      }

      return truncateTextWithEllipsis
    }};

    color: ${({ size, status }) => getTextColor(size, status)};
    font-size: ${({ size }) => `${getTitleSize(size)}px`};
    font-weight: 700;
    line-height: 18px;
    width: 100%;
  `,

  SecondaryLabel: styled.div<{ size: CardSize; status: CleanRealtimeStatus }>`
    ${truncateTextWithEllipsis};

    color: ${({ size, status }) => getTextColor(size, status)};
    font-size: 13px;
    width: 100%;
  `,
}
