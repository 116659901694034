import {
  Action,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit'
import { merge } from 'lodash/fp'
import get from 'lodash/get' // eslint-disable-line
import { getType } from 'typesafe-actions'

import {
  JSONApiObjectWithRelationships,
  NormalizedJSONApiResponse,
} from 'packages/utils/store/jsonapi.types'
import {
  getIdAndDataTypeFromAction,
  getKeyedDataFromAction,
} from 'packages/utils/store/store.utils'

import { CleansActionTypes, CleansResponse } from '../cleans'
import { fetchCleanAction } from '../cleans/actions'
import { TicketsResponse } from '../tickets'
import { fetchTicketByIdAction } from '../tickets/actions'
import {
  addUnitsToZoneAction,
  removeUnitsFromZoneAction,
} from '../zones/actions'
import { RawZone } from '../zones/zones.types'
import {
  createUnitPreferenceAction,
  fetchUnitByIdAction,
  fetchUnitByIdStrict,
  fetchUnitsByZone,
  fetchUnitsByZoneWithPreferences,
  removeUnitPreferenceAction,
  searchUnits,
  updateUnitInSearchResults,
} from './actions'
import {
  UnitsState,
  UnitAttributes,
  UnitPreferenceAttributes,
  UnitRelationships,
  UnitPreferenceRelationships,
  RawUnit,
  RawUnitPreference,
  UnitResponse,
} from './units.types'
import { emptyNormalizedUnitsData } from './units.utils'

const initialState: UnitsState = {
  data: {},
  error: undefined,
  housekeeperPreferences: {},
  isLoading: false,
  oncallUsers: {},
  searchResults: {
    unit: {},
    zone: {},
  },
}

const fallbackError = new Error('Unknown Error in unitsReducer')

type UnitWithRelationships = JSONApiObjectWithRelationships<
  UnitAttributes,
  UnitRelationships
>

type UnitPreferenceWithRelationships = JSONApiObjectWithRelationships<
  UnitPreferenceAttributes,
  UnitPreferenceRelationships
>

function getIdAndUnitFromAction(action) {
  return getIdAndDataTypeFromAction<RawUnit>(action, 'unit')
}

function getIdAndUnitPreferenceFromAction(action) {
  return getIdAndDataTypeFromAction<RawUnitPreference>(
    action,
    'housekeeperUnitPreference',
  )
}

type FetchUnitsByZoneRelatedActions = PayloadAction<
  NormalizedJSONApiResponse<UnitResponse>,
  string
>

type FetchUnitsRelatedActions = PayloadAction<
  NormalizedJSONApiResponse<TicketsResponse | UnitResponse>,
  string
>

type RejectedAction = {
  error: Error
} & Action

const isFetchUnitsFulfilled = (
  action: FetchUnitsByZoneRelatedActions,
): action is FetchUnitsByZoneRelatedActions => {
  if (isFulfilled(fetchUnitsByZone, fetchUnitsByZoneWithPreferences)(action)) {
    return true
  }

  return false
}

const isFetchUnitsPending = (
  action: FetchUnitsByZoneRelatedActions,
): action is FetchUnitsByZoneRelatedActions => {
  if (isPending(fetchUnitsByZone, fetchUnitsByZoneWithPreferences)(action)) {
    return true
  }

  return false
}

const isRelatedToFetchUnitsAction = (
  action: FetchUnitsRelatedActions,
): action is FetchUnitsRelatedActions => {
  if (isFulfilled(fetchUnitByIdStrict)(action)) {
    return true
  }

  // Move these to the `isAnyOf` params after refactoring the action
  // creators to use createAsyncThunk
  switch (action.type) {
    case getType(fetchTicketByIdAction.success):
    case getType(fetchUnitByIdAction.success):
      return true
    default:
      return false
  }
}

const isFailure = (action: RejectedAction): action is RejectedAction => {
  if (
    isRejected(
      fetchUnitsByZone,
      fetchUnitsByZoneWithPreferences,
      searchUnits,
      updateUnitInSearchResults,
    )(action)
  ) {
    return true
  }

  // Move these to the `isAnyOf` params after refactoring the action
  // creators to use createAsyncThunk
  switch (action.type) {
    case getType(addUnitsToZoneAction.failure):
    case getType(createUnitPreferenceAction.failure):
    case getType(fetchUnitByIdAction.failure):
    case getType(removeUnitsFromZoneAction.failure):
    case getType(removeUnitPreferenceAction.failure):
      return true
    default:
      return false
  }
}

export const unitsSlice = createSlice({
  extraReducers: builder => {
    // CREATE PREFERENCE
    builder.addCase(
      getType(createUnitPreferenceAction.success),
      (state, action) => {
        const [prefId, preference] = getIdAndUnitPreferenceFromAction(action)
        state.housekeeperPreferences[prefId] =
          preference as UnitPreferenceWithRelationships

        return
      },
    )

    // REMOVE PREFERENCE
    builder.addCase(
      getType(removeUnitPreferenceAction.success),
      (state, action) => {
        const preferenceId = get(action, 'payload', '')
        delete state.housekeeperPreferences[preferenceId]
        return
      },
    )

    // REMOVE UNITS
    builder.addCase(
      getType(removeUnitsFromZoneAction.success),
      (state, action) => {
        const removedUnitIds = get(
          action,
          'payload.normalized.relationshipUpdate.relationshipIds',
          [],
        )
        removedUnitIds.forEach(unitId => {
          const unit = state.data[unitId]
          if (unit) {
            // find and remove associated housekeeperPreferences records from the local store
            const hkPreferences =
              get(unit, ['relationships', 'housekeeperPreferences', 'data']) ||
              []
            const hkPreferencesIds = hkPreferences.map(item => item.id)
            hkPreferencesIds.forEach(daId => {
              if (state.housekeeperPreferences[daId]) {
                delete state.housekeeperPreferences[daId]
              }
            })

            delete state.data[unitId]
          }
        })

        return
      },
    )

    // SEARCH UNITS
    builder.addCase(searchUnits.fulfilled, (state, action) => {
      state.searchResults.unit = getKeyedDataFromAction(action, 'unit') || {}
      state.searchResults.zone = getKeyedDataFromAction(action, 'zone') || {}
      state.isLoading = false
      return
    })

    builder.addCase(updateUnitInSearchResults.fulfilled, (state, action) => {
      const [unitId, unit] = getIdAndUnitFromAction(action)
      const [zoneId, zone] = getIdAndDataTypeFromAction<RawZone>(action, 'zone')

      state.searchResults.unit[unitId] = unit as UnitWithRelationships
      // even though we will have this zone ( the current zone ) locally in zones state,
      // we still need to add it to the search results because it may not exist there
      state.searchResults.zone[zoneId] = zone
      return
    })

    // FETCH CLEAN
    builder.addCase(
      getType(fetchCleanAction.success),
      (
        state,
        action: PayloadAction<
          NormalizedJSONApiResponse<CleansResponse>,
          CleansActionTypes.FETCH_CLEAN_SUCCESS
        >,
      ) => {
        const normalized = action.payload.normalized || emptyNormalizedUnitsData

        if (normalized.unit) {
          state.isLoading = false
          state.error = undefined

          Object.values(normalized.unit).forEach(incomingUnit => {
            const existingUnit = state.data[incomingUnit.id] || {}
            const mergedUnit = merge(existingUnit, incomingUnit)
            state.data[incomingUnit.id] = mergedUnit
          })
        }

        return
      },
    )

    // FETCH UNITS
    builder.addMatcher(isFetchUnitsPending, state => {
      state.isLoading = true

      return
    })

    builder.addMatcher(isRelatedToFetchUnitsAction, (state, action) => {
      const [unitId, unit] = getIdAndUnitFromAction(action)

      // This merge predates custom inspection items but in the case when we've removed custom inspection
      // items, we want to remove them from redux as well
      state.data[unitId] = merge(state.data[unitId] ?? {}, unit)

      if (state.data[unitId].relationships?.customInspectionItems) {
        state.data[unitId].relationships.customInspectionItems =
          unit.relationships.customInspectionItems
      }

      return
    })

    builder.addMatcher(isFetchUnitsFulfilled, (state, action) => {
      state.isLoading = false
      state.error = undefined

      const normalized = action.payload.normalized || {}
      state.data = normalized.unit || {}
      state.housekeeperPreferences = normalized.housekeeperUnitPreference || {}

      state.oncallUsers = normalized.oncallUser || {}

      return
    })

    // FAILURE STATES
    builder.addMatcher(isFailure, (state, action) => {
      state.isLoading = false
      state.error = get(action, 'payload', fallbackError)
      return
    })
  },
  initialState,
  name: 'units',
  reducers: {
    // SEARCH UNITS
    clearUnitSearchResults: state => {
      state.searchResults = {
        unit: {},
        zone: {},
      }

      return
    },

    // clear all data when switching the current zone
    clearZone: state => {
      state.data = {}
      return
    },
  },
})

export const { clearUnitSearchResults, clearZone } = unitsSlice.actions

export default unitsSlice.reducer
