import * as React from 'react'
import { ValueType } from 'react-select/src/types'

import { AsyncSelector } from 'packages/common'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { User } from 'app/hkhub/store/users/users.types'

import { getHighlightedUserSearchResults } from './AsyncUserSelector.helpers'

type AsyncUserSelectorProps = {
  className?: string
  defaultOptions?: User[]
  getUsersResults: () => User[]
  isDisabled?: boolean
  onUserSelectionChange: (value: ValueType<User>) => void
  searchUsers: (input: string) => Promise<any> // eslint-disable-line
  selectedUser: User | null
}

const AsyncUserSelector: React.FC<AsyncUserSelectorProps> = ({
  className,
  getUsersResults,
  isDisabled = false,
  onUserSelectionChange,
  defaultOptions = [],
  searchUsers,
  selectedUser = null,
}) => {
  const { t } = useI18n()

  // The following line is used to refer to the current version of the getResults selector within load options
  const getResultsRef = React.useRef(getUsersResults)
  getResultsRef.current = getUsersResults

  const loadOptions = async (input, callback) => {
    await searchUsers(input)
    if (input.length >= 3) {
      const searchResultsInSelect = getResultsRef.current()
      callback(searchResultsInSelect)
    }
  }

  const noOptionsMessage = React.useCallback(
    ({ inputValue }) =>
      inputValue === '' ? t(Slugs.typeToSearch) : t(Slugs.noResults),
    [t],
  )

  return (
    <AsyncSelector
      className={className}
      defaultOptions={defaultOptions}
      isDisabled={isDisabled}
      formatOptionLabel={getHighlightedUserSearchResults}
      getOptionValue={user => user.id}
      loadingMessage={() => t(Slugs.loading)}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      onChange={onUserSelectionChange}
      placeholder={t(Slugs.select)}
      value={selectedUser}
    />
  )
}

export default AsyncUserSelector
