/* eslint-disable sort-keys */

// these legacy values are specified here only as a reference to the previous Sass variables (i.e. _zLayers.scss)
// these should only be used to provide compatibility with components that are still using Sass styling
const legacyLayerValues = {
  base: 0,
  l1: 10,
  l2: 20,
  l3: 30,
  l4: 40,
  l5: 50,
  max: 1050,
}

const legacyLayers = {
  banner: legacyLayerValues.l5,
}

export const layerValues = {
  l0: 0,
  l1: 100,
  l2: 200,
  l3: 300,
  l4: 400,
  l5: 500,
  // ideally, we should not define any z-layers higher than this value
  // if you REALLY need a higher value for any reason, we probably just need to bump this one up instead
  l9: 1050,
}

export const layers = {
  base: layerValues.l0,

  offlineBanner: legacyLayers.banner - 1,

  autoScheduleOverlay: layerValues.l1,

  zoneSelector: layerValues.l2 - 1,
  dropdownMenu: layerValues.l2,
  tooltip: layerValues.l2,

  shimmer: layerValues.l5 - 2,
  drawerBackdrop: layerValues.l5 - 1,
  drawer: layerValues.l5,
  modalBackdrop: layerValues.l5 + 1,
  modal: layerValues.l5 + 2,

  max: layerValues.l9,
}
