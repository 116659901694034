import styled from '@emotion/styled'
import get from 'lodash/get' // eslint-disable-line
import * as React from 'react'

import { Button, ContentModal, InputField, Select } from 'packages/common'
import sharedModalStyles from 'packages/common/src/legacy/LegacyModal/LegacyModal.module.scss'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors, text } from 'packages/styles'

import { AssignmentSelectOptionStyles } from 'app/hkhub/components/schedule/components/common/CleanDrawer/CleanAssignments/AssignmentEditor/AssignmentEditor.styles'
import { parseHkName } from 'app/hkhub/components/schedule/helpers/housekeeperHelpers'
import { Slugs, useI18n } from 'app/hkhub/i18n'
import {
  HkEmployeeType,
  Housekeeper,
} from 'app/hkhub/store/housekeepers/housekeepers.types'
import { UnitPreferenceKind } from 'app/hkhub/store/units/units.types'

import styles from './AddPreferenceModal.module.scss'

export type AddPreferenceModalConfig = {
  alias: UnitPreferenceKind
  unitCode: string
}

export type AddPreferenceModalProps = {
  afterClose: () => void
  config: AddPreferenceModalConfig | null
  housekeepers: Housekeeper[]
  isMissingPayRates: boolean
  onConfirm: (housekeeper: Housekeeper | null) => Promise<any> // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type ModalContentProps = {
  beginTransitionOut: () => void
  config: AddPreferenceModalConfig | null
  housekeepers: Housekeeper[]
  isMissingPayRates: boolean
  onConfirm: (housekeeper: Housekeeper | null, reason: string) => Promise<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  onSelect: (hk: Housekeeper | null) => void
  selectedHk: Housekeeper | null
}

const St = {
  MissingRatesIcon: styled(SvgIcon)`
    color: ${colors.alert};
    margin-left: 8px;
  `,

  MissingRatesOptionText: styled.span`
    text-decoration: line-through;
  `,

  MissingRatesText: styled.span`
    ${text.bodyRegularSmall}
    color: ${colors.alert};
    font-style: italic;
    margin-bottom: 0;
    margin-left: 8px;
  `,
}

export const ModalContent: React.FC<ModalContentProps> = ({
  beginTransitionOut,
  config,
  housekeepers,
  isMissingPayRates,
  onConfirm,
  onSelect,
  selectedHk,
}) => {
  const { t } = useI18n()

  const [reason, setReason] = React.useState('')

  const confirm = () => {
    beginTransitionOut()
    onConfirm(selectedHk, reason)
  }

  const isMandatory = get(config, 'alias') === UnitPreferenceKind.mandatory

  const getTitle = (alias: UnitPreferenceKind) => {
    switch (alias) {
      case UnitPreferenceKind.excluded:
        return t(Slugs.addExcluded)
      case UnitPreferenceKind.mandatory:
        return t(Slugs.addMandatory)
      case UnitPreferenceKind.suggested:
        return t(Slugs.addSuggested)
      default:
        return ''
    }
  }

  const getBody = (alias: UnitPreferenceKind) => {
    switch (alias) {
      case UnitPreferenceKind.excluded:
        return t(Slugs.addExcludedDescription)
      case UnitPreferenceKind.mandatory:
        return t(Slugs.addMandatoryDescription)
      case UnitPreferenceKind.suggested:
        return t(Slugs.addSuggestedDescription)
      default:
        return ''
    }
  }

  const getOptionDisplayName = (
    option: Housekeeper,
    isMissingPayRates: boolean,
  ): React.ReactFragment => {
    if (isMissingPayRates && hkIsContractor(option) && isMandatory) {
      return (
        <>
          <St.MissingRatesOptionText>
            {parseHkName(option)}
          </St.MissingRatesOptionText>
          <St.MissingRatesIcon icon={IconName.alertTriangle} size={14} />
          <St.MissingRatesText className="ratesNeeded">
            Rates Needed
          </St.MissingRatesText>
        </>
      )
    }

    return parseHkName(option)
  }

  const hkIsContractor = (hk: Housekeeper): boolean => {
    return (
      hk?.employeeType === HkEmployeeType.contractor ||
      hk?.employeeType === HkEmployeeType.agency
    )
  }

  const optionIsDisabled = (
    option: Housekeeper,
    isMissingPayRates: boolean,
  ): boolean => {
    return isMissingPayRates && hkIsContractor(option) && isMandatory
  }

  return (
    <>
      <h3 className={styles.unitCode}>{config ? config.unitCode : ''}</h3>
      <h2 className={styles.title}>{config ? getTitle(config.alias) : ''}</h2>
      <div className={styles.body}>{config ? getBody(config.alias) : ''}</div>

      <h4 className={styles.label}>{t(Slugs.housekeeper)}</h4>
      <Select
        getOptionLabel={option =>
          getOptionDisplayName(option, isMissingPayRates)
        }
        isOptionDisabled={option => optionIsDisabled(option, isMissingPayRates)}
        noOptionsMessage={() => t(Slugs.noOptions)}
        onChange={onSelect}
        options={housekeepers}
        selectedValue={selectedHk}
        style={AssignmentSelectOptionStyles}
      />

      {isMandatory ? (
        <div className={styles.reason}>
          <InputField label={t(Slugs.reason)} onInputChange={setReason} />
        </div>
      ) : null}

      <div className={sharedModalStyles.buttonsWrapper}>
        <Button onClick={beginTransitionOut} buttonType={'utility'}>
          {t(Slugs.cancel)}
        </Button>
        <Button
          disabled={!selectedHk || (isMandatory && !reason)}
          onClick={confirm}
          buttonType={'primary'}
        >
          {t(Slugs.add)}
        </Button>
      </div>
    </>
  )
}

const AddPreferenceModal: React.FC<AddPreferenceModalProps> = props => {
  const { afterClose, config } = props
  const [selectedHk, setSelectedHk] = React.useState<Housekeeper | null>(null)

  const onAfterClose = () => {
    afterClose()
    setSelectedHk(null)
  }

  const onSelect = (hk: Housekeeper | null) => {
    if (Array.isArray(hk) && hk.length === 0) {
      setSelectedHk(null)
    } else {
      setSelectedHk(hk)
    }
  }

  return (
    <ContentModal
      afterClose={onAfterClose}
      maxWidth={500}
      overflow={'visible'}
      showing={!!config}
      renderChildren={({ beginTransitionOut }) => (
        <ModalContent
          {...props}
          beginTransitionOut={beginTransitionOut}
          onSelect={onSelect}
          selectedHk={selectedHk}
        />
      )}
    />
  )
}

export default React.memo(AddPreferenceModal)
